import React, { useMemo } from 'react'
import { IorvoSequenceNumberRenderer } from '../../iorvo-grid/types/IorvoGridProps'
import { getColorClass } from '../../../utils/colors'
import { openDetailsPage, stopPropagation } from '../../../utils/navigation'
import Icon from '../../icon/Icon'
import { isMob } from '../../../utils/is'

const SNRenderer: React.FC<IorvoSequenceNumberRenderer> = ({
  displayIndex,
  payload,
  row,
}) => {
  const { rowsClassNames } = payload
  const rowColor = useMemo(() => {
    if (rowsClassNames && rowsClassNames[row._id]) {
      return rowsClassNames[row._id]
    }
  }, [row, rowsClassNames])

  const showExpand: boolean = !isMob()
  const openPage = () => {
    openDetailsPage(row._id)
  }

  return (
    <div className={'irv-sn'}>
      {rowColor && <i className={`color ${getColorClass(rowColor)}`} />}

      {displayIndex}

      {showExpand && (
        <button
          className={'expand'}
          onMouseDown={stopPropagation}
          onClick={openPage}>
          <Icon type={'arrow-expand'} />
        </button>
      )}
    </div>
  )
}

export default React.memo(SNRenderer)
