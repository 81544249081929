import React from 'react'
import {ColumnSettings} from "../types";
import {getSettingsComponents} from "../../../../../../components/columnHelpers/columnSettings";
import { GridColumn } from '../../../../../../components/grid/types/GridColumn';

export default React.memo(function GeneralColumnSettings(props: ColumnSettings) {
  const {column, table, params, onChange} = props
  const columnSettings = getSettingsComponents(column.type)

  if (!columnSettings || columnSettings.length === 0) {
    return null
  }

  return (
    <div className={'column-settings-item'}>
      {columnSettings.map((Component, i) => (
        <Component
          key={i}
          table={table}
          params={params}
          column={column as GridColumn}
          onAction={onChange}
        />
      ))}
    </div>
  )
})
