import React, { CSSProperties, memo } from 'react'
import { IorvoGridColumn } from '../types/IorvoGridColumn'

interface Props {
  column: IorvoGridColumn
  columnPayload: any
  row: any
  rowIndex: number[]
  active?: boolean
  index: number
}

const Cell: React.FC<Props> = ({
  row,
  column,
  columnPayload,
  active,
  index,
  rowIndex,
}) => {
  const Renderer = column.renderer
  const Component = Renderer ? (
    <Renderer
      active={active}
      row={row}
      column={column}
      index={rowIndex[1]}
      payload={columnPayload}
      groupIndex={rowIndex[2]}
    />
  ) : (
    row[column._id]
  )

  const style: CSSProperties = {
    width: column.width,
  }

  let cn = 'irv-cell'

  if (active) {
    cn += ' active'
  }

  return (
    <div className={cn} style={style} data-c={index}>
      {Component}
    </div>
  )
}

export default memo(Cell)
