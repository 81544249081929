import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../../../components/logo/Logo'
import {
  ActionType,
  disturbEntity,
  DisturbEntityArguments,
  loadEntity,
  LoadEntityArguments,
} from '../../../../store/CommonEffects'
import { EyzyUser } from '../../../../types/EyzyUser'
import { Workspace } from '../../../../types/Workspace'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import SBWorkspace from './components/SBWorkspace'
import { BaseActions } from '../../../../store/actions/BaseActions'
import { Base } from '../../../../types/Base'
import { removeItem } from '../../../../utils/array'
import { lastUsage } from '../../../../utils/storage'
import { BaseTable } from '../../../../types/BaseTable'
import { BaseView } from '../../../../types/BaseView'
import { history } from '../../../../utils/navigation'
import { WorkspaceActions } from '../../../../store/actions/WorkspaceActions'
import get from 'lodash/get'
import { orderColumns } from '../../../../utils/column'
import BaseCollaborators from '../../../../components/workspace/collaborators/BaseCollaborators'
import NEWIcon from '../../../../components/icon/NEWIcon'

import './style.scss'

interface State {
  collaborating?: { base: Base; ws: Workspace }
  expandedWs: string[]
  expandedBase: string[]
}

class Sb extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)

    const tableId = props.activeTable ? props.activeTable._id : ''
    const expandedBase = []

    props.ws.bases.forEach(base => {
      if (base.tables.some(t => t._id === tableId)) {
        expandedBase.push(base._id)
      }
    })

    this.state = {
      expandedWs: [props.ws._id],
      expandedBase,
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { activeTable, activeBase } = this.props
    const expandedBase = this.state.expandedBase

    if (
      activeTable &&
      !expandedBase.includes(activeTable.baseId) &&
      activeBase !== prevProps.activeBase
    ) {
      this.setState({
        expandedBase: expandedBase.concat(activeTable.baseId),
      })
    }
  }

  callAction = (
    entity: ActionType,
    id: string,
    payload?: any,
  ): Promise<any> => {
    return this.props.disturbEntity({
      entity,
      id,
      payload,
    })
  }

  handleCloseAll = () => {
    this.setState({
      collaborating: null,
    })
  }

  handleToggleBase = (base: Base, openNextLink?: boolean, cb?: () => void) => {
    const { loadEntity, collapsed } = this.props
    const { expandedBase } = this.state
    const baseId = base._id

    if (collapsed) {
      openNextLink = true
    }

    const openBase = (base: Base) => {
      if (!openNextLink) {
        return
      }

      const { tableId, viewId } = lastUsage(base)

      const nextTable: BaseTable =
        base.tables.find(t => t._id === tableId) || base.tables[0]
      const nextView: BaseView =
        nextTable.views.find(v => v._id === viewId) || nextTable.views[0]
      const nextPath: string = `/${nextTable._id}/${nextView._id}`

      // eslint-disable-next-line
      if (location.pathname !== nextPath) {
        history.push(nextPath)
      }

      cb && cb()
    }

    if (expandedBase.includes(baseId)) {
      if (openNextLink) {
        openBase(base)
      } else {
        this.setState({
          expandedBase: removeItem(expandedBase, b => b === baseId),
        })
      }
    } else {
      const isLoaded: boolean = !!get(base, 'tables[0]._id', false)

      if (isLoaded) {
        this.setState({
          expandedBase: expandedBase.concat(baseId),
        })
        openBase(base)
      } else {
        loadEntity({
          entity: BaseActions.FETCH_TABLES,
          action: BaseActions.FETCH_TABLES,
          id: baseId,
        }).then(({ tables }) => {
          this.setState({
            expandedBase: expandedBase.concat(baseId),
          })

          openBase({
            ...base,
            tables,
          })
        })
      }
    }
  }

  handleToggleWS = (ws: Workspace) => {
    const { loadEntity } = this.props
    const { expandedWs } = this.state

    if (expandedWs.includes(ws._id)) {
      this.setState({
        expandedWs: removeItem(expandedWs, w => w === ws._id),
      })
    } else {
      const isLoaded: boolean = !!get(ws, 'bases[0]._id', false)

      if (isLoaded) {
        this.setState({
          expandedWs: expandedWs.concat(ws._id),
        })
      } else {
        loadEntity({ action: WorkspaceActions.FETCH_BASES, id: ws._id }).then(
          _ => {
            this.setState({
              expandedWs: expandedWs.concat(ws._id),
            })
          },
        )
      }
    }
  }

  handleToggle = (entity: Workspace | Base, openNextLink?: boolean) => {
    if ('workspaceId' in entity) {
      this.handleToggleBase(entity as Base, openNextLink)
    } else {
      this.handleToggleWS(entity as Workspace)
    }
  }

  handleMenuClick = (action: ActionType, payload?: any) => {
    const { wss, activeBase } = this.props

    switch (action) {
      case BaseActions.PERMISSIONS_SETTING: {
        const ws = wss.find(ws => ws.bases.some(b => b._id === payload._id))

        if (ws) {
          return this.setState({
            collaborating: { ws, base: payload },
          })
        }

        break
      }

      case BaseActions.REMOVE: {
        const baseId: string = (payload as Base)._id
        const ws = wss.find(ws => ws.bases.some(b => b._id === baseId))

        if (ws) {
          if (baseId === activeBase) {
            let nextIndex: number =
              ws.bases.findIndex(b => b._id === baseId) + 1

            if (nextIndex === ws.bases.length) {
              nextIndex = ws.bases.length - 2
            }

            const nextOpenedBase: Base = ws.bases[nextIndex]

            this.handleToggleBase(nextOpenedBase, true, () => {
              this.callAction(action, baseId)
            })
          } else {
            this.callAction(action, baseId)
          }
        }

        break
      }

      case BaseActions.REMOVE_TABLE: {
        const ws = wss.find(ws => ws.bases.some(b => b._id === payload.baseId))
        console.log(ws)
      }
    }
  }

  render() {
    const {
      activeBase,
      activeTable,
      onToggleBar,
      wss,
      user,
      disturbEntity,
      collapsed,
      hideLogo,
    } = this.props
    const { expandedWs, expandedBase, collaborating } = this.state
    const workspaces = orderColumns(wss, get(user, 'params.wsOrder'))

    const collaborationBase = collaborating ? collaborating.base : null
    const collaborationWS = collaborating ? collaborating.ws : null

    return (
      <div className={'ws-sb sb'}>
        <BaseCollaborators
          user={user}
          ws={collaborationWS}
          base={collaborationBase}
          visible={!!collaborating}
          onClose={this.handleCloseAll}
          onAction={disturbEntity}
        />

        <div className={'sb-header'}>
          {!hideLogo && (
            <div className={'sb-logo'}>
              <Link to={'/'}>
                <Logo small={collapsed} />
              </Link>
            </div>
          )}
        </div>

        {/*<div className="sb-actions">*/}
        {/*  <a href={'/activity'} className={'sb-act'}>*/}
        {/*    <NEWIcon type={'bell'}/>*/}
        {/*    <span>Home</span>*/}
        {/*  </a>*/}
        {/*  <a href={'/activity'} className={'sb-act'}>*/}
        {/*    <NEWIcon type={'bell'}/>*/}
        {/*    <span>Activity</span>*/}
        {/*  </a>*/}

        {/*  <a href={'/dashboard'} className={'sb-act'}>*/}
        {/*    <NEWIcon type={'dashboard'}/>*/}
        {/*    <span>Dashboard</span>*/}
        {/*  </a>*/}
        {/*</div>*/}

        <div className="sb-wss sbr">
          {workspaces.map(w => (
            <SBWorkspace
              key={w._id}
              expanded={expandedWs.includes(w._id)}
              expandedBase={expandedBase}
              activeBase={activeBase}
              activeTable={activeTable ? activeTable._id : ''}
              ws={w}
              user={user}
              callAction={this.callAction}
              onToggle={this.handleToggle}
              onMenuClick={this.handleMenuClick}
            />
          ))}
        </div>

        <div className="sb-footer">
          <button className={'bo'} onClick={onToggleBar}>
            <NEWIcon type={'double-small-left'} />
            <span>Спрятать</span>
          </button>
        </div>
      </div>
    )
  }
}

type Props = ReduxProps & {
  ws: Workspace
  collapsed: boolean
  activeTable?: BaseTable
  hideLogo?: boolean
  onToggleBar: () => void
}

type ReduxStateProps = {
  activeBase: string
  user: EyzyUser
  wss: Workspace[]
}

type ReduxDispatchProps = {
  loadEntity: (args: LoadEntityArguments) => Promise<any>
  disturbEntity: (args: DisturbEntityArguments) => Promise<any>
}

type ReduxProps = ReduxStateProps & ReduxDispatchProps

const mapStateToProps = (state: any): ReduxStateProps => ({
  activeBase: state.active.base,
  wss: state.wss,
  user: state.user,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators<any, ReduxDispatchProps>(
    {
      loadEntity,
      disturbEntity,
    },
    dispatch,
  )

export default connect<ReduxStateProps, ReduxDispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(Sb)
