import React, { memo, useCallback } from 'react'
import { RendererClassNames } from '../definitions'
import BaseGridInputRenderer from '../utils/BaseGridInputRenderer'
import { GridCellRendererProps } from '../types/GridRenderer'
import { isEmail } from '../../../utils/is'
import { GridColumn } from "../../grid/types/GridColumn"

const GridEmailRenderer: React.FC<GridCellRendererProps> = props => {
  const renderer = useCallback((column: GridColumn, value: any) => {
    const isValidEmail: boolean = isEmail(value)

    if (!isValidEmail) {
      return value
    }

    if (props.active) {
      return <a href={`mailto:${value}`}>{value}</a>
    }

    return <span>{value}</span>
  }, [props])

  return (
    <BaseGridInputRenderer
      {...props}
      renderer={renderer}
      className={RendererClassNames.EMAIL}
    />
  )
}

export default memo(GridEmailRenderer)
