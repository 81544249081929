import React from 'react'
import {GridRendererProps} from "../GridRendererProps";
import {getValue} from "../../../general/utils/value";

export default React.memo(function GridPhoneRenderer(props: GridRendererProps) {
  const {row, column} = props
  const value = getValue(column.table, column, row)

  if (!value) {
    return null
  }

  return (
    <div className="re-value phone">
      <span><a href={`tel:${value}`}>{value}</a></span>
    </div>
  )
})
