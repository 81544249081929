import React, { memo } from 'react'
import { RendererClassNames } from '../definitions'
import BaseGridInputRenderer from '../utils/BaseGridInputRenderer'
import { GridCellRendererProps } from '../types/GridRenderer'

const GridTextRenderer: React.FC<GridCellRendererProps> = props => {
  return (
    <BaseGridInputRenderer {...props} />
  )
}

export default memo(GridTextRenderer)
