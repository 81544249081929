import React from 'react'
import {Event} from '../NEW__types/Event'
import {EventRendererProps} from "../NEW__types/Calendar";

interface Props {
  event: Event
  renderer?: React.FunctionComponent<EventRendererProps>
}

export default React.memo(function CalendarEvent(props: Props) {
  const {event, renderer} = props
  const className: string = `c-event` + (event.className ? ` ${event.className}` : '')

  return (
    <div className={className}>
      {
        renderer
          ? renderer({event})
          : <div className={'c-event-title'} title={event.title}>{event.title}</div>
      }
    </div>
  )
})
