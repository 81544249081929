import React, { useMemo } from "react"
import { SimpleRendererProps } from "../types/SimpleRendererProps"
import { SimpleRendererFactory } from "../factory"
import { asArray } from "../../../general/utils/array"

const LookupRenderer: React.FC<SimpleRendererProps> = ({
  value,
  className,
  column,
  params,
}) => {
  const renderColumn = useMemo(() => {
    return {
      ...column,
      type: column.params.foreignType,
      params: {
        ...column.params.foreignParams,
      },
    }
  }, [column])

  return (
    <div className={className}>
      {asArray(value).map(({ _id, value }) => (
        <SimpleRendererFactory
          key={_id}
          column={renderColumn}
          value={value}
          params={params}
        />
      ))}
    </div>
  )
}

export default React.memo(LookupRenderer)
