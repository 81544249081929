import React, { useEffect, useState } from 'react'
import { BaseTable } from '../../types/BaseTable'
import LinkedEntriesModal from './LinkedEntriesModal'
import { GridRow } from '../grid/types/GridRow'
import { load } from '../../store/CommonEffects'
import { Q } from '../../containers/workspace/types/Q'
import { ViewLike } from '../../types/BaseView'
import LinkedEntriesList from './LinkedEntriesList'
import { asArray, uniq } from '../../general/utils/array'
import { LinkedEntriesProps } from './types'
import { isEmpty } from '../../general/utils/is'
import { applySort } from '../../utils/sort'

import './style.scss'

const LinkedEntries: React.FC<LinkedEntriesProps> = ({
  value,
  data,
  foreignTable,
  dataAction,
  readonly,
  entriesParams = {},
  selectorVisible,
  onVisibilityChange,
  onChange,
}) => {
  const [table, setTable] = useState<BaseTable | null>(
    foreignTable || data?.table,
  )
  const [view, setView] = useState<ViewLike | null>(null)
  const [rows, setRows] = useState<GridRow[] | null>(data?.rows || null)

  const [valueRows, setValueRows] = useState<GridRow[] | null>(null)

  const handleCloseSelector = () => onVisibilityChange(false)

  const handleChange = value => {
    const rowIds: string[] = uniq(value)
    const payloadRows = (rows || []).filter(r => rowIds.includes(r._id))

    onChange(rowIds.map(_id => ({ _id })), {
      rows: payloadRows,
      table
    })
  }

  const handleRemove = (id: string) => {
    onChange(asArray(value).filter(r => r._id !== id))
  }

  // Load table + filtered rows
  useEffect(() => {
    if (selectorVisible) {
      const loadArgs = {
        ...dataAction,
        q: [Q.TABLE],
      }

      load(loadArgs).then(
        ({ table, rows }: { table: BaseTable; rows: GridRow[] }) => {
          let view

          if (table) {
            setTable(table)

            if (entriesParams.viewId) {
              view = table.views.find(v => v._id === entriesParams.viewId)

              setView(view)
            }
          }

          if (view && table) {
            setRows(applySort(rows, table, view.sort))
          } else {
            setRows(rows)
          }
        },
      )
    }
  }, [selectorVisible, entriesParams, dataAction])

  useEffect(() => {
    if (rows) {
      setValueRows(
        asArray(value)
          .map(({ _id }) => rows.find(r => r._id === _id))
          .filter(Boolean),
      )
    }
  }, [value, rows])

  useEffect(() => {
    if (entriesParams?.viewId && data?.table) {
      setView(data.table.views.find(v => v._id === entriesParams.viewId))
    }
  }, [data, entriesParams])

  useEffect(() => {
    if (!table && !isEmpty(value)) {
      const loadArgs = {
        ...dataAction,
        q: [Q.TABLE],
      }

      load(loadArgs).then(({ table, rows }) => {
        if (table) {
          setTable(table)

          if (entriesParams.viewId) {
            setView(table.views.find(v => v._id === entriesParams.viewId))
          }
        }

        const valueIds = asArray(value).map(({ _id }) => _id)

        setRows(rows.filter(r => valueIds.includes(r._id)))
      })
    }
    // Это делается только на MOUNT. Если есть значение, но нету таблицы - подгрузить нужно
    // eslint-disable-next-line
  }, [])

  return (
    <div className={'linked-entries'}>
      {valueRows && valueRows.length > 0 && (
        <LinkedEntriesList
          rows={valueRows}
          table={table}
          readonly={readonly}
          view={view}
          onRemove={handleRemove}
        />
      )}

      <LinkedEntriesModal
        rows={rows}
        table={table}
        view={view}
        multiple={entriesParams.multiple}
        visible={selectorVisible}
        selected={value.map(({ _id }) => _id)}
        onClose={handleCloseSelector}
        onChange={handleChange}
      />
    </div>
  )
}

export default LinkedEntries
