import React, { useEffect, useMemo, useState } from 'react'
import { GridRow } from '../../grid/types/GridRow'
import { BaseTable } from '../../../types/BaseTable'
import { ViewLike } from '../../../types/BaseView'
import { prepareColumns } from '../../../utils/column'
import { getValue } from '../../../general/utils/value'
import { BaseRendererFactory } from '../../__renderers/RendererFactory'
import './style.scss'
import { Table } from '../../irv-ui'
import { isMob } from '../../../utils/is'
import {
  getColumnParamsItem,
  mergeColumnWithViewParams,
} from '../../../utils/get'
import { GridColumn } from '../../grid/types/GridColumn'
import { createGridGroups } from '../../../utils/grid'
import { GridGroup } from '../../iorvo-grid/types/IorvoGridProps'
import { TableTheme } from '../../irv-ui/components/table/types/TableTheme'
import { SimpleRendererFactory } from '../../_renderers/factory'
import { FormProvider, useFormPayload } from '../../_renderers/RendererContext'

interface Props {
  rows: GridRow[]
  table: BaseTable
  view?: ViewLike
  showCheckbox?: boolean
  selected?: string[]
  onClick: (rowId: string[]) => void
}

const RowsTable: React.FC<Props> = ({
  rows,
  table,
  view,
  showCheckbox,
  selected,
  onClick,
}) => {
  const formPayload = useFormPayload()
  const [groups, prepareGroup] = useState<GridGroup[]>(
    createGridGroups(table, view, rows, {}),
  )

  useEffect(() => {
    prepareGroup(createGridGroups(table, view, rows, {}))
  }, [rows, view, table])

  const columns = useMemo(() => {
    return prepareColumns(table, view).map((column: GridColumn) => ({
      _id: column._id,
      title: column.name,
      width: getColumnParamsItem(view, column._id, 'width'),
      render: (row: GridRow) => (
        <FormProvider.Provider value={{ ...formPayload, row, table }}>
          <SimpleRendererFactory
            value={getValue(table, column, row)}
            column={column}
            params={mergeColumnWithViewParams(view, column)}
          />
        </FormProvider.Provider>
      ),
    }))
  }, [table, view])

  return (
    <Table
      pin={isMob() ? 0 : 1}
      columns={columns}
      data={rows}
      layout={'fixed'}
      size={'s'}
      theme={[TableTheme.MAIN, TableTheme.ACTIONABLE]}
      grouping={groups && groups.length ? groups : void 0}
      groupRenderer={props => {
        const group = props.group.group

        if (!group.value) {
          return <span className={'secondary-text'}>Ungrouped</span>
        }

        return (
          <BaseRendererFactory
            readonly
            onChange={() => {}}
            value={group.value}
            columnType={group.column.type}
            params={group.column.params}
          />
        )
      }}
      selectionMode={'multipleRow'}
      selectedKeys={showCheckbox ? selected : null}
      onClick={row => onClick(row._id)}
      onSelect={rows => onClick(rows)}
    />
  )
}

export default React.memo(RowsTable)
