import React, { useState } from 'react'
import Tooltip, { TooltipPlacement } from 'antd/lib/tooltip'
import Drawer from 'antd/lib/drawer'
import { cn } from '../../utils/classname'
import './style.scss'
import { isMob } from '../../utils/is'
import { Tray } from "../irv-ui"

interface Props {
  children?: any
  width?: number
  title?: React.ReactNode
  onClose?: () => void
  footer?: React.ReactNode
  onVisibleChange?: (isVisible: boolean) => void
  placement?: TooltipPlacement
  trigger?: string | string[]
  content?: React.ReactNode
  overlayClassName?: string
  align?: any
  defaultVisible?: boolean
  visible?: boolean
  getPopupContainer?: (r: any) => any
}

const ResponsiveTooltip: React.FC<Props> = ({
  align,
  trigger,
  onClose,
  children,
  content,
  footer,
  placement,
  getPopupContainer,
  overlayClassName,
  onVisibleChange,
  ...rest
}) => {
  const [isDrawerVisible, setDrawerVisibility] = useState(false)

  if (isMob()) {
    const onClick = () => {
      setDrawerVisibility(true)
    }

    const handleClose = () => {
      setDrawerVisibility(false)
      onClose && onClose()
    }

    return (
      <>
        {React.cloneElement(children, {
          onClick
        })}
        <Tray
          open={isDrawerVisible}
          onClose={handleClose}
          className={cn('resp-tooltip', overlayClassName)}>
          {content}
        </Tray>
      </>
    )
  }

  return (
    <Tooltip
      title={content}
      onOpenChange={onVisibleChange}
      overlayClassName={cn('resp-tooltip', overlayClassName)}
      placement={placement}
      destroyTooltipOnHide
      trigger={trigger}
      align={align}
      getPopupContainer={getPopupContainer}
      {...rest}>
      {children}
    </Tooltip>
  )
}

export default ResponsiveTooltip

export class ResponsiveTooltipee extends React.PureComponent<Props> {
  render() {
    const {
      align,
      trigger,
      onClose,
      children,
      content,
      footer,
      placement,
      getPopupContainer,
      overlayClassName,
      onVisibleChange,
      ...rest
    } = this.props

    if (isMob()) {
      return (
        <Drawer
          title={content}
          placement={'bottom'}
          onClose={onClose}
          className="resp-tooltip"
          height={document.body.clientHeight}
          footer={footer}
          {...rest}>
          {children}
        </Drawer>
      )
    }

    return (
      <Tooltip
        title={content}
        onOpenChange={onVisibleChange}
        overlayClassName={cn('resp-tooltip', overlayClassName)}
        placement={placement}
        destroyTooltipOnHide
        trigger={trigger}
        align={align}
        getPopupContainer={getPopupContainer}
        {...rest}>
        {children}
      </Tooltip>
    )
  }
}
