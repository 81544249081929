import {ColumnType} from '../types/ColumnType'

const getById = (table, columnId) => {
  return table.columns.find(c => c._id === columnId)
}

// Для лукапа - вернем его колоную. Остальные по дэфолту
const getUIColumnType = (column) => {
  if (!column) {
    return
  }

  const foreignType = column.params && column.params.foreignType
  return foreignType || column.type
}

const isColumnTemplate = (str) => {
  return /^{c[^}]+}$/.test(str)
}

const isColumnTypeFactory = (types) => {
  return (column) => {
    if (types.includes(column.type)) {
      return true
    }

    const foreignType = column.params && column.params.foreignType

    return column.type === ColumnType.LOOKUP && types.includes(foreignType)
  }
}

// Это для учета LOOKUP
const isUserColumn = isColumnTypeFactory([ColumnType.USER, ColumnType.CREATOR, ColumnType.MODIFIED_BY])
const isSelectColumn = isColumnTypeFactory([ColumnType.SINGLE_SELECT, ColumnType.MULTIPLE_SELECT])
const isDateColumn = isColumnTypeFactory([ColumnType.DATE, ColumnType.CREATION_DATE, ColumnType.MODIFIED_ON])
const isSpecialColumn = isColumnTypeFactory([ColumnType.CREATOR, ColumnType.MODIFIED_BY, ColumnType.CREATION_DATE, ColumnType.MODIFIED_ON])
const isLinkColumn = isColumnTypeFactory([ColumnType.LINK_TO_RECORD])
const isComputedColumn = isColumnTypeFactory([ColumnType.LOOKUP, ColumnType.ROLLUP, ColumnType.RECORD_INDEX])
const isActionColumn = isColumnTypeFactory([ColumnType.ACTION])
const isStringColumn = isColumnTypeFactory([ColumnType.TEXT, ColumnType.LONG_TEXT])
const isNumberColumn = isColumnTypeFactory([ColumnType.NUMBER])
const isTextColumn = isColumnTypeFactory([ColumnType.TEXT, ColumnType.LONG_TEXT])

const isSpecialColumnAndNotLookup = (column) => {
  return [ColumnType.CREATOR, ColumnType.MODIFIED_BY, ColumnType.CREATION_DATE, ColumnType.MODIFIED_ON].includes(column.type)
}

// Но иногда этого не нужно
const isLinkColumnType = (type) => [ColumnType.LINK_TO_RECORD].includes(type)

const isAllowedLookupColumnType = type => {
  return ![ColumnType.FORMULA].includes(type)
}


const allowedColumnForLinkTypes = [
  ColumnType.TEXT,
  ColumnType.LONG_TEXT,
  ColumnType.DATE,
  ColumnType.EMAIL,
  ColumnType.PHONE,
  ColumnType.LINK,
  ColumnType.NUMBER,
  ColumnType.LINK_TO_RECORD,
  ColumnType.SINGLE_SELECT,
  ColumnType.MULTIPLE_SELECT
]


const getAllowedColumnForLink = table => {
  return table.columns.find(column => allowedColumnForLinkTypes.includes(column.type))
}

export {isUserColumn}
export {isDateColumn}
export {isSpecialColumn}
export {isLinkColumn}
export {isLinkColumnType}
export {isSpecialColumnAndNotLookup}
export {isComputedColumn}
export {isColumnTemplate}
export {isStringColumn}
export {isActionColumn}
export {isSelectColumn}
export {isNumberColumn}
export {isTextColumn}
export {isAllowedLookupColumnType}
export {getById}
export {getUIColumnType}
export {getAllowedColumnForLink}
