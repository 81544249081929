import React from 'react'
import {BaseView} from '../../../../../../types/BaseView'
import {BaseTable} from "../../../../../../types/BaseTable";
import Select from "antd/lib/select";
import get from 'lodash.get'
import {ActionType} from "../../../../../../store/CommonEffects";
import {TableActions} from "../../../../../../store/actions/TableActions";
import NEWIcon from "../../../../../../components/icon/NEWIcon";
import {translateRowHeight} from "../../../../../../utils/translations";

interface Props {
  view: BaseView
  table: BaseTable
  onAction: (action: ActionType, payload: any) => void
}

export default React.memo(function CustomizeHeight(props: Props) {
  const {view, onAction} = props
  const viewRowHeight: number = get(view, 'params.rowHeight', 32)

  const handleChange = (rowHeight: number) => {
    onAction(TableActions.UPDATE_VIEW, {
      params: {
        ...(view.params || {}),
        rowHeight
      }
    })
  }

  return (
    <div className={'view-settings-item height'}>
      <div className="c-selector">
        <Select
          getPopupContainer={trigger => trigger.parentNode}
          value={viewRowHeight}
          showArrow={false}
          onChange={handleChange}
          className={'column-selector'}
          dropdownMatchSelectWidth={false}
        >
          <Select.Option value={32}>
            <NEWIcon type={'short'}/> {translateRowHeight(32)}
          </Select.Option>
          <Select.Option value={56}>
            <NEWIcon type={'medium'}/> {translateRowHeight(56)}
          </Select.Option>
          <Select.Option value={88}>
            <NEWIcon type={'tall'}/> {translateRowHeight(88)}
          </Select.Option>
          <Select.Option value={128}>
            <NEWIcon type={'extra-tall'}/> {translateRowHeight(128)}
          </Select.Option>
        </Select>
      </div>
    </div>
  )
})
