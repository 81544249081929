import React from 'react'
import {GridRendererProps} from "../GridRendererProps";
import BaseSingleSelectRenderer from "./BaseSingleSelectRenderer";

export default React.memo(function GridSingleSelectRenderer(props: GridRendererProps) {
  const {onCommit, isActive, row, column} = props

  return (
    <BaseSingleSelectRenderer
      value={row[column._id]}
      readonly={!isActive}
      params={column.params}
      width={column.width || 150}
      options={column.params.options || []}
      onChange={(val) => onCommit(val)}
    />
  )
})
