import React, { memo } from 'react'
import {
  ViewDetailsBlock,
  ViewDetailsBlockType,
} from '../../../types/ViewDetails'
import DetailsPanelBlock from './panel/DetailsPanelBlock'
import DetailsTableBlock from './table/DetailsTableBlock'
import DetailsFieldsBlock from './fields/DetailsFieldsBlock'

const BlockFactory: React.FC<{ block?: ViewDetailsBlock }> = ({
  block,
}): any => {
  let Component

  switch (block.type) {
    case ViewDetailsBlockType.TABLE:
      Component = DetailsTableBlock
      break

    case ViewDetailsBlockType.FIELDS:
      Component = DetailsFieldsBlock
      break

    default:
      Component = DetailsPanelBlock
      break
  }

  return <Component block={block} />
}

export default memo(BlockFactory)
