import { DOMAttributes, useLayoutEffect, useState } from 'react'
import { mergeProps } from '../utils/mergeProps'
import { useOverlay } from './useOverlay'
import { useOverlayPosition } from './useOverlayPosition'

export interface PopoverProp {
  open?: boolean

  onClose: () => void

  /**
   * The ref for the element which the popover positions itself with respect to.
   */
  triggerRef: any
  /**
   * The ref for the popover element.
   */
  popoverRef: any
  /**
   * Whether the popover is non-modal, i.e. elements outside the popover may be
   * interacted with by assistive technologies.
   *
   * Most popovers should not use this option as it may negatively impact the screen
   * reader experience. Only use with components such as combobox, which are designed
   * to handle this situation carefully.
   */
  isNonModal?: boolean
  /**
   * Whether pressing the escape key to close the popover should be disabled.
   * @default false
   */
  isKeyboardDismissDisabled?: boolean
}

export type Axis = 'top' | 'bottom' | 'left' | 'right'
export type PlacementAxis = Axis | 'center'

export interface PopoverAria<T = any> {
  /** Props for the popover element. */
  popoverProps: DOMAttributes<T>
  /** Props to apply to the underlay element, if any. */
  underlayProps: DOMAttributes<T>
  /** Placement of the popover with respect to the trigger. */
  placement: PlacementAxis
}

export function usePopover(props: PopoverProp): PopoverAria {
  const {
    open,
    onClose,
    triggerRef,
    popoverRef,
    isNonModal,
    isKeyboardDismissDisabled,
    ...otherProps
  } = props

  const { overlayProps, underlayProps } = useOverlay(
    {
      open,
      onClose,
      isKeyboardDismissDisabled,
      shouldCloseOnBlur: true,
      shouldCloseOnInteractOutside(el) {
        // Не прячем поповер, ЕСЛИ был фокус ГДЕ-ТО внутри (типа внутри триггера рисуем input)
        if (triggerRef && triggerRef.current) {
          return !triggerRef.current.contains(el)
        }

        return true
      },
    },
    popoverRef,
  )

  const { overlayProps: positionProps, placement } = useOverlayPosition({
    ...otherProps,
    targetRef: triggerRef,
    overlayRef: popoverRef,
    open,
  })

  // Delay preventing scroll until popover is positioned to avoid extra scroll padding.
  // This requires a layout effect so that positioning has been committed to the DOM
  // by the time usePreventScroll measures the element.
  const [isPositioned, setPositioned] = useState(false)

  useLayoutEffect(() => {
    if (!isNonModal && placement) {
      setPositioned(true)
    }
  }, [isNonModal, placement])

  // usePreventScroll({
  //   isDisabled: isNonModal || !isPositioned
  // });

  // useLayoutEffect(() => {
  //   if (open && !isNonModal && popoverRef.current) {
  //     return ariaHideOutside([popoverRef.current]);
  //   }
  // }, [isNonModal, open, popoverRef]);

  return {
    popoverProps: mergeProps(overlayProps, positionProps),
    underlayProps,
    placement,
  }
}
