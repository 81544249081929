export enum BaseActions {
  REMOVE = 'b0',
  UPDATE = 'b1',
  SHARE = 'b3',
  FETCH = 'b4',
  PERMISSIONS_SETTING = 'b5', // do not do call ...
  UPDATE_PERMISSIONS = 'b6',
  CREATE_TABLE = 'b7',
  REMOVE_TABLE = 'b8',
  FETCH_TABLES = 'b9',
  DUPLICATE = 'b10',
  MOVE = 'b11',
}