import { SocialTypes } from "../types/SocialNetworks"

const urls = [
  'https://instagram.com/',
  'https://facebook.com/',
  'https://twitter.com/'
]

export const SocialLinks = {
  [SocialTypes.INSTAGRAM]: urls[0],
  [SocialTypes.FACEBOOK]: urls[1],
  [SocialTypes.TWITTER]: urls[2],
}

const removeSlash = value => value
  .replace(/^\//, '')
  .replace(/\/$/, '')

export function processSocialValue(value: string): string {
  try {
    const url = new URL(value)

    return removeSlash(url.pathname)
  } catch (e) {
    return value.replace(/[^\w+.-_/]/g, '')
  }
}
