import React, { useCallback, useState } from "react"
import {getColorClass} from "../../utils/colors";
import Tooltip from "antd/lib/tooltip";
import ColorPalette from "./ColorPalette";

import './style.scss'
import NEWIcon from "../icon/NEWIcon";

interface Props {
  color?: string
  onSelect?: (color: string) => void
}

export default React.memo(function ColorPicker(props: Props) {
  const color = getColorClass(props.color)
  const [visible, setVisible] = useState(false)

  const onSelect = (color: string) => {
    if (props.onSelect) {
      props.onSelect(color)
    }

    setVisible(false)
  }

  const title = (
    <ColorPalette
      color={props.color}
      onSelect={onSelect}
    />
  )

  const onOpenChange = useCallback((v: boolean) => {
    setVisible(v)
  }, [])

  return (
    <div className={'color-picker'}>
      <Tooltip
        title={title}
        trigger={['click']}
        placement="bottomRight"
        destroyTooltipOnHide
        onOpenChange={onOpenChange}
        open={visible}
        getPopupContainer={triggerNode => triggerNode.parentElement}
      >
        <button className={color}>
          <NEWIcon type={'caret-down'}/>
        </button>
      </Tooltip>
    </div>
  )
})
