import React, { useMemo } from 'react'
import { RendererClassNames } from '../definitions'
import { SimpleRendererFactory } from '../factory'
import { asArray } from '../../../general/utils/array'
import { SimpleRendererProps } from '../types/SimpleRendererProps'

const LookupRenderer: React.FC<SimpleRendererProps> = ({ value, column, params }) => {
  const renderColumn = useMemo(() => {
    return {
      ...column,
      type: column.params.foreignType,
      params,
    }
  }, [column])

  return (
    <div className={RendererClassNames.LOOKUP}>
      {asArray(value)
        .filter(v => !!v.value)
        .map(({ _id, value }) => (
          <SimpleRendererFactory
            key={_id}
            column={renderColumn}
            value={value}
            params={renderColumn.params}
          />
        ))}
    </div>
  )
}

export default React.memo(LookupRenderer)
