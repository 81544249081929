import React from 'react'
import DatePicker from 'react-datepicker'
import { ViewDateField } from '../../../../../types/BaseView'
import { GridColumn } from '../../../../../components/grid/types/GridColumn'
import ColumnNameWithIcon from '../../../../../components/columnHelpers/ColumnNameWithIcon'
import { EventDate } from '../../../../../components/calendar/NEW__types/Event'
import { dayjs } from '../../../../../general/utils/date'
import TimeSelector from './TimeSelector'
import { CalendarViewType } from '../../../../../components/calendar/NEW__types/Calendar'
import { RangeType } from '../../../../../utils/calendar'

import './style.scss'
import { isDateColumn } from '../../../../../general/utils/column'

interface Props {
  start: Date
  startColumn: GridColumn
  end?: Date
  endColumn?: GridColumn
  view: CalendarViewType
  onChange: (values: EventDate) => void
}

export default class DateTimeRangePicker extends React.PureComponent<Props> {
  getMaxMinTime = () => {
    const maxTime = dayjs(this.props.start)
    const minTime = dayjs(this.props.start)

    maxTime.hour(23)
    maxTime.minute(59)

    return {
      minTime,
      maxTime,
    }
  }

  handleChange = (type: RangeType, date: Date) => {
    const start = dayjs(this.props.start)
    const end = dayjs(this.props.end)
    const hasEnd: boolean = !!this.props.end
    const result: Partial<ViewDateField<any>> = {}

    switch (type) {
      case RangeType.START_DATE: {
        result.start = start
          .date(date.getDate())
          .month(date.getMonth())
          .year(date.getFullYear())
          .toDate()

        if (hasEnd) {
          result.end = end
            .date(date.getDate())
            .month(date.getMonth())
            .year(date.getFullYear())
            .toDate()
        }

        break
      }

      case RangeType.START_TIME: {
        const resultStart = start
          .hour(date.getHours())
          .minute(date.getMinutes())

        result.start = resultStart.toDate()

        if (hasEnd) {
          result.end = end
            .add(resultStart.diff(start, 'minute'), 'minute')
            .toDate()
        }

        break
      }

      case RangeType.END_DATE: {
        result.start = start
          .date(date.getDate())
          .month(date.getMonth())
          .year(date.getFullYear())
          .toDate()

        result.end = end
          .date(date.getDate())
          .month(date.getMonth())
          .year(date.getFullYear())
          .toDate()

        break
      }

      case RangeType.END_TIME: {
        result.end = end
          .hour(date.getHours())
          .minute(date.getMinutes())
          .toDate()

        break
      }

      // eslint-disable-next-line
      default:
        return
    }

    this.props.onChange(result as EventDate)
  }

  handleChangeStartTime = t => this.handleChange(RangeType.START_TIME, t)
  handleChangeEndTime = t => this.handleChange(RangeType.END_TIME, t)

  render() {
    const { start, startColumn, end, endColumn, view } = this.props
    const hasEnd: boolean = !!(end || endColumn)
    const isEndEditable: boolean = hasEnd && isDateColumn(endColumn)

    return (
      <div className={'date-range'}>
        <div className="date-range__interval start">
          <ColumnNameWithIcon column={startColumn} />

          <div className="date-range-ctrl">
            <div className="date">
              <DatePicker
                className={'date-range__date'}
                locale="ru"
                showPopperArrow={false}
                placeholder={'dd.MM.yyyy'}
                dateFormat={'d MMMM yyyy'}
                selected={start}
                onChange={v => this.handleChange(RangeType.START_DATE, v)}
              />
            </div>

            <TimeSelector
              date={start}
              intervals={60}
              view={view}
              onChange={this.handleChangeStartTime}
            />
          </div>
        </div>

        {hasEnd && (
          <div className="date-range__interval end">
            <ColumnNameWithIcon column={endColumn} />

            <div className="date-range-ctrl">
              <TimeSelector
                endFrom={start}
                date={end}
                intervals={60}
                view={view}
                disabled={!isEndEditable}
                onChange={this.handleChangeEndTime}
              />

              <div className="date">
                <DatePicker
                  className={'date-range__date'}
                  locale="ru"
                  placeholder={'dd.MM.yyyy'}
                  showPopperArrow={false}
                  dateFormat={'d MMMM yyyy'}
                  selected={end}
                  disabled={!isEndEditable}
                  onChange={v => this.handleChange(RangeType.END_DATE, v)}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}
