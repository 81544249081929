import {
  GridColumn,
  GridColumnAction,
  GridColumnActionItem,
} from '../components/grid/types/GridColumn'
import { columnsFactory } from '../components/grid/columns/ColumnsFactory'
import { ColumnType } from '../components/grid/types/ColumnType'
import {
  BaseView,
  BaseViewColumnParams,
  SortOrderType,
  ViewGroupedRows,
  ViewGroups,
  ViewLike,
} from '../types/BaseView'
import { GridRow } from '../components/grid/types/GridRow'
import { BaseTable } from '../types/BaseTable'
import { isArray, isSpecialColumn, isString } from './is'
import { Roles } from '../components/roles/Roles'
import { isRoleAllowed, UIRoleAction } from '../components/roles/RoleGateway'
import { prepareColumns } from './column'
import { getRowDefaultValues } from './get'
import { EyzyUser } from '../types/EyzyUser'
import { Filter } from '../general/filters/Filter'
import {
  getSortValue,
  getSpecificValue,
  getValue,
  applyFormula,
} from '../general/utils/value'
import { BasicOperators } from '../types/BasicOperators'
import { getById } from '../general/utils/column'
import { dayjs } from '../general/utils/date'
import { applySort } from './sort'
import orderBy from 'lodash.orderby'
import { gridRendererFactory } from '../components/_renderers/definitions'
import { GridGroup } from '../components/iorvo-grid/types/IorvoGridProps'
import { hasViewGroup } from './view'
import { arrToObj } from './array'

export function prepareGridColumns(
  table: BaseTable,
  view: BaseView,
  role: Roles,
): GridColumn[] {
  return prepareColumns(table, view).map(column => ({
    ...getViewColumn(column, view),
    resize: isRoleAllowed(UIRoleAction.TABLE_EDIT_VIEW, role),
    renderer: gridRendererFactory(column),
  }))
}

export function buildGridColumns(
  table: BaseTable,
  view: ViewLike | BaseView,
  userRole: Roles,
): GridColumn[] {
  return prepareColumns(table, view).map((column, i) => {
    const resultColumn: GridColumn = getViewColumn(
      {
        ...getColumnRenderer(column, userRole),
        table,
      },
      view,
    )

    return resultColumn
  })
}

export function getColumnRenderer(
  column: GridColumn,
  userRole: Roles,
): GridColumn {
  const type = column.type || ColumnType.TEXT
  const field = column._id
  const columnOptions: GridColumn = {
    ...column,
    field,
    ...columnsFactory(type),
  }

  if (!isRoleAllowed(UIRoleAction.TABLE_RESIZE_COLUMN, userRole)) {
    columnOptions.resizer = false
  }

  return columnOptions
}

export function getViewColumn(
  column: GridColumn,
  view: BaseView | ViewLike,
): GridColumn {
  const viewColumnsParams: BaseViewColumnParams | undefined = view.columnsParams

  if (!viewColumnsParams) {
    return column
  }

  const params = {
    ...(column.params || {}),
    ...viewColumnsParams[column._id],
  }

  const { width, hidden, ...restParams } = params

  return {
    ...column,
    width,
    hidden,
    params: restParams,
  }
}

export function getRowValue(
  row: GridRow,
  column: GridColumn,
  table?: BaseTable,
) {
  if (isSpecialColumn(column.type)) {
    return (row || {})[column.type]
  }

  if (column.type === ColumnType.FORMULA && table) {
    return applyFormula(column.params.formula, {
      row,
      column,
      table,
    })
  }

  return (row || {})[column._id]
}

const getGroupKey = (value: any, ignoreListOrder?: boolean) => {
  if (!value) {
    return ''
  }

  if (isArray(value)) {
    const isComputed: boolean = value.some(i => !!i.value)

    if (isComputed) {
      const values: string[] = []

      for (let i = 0; i < value.length; i++) {
        values.push(...value[i].value)
      }

      values.sort((a: any, b: any) => {
        return a > b ? 1 : -1
      })

      return values.join(', ')
    }

    if (ignoreListOrder) {
      value.sort((a, b) => ('' + a).localeCompare('' + b))
    }

    return value.join(', ')
  }

  return value
}

function nestGroupsBy(arr, properties, groupBy) {
  properties = Array.from(properties)

  if (properties.length === 1) {
    return groupBy(arr, properties[0])
  }

  const property = properties.shift()
  const grouped = groupBy(arr, property)

  for (let key in grouped) {
    grouped[key] = nestGroupsBy(grouped[key], Array.from(properties), groupBy)
  }

  return grouped
}

export function makeViewGroups(
  table: BaseTable,
  view: ViewLike,
  rows: GridRow[],
  isSortingNeeded: boolean = false,
): ViewGroupedRows[] {
  let index: number = 0

  const items = isSortingNeeded ? applySort(rows, table, view.sort) : rows

  return nestGroupsBy(items, view.groups, (arr, group) => {
    const column = getById(table, group.columnId)
    const result = (arr.rows || arr).reduce((res, row) => {
      let groupValue = getRowValue(row, column, table)
      let groupKey = getGroupKey(groupValue)

      if (group.clarification) {
        groupKey = clarifyGroupValue(groupKey, group.clarification)
      }

      if (!res[groupKey]) {
        res[groupKey] = {
          column,
          rows: [],
          value: groupValue,
          sortValue: getSortValue(table, column, row),
          group,
          index: index++,
        }
      }

      res[groupKey].rows.push(row)

      return res
    }, {})

    return orderBy(Object.values(result), g => g.sortValue || 0, [group.sort])
  })
}

export function createGridGroups(
  table: BaseTable,
  view: BaseView | ViewLike,
  rows: GridRow[],
  payload: any,
  isSortingNeeded?: boolean
): GridGroup[] {
  if (!hasViewGroup(view, table)) {
    return
  }

  const items = isSortingNeeded ? applySort(rows, table, view.sort) : rows
  const columns = arrToObj(table.columns, '_id', false)
  const groups = view.groups.filter(g => g.columnId in columns)

  return nestGroupsBy(items, groups, (arr, group) => {
    const column = columns[group.columnId]

    // Это уже подгруппа
    if (arr.rows) {
      const groups = arr.rows.reduce((res, rowIndex) => {
        const row = items[rowIndex]

        let groupValue = getRowValue(row, column, table)
        let groupKey = getGroupKey(groupValue, group.ignoreListOrder)

        if (group.clarification) {
          groupKey = groupValue = clarifyGroupValue(groupKey, group.clarification)
        }

        if (!res[groupKey]) {
          res[groupKey] = {
            rows: [],
            key: `${arr.key}.${groupKey}`,
            group: {
              ...group,
              sortValue: getSortValue(table, column, row, payload),
              value: groupValue,
              column,
            },
          }
        }

        res[groupKey].rows.push(rowIndex)

        return res
      }, {})

      return {
        ...arr,
        groups: orderBy(Object.values(groups), g => g.sortValue || 0, [
          group.sort,
        ]),
      }
    }

    const result = (arr.rows || arr).reduce((res, row, index) => {
      let groupValue = getRowValue(row, column, table)
      let groupKey = getGroupKey(groupValue, group.ignoreListOrder)

      if (group.clarification) {
        groupKey = groupValue = clarifyGroupValue(groupKey, group.clarification)
      }

      if (!res[groupKey]) {
        res[groupKey] = {
          rows: [],
          key: groupKey,
          group: {
            ...group,
            sortValue: getSortValue(table, column, row, payload),
            value: groupValue,
            column,
          },
        }
      }

      res[groupKey].rows.push(index)

      return res
    }, {})

    return orderBy(Object.values(result), g => g.group.sortValue || 0, [group.sort])
  })
}

export function groupRows(
  table: BaseTable,
  column: GridColumn,
  rows: GridRow[],
  group: ViewGroups,
  view?: BaseView,
): any {
  const result: Map<any, { rows: number[]; val: any }> = new Map([
    [null, { rows: [], val: null }],
  ])

  for (let i = 0; i < rows.length; i++) {
    let realVal = getRowValue(rows[i], column, table)
    let groupKey = getGroupKey(realVal)

    if (group.clarification) {
      groupKey = clarifyGroupValue(groupKey, group.clarification)
    }

    if (!result.has(groupKey)) {
      result.set(groupKey, {
        rows: [i],
        val: realVal,
      })
    } else {
      const val = result.get(groupKey)!
      const obj = {
        rows: val.rows.concat(i),
        val: val.val,
      }

      result.set(groupKey, obj)
    }
  }

  const converted = Array.from(result).filter(items => {
    return !(items[0] === null && items[1].rows.length === 0)
  })

  if (group.clarification) {
    sortByClarification(converted, group.clarification)
  }

  const side = group.sort === SortOrderType.DESC ? -1 : 1
  const sorted = converted.sort((a, b) => {
    let item0: any = a[1].val
    let item1: any = b[1].val

    if (isString(item0)) {
      try {
        item0 = item0.toLowerCase()
        item1 = item1.toLowerCase()
      } catch (e) {}
    }

    if (item0 === item1) {
      return 0
    }

    return (item0 > item1 ? 1 : -1) * side
  })

  return sorted.map(i => ({
    value: i[0],
    rows: i[1].rows,
  }))
}

function sortByClarification(items, clarification) {
  if ('month' === clarification) {
    items.sort((i0, i1) => {
      const date0 = dayjs(i0[1].val)
      const date1 = dayjs(i1[1].val)

      if (!date0.isValid()) {
        return -1
      }

      if (!date1.isValid()) {
        return 1
      }

      return +date0 - +date1
    })
  }
}

function clarifyGroupValue(value: any, clarification): any {
  let format

  switch (clarification) {
    case 'month':
      format = 'MMMM YYYY'
      break
    case 'year':
      format = 'YYYY'
      break
    case 'day':
      format = 'LL'
      break
  }
  const day = dayjs(value)

  if (!day.isValid()) {
    return value
  }

  return day.format(format)
}

export function createRowWithDefaults(
  table: BaseTable,
  view: BaseView,
): GridRow {
  return getRowDefaultValues(table, view.columnsParams || {})
}

export function getVisibleAction(
  actions: GridColumnAction[],
  user: EyzyUser,
  table: BaseTable,
  row: GridRow,
): GridColumnAction | undefined {
  return actions.find(action => {
    if (!action.visibility) {
      return true
    }

    const f = new Filter({
      table,
      user,
      filter: action.visibility,
    })

    return f.isMatch(row)
  })
}

interface ActionPayload {
  column: GridColumn
  table: BaseTable
  user: EyzyUser
}

export function compileActionValue(
  action: GridColumnActionItem,
  row: GridRow,
  payload: ActionPayload,
) {
  const value: any = getSpecificValue(action.value, row, payload)

  if (value !== undefined) {
    return value
  }

  // ts-ignore
  switch (action.type) {
    case BasicOperators.INCREMENT: {
      if (ColumnType.NUMBER !== payload.column.type) {
        return
      }

      const val = parseInt(getValue(payload.table, payload.column, row))

      return isFinite(val) ? val + 1 : 1
    }

    case BasicOperators.DECREMENT: {
      if (ColumnType.NUMBER !== payload.column.type) {
        return
      }

      const val = parseInt(getValue(payload.table, payload.column, row))

      if (isFinite(val)) {
        return val - 1
      }
    }
  }
}
