import React, { useState } from 'react'
import EventDetails from './EventDetails'
import { ViewDateField } from '../../../../../types/BaseView'
import { Button } from '../../../../../components/irv-ui'
import Icon from '../../../../../components/icon/Icon'
import ReactDOM from 'react-dom'
import { cn } from '../../../../../utils/classname'
import { CalendarViewType } from '../../../../../components/calendar/NEW__types/Calendar'
// import {isNewEvent} from "../../../../../utils/calendar";
import { Event } from '../../../../../types/Calendar'

interface Props {
  event?: Event
  dateFields: ViewDateField
  view: CalendarViewType
  onChange: (value: any) => void
  onRemove: (id: string) => void
  onSave: (payload: any) => void
}

export default function EventDetailsCard(props: Props) {
  const { dateFields, view, onChange, event, onSave, onRemove } = props
  const [updates, setUpdates] = useState(null)
  const rowId: string | null = event ? event.id : null
  // const isNew: boolean = event && isNewEvent(event)

  let cardBounds: any

  const handleRemove = () => {
    if (onRemove && event) {
      onRemove(event.id)
    }
  }

  const handleSave = () => {
    onSave(updates)
    setUpdates(null)
  }

  const handleChange = (value: any) => {
    const lastUpdates = {
      ...updates,
      ...value,
    }

    onChange(value)
    setUpdates(lastUpdates)

    // if (isSaving && !isNew) {
    //   onSave(lastUpdates)
    //   setUpdates(null)
    // } else {
    //   onChange(value)
    //   setUpdates(lastUpdates)
    // }
  }

  if (event) {
    let eventContainer = document.body.querySelector(`[data-id="${event.id}"]`)

    if (!eventContainer) {
      // ебический хук. Для нового если поменять повторения - ломается
      eventContainer = document.body.querySelector(`[data-id="${event.id}.0"]`)
    }

    if (!eventContainer) {
      return null
    }

    const eventBounds = eventContainer.getBoundingClientRect()
    const offset = 15

    cardBounds = {
      left: eventBounds.right + offset,
      top: Math.max(eventBounds.top - offset, offset),
    }

    if (cardBounds.left + 510 + offset > window.innerWidth) {
      cardBounds.left = Math.max(eventBounds.left - 510 - offset, offset)
    }

    if (cardBounds.top + 300 > window.innerHeight) {
      delete cardBounds.top
      cardBounds.bottom = offset
    }
  }

  const cardClassName: string = cn('event-card', event && 'o')
  const cardContent: any = !event ? null : (
    <>
      <EventDetails
        event={event!}
        dateFields={dateFields}
        view={view}
        onChange={handleChange}
      />

      <div className={'event-card-footer'}>
        <Button variant={'primary'} className={'save-btn'} onClick={handleSave}>
          Сохранить
        </Button>
        ,
        <Button
          className={'cancel-btn'}
          variant={'minimal'}
          onClick={_ => onRemove('-1')}>
          Отмена
        </Button>
        {rowId && (
          <Button
            variant={'minimal'}
            className={'delete-btn'}
            onClick={handleRemove}>
            <Icon type={'delete'} />
            Удалить
          </Button>
        )}
      </div>
    </>
  )

  return ReactDOM.createPortal(
    <div className={cardClassName} style={cardBounds}>
      {cardContent}
    </div>,
    document.body,
  )
}
