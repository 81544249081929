import React, { useCallback, useMemo, useState } from 'react'
import { FormRendererProps } from '../types/FormRenderer'
import { Input } from '../../irv-ui'
import { isEmail } from '../../../utils/is'
import BtnWithIcon from '../../btn/BtnWithIcon'
import NEWIcon from '../../icon/NEWIcon'

const FormEmailRenderer: React.FC<FormRendererProps> = ({
  value,
  readonly,
  column,
  className,
  onCommit,
  onChange,
}) => {
  const handleBlur = useCallback(
    e => {
      if (onCommit) {
        onCommit(e.target.value)
      }
    },
    [onCommit],
  )

  if (readonly) {
    if (!value) {
      return null
    }

    return (
      <div className={className}>
        <a className={'link'} title={value} href={`mailto:${value}`}>
          {value}
        </a>
      </div>
    )
  }

  return (
    <>
      <Input
        id={column._id}
        className={className}
        value={value}
        onBlur={handleBlur}
        onChange={onChange}
      />

      {value && (
        <a className={'suffix'} title={value} href={`mailto:${value}`}>
          <BtnWithIcon icon={'at'} />
        </a>
      )}
    </>
  )
}

export default FormEmailRenderer
