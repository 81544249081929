import React from 'react'
import { Button } from '../../components/irv-ui'
import NEWIcon from '../icon/NEWIcon'
import { cn } from '../../utils/classname'
import { IrvBaseBtnProps } from "../irv-ui/components/button/types/IrvButtonProps"

type Props = IrvBaseBtnProps & {
  icon?: string
  cn?: string
  min?: boolean
}

const BtnWithIcon: React.FC<Props> = ({
  onClick,
  children,
  icon,
  min,
  focusRing,
  cn: classNames,
}) => {
  const variant: string = min ? 'minimal' : ''
  const className: string = cn('bti', classNames)

  return (
    <Button
      className={className}
      variant={variant}
      focusRing={focusRing}
      onClick={onClick}>
      {icon && <NEWIcon type={icon} />}

      {children && <span>{children}</span>}
    </Button>
  )
}

export default React.memo(BtnWithIcon)
