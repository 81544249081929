import React from "react"
import { Switch } from "../../../../../../components/irv-ui"
import { BaseView } from "../../../../../../types/BaseView"
import { BaseTable } from "../../../../../../types/BaseTable"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { getColumnIcon } from "../../../../../../components/grid/columns/ColumnsFactory"
import NEWIcon from "../../../../../../components/icon/NEWIcon"
import BtnWithIcon from "../../../../../../components/btn/BtnWithIcon"
import { ActionType } from "../../../../../../store/CommonEffects"
import { isVisibleColumn, orderColumns } from "../../../../../../utils/column"
import { reorder } from "../../../../../../utils/array"
import { TableActions } from "../../../../../../store/actions/TableActions"
import { GridColumn } from "../../../../../../components/grid/types/GridColumn"
import { ViewSettingsUIState, ViewSettingsUIStateItem } from "../../../../types/UIState"
import { stopPropagation } from "../../../../../../utils/navigation"

interface Props {
  view: BaseView
  table: BaseTable
  onAction: (action: ActionType, payload: any) => Promise<{ column: GridColumn }>
  onPageChange: (state: ViewSettingsUIState) => void
}

export default React.memo(function CustomizeFields(props: Props) {
  const { view, table, onAction, onPageChange } = props
  const columns: GridColumn[] = orderColumns(table.columns, view.order)

  const handleDragEnd = (result: any) => {
    if (!result.destination) {
      return
    }

    const currentOrder = orderColumns(table.columns, view.order).map(column => column._id)
    const reOrdered = reorder(
      currentOrder,
      result.source.index,
      result.destination.index,
    )

    const viewOrder = Object.assign({}, view.order)
    const newViewOrder = {}

    reOrdered.forEach((columnId, index) => {
      if (columnId in viewOrder || columnId === result.draggableId) {
        newViewOrder[columnId] = index
      }
    })

    onAction(TableActions.UPDATE_VIEW, {
      order: newViewOrder,
    })
  }

  const handleChangeVisibility = (columnId: string, hidden: boolean) => {
    onAction(TableActions.UPDATE_VIEW_COLUMN, {
      columnId,
      params: { hidden },
    })
  }

  const editColumn = (column: GridColumn) => () => {
    onPageChange({
      type: ViewSettingsUIStateItem.COLUMN,
      payload: {
        columnId: column._id,
      },
    })
  }

  const addField = () => {
    onAction(TableActions.CREATE_COLUMN, { name: "Новая колонка" }).then(({ column }) => {
      onPageChange({
        type: ViewSettingsUIStateItem.COLUMN,
        payload: {
          columnId: column._id,
        },
      })
    })
  }

  return (
    <div className={"view-settings-item fields"}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <ul
              {...provided.droppableProps}
              className={"sbr"}
              ref={provided.innerRef}
            >
              {columns.map((column, index) => (
                <Draggable key={column._id} draggableId={column._id} index={index}>
                  {(provided) => (
                    <li
                      key={column._id}
                      ref={provided.innerRef}
                      className={"view-field c-item"}
                      onClick={editColumn(column)}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div className={"column-def"}>
                        <NEWIcon type={getColumnIcon(column.type)} />
                        <NEWIcon type={"drag"} />
                        <span className="text truncate">{column.name}</span>
                      </div>

                      <div onClick={stopPropagation} className={"df"}>
                        <Switch size="sm"
                                defaultChecked={isVisibleColumn(column, view)}
                                onChange={(hidden: boolean) => handleChangeVisibility(column._id, !hidden)} />
                      </div>

                      <div className={"actions"}>
                        <BtnWithIcon min icon={"arrow-right"} />
                      </div>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>

      <BtnWithIcon icon={"b-add-circle"} onClick={addField}>
        Добавить поле
      </BtnWithIcon>
    </div>
  )
})
