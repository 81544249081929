import React from 'react'

interface Props {
  handler: () => void
}

const Caret: React.FC<Props> = ({ handler }) => {
  return (
    <button className={'irv-caret'} onClick={handler}>
      <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="24" height="24">
        <path d="M6.41,9H17.59a1,1,0,0,1,.7,1.71l-5.58,5.58a1,1,0,0,1-1.42,0L5.71,10.71A1,1,0,0,1,6.41,9Z"/>
      </svg>
    </button>
  )
}

export default React.memo(Caret)
