import React from 'react'
import {BaseTable} from "../../../types/BaseTable";
import Select from 'antd/es/select'
import {GridColumn} from "../../grid/types/GridColumn";
import ColumnNameWithIcon from "../ColumnNameWithIcon";
import {cn} from "../../../utils/classname";

import './style.scss'

interface Props {
  placeholder?: string
  hiddenColumns?: string[]
  table: BaseTable
  selectedItem?: string
  style?: any
  onChange: (columnId: string) => void
  showArrow?: boolean
  cn?: string
}

export default React.memo(function ColumnSelector(props: Props) {
  const {selectedItem, table, onChange, style, hiddenColumns, placeholder, showArrow} = props
  const columns: GridColumn[] = !hiddenColumns ? table.columns : table.columns.filter(c => !hiddenColumns.includes(c._id))
  const className: string = cn('column-selector', props.cn)

  return (
    <Select
      getPopupContainer={trigger => trigger.parentNode}
      value={selectedItem}
      onChange={onChange}
      style={style}
      showArrow={showArrow}
      placeholder={placeholder}
      className={className}
      dropdownMatchSelectWidth={false}
    >
      {columns.map((column) => (
        <Select.Option value={column._id} key={column._id}>
          <ColumnNameWithIcon column={column} />
        </Select.Option>
      ))}
    </Select>
  )
})
