import { TableActions } from '../actions/TableActions'
import { GridRow } from '../../components/grid/types/GridRow'
import { BaseActions } from '../actions/BaseActions'
import { AppActions } from '../actions/AppActions'

const initState: GridRow[] = []

export default function rows(state = initState, action) {
  const { result, payload } = action

  switch (action.type) {
    case AppActions.CLEAR_ROWS:
      return []

    case AppActions.INIT:
    case TableActions.FILTER_ROWS:
    case TableActions.FETCH:
    case BaseActions.FETCH:
      return payload.rows || state

    case TableActions.CREATE_ROW:
      if (result) {
        return state.concat(result)
      }

      return state

    case TableActions.UPDATE_COLUMN:
      if (result && result.rows) {
        return result.rows
      }

      return state

    case TableActions.DELETE_ROW:
      if (!action.result) {
        const deletedRows = action.payload.rowIds || []

        return state.filter(row => !deletedRows.includes(row._id))
      }

      return state

    case TableActions.UPDATE_CELL:
      if (result) {
        if (result === 'ok') {
          return state
        }

        return state.map(row => {
          if (row._id === result._id) {
            return result
          }

          return row
        })
      }

      return state.map(row => {
        if (row._id !== payload.rowId) {
          return row
        }

        return {
          ...row,
          [payload.cellId]: payload.value,
        }
      })

    case TableActions.UPDATE_ROW:
    case TableActions.CALL_ACTION:
      if (result) {
        if (result === 'ok') {
          return state.map(row =>
            row._id === payload.rowId ? { ...row, ...payload.data } : row,
          )
        } else {
          return state.map(row => (row._id === payload.rowId ? result : row))
        }
      }

      return state.map(row => {
        if (row._id !== payload.rowId) {
          return row
        }

        return {
          ...row,
          ...payload.data,
        }
      })
    default:
      return state
  }
}
