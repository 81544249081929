import React from 'react'
import {GridRendererProps} from "../GridRendererProps";
import {applyFormula} from "../../../general/utils/value";
import {GridColumnTagType} from "../../grid/types/GridColumn";
import {Tag} from "../../../components/irv-ui";
import { dayjs, DEFAULT_DATE_FORMAT } from "../../../general/utils/date"

export default React.memo(function GridFormulaRenderer(props: GridRendererProps) {
  const {row, column, isActive} = props

  if (isActive || !column.params) {
    return null
  }

  const formula = applyFormula(column.params.formula, {
    table: column.table, row, column
  })

  const formulaValue = formula instanceof Date ? dayjs(formula).format(DEFAULT_DATE_FORMAT) : formula

  if (column.params.viewAs === GridColumnTagType.TAG) {
    if (!formula && 0 !== formula) {
      return null
    }

    return (
      <div className={'re-value'}>
        <Tag>{formulaValue}</Tag>
      </div>
    )
  }

  return (
    <div className={'re-value'}>{formulaValue}</div>
  )
})
