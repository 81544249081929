export function reorder<T = any>(
  list: T[],
  startIndex: number,
  endIndex: number,
): T[] {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export function replaceItem<T = any>(
  target: T[],
  predicate: (item: T) => boolean,
  items?: T[],
  itemAsFn?: (item: T) => T,
): T[] {
  const targetIndex: number = target.findIndex(predicate)
  const copy = target.concat([])

  if (typeof itemAsFn === 'function' && -1 !== targetIndex) {
    ;(items || []).push(itemAsFn(target[targetIndex]))
  }

  if (-1 === targetIndex && items) {
    copy.push(...items)
  } else {
    copy.splice(targetIndex, 1, ...items)
  }

  return copy
}

export function removeItem<T = any>(
  target: T[],
  predicate: (item: T, i?: number) => boolean,
): T[] {
  const copy = target.concat([])
  const targetIndex: number = target.findIndex(predicate)

  if (-1 === targetIndex) {
    return copy
  }

  copy.splice(targetIndex, 1)

  return copy
}

export function insert<T = any>(target: T[], index: number, items: T[]): T[] {
  const copy = target.concat([])

  copy.splice(index, 0, ...items)

  return copy
}

export function arrToObj(targetArray, key, removeKey: boolean = false): any[] {
  return targetArray.concat().reduce((map, input) => {
    const arr = { ...input }
    const targetKey = arr[key]

    if (removeKey) {
      delete arr[key]
    }

    return { ...map, [targetKey]: arr }
  }, {})
}
