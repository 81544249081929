import React from "react"
import { Button } from "../../../components/irv-ui"
import Icon from "../../icon/Icon"
import { isEmpty } from "../../../utils/is"
import { LinkedRow } from "../../../types/Row"
import LinkedRecordList from "../../linkedRecord/LinkedRecordList"
import LinkedRecordModal from "../../linkedRecord/LinkedRecordModal"
import { GridRow } from "../../grid/types/GridRow"
import { getView } from "../../../utils/get"
import { asArray } from "../../../general/utils/array"
import { removeItem } from "../../../utils/array"
import PayloadProvider from "../../workspace/PayloadProvider"
import { BaseTable } from "../../../types/BaseTable"

export default class FormLinkToRecordRenderer extends React.PureComponent<any> {
  state = {
    isModalVisible: false,
  }

  openModal = () => {
    this.setState({ isModalVisible: true })
  }

  closeModal = () => {
    this.setState({ isModalVisible: false })
  }

  handleSelect = (linkedRow: LinkedRow[], rows: GridRow[], table: BaseTable) => {
    this.props.onChange(asArray(rows), { table })
    this.setState({
      isModalVisible: false,
    })
  }

  render() {
    const { params, view, readonly, column, onChange, expandable } = this.props
    const value = this.props.value || []
    const { isModalVisible } = this.state
    const isMultiple: boolean = true === params.multiple
    const isAddBtnVisible: boolean = readonly
      ? false
      : isMultiple
        ? true
        : !value.length

    return (
      <PayloadProvider.Consumer>
        {(payload) => {
          const { table, rows } = (payload.links[column._id] || {})
          const linksValue = rows || value

          const onRemove = readonly ? null : (id: string) => {
            const updatedRows: GridRow[] = removeItem(linksValue, (rec: LinkedRow) => rec._id === id)

            onChange(
              updatedRows.map(r => {
                return { _id: r._id }
              }),
            )
          }

          return (
            <div className={"lr"}>
              {isAddBtnVisible && (
                <Button className="add-record" onClick={this.openModal}><Icon type={"plus"} /> Выбрать запись</Button>
              )}

              {!isEmpty(value) && table && (
                <LinkedRecordList
                  rows={linksValue}
                  table={table}
                  view={view || getView(table, params.viewId)}
                  onRemove={onRemove}
                  expandable={expandable}
                />
              )}

              {isModalVisible && (
                <LinkedRecordModal
                  tableId={this.props.table._id}
                  viewId={params.viewId}
                  columnId={column._id}
                  container={document.querySelector(".grid-link-record")}
                  visible={isModalVisible}
                  selectedRows={linksValue}
                  onClose={this.closeModal}
                  onSelect={this.handleSelect}
                  multiple={isMultiple}
                />
              )}
            </div>
          )
        }}
      </PayloadProvider.Consumer>
    )
  }
}
