import React, { useContext } from 'react'
import { GridColumn } from '../../grid/types/GridColumn'
import { GridRow } from "../../grid/types/GridRow"
import { BaseView } from "../../../types/BaseView"

interface Props {
  readonly?: boolean
  fields: GridColumn[]
  data: GridRow
  view: BaseView
  onChange: (column: GridColumn, value: any) => void
  onCommit?: (column: GridColumn, value: any) => void
}

export const BlockProvider = React.createContext<Props>(null)

export const useBlockProvider = () => {
  return useContext(BlockProvider)
}
