const UserRoles = {
  OWNER: 10,
  DEPUTY: 8,
  ADMIN: 7,
  EDITOR: 6,
  MEMBER: 4,
  READ_ONLY: 2,
  WITHOUT_ACCESS: 0,
  REMOVED_USER: -1
}

export {UserRoles}
