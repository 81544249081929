import React from 'react'
import { Preset } from '../../types/Presets'

interface Props {
  preset: Preset
}

export default React.memo(function HeaderPreset(props: Props) {
  const headers = props.preset.buildHeaders()

  return (
    <React.Fragment>
      {headers.map((h, i) => (
        <div className="tl-header-pr-row" key={i}>
          {h.map((c, j) => {
            const className = [
              'tl-header-pr-cell', 
              c.showWeekends && props.preset.isWeekend(j) ? 'we' : ''
            ].filter(Boolean).join(' ')

            return (
              <div className={className} key={j} style={{flexBasis: c.width}}>
                {c.label}
              </div>
            )
          })}
        </div>
      ))}
    </React.Fragment>
  )
})