import React from 'react'
import {CalendarViewProps, CalendarViewType, ViewConfig} from "../NEW__types/Calendar";
import {Event} from "../NEW__types/Event";
import {dayjs, formats, getDimensions, hashMonth, isMovementStarted, isToday, isTouchable, isWeekend} from "../utils";
import DayTimeline from "../components/dayTimeline/DayTimeline";
import CurrentTime from "../components/dayTimeline/CurrentTime";
import DaysCell from "../components/event/DaysCell";

import '../NEW__styles/calendar/custom.scss'

const ROW_HEIGHT: number = 50
const STEP = 10

type CustomDaysProps = CalendarViewProps & {
  days: number
}

interface State {
  config: ViewConfig
  height: number
  rootStyle: any
  scrollElStyle: any
  cssStep: number
}

const defaultConfig = {
  startHours: 0,
  endHours: 24,
  rowHeight: ROW_HEIGHT,
  step: STEP,
}

export default class CustomDays extends React.PureComponent<CustomDaysProps, State> {
  height: number
  isTouch: boolean = ('ontouchstart' in window)
  isTouchMoving: boolean

  tfEl: React.RefObject<any> = React.createRef()
  eventsEl: React.RefObject<any> = React.createRef()
  timelineEl: React.RefObject<any> = React.createRef()
  headDaysEl: React.RefObject<any> = React.createRef()
  container: React.RefObject<any> = React.createRef()

  constructor(props: CustomDaysProps) {
    super(props)

    this.state = this.getViewport(props.view, props.days)
  }

  componentDidMount() {
    try {
      this.container.current.querySelector('.ct').scrollIntoViewIfNeeded()

      const {scrollLeft, scrollTop} = this.eventsEl.current

      this.tfEl.current.scrollLeft = scrollLeft
      this.tfEl.current.scrollTop = scrollTop
    } catch (e) {
    }
  }

  componentDidUpdate(prevProps: Readonly<CustomDaysProps>) {
    if (prevProps.view !== this.props.view) {
      this.setState({
        ...this.getViewport(this.props.view, this.props.days)
      })
    }
  }

  createEventFromCellNode = (cellNodeEl: HTMLDivElement | undefined, x: number, y: number) => {
    const {onAdd, active} = this.props

    if (!onAdd || !cellNodeEl) {
      return
    }

    const {config, cssStep} = this.state
    const elBounds = cellNodeEl.getBoundingClientRect()
    const dayStartTime = dayjs(active)
      .hour(0)
      .minute(0)
      .second(0)
      .add(+cellNodeEl.dataset.day, 'day')

    const top = y + (config.startHours || 0) * config.rowHeight
    const clickedMinutes = ((top - elBounds.top) / cssStep) * config.step
    const minutes = clickedMinutes - (clickedMinutes % 30)

    const result = dayStartTime
      .add(minutes, 'minute')

    onAdd({
      start: result.toDate(),
      end: result.add(1, 'hour').toDate()
    })
  }

  initDragging = (event: Event, eventNode: any, start: number[]) => {
    const {active, viewportHeight, onChange, onSelect} = this.props

    if (!onChange) {
      return
    }

    const dayContainer = eventNode.closest('.c-cell')
    const dayContainerWidth = dayContainer.getBoundingClientRect().width

    const {config, cssStep} = this.state

    const eventsContainerBounds = this.eventsEl.current.getBoundingClientRect()
    const eventBounds = getDimensions(event.start, event.end, config, viewportHeight)

    const diffMinutes: number = dayjs(event.end).diff(event.start, 'minute')
    const dayStartTime = dayjs(active)
      .hour(0)
      .minute(0)
      .second(0)
      .add(+dayContainer.dataset.day, 'day')

    document.body.classList.add('usn')

    let modelEl
    let modelTime
    let modelLeft

    const modelDates = {
      start: dayjs(event.start),
      end: dayjs(event.end)
    }

    const onMouseUp = () => {
      document.body.style.userSelect = ''
      document.body.classList.remove('usn')

      if (modelEl) {
        modelEl.remove()
        const date: any = dayjs(active).add(modelLeft / dayContainerWidth, 'day').date()
        const newDates = {
          start: modelDates.start.date(date).toDate(),
          end: modelDates.end.date(date).toDate()
        }

        onChange(event, newDates)
      } else {
        onSelect && onSelect(event)
      }

      eventNode.classList.remove('g')

      window.removeEventListener('mousemove', onMouseMove, false)
      window.removeEventListener('mouseup', onMouseUp, false)
    }

    const onMouseMove = (e) => {
      if (!isMovementStarted(start, [e.pageX, e.pageY])) {
        return
      }

      if (!modelEl) {
        eventNode.classList.add('g')
        initModel()
      }

      const currentLeft = e.pageX - eventsContainerBounds.left
      const currentTop = eventBounds.top - (start[1] - e.pageY)

      let top = Math.max(0, currentTop - (currentTop % cssStep))

      modelLeft = currentLeft - (currentLeft % dayContainerWidth)

      modelEl.style.top = `${top}px`
      modelEl.style.left = `${modelLeft}px`

      updateModelHeader(top)
    }

    const initModel = () => {
      modelEl = eventNode.cloneNode(true)
      modelEl.classList.add('tmn')
      modelEl.style.width = `${dayContainerWidth}px`

      document.body.style.userSelect = 'none'

      this.eventsEl.current.append(modelEl)

      modelTime = modelEl.querySelector('.c-event-time')
    }

    const updateModelHeader = (x) => {
      const top: number = x + (config.startHours || 0) * (config.rowHeight || ROW_HEIGHT)
      const endEventMinutes = (top / cssStep) * config.step + diffMinutes

      const start: any = getTimeByTop(x, true)
      const end: dayjs.Dayjs = dayStartTime.add(endEventMinutes, 'minute')

      Object.assign(modelDates, {
        start, end
      })

      modelTime.innerHTML = `${start.format('HH:mm')} - ${end.format('HH:mm')}`
    }

    const getTimeByTop = (top: number, onlyDate?: boolean) => {
      const topWithStartHours: number = top + (config.startHours || 0) * (config.rowHeight || ROW_HEIGHT)
      const minutes = (topWithStartHours / cssStep) * config.step
      const result = dayStartTime.add(minutes, 'minute')

      if (onlyDate) {
        return result
      }

      return result.format('HH:mm')
    }

    window.addEventListener('mousemove', onMouseMove, false)
    window.addEventListener('mouseup', onMouseUp, false)
  }

  initEventCreation = (target: any, x: number, y: number) => {
    const {onAdd} = this.props

    if (!onAdd) {
      return
    }

    const cellNode = target.classList.contains('c-cell') ? target : target.closest('.c-cell')

    if (!cellNode) {
      return
    }

    // let isMoved: boolean = false

    function onMouseMove(e) {
      // isMoved = true
    }

    const onMouseUp = () => {
      window.removeEventListener('mousemove', onMouseMove, false)
      window.removeEventListener('mouseup', onMouseUp, false)

      this.createEventFromCellNode(cellNode, x, y)
    }

    window.addEventListener('mousemove', onMouseMove, false)
    window.addEventListener('mouseup', onMouseUp, false)
  }

  getViewport = (view: CalendarViewType, days: number) => {
    const config = Object.assign({}, defaultConfig, view.config)
    const height = this.calculateHeight(config)

    const rootStyle = {
      height
    }

    const scrollElStyle = config.minCellWidth
      ? {minWidth: config.minCellWidth * days}
      : null

    if (scrollElStyle) {
      rootStyle['minWidth'] = scrollElStyle.minWidth
    }

    return {
      config,
      scrollElStyle,
      height,
      rootStyle,
      cssStep: config.rowHeight / (60 / config.step)
    }
  }

  getTouchesCoords = (e): undefined | [number, number] => {
    if (!e.touches) {
      return
    }

    const touch = e.touches[0] || e.changedTouches[0]

    if (touch) {
      return [touch.clientX, touch.clientY]
    }
  }

  getEventFromNode = (node: any): Event | undefined => {
    if (!node) {
      return
    }

    const {events} = this.props
    const cellContainer = node.closest('.c-cell')

    if (!cellContainer) {
      return
    }

    const dEvents = events[cellContainer.dataset.h]

    if (!dEvents) {
      return
    }

    return dEvents.find(_e => _e.id === node.dataset.id)
  }

  calculateHeight = (config: ViewConfig): number => {
    const {startHours, endHours} = config
    const rowHeight: number = config.rowHeight || ROW_HEIGHT

    if (!startHours && !endHours) {
      return rowHeight * 24
    }

    let h = 24 - (startHours || 0)

    if (endHours) {
      h -= (24 - endHours)
    }

    return h * rowHeight
  }

  selectEvent = (event?: Event) => {
    if (this.props.onSelect) {
      this.props.onSelect(event)
    }
  }

  handleRootScroll = (e) => {
    const {scrollTop, scrollLeft} = e.target

    this.timelineEl.current.scrollTop = scrollTop
    this.headDaysEl.current.scrollLeft = scrollLeft

    if (this.isTouch) {
      this.eventsEl.current.scrollTop = scrollTop
      this.eventsEl.current.scrollLeft = scrollLeft
    }
  }

  handleTouchMove = () => {
    this.isTouchMoving = true
  }

  handleTouchEnd = (e) => {
    if (this.isTouchMoving) {
      return this.isTouchMoving = false
    }

    const {onSelect, selected} = this.props
    const [x, y] = this.getTouchesCoords(e)
    const elementsFromPoint: any[] = document.elementsFromPoint(x, y)
    const eventNode: any = elementsFromPoint.find(el => el.classList.contains('c-event'))
    const event: Event | undefined = this.getEventFromNode(eventNode)

    if (onSelect) {
      if (event) {
        return onSelect(event)
      }
    }

    if (selected && onSelect) {
      return onSelect()
    }

    const cellNode = elementsFromPoint.find(el => el.classList.contains('c-cell'))

    if (cellNode) {
      this.createEventFromCellNode(cellNode, x, y)
    }
  }

  handleMouseDown = (e) => {
    const eventNode: any = e.target.classList.contains('c-event') ? e.target : e.target.closest('.c-event')
    const {onSelect, selected, readonly} = this.props
    const event: Event | undefined = this.getEventFromNode(eventNode)

    if (readonly) {
      return this.selectEvent(event)
    }

    if (event) {
      if (e.target.classList.contains('event-resize')) {
        return console.log('init resize', event)
      }

      return this.initDragging(
        event,
        eventNode,
        [e.pageX, e.pageY]
      )
    }

    if (selected && onSelect) {
      return onSelect()
    }

    this.initEventCreation(
      e.target,
      e.pageX,
      e.pageY
    )
  }

  renderHeader = () => {
    const cells = []
    const {active, days} = this.props

    for (let i = 0; i < days; i++) {
      const date = active.add(i, 'day')

      let cellClassName = 'c-hcell'

      if (isToday(date)) {
        cellClassName += ' today'
      }

      cells.push(
        <div className={cellClassName} key={i}>
          <span>
            {formats.custom(date)}
          </span>

          <span className={'c-text'}>
            {date.date()}
          </span>
        </div>
      )
    }

    return (
      <div className={'c-head-days'} ref={this.headDaysEl}>
        <div className="c-head-s" style={this.state.scrollElStyle}>
          {cells}
        </div>
      </div>
    )
  }

  renderEvents = () => {
    const rows = []
    const {active, days, view, selected, events} = this.props
    const config = view.config || {}

    for (let i = 0; i < days; i++) {
      const day = active.add(i, 'day')
      const eventsHash: string = hashMonth(day)
      const cellEvents = events[eventsHash] || []

      let cellClassName = 'c-cell'

      if (isWeekend(day)) {
        cellClassName += ' weekend'
      }

      rows.push(
        <div className={cellClassName} key={i} data-day={i} data-h={eventsHash}>
          {cellEvents.length > 0 && (
            <DaysCell
              events={cellEvents}
              viewportHeight={this.state.height}
              config={config}
              selected={selected}
            />
          )}

          {isToday(day) && (
            <CurrentTime
              rowHeight={config.rowHeight || ROW_HEIGHT}
              startHours={config.startHours}
            />
          )}
        </div>
      )
    }

    return rows
  }

  render() {
    const viewConfig = this.props.view.config
    const cn = ['c-custom', this.isTouch ? 'c-t' : ''].join(' ')

    return (
      <div className={cn} ref={this.container}>
        <div className="c-head">
          <div className={'c-head-tz'}/>
          {this.renderHeader()}
        </div>

        <div className="c-body">
          <div className="c-custom-wrap">
            <div className="c-custom-time" ref={this.timelineEl}>
              <DayTimeline config={viewConfig}/>
            </div>

            <div
              className={'c-custom-events'}
              ref={this.eventsEl}
              onMouseDown={this.handleMouseDown}
              onScroll={this.handleRootScroll}
            >
              <div className="c-custom-s" style={this.state.rootStyle}>
                <DayTimeline silence={true} config={viewConfig}/>
                {this.renderEvents()}
              </div>
            </div>

            {isTouchable() && (
              <div
                className={'c-custom-tf'}
                ref={this.tfEl}
                onScroll={this.handleRootScroll}
                onTouchEnd={this.handleTouchEnd}
                onTouchMove={this.handleTouchMove}
              >
                <div style={this.state.rootStyle}/>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}