import {WorkspaceActions} from "../actions/WorkspaceActions";
import {replaceItem} from "../../utils/array";
import {Workspace} from "../../types/Workspace";
import {EyzyUser, EyzyUserParams} from "../../types/EyzyUser";
import {BaseActions} from "../actions/BaseActions";
import { Base } from "../../types/Base";

const initState: Workspace[] = []

export default function workspaces(state = initState, action) {
  const payload = action.payload || {}
  const result = action.result

  switch (action.type) {
    case WorkspaceActions.FETCH: {
      const res: Workspace[] = []
      const {workspaces, userMap, bases} = payload

      workspaces.forEach(w => {
        const workspace: Workspace = {...w}
        const userParams = workspace.userParams || {}

        const userWithRemoved = Object.keys(userParams)
        const removedUsers = userWithRemoved.filter(u => !workspace.users.includes(u))

        workspace.removedUsers = removedUsers
          .map(id => {
            const user: EyzyUser | undefined = userMap[id]

            if (!user) {
              // eslint-disable-next-line
              return
            }

            const params: EyzyUserParams = {
              ...user.params,
              ...userParams[id] || {}
            }

            // eslint-disable-next-line
            if (!params.removedOn) {
              // eslint-disable-next-line
              return
            }

            return {
              ...user,
              params
            }
          })
          .filter(Boolean)

        workspace.wsUsers = workspace.users
          .map(id => {
            const user: EyzyUser | undefined = userMap[id]

            if (!user) {
              // eslint-disable-next-line
              return
            }

            const params: EyzyUserParams = {
              ...user.params,
              ...userParams[id] || {}
            }

            if (params.removedOn) {
              // eslint-disable-next-line
              return
            }

            return {
              ...user,
              params
            }
          })
          .filter(Boolean)

        workspace.baseIds = bases
          .filter((b: Base) => b.workspaceId === w._id)
          .map(b => b._id)

        res.push(workspace)
      })

      return res
    }

    case WorkspaceActions.CREATE:
      if (result) {
        return state.concat(result)
      }

      return state

    case WorkspaceActions.REMOVE:
      return state.filter(w => w._id !== payload._id)

    case WorkspaceActions.UPDATE:
      return replaceItem(state, (w) => w._id === payload._id, [], workspace => {
        return {
          ...workspace,
          ...payload
        }
      })

    case WorkspaceActions.REMOVE_COLLABORATOR:
      if (result) {
        return state.map(workspace => {
          if (workspace._id !== payload.id) {
            return workspace
          }

          return {
            ...workspace,
            wsUsers: workspace.wsUsers.filter(u => u._id !== payload.userId)
          }
        })
      }

      return state

    case BaseActions.UPDATE_PERMISSIONS:
      if (result) {
        return state.map(ws => {
          if (!ws.baseIds.includes(payload.id)) {
            return ws
          }

          const wsUsers = [...ws.wsUsers].map(user => {
            const uAccess = payload.users.find(u => user._id === u.userId)

            if (!uAccess) {
              return user
            }

            const userRoles = user.roles

            if (!uAccess.hasAccess) {
              delete userRoles[payload.id]
            } else {
              userRoles[payload.id] = uAccess.level
            }

            return {
              ...user,
              roles: userRoles
            }
          })

          return {
            ...ws,
            wsUsers
          }
        })
      }
      return state

    default:
      return state
  }
}