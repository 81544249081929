import dayjs from 'dayjs'
import uk from 'dayjs/locale/uk'
import localizedFormat from 'dayjs/plugin/localeData'
import weekdays from 'dayjs/plugin/weekday'
import dayjsUtc from 'dayjs/plugin/utc'
import { asArray } from '../general/utils/array'

dayjs.locale(uk)
dayjs.extend(localizedFormat)
dayjs.extend(weekdays)
dayjs.extend(dayjsUtc)

// @ts-ignore
export const weekdaysMin = [...dayjs.weekdaysMin(dayjs())]

export const weekdaysValueList = (): { value: number; key: string }[] =>
  weekdaysMin.map((w, i) => ({
    value: i,
    key: w,
  }))

export const utc = (date?: Date | string) => dayjs(date).utc()

export const isValid = (date: Date): boolean => {
  return dayjs(date).isValid()
}

export const exportComputedDates = (item: any[]) => {
  return asArray(item).reduce((result, value) => {
    result.push(...asArray(value.value))

    return result
  }, [])
}

export const parseDefaultValue = (defaultValue: any): string | null => {
  if (defaultValue === 'TODAY()') {
    return defaultValue
  }

  const date = dayjs(defaultValue)

  if (date.isValid()) {
    return 'date'
  }
}
