import React from 'react'
import { SimpleRendererProps } from '../types/SimpleRendererProps'
import { format } from "../../../general/utils/date"

const LinkRenderer: React.FC<SimpleRendererProps> = ({ value, className, }) => {
  if (!value) {
    return
  }

  const href = /^http/.test(value) ? value : `http://${value}`
  return (
    <div className={className}>
      <a
        className="link"
        target="_blank"
        rel="noopener noreferrer"
        href={href}
        onClick={e => e.stopPropagation()}>
        {value}
      </a>
    </div>
  )
}

export default React.memo(LinkRenderer)
