import {BaseTable} from "../types/BaseTable";
import {ViewSort} from "../types/BaseView";
import {isEmpty} from "./is";
import {GridColumn} from "../components/grid/types/GridColumn";
import {getColumnById} from "./get";
import orderBy from 'lodash.orderby'
import {GridRow} from "../components/grid/types/GridRow";
import { getSortValue } from "../general/utils/value";

export function applySort(rows: any[], table: BaseTable, sort?: ViewSort[]) {
  if (!sort || isEmpty(sort)) {
    return rows
  }

  const iteratees: ((r: GridRow) => any)[] = []
  const orders: string[] = []

  for (let i = 0; i < sort.length; i++) {
    const {columnId, order} = sort[i]
    const column: GridColumn | undefined = getColumnById(table, columnId)

    if (!column) {
      console.error(`Колонка не найдена [${columnId}]`)
      return rows
    }

    iteratees.push(r => getSortValue(table, column, r))
    orders.push(order)
  }

  return orderBy(rows, iteratees, orders)
}

