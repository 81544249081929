import React from "react";
import {GridRendererProps} from "../GridRendererProps";

export default class GridLongTextEditor extends React.Component<GridRendererProps> {
  area: React.RefObject<any> = React.createRef()

  componentDidMount() {
    const ref = this.area.current
    const {value, keyCode} = this.props

    setTimeout(_ => {
      ref.value = value || keyCode || ''
      ref.focus()
    }, 5)
  }

  handleBlur = () => {
    this.props.onCommit(this.area.current.value)
  }

  handleKeyDown = (e: React.KeyboardEvent) => {
    if (13 === e.keyCode && e.shiftKey) {
      e.preventDefault()
      this.handleBlur()
    }

    if (27 === e.keyCode) {
      this.props.onCommit(this.props.value)
    }
  }

  render() {
    return (
      <textarea
        className="grid-editor"
        ref={this.area}
        style={{height: '100px'}}
        onKeyDown={this.handleKeyDown}
        onBlur={this.handleBlur}
      />
    )
  }
}
