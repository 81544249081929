import React from 'react'
import { connect } from 'react-redux'
import './style.scss'
import Logo from '../../../../components/logo/Logo'
import SavingIndicator from '../../../../components/loading/SavingIndicator'
import { EyzyUser } from '../../../../types/EyzyUser'
import Profile from '../../../../components/user/Profile'

interface Props {
  isLoading: boolean
  user: EyzyUser
}

const mapStateToProps = state => ({
  isLoading: state.loading,
  user: state.user,
})

function HelloTopbar(props: Props) {
  const { isLoading, user } = props

  return (
    <div className="hello-topbar">
      <div className="hello-wrapper">
        <Logo />

        <div className="hello-topbar__nav">
          <SavingIndicator visible={isLoading} />
        </div>

        {user && process.env.NODE_ENV === 'development' && (
          <span style={{ margin: '0 10px' }}>
            Hi, {user.fullName || user.email}
          </span>
        )}

        {user && <Profile user={user} />}
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(HelloTopbar)
