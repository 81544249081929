const FilterOperators = {
  CONTAINS: 'contains',
  DOES_NOT_CONTAIN: 'doesNotContain',
  IS: 'is',
  IS_NOT: 'not',
  IS_EMPTY: 'ie',
  IS_NOT_EMPTY: 'ine',
  IS_ANY_OF: 'anyOf',
  IS_NONE_OF: 'noneOf',
  IS_ALL_OF: 'allOf',
  IS_WITHIN: 'withIn',
  IS_BEFORE: 'before',
  IS_AFTER: 'after',
  IS_ON_OR_BEFORE: 'onOrBefore',
  IS_ON_OR_AFTER: 'onOrAfter',

  IS_STARTS_WITH: 'startsWith',
  IS_ENDS_WITH: 'endsWith',

  IS_EQUAL: '=',
  IS_NOT_EQUAL: '!=',
  IS_LESS_THAN: '<',
  IS_LESS_THAN_OR_EQUAL: '<=',
  IS_GREATER_THAN: '>',
  IS_GREATER_THAN_OR_EQUAL: '>=',
}

export {FilterOperators}