import React, { useEffect, useState } from 'react'
import { Button, Checkbox } from '../../../../../../components/irv-ui'
import { BaseView, SortOrderType } from '../../../../../../types/BaseView'
import { BaseTable } from '../../../../../../types/BaseTable'
import { getColumnById } from '../../../../../../utils/get'
import BtnWithIcon from '../../../../../../components/btn/BtnWithIcon'
import ColumnSortOrder from '../../../../../../components/columnHelpers/columnSortOrder/ColumnSortOrder'
import ColumnSelector from '../../../../../../components/columnHelpers/columnSelector/ColumnSelector'
import Select from 'antd/lib/select'
import { asArray } from '../../../../../../general/utils/array'
import { ActionType } from '../../../../../../store/CommonEffects'
import { TableActions } from '../../../../../../store/actions/TableActions'
import {
  getUIColumnType,
  isDateColumn,
  isUserColumn,
} from '../../../../../../general/utils/column'
import { GridColumn } from '../../../../../../components/grid/types/GridColumn'
import { ColumnType } from '../../../../../../components/grid/types/ColumnType'

interface Props {
  view: BaseView
  table: BaseTable
  onAction: (action: ActionType, payload: any) => void
}

const isAllowToIgnoreOrder = (column: GridColumn): boolean => {
  const type = getUIColumnType(column)

  if (type === ColumnType.MULTIPLE_SELECT) {
    return true
  }

  const params = column.params || {}
  const columnParams = params.foreignParams || params

  if (isUserColumn(column) || column.type === ColumnType.LINK_TO_RECORD) {
    return columnParams.multiple
  }

  return false
}

export default React.memo(function CustomizeGroup(props: Props) {
  const { view, table, onAction } = props
  const [groups, setGroups] = useState([])
  const [isAddingNewGroup, setIsAddingNewGroup] = useState(false)
  const isGrouped: boolean = groups.length > 0

  useEffect(() => {
    setGroups(asArray(view.groups))
  }, [view])

  const addGroup = () => setIsAddingNewGroup(true)

  const handleAdd = columnId => {
    onAction(TableActions.UPDATE_VIEW, {
      groups: groups.concat([{ columnId }]),
    })
  }

  const handleChange = (index: number, payload: any) => {
    onAction(TableActions.UPDATE_VIEW, {
      groups: groups.map((g, i) => {
        if (i !== index) {
          return g
        }

        return {
          ...g,
          ...payload,
        }
      }),
    })
  }

  const handleRemove = (index: number) => {
    onAction(TableActions.UPDATE_VIEW, {
      groups: groups.filter((g, i) => i !== index),
    })
  }

  return (
    <div className={'view-settings-item group'}>
      {!isGrouped && (
        <div className={'help'}>
          Выберите поле, по которому хотите группировать записи
        </div>
      )}

      {groups.map((group, i) => {
        const column = group.columnId && getColumnById(table, group.columnId)

        return (
          <div className={'group-item'}>
            <div className={'c-selector'}>
              <ColumnSelector
                placeholder="Выберите поле для группировки"
                selectedItem={group.columnId}
                showArrow={false}
                onChange={columnId => handleChange(i, { columnId })}
                table={table}
              />
              <BtnWithIcon
                min
                icon={'cross-small'}
                onClick={() => handleRemove(i)}
              />
            </div>

            {column && (
              <div className={'c-settings'}>
                <div>
                  <div className={'c-settings-text'}>Сортировка</div>
                  <div className="c-settings-ctrl">
                    <ColumnSortOrder
                      order={groups[0].sort || SortOrderType.ASC}
                      column={column}
                      onChange={sort => handleChange(i, { sort })}
                    />
                  </div>
                </div>

                {isDateColumn(column) && (
                  <div>
                    <div className={'c-settings-text'}>Тип даты</div>
                    <div className="c-selector">
                      <Select
                        getPopupContainer={trigger => trigger.parentNode}
                        value={group.clarification || null}
                        onChange={clarification =>
                          handleChange(i, { clarification })
                        }
                        style={{ width: 130 }}>
                        <Select.Option value={null}>Без указания</Select.Option>
                        <Select.Option value={'day'}>День</Select.Option>
                        <Select.Option value={'month'}>Месяц</Select.Option>
                        <Select.Option value={'year'}>Год</Select.Option>
                      </Select>
                    </div>
                  </div>
                )}

                {isAllowToIgnoreOrder(column) && (
                  <div>
                    <div className={'c-settings-text'}>
                      Игнорировать порядок
                    </div>
                    <div className="c-settings-ctrl">
                      <Checkbox
                        onChange={ignoreListOrder =>
                          handleChange(i, { ignoreListOrder: ignoreListOrder })
                        }
                        checked={group.ignoreListOrder}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )
      })}

      {!isGrouped || isAddingNewGroup ? (
        <div className={'group-item'}>
          <div className={'c-selector'}>
            <ColumnSelector
              placeholder="Выберите поле для группировки"
              showArrow={false}
              onChange={handleAdd}
              table={table}
            />
          </div>
        </div>
      ) : null}

      {isGrouped && groups.length < 3 && (
        <div className={'group-item'}>
          <Button onClick={addGroup}>Добавить подгруппу</Button>
        </div>
      )}
    </div>
  )
})
