import { useEffect } from "react"
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { BaseRouterProps } from "../types/BaseRouterProps"
import Loading from "../../../components/loading/Loading"
import { EyzyUser } from "../../../types/EyzyUser"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { loadEntity, LoadEntityArguments } from "../../../store/CommonEffects"
import { lastUsage } from "../../../utils/storage"
import { Q } from "../types/Q"
import { BaseTable } from "../../../types/BaseTable"
import { BaseView } from "../../../types/BaseView"
import { buildLink, history, Pages } from "../../../utils/navigation"
import { BaseActions } from "../../../store/actions/BaseActions"

function BaseRouterContainer(props: Props) {
  const { user, match, loadEntity } = props
  const baseId = match.params.baseId

  useEffect(() => {
    const loadArgs: any = {
      entity: BaseActions.FETCH,
      id: baseId,
      q: [],
    }
    const { tableId, viewId } = lastUsage(baseId)

    const finishLoading = ({ tables }) => {
      const selectedTable: BaseTable =
        tables.find(t => t._id === tableId) || tables[0]

      const selectedView: BaseView =
        selectedTable.views.find(v => v._id === viewId) ||
        selectedTable.views[0]

      history.replace(
        buildLink(Pages.MAIN_WORKSPACE, {
          tableId: selectedTable._id,
          viewId: selectedView._id,
        }) + "#ld",
      )
    }

    if (tableId) {
      loadArgs.queryString = { tableId }

      if (viewId) {
        loadArgs.queryString["viewId"] = viewId
      }
    }

    if (!user) {
      loadArgs.q.push(Q.USER)
    }

    if (!tableId) {
      loadArgs.q.push(Q.ROWS)
    }

    loadEntity(loadArgs).then(finishLoading)
    // eslint-disable-next-line
  }, [])

  return (
    <div className={"ws"}>
      <Loading />
    </div>
  )
}

type ReduxStateProps = {
  user: EyzyUser
}

type ReduxDispatchProps = {
  loadEntity: (args: LoadEntityArguments) => Promise<any>
}

type RouterProps = RouteComponentProps<BaseRouterProps> & {}
type Props = ReduxStateProps & ReduxDispatchProps & RouterProps

const mapStateToProps = (state: any): ReduxStateProps => ({
  user: state.user,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators<any, ReduxDispatchProps>(
    {
      loadEntity,
    },
    dispatch,
  )

export default withRouter<RouterProps, any>(
  connect<ReduxStateProps, ReduxDispatchProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(BaseRouterContainer),
)
