import { GridColumn } from '../components/grid/types/GridColumn'
import { BaseView } from '../types/BaseView'
import { ColumnType } from '../components/grid/types/ColumnType'
import { EyzyUser } from '../types/EyzyUser'

export function isUserRemoved(user: EyzyUser): boolean {
  if (!user.params) {
    return false
  }

  return !!user.params.removedOn
}

export function isActiveUser(user: EyzyUser): boolean {
  return !isUserRemoved(user)
}

export function isRealEmail(value: any): boolean {
  if (!~(value || '').indexOf('@')) {
    return false
  }

  return /\w@[\w]{2,}\.[\w]{2,}/.test(value)
}

export function isEmail(value: any): boolean {
  return !!~(value || '').indexOf('@')
}

export function isEmpty(value: any): boolean {
  if (null == value && 0 !== value) {
    return true
  }

  if (typeof value === 'number') {
    return false
  }

  if (isArray(value) || isString(value)) {
    return !value.length
  }

  const keys = Object.keys(value)

  return keys.length === 0
}

export function isString(value: any): boolean {
  return 'string' === typeof value
}

export function isArray(value: any): boolean {
  return Array.isArray(value)
}

export function isShallowEqual(objA: any, objB: any, keys?: string[]): boolean {
  if (objA === objB) {
    return true
  }

  if (Object.keys(objA).length !== Object.keys(objB).length) {
    return false
  }

  if (!keys) {
    keys = Object.keys(objA)
  }

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    const valueA = objA[key]
    const valueB = objB[key]

    if (valueA !== valueB) {
      return false
    }
  }

  return true
}

export function isBool(value: any): boolean {
  return 'boolean' === typeof value
}

export function isRowId(id: string): boolean {
  return /^r/.test(id)
}

export function isSpecialColumn(columnType: string): boolean {
  return ['modifiedOn', 'modifiedBy', 'createdOn', 'createdBy'].includes(
    columnType,
  )
}

export function isDateColumn(columnType: string): boolean {
  return ['modifiedOn', 'createdOn'].includes(columnType)
}

export function isMob(isRealMob?: boolean): boolean {
  return document.body.clientWidth <= (isRealMob ? 480 : 768)
}

export function isTablet(): boolean {
  return document.body.clientWidth <= 1024
}

export function isColumnHidden(column: GridColumn, view: BaseView): boolean {
  const columnParams = view.columnsParams

  if (!columnParams || !columnParams[column._id]) {
    return false
  }

  return !!columnParams[column._id].hidden
}

export function isLinkColumnType(column: GridColumn): boolean {
  return column.type === ColumnType.LINK_TO_RECORD && !!column.params
}

const COMPUTED_COLUMNS_TYPES: ColumnType[] = [ColumnType.LOOKUP]

export function isComputedColumn(column: GridColumn): boolean {
  return COMPUTED_COLUMNS_TYPES.includes(column.type)
}

export function isFormulaValueValid(value: any): boolean {
  return value !== '#VALUE!'
}
