import { isIOS } from '../utils/platform'
import { chain } from '../utils/chain'
import { useLayoutEffect } from 'react'

interface PreventScrollOptions {
  /** Whether the scroll lock is disabled. */
  disabled?: boolean
}

/**
 * Prevents scrolling on the document body on mount, and
 * restores it on unmount. Also ensures that content does not
 * shift due to the scrollbars disappearing.
 */
export function usePreventScroll(options: PreventScrollOptions = {}) {
  const { disabled } = options

  useLayoutEffect(() => {
    if (disabled) {
      return
    }

    if (isIOS()) {
      return preventScrollMobileSafari()
    } else {
      return preventScrollStandard()
    }
  }, [disabled])
}

// For most browsers, all we need to do is set `overflow: hidden` on the root element, and
// add some padding to prevent the page from shifting when the scrollbar is hidden.
function preventScrollStandard() {
  return chain(
    setStyle(
      document.documentElement,
      'paddingRight',
      `${window.innerWidth - document.documentElement.clientWidth}px`,
    ),
    setStyle(document.documentElement, 'overflow', 'hidden'),
  )
}

function preventScrollMobileSafari() {
  setStyle(document.documentElement, 'overflow', 'hidden')

  return () => {
    setStyle(document.documentElement, 'overflow', '')
  }
}

// Sets a CSS property on an element, and returns a function to revert it to the previous value.
function setStyle(element: HTMLElement, style: string, value: string) {
  let cur = element.style[style]
  element.style[style] = value
  return () => {
    element.style[style] = cur
  }
}
