import React from "react"
import { FilterGroup } from "./types/FilterPickerProps"
import FilterPickerItem from "./FilterPickerItem"
import { DynamicFilterItem, FilterOp } from "./types/Filter"

export default React.memo(function FGroup(props: FilterGroup) {
  const handleOpChange = (op: FilterOp) => {
    props.onOpChange(op, props.level)
  }

  const filters = props.filter.filters || []

  return (
    <div className={`fp-group level-${props.level}`}>
      {filters.map((filter: DynamicFilterItem, i: number) => {
        if ("filters" in filter) {
          // @ts-ignore
          return FGroup({
            ...props,
            level: props.level + 1,
            // @ts-ignore
            filter,
          })
        }

        return (
          <FilterPickerItem
            payload={props.payload}
            onChange={props.onChange}
            onOpChange={handleOpChange}
            filter={filter}
            table={props.table}
            op={props.filter.op}
            i={i}
          />
        )
      })}
    </div>
  )
})
