import {__get, __post, getActionURL, getQuery} from "../utils/request"
import {WorkspaceActions} from "./actions/WorkspaceActions";
import {BaseActions} from "./actions/BaseActions";
import {TableActions} from "./actions/TableActions";
import {RowActions} from "./actions/RowActions";
import {ViewActions} from "./actions/ViewActions";
import {AddonActions} from "./actions/AddonActions";
import {Q} from "../containers/workspace/types/Q";
import {isEmpty} from "../utils/is";
import store from "./index";
import {AppActions} from "./actions/AppActions";

export type ActionType =
  AppActions
  | WorkspaceActions
  | BaseActions
  | TableActions
  | ViewActions
  | RowActions
  | AddonActions

function getActionUrl(action: ActionType) {
  const firstLetter: string = (action as string).substr(0, 1)

  switch (firstLetter) {
    case 'p':
      return 'app'
    case 'w':
      return 'workspace'
    case 'b':
      return 'base'
    case 't':
      return 'table'
    case 'v':
      return 'view'
    case 'a':
      return 'addon'

    default:
      throw new ReferenceError('Что-то не так')
  }
}

const mapActionUrls: Map<string, string> = new Map([
  [AppActions.UTILITY, 'utility'],
  [AppActions.ENTITIES, 'entities'],

  [AddonActions.READ_RECORDS, 'records'],

  [WorkspaceActions.REMOVE, 'remove'],
  [WorkspaceActions.UPDATE, 'update'],
  [WorkspaceActions.CREATE, 'create'],
  [WorkspaceActions.UPDATE_ROLE, 'updatePermissions'],
  [WorkspaceActions.REMOVE_COLLABORATOR, 'removeCollaborator'],
  [WorkspaceActions.INVITE_COLLABORATOR, 'invite'],
  [WorkspaceActions.CREATE_BASE, 'createBase'],
  [WorkspaceActions.ADDONS_DATA, 'addonsData'],
  [WorkspaceActions.UTILITY, 'utility'],
  [WorkspaceActions.FETCH_BASES, 'bases'],

  [BaseActions.REMOVE as string, 'remove'],
  [BaseActions.UPDATE as string, 'update'],
  [BaseActions.UPDATE_PERMISSIONS as string, 'updatePermissions'],
  [BaseActions.CREATE_TABLE as string, 'createTable'],
  [BaseActions.REMOVE_TABLE as string, 'removeTable'],
  [BaseActions.FETCH_TABLES as string, 'tables'],

  [TableActions.CREATE as string, 'create'],
  [TableActions.REMOVE as string, 'remove'],
  [TableActions.UPDATE as string, 'update'],
  [TableActions.UPDATE_COLUMN as string, 'updateColumn'],
  [TableActions.CREATE_COLUMN as string, 'createColumn'],
  [TableActions.REMOVE_COLUMN as string, 'removeColumn'],
  [TableActions.CREATE_ROW, 'createRow'],
  [TableActions.DELETE_ROW, 'deleteRow'],
  [TableActions.UPDATE_CELL, 'updateCell'],
  [TableActions.UPDATE_ROW, 'updateRow'],
  [TableActions.CALL_ACTION, 'callAction'],
  [TableActions.CREATE_VIEW, 'createView'],
  [TableActions.UPDATE_VIEW_COLUMN, 'updateViewColumn'],
  [TableActions.UPDATE_VIEW, 'updateView'],
  [TableActions.UPDATE_VIEW_FILTER, 'updateView'],
  [TableActions.FILTER_ROWS, 'filter'],
  [TableActions.CALENDAR_ROWS, 'calendar'],
  [TableActions.READ_RECORDS, 'records'],

  [AddonActions.CREATE_ROW, 'submit']
])

export function getActionItem(type: ActionType, id: string | null, queryString: any = {}): Promise<any> {
  const url = getActionURL(
    getActionUrl(type),
    mapActionUrls.get(type) as string,
    id
  )

  return __get(url + getQuery(queryString))
}

export function fetchItemWithoutId(type: ActionType, q?: Q[]) {
  const _id: string = mapActionUrls.get(type)
  return fetchItem(type, _id, {}, q)
}

export function fetchItemWithAction(type: ActionType, id: string | null) {
  return (dispatch): Promise<any> => {
    const url = getActionURL(
      getActionUrl(type),
      mapActionUrls.get(type) as string,
      id
    )

    const request = __get(url)

    request.then(payload => {
      if (id) {
        payload.id = id
      }

      dispatch({type, payload})
    })

    return request
  }
}

export function fetchItem(type: ActionType, id: string | null, queryString: any = {}, q?: Q[]) {
  return (dispatch): Promise<any> => {
    if (q && !isEmpty(q)) {
      queryString.q = q.join(',')
    }

    let url: string = getActionURL(getActionUrl(type), null, id) + getQuery(queryString)

    const request = __get(url)

    request.then(payload => {
      dispatch({type, payload})
    })

    return request
  }
}

export function callAction(type: ActionType, id: string | null, payload: any = {}) {
  return (dispatch) => {
    const url = getActionURL(getActionUrl(type), mapActionUrls.get(type) as string, id)
    const payloadToServer = Object.assign({}, payload)

    if (!payload._id) {
      payload._id = id
    }

    dispatch({type, payload})

    const request = __post(url, payloadToServer)

    request.then(result => {
      if (id) {
        payload.id = id
      }

      dispatch({type, payload, result})
    })

    return request
  }
}

export function runAction(type: ActionType, id: string | null, payload: any = {}) {
  return callAction(type, id, payload)(store.dispatch)
}


// ВСЕ, ЧТО ВЫШЕ - УДАЛИТЬ

export function disturbEntity(args: DisturbEntityArguments) {
  return (dispatch) => {
    const {entity, id, payload = {}} = args
    const url = getActionURL(getActionUrl(entity), mapActionUrls.get(entity) as string, id)
    const payloadToServer = Object.assign({}, payload)

    if (id) {
      payload.id = id
    }

    dispatch({type: entity, payload})

    const request = __post(url, payloadToServer)

    request.then(result => {
      dispatch({type: entity, payload, result})
    })

    return request
  }
}

export function load({entity, id, action, queryString, q}: LoadEntityArguments): Promise<any> {
  const type: ActionType = entity || action
  const urlAction: string = action && !entity ? action : ''

  let url = getActionURL(
    getActionUrl(type),
    urlAction && mapActionUrls.get(urlAction) as string,
    id
  )

  if (queryString || q) {
    const query = Object.assign({}, queryString)

    if (q && q.length > 0) {
      query.q = q
    }

    url += getQuery(query)
  }

  return __get(url)
}

export function loadEntity(args: LoadEntityArguments) {
  return (dispatch): Promise<any> => {
    const request = load(args)

    request.then(payload => {
      dispatch({
        type: args.entity || args.action,
        payload,
        id: args.id
      })
    })

    return request
  }
}

export type DisturbEntityArguments = {
  entity: ActionType
  id: string
  payload?: any
}

export type LoadEntityArguments = {
  entity?: ActionType
  id?: string
  action?: ActionType
  queryString?: any
  q?: Q[]
}
