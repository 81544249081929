import React from 'react'
import { GridCellRendererProps } from './types/GridRenderer'
import {
  baseRendererFactory,
  formRendererFactory,
  gridRendererFactory,
  groupRendererFactory,
  inlineFormRendererFactory,
  RendererClassNames__,
  simpleRendererFactory,
} from './definitions'
import { BaseRendererProps } from './types/BaseRenderer'
import { GroupRendererProps } from './types/GroupRenderer'
import { FormRendererProps } from './types/FormRenderer'
import { cn } from '../irv-ui/utils/cn'
import { ColumnType } from '../../general/types/ColumnType'
import { SimpleRendererProps } from './types/SimpleRendererProps'
import { AddonFormProps } from './types/AddonFormProps'

export function GridRendererFactory(props: GridCellRendererProps) {
  const Renderer = gridRendererFactory(props.column)

  return <Renderer {...props} />
}

export function BaseRendererFactory(props: BaseRendererProps) {
  const Renderer = baseRendererFactory(props.column)

  return <Renderer {...props} />
}

export function GroupRendererFactory(props: GroupRendererProps) {
  const Renderer = groupRendererFactory(props.column)

  return <Renderer {...props} />
}

function BaseFormRendererFactory(
  Renderer: React.FC<FormRendererProps>,
  props: FormRendererProps,
) {
  if (!Renderer) {
    return null
  }

  const { label, column, error, formOptions } = props
  const className = cn({
    'irv-frm-row': true,
    error: !!error,
    required: formOptions?.required,
  })

  const rendererClassName: string = RendererClassNames__[column.type]

  // Ебанный хук. Как дату изменю - убрать
  const frmValueClassName = cn({
    'irv-frm-value': true,
    lr: column.type === ColumnType.LINK_TO_RECORD,
  })

  return (
    <div className={className}>
      {label !== false && (
        <label className={'irv-frm-label'} htmlFor={column._id}>
          {label || column.name}
        </label>
      )}

      {formOptions?.description && (
        <div className={'irv-frm-description'}>{formOptions?.description}</div>
      )}

      <div className={frmValueClassName}>
        <Renderer {...props} className={rendererClassName} id={column._id} />
      </div>

      {error && (
        <div className={'irv-frm-error'}>{error}</div>
      )}
    </div>
  )
}

export function FormRendererFactory(props: FormRendererProps) {
  return BaseFormRendererFactory(formRendererFactory(props.column), props)
}

export function InlineFormRendererFactory(props: FormRendererProps) {
  return BaseFormRendererFactory(inlineFormRendererFactory(props.column), props)
}

export function SimpleRendererFactory(props: SimpleRendererProps) {
  const Renderer = simpleRendererFactory(props.column)
  const className: string = cn(
    'irv-smlp',
    RendererClassNames__[props.column.type],
  )

  return <Renderer {...props} className={className} />
}
