import React, { memo, useCallback, useEffect, useState } from "react"
import { useKeyPress } from "../../hooks/useKeyPress"
import { getValue } from "../../../general/utils/value"
import { EventKey, isKey } from "../../../utils/dom"
import { GridCellRendererProps } from "../types/GridRenderer"
import { GridColumn } from "../../grid/types/GridColumn"
import { useGridRendererClassName } from "../hooks/useGridRendererClassName"

type Props = GridCellRendererProps & {
  renderer?: (column: GridColumn, value: any) => React.ReactElement
  className?: string
  processValue?: (value) => any
}

const GridTextRenderer: React.FC<Props> = ({
  column,
  active,
  payload,
  row,
  renderer,
  className,
  processValue
}) => {
  const [value, setValue] = useState(getValue(payload.table, column, row))
  const [isEdit, setEdit] = useState<boolean>(false)
  const cn = useGridRendererClassName(column.type, isEdit, className)

  const pressedKey = useKeyPress(null, active, row._id + column._id)

  const handleChange = e => {
    if (processValue) {
      setValue(
        processValue(e.target.value)
      )
    } else {
      setValue(e.target.value)
    }
  }

  const handleDblClick = useCallback(() => {
    setEdit(true)
  }, [])

  const presentation = (
    <div className={cn} onDoubleClick={handleDblClick}>
      {renderer ? renderer(column, value) : value}
    </div>
  )

  const saveValue = useCallback(() => {
    setEdit(false)

    if (getValue(payload.table, column, row) !== value) {
      payload.onChange(row._id, column, value)
    }
    // eslint-disable-next-line
  }, [row, value, column, payload])

  useEffect(() => {
    setValue(getValue(payload.table, column, row))
  }, [payload.table, column, row])

  useEffect(() => {
    // console.log(pressedKey, value, active)
    if (!pressedKey || pressedKey.ctrlKey || pressedKey.metaKey) {
      return
    }

    if (isEdit) {
      if (isKey(pressedKey.key, EventKey.ESC)) {
        setEdit(false)
        setValue(getValue(payload.table, column, row))
      } else if (isKey(pressedKey.key, EventKey.ENTER)) {
        saveValue()
      }
    } else {
      if (pressedKey.key.length === 1) {
        setTimeout(_ => setValue(pressedKey.key))
        setEdit(true)
      } else if (isKey(pressedKey.key, EventKey.ENTER)) {
        setEdit(true)
      }
    }
    // eslint-disable-next-line
  }, [pressedKey, active])

  if (payload.readonly) {
    return presentation
  }

  if (isEdit) {
    return (
      <input
        className={cn}
        value={value}
        autoFocus
        onChange={handleChange}
        onBlur={saveValue}
      />
    )
  }

  return presentation
}

export default memo(GridTextRenderer)
