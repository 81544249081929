export enum ColumnType {
    TEXT = 'text',
    LONG_TEXT = 'long',
    SINGLE_SELECT = 'single-select',
    MULTIPLE_SELECT = 'mul-select',
    DATE = 'date',
    EMAIL = 'email',
    CHECKBOX = 'checkbox',
    PHONE = 'phone',
    LINK = 'link',
    NUMBER = 'number',
    LINK_TO_RECORD = 'record',
    COUNT = 'count',
    FORMULA = 'formula',
    LOOKUP = 'lookup',
    ROLLUP = 'rollup',
    USER = 'user',
    CREATION_DATE = 'createdOn',
    CREATOR = 'createdBy',
    MODIFIED_BY = 'modifiedBy',
    MODIFIED_ON = 'modifiedOn',
}