import {FilterOperators} from '../types/FilterOperators'
import {asArray} from '../utils/array'

/*eslint default-case: 0*/

function filterUserRole(rule, ids, currentUserId) {
  switch (rule.op) {
    case FilterOperators.IS: {
      if (rule.value === 'currentUser') {
        return ids.length === 1 && ids[0] === currentUserId
      }

      const aValue = asArray(rule.value)

      if (aValue.length !== ids.length) {
        return false
      }

      return true !== asArray(rule.value).some((id, index) => {
        if (!id) {
          return true
        }

        return id !== ids[index]
      })
    }

    case FilterOperators.IS_NOT: {
      if (rule.value === 'currentUser') {
        return ids.length === 1 && ids[0] !== currentUserId
      }

      const aValue = asArray(rule.value)

      if (aValue.length !== ids.length) {
        return false
      }

      return true !== asArray(rule.value).some((id, index) => {
        if (!id) {
          return true
        }

        return id === ids[index]
      })
    }

    case FilterOperators.IS_ANY_OF: {
      const rowIds = asArray(rule.value).map(id => {
        if (id === 'currentUser') {
          return currentUserId
        }

        return id
      })

      return rowIds.some(id => ids.includes(id))
    }
  }

  return false
}

export {filterUserRole}