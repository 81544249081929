import {UserRoles} from '../types/UserRoles'

const getWSRole = (ws, user) => {
  const wsUser = ws.users[user.id] || null

  // Чтобы не палили id.
  if (!wsUser || wsUser.removedOn) {
    return UserRoles.WITHOUT_ACCESS
  }

  return wsUser.role
}


const userEntityRole = (user, {app, ws, base, table}) => {
  const userId = user._id
  const wsUser = ws.users[userId]

  if (!wsUser) {
    return UserRoles.WITHOUT_ACCESS
  }

  if (wsUser.removedOn) {
    return UserRoles.REMOVED_USER
  }

  if (wsUser.role === UserRoles.OWNER) {
    return UserRoles.OWNER
  }

  if (base && base.limitedAccess) {
    const limitedAccess = base.limitedAccess || {}

    return limitedAccess[userId] || UserRoles.WITHOUT_ACCESS
  }

  return wsUser.role
}


export {getWSRole}
export {userEntityRole}
