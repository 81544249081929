import React, { memo, useCallback } from 'react'
import { RendererClassNames } from '../definitions'
import BaseGridInputRenderer from '../utils/BaseGridInputRenderer'
import { GridCellRendererProps } from '../types/GridRenderer'
import { GridColumn } from '../../grid/types/GridColumn'

const GridPhoneRenderer: React.FC<GridCellRendererProps> = props => {
  const renderer = useCallback(
    (column: GridColumn, value: any) => {
      if (props.active) {
        return (
          <a href={value} rel="noopener noreferrer" target={'_blank'}>
            {value}
          </a>
        )
      }

      return value
    },
    [props],
  )

  return (
    <BaseGridInputRenderer
      {...props}
      renderer={renderer}
      className={RendererClassNames.EMAIL}
    />
  )
}

export default memo(GridPhoneRenderer)
