import React from 'react'
import { TimelineProps, ProcessedEvent, ProcessedResource, ResourceGroup, ResourceOrGroup } from '../types/Timeline'
import { Preset } from '../types/Presets'
import { Event } from '../types/Event'
import { Resource } from '../types/Resource'
import HeaderColumns from './components/HeaderColumns'
import TimelineResource from './components/TimelineResource'
import TimelineEvent from './components/TimelineEvent'
import HeaderPreset from './components/HeaderPreset'
import DayAndMonth from './presets/DayAndMonth'
import dayjs from 'dayjs'

import '../style/timeline.scss'

interface State {
  columnsWidth: number
  rowsWidth: number
  rowsHeight: number
  preset: Preset
  events: Event[]
  processedResources: Array<ResourceOrGroup>
  collapsedGroups: string[]
}

class Timeline extends React.PureComponent<TimelineProps, State> {
  // @ts-ignore
  state: State = {
    collapsedGroups: []
  }

  isHScrolled: boolean = false
  isDragging: boolean = false

  root: React.RefObject<HTMLDivElement> = React.createRef()
  resourcesEl: React.RefObject<HTMLDivElement> = React.createRef()
  headerScroller: React.RefObject<HTMLDivElement> = React.createRef()
  hScroller: React.RefObject<HTMLDivElement> = React.createRef()
  grid: React.RefObject<HTMLDivElement> = React.createRef()
  body: React.RefObject<HTMLDivElement> = React.createRef()
  headerEl: React.RefObject<HTMLDivElement> = React.createRef()

  static getDerivedStateFromProps = (props: TimelineProps, state: State) => {
    if (!state.columnsWidth) {
      const preset = new DayAndMonth(props.startDate, props.endDate)
      const eventsByResource = props.events
        .filter(event => preset.isMatched(event))
        .reduce((result, event) => {
          const resourceId = event.resourceId

          if (!resourceId) {
            return result
          }

          if (!result[resourceId]) {
            result[resourceId] = []
          }

          result[resourceId].push({
            ...event,
            start: dayjs(event.start),
            end: dayjs(event.end || event.start)
          })

          return result
        }, {})

      let processedResources: ResourceOrGroup[] = []

      const resources = !props.group
        ? props.resources.concat()
        : props.resources.sort((a, b) => {
          const propA = String(a[props.group]) || ''
          const propB = String(b[props.group]) || ''

          return propA.localeCompare(propB)
        })

      if (props.group) {
        const createdGroups: { [group: string]: ResourceGroup } = {}

        resources.forEach((resource) => {
          const groupKey = resource[props.group]

          if (!createdGroups[groupKey]) {
            createdGroups[groupKey] = {
              resources: [],
              isGroup: true,
              groupValue: groupKey,
              height: preset.height
            }

            processedResources.push(createdGroups[groupKey])
          }

          const events = Timeline.processEvents(eventsByResource[resource.id] || [], preset)
          const pResource = {
            resource,
            events,
            height: preset.calculateHeight(events)
          }

          createdGroups[groupKey].resources.push(pResource)

          processedResources.push(pResource)
        })
      } else {
        resources.forEach((resource) => {
          const events = Timeline.processEvents(eventsByResource[resource.id] || [], preset)
          const pResource = {
            resource,
            events: events,
            height: preset.calculateHeight(events)
          }
  
          processedResources.push(pResource)
        }) 
      }

      if (props.hideEmptyResources) {
        processedResources = processedResources.filter(r => {
          if (r.isGroup) {
            return true
          }

          return ((r as ProcessedResource).events || []).length > 0
        })
      }

      const rowsHeight = processedResources.reduce((totalHeight, resource) => {
        return totalHeight + resource.height
      }, 0)

      return {
        columnsWidth: props.columns.reduce((res: number, column) => {
          return res + (column.width || 100)
        }, 0),
        preset,
        rowsWidth: preset.width,
        rowsHeight,
        processedResources
      }
    }

    return null
  }

  static processEvents = (events: Event[], preset: Preset): ProcessedEvent[] => {
    const resourceEvents: Event[] = events.concat().sort((e0, e1) => {
      return +dayjs(e0.start) - +dayjs(e1.start)
    })

    return resourceEvents.map(event => {
      const evDimens = preset.getEventDimentions(event, resourceEvents)

      return {
        event,
        x: evDimens.x,
        y: evDimens.y,
        width: evDimens.width,
        height: preset.height
      }
    })
  }

  isCollapsed = (r: Resource): boolean => {
    const groupValue = r[this.props.group]

    return this.state.collapsedGroups.includes(groupValue)
  }

  initColumnsResize = () => {
  }

  handleMouseDown = (e) => {
    const isAlowedToDrag: boolean = e.target.classList.contains('tl-row') || e.target.classList.contains('tl-group')
    const grid = this.grid.current
    const body = this.body.current

    if (isAlowedToDrag && grid) {
      const startX = grid.scrollLeft
      const startY = body ? body.scrollTop : 0
      const initClientX = e.nativeEvent.clientX
      const initClientY = e.nativeEvent.clientY

      const isMoved = e => {
        if (Math.abs(e.clientX - initClientX) < 10) {
          return false
        }

        if (Math.abs(e.clientY - initClientY) < 10) {
          return false
        }

        return true
      }

      const mover = (moveEvent) => {
        grid.scrollLeft = startX - (moveEvent.clientX - initClientX)

        if (isMoved(moveEvent)) {
          this.isDragging = true
        }

        if (body) {
          body.scrollTop = startY - (moveEvent.clientY - initClientY)
        }
      }

      document.body.classList.add('grabbing')

      const releaseMover = () => {
        window.removeEventListener('mousemove', mover, false)
        window.removeEventListener('mouseup', releaseMover, false)

        document.body.classList.remove('grabbing')

        setTimeout(_ => {
          this.isDragging = false
        })
      }

      window.addEventListener('mousemove', mover, false)
      window.addEventListener('mouseup', releaseMover, false)
    }

  }

  handleScroll = (e) => {
    if (this.isHScrolled) {
      return
    }

    this.headerScroller.current.scrollLeft = e.target.scrollLeft
    this.hScroller.current.scrollLeft = e.target.scrollLeft
  }

  handleNoSplitScroll = (e) => {
    this.headerEl.current.scrollLeft = e.target.scrollLeft
  }

  handleRowEnter = (e) => {
    const row = e.currentTarget

    if (!row) {
      return // o_O
    }

    const resourceId = row.dataset.id

    requestAnimationFrame(_ => {
      const root = this.root.current
      const rowElements = root.querySelectorAll(`[data-id="${resourceId}"]`)

      for (let i = 0; i < rowElements.length; i++) {
        rowElements[i].classList.add('tl-row-hover')
      }
    })
  }

  handleRowLeave = () => {
    requestAnimationFrame(_ => {
      const hoveredElements = this.root.current.querySelectorAll('.tl-row-hover')

      for (let i = 0; i < hoveredElements.length; i++) {
        hoveredElements[i].classList.remove('tl-row-hover')
      }
    })
  }

  handleHScrollMouseDown = () => {
    this.isHScrolled = true

    const hScroller = this.hScroller.current
    const headerScroller = this.headerScroller.current
    const grid = this.grid.current

    const handler = (e: any) => {
      headerScroller.scrollLeft = e.target.scrollLeft
      grid.scrollLeft = e.target.scrollLeft
    }

    const mouseUphandler = () => {
      hScroller.removeEventListener('scroll', handler, false)
      window.removeEventListener('mouseup', mouseUphandler, false)

      this.isHScrolled = false
    }

    hScroller.addEventListener('scroll', handler, false)
    window.addEventListener('mouseup', mouseUphandler, false)
  }

  handleGroupClick = (e) => {
    const index = +e.currentTarget.dataset.index
    const group = this.state.processedResources[index] as ResourceGroup

    if (this.isDragging) {
      return
    }

    if (group) {
      const collapsedGroups = this.state.collapsedGroups.concat()
      const index = collapsedGroups.indexOf(group.groupValue)

      if (-1 === index) {
        collapsedGroups.push(group.groupValue)
      } else {
        collapsedGroups.splice(
          index, 1
        )
      }

      const { processedResources, preset } = this.state
      const visibleResources = processedResources.filter(resource => resource.isGroup)
      const hideEmptyResources = this.props.hideEmptyResources

      const rowsHeight = (visibleResources as ResourceGroup[]).reduce((height: number, group: ResourceGroup) => {
        if (collapsedGroups.includes(group.groupValue)) {
          return height + preset.height
        }

        const resources = hideEmptyResources
          ? group.resources.filter(r => {
              if (r.isGroup) {
                return true
              }
    
              return ((r as ProcessedResource).events || []).length > 0
            })
          : group.resources

        return height + preset.height + resources.reduce((g, resource) => {
          return g + resource.height
        }, 0)
      }, 0)
      console.log(rowsHeight)
      this.setState({ collapsedGroups, rowsHeight })
    }
  }

  renderRows = (onlyCanvas?: boolean) => {
    if (onlyCanvas) {
      return this.renderCanvas()
    }

    return (
      <div className="tl-grid" onScroll={this.handleScroll} ref={this.grid}>
        {this.renderCanvas()}
      </div>
    )
  }

  renderResources = () => {
    const { columnsWidth, rowsHeight, processedResources } = this.state
    const { columns, groupRenderer: GroupRenderer, group } = this.props

    return (
      <div className="tl-resources" style={{width: columnsWidth}} ref={this.resourcesEl}>
        <div className="tl-resources-columns">
          {processedResources.map((r: ResourceOrGroup, i) => {
            if (r.isGroup) {
              const resource = (r as ResourceGroup).resources[0]
              const className = [
                'tl-group',
                resource && this.isCollapsed(resource.resource) ? 'collapsed' : ''
              ].filter(Boolean).join(' ')

              return (
                <div className={className} style={{height: r.height}} data-index={i} onMouseUp={this.handleGroupClick}>
                  <button className="exp"></button>
                  {GroupRenderer && <GroupRenderer group={r} />}
                </div>
              )
            }

            if (group && this.isCollapsed((r as ProcessedResource).resource)) {
              return null
            }

            const resource = (r as ProcessedResource).resource

            return (
              <TimelineResource 
                resource={resource} 
                columns={columns}
                height={r.height}
                key={resource.id}
              />
            )
          })}
        </div>
        <div className='spl' style={{ height: rowsHeight}} onMouseDown={this.initColumnsResize} />
      </div>
    )
  }

  renderCanvas() {
    const { rowsWidth, rowsHeight, preset, processedResources } = this.state
    const { eventWidth, columns } = preset
    const cells = Array.from(Array(columns))
      .map((_, i) => i)

    return (
      <div className="tl-canvas" style={{width: rowsWidth}}>
        <div className='tl-cells' style={{ height: rowsHeight}}>
          {cells.map(i => {
            const className = [
              'tl-cell', 
              preset.isWeekend(i) ? 'we' : ''
            ].filter(Boolean).join(' ')

            const style = {
              width: eventWidth,
              left: eventWidth * i
            }

            return (
              <div className={className} key={i} style={style} />
            )
          })}
        </div>

        {processedResources.map((r: ResourceOrGroup, i) => {
          if (r.isGroup) {
            return (
              <div className='tl-group' style={{height: r.height}} data-index={i} onMouseUp={this.handleGroupClick}/>
            )
          }

          if (this.props.group && this.isCollapsed((r as ProcessedResource).resource)) {
            return null
          }

          const { events, resource } = r as ProcessedResource

          return (
            <div className='tl-row' 
              data-id={resource.id}
              key={resource.id} 
              style={{ height: r.height }}
              onMouseEnter={this.handleRowEnter}
              onMouseLeave={this.handleRowLeave}
            >
              {events.map(event => (
                <TimelineEvent 
                  event={event.event} 
                  key={event.event.id} 
                  x={event.x} 
                  y={event.y}
                  height={event.height}
                  width={event.width}
                />
              ))}
            </div>
          )
        })}
      </div>
    )
  }

  renderBody() {
    if (false === this.props.split) {
      return (
        <div className="tl-body" onScroll={this.handleNoSplitScroll}>
          {this.renderResources()}
          {this.renderRows(true)}
        </div>
      )
    }

    return (
      <div className="tl-body" ref={this.body}>
        <div className="tl-clip">
          {this.renderResources()}
          {this.renderRows()}
        </div>
      </div>
    )
  }

  render() {
    const { columns, split, group} = this.props
    const { columnsWidth, preset, rowsWidth } = this.state
    const rootClassName = [
      'tl', 
      false === split ? 'no-split' : '',
      group ? 'grouped' : ''
    ].filter(Boolean).join(' ')

    return (
      <div className={rootClassName} ref={this.root} onMouseDown={this.handleMouseDown}>
        <div className='tl-header' ref={this.headerEl}>
          <HeaderColumns columns={columns} width={columnsWidth} />
          <div className="tl-header-pr">
            <div className="tl-header-pr-scroller" ref={this.headerScroller}>
              <HeaderPreset preset={preset}/>
            </div>
          </div>
        </div>

        {this.renderBody()}

        <div className="tl-h-scroll" style={{ marginLeft: columnsWidth }} ref={this.hScroller} onMouseDown={this.handleHScrollMouseDown}>
          <div className="tl-h-scroll-gag" style={{width: rowsWidth}}/>
        </div>
      </div>
    )
  }
}

export default Timeline