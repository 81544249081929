import {generatePath, matchPath} from "react-router-dom"
import {createBrowserHistory} from "history"
import {RowSharingLink} from "../components/grid/types/GridRow"

export const history = createBrowserHistory()

export enum Pages {
  MAIN = '/',
  MAIN_BASE = '/:baseId(b\\w+)',
  MAIN_WORKSPACE_NO_VIEW = '/:tableId(t\\w+)',
  MAIN_WORKSPACE = '/:tableId(t\\w+)/:viewId(v\\w+)/:rowId?',
  TABLE_BASE = '/:tableId(t\\w+)/:viewId(v\\w+)',
  LOGIN = '/login',
  RESET_PASSWORD = '/r/:token',
  RESET_PASSWORD_ERROR = '/re/',
  ROW_DETAILS = '/:link(s\\w+)',
  NOT_FOUND = '/404',
}

export function matchUrl(url: string, params): any {
  return matchPath(url, params)
}

export function buildLink(page: Pages, params: any) {
  return generatePath(page, params)
}

export function openPage(page: Pages, params: any = {}, replace?: boolean) {
  const url = buildLink(page, params)

  if (replace) {
    window.history.replaceState({}, "", url)
  } else {
    window.history.pushState({}, "", url)
  }
}

export function createShareLink(rowId: string, link: RowSharingLink): string {
  return window.location.origin + `/s${link._id}${rowId}`
}

export function stopPropagation(e) {
  e.stopPropagation()
}

export function openDetailsPage(rowId: string) {
  const matched: any = matchUrl(document.location.pathname, {
    path: Pages.MAIN_WORKSPACE
  })

  const params = {
    ...matched.params,
    rowId
  }

  history.push(
    buildLink(Pages.MAIN_WORKSPACE, params)
  )
}

export function setSearchURL(data: any) {
  const url: string[] = [
    window.location.pathname
  ]

  try {
    const query = Object.entries(data)
      .map(el => el.join('='))
      .join('&')

    url.push(query)

    history.push(
      url.join('?')
    )
  } catch (e) {

  }
}
