export enum ViewActions {
  UPDATE_COLUMN = 'v1',
  SORT_COLUMN_A_Z = 'v2',
  SORT_COLUMN_Z_A = 'v3',
  FILTER_COLUMN = 'v4',
  GROUP_COLUMN = 'v5',
  HIDE_COLUMN = 'v6',
  SHOW_COLUMN = 'v7',
  DUPLICATE = 'v8'
}
