import React, { useState } from 'react'
import { BaseView } from '../../../../types/BaseView'
import { BaseTable } from '../../../../types/BaseTable'
import { isEmpty } from '../../../../general/utils/is'
import DynamicFilter from './DynamicFilter'
import { getDynamicFilterValue } from '../../../../general/filters/utils'
import { setSearchURL } from '../../../../utils/navigation'

import './style.scss'

interface Props {
  view: BaseView
  table: BaseTable
  onFilter: (filters: any) => void
}

export default React.memo(function DynamicFiltersContainer(props: Props) {
  const { view, table } = props
  const [changedFilters, setFilters] = useState({})

  if (!view.dynamicFilter || isEmpty(view.dynamicFilter.filters)) {
    return null
  }

  const onChange = (filterId: string, filterValue: any) => {
    const filters = {
      ...changedFilters,
      [filterId]: filterValue,
    }

    setFilters(filters)
    props.onFilter(filters)

    setSearchURL(filters)
  }

  const getDynamicFilters = () =>
    view.dynamicFilter.filters.map(f => {
      return {
        ...f,
        value: changedFilters[f._id] || getDynamicFilterValue(f),
      }
    })

  return (
    <div className={'df nsb'}>
      {getDynamicFilters().map((f, i) => (
        <DynamicFilter key={i} filter={f} table={table} onChange={onChange} />
      ))}
    </div>
  )
})
