import React, {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { cn } from '../../utils/cn'
import { isKeyPressed, KEY_CODES } from '../../utils/keyboard'
import { isMobileDevice } from '../../utils/platform'

interface Props {
  children?: (item: any, checkMark?: ReactNode) => ReactNode
  items: { value: any } & any
  selectedKeys: string[]
  dropdownWidth?: number
  onSelect: (item) => void
}

const scrollTo = (refObj, selectedItem) => {
  try {
    const domEl = refObj.querySelector(`[data-el="${selectedItem}"]`)
    domEl.scrollIntoViewIfNeeded()
  } catch (e) {}
}

const Arrow = () => (
  <svg
    focusable="false"
    className={'mark'}
    width={12}
    height={12}
    role="img"
    fill={'#0052cc'}>
    <path d="M4.5 10a1.022 1.022 0 0 1-.799-.384l-2.488-3a1 1 0 0 1 1.576-1.233L4.5 7.376l4.712-5.991a1 1 0 1 1 1.576 1.23l-5.51 7A.978.978 0 0 1 4.5 10z"></path>
  </svg>
)

const SelectItems = (props: Props, ref: any) => {
  const { selectedKeys, items, dropdownWidth, children } = props
  const [selectedItem, setSelectedItem] = useState(selectedKeys[0])

  const onMouseEnter = useCallback(
    e => {
      const target = e.target.closest('.irv-select-item') || e.taget
      const value = target.dataset.el

      setSelectedItem('' + value)
    },
    [items, selectedItem],
  )

  const onMouseDown = useCallback(e => {
    e.stopPropagation()
  }, [])

  const onSelect = e => {
    const target = e.target.closest('.irv-select-item') || e.taget
    const domValue = target.dataset.el

    e.stopPropagation()

    props.onSelect(domValue)
  }

  const onKeyDown = useCallback(
    e => {
      if (
        !isKeyPressed(e.key, [
          KEY_CODES.ARROW_DOWN,
          KEY_CODES.ARROW_UP,
          KEY_CODES.ENTER,
        ])
      ) {
        return
      }

      e.preventDefault()
      e.stopPropagation()

      if (isKeyPressed(e.key, [KEY_CODES.ENTER])) {
        return props.onSelect(selectedItem)
      }

      const currentIndex = items.findIndex(
        ({ value }) => '' + value === '' + selectedItem,
      )

      let nextIndex

      if (isKeyPressed(e.key, [KEY_CODES.ARROW_DOWN])) {
        nextIndex =
          currentIndex === -1 ? 0 : Math.min(currentIndex + 1, items.length - 1)
      } else {
        nextIndex =
          currentIndex === -1 ? items.length - 1 : Math.max(currentIndex - 1, 0)
      }

      const nextValue = items[nextIndex]

      if (nextValue) {
        setSelectedItem('' + nextValue.value)
        scrollTo(ref.current, nextValue.value)
      }
    },
    [selectedItem, items],
  )

  const isActive = item => selectedKeys.includes(item.value)
  const isSelected = isMobileDevice()
    ? () => false
    : item => '' + item.value === selectedItem

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown, false)

    return () => {
      document.removeEventListener('keydown', onKeyDown, false)
    }
  }, [onKeyDown])

  useEffect(() => {
    if (!selectedItem || !ref) {
      return
    }

    const timer = setTimeout(() => scrollTo(ref.current, selectedItem), 200)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  const style = useMemo(() => {
    if (dropdownWidth) {
      return { width: dropdownWidth }
    }

    return null
  }, [dropdownWidth])

  return (
    <div className={'irv-select-items sbr'} style={style} ref={ref}>
      {!items.length && <p className={'no-data'}>No Data</p>}

      {items.map(item => {
        const selected = isSelected(item)
        const active = isActive(item)

        return (
          <div
            key={item.value}
            tabIndex={-1}
            className={cn('irv-select-item', { selected, active })}
            data-el={String(item.value)}
            onMouseOver={onMouseEnter}
            onMouseDown={onMouseDown}
            onClick={onSelect}>
            {children ? (
              children(item, active ? <Arrow /> : null)
            ) : (
              <div className={'irv-item'}>
                {item.text || item.value || ''}
                {active && <Arrow />}
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default forwardRef(SelectItems)
