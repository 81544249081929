import React, { useCallback, useMemo } from 'react'
import { SettingsItemComponentProps } from '../types'
import ColumnSelector from '../../columnSelector/ColumnSelector'
import { isAllowedForMirror } from '../../../../utils/column'

export default React.memo((props: SettingsItemComponentProps) => {
  const { params, table, onAction } = props

  const hiddenColumns = useMemo(() => {
    return table.columns.filter(c => !isAllowedForMirror(c)).map(c => c._id)
  }, [table])

  const handleColumnChange = useCallback((columnId: string) => {
    onAction({
      columnId,
    })
  }, [onAction])

  return (
    <div className={'column-settings-crow'}>
      <div className="column-settings-cell">
        <div className={'column-settings-label'}>Колонка</div>
        <div className={'column-settings-ctrl c-selector'}>
          <ColumnSelector
            table={table}
            placeholder={'Выберите поле'}
            selectedItem={params.columnId}
            hiddenColumns={hiddenColumns}
            onChange={handleColumnChange}
          />
        </div>
      </div>
    </div>
  )
})
