
// Еще не сделано
// https://dribbble.com/shots/16262541-Table-Component-Dashboard-UI-kit
// https://dribbble.com/shots/18282368-Briefing-Process-Service-Details

export enum TableTheme {
  // Показует, что таблица будет с роу-ховер
  ACTIONABLE = 'abl',

  /*
  * Базовая тема
  * https://dribbble.com/shots/15449715-CRM-Mac-Application/attachments/7220411?mode=media
  * https://dribbble.com/shots/16929108-New-Subscribers/attachments/11999106?mode=media
  * */
  BASIC = 'bs',

  /*
  * Типо главная
  *
  * https://dribbble.com/shots/15270061-Payfit-Table-component-Midnight-Design-system/attachments/7022109?mode=media
  * */
  MAIN = 'mn',

  /*
  * Заполненная нахуй
  *
  * https://dribbble.com/shots/19262105-Nook-Members-Settings
  * */
  GRAY = 'hg',

  /*
  * Просто беленькая без выебонов
  * */
  WHITE = 'ww',
}
