import React from 'react'
import { SimpleRendererProps } from '../types/SimpleRendererProps'
import { format } from "../../../general/utils/date"
import { Checkbox } from "../../irv-ui"

const CheckboxRenderer: React.FC<SimpleRendererProps> = ({ value, className, }) => {
  if (!value) {
    return null
  }

  return (
    <div className={className}>
      <Checkbox checked={value} readOnly />
    </div>
  )
}

export default React.memo(CheckboxRenderer)
