import React, { useCallback, useEffect, useState } from 'react'
import Icon from '../../../../components/icon/Icon'
import { Button, Input, Select, Switch } from '../../../../components/irv-ui'
import {
  GridRow,
  RowSharingLink,
} from '../../../../components/grid/types/GridRow'
import { __get, __post } from '../../../../utils/request'
import { BaseTable } from '../../../../types/BaseTable'
import { createShareLink } from '../../../../utils/navigation'
import Loading from '../../../../components/loading/Loading'
import { BaseView } from '../../../../types/BaseView'

interface Props {
  row: GridRow
  table: BaseTable
  view: BaseView
}

const editingOptions = [
  { value: 1, text: 'Редагування заборонено' },
  { value: 2, text: 'Тільки авторизовані' },
  { value: 3, text: 'Будь-хто за посиланням' },
]

export default React.memo(function ShareContainer(props: Props) {
  const { row, table, view } = props
  const [share, setShare] = useState<RowSharingLink | null>()
  const [loading, setLoading] = useState<boolean>(false)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [isCopiedIndex, setCopiedState] = useState(-1)

  useEffect(() => {
    __get(`table/${table._id}/sharingState/${row._id}?viewId=${view._id}`).then(
      resp => {
        setShare(resp)
        setIsLoaded(true)
      },
    )
  }, [row, table])

  const editLevel = share?.editLevel ?? 1

  const handleSharingChange = useCallback(
    value => {
      const sharing = {
        viewId: view._id,
        published: value,
      }

      setLoading(true)

      __post(`table/${table._id}/sharingState/${row._id}`, sharing).then(
        resp => {
          setShare(resp)
          setLoading(false)
        },
      )
    },
    [share, view],
  )

  const handleEditChange = useCallback(
    editLevel => {
      if (!share || !share.viewId) {
        return
      }

      const data = {
        viewId: share.viewId,
        editLevel,
        published: true,
      }

      setLoading(true)

      __post(`table/${table._id}/sharingState/${row._id}`, data).then(resp => {
        console.log(resp)
        setShare(resp)
        setLoading(false)
      })
    },
    [share],
  )

  const handleCopy = (e, i) => {
    const input = e.target.parentNode.querySelector('input')

    try {
      navigator.clipboard.writeText(input.value).then(() => {
        setCopiedState(i)

        setTimeout(_ => {
          setCopiedState(-1)
        }, 2000)
      })
    } catch (e) {}
  }

  if (!isLoaded) {
    return (
      <div className={'share-row'}>
        <Loading delay={300} />
      </div>
    )
  }

  return (
    <div className={'share-row'}>
      <Switch
        className={'share-row-ctrl'}
        onChange={handleSharingChange}
        checked={!!share}>
        <Icon type={'earth'} />
        <span>Поделиться в Интернете</span>
      </Switch>

      {loading && <Loading delay={300} />}

      {!share && <div className={'share-row-container'} />}

      {share && (
        <div className={'share-row-container'}>
          <div className={'share-row-link'} key={share._id}>
            <div className={'share-row-details'}>
              <Input fit disabled value={createShareLink(row._id, share)} />
              <Button onClick={e => handleCopy(e, 0)}>
                {isCopiedIndex === 0 ? 'Скопировано!' : 'Копировать'}
              </Button>
            </div>

            <div className={'share-row-s-view'}>
              <div className={'share-row-label'}>Редагування запису</div>
              <div className={'share-row-ctrl'}>
                <Select
                  items={editingOptions}
                  value={editLevel}
                  onSelect={handleEditChange}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
})
