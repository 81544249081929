import { AppActions } from '../actions/AppActions'
import { TableActions } from '../actions/TableActions'

const initState: any = null

export default function rows(state = initState, action) {
  const { payload } = action
  switch (action.type) {
    case AppActions.INIT:
      return payload.app

    case TableActions.FETCH:
      return payload.app || state

    default:
      return state
  }
}
