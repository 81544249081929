import React from 'react'
import {BaseTable} from "../../../../../types/BaseTable";
import {cn} from "../../../../../utils/classname";
import Icon from "../../../../../components/icon/Icon";
import {lastUsage} from "../../../../../utils/storage";
import {Base} from '../../../../../types/Base';
import {Link} from 'react-router-dom';
import TableContextMenu from '../../../../../components/contextMenu/table/TableContextMenu';
import {ActionType} from "../../../../../store/CommonEffects";
import {Roles} from "../../../../../components/roles/Roles";
import {isRoleAllowed, UIRoleAction} from "../../../../../components/roles/RoleGateway";
import {isMob} from "../../../../../utils/is";

interface Props {
  base: Base
  table: BaseTable
  role: Roles
  active: boolean
  onMenuClick: (action: ActionType, payload?: any) => void
}

export default React.memo((props: Props) => {
  const {base, table, role, active, onMenuClick} = props
  const containerClassName = cn(
    'sb-table',
    active && 'active'
  )

  const {viewId} = lastUsage(base, table)
  const linkViewId: string = viewId || (table.views ? table.views[0]._id : '')
  const to: string = `/${table._id}/${linkViewId}`

  return (
    <div className={containerClassName}>
      <Link to={to} className={'tc'} title={table.name}>
        {table.name || 'Таблица без названия'}
      </Link>

      {!isMob() && isRoleAllowed(UIRoleAction.TABLE_EDIT, role) && (
        <TableContextMenu
          table={table}
          onMenuClick={onMenuClick}
        >
          <button className={'cha'}>
            <Icon type={'dots-horizontal'}/>
          </button>
        </TableContextMenu>
      )}
    </div>
  )
})
