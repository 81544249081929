import {Base} from "./Base"
import {Addon} from "./Addon";
import {Roles} from "../components/roles/Roles";
import {EyzyUser} from "./EyzyUser";

export interface Workspace<U = EyzyUser> {
  _id: string
  name: string
  bases: Base[]
  addons?: Addon[]
  roles: { [userId: string] : Roles }
  baseIds: string[]
  params?: {
    modes: WSModes[]
    showCollaborators?: boolean
  }
  // ВСЕ ПОЛЬЗОВАТЕЛИ, ДАЖЕ УДАЛЕННЫЕ
  collaborators: EyzyUser[]

  // Только активные
  activeCollaborators: EyzyUser[]

  // TODO: REMOVE УДОЛИТЬ НАХ
  _users: U[]
  userParams?: any
  users: string[]
  removedUsers: U[]
  wsUsers: U[]
}

export enum WSModes {
  SIMPLIFIED_NAV = 'simplifiedNav'
}
