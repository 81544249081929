import React, { useMemo } from 'react'
import { GridCellRendererProps } from '../types/GridRenderer'
import { getColumnById } from '../../../utils/get'
import { GridRendererFactory } from '../factory'
import { ColumnType } from "../../../general/types/ColumnType"

const GridMirrorRenderer: React.FC<GridCellRendererProps> = (
  props: GridCellRendererProps,
) => {
  const { column, payload } = props
  const { table } = payload

  const payloadCopy = useMemo(() => ({
    ...payload,
    readonly: true
  }), [payload])

  const mirroredColumn = useMemo(
    () => getColumnById(table, column.params.columnId),
    [table, column],
  )

  if (!mirroredColumn || mirroredColumn.type === ColumnType.MIRROR) {
    return null
  }

  return <GridRendererFactory {...props} payload={payloadCopy} column={mirroredColumn} />
}

export default React.memo(GridMirrorRenderer)
