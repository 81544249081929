import { View } from '../components/calendar/types/View'
import { Roles } from '../components/roles/Roles'
import {
  BaseFilter,
  DynamicViewFilters,
  ViewFilters,
} from '../components/filter/types/Filter'
import { CalendarViewType } from '../components/calendar/NEW__types/Calendar'
import { GridColumn } from '../components/grid/types/GridColumn'
import { GridRow } from '../components/grid/types/GridRow'
import { ViewDetailsProfile, ViewDetailsSettings, ViewDetailsType } from "./ViewDetails"

export interface ItemTitleBadge {
  col: string
  agg: AggregationType
  color?: string
  label?: string | boolean
}

export enum SortOrderType {
  DESC = 'desc',
  ASC = 'asc',
}

export enum AggregationType {
  NONE = 'none',
  EMPTY = 'empty',
  FILLED = 'filled',
  UNIQUE = 'unique',
  PERCENT_EMPTY = 'pEmpty',
  PERCENT_FILLED = 'pFilled',
  PERCENT_UNIQUE = 'pUnique',
  SUM = 'sum',
  COUNT = 'count',
}

export enum ViewVisibilityType {
  LIMITED = 'limited',
  LOCKED = 'locked',
  PERSONAL = 'personal',
}

export interface CalendarView {
  type: typeof View
  label: string
  value?: number // Если кастом - указать кол-во дней/месяцев
}

export interface BaseViewColumnParams {
  width?: number
  hidden?: boolean
  defaultValue?: any
}

export interface ViewGroups {
  _id: string
  columnId: string
  agg?: AggregationType
  clarification?: any
  sort?: SortOrderType
  ignoreListOrder?: boolean
}

export interface ViewGroupedRows {
  value: any
  column: GridColumn
  index: number
  rows?: GridRow[]
  groups?: Array<ViewGroupedRows>
}

// TODO REmove
export interface ViewGroupValue {
  rows: GridRow[]
  groupValue: any
  groupColumn: GridColumn
}

export interface ViewSort {
  columnId: string
  order?: SortOrderType
}

export interface ViewVisibility {
  type: ViewVisibilityType
  role?: Roles
  users?: string[]
}

/*
 * columnId - пользователь, сингле селект... Не нужен тип
 * dateField - в календаре
 * condition - все, блять, понятно )))))
 * */

export interface ViewColorCondition {
  color: string
  filter?: BaseFilter
}

export interface ViewColor {
  type?: 'row'
  otherwise?: 'string' // ELSE color
  col?: 'string'
  condition?: ViewColorCondition[]
}

export type ViewDateField_OLD<T = GridColumn> = {
  title: T
  template?: T
  start?: T
  end?: T
  interval?: T
  periodicity?: T
  iteration?: T
  repeat?: T
  repeatEnd?: T
  allDay?: T
}

export interface ViewDateField<T = GridColumn> {
  title: T
  start: T
  end?: T
  settings?: T
}

export interface BaseViewCalendar {
  fields?: ViewDateField<string>[]
  views: CalendarViewType[]
}

export interface ViewTimeline {
  template: string
  resource: string
  dates: { start: string; end?: string }[]
}

export interface BaseViewDetails {
  as: ViewDetailsType
  template: ViewDetailsProfile
  settings?: ViewDetailsSettings
  // Минимальный уровень шаринга. По дэфолту это ADMIN
  shareRole?: Roles
}

export interface BaseViewParams {
  color?: 'row'
  orderNumber?: boolean
  rowHeight?: number
  // REMOVE
  allowedViews?: View[]
  views?: CalendarViewType[]
  calendar?: BaseViewCalendar
}

export interface BaseView {
  _id: string
  name: string
  type: string
  filters?: ViewFilters
  filter?: BaseFilter
  dynamicFilter?: DynamicViewFilters
  groups?: ViewGroups[]
  sort?: ViewSort[]
  color?: ViewColor
  order?: { [key: string]: number }
  dateField?: ViewDateField[]
  columnsParams?: { [key: string]: BaseViewColumnParams }
  timeline?: ViewTimeline
  params?: BaseViewParams
  visibility?: ViewVisibility
  details?: BaseViewDetails
}

export interface ViewLike {
  groups?: ViewGroups[]
  columnsParams?: { [key: string]: BaseViewColumnParams }
  sort?: ViewSort[]
  color?: ViewColor
  order?: { [key: string]: number }
}
