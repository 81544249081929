import React from 'react'
import { Tag } from '../../../../components/irv-ui'
import Shape from '../../../../components/shape/Shape'
import { Workspace } from '../../../../types/Workspace'
import { Base } from '../../../../types/Base'
import {
  ActionType,
  DisturbEntityArguments,
} from '../../../../store/CommonEffects'
import { WorkspaceActions } from '../../../../store/actions/WorkspaceActions'
import HelloBase from './HelloBase'
import {
  isWSActionGranted,
  UIRoleAction,
} from '../../../../components/roles/RoleGateway'
import { EyzyUser } from '../../../../types/EyzyUser'
import Avatar from '../../../../components/user/Avatar'
import { isEmpty, isUserRemoved } from '../../../../utils/is'
import WidgetsContainer from '../../../../components/addons/WidgetsContainer'
import { AddonType } from '../../../../types/Addon'
import WidgetListContainer from '../../../../components/addons/WidgetListContainer'
import WidgetTimelineContainer from '../../../../components/addons/WidgetTimelineContainer'
import PayloadProvider from '../../../../components/workspace/PayloadProvider'
import WSContextMenu from '../../../../components/contextMenu/workspace/WSContextMenu'
import WorkspaceCollaborators from '../../../../components/workspace/collaborators/WorkspaceCollaborators'
import { getRandomBaseColor } from '../../../../utils/colors'
import BaseCollaborators from '../../../../components/workspace/collaborators/BaseCollaborators'
import { BaseActions } from '../../../../store/actions/BaseActions'
import NEWIcon from '../../../../components/icon/NEWIcon'

import './style.scss'

interface Props {
  workspace: Workspace
  user: EyzyUser
  onAction: (args: DisturbEntityArguments) => Promise<any>
  onWidgetAction: () => void
  utility: any
}

interface State {
  isWSCollaboratorsVisible?: boolean
  baseSharing?: Base
  listUKey: number
}

export default class HelloWorkspace extends React.PureComponent<Props, State> {
  state: State = {
    listUKey: 1,
  }

  handleAddBase = () => {
    const { workspace, onAction } = this.props

    onAction({
      entity: WorkspaceActions.CREATE_BASE,
      id: workspace._id,
      payload: {
        name: `Новая база ${(workspace.bases || []).length + 1}`,
        color: getRandomBaseColor(),
      },
    })
  }

  handleCloseAll = () => {
    this.setState({
      isWSCollaboratorsVisible: false,
      baseSharing: null,
    })
  }

  handleWSMenuClick = (action: ActionType) => {
    switch (action) {
      case WorkspaceActions.PERMISSIONS_SETTING:
        return this.openWSSharing()
    }
  }

  handleBaseMenuClick = (entity: ActionType, payload: any) => {
    switch (entity) {
      case BaseActions.PERMISSIONS_SETTING:
        return this.setState({
          baseSharing: payload as Base,
        })

      default:
        this.props.onAction({
          entity,
          id: payload._id,
        })
    }
  }

  handleSend = () => {
    if (this.hasWidgets(AddonType.LIST)) {
      this.setState({
        listUKey: this.state.listUKey + 1,
      })

      this.props.onWidgetAction()
    }
  }

  hasWidgets = (type: AddonType) => {
    return (this.props.workspace.addons || []).some(a => a.type === type)
  }

  openWSSharing = () => {
    this.setState({ isWSCollaboratorsVisible: true })
  }

  renderHeader = () => {
    const { workspace, user } = this.props

    if (!isWSActionGranted(UIRoleAction.WORKSPACE_EDIT, user, workspace)) {
      return (
        <div className="hello-workspace__name">
          <h3>{workspace.name}</h3>
        </div>
      )
    }

    return (
      <WSContextMenu
        ws={workspace}
        user={user}
        onMenuClick={this.handleWSMenuClick}>
        <div className="hello-workspace__name">
          <h3 className="bordered">{workspace.name}</h3>
          <NEWIcon type="caret-down" />
        </div>
      </WSContextMenu>
    )
  }

  renderShare = () => {
    const { workspace, user } = this.props

    if (!isWSActionGranted(UIRoleAction.WORKSPACE_SHARE, user, workspace)) {
      return null
    }

    return (
      <div className="hello-workspace__share" onClick={this.openWSSharing}>
        <div className="hello-workspace__avatars">
          {workspace.collaborators
            .filter(u => !isUserRemoved(u))
            .map(user => (
              <Avatar key={user._id} user={user} hideTip={true} />
            ))}
        </div>

        <Tag rounded>Share</Tag>
      </div>
    )
  }

  render() {
    const { workspace, user, utility, onAction } = this.props
    const { isWSCollaboratorsVisible, baseSharing, listUKey } = this.state

    return (
      <PayloadProvider.Provider value={{ users: workspace.collaborators }}>
        <div className="hello-workspace" data-id={workspace._id}>
          <div className="hello-workspace__header">
            <div className="hello-workspace__title">{this.renderHeader()}</div>
            {this.renderShare()}
          </div>

          <WorkspaceCollaborators
            onClose={this.handleCloseAll}
            onAction={onAction}
            user={user}
            visible={isWSCollaboratorsVisible}
            ws={workspace}
          />

          <BaseCollaborators
            user={user}
            ws={workspace}
            base={baseSharing}
            visible={!!baseSharing}
            onClose={this.handleCloseAll}
            onAction={onAction}
          />

          {!isEmpty(workspace.addons) && (
            <WidgetsContainer
              workspace={workspace}
              widgets={workspace.addons!.filter(
                a => a.type === AddonType.WIDGET,
              )}
              onSend={this.handleSend}
            />
          )}

          <div className="hello-workspace__body">
            {this.hasWidgets(AddonType.LIST) && (
              <WidgetListContainer
                key={listUKey}
                widgets={workspace.addons!.filter(
                  a => a.type === AddonType.LIST,
                )}
                utility={utility}
                onAction={onAction}
              />
            )}

            {this.hasWidgets(AddonType.TIMELINE) && (
              <WidgetTimelineContainer
                key={listUKey}
                user={user}
                widgets={workspace.addons!.filter(
                  a => a.type === AddonType.TIMELINE,
                )}
              />
            )}

            <ul className="hello-workspace__items">
              {workspace.bases.map(b => (
                <HelloBase
                  key={b._id}
                  base={b}
                  workspace={workspace}
                  user={user}
                  onMenuClick={this.handleBaseMenuClick}
                  onAction={onAction}
                />
              ))}

              {isWSActionGranted(
                UIRoleAction.WORKSPACE_CREATE_BASE,
                user,
                workspace,
              ) && (
                <li key="add" className={'add'}>
                  <button
                    className="hello-link hello-link__add"
                    onClick={this.handleAddBase}>
                    <Shape>
                      <NEWIcon type="plus" />
                    </Shape>
                    Добавить базу
                  </button>
                </li>
              )}
            </ul>
          </div>
        </div>
      </PayloadProvider.Provider>
    )
  }
}
