import React from "react";
import BaseTextRenderer from "./text/BaseTextRenderer";
import BaseLongTextRenderer from "./longText/BaseLongTextRenderer";
import BaseDateRenderer from "./date/BaseDateRenderer";
import BaseCreatedDateRenderer from "./date/BaseCreatedDateRenderer";
import BaseSingleSelectRenderer from "./singleSelect/BaseSingleSelectRenderer";
import BaseMultipleSelectRenderer from "./multipleSelect/BaseMultipleSelectRenderer";
import BaseEmailRenderer from "./email/BaseEmailRenderer";
import BaseCheckboxRenderer from "./checkbox/BaseCheckboxRenderer";
import BasePhoneRenderer from "./phone/BasePhoneRenderer";
import BaseLookupRenderer from "./lookup/BaseLookupRenderer";
import {BaseFormRendererProps, BaseRendererProps} from "./BaseRendererProps";
import BaseLinkToRecordRenderer from "./linkToRecord/BaseLinkToRecordRenderer";
import AddonLinkToRecordRenderer from "./linkToRecord/AddonLinkToRecordRenderer";
import FormCreatedDateRenderer from "./date/FormSpecialDateRenderer";
import BaseFormulaRenderer from "./formula/BaseFormulaRenderer";
import UserRenderer from "./user/UserRenderer";
import BaseNumberRenderer from "./number/BaseNumberRenderer";
import FormTextRenderer from "./text/FormTextRenderer";
import FormLongTextRenderer from "./longText/FormLongTextRenderer";
import FormLinkToRecordRenderer from "./linkToRecord/FormLinkToRecordRenderer";
import BaseCountRenderer from "./count/BaseCountRenderer";
import BaseSpecialUserRenderer from "./user/BaseSpecialUserRenderer";
import SimpleValue from "./SimpleValue";
import BaseDelayedTextRenderer from "./text/BaseDelayedTextRenderer";
import BaseDelayedLongTextRenderer from "./longText/BaseDelayedLongTextRenderer";
import FormCreatorRenderer from "./user/FormCreatorRenderer";
import {ColumnType} from "../../general/types/ColumnType";
import BaseDalayedDateRenderer from './date/BaseDalayedDateRenderer'

function getRenderer(type): any {
  switch (type) {
    case ColumnType.LONG_TEXT:
      return BaseLongTextRenderer
    case ColumnType.SINGLE_SELECT:
      return BaseSingleSelectRenderer
    case ColumnType.MULTIPLE_SELECT:
      return BaseMultipleSelectRenderer
    case ColumnType.CHECKBOX:
      return BaseCheckboxRenderer
    case ColumnType.EMAIL:
      return BaseEmailRenderer
    case ColumnType.PHONE:
      return BasePhoneRenderer
    case ColumnType.NUMBER:
      return BaseNumberRenderer
    case ColumnType.ROLLUP:
      return SimpleValue
    case ColumnType.LINK:
      return BaseTextRenderer
    case ColumnType.FORMULA:
      return BaseFormulaRenderer
    case ColumnType.COUNT:
      return BaseCountRenderer
    case ColumnType.LOOKUP:
      return BaseLookupRenderer
    case ColumnType.LINK_TO_RECORD:
      return BaseLinkToRecordRenderer
    case ColumnType.DATE:
      return BaseDateRenderer
    case ColumnType.CREATION_DATE:
    case ColumnType.MODIFIED_ON:
      return BaseCreatedDateRenderer
    case ColumnType.USER:
      return UserRenderer
    case ColumnType.CREATOR:
    case ColumnType.MODIFIED_BY:
      return BaseSpecialUserRenderer
  }

  return BaseTextRenderer
}

function getFormRenderer(type) {
  switch (type) {
    case ColumnType.LINK_TO_RECORD:
      return FormLinkToRecordRenderer
    case ColumnType.CREATION_DATE:
      return FormCreatedDateRenderer
    case ColumnType.LONG_TEXT:
      return FormLongTextRenderer
    case ColumnType.PHONE:
    case ColumnType.LINK:
      return FormTextRenderer
    case ColumnType.CREATOR:
      return FormCreatorRenderer
  }

  return getRenderer(type)
}

function getAddonRenderer(type) {
  switch (type) {
    case ColumnType.LINK_TO_RECORD:
      return AddonLinkToRecordRenderer
    case ColumnType.CREATION_DATE:
      return FormCreatedDateRenderer
  }

  return getFormRenderer(type)
}

function getDelayedRenderer(type) {
  switch (type) {
    case ColumnType.TEXT:
      return BaseDelayedTextRenderer
    case ColumnType.LONG_TEXT:
      return BaseDelayedLongTextRenderer
    case ColumnType.DATE:
      return BaseDalayedDateRenderer
  }

  return getRenderer(type)
}

export default function RendererFactory(props: any) {
  const Renderer = getRenderer(props.column.type)

  return (
    <Renderer {...props} />
  )
}

export function BaseDelayedRendererFactory(props: BaseRendererProps<any>) {
  const Renderer = getDelayedRenderer(props.columnType!)

  return (
    <Renderer {...props} />
  )
}

export function BaseRendererFactory(props: BaseRendererProps<any>) {
  const Renderer = getRenderer(props.columnType!)

  return (
    <Renderer {...props} />
  )
}

export function FormRendererFactory<T = any>(props: BaseFormRendererProps<T>) {
  const Renderer = getFormRenderer(props.column.type)

  return (
    <Renderer {...props} />
  )
}

export function AddonRendererFactory(props) {
  const Renderer = getAddonRenderer(props.column.type)

  return (
    <Renderer {...props} />
  )
}
