import React from 'react'
import {GridColumn} from "../grid/types/GridColumn";
import {getColumnIcon} from "../grid/columns/ColumnsFactory";

interface Props {
  column: GridColumn
  name?: string
}

export default React.memo(function (props: Props) {
  return (
    <div className={'column-def'}>
      <i className={`icon i-${getColumnIcon(props.column.type)}`}/>
      <span className="text truncate">{props.name || props.column.name}</span>
    </div>
  )
})
