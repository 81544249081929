import React, { memo, useMemo } from 'react'
import { BaseFormLayout, FormProps } from '../types/BaseFormProps'
import { cn } from '../../../irv-ui/utils/cn'
import {
  FormRendererFactory,
  InlineFormRendererFactory,
} from '../../../_renderers/factory'
import { GridColumn } from '../../../grid/types/GridColumn'
import ColumnNameWithIcon from '../../../columnHelpers/ColumnNameWithIcon'

import './styles.scss'

const Form: React.FC<FormProps> = ({
  layout,
  header,
  data,
  fields,
  readonly,
  errors = {},
  formOptions = {},
  onChange,
  onCommit,
}) => {
  const handlers = useMemo(() => {
    return fields.reduce((f, r) => {
      return {
        ...f,
        [r._id]: {
          onChange: (value: any, payload?: any) => {
            if (onChange) {
              onChange(r, value, payload)
            }
          },
          onCommit: (value: any) => {
            if (onCommit) {
              onCommit(r, value)
            }
          },
        },
      }
    }, {})
  }, [fields, onChange, onCommit])

  const { Factory, FieldLabel, formClassName } = useMemo(() => {
    const Factory =
      layout === BaseFormLayout.INLINE
        ? InlineFormRendererFactory
        : FormRendererFactory

    const FieldLabel =
      layout === BaseFormLayout.INLINE
        ? (column: GridColumn) => <ColumnNameWithIcon column={column} />
        : (column: GridColumn) => column.name

    return {
      FieldLabel,
      Factory,
      formClassName: cn(
        'irv-frm',
        layout ? `irv-frm-${layout}` : '',
        readonly ? 'irv-frm--ro' : '',
      ),
    }
  }, [layout, readonly])

  return (
    <div className={formClassName}>
      {header && <div className={'irv-frm-header'}>{header}</div>}

      {fields.map(field => {
        const id: string = field._id

        return (
          <Factory
            key={id}
            value={data[id]}
            label={FieldLabel(field)}
            error={errors?.[id]}
            formOptions={formOptions[id]}
            column={field}
            readonly={readonly}
            params={field.params}
            onChange={handlers[id]?.onChange}
            onCommit={handlers[id]?.onCommit}
          />
        )
      })}
    </div>
  )
}

export default memo(Form)
