import React from 'react'
import {SettingsItemComponentProps} from "../types";
import Loading from "../../../loading/Loading";
import {connect} from "react-redux";
import {Workspace} from "../../../../types/Workspace";
import {loadEntity, LoadEntityArguments} from "../../../../store/CommonEffects";
import {bindActionCreators} from "redux";
import {AppActions} from "../../../../store/actions/AppActions";
import Select from "antd/es/select";
import BaseLink from "../../../../containers/workspace/components/helpers/BaseLink";

const {Option, OptGroup} = Select

interface State {
  loaded?: boolean
}

class Record extends React.PureComponent<Props, State> {
  state: State = {}

  componentDidMount() {
    const {app, ws, loadEntity} = this.props

    loadEntity({
      id: app._id,
      action: AppActions.ENTITIES,
      queryString: {
        ws: ws._id
      }
    }).then(_ => this.setState({loaded: true}))
  }

  handleTableChange = (tableId: string) => {
    const {onAction, column} = this.props
    const params: any = {
      ...column.params,
      tableId,
      viewId: ''
    }

    onAction(params)
  }

  renderSettings = () => {
    const {ws, params} = this.props
    const options = ws.bases.map(base => {
      return (
        <OptGroup label={<BaseLink base={base}/>} key={base._id}>
          {base.tables.map(table => (
            <Option value={table._id} key={table._id}>{table.name || 'Таблица без имени'}</Option>
          ))}
        </OptGroup>
      )
    })

    return (
      <>
        <div className={'column-settings-row'}>
          <div className={'column-settings-label'}>
            Ссылка на таблицу
          </div>
          <div className={'column-settings-ctrl c-selector'}>
            <Select
              placeholder="Выберите таблицу"
              getPopupContainer={trigger => trigger.parentNode}
              value={params.tableId}
              onChange={this.handleTableChange}
            >
              {options}
            </Select>
          </div>
          <div className="column-settings-hint">
            При изменении таблицы все ссылки будут утеряны
          </div>
        </div>
      </>
    )
  }

  render() {
    return (
      <div className={'column-settings-link'}>
        {this.state.loaded ? this.renderSettings() : <Loading />}
      </div>
    )
  }
}

type ReduxDispatchProps = {
  loadEntity: (args: LoadEntityArguments) => Promise<any>,
}

type ReduxStateProps = {
  ws: Workspace
  app: any
}

type Props = SettingsItemComponentProps & ReduxStateProps & ReduxDispatchProps

const mapStateToProps = (state: any): ReduxStateProps => ({
  ws: state.wss.find(ws => ws._id === state.active.ws),
  app: state.app
})

const mapDispatchToProps = (dispatch) => bindActionCreators<any, ReduxDispatchProps>({
  loadEntity,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Record)
