import React from 'react'
import { ViewProps } from '../types/ViewProps'
import IorvoGrid from '../../../components/iorvo-grid/Grid'
import { GridColumn } from '../../../components/grid/types/GridColumn'
import { createGridGroups, prepareGridColumns } from '../../../utils/grid'
import GridHeaderRenderer from '../../../components/grid/HeaderRenderer'
import { IorvoGridColumn } from '../../../components/iorvo-grid/types/IorvoGridColumn'
import { TableActions } from '../../../store/actions/TableActions'
import { CellPayload } from '../../../components/_renderers/types/GridRenderer'
import SNRenderer from '../../../components/_renderers/grid/SNRenderer'
import {
  GridGroup,
  IorvoGridMask,
  IorvoSequenceNumber,
} from '../../../components/iorvo-grid/types/IorvoGridProps'
import GridGroupSummary from '../components/grid/GridGroupSummary2'
import { hasColor, hasViewGroup } from '../../../utils/view'
import { getTableColorsMap } from '../../../utils/colors'
import { BaseView } from '../../../types/BaseView'
import { getViewParamItem } from '../../../utils/get'
import { isMob } from '../../../utils/is'
import { Tray } from '../../../components/irv-ui'
import CellDetails from '../components/cell/CellDetails'
import {
  isRoleAllowed,
  UIRoleAction,
} from '../../../components/roles/RoleGateway'

import '../styles/grid.scss'
import { ColumnType } from '../../../general/types/ColumnType'
import { isDateColumn } from '../../../general/utils/column'

interface State {
  columns: GridColumn[]
  columnPayload: CellPayload
  sn: IorvoSequenceNumber
  grouping?: GridGroup[]
  rowsClassNames?: {}
  trayMask?: IorvoGridMask | null
  mask?: IorvoGridMask | null
}

export default class GridView extends React.PureComponent<ViewProps, State> {
  gridAPI: any = React.createRef()
  _isPr: boolean

  constructor(props: ViewProps) {
    super(props)

    const rowsClassNames = hasColor(props.view)
      ? getTableColorsMap(props.rows, props.table, props.view, props.user)
      : null

    this.state = {
      columns: prepareGridColumns(props.table, props.view, props.userRole),
      columnPayload: this.getColumnPayload(rowsClassNames),
      sn: this.getSN(props.view),
      grouping: createGridGroups(props.table, props.view, props.rows, {
        users: props.ws.collaborators,
      }),
      rowsClassNames,
    }
  }

  componentDidUpdate(prevProps) {
    const { table, view, userRole, user, rows, ws } = this.props
    const nextState: Partial<State> = {}

    if (
      prevProps.table !== table ||
      prevProps.view !== view ||
      prevProps.userRole !== userRole
    ) {
      Object.assign(nextState, {
        columns: prepareGridColumns(table, view, userRole),
        grouping: createGridGroups(
          table,
          view,
          rows,
          {
            users: ws.collaborators,
          },
          true,
        ),
        columnPayload: this.getColumnPayload(this.state.rowsClassNames),
      })
    }

    if (rows !== prevProps.rows) {
      nextState.rowsClassNames = hasColor(view)
        ? getTableColorsMap(rows, table, view, user)
        : null

      nextState.columnPayload = this.getColumnPayload(nextState.rowsClassNames)

      if (
        hasViewGroup(view, table) ||
        hasViewGroup(prevProps.view, prevProps.table)
      ) {
        Object.assign(nextState, {
          grouping: createGridGroups(
            table,
            view,
            rows,
            {
              users: ws.collaborators,
            },
            true,
          ),
        })
      }

      const mask = this.state.mask

      if (mask) {
        setTimeout(() => {
          this.gridAPI.current.selectCellById(mask.id)
        }, 300)
      }
    }

    if (Object.keys(nextState).length) {
      // @ts-ignore
      this.setState(nextState)
    }
  }

  getColumnPayload = (rowsClassNames?: any): CellPayload => {
    const { table, view, userRole, ws, onAction } = this.props

    return {
      table,
      view,
      userRole,
      readonly: !isRoleAllowed(UIRoleAction.TABLE_EDIT_ROW, userRole),
      ws,
      rowsClassNames,
      users: ws.collaborators,
      onAction: onAction,
      onChange: this.handleChange,
    }
  }

  getSN = (view: BaseView): IorvoSequenceNumber => {
    const showOrder: boolean = getViewParamItem(view, 'orderNumber') !== false

    let width = hasColor(view) ? 75 : 70

    if (!showOrder) {
      width -= 20
    } else {
      if (isMob()) {
        width -= 20
      }
    }

    return {
      width,
      renderer: SNRenderer,
    }
  }

  getContainerCN = (): string => {
    const result: string[] = ['ws-view']
    const view = this.props.view

    if (hasColor(view)) {
      result.push('clr')
    }

    if (view.color) {
      if (view.color.type === 'row') {
        result.push('gc-row')
      }
    }

    return result.join(' ')
  }

  handleChange = (rowId: string, column: GridColumn, value: any) => {
    const { onAction } = this.props

    onAction(TableActions.UPDATE_ROW, {
      rowId,
      data: {
        [column._id]: value,
      },
    })

    if (column.type === ColumnType.DATE && this.state.trayMask) {
      this.handleTrayClose()
    }
  }

  handleResize = (column: IorvoGridColumn, width: number) => {
    const { onAction, view } = this.props

    onAction(TableActions.UPDATE_VIEW_COLUMN, {
      viewId: view._id,
      columnId: column._id,
      params: {
        width,
      },
    })
  }

  handleTrayClose = () => {
    this.setState({ trayMask: null })
  }

  handleSelectCell = (mask: IorvoGridMask | null) => {
    if (isMob()) {
      if (mask) {
        this.setState({ trayMask: mask })
      }
    } else {
      this.setState({ mask })
    }
  }

  renderEmptyRows = () => {
    const { rows } = this.props

    if (rows.length) {
      return null
    }

    return (
      <div>
        <p className={'no-data'}>Пока нет записей</p>
      </div>
    )
  }

  renderTray = () => {
    if (!isMob()) {
      return null
    }

    const { trayMask, columns } = this.state
    const { table, rows, userRole } = this.props

    const column = trayMask ? columns[trayMask.c - 1] : null

    if (column && column.type === ColumnType.ACTION) {
      return
    }

    const style = column && isDateColumn(column) ? { height: 400 } : null

    return (
      <Tray open={!!trayMask} onClose={this.handleTrayClose} style={style}>
        <CellDetails
          mask={trayMask}
          table={table}
          columns={columns}
          row={trayMask && rows[trayMask.r]}
          userRole={userRole}
          closeSelf={this.handleTrayClose}
          onChange={this.handleChange}
        />
      </Tray>
    )
  }

  renderGroupHeader = props => {
    return <GridGroupSummary {...props} originalRows={this.props.rows} />
  }
  renderGrid = () => {
    const { columns, columnPayload, sn, grouping, rowsClassNames } = this.state
    const { rows } = this.props
    const freeze: number = isMob() ? 0 : 2

    return (
      <IorvoGrid
        ref={this.gridAPI}
        rows={rows}
        rowsClassNames={rowsClassNames}
        columns={columns}
        sn={sn}
        freeze={freeze}
        grouping={grouping}
        columnPayload={columnPayload}
        headerRenderer={GridHeaderRenderer}
        groupHeaderRenderer={this.renderGroupHeader}
        onResize={this.handleResize}
        onSelect={this.handleSelectCell}
      />
    )
  }

  render() {
    return (
      <div className={this.getContainerCN()}>
        {this.renderGrid()}
        {this.renderEmptyRows()}
        {this.renderTray()}
      </div>
    )
  }
}
