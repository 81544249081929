import React from "react";
import {GridRendererProps} from "../GridRendererProps";
import BaseCountRenderer from "./BaseCountRenderer";
import { getValue } from "../../../general/utils/value";

export default React.memo(function GridLinkRenderer(props: GridRendererProps) {
  const {row, column} = props
  const value = getValue(column.table, column, row)

  return (
    <div className="re-value">
      <BaseCountRenderer onChange={() => {}} value={value} />
    </div>
  )
})
