import { EyzyUser } from '../../types/EyzyUser'
import { TableActions } from '../actions/TableActions'
import { BaseActions } from '../actions/BaseActions'
import { RowActions } from '../actions/RowActions'

const initState: { [key: string]: EyzyUser } | null = null

export default function rows(state = initState, action) {
  switch (action.type) {
    case TableActions.FETCH:
    case RowActions.SHARED_ROW:
      return action.payload.userMap || state

    case BaseActions.FETCH: {
      const userParams = action.payload.userParams

      if (userParams) {
        const newState = {}

        Object.keys(userParams).forEach(key => {
          if (key in state) {
            newState[key] = {
              ...state[key],
              params: {
                ...state[key],
                ...userParams[key],
              },
            }
          }
        })

        return newState
      }

      return action.payload.userMap || state
    }

    default:
      return state
  }
}
