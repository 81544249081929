import React, { useCallback, useEffect, useState } from 'react'
import { Checkbox } from '../../../components/irv-ui'
import { GridCellRendererProps } from '../types/GridRenderer'
import { getValue } from '../../../general/utils/value'
import CheckboxRenderer from '../base/CheckboxRenderer'
import { RendererClassNames } from '../definitions'
import { useKeyPress } from '../../hooks/useKeyPress'
import { EventKey } from '../../../utils/dom'

const GridCheckboxRenderer: React.FC<GridCellRendererProps> = ({
  active,
  column,
  row,
  payload,
}) => {
  const [value, setValue] = useState(getValue(payload.table, column, row))
  const handleChange = useCallback(
    checked => {
      if (payload.readonly) {
        return
      }

      setValue(checked)
      payload.onChange(row._id, column, checked)
    },
    [payload, row, column],
  )

  const pressedKey = useKeyPress(
    payload.readonly ? [] : [EventKey.SPACE, EventKey.ENTER],
    active,
    row._id + column._id,
  )

  useEffect(() => {
    setValue(getValue(payload.table, column, row))
  }, [payload.table, column, row])

  useEffect(() => {
    if (pressedKey) {
      setTimeout(_ => {
        handleChange(!value)
      }, 300)
    }
  }, [pressedKey, value, handleChange])

  if (!active) {
    return value ? (
      <CheckboxRenderer value={value} table={payload.table} column={column} />
    ) : null
  }

  return (
    <div className={RendererClassNames.CHECKBOX}>
      <Checkbox className={'irv-an'} checked={value} onChange={handleChange} />
    </div>
  )
}

export default React.memo(GridCheckboxRenderer)
