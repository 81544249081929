const icons = [
  'custom',
  'airballoon',
  'account-supervisor',
  'alien',
  'barn',
  'bike',
  'android',
  'amplifier',
  'coffee',
  'asterisk',
  'crown',
  'brightness-auto',
  'box',
  'bug-outline',
  'android-auto',
  'soccer',
  'chart-gantt',
  'check-decagram',
  'silverware-fork-knife',
  'chess-king',
  'movie-outline',
  'music-note',
  'apps',
  'freebsd',
  'comment',
  'oil',
  'ocarina',
  'corn',
  'clock-fast',
  'go-kart',
  'google-maps',
  'google-nearby',
  'ambulance',
  'google-photos',
  'apache-kafka',
  'google-play',
  'cash-100',
  'cash-fast'
]

export {icons}