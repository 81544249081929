import { ItemTitleBadge, ViewSort, ViewVisibility } from './BaseView'
import { BaseFilter } from '../components/filter/types/Filter'
import { GridColumnParams } from '../components/grid/types/GridColumn'
import { FormOptions } from "./FormOptions"
import { BaseFormOptions } from "../components/form/__new_form/types/BaseFormProps"

export enum ViewDetailsBlockType {
  // Коллапс панэль
  PANEL = 'panel',

  // Просто список полей
  FIELDS = 'fields',

  // Таблица. Просто таблица
  TABLE = 'table',
}

export interface ViewDetailsBlockParams {
  // Колонка для блока. Испольхуется для: table and ...
  columnId?: string

  // Колонки, которые отображаем. Если есть viewId - игнорим
  tableColumns?: string[]

  // У каждой записи может быть ребенок.
  // Любой из существующих блоков
  tableChildren?: ViewDetailsBlock

  // Так же как и вью - просто настраиваем
  columnsParams?: { [columnId: string]: GridColumnParams }

  sort?: ViewSort[]

  filter?: BaseFilter

  // Пока-что только для таблицы
  layout?: 'fixed'
  viewId?: string

  // Список колонок, которые показуем. Используется для: fields, panel
  columns: string[]
}

export interface ViewDetailsBlock {
  _id: string
  // Заголовок для блока. Нет - ну и хуй с ним. Сами дизайните все...
  title?: string

  titleBadge?: ItemTitleBadge[]

  // Закрытый ли по умолчанию
  closed?: boolean

  // Тип блока. Все обернуто будет блоками
  // По умолчанию это будет просто список полей в лоб
  type?: ViewDetailsBlockType

  params: ViewDetailsBlockParams
}

export enum ViewDetailsType {
  PROFILE = 'profile',
  FORM = 'form',
}

export enum ViewDetailsFormLayout {
  FULL_LEFT = 1,
  LEFT,
  CENTER,
  RIGHT,
  FILL_RIGHT
}

export interface ViewDetailsProfileTab {
  blocks: ViewDetailsBlock[]
  name?: string
  visibility?: ViewVisibility
}

export interface ViewDetailsProfile {
  logo?: string
  name: string
  info: ViewDetailsBlock[]
  tabs: ViewDetailsProfileTab[]
}

export interface ViewDetailsSettings {
  title?: string
  description?: string
  bgColor?: string
  bgImage?: string
  bgType: 'img' | 'color'
  logo?: string
  layout: ViewDetailsFormLayout
  formOptions?: BaseFormOptions
}
