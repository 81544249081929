import React from "react";
import {Switch} from '../../../../components/irv-ui'
import {SettingsItemComponentProps} from "../types";
import Select from "antd/es/select";

enum NumberTypes {
  INT,
  FLOAT
}

export default React.memo((props: SettingsItemComponentProps) => {
  const {params, onAction} = props

  const setPrecision = (precision: number) => {
    onAction({
      precision
    })
  }

  const handleChangeFormat = (type: NumberTypes) => {
    setPrecision(type === NumberTypes.INT ? 0 : 1)
  }

  const onChangeNegative = (negative) => {
    onAction({
      negative
    })
  }

  const precision = params.precision || 0
  const isInt: boolean = precision === 0

  return (
    <>
      <div className={'column-settings-crow'}>
        <div className="column-settings-cell">
          <div className={'column-settings-label'}>
            Тип поля
          </div>
          <div className={'column-settings-ctrl c-selector'}>
            <Select
              getPopupContainer={trigger => trigger.parentNode}
              dropdownMatchSelectWidth={false}
              onChange={handleChangeFormat}
              value={isInt ? NumberTypes.INT : NumberTypes.FLOAT}
            >
              <Select.Option value={NumberTypes.INT}>Целое число</Select.Option>
              <Select.Option value={NumberTypes.FLOAT}>С плавающей запятой</Select.Option>
            </Select>
          </div>
        </div>
        {!isInt && (
          <div className="column-settings-cell">
            <div className={'column-settings-label'}>
              Десятичные дроби
            </div>
            <div className={'column-settings-ctrl c-selector'}>
              <Select
                getPopupContainer={trigger => trigger.parentNode}
                dropdownMatchSelectWidth={false}
                onChange={setPrecision}
                value={precision}
              >
                {[...Array(6)].map((_, i) => (
                  <Select.Option value={i + 1}>{i + 1}</Select.Option>
                ))}
              </Select>
            </div>
          </div>
        )}
      </div>

      <div className={'column-settings-row'}>
        <div className={'column-settings-label'}>
          Отрицательные значения
        </div>
        <div className={'column-settings-ctrl'}>
          <Switch defaultChecked={params.negative !== false} onChange={onChangeNegative}/>
        </div>
      </div>
    </>


  )
})
