import React from 'react'
import {GridRendererProps} from "../GridRendererProps";
import { getValue } from '../../../general/utils/value';

export default React.memo(function GridJSONRenderer(props: GridRendererProps) {
  const {row, column, isActive} = props
  const value = getValue(column.table, column, row)

  if (isActive || !value) {
    return null
  }

  return (
    <div className={'re-value'}>{JSON.stringify(value)}</div>
  )
})
