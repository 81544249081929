import React from 'react'
import { connect } from 'react-redux'
import { Workspace } from '../../../../types/Workspace'
import Loading from '../../../../components/loading/Loading'
import HelloMenu from '../menu/HelloMenu'
import HelloWorkspace from '../workspace/HelloWorkspace'
import { bindActionCreators } from 'redux'
import {
  disturbEntity,
  DisturbEntityArguments,
  loadEntity,
  LoadEntityArguments,
} from '../../../../store/CommonEffects'
import { EyzyUser } from '../../../../types/EyzyUser'
import { Q } from '../../../workspace/types/Q'
import { WorkspaceRouterProps } from '../../../workspace/types/WorkspaceRouterProps'
import { AppActions } from '../../../../store/actions/AppActions'
import { orderColumns } from '../../../../utils/column'
import get from 'lodash/get'
import { WorkspaceActions } from '../../../../store/actions/WorkspaceActions'
import Select from '../../../../components/irv-ui/components/select/Select'

let lastScrollTop: number = 0

interface State {
  loaded: boolean
}

class HelloContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      loaded: !!props.user,
    }
  }

  componentDidMount() {
    const { user, loadEntity, wss } = this.props
    const q = !user ? [Q.USER] : null
    const loadUtility = () => {
      loadEntity({
        action: AppActions.UTILITY,
        q: [Q.ADDONS],
      })
    }

    if (user) {
      wss.forEach(ws => {
        if (!ws.bases.some(b => b._id)) {
          loadEntity({ action: WorkspaceActions.FETCH_BASES, id: ws._id })
        }
      })

      loadUtility()
    } else {
      loadEntity({ entity: AppActions.INIT, q }).then(_ => {
        this.setState({ loaded: true })
        loadUtility()
      })
    }

    const container = document.querySelector('.hello-container')

    if (container) {
      container.scrollTop = lastScrollTop
    }

    document.title = 'iorvo'
  }

  handleWidgetAction = () => {
    this.props.loadEntity({
      action: AppActions.UTILITY,
      q: [Q.ADDONS],
    })
  }

  handleContainerScroll = e => {
    lastScrollTop = e.target.scrollTop
  }

  handleAction = (args: DisturbEntityArguments): Promise<any> => {
    return this.props.disturbEntity(args)
  }

  render() {
    if (!this.state.loaded) {
      return <Loading />
    }

    const { user, utility, wss } = this.props
    const workspaces = orderColumns(wss, get(user, 'params.wsOrder'))

    return (
      <div className="hello-container" onScroll={this.handleContainerScroll}>
        <div className="hello-wrapper">
          <HelloMenu user={user} workspaces={wss} />

          <div className="hello-workspaces">
            {workspaces.map(workspace => (
              <HelloWorkspace
                user={user}
                workspace={workspace}
                key={workspace._id}
                utility={utility}
                onWidgetAction={this.handleWidgetAction}
                onAction={this.handleAction}
              />
            ))}

            {!workspaces.length && (
              <p>Рабочие области не найдены. Добавьте для начала работы.</p>
            )}

            {/*<button className="add-workspace" onClick={this.handleCreateWS}>*/}
            {/*  <Icon type={"plus"}/>Добавить рабочую область*/}
            {/*</button>*/}
          </div>
        </div>
      </div>
    )
  }
}

type ReduxStateProps = {
  utility: any
  user: EyzyUser
  wss: Workspace[]
}

type ReduxDispatchProps = {
  loadEntity: (args: LoadEntityArguments) => Promise<any>
  disturbEntity: (args: DisturbEntityArguments) => Promise<any>
}

type ReduxProps = ReduxStateProps & ReduxDispatchProps
type RouterProps = WorkspaceRouterProps & {}
type Props = ReduxProps & RouterProps

const mapStateToProps = (state: any): ReduxStateProps => ({
  wss: state.wss,
  user: state.user,
  utility: state.utility,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators<any, ReduxDispatchProps>(
    {
      loadEntity,
      disturbEntity,
    },
    dispatch,
  )

export default connect<ReduxStateProps, ReduxDispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(HelloContainer)
