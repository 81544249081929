import {AppActions} from "../actions/AppActions";

const initState: { [key: string]: any } | null = null

export default function rows(state = initState, action) {
  switch (action.type) {
    case AppActions.UTILITY:
      return {
        ...state,
        ...action.payload
      }

    default:
      return state
  }
}