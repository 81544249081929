import React from "react";
import {Event, PositionedEvent} from "../../NEW__types/Event";
import {ViewConfig} from "../../types/View";
import dayjs from "dayjs";
import {getDimensions, isPast} from "../../utils";

interface Props {
  events: Event[]
  viewportHeight: number
  config: ViewConfig
  selected?: string
}

const clusterEvents = (events: Event[], config: ViewConfig, viewportHeight: number): PositionedEvent[] => {
  // Ивенты сверху вниз
  const positionedEvents = events
    .map(e => {
      const attitude = getDimensions(e.start, e.end, config, viewportHeight)

      return {
        ...e,
        attitude
      }
    })
    .sort((e1, e2) => e2.attitude.top - e1.attitude.top)

  const result: PositionedEvent[] = positionedEvents
    .map((e, index) => {
      let eventIntersections: number = 0
      // let totalIntersections: number = 0

      const {top, height} = e.attitude

      // Идем вверх по ивентам
      for (let i = index + 1; i < positionedEvents.length; i++) {
        const pe = positionedEvents[i]
        // const peTop = pe.attitude.top
        const peBottom = pe.attitude.top + pe.attitude.height

        // низ выше, чем начало текущего - нахуй
        if (peBottom < top) {
          continue
        }

        eventIntersections++
      }

      const attitude = {
        top,
        height
      }

      if (eventIntersections) {
        attitude['left'] = `${100 * (eventIntersections * 0.12)}%`
        attitude['right'] = 0
        attitude['width'] = 'auto'
      }

      return {
        ...e,
        attitude
      }
    })
    .sort((e1, e2) => e1.attitude.top - e2.attitude.top)

  return result
}

export default React.memo(function DaysCell(props: Props) {
  const {config, viewportHeight, selected} = props
  const events = clusterEvents(props.events, config, viewportHeight)

  return (
    <>
      {events.map(event => {
        const className = [
          'c-event',
          event.attitude.height < 41 ? 'small' : '',
          event.className,
          event.id === selected ? 'active' : '',
          isPast(event) ? 'past' : ''
        ]
          .filter(Boolean)
          .join(' ')

        const time: string = `${dayjs(event.start).format('HH:mm')} - ${dayjs(event.end).format('HH:mm')}`
        const eventTitle: string = `${event.title || ''} (${time})`

        return (
          <div
            className={className}
            style={event.attitude}
            data-id={event.id}
            title={eventTitle}
            key={event.id}
          >
            <div className="c-event-header">
              <div className="c-event-title">{event.title}</div>
              <div className="c-event-time">{time}</div>
            </div>
          </div>
        )
      })}
    </>
  )
})