import { Parser } from 'hot-formula-parser'
import {
  dayjs,
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
} from '../general/utils/date'
import { GridColumnLabelType } from '../components/grid/types/GridColumn'

export function aggregate(formula: string, values): string {
  const parser = new Parser()
  parser.setVariable('values', values)

  const parsedFormula = parser.parse(formula)

  return parsedFormula.result || parsedFormula.errror
}

export function applyFormulaForDefault(formula: string): any {
  try {
    const parser = new Parser()

    parser.setFunction('TODAY', params => {
      const today = dayjs()

      if (params[0]) {
        // rounding
        return today.startOf(params[0]).toDate()
      }

      return today.toDate()
    })

    return parser.parse(formula).result
  } catch (e) {
    console.log(e)
  }
}

export function renderFormulaValue(value: any): any {
  if (value instanceof Date || dayjs.isDayjs(value)) {
    return dayjs(value).format(DEFAULT_DATE_FORMAT + ', ' + DEFAULT_TIME_FORMAT)
  }

  return String(value)
}

export function getFormulaValueLabel(value: any, divider: any, labelType: GridColumnLabelType): { label: any, percent: any} | undefined {
  // @ts-ignore
  const percent = parseInt((+value / +divider) * 100)

  if (isNaN(percent)) {
    return
  }

  if (labelType === GridColumnLabelType.DIFF) {
    const result = divider - value

    if (!isNaN(result)) {
      return {
        label: result,
        percent
      }
    }
  }

  if (labelType === GridColumnLabelType.OP) {
    return {
      percent,
      label: `${value}/${divider}`
    }
  }

  if (labelType === GridColumnLabelType.OP_REVERSE) {
    // @ts-ignore
    const reversePercent = (divider - value) / +divider

    return {
      // @ts-ignore
      percent: parseInt(+reversePercent * 100),
      // @ts-ignore
      label: `${parseInt(divider * reversePercent)}/${divider}`
    }
  }

  if (labelType === GridColumnLabelType.PERCENT) {
    const result = value / divider

    if (isFinite(result)) {
      return {
        percent,
        label: `${percent}%`
      }
    }
  }
}
