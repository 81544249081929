import React from 'react'
import { BaseView } from '../../../../types/BaseView'
import { BaseTable } from '../../../../types/BaseTable'
import { GridRow } from '../../../../components/grid/types/GridRow'
import store from '../../../../store'
import './style.scss'
import { GridColumn } from '../../../../components/grid/types/GridColumn'
import { callAction } from '../../../../store/CommonEffects'
import { TableActions } from '../../../../store/actions/TableActions'
import { connect } from 'react-redux'
import { Roles } from '../../../../components/roles/Roles'
import {
  isRoleAllowed,
  UIRoleAction,
} from '../../../../components/roles/RoleGateway'
import { isLinkColumn } from '../../../../general/utils/column'
import { __get, getActionURL, getQuery } from '../../../../utils/request'
import { EyzyLink } from '../../../../components/workspace/PayloadProvider'
import ShareContainer from './ShareContainer'
import ResponsiveTooltip from '../../../../components/responsive/ResponsiveTooltip'
import { Dialog } from '../../../../components/irv-ui'
import { Workspace } from '../../../../types/Workspace'
import BtnWithIcon from '../../../../components/btn/BtnWithIcon'
import { EyzyUser } from '../../../../types/EyzyUser'
import Loading from '../../../../components/loading/Loading'
import FormDetailsController from '../../../../components/details/FormDetailsController'
import { ViewDetailsType } from '../../../../types/ViewDetails'
import { getValue } from '../../../../general/utils/value'
import { isAllowedToShareRow } from '../../../../utils/view'

interface Props {
  view: BaseView
  table: BaseTable
  rows: GridRow[]
  rowId: string
  ws: Workspace
  onClose?: () => void
  userRole: Roles
  user: EyzyUser
}

interface State {
  links: Record<string, EyzyLink>
  loaded?: boolean
  row?: GridRow
  modalWidth: number
}

class RowDetails extends React.PureComponent<Props, State> {
  state: State = {
    links: {},
    modalWidth: 1200,
  }

  componentDidMount() {
    const { rowId, rows } = this.props

    if (this.props.rowId) {
      const row: GridRow | undefined = rows.find(r => r._id === rowId)

      if (row) {
        this.setState({ row })
      }

      this.loadLinks()
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { rowId, rows, view } = this.props

    if (rowId && rowId !== prevProps.rowId) {
      const row: GridRow | undefined = rows.find(r => r._id === rowId)

      this.setState({
        loaded: false,
        row,
        modalWidth: view.details?.as === ViewDetailsType.FORM ? 820 : 1200,
      })
      this.loadLinks()
    }
  }

  loadLinks = () => {
    const { table, view, rowId } = this.props
    const linkedColumns: GridColumn[] = table.columns.filter(isLinkColumn)

    if (linkedColumns.length) {
      const url: string =
        getActionURL('table', 'rowLinks', table._id) +
        getQuery({
          viewId: view._id,
          rowId,
        })

      __get(url).then(links => {
        this.setState({ links, loaded: true })
      })
    } else {
      this.setState({ loaded: true })
    }
  }

  handleCommit = (column: GridColumn, value: any) => {
    const { userRole, rowId, rows, table } = this.props
    const readOnly: boolean = !isRoleAllowed(
      UIRoleAction.TABLE_EDIT_ROW,
      userRole,
    )

    if (readOnly) {
      return
    }

    const prevRow = rows.find(r => r._id === rowId)

    if (!prevRow) {
      return
    }

    const prevValue = getValue(table, column, prevRow)

    // На блере происходит магия - и нужно проверить на пустоту
    if ((prevValue || '') === value) {
      return
    }

    const payload = {
      cellId: column._id,
      value,
      rowId,
    }

    callAction(TableActions.UPDATE_CELL, table._id, payload)(store.dispatch)
  }

  handleChange = (column: GridColumn, value: any) => {
    this.setState(state => {
      return {
        row: {
          ...state.row,
          [column._id]: value,
        },
      }
    })
  }

  renderHeader = () => {
    const { rows, rowId, table, view, userRole } = this.props
    const row: GridRow = rows.find(r => r._id === rowId)!

    const showShare: boolean =
      isRoleAllowed(UIRoleAction.TABLE_EDIT_ROW, userRole) &&
      isAllowedToShareRow(view, userRole)

    const ShareContainerComponent = (
      <ShareContainer row={row} table={table} view={view} />
    )

    return (
      <div className={'row-details-header'}>
        {showShare && (
          <ResponsiveTooltip
            content={ShareContainerComponent}
            placement={'bottomRight'}
            trigger={'click'}
            overlayClassName={'row-details-tooltip'}>
            <BtnWithIcon min icon={'r-share'} />
          </ResponsiveTooltip>
        )}
      </div>
    )
  }

  renderDetails = (row: GridRow) => {
    const { links } = this.state
    const { view, table, userRole, user, ws } = this.props
    const readOnly: boolean = !isRoleAllowed(
      UIRoleAction.TABLE_EDIT_ROW,
      userRole,
    )

    return (
      <FormDetailsController
        view={view}
        table={table}
        row={row}
        collaborators={ws.collaborators}
        links={links}
        user={user}
        readonly={readOnly}
        onCommit={this.handleCommit}
        onChange={this.handleChange}
      />
    )
  }

  render() {
    const { loaded, row, modalWidth } = this.state
    const { rowId, onClose } = this.props

    if (!row) {
      return null
    }

    return (
      <Dialog
        open={!!rowId}
        onClose={onClose}
        footer={null}
        alignSelf={'start'}
        width={modalWidth}
        className={'row-details-modal'}
        header={this.renderHeader()}>
        {loaded && this.renderDetails(row)}
        {!loaded && <Loading />}
      </Dialog>
    )
  }
}

const mapStateFromProps = state => ({
  rows: state.rows,
  user: state.user,
})

export default connect(mapStateFromProps)(RowDetails)
