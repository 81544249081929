import { FilterOperators } from "../../../general/types/FilterOperators";
import { DateFilterModes } from "../../../general/types/DateFilterModes";
import {Roles} from "../../roles/Roles";
import {EyzyUser} from "../../../types/EyzyUser";

export interface FilterChunk {
  colId: string
  op: typeof FilterOperators
  value?: any
  mode?: typeof DateFilterModes
}

export enum FilterItemMode {
  CELL_VALUE = 'cellValue'
}

export interface DynamicFilterItem {
  _id: string
  col: string
  op: typeof FilterOperators
  mode?: typeof DateFilterModes | FilterItemMode
  value?: any
}

export interface FilterPayload {
  users?: EyzyUser[]
}

export type FilterOp = 'or' | 'and'

export interface BaseFilter {
  filters?: Array<DynamicFilterItem | BaseFilter>
  op?: FilterOp
}

export interface DynamicViewFilters {
  access?: Roles
  filters: DynamicFilterItem[]
}

// TODO удалить нахуй
export type ViewFilters = Array<Array<FilterChunk>>
