import React from "react"
import { EyzyUser } from "../../types/EyzyUser"
import Avatar from "./Avatar"

import './styles/user-select.scss'
import { getColorClass } from "../../utils/colors"

interface Props {
  user: EyzyUser
  useColor?: boolean
}

const UserSelectOption: React.FC<Props> = ({ user, useColor }) => {
  if (!user.params) {
    return <div className={'user-select-opt'} />
  }

  return (
    <div className={'user-select-opt'}>
      {useColor && <span className={`c ${getColorClass(user.params.color)}`} />}

      <Avatar user={user} />
      <div className={'info'}>
        <span className={'name'}>{user.fullName}</span>
        <span className={'email'}>{user.email}</span>
      </div>
    </div>
  )
}

export default React.memo(UserSelectOption)
