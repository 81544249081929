import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { TableProps } from './types/Table'
import { TableTheme } from './types/TableTheme'
import useTable from './useTable'
import { cn } from '../../utils/cn'
import TableContainer from './containers/TableContainer'
import GroupsContainer from './containers/GroupsContainer'

import './styles.scss'

const Table: React.FC<TableProps> = ({
  columns,
  data,
  layout,
  height,
  pin,
  pinRight,
  size,
  idKey = '_id',
  children,
  selectedKeys,
  selectionMode,
  grouping,
  groupRenderer,
  empty,
  theme,
  onClick,
  onSelect,
  onToggleExpand,
}) => {
  const tableClassName = useMemo(() => {
    return cn({
      fixed: layout === 'fixed',
    })
  }, [layout])

  const [scrolled, setScrolled] = useState<boolean>(false)
  const [state, setState] = useState({
    collapsedGroups: [],
    rowSelection: selectedKeys,
    expandedRows: children ? [] : null,
  })

  useEffect(() => {
    setState({
      ...state,
      rowSelection: selectedKeys,
    })
  }, [selectedKeys])

  const containerClassName: string = cn(
    'irv-table',
    theme || [TableTheme.BASIC],
    {
      scrolled,
      grouped: grouping && grouping.length,
      expandable: !!children,
    },
    size && size !== 'm' ? `e-table-${size}` : '',
  )

  const containerStyle: any = {}

  // Игнорим высоту для BASIC. Ибо юзер не поймет ЧТО ТАМ и как по скроллу
  if (height && !theme.includes(TableTheme.BASIC)) {
    containerStyle.maxHeight = height
  }

  const handleBodyClick = useCallback(
    e => {
      e.persist()

      const tr = e.target.closest('.row')

      if (!tr || !onClick) {
        return
      }

      const key = tr.dataset.key

      if (!key) {
        return
      }

      const row = data.find(r => r[idKey] === key)

      if (row) {
        if (onSelect && selectionMode === 'multipleRow') {
          handleSelect([key], !(selectedKeys || []).includes(key))
        } else {
          onClick(row)
        }
      }
    },
    [onClick, onSelect, selectionMode, idKey, data, selectedKeys],
  )

  const handleExpand = useCallback(
    (id: string) => {
      let expandedRows = state.expandedRows.concat()
      let currentState: boolean = expandedRows.includes(id)

      if (currentState) {
        expandedRows = expandedRows.filter(key => key !== id)
      } else {
        expandedRows = expandedRows.concat(id)
      }

      if (onToggleExpand) {
        onToggleExpand(id, !currentState)
      }

      setState({
        ...state,
        expandedRows,
      })
    },
    [state, onToggleExpand],
  )

  const handleSelect = useCallback(
    (ids: string[], selected: boolean) => {
      let newState = (selectedKeys || []).concat()

      if (selected) {
        newState = newState.concat(ids)
      } else {
        newState = newState.filter(id => !ids.includes(id))
      }

      onSelect(Array.from(new Set(newState)))
    },
    [selectedKeys, handleBodyClick],
  )

  const handleScroll = useCallback(
    e => {
      const scrollContainer = e.target
      const scrollLeft = scrollContainer.scrollLeft

      if (scrollLeft && !scrolled) {
        setScrolled(true)
      } else if (!scrollLeft && scrolled) {
        setScrolled(false)
      }
    },
    [scrolled],
  )

  const handleCollapse = useCallback(
    (id: string) => {
      let collapsedGroups = state.collapsedGroups.concat()

      if (collapsedGroups.includes(id)) {
        collapsedGroups = collapsedGroups.filter(key => key !== id)
      } else {
        collapsedGroups = collapsedGroups.concat(id)
      }

      setState({
        ...state,
        collapsedGroups,
      })
    },
    [state],
  )

  const { rows, headers, groups } = useTable({
    columns,
    data,
    pin,
    pinRight,
    idKey,
    layout,
    grouping,
    groupRenderer,
    state,
    onSelect: handleSelect,
    onCollapse: handleCollapse,
    onExpand: handleExpand,
  })

  const Container = useMemo(() => {
    if (grouping) {
      return GroupsContainer
    }

    return TableContainer
  }, [grouping])

  return (
    <div
      className={containerClassName}
      style={containerStyle}
      onScroll={handleScroll}>
      <Container
        rows={rows}
        groups={groups}
        className={tableClassName}
        layout={layout}
        headers={headers}
        children={children}
        onBodyClick={handleBodyClick}
        empty={empty}
      />
    </div>
  )
}

export default memo(Table)
