import React, {useEffect, useRef, useState} from 'react'
import {dayjs} from "../../../../../general/utils/date";
import Tooltip from "antd/lib/tooltip";
import {CalendarViewType} from "../../../../../components/calendar/NEW__types/Calendar";
import get from 'lodash/get'
import {Input} from '../../../../../components/irv-ui'

interface Props {
  endFrom?: Date
  disabled?: boolean
  date: Date
  intervals: number
  view: CalendarViewType
  onChange: (date: Date) => void
}

type IntervalItem = {
  label: any
  d: string
  value: number
}

const buildEndIntervals = (d: Date, step: number = 30): IntervalItem[] => {
  const result: IntervalItem[] = []
  const date = dayjs(d)
  const dayStart = dayjs(d).startOf('day')
  const valueFrom: number = date.diff(dayStart, 'minutes')

  for (let i = 1; i < 11; i++) {
    const value = i * step
    const nextDate = date.add(value, 'minute')
    const hDiff = nextDate.diff(date, 'hours')
    const mDiff = nextDate.diff(date, 'minutes') % 60

    const label: string[] = []

    if (hDiff) {
      label.push(`${hDiff}ч`)
    }

    if (mDiff) {
      label.push(`${mDiff}мин`)
    }

    const d = nextDate.format('HH:mm')

    result.push({
      label: (
        <span>
          {d}
          <span className={'ts-e'}>{label.join(' ')}</span>
        </span>
      ),
      value: valueFrom + value,
      d
    })
  }

  return result
}

const buildIntervals = (interval: number, startHours: number = 0): IntervalItem[] => {
  const date = dayjs().startOf('day')
  const result: IntervalItem[] = []

  for (let i = 0; i < 1440 / interval; i++) {
    const value = i * interval

    if (value < startHours * 60) {
      continue
    }

    const time = date.add(value, 'minute')
    const label = time.format('HH:mm')

    result.push({
      label,
      value,
      d: label
    })
  }

  return result
}

const getTimeFromString = (str: string): [number, number] => {
  const resultStr: string = str
    .replace(/\D*/g, '')
    .padEnd(4, '0')

  const [enteredHours, enteredMins] = [
    Math.abs(parseInt(resultStr.slice(0, 2))),
    Math.abs(parseInt(resultStr.slice(2)))
  ]

  const hours = Math.min(enteredHours, 23)

  let minutes = Math.min(enteredMins, 60)

  if (minutes === 60) {
    minutes = 0
  }

  return [hours, minutes]
}

export default React.memo(function TimeSelector(props: Props) {
  const {date, intervals, view, endFrom, disabled, onChange} = props
  const d = dayjs(date)
  const value = d.format('HH:mm')
  const tsEl = useRef(null)
  const [isScrolled, setIsScrolled] = useState(false)
  const [inputValue, setInputValue] = useState(d.format('HH:mm'))
  const [isTooltipVisible, setTooltipVisible] = useState(false)

  const dateString: string = '' + date

  useEffect(() => {
    setInputValue(value)
    // eslint-disable-next-line
  }, [dateString])

  const handleClick = (e) => {
    let targetEl = e.target

    if (!targetEl.dataset.v) {
      targetEl = targetEl.closest('li')
    }

    if (!targetEl.dataset.v) {
      return
    }

    onChange(d.startOf('day').add(targetEl.dataset.v, 'minute').toDate())
    setTooltipVisible(false)
  }

  const handleFinish = (e) => {
    const [hours, minutes] = getTimeFromString(e.target.value)

    onChange(
      d.startOf('day').hour(hours).minute(minutes).toDate()
    )
  }

  const intervalItems = endFrom
    ? buildEndIntervals(endFrom, get(view, 'config.step'))
    : buildIntervals(intervals, get(view, 'config.startHours'))

  const tooltipTitle = (
    <ul className={'ts-list'} onClick={handleClick}>
      {intervalItems.map(i => (
        <li
          className={i.d === value ? 'active' : ''}
          data-v={i.value}
          key={i.value}>
          {i.label}
        </li>
      ))}
    </ul>
  )

  const handleInputChange = value => setInputValue(value)
  const handleVisibleChange = (visible) => {
    setTooltipVisible(visible)

    if (visible && !isScrolled) {
      try {
        setTimeout(_ => {
          const tsListEl = tsEl.current.querySelector('.ts-list')
          const index = intervalItems.findIndex(v => v.label === value)

          tsListEl.scrollTop = index * 35
          setIsScrolled(true)
        })
      } catch (e) {
        console.log(e)
      }
    }
  }

  return (
    <div className={'ts'} ref={tsEl}>
      <Tooltip
        title={tooltipTitle}
        open={isTooltipVisible}
        trigger={['click']}
        placement="bottomRight"
        onOpenChange={handleVisibleChange}
        getPopupContainer={triggerNode => triggerNode.parentElement}
      >
        <Input
          disabled={disabled}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleFinish}
          onPressEnter={handleFinish}
        />
      </Tooltip>
    </div>
  )
})
