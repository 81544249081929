import {FilterOperators} from '../types/FilterOperators'
import {DateFilterModes} from '../types/DateFilterModes'
import {utc, dayjs} from '../utils/date'

/*eslint default-case: 0*/

function filterDateRule(rule) {
  switch (rule.op) {
    case FilterOperators.IS:
      return dateFilterIn(rule)
    case FilterOperators.IS_WITHIN:
      return dateFilterWithin(rule)
    case FilterOperators.IS_AFTER:
      return dateFilterAfter(rule)
    case FilterOperators.IS_BEFORE:
      return dateFilterBefore(rule)
  }

  return []
}

const dateFilterIn = (rule) => {
  const now = utc()

  switch (rule.mode) {
    case DateFilterModes.TODAY: {
      return [
        now.startOf('day').toDate(),
        now.endOf('day').toDate()
      ]
    }
  }

  return []
}

const dateFilterWithin = (rule) => {
  const {value, mode} = rule
  const now = utc()

  switch (mode) {
    case DateFilterModes.MONTH: {
      const date = dayjs(value)
      const $gte = date.startOf(mode).toDate()
      const $lte = date.endOf(mode).toDate()

      return [$gte, $lte]
    }

    case DateFilterModes.CURRENT_WEEK:
    case DateFilterModes.CURRENT_MONTH:
    case DateFilterModes.CURRENT_YEAR: {
      const key = mode.replace(/current/, '').toLowerCase()
      const $gte = now.startOf(key).toDate()
      const $lte = now.endOf(key).toDate()

      return [$gte, $lte]
    }

    case DateFilterModes.PAST_7_DAYS:
    case DateFilterModes.PAST_30_DAYS:
    case DateFilterModes.PAST_DAYS_N: {
      const days = mode === DateFilterModes.PAST_DAYS_N
        ? value
        : parseInt(mode.replace(/\D*/, ''))

      const $gte = now.subtract(days, 'day').toDate()

      return [$gte, now.toDate()]
    }

    case DateFilterModes.PAST_YEAR:
    case DateFilterModes.PAST_MONTH:
    case DateFilterModes.PAST_WEEK: {
      const key = mode.replace(/past/, '').toLowerCase()
      const $gte = now.subtract(1, key).startOf(key).toDate()
      const $lte = now.subtract(1, key).endOf(key).toDate()

      return [$gte, $lte]
    }

    case DateFilterModes.PAST_MONTHS_N: {
      return [now.subtract(value || 1, 'month').toDate()]
    }

    case DateFilterModes.NEXT_7_DAYS:
    case DateFilterModes.NEXT_30_DAYS:
    case DateFilterModes.NEXT_DAYS_N: {
      const days = mode === DateFilterModes.NEXT_DAYS_N
        ? value
        : parseInt(mode.replace(/\D*/, ''))

      const $gte = now.add(days, 'day').toDate()

      return [$gte, now.toDate()]
    }

    case DateFilterModes.NEXT_YEAR:
    case DateFilterModes.NEXT_MONTH:
    case DateFilterModes.NEXT_WEEK: {
      const key = mode.replace(/next/, '').toLowerCase()
      const $gte = now.add(1, key).startOf(key).toDate()
      const $lte = now.add(1, key).endOf(key).toDate()

      return [$gte, $lte]
    }

    case DateFilterModes.NEXT_MONTHS_N: {
      return [now.add(value, 'month').toDate()]
    }

    case DateFilterModes.EXACT: {
      return [
        dayjs(value[0]).toDate(),
        dayjs(value[1]).toDate()
      ]
    }
  }

  return []
}

const dateFilterAfter = (rule) => {
  const {value, mode} = rule
  const date = utc(value)

  if (!date.isValid()) {
    return []
  }

  switch (mode) {
    case DateFilterModes.EXACT:
      return [date.toDate()]
  }
}

const dateFilterBefore = (rule) => {
  const now = utc()

  switch (rule.mode) {
    case DateFilterModes.PAST_MONTH: {
      return [
        now.subtract(2000, 'year').toDate(),
        now.subtract(1, 'month').toDate()
      ]
    }

    case DateFilterModes.PAST_MONTHS_N: {
      return [
        now.subtract(2000, 'year').toDate(),
        now.subtract(rule.value !== undefined ? rule.value : 1, 'month').toDate()
      ]
    }

    case DateFilterModes.PAST_DAYS_N: {
      const days = rule.value !== undefined ? rule.value : 1

      return [
        now.subtract(2000, 'year').toDate(),
        now.subtract(days, 'day').toDate()
      ]
    }

    default: return []
  }
}

export {filterDateRule}