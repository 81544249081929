import {ColumnsRenderers} from "../types/ColumnsRenderers";
import GridLongTextEditor from "../../__renderers/longText/GridLongTextRenderer";
import GridDateRenderer from "../../__renderers/date/GridDateRenderer";
import GridSingleSelectRenderer from "../../__renderers/singleSelect/GridSingleSelectRenderer";
import GridMultipleSelectRenderer from "../../__renderers/multipleSelect/GridMultipleSelectRenderer";
import GridEmailRenderer from "../../__renderers/email/GridEmailRenderer";
import GridPhoneRenderer from "../../__renderers/phone/GridPhoneRenderer";
import GridLinkRenderer from "../../__renderers/link/GridLinkRenderer";
import GridNumberRenderer from "../../__renderers/number/GridNumberRenderer";
import GridCheckboxRenderer from "../../__renderers/checkbox/GridCheckboxRenderer";
import GridCreatedDateRenderer from "../../__renderers/date/GridCreatedDateRenderer";
import GridUserRenderer from "../../__renderers/user/GridUserRenderer";
import GridCreatorRenderer from "../../__renderers/user/GridCreatorRenderer";
import GridModifiedByRenderer from "../../__renderers/user/GridModifiedByRenderer";
import GridModifiedDateRenderer from "../../__renderers/date/GridModifiedDateRenderer";
import GridLookupRenderer from "../../__renderers/lookup/GridLookupRenderer";
import GridLinkToRecordRenderer from "../../__renderers/linkToRecord/GridLinkToRecordRenderer";
import GridFormulaRenderer from "../../__renderers/formula/GridFormulaRenderer";
import {isComputedColumn, isLinkColumn, isSpecialColumn} from "../../../general/utils/column";
import {ColumnType} from "../../../general/types/ColumnType";
import GridCountRenderer from "../../__renderers/count/GridCountRenderer";
import BaseActionRenderer from "../../__renderers/action/GridActionRenderer";
import GridRecordIndexRenderer from "../../__renderers/recordIndex/GridRecordIndexRenderer";
import GridJSONRenderer from "../../__renderers/json/GridJSONRenderer";

const columnIcons: any = {
  [ColumnType.TEXT]: 'text',
  [ColumnType.LONG_TEXT]: 'long',
  [ColumnType.DATE]: 'd-calendar',
  [ColumnType.CREATION_DATE]: 'd-calendar',
  [ColumnType.CHECKBOX]: 'checkbox',
  [ColumnType.SINGLE_SELECT]: 'b-caret-circle-down',
  [ColumnType.MULTIPLE_SELECT]: 'list',
  [ColumnType.EMAIL]: 'at',
  [ColumnType.SOCIAL]: 'network',
  [ColumnType.PHONE]: 'phone',
  [ColumnType.LINK]: 'link',
  [ColumnType.NUMBER]: 'one',
  [ColumnType.LINK_TO_RECORD]: 'right-left',
  [ColumnType.FORMULA]: 'fx',
  [ColumnType.COUNT]: 'count',
  [ColumnType.LOOKUP]: 'search',
  [ColumnType.ROLLUP]: 't-formula',
  [ColumnType.USER]: 'user',
  [ColumnType.CREATOR]: 'user',
  [ColumnType.MODIFIED_ON]: 'd-calendar',
  [ColumnType.MODIFIED_BY]: 'user',
  [ColumnType.ACTION]: 'double-tap',
  [ColumnType.RECORD_INDEX]: 'l-count',
  [ColumnType.JSON]: 'code',
  [ColumnType.MIRROR]: 'mirror',
  [ColumnType.LIST]: 'list',
}

export const columnTexts: any = {
  [ColumnType.TEXT]: 'Однострочный текст',
  [ColumnType.LONG_TEXT]: 'Длинный текст',
  [ColumnType.DATE]: 'Дата',
  [ColumnType.SINGLE_SELECT]: 'Одиночный выбор',
  [ColumnType.MULTIPLE_SELECT]: 'Множественный выбор',
  [ColumnType.EMAIL]: 'E-Mail',
  [ColumnType.SOCIAL]: 'Соц. сеть',
  [ColumnType.CHECKBOX]: 'Checkbox',
  [ColumnType.PHONE]: 'Номер телефона',
  [ColumnType.LINK]: 'Ссылка',
  [ColumnType.NUMBER]: 'Число',
  [ColumnType.USER]: 'Пользователь',
  [ColumnType.LIST]: 'Список',
  [ColumnType.LINK_TO_RECORD]: 'Ссылка на запись таблицы',
  [ColumnType.FORMULA]: 'Формула',
  [ColumnType.LOOKUP]: 'Связанное поле',
  [ColumnType.ROLLUP]: 'Свертывание',
  [ColumnType.COUNT]: 'Подсчет',
  [ColumnType.CREATION_DATE]: 'Дата создания',
  [ColumnType.CREATOR]: 'Создатель',
  [ColumnType.MODIFIED_ON]: 'Дата редактирования',
  [ColumnType.MODIFIED_BY]: 'Кем редактирован',
  [ColumnType.ACTION]: 'Действие',
  [ColumnType.RECORD_INDEX]: 'Индекс ссылки',
  [ColumnType.JSON]: 'JSON',
  [ColumnType.MIRROR]: 'Зеркало',
}

const columnRenderers: any = {
  [ColumnType.LONG_TEXT]: {
    editor: GridLongTextEditor
  },
  [ColumnType.SINGLE_SELECT]: {
    editor: false,
    renderer: GridSingleSelectRenderer
  },
  [ColumnType.MULTIPLE_SELECT]: {
    editor: false,
    renderer: GridMultipleSelectRenderer
  },
  [ColumnType.DATE]: {
    renderer: GridDateRenderer,
    editor: false
  },
  [ColumnType.EMAIL]: {
    renderer: GridEmailRenderer,
  },
  [ColumnType.CHECKBOX]: {
    editor: false,
    renderer: GridCheckboxRenderer
  },
  [ColumnType.PHONE]: {
    renderer: GridPhoneRenderer,
  },
  [ColumnType.LINK]: {
    renderer: GridLinkRenderer,
  },
  [ColumnType.NUMBER]: {
    renderer: GridNumberRenderer,
  },
  [ColumnType.LINK_TO_RECORD]: {
    renderer: GridLinkToRecordRenderer,
    editor: false,
  },
  [ColumnType.CREATION_DATE]: {
    renderer: GridCreatedDateRenderer,
    editor: false,
  },
  [ColumnType.MODIFIED_ON]: {
    renderer: GridModifiedDateRenderer,
    editor: false,
  },
  [ColumnType.COUNT]: {
    renderer: GridCountRenderer,
    editor: false
  },
  [ColumnType.FORMULA]: {
    renderer: GridFormulaRenderer,
    editor: false
  },
  [ColumnType.LOOKUP]: {
    renderer: GridLookupRenderer,
    editor: false
  },
  [ColumnType.ROLLUP]: {
    editor: false
  },
  [ColumnType.USER]: {
    editor: false,
    renderer: GridUserRenderer
  },
  [ColumnType.CREATOR]: {
    editor: false,
    renderer: GridCreatorRenderer
  },
  [ColumnType.MODIFIED_BY]: {
    editor: false,
    renderer: GridModifiedByRenderer
  },
  [ColumnType.ACTION]: {
    editor: false,
    renderer: BaseActionRenderer
  },
  [ColumnType.RECORD_INDEX]: {
    editor: false,
    renderer: GridRecordIndexRenderer
  },
  [ColumnType.JSON]: {
    renderer: GridJSONRenderer,
    editor: false
  },
}

export function columnsFactory(type: string): ColumnsRenderers {
  return columnRenderers[type] || {}
}

export function getColumnIcon(type: any): string {
  return columnIcons[type] || columnIcons[ColumnType.TEXT]
}

interface ColumnTypeList {
  name: string
  type: string
  icon: string
}

export function getColumnTypes(): ColumnTypeList[] {
  return Object.keys(columnTexts).map(key => ({
    name: columnTexts[key],
    type: key,
    icon: getColumnIcon(key)
  }))
}

export const basicColumns: ColumnTypeList[] = []
export const advancedColumns: ColumnTypeList[] = []

const specialColumns = [
  ColumnType.FORMULA,
  ColumnType.COUNT,
  ColumnType.ACTION,
  ColumnType.RECORD_INDEX,
  ColumnType.JSON,
  ColumnType.MIRROR,
]

getColumnTypes().forEach(c => {
  if (isSpecialColumn(c) || isLinkColumn(c) || isComputedColumn(c) || specialColumns.includes(c.type)) {
    advancedColumns.push(c)
  } else {
    basicColumns.push(c)
  }
})
