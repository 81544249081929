import Logo from '../../components/logo/Logo'
import React from 'react'

export default function ResetPasswordError() {
  return (
    <div className="login">
      <Logo small={1} />
      <form className={'login-form'}>
        <strong>
          Токен для відновлення пароля більше не активний. <br /> Запитайте
          новий у адміністратора
        </strong>

        <br />
        <a href={'/'}>Повернутися на головну</a>
      </form>
    </div>
  )
}
