import React, { useEffect, useMemo, useState } from 'react'
import { Tag } from '../../../components/irv-ui'
import { GridCellRendererProps } from '../types/GridRenderer'
import { applyFormula, getValue } from '../../../general/utils/value'
import { RendererClassNames } from '../definitions'
import { useKeyPress } from '../../hooks/useKeyPress'
import { EventKey } from '../../../utils/dom'
import Tooltip from 'antd/lib/tooltip'
import { GridColumnTagType } from '../../grid/types/GridColumn'
import { renderFormulaValue } from '../../../utils/formula'
import FormulaValueRenderer from '../utils/FormulaValueRenderer'

const GridFormulaRenderer: React.FC<GridCellRendererProps> = ({
  active,
  column,
  row,
  payload,
}) => {
  const value = useMemo(() => {
    const res = getValue(payload.table, column, row)

    if (res === '#VALUE!') {
      return ''
    }

    return res
  }, [payload, column, row])

  const displayValue = useMemo(() => renderFormulaValue(value), [value])

  const divideBy = useMemo(() => {
    if (!column.params.divideBy) {
      return
    }

    return applyFormula(column.params.divideBy, {
      row,
      table: payload.table,
      column,
    })
  }, [row, column])

  const [visible, setVisible] = useState<boolean>(false)
  const pressedKey = useKeyPress(
    [EventKey.SPACE, EventKey.ENTER],
    active,
    row._id + column._id,
  )

  const handleDblClick = () => {
    setVisible(true)
  }

  const isTag = useMemo(
    () => column.params?.viewAs === GridColumnTagType.TAG,
    [column],
  )

  useEffect(() => {
    if (pressedKey) {
      setVisible(true)
    }
  }, [pressedKey])

  useEffect(() => {
    setVisible(false)
    // eslint-disable-next-line
  }, [active])

  return (
    <Tooltip
      title={'Значение поля вычисляется автоматически'}
      visible={visible}>
      <div
        className={RendererClassNames.FORMULA}
        onDoubleClick={handleDblClick}>
        <FormulaValueRenderer
          divideBy={divideBy}
          displayValue={displayValue}
          labelType={column.params.labelType}
          viewAs={column.params.viewAs}
          asText
        />
      </div>
    </Tooltip>
  )
}

export default React.memo(GridFormulaRenderer)
