import React, {ReactNode} from 'react'

export interface TabProps {
  children?: any
  label: ReactNode | string
  disabled?: boolean
  tab?: ReactNode
  headerClassName?: string
}

const Tab: React.FC<TabProps> = ({children, tab}) => {
  return (
    <div className="eyzy-tabs-content__item">
      { children || tab }
    </div>
  )
}

export default React.memo(Tab)
