
const MAX_BYTES: number = 65536

// Node supports requesting up to this number of bytes
// https://github.com/nodejs/node/blob/master/lib/internal/crypto/random.js#L48
const MAX_UINT32: number = 4294967295

const _crypto: any = window.crypto || window["msCrypto"]

function randomBytes(size: number) {
  // phantomjs needs to throw
  if (size > MAX_UINT32) throw new RangeError('requested too many random bytes')

  const bytes = new Int16Array(size)

  if (size > 0) {  // getRandomValues fails on IE if size == 0
    if (size > MAX_BYTES) { // this is the max bytes crypto.getRandomValues
      // can do at once see https://developer.mozilla.org/en-US/docs/Web/API/window.crypto.getRandomValues
      for (let generated = 0; generated < size; generated += MAX_BYTES) {
        // buffer.slice automatically checks if the end is past the end of
        // the buffer so we don't have to here
        _crypto.getRandomValues(bytes.slice(generated, generated + MAX_BYTES))
      }
    } else {
      _crypto.getRandomValues(bytes)
    }
  }

  return bytes
}

const urlSafeCharacters: string[] = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'.split('')
const generateForCustomCharacters = (length, characters): string => {
  // Generating entropy is faster than complex math operations, so we use the simplest way
  const characterCount = characters.length;
  const maxValidSelector = (Math.floor(0x10000 / characterCount) * characterCount) - 1; // Using values above this will ruin distribution when using modular division
  const entropyLength = 2 * Math.ceil(1.1 * length); // Generating a bit more than required so chances we need more than one pass will be really low
  let string = '';
  let stringLength = 0;

  while (stringLength < length) { // In case we had many bad values, which may happen for character sets of size above 0x8000 but close to it
    const entropy = randomBytes(entropyLength);
    let entropyPosition = 0;
    while (entropyPosition < entropyLength && stringLength < length) {
      const entropyValue = Math.abs(entropy[entropyPosition]);
      entropyPosition += 2;
      if (entropyValue > maxValidSelector) { // Skip values which will ruin distribution when using modular division
        continue;
      }

      string += characters[entropyValue % characterCount];
      stringLength++;
    }
  }

  return string;
}

function pseudoRandom(size: number, characters: string[]): string {
  const result: string[] = []
  const keys: number[] = Array.from(new Array(64)).map((a, i) => i)

  keys.sort(() => { return Math.random() > 0.5 ? 1 : -1 })

  for (let i = 0; i < size; i++) {
    result.push(characters[keys[i]])
  }

  return result.join('')
}

export function random(prefix: string, size: number = 15) {
  try {
    return prefix + generateForCustomCharacters(size, urlSafeCharacters)
  } catch(e) {
    console.log(e)
    return prefix + pseudoRandom(size, urlSafeCharacters)
  }
}
