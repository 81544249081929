import React from 'react'
import get from 'lodash.get'
import { Button } from '../../../../../components/irv-ui'
import { BaseView } from '../../../../../types/BaseView'
import { BaseTable } from '../../../../../types/BaseTable'
import appsIcon from '../../../../../assets/svg/apps.svg'
import BtnWithIcon from '../../../../../components/btn/BtnWithIcon'
import { cn } from '../../../../../utils/classname'
import CustomizeContainer from '../customize/CustomizeContainer'
import { ActionType } from '../../../../../store/CommonEffects'
import Tooltip from 'antd/lib/tooltip'
import CustomizeFilter from '../customize/blocks/CustomizeFilter'
import { Workspace } from '../../../../../types/Workspace'
import {
  getFilterColumnsText,
  getSortColumnsText,
} from '../../../../../utils/get'
import { isEmpty } from '../../../../../general/utils/is'
import CustomizeSort from '../customize/blocks/CustomizeSort'
import {
  ViewSettingsUIState,
  ViewSettingsUIStateItem,
} from '../../../types/UIState'
import PayloadProvider from '../../../../../components/workspace/PayloadProvider'
import { isMob } from '../../../../../utils/is'
import DynamicFiltersContainer from '../../dynamicFilters/DynamicFiltersContainer'
import { TableActions } from '../../../../../store/actions/TableActions'
import { Roles } from '../../../../../components/roles/Roles'
import {
  isRoleAllowed,
  UIRoleAction,
} from '../../../../../components/roles/RoleGateway'
import { debounce } from '../../../../../utils/common'
import { history } from '../../../../../utils/navigation'

import './style.scss'

interface Props {
  view: BaseView
  table: BaseTable
  ws: Workspace
  userRole: Roles
  payload?: any
  onAction: (action: ActionType, payload: any) => Promise<void>
}

interface State {
  pageState?: ViewSettingsUIState
  pageStateCopy?: ViewSettingsUIState
  ftVisible?: boolean
  srVisible?: boolean
  search: string
  __o?: any
}

export default class ViewHeader extends React.PureComponent<Props, State> {
  state: State = {
    search: '',
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.payload !== state.pageStateCopy) {
      return {
        pageStateCopy: props.payload,
        pageState: props.payload,
      }
    }

    return null
  }

  applySearch: (s: string) => void = debounce((search: string) => {
    const url = new URL(document.location.href)

    url.searchParams.set('search', search)

    history.replace(url.pathname + url.search)
  }, 1000)

  getClassName = (): string => {
    const className: string[] = ['ws-view-header']
    const { view } = this.props

    const dynamicFilters = get(view, 'dynamicFilter.filters.length', 0)

    if (dynamicFilters > 0) {
      className.push('dfc')
    }

    return className.join(' ')
  }

  toggleSettings = () => {
    const { pageState } = this.state

    this.setState({
      pageState: pageState
        ? null
        : {
            type: ViewSettingsUIStateItem.GENERAL,
          },
    })
  }

  handleSearch = e => {
    const search = e.target.value

    this.applySearch(search)
    this.setState({ search })
  }

  handlePageChange = (pageState: ViewSettingsUIState) => {
    this.setState({ pageState })
  }

  handleTVisibilityChange = (type: string) => (state: boolean) => {
    // @ts-ignore
    this.setState({
      [type]: state,
    })
  }

  handleFilter = (filters: any) => {
    this.props.onAction(TableActions.FILTER_ROWS, filters)
  }

  isHeaderVisible = () => {
    const { view, userRole } = this.props

    if (view.dynamicFilter && !isEmpty(view.dynamicFilter.filters)) {
      return true
    }

    return isRoleAllowed(UIRoleAction.TABLE_EDIT_VIEW, userRole)
  }

  renderSortBtn = () => {
    const { view, table, ws, onAction } = this.props
    const { srVisible } = this.state
    const overlay = (
      <CustomizeSort
        table={table}
        view={view}
        onAction={onAction}
        users={ws._users}
      />
    )

    const btnText = () => {
      if (!view.sort || isEmpty(view.sort)) {
        return 'Сортировка'
      }

      return (
        <>
          <strong>Сортировка:&nbsp;</strong>
          {getSortColumnsText(table, view.sort)}
        </>
      )
    }

    return (
      <Tooltip
        title={overlay}
        trigger={['click']}
        visible={srVisible}
        placement="bottomRight"
        overlayClassName={'wtt'}
        destroyTooltipOnHide
        onVisibleChange={this.handleTVisibilityChange('srVisible')}
        getPopupContainer={triggerNode => triggerNode.parentElement}>
        <BtnWithIcon icon={'sort'} min={!srVisible}>
          {btnText()}
        </BtnWithIcon>
      </Tooltip>
    )
  }

  renderFilterBtn = () => {
    const { view, table, ws, onAction } = this.props
    const { ftVisible } = this.state

    const overlay = (
      <CustomizeFilter
        table={table}
        filter={view.filter}
        onAction={onAction}
        users={ws._users}
      />
    )

    const btnText = () => {
      if (!view.filter) {
        return 'Фильтр'
      }

      return (
        <>
          <strong>Фильтр:&nbsp;</strong>
          {getFilterColumnsText(table, view.filter)}
        </>
      )
    }

    return (
      <Tooltip
        title={overlay}
        trigger={['click']}
        open={ftVisible}
        placement="bottomRight"
        overlayClassName={'wtt'}
        destroyTooltipOnHide
        onOpenChange={this.handleTVisibilityChange('ftVisible')}
        getPopupContainer={triggerNode => triggerNode.parentElement}>
        <BtnWithIcon icon={'filter'} min={!ftVisible}>
          {btnText()}
        </BtnWithIcon>
      </Tooltip>
    )
  }

  renderMob = () => {
    const { table, view } = this.props

    if (!view.dynamicFilter || isEmpty(view.dynamicFilter.filters)) {
      return null
    }

    return (
      <div className={this.getClassName()}>
        <div className={'ws-view-hbase'}>
          <DynamicFiltersContainer
            view={view}
            table={table}
            onFilter={this.handleFilter}
          />
        </div>
      </div>
    )
  }

  render() {
    const { view, table, userRole, ws, onAction } = this.props
    const { pageState } = this.state

    if (!this.isHeaderVisible()) {
      return null
    }

    if (isMob()) {
      return this.renderMob()
    }

    // const searchText: string = `Поиск в ${view.name}`
    const settingsCn: string = cn('bti stg cv', !!pageState && 'stg-o')

    return (
      <div className={this.getClassName()}>
        <div className={'ws-view-hbase'}>
          {/*<Input*/}
          {/*  placeholder={searchText}*/}
          {/*  title={searchText}*/}
          {/*  onChange={this.handleSearch}*/}
          {/*  value={search}*/}
          {/*/>*/}

          <DynamicFiltersContainer
            view={view}
            table={table}
            onFilter={this.handleFilter}
          />
        </div>
        {isRoleAllowed(UIRoleAction.TABLE_EDIT_VIEW, userRole) && (
          <>
            <div className={'ws-view-ctrl'}>
              {this.renderFilterBtn()}
              {this.renderSortBtn()}

              <Button className={settingsCn} onClick={this.toggleSettings}>
                <img src={appsIcon} alt={''} width={13} />
                <span>Настройки</span>
              </Button>
            </div>

            <PayloadProvider.Provider value={{ users: ws._users }}>
              <CustomizeContainer
                view={view}
                table={table}
                settingsUIState={pageState}
                onPageChange={this.handlePageChange}
                onAction={onAction}
              />
            </PayloadProvider.Provider>
          </>
        )}
      </div>
    )
  }
}
