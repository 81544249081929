import React from "react"
import { Input } from "../../../components/irv-ui"
import { BaseRendererProps } from "../BaseRendererProps"
import { asString } from "../../../utils/as"

export default class BaseTextRenderer extends React.PureComponent<BaseRendererProps<string>> {
  render() {
    const { value, readonly, onChange } = this.props

    if (readonly) {
      return <div className={"re-value"}>{asString(value)}</div>
    }

    return (
      <Input
        fit
        className={"re-editor"}
        value={asString(value)}
        onChange={onChange}
      />
    )
  }
}
