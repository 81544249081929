import dayjs from "dayjs"
import ru from 'dayjs/locale/ru'
import {View, ViewConfig} from "./types/View"
import today from 'dayjs/plugin/isToday'
import dayjsUtc from "dayjs/plugin/utc"
import {CalendarViewType, DateLike, HashedEvents, ViewType} from "./NEW__types/Calendar"
import {Event} from "./NEW__types/Event"

dayjs.extend(today)
dayjs.locale(ru)
dayjs.extend(dayjsUtc)

const ROW_HEIGHT = 50
export const MS_IN_DAY: number = 86400000

export {dayjs}

export const formats = {
  custom(date) {
    return date.format('dd')
  }
}

export function isPast(event: Event): boolean {
  const start = dayjs(event.start)
  const now = dayjs()

  if (event.end) {
    return dayjs(event.end).isBefore(now)
  }

  return start.isBefore(now)
}

export function isToday(day) {
  return day.isToday()
}

export function hashMonth(date: DateLike): string {
  return dayjs(date).format('DD/MM/YYYY')
}

export function hashDate(date): string {
  return dayjs(date).format('DD/MM/YYYY')
}

export function getActiveView(key: any, views: CalendarViewType[]): CalendarViewType {
  return (views || []).find(v => v.key === key) || views[0]
}

export function getToolbarDates(d: DateLike, view: CalendarViewType): dayjs.Dayjs[] {
  const intervals = getViewIntervals(d, view)

  if (view.type === ViewType.DAY) {
    return [dayjs(intervals[0])]
  }

  return [dayjs(intervals[0]), dayjs(intervals[1])]
}

export function getHashFn(view?: ViewType) {
  switch (view) {
    default:
      return hashDate
  }
}

export function getViewIntervals(active: DateLike, view: CalendarViewType): [Date, Date] {
  const date = dayjs(active)

  switch (view.type) {
    case ViewType.DAY:
    case ViewType.WEEK: {
      return [
        date.startOf('week').toDate(),
        date.endOf('week').toDate()
      ]
    }

    case ViewType.WORKING_WEEK:
    case ViewType.MONTH: {
      return [
        date.startOf('month').toDate(),
        date.endOf('month').toDate()
      ]
    }
    case ViewType.YEAR:
    default:
      return [dayjs().toDate(), dayjs().toDate()]
  }
}

export function createHashedEvents(events: Event[], active: dayjs.Dayjs, view: CalendarViewType): HashedEvents {
  const hashFn = getHashFn(view.type)
  const intervals = getViewIntervals(active, view)
  const intervalsMS: [number, number] = [+intervals[0], +intervals[1]]

  const hashedEvents = events
    .filter(e => {
      if (e.repeat) {
        return true
      }

      if (e.end && +dayjs(e.end) < intervalsMS[0]) {
        return false
      }

      if (+dayjs(e.start) > intervalsMS[1]) {
        return false
      }

      return true
    })

  return hashedEvents.reduce((result: HashedEvents, event: Event) => {
    const hash = hashFn(event.start)

    if (!result[hash]) {
      result[hash] = []
    }

    result[hash].push(event)

    return result
  }, {})
}

export function aggregateEvents(events: Event[], hashFn: (event: Event) => string) {
  return events.reduce((result, event) => {
    const hash = hashFn(event)
    const resourceId = event.resourceId!


    if (!result[resourceId]) {
      result[resourceId] = {
        [hash]: [event]
      }
    } else {
      if (!result[resourceId][hash]) {
        result[resourceId][hash] = []
      }

      result[resourceId][hash].push(event)
    }

    return result
  }, {})
}

export function calculateMaxEvents(resources: { [key: string]: number }): { [key: string]: number } {
  const counts = {}

  Object.keys(resources).forEach(key => {
    counts[key] = Object.keys(resources[key]).reduce((result, rKey) => {
      return Math.max(result, resources[key][rKey].length)
    }, 0)
  })

  return counts
}

export function dateHash(date: Date | string | dayjs.Dayjs | number): string {
  return dayjs(date).format('YYYY-MM-DD')
}

export function effectiveHash(date: DateLike): number {
  return +dayjs(date).format('YYYYMMDD')
}

export function monthHash(event: Event): string {
  return dateHash(event.start)
}


export function isMovementStarted(coords0: number[], coords1: number[]): boolean {
  if (Math.abs(coords0[0] - coords1[0]) > 5) {
    return true
  }

  if (Math.abs(coords0[1] - coords1[1]) > 5) {
    return true
  }

  return false
}

export function isWeekend(date: DateLike): boolean {
  return [0, 6].includes(dayjs(date).day())
}

export function isTouchable(): boolean {
  return document.body.clientWidth <= 1024
}

export function roundMinutes(minutes: number, step: number): number {
  const rMins: number = Math.floor(minutes)
  const m = rMins % step

  if (m === 0) {
    return minutes
  }

  if (step / 2 > m) {
    return Math.floor(rMins - (step - m))
  }

  return Math.floor(rMins + (step - m))
}

export function between(point: number[], operand: number, withoutEdges?: boolean): boolean {
  if (withoutEdges) {
    return point[0] < operand && operand < point[1]
  }

  return point[0] <= operand && operand <= point[1]
}

export function getInterval(activeDate: DateLike, view: View): dayjs.Dayjs[] {
  const date = dayjs(activeDate)

  switch (view) {
    case View.WEEK:
      const startWeek = date.startOf('week')

      return [
        startWeek,
        startWeek.add(7, 'day')
      ]

    case View.WORKING_WEEK: {
      const startWeek = date.startOf('week')

      return [
        startWeek,
        startWeek.add(5, 'day')
      ]
    }

    case View.DAY:
      return [
        date.startOf('day'),
        date.endOf('day'),
      ]

    default:
      return [dayjs(), dayjs()]
  }
}


export const topWithStartHours = (top: number, rowHeight: number, startHours?: number) => {
  return top - (startHours || 0) * rowHeight
}

export const getDimensions = (start: DateLike, end: DateLike, config: ViewConfig, totalHeight: number) => {
  const dStart = +dayjs(start).startOf('day')
  const uStart = +dayjs(start)
  const uEnd = +dayjs(end)
  const rowHeight: number = config.rowHeight || ROW_HEIGHT
  const calendarRealHeight: number = rowHeight * 24

  let top = topWithStartHours(
    calendarRealHeight * ((uStart - dStart) / MS_IN_DAY),
    rowHeight || ROW_HEIGHT,
    config.startHours
  )

  let height = calendarRealHeight * ((uEnd - uStart) / MS_IN_DAY)

  if ((top + height) > totalHeight) {
    height -= (top + height) - totalHeight
  }

  return {
    top,
    height
  }
}