export enum EventKey {
  ESC = 'escape',
  ENTER = 'enter',
  SPACE = ' '
}

export function isKey(sourceKey: any, targetKey: EventKey): boolean {
  return sourceKey ? sourceKey.toLowerCase() === targetKey : false
}

export function onKeyHandler(keys: EventKey[], handler: (e: KeyboardEvent) => any): () => void {
  const evHandler = e => {
    if (keys.includes(e.key.toLocaleLowerCase())) {
      handler(e)
    }
  }

  window.addEventListener('keydown', evHandler, true)

  return () => {
    window.removeEventListener('keydown', evHandler, true)
  }
}
