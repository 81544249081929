import React from 'react'
import { ViewColumnSettings } from '../types'
import { getSettingsComponents } from '../../../../../../components/columnHelpers/columnSettings'
import { GridColumn } from '../../../../../../components/grid/types/GridColumn'

export default React.memo(function ViewColumnSettings(
  props: ViewColumnSettings,
) {
  const { column, table, params, title, onChange } = props
  const columnViewSettings = getSettingsComponents(column.type, true)

  if (!columnViewSettings || columnViewSettings.length === 0) {
    return null
  }

  return (
    <div className={'column-settings-item'}>
      {title && <h3>{title}</h3>}
      {columnViewSettings.map((Component, i) => (
        <Component
          key={i}
          table={table}
          params={params}
          column={column as GridColumn}
          onAction={onChange}
        />
      ))}
    </div>
  )
})
