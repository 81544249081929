import React, { useCallback } from 'react'
import { Checkbox } from '../../../components/irv-ui'
import { FormRendererProps } from '../types/FormRenderer'

const FormCheckboxRenderer: React.FC<FormRendererProps> = ({
  value,
  readonly,
  className,
  params = {},
  onChange,
  onCommit,
}) => {
  const handleChange = useCallback(
    checked => {
      if (readonly) {
        return
      }

      onChange(checked)

      if (onCommit) {
        onCommit(checked)
      }
    },
    [onChange, onCommit, readonly],
  )

  return (
    <div className={className}>
      <Checkbox checked={value} onChange={handleChange} readOnly={readonly} />
    </div>
  )
}

export default React.memo(FormCheckboxRenderer)
