import { EyzyUser } from '../../../types/EyzyUser'
import React, { useMemo } from 'react'
import Icon from '../../icon/Icon'
import { getAvatarCDN } from '../../user/Avatar'
import { isUserRemoved } from '../../../utils/is'
import { getColorClass } from '../../../utils/colors'
import { Tag } from '../../irv-ui'

interface Props {
  user: EyzyUser
  showName?: boolean
  onClose?: () => void
}

export default React.memo(function UserBadge(props: Props) {
  const { user, showName, onClose } = props
  const color: string = '#fff'
  const userColor: string = (user.params && user.params.color) || 'b9'

  const userName: string = useMemo(() => {
    if (user.fullName) {
      return user.fullName
    }

    if (user.firstName || user.lastName) {
      return [user.firstName, user.lastName].filter(Boolean).join(' ')
    }

    return user.email
  }, [user])

  let userIcon

  if (user.avatar) {
    const avatar = getAvatarCDN(user.avatar)
    userIcon = <img key={avatar} src={avatar} width={25} height={25} alt="" />
  } else {
    userIcon = <Icon type={'account-circle'} />
  }

  let className = 'eyzy-user-badge ' + getColorClass(userColor)

  if (isUserRemoved(user)) {
    className += ' removed'
  }

  if (false === showName) {
    className += ' nn'
  }

  // TODO remove eyzy-tag className
  return (
    <Tag
      key={user._id}
      style={{ color }}
      rounded
      className={className}
      title={`${userName} (${user.email})`}
      onRemove={onClose}>
      {userIcon}
      {false !== showName && <span className={'name'}>{userName}</span>}
    </Tag>
  )
})
