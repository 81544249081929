import React from 'react'
import { EyzyUser } from '../../types/EyzyUser'
import UserBadge from '../__renderers/user/UserBadge'

type UsersMap = {
  [_id: string]: EyzyUser
}

interface RendererProps {
  value: string
  onClose?: () => void
}

export default function buildTagRenderer(
  usersMap: UsersMap,
  onClose?: (value: any) => void,
): any {
  return (props: RendererProps) => {
    const user: EyzyUser | undefined = usersMap[props.value]

    if (!user) {
      return null
    }

    const badgeProps = {
      user,
    }

    if (onClose) {
      badgeProps['onClose'] = () => onClose(props.value)
    }

    return <UserBadge {...badgeProps} />
  }
}
