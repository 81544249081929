import React, { forwardRef } from 'react'
import { TrayProps } from './types/TrayProps'
import { useDOMRef } from '../hooks/useDomRef'
import Overlay from './Overlay'
import { useOverlay } from '../hooks/useOverlay'
import { Underlay } from './Underlay'
import { cn } from '../utils/cn'

import './styles/tray.scss'

const TrayWrapper = forwardRef((props: TrayProps, ref: any) => {
  const { children, open, style, className } = props
  const { underlayProps } = useOverlay(props, ref)

  const trayClassName = cn('irv-tray', { open }, className)

  return (
    <>
      <Underlay {...underlayProps} open={open} />
      <div className={trayClassName} ref={ref} style={style}>
        {children}
      </div>
    </>
  )
})

const Tray = (props: TrayProps, ref: any) => {
  const { children, open, ...otherProps } = props
  const domRef: any = useDOMRef(ref)

  return (
    <Overlay {...otherProps} open={open}>
      <TrayWrapper {...props} ref={domRef}>
        {children}
      </TrayWrapper>
    </Overlay>
  )
}

export default forwardRef(Tray)
