import React from 'react'
import { VariableSizeList as List } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import { GridRow } from '../grid/types/GridRow'
import { ViewLike } from '../../types/BaseView'
import { BaseTable } from '../../types/BaseTable'
import { hasViewGroup } from '../../utils/view'
import { buildGridColumns } from '../../utils/grid'
import { BaseDelayedRendererFactory } from '../__renderers/RendererFactory'
import { GridColumn } from '../grid/types/GridColumn'
import { EyzyUser } from '../../types/EyzyUser'
import get from 'lodash.get'
import { getColorClass, getTableColorsMap } from '../../utils/colors'
import { getValue } from "../../general/utils/value"

import './style.scss'
import { applySort } from "../../utils/sort"

interface Props {
  user?: EyzyUser
  rows: GridRow[]
  view: ViewLike
  table: BaseTable
  readonly?: boolean

  height?: number

  onResize?: (columnId: string, width: number) => void
  onChange?: (rowId: string, column: GridColumn, value: any) => void
  onCreate?: () => void
}

interface State {
  columns: GridColumn[]
  rows: any[]
  rowClasses?: any
}

export default class IorvoList extends React.PureComponent<Props, State> {
  listRef: React.RefObject<HTMLDivElement> = React.createRef()
  list: React.RefObject<HTMLDivElement> = React.createRef()
  header: React.RefObject<HTMLDivElement> = React.createRef()

  constructor(props) {
    super(props)

    this.state = {
      columns: buildGridColumns(props.table, props.view, 10),
      rows: this.buildRows(props),
      rowClasses: props.view.color
        ? getTableColorsMap(props.rows, props.table, props.view, props.user)
        : null,
    }
  }

  componentDidMount() {
    // из-за автоскролла нужно чуток подождать...
    setTimeout(_ => {
      this.list.current &&
        this.list.current.addEventListener(
          'scroll',
          this.handleScrollList,
          false,
        )
    })
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any,
  ) {
    if (prevProps.rows !== this.props.rows) {
      this.setState({
        rows: this.buildRows(this.props),
        rowClasses: this.props.view.color
          ? getTableColorsMap(
              this.props.rows,
              this.props.table,
              this.props.view,
              this.props.user,
            )
          : null,
      })
    }
  }

  componentWillUnmount() {
    if (this.list.current) {
      this.list.current.addEventListener('scroll', this.handleScrollList, false)
    }
  }

  buildRows = (props: Props) => {
    const { rows, table, view, onCreate } = props
    const data = view.sort ? applySort(rows, table, view.sort) : rows
    const resRows: any[] = data.map(r => ({
      row: r,
    }))

    if (onCreate) {
      resRows.push({ onCreate })
    }

    return resRows
  }

  hasGroups = (): boolean => {
    return hasViewGroup(this.props.view, this.props.table)
  }

  handleStartResize = (e: any) => {
    const startPos: number = e.clientX
    const columnId: string | undefined = e.target.dataset.c

    if (!columnId) {
      return
    }

    const { columns } = this.state
    const column: GridColumn | undefined = columns.find(c => c._id === columnId)

    if (!column) {
      return
    }

    document.body.style.userSelect = 'none'

    const initColumnWidth: number = column.width || 150
    let newWidth: number

    const handleMouseUp = () => {
      document.body.style.userSelect = ''

      window.removeEventListener('mouseup', handleMouseUp)
      window.removeEventListener('mousemove', handleMouseMove)

      if (this.props.onResize) {
        this.props.onResize(columnId, newWidth)
      }
    }

    const handleMouseMove = (e: any) => {
      const widthDiff: number = e.clientX - startPos

      newWidth = Math.max(30, initColumnWidth + widthDiff)

      const stateColumns = columns.map(c => {
        if (c._id === columnId) {
          return {
            ...c,
            width: newWidth,
          }
        }

        return c
      })

      this.setState({ columns: stateColumns })
    }

    window.addEventListener('mouseup', handleMouseUp)
    window.addEventListener('mousemove', handleMouseMove)
  }

  handleScrollList = e => {
    if (this.header.current) {
      this.header.current.scrollLeft = e.target.scrollLeft
    }
  }

  getItemSize = (index: number): number => {
    const row = this.state.rows[index]

    if (!row) {
      return 32
    }

    if (row.onCreate) {
      return 40
    }

    return 32
  }

  getItemsCount = (): number => {
    const { rows, onCreate } = this.props

    return rows.length + 1 + (onCreate ? 1 : 0)
  }

  renderGroups = () => {}

  renderRows = ({ style, index }) => {
    const { columns, rows } = this.state
    const data = rows[index]

    if (!data) {
      return null
    }

    if (data.onCreate) {
      return (
        <div style={style} className={'irv-list-add'}>
          <button onClick={data.onCreate}>+ Добавить запись</button>
        </div>
      )
    }

    const { onChange, view, table } = this.props
    const { rowClasses } = this.state
    const rowColor = rowClasses && rowClasses[data.row._id]

    return (
      <div style={style} className={'irv-list-row'}>
        {rowColor && <i className={`color ${getColorClass(rowColor)}`} />}

        {columns.map(column => (
          <div
            key={column._id}
            style={{ width: column.width || 150 }}
            className={'irv-list-cell'}>
            <BaseDelayedRendererFactory
              onChange={val => onChange(data.row._id, column, val)}
              value={getValue(table, column, data.row)}
              columnType={column.type}
              params={Object.assign(
                {},
                column.params || {},
                get(view, 'columnParams.' + column._id),
              )}
            />
          </div>
        ))}
      </div>
    )
  }

  renderHeader = () => {
    if (this.hasGroups()) {
      return null
    }

    const { columns } = this.state
    const { readonly } = this.props

    return (
      <div className={'irv-list-header'} ref={this.header}>
        <div className={'irv-list-wh'}>
          {columns.map(column => (
            <div
              key={column._id}
              className={'irv-column'}
              style={{ width: column.width || 150 }}>
              {column.name}

              {!readonly && (
                <div
                  className={'rs'}
                  onMouseDown={this.handleStartResize}
                  data-c={column._id}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    )
  }

  render() {
    const { table, rows, height } = this.props
    const style: any = {}

    if (height) {
      style.height = height
    }

    return (
      <div className={'irv-list'} style={style}>
        {this.renderHeader()}

        <div className={'irv-list-content'}>
          <AutoSizer ref={this.listRef}>
            {({ height, width }) => (
              <List
                outerRef={this.list}
                width={width}
                height={height}
                itemCount={this.getItemsCount()}
                itemData={{
                  items: this.state.rows,
                  rows,
                  table,
                }}
                itemSize={this.getItemSize}>
                {this.hasGroups() ? this.renderGroups : this.renderRows}
              </List>
            )}
          </AutoSizer>
        </div>
      </div>
    )
  }
}
