import React from 'react'
import { Button } from '../../../components/irv-ui'
import { ViewProps } from '../types/ViewProps'
import { Event as CalendarEventType } from '../../../types/Calendar'
import { Event, EventDate } from '../../../components/calendar/NEW__types/Event'
import { Calendar } from '../../../components/calendar'
import CalendarConfigurator from '../../../components/workspace/calendar/calendarConfigurator/CalendarConfigurator'
import { BaseView, ViewColor, ViewDateField } from '../../../types/BaseView'
import { TableActions } from '../../../store/actions/TableActions'
import { isEmpty } from '../../../general/utils/is'
import { GridRow } from '../../../components/grid/types/GridRow'
import { BaseTable } from '../../../types/BaseTable'
import { getAllowedColumnForLink } from '../../../utils/get'
import dayjs from 'dayjs'
import get from 'lodash.get'
import { arrToObject } from '../../../general/utils/array'
import { getRowColor } from '../../../utils/colors'
import { GridColumn } from '../../../components/grid/types/GridColumn'
import Tooltip from 'antd/lib/tooltip'
import CalendarEvent from '../../../components/calendar/containers/CalendarEvent'
import { FILTER_FORMAT, format } from '../../../general/utils/date'
import {
  CalendarToolbarProps,
  CalendarViewType,
  ViewType,
} from '../../../components/calendar/NEW__types/Calendar'
import { DateLike } from '../../../components/calendar/types/Event'
import { getActionItem } from '../../../store/CommonEffects'
import store from '../../../store'
import Loading from '../../../components/loading/Loading'
import EventDetailsCard from '../components/calendar/eventDetails/EventDetailsCard'
import {
  eventToRow,
  getRepeatedEvents,
  getToolbarDates,
  isNewEvent,
  lightEventToRow,
  rowToEvent,
} from '../../../utils/calendar'
import { getViewIntervals } from '../../../components/calendar/utils'
import { RRule, rrulestr } from 'rrule'
import { isMob } from '../../../utils/is'
import MobEventDetails from '../components/calendar/mobileEventDetails/MobEventDetails'
import { createRowWithDefaults } from '../../../utils/grid'
import { ToolbarDirection } from '../../../components/calendar/types/Toolbar'
import Select from 'antd/es/select'

import '../styles/calendar.scss'
import Dialog from '../../../components/irv-ui/components/dialog/Dialog'

enum RecurringType {
  CHANGING,
  DELETING,
}

interface State {
  events: Event[]
  active: Date
  activeView?: any
  isConfiguratorVisible?: boolean
  rows: GridRow[]
  loading?: boolean
  selected?: Event | null
  selectedOrigin?: Event | null
  recurring?: { type: RecurringType; payload: any; event: Event } | null
  dateFields: ViewDateField[]
}

export default class CalendarView_NEW extends React.PureComponent<
  ViewProps,
  State
> {
  container: React.RefObject<HTMLDivElement> = React.createRef()

  constructor(props: ViewProps) {
    super(props)

    const isConfigured = this.isConfigured()

    this.state = {
      events: [],
      rows: props.rows,
      active: new Date(),
      activeView: get(
        props.view,
        'params.calendar.views[0].key',
        ViewType.MONTH,
      ),
      isConfiguratorVisible: !isConfigured,
      dateFields: this.getDateFields(props.table, props.view),
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleKeyUp, false)
  }

  componentDidMount() {
    this.prepareEvent()

    document.addEventListener('keyup', this.handleKeyUp, false)
  }

  componentDidUpdate(
    prevProps: Readonly<ViewProps>,
    prevState: Readonly<State>,
    snapshot?: any,
  ) {
    if (prevProps.rows !== this.props.rows) {
      this.prepareEvent()
    }
  }

  isCardHidden = (): boolean => {
    const view = this.getActiveView()

    if (!view || !view.config) {
      return true
    }

    return view.config.showCard === false
  }

  isRepeatableItem = (id: string): boolean => {
    return !!id && /\.\d+$/.test(id)
  }

  isConfigured = (): boolean => {
    const fields = get(this.props.view, 'params.calendar.fields')
    return fields && !isEmpty(fields)
  }

  getDateFields = (table: BaseTable, view: BaseView): ViewDateField[] => {
    if (!this.isConfigured()) {
      return []
    }

    const columnsMap = arrToObject(table.columns, '_id', false)
    const keys = ['start', 'end', 'settings']

    return view.params.calendar.fields
      .map(df => {
        const result: Partial<ViewDateField> = {}

        keys.forEach(key => {
          if (df[key]) {
            const column: GridColumn | undefined = columnsMap[df[key]]

            if (column) {
              result[key] = column
            }
          }
        })

        result.title = columnsMap[df.title] || getAllowedColumnForLink(table)

        if (!result.title) {
          // eslint-disable-next-line
          return
        }

        return result as ViewDateField
      })
      .filter(Boolean)
  }

  getActiveView = (): CalendarViewType => {
    const views = this.getCalendarViews()
    return views.find(v => v.key === this.state.activeView) || views[0]
  }

  getCalendarViews = (): CalendarViewType[] => {
    return get(this.props.view, 'params.calendar.views', [
      {
        type: ViewType.MONTH,
        label: 'Месяц',
      },
    ])
  }

  buildAnEventFromRow = (row: GridRow, dfIndex?: number): Event | null => {
    const dateFields = this.state.dateFields
    const df = dateFields[dfIndex || 0]

    if (df) {
      const { table } = this.props
      return rowToEvent(row, df, table, { df: dfIndex || 0 })
    }

    return null
  }

  buildEvents = (
    rows: GridRow[],
    field: ViewDateField,
    payload: any,
  ): Event[] => {
    const { view, table } = this.props
    const { start, title } = field

    const { active } = this.state
    const mainColumn = title || getAllowedColumnForLink(table)

    if (!start || !mainColumn) {
      return []
    }

    const calendarView: CalendarViewType = this.getActiveView()

    if (!calendarView) {
      return []
    }

    const color: ViewColor = view.color
    const events: Event[] = []
    const intervals = getViewIntervals(active, calendarView)

    rows.forEach(row => {
      let eventClassName: string | undefined

      if (color) {
        const rowColor: any = getRowColor(row, table, view)

        if (rowColor) {
          eventClassName = `color-${rowColor}`
        }
      }

      const event: CalendarEventType | undefined = rowToEvent(
        row,
        field,
        table,
        payload,
      )

      if (!event) {
        return
      }

      const resEvents: Event[] = event.rrule
        ? getRepeatedEvents(event, intervals)
        : [event]

      if (eventClassName) {
        events.push(
          ...resEvents.map(e => ({ ...e, className: eventClassName })),
        )
      } else {
        events.push(...resEvents)
      }
    })

    return events
  }

  loadEvents = (active: DateLike) => {
    const { table, view } = this.props
    const payload = {
      viewId: view._id,
      view: this.state.activeView,
      active: dayjs(active).format(FILTER_FORMAT),
    }

    return getActionItem(TableActions.FILTER_ROWS, table._id, payload).then(
      payload => {
        store.dispatch({
          type: TableActions.FILTER_ROWS,
          payload,
        })

        this.setState({ loading: false })
      },
    )
  }

  prepareEvent = (resetState?: boolean) => {
    const { rows } = this.props
    const events: Event[] = []

    if (!this.isConfigured()) {
      return
    }

    this.state.dateFields.forEach((field: ViewDateField, i) => {
      const preparedEvents: Event[] = this.buildEvents(rows, field, { df: i })

      events.push(...preparedEvents)
    })

    let newState = { events }

    if (resetState) {
      newState = Object.assign({}, newState, {
        selected: null,
        events: events.filter(e => !isNewEvent(e)),
        recurring: null,
      })
    }

    this.setState(newState)
  }

  closeFieldConfigurator = () => {
    this.setState({ isConfiguratorVisible: false })
  }

  cancelCreation = () => {
    this.prepareEvent(true)
  }

  initRecurringChange = (payload: any, event?: Event) => {
    return this.setState({
      recurring: {
        type: RecurringType.CHANGING,
        payload,
        event: event || this.state.selectedOrigin,
      },
    })
  }

  handleKeyUp = e => {
    const { selected } = this.state

    if (e.keyCode === 27) {
      if (selected && selected.id === '-1') {
        this.cancelCreation()
      } else {
        this.setState({ selected: null, selectedOrigin: null, recurring: null })
      }
    }
  }

  handleNavigate = payload => {
    this.loadEvents(payload.active)
    this.setState({
      ...payload,
      selected: null,
      selectedOrigin: null,
      loading: true,
    })
  }

  handleConfigurationChange = (dateField: any) => {
    this.props
      .onAction(TableActions.UPDATE_VIEW, {
        dateField,
        viewId: this.props.view._id,
      })
      .then(_ => {
        this.setState({ isConfiguratorVisible: false })
      })
  }

  handleSelect = (selected?: Event) => {
    if (selected && selected.id === '-1') {
      return
    }

    this.setState({
      selected,
      selectedOrigin: { ...selected },
      events: this.state.events.filter(e => e.id !== '-1'),
    })
  }

  handleRemove = (id: string) => {
    if (!id) {
      return this.cancelCreation()
    }

    this.props.onAction(TableActions.DELETE_ROW, {
      rowIds: [id.replace(/\.\d+$/, '')],
    })

    this.cancelCreation()
  }

  handleSave = (data: any) => {
    const { selected, dateFields } = this.state

    if (!selected || !this.isConfigured()) {
      return
    }

    if (!isNewEvent(selected) && this.isRepeatableItem(selected.id)) {
      return this.initRecurringChange(data)
    }

    const row: GridRow = {
      ...createRowWithDefaults(this.props.table, this.props.view),
      ...eventToRow({ ...selected, ...data }, dateFields[0]),
    }

    if (isNewEvent(selected)) {
      return this.props
        .onAction(TableActions.CREATE_ROW, { data: row })
        .then(_ => {
          this.cancelCreation()
        })
    }

    const df = dateFields[selected.payload.df]

    if (!df) {
      throw new Error(`Event [${selected.id} doesnt have a DF`)
    }

    this.props.onAction(TableActions.UPDATE_ROW, {
      rowId: selected.id,
      data: lightEventToRow(data, df),
    })

    this.cancelCreation()
  }

  handleChangeDetails = (value: any) => {
    const { events, selected, dateFields } = this.state

    let selectedEvent = {
      ...selected,
      ...value,
    }

    if ('rrule' in value && value.rrule !== null) {
      selectedEvent.rrule = new RRule(value.rrule)
    }

    const isNew: boolean = isNewEvent(selectedEvent)
    const state: Partial<State> = {}

    if (isNew) {
      if (selectedEvent.repeat) {
        const isNew: boolean = isNewEvent(selectedEvent)
        const df = dateFields[selectedEvent.payload.df] || dateFields[0]
        const row: GridRow = eventToRow({ ...selectedEvent }, df)

        row._id = selectedEvent.id

        const repeatEvents: Event[] = this.buildEvents(
          [row],
          df,
          selectedEvent.payload || { df: 0 },
        )
        const filterFn = (e: Event) => {
          if (isNew) {
            return !isNewEvent(e)
          }

          return true
        }

        state.events = events.filter(filterFn).concat(...repeatEvents)
        selectedEvent = repeatEvents[0]
      } else {
        state.events = events.map(e =>
          e.id === selectedEvent.id ? selectedEvent : e,
        )
      }
    } else {
      state.events = events.map(e =>
        e.id === selectedEvent.id ? { ...selectedEvent } : e,
      )
    }

    if (value.start) {
      state.active = value.start
    }

    this.setState(state as State, () => {
      this.setState({ selected: selectedEvent })
    })
  }

  handleChangeEvent = (event: Event, dates: EventDate) => {
    const dateFields = this.state.dateFields[event.payload.df]

    if (!dateFields) {
      return
    }

    const { start, end } = this.state.dateFields[0]
    const payload: any = {}

    if (dates.start) {
      payload[start._id] = dates.start
    }

    if (end && dates.end) {
      payload[end._id] = dates.end
    }

    if (this.isRepeatableItem(event.id)) {
      return this.initRecurringChange(dates, event)
    }

    this.props.onAction(TableActions.UPDATE_ROW, {
      rowId: event.id,
      data: payload,
    })
  }

  handleAdd = (dates: EventDate) => {
    if (!this.isConfigured() || this.isCardHidden()) {
      return
    }

    const { dateFields, events } = this.state
    const { start, end } = dateFields[0]

    const newRow: GridRow = {
      _id: '-1',
      [start._id]: dates.start,
    }

    if (end && dates.end) {
      newRow[end._id] = dates.end
    }

    const newEvent = this.buildAnEventFromRow(newRow)

    this.setState(
      {
        events: events.concat(newEvent),
      },
      () => {
        this.setState({
          selected: newEvent,
        })
      },
    )
  }

  handleViewChange = (activeView: string) => {
    this.setState({ activeView }, () => {
      this.loadEvents(this.state.active)
    })
  }

  recurringCurrent = () => {
    const { recurring, dateFields } = this.state
    const { payload, event } = recurring
    const rowId: string = event.id.replace(/\.\d+$/g, '')
    const df = dateFields[event.payload.df] || dateFields[0]
    const originalRow = this.props.rows.find(r => r._id === rowId)

    const settingsId = df.settings._id
    const startId = df.start._id
    const endId = df.end._id

    // TODO У записи удалить ссылки (или что делать с ними)

    const end = dayjs(event.start).subtract(1, 'day').add(1, 'hour')

    const newRow = {
      ...originalRow,
      ...lightEventToRow(payload, df),
      [startId]: event.start,
    }

    if (endId) {
      newRow[endId] = event.end
    }

    delete newRow[settingsId]
    delete newRow._id

    let updatedRecurring

    // обновим оригинал
    try {
      const originRRule = rrulestr(get(originalRow[settingsId], 'rrule'))
      const originNewRRule = new RRule({
        ...originRRule.origOptions,
        until: end.toDate(),
      })

      updatedRecurring = {
        rrule: originNewRRule.toString().replace('RRULE:', ''),
      }
    } catch (e) {
      console.log(e)
    }

    // обновим текущий
    const nextRowRRule = new RRule({
      ...rrulestr(get(originalRow[settingsId], 'rrule')).origOptions,
      dtstart: dayjs(event.start).add(1, 'day').toDate(),
    })

    let nextRow = { ...originalRow }
    delete nextRow._id

    nextRowRRule.all(d => {
      nextRow[startId] = d

      if (endId) {
        const diffMinutes: number = dayjs(event.end).diff(event.start, 'minute')
        nextRow[endId] = dayjs(d).add(diffMinutes, 'minute')
      }

      return false
    })

    this.props.onAction(TableActions.UPDATE_ROW, {
      rowId,
      data: {
        [settingsId]: updatedRecurring,
      },
    })

    this.props.onAction(TableActions.CREATE_ROW, { data: newRow })

    this.props.onAction(TableActions.CREATE_ROW, { data: nextRow })

    this.cancelCreation()
  }

  recurringFuture = () => {
    const { recurring, dateFields } = this.state
    const { payload, event } = recurring
    const rowId: string = event.id.replace(/\.\d+$/g, '')
    const df = dateFields[event.payload.df] || dateFields[0]
    const originalRow = this.props.rows.find(r => r._id === rowId)

    const settingsId = df.settings._id

    // TODO У записи удалить ссылки (или что делать с ними)

    const end = dayjs(event.start).subtract(1, 'day').add(1, 'hour')

    const newRow = {
      ...originalRow,
      ...lightEventToRow(payload, df),
    }

    delete newRow._id

    let updatedRecurring

    try {
      const originRRule = rrulestr(get(originalRow[settingsId], 'rrule'))
      const originNewRRule = new RRule({
        ...originRRule.origOptions,
        until: end.toDate(),
      })

      updatedRecurring = {
        rrule: originNewRRule.toString().replace('RRULE:', ''),
      }
    } catch (e) {
      console.log(e)
    }

    this.props.onAction(TableActions.UPDATE_ROW, {
      rowId,
      data: {
        [settingsId]: updatedRecurring,
      },
    })

    this.props.onAction(TableActions.CREATE_ROW, { data: newRow })

    this.cancelCreation()
  }

  recurringAll = () => {
    const { recurring, dateFields } = this.state
    const { payload, event } = recurring
    const rowId: string = event.id.replace(/\.\d+$/g, '')
    const df = dateFields[event.payload.df] || dateFields[0]
    const originalRow = this.props.rows.find(r => r._id === rowId)

    if (!originalRow) {
      return
    }

    const start = df.start._id
    const end = df.end && df.end._id
    const isMain: boolean = /\.0$/.test(event.id)

    let data = { ...payload }

    if (!isMain) {
      if (data.start) {
        const newStartDate = dayjs(data.start)

        data.start = dayjs(originalRow[start])
          .hour(newStartDate.hour())
          .minute(newStartDate.minute())
          .toDate()
      }

      if (data.end) {
        const newEndDate = dayjs(data.end)

        data.end = dayjs(originalRow[end])
          .hour(newEndDate.hour())
          .minute(newEndDate.minute())
          .toDate()
      }
    }

    data = lightEventToRow(data, df)

    this.props.onAction(TableActions.UPDATE_ROW, {
      rowId,
      data,
    })

    this.cancelCreation()
  }

  renderEvent = props => {
    return <div className={'c-event-title'}>{props.event.title}</div>
  }

  renderTooltipEvents = ({ events, moreEvents, date }) => {
    return (
      <div className={'ct-events'}>
        <div className="ct-date">{format(date)}</div>
        {events.map(e => (
          <CalendarEvent event={e} key={e.id} />
        ))}
        <div className="ct-hr" />
        {moreEvents.map(e => (
          <CalendarEvent event={e} key={e.id} />
        ))}
      </div>
    )
  }

  renderToolbar = (props: CalendarToolbarProps) => {
    const { activeView } = this.state

    return (
      <>
        <button
          className={'nav-ctrl'}
          onClick={_ => props.navigate(ToolbarDirection.PREV_PERIOD)}>
          <i className={'icon mdi mdi-chevron-left'} />
        </button>
        <button
          className="today"
          onClick={_ => props.navigate(ToolbarDirection.TODAY)}>
          Сегодня
        </button>
        <button
          className={'nav-ctrl'}
          onClick={_ => props.navigate(ToolbarDirection.NEXT_PERIOD)}>
          <i className={'icon mdi mdi-chevron-right'} />
        </button>

        <div className={'nav-dates'}>
          {getToolbarDates(props.activeView.type, props.dates)}
        </div>

        {props.views.length > 1 ? (
          <div className="nav-views">
            <Select
              value={activeView}
              onChange={this.handleViewChange}
              dropdownMatchSelectWidth={false}>
              {props.views.map(view => (
                <Select.Option value={view.key} key={view.key}>
                  {view.label}
                </Select.Option>
              ))}
            </Select>
          </div>
        ) : null}
      </>
    )
  }

  renderMore = props => {
    return (
      <Tooltip
        trigger={['click']}
        overlayClassName={'resp-tooltip'}
        destroyTooltipOnHide
        title={this.renderTooltipEvents(props)}>
        {props.children}
      </Tooltip>
    )
  }

  renderEventCard = () => {
    if (!this.isConfigured() || this.isCardHidden()) {
      return null
    }

    const { selected, dateFields } = this.state
    const df: ViewDateField =
      selected && selected.payload
        ? dateFields[selected.payload.df]
        : dateFields[0]

    if (isMob()) {
      return (
        <MobEventDetails
          event={selected}
          dateFields={df}
          view={this.getActiveView()}
          onSave={this.handleSave}
          onChange={this.handleChangeDetails}
          onRemove={this.handleRemove}
        />
      )
    }

    return (
      <EventDetailsCard
        event={selected}
        dateFields={df}
        view={this.getActiveView()}
        onSave={this.handleSave}
        onChange={this.handleChangeDetails}
        onRemove={this.handleRemove}
      />
    )
  }

  renderRecurringModal() {
    const { recurring } = this.state
    const visible: boolean = !!recurring

    let content = null
    let buttons = null

    if (visible) {
      const { event, payload } = recurring
      const eventDateColumns: string[] = ['start', 'end']
      const isDateChanged: boolean = eventDateColumns.some(id => id in payload)

      let showAll: boolean = !isDateChanged

      if (!showAll) {
        showAll =
          dayjs(event.start).isSame(payload.start, 'day') ||
          dayjs(event.end).isSame(payload.end, 'day')
      }

      if (recurring.type === RecurringType.CHANGING) {
        content = (
          <div className={'c-recurring-head'}>
            <h3>Вы изменяете повторяющееся событие.</h3>
            <span className={'secondary-text'}>
              Вы хотите изменить только это событие или изменить время для этого
              и всех будущих событий?
            </span>
          </div>
        )

        const controls = [
          <Button variant="primary" autoFocus onClick={this.recurringCurrent}>
            Только это событие
          </Button>,
          <Button onClick={this.recurringFuture}>Это и все последующие</Button>,
        ]

        if (showAll) {
          controls.push(
            <Button onClick={this.recurringAll}>Все события</Button>,
          )
        }

        controls.push(
          <Button className={'cancel'} onClick={this.cancelCreation}>
            Отмена
          </Button>,
        )

        buttons = <div className={'c-recurring-controls'}>{controls}</div>
      }
    }

    return (
      <Dialog open={visible} width={370} footer={null}>
        {content}
        {buttons}
      </Dialog>
    )
  }

  render() {
    const {
      active,
      selected,
      activeView,
      events,
      loading,
      isConfiguratorVisible,
    } = this.state
    const { view, table } = this.props

    return (
      <div className={'ws-view'} ref={this.container}>
        <CalendarConfigurator
          table={table}
          view={view}
          onChange={this.handleConfigurationChange}
          onCancel={this.closeFieldConfigurator}
          visible={isConfiguratorVisible}
        />

        <Calendar
          events={events}
          active={active}
          activeView={activeView}
          selected={selected && selected.id}
          views={this.getCalendarViews()}
          moreRenderer={this.renderMore}
          toolbar={this.renderToolbar}
          eventRenderer={this.renderEvent}
          onAdd={this.handleAdd}
          onChange={this.handleChangeEvent}
          onSelect={this.handleSelect}
          onNavigate={this.handleNavigate}
        />

        {this.renderEventCard()}
        {this.renderRecurringModal()}

        {loading && <Loading delay={1000} />}
      </div>
    )
  }
}
