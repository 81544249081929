import React, { useMemo } from "react"
import { SimpleRendererProps } from '../types/SimpleRendererProps'
import { format } from "../../../general/utils/date"
import { GridColumnOptionItem } from "../../grid/types/GridColumn"
import { asArray } from "../../../utils/as"
import { RendererClassNames } from "../definitions"
import Tag from '../../shape/Tag'

const SelectRenderer: React.FC<SimpleRendererProps> = ({ value, className, params, column }) => {
  const items: GridColumnOptionItem[] = useMemo(() => {
    const options = column.params.options || []

    return asArray(value)
      .map(id => options.find(o => o._id === id))
      .filter(Boolean)
  }, [value, column])

  return (
    <div className={className}>
      {items.map(item => (
        <Tag
          key={item._id}
          color={params.useColor ? item.color : ''}
          removed={!!item.removedOn}>
          {item.text}
        </Tag>
      ))}
    </div>
  )
}

export default React.memo(SelectRenderer)
