import {Base} from "../../../types/Base";

export function baseUpdater(state: any, baseId: string, cb: (base: Base) => Partial<Base>) {
  return state.map(ws => {
    const hasBase: boolean = ws.bases.some(base => {
      return base._id === baseId
    })

    if (!hasBase) {
      return ws
    }

    return {
      ...ws,
      bases: ws.bases.map(base => {
        return base._id === baseId
          ? {...base, ...cb(base)}
          : base
      })
    }
  })
}
