import React from 'react'
import { BaseRendererProps } from '../types/BaseRenderer'
import { RendererClassNames } from '../definitions'
import LinkToRecordTags from '../utils/LinkToRecordTags'

const LinkToRecordRenderer: React.FC<BaseRendererProps> = ({ value, handler }) => {
  return (
    <div className={RendererClassNames.LINK_TO_RECORD}>
      <LinkToRecordTags value={value} onRemove={handler} />
    </div>
  )
}

export default React.memo(LinkToRecordRenderer)
