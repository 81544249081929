import { useEffect, useState } from 'react'
import { EventKey } from '../../utils/dom'
import { isMob } from "../../utils/is"

interface KeyState {
  key: EventKey
  shiftKey?: boolean
  ctrlKey?: boolean
  metaKey?: boolean
}

const keydownHandlers = {}

document.addEventListener('DOMContentLoaded', () => {
  window.addEventListener('keydown', e => {
    // @ts-ignore
    Object.values(keydownHandlers).forEach(h => h(e))
  })

  window.addEventListener('keyup', e => {
    // @ts-ignore
    Object.values(keydownHandlers).forEach(h => h({ key: null }))
  })
})

export const useKeyPress = (
  keys: string[] | null,
  active: boolean,
  id: any,
) => {
  if (!id) {
    throw new Error()
  }

  const [keyPressed, setKeyPressed] = useState<KeyState | null>(null)

  useEffect(() => {
    if (active && !isMob()) {
      keydownHandlers[id] = ({ key, shiftKey, ctrlKey, metaKey }) => {
        if (key === null) {
          setKeyPressed(null)
        } else if (!keys || keys.includes(key.toLowerCase())) {
          setKeyPressed({ key, shiftKey, ctrlKey, metaKey })
        }
      }
    } else {
      delete keydownHandlers[id]
    }
  }, [active, keys, id])

  useEffect(() => {
    return () => {
      delete keydownHandlers[id]
    }
  }, [id])

  return keyPressed
}
