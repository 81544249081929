import React from "react";
import {BaseRendererProps} from "../BaseRendererProps";
import {BaseRendererFactory} from "../RendererFactory";
import {isArray, isString} from "../../../utils/is";
import {asArray} from "../../../utils/as";
import {ColumnType} from "../../grid/types/ColumnType";

const getForeignColumnType = (params) => {
  if (params.foreignType !== ColumnType.LOOKUP) {
    return params.foreignType
  }

  let maxIterations: number = 10
  let i: number = 0
  let foreignParams = params.foreignParams

  while (i++ < maxIterations) {
    if (foreignParams && foreignParams.foreignType && foreignParams.foreignType !== 'lookup') {
      return foreignParams.foreignType
    }

    foreignParams = foreignParams.foreignParams
  }
}

export default React.memo(function BaseLookupRenderer(props: BaseRendererProps<{ _id: string, value: any }[]>) {
  // TODO это сделано чисто для группировки. Избавиться!!
  const values = isString(props.value)
    ? [{ value: props.value, _id: '' }]
    : props.value

  const foreignParams = props.params ? props.params.foreignParams : null
  const columnType = getForeignColumnType(props.params)

  if (!columnType || columnType === ColumnType.LOOKUP) {
    // TODO: throw an error
    return null
  }

  return (
    <div className={'lookup-value'}>
      { asArray(values).map(({value, _id}) => {
        if (isArray(value) && value[0] && value[0]._id) {
          return (
            <div key={_id}>
              {value.map(({_id, value}) => {
                return (
                  <BaseRendererFactory
                    {...props}
                    {...foreignParams}
                    columnType={columnType || props.columnType}
                    key={_id}
                    value={value}
                    readonly={true}
                  />
                )
              })}
            </div>
          )
        }

        return (
          <BaseRendererFactory
            {...props}
            {...foreignParams}
            columnType={columnType || props.columnType}
            key={_id}
            value={value}
            readonly={true}
          />
        )
      }) }
    </div>
  )
})
