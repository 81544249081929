import React, { forwardRef, Ref } from "react"
import { IrvBaseInputProps } from "./types/IrvBaseInputProps"
import { parseWidth } from "../../common/dom"
import { isEnter, isEsc } from "../../common/keyboard"
import { cn } from "../../../../utils/classname"

import './styles.scss'

function BaseInput(props: IrvBaseInputProps, ref: Ref<any>) {
  const {
    processValue,
    className,
    width,
    fit,
    type,
    style,
    onPressEnter,
    onPressEsc,
    onKeyDown,
    onChange,
    ...rest
  } = props

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const val: string = processValue
      ? processValue(e.target.value)
      : e.target.value

    if (onChange) {
      onChange(val, e)
    }
  }

  const handleKeyDown = (e: any) => {
    if (onPressEnter && isEnter(e)) {
      onPressEnter(e)
    }

    if (onPressEsc && isEsc(e)) {
      onPressEsc(e)
    }

    if (onKeyDown) {
      onKeyDown(e)
    }
  }

  const classNames = cn(
    type === "textarea" ? "e-textarea" : "e-input",
    className,
  )

  const parsedWidth = parseWidth(width)
  const elemStyle = style || {}

  if (parsedWidth) {
    elemStyle.width = parsedWidth
  }

  const ElementType: React.ElementType =
    type === "textarea" ? "textarea" : "input"

  return (
    // @ts-ignore
    <ElementType
      {...rest}
      ref={ref}
      autoFocus={!!rest.autoFocus}
      type={type || "text"}
      className={classNames}
      style={elemStyle}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
    />
  )
}

export default forwardRef(BaseInput)
