import {Base} from "../../types/Base";
import {BaseActions} from "../actions/BaseActions";
import {removeItem, replaceItem} from "../../utils/array";
import {WorkspaceActions} from "../actions/WorkspaceActions";
import {TableActions} from "../actions/TableActions";

const initState: Base[] = []

export default function bases(state = initState, action) {
  const payload = action.payload || {}
  const result = action.result

  switch (action.type) {
    case BaseActions.FETCH:
    case WorkspaceActions.FETCH:
    case TableActions.FETCH:
      const bases = payload.bases || state

      if (!payload.tables) {
        return bases
      }

      const fetchingBaseId: string = payload.tables[0].baseId

      return bases.map(base => {
        if (fetchingBaseId !== base._id) {
          return base
        }

        return {
          ...base,
          tables: payload.tables
        }
      })

    case WorkspaceActions.CREATE_BASE:
      if (!result) {
        return state.concat(
          {
            ...payload,
            _id: -1
          }
        )
      }

      return replaceItem(state, b => b._id === -1, [result])

    case BaseActions.FETCH_TABLES: {
      const {id, tables} = payload

      return state.map(base => {
        if (base._id === id) {
          return {
            ...base,
            tables
          }
        }

        return base
      })
    }

    case BaseActions.REMOVE:
      return removeItem(state, b => b._id === payload._id)

    case BaseActions.UPDATE:
      const {_id, ...rest} = payload
      return replaceItem(state, b => b._id === _id, [], (base) => {
        return {
          ...base,
          ...rest
        }
      })

    case BaseActions.UPDATE_PERMISSIONS:
      if (result) {
        return state.map(base => {
          if (base._id !== payload.id) {
            return base
          }

          return {
            ...base,
            limitedAccess: !!payload.limitedAccess
          }
        })
      }

      return state

    default:
      return state
  }
}