import React, { useEffect, useState } from "react"
import { Input } from "../../../components/irv-ui"
import { ActionType } from "../../../store/CommonEffects"
import ComplexMenu from "../../dropdown/ComplexMenu"
import { EventKey, onKeyHandler } from "../../../utils/dom"
import { TableActions } from "../../../store/actions/TableActions"
import { GridColumn } from "../../grid/types/GridColumn"
import { ViewActions } from "../../../store/actions/ViewActions"
import Menu from "antd/lib/menu"
import { getMenuIcon } from "../../dropdown/DropdownMenu"

type Props = {
  children: React.ReactNode
  column: GridColumn
  onAction: (action: ActionType, payload?: any) => void
}

const menuItems = [
  // {key: '1', divider: 1},
  // {key: TableActions.DUPLICATE_COLUMN, text: 'Создать копию'},
  // {key: ViewActions.GROUP_COLUMN, text: 'Группировать'},
  // {key: TableActions.ADD_LEFT_COLUMN, text: 'Добавить поле слева'},
  // {key: TableActions.ADD_RIGHT_COLUMN, text: 'Добавить поле справа'},
  { key: ViewActions.SORT_COLUMN_A_Z, text: "Сортировка по возрастанию" },
  { key: ViewActions.SORT_COLUMN_Z_A, text: "Сортировка по убыванию" },
  { key: "1", divider: 1 },
  { key: ViewActions.HIDE_COLUMN, text: "Спрятать поле" },
  { key: TableActions.REMOVE_COLUMN, text: "Удалить поле" },
]

function ColumnContextMenu(props: Props) {
  const { children, column, onAction } = props
  const [entityName, setEntityName] = useState(column.name)
  const [visible, setMenuVisibility] = useState(false)

  let keyHandlerState: null | (() => void)

  useEffect(() => {
    setEntityName(column.name)
  }, [column])

  const releaseKeyHandler = () => {
    if (keyHandlerState) {
      keyHandlerState()
    }

    keyHandlerState = null
  }

  const handleClickMenu = ({ key }) => {
    setMenuVisibility(false)
    onAction(key, { columnId: column._id })
  }

  const handleChangeName = name => setEntityName(name)
  const saveName = () => {
    if (entityName !== column.name) {
      onAction(TableActions.UPDATE_COLUMN, {
        columnId: column._id,
        name: entityName,
      })

      setTimeout(() => {
        setMenuVisibility(false)
      }, 200)
    }
  }

  const onVisibleChange = v => {
    setMenuVisibility(v)

    if (v) {
      keyHandlerState = onKeyHandler([EventKey.ESC], () => {
        cancelEditing()
        setEntityName(column.name)
        releaseKeyHandler()
      })
    } else {
      releaseKeyHandler()
    }
  }

  const cancelEditing = () => {
    setMenuVisibility(false)
  }

  const openSettings = () => {
    onAction(TableActions.SETTINGS_COLUMN, { columnId: column._id })
    cancelEditing()
  }

  const header = (
    <>
      <Input
        fit
        autoFocus
        value={entityName}
        className={"din"}
        onChange={handleChangeName}
        onPressEnter={saveName}
        onBlur={saveName}
      />
      <Menu onClick={openSettings}>
        <Menu.Item>
          {getMenuIcon(TableActions.SETTINGS_COLUMN)}
          Настройки поля
        </Menu.Item>
      </Menu>
    </>
  )

  return (
    <ComplexMenu
      menu={menuItems}
      header={header}
      overlayClassName={"sb-smenu"}
      visible={visible}
      isMHeader={true}
      onClick={handleClickMenu}
      onVisibleChange={onVisibleChange}>
      {children}
    </ComplexMenu>
  )
}

export default ColumnContextMenu
