import React, {useState} from "react";
import {GridRow} from "../grid/types/GridRow";
import {BaseTable} from "../../types/BaseTable";
import {cn} from "../../utils/classname";
import {BaseRendererFactory} from "../__renderers/RendererFactory";
import {BaseView} from "../../types/BaseView";
import {GridColumn} from "../grid/types/GridColumn";
import {getColumnParams} from "../../utils/get";
import {prepareColumns} from "../../utils/column";
import {Checkbox} from '../../components/irv-ui';
import {getValue} from "../../general/utils/value";

interface Props {
  row: GridRow
  table: BaseTable
  view?: BaseView
  onClick?: (row: GridRow) => void
  onRemove?: (id: string) => void
  active?: boolean
  expandable?: boolean
  showCheckbox?: boolean
}

function getColStyle(column: GridColumn, view?: BaseView): any {
  if (!view) {
    return
  }

  const params = getColumnParams(view, column._id)

  if (!params.width) {
    return
  }

  return {
    minWidth: `${params.width}px`
  }
}

export default React.memo(function LinkedRecordItem(props: Props) {
  const {active, row, table, onClick, onRemove, view, showCheckbox, expandable} = props
  const [expanded, setExpanded] = useState(false)
  const className = cn(
    'sbr',
    'linked-records__row',
    {active},
    {expanded}
  )

  const handleClickRow = () => {
    if (expandable) {
      setExpanded(!expanded)
    }

    onClick && onClick(row)
  }

  return (
    <div className={className} onClick={handleClickRow}>
      <div className='body'>
        {showCheckbox && (
          <Checkbox checked={active} onClick={e => e.stopPropagation()}/>
        )}

        {prepareColumns(table, view).map(column => (
          <div className="linked-records__col" key={column._id} style={getColStyle(column, view)}>
            <span className="col-name">{column.name}</span>
            <span className="col-value">
              <BaseRendererFactory
                value={getValue(table, column, row)}
                readonly={true}
                onChange={_ => {
                }}
                columnType={column.type}
                params={column.params || {}}
                options={(column.params || {}).options}
              />
            </span>
          </div>
        ))}
      </div>
      {onRemove && (
        <svg className="remove" onClick={(e) => {
          e.stopPropagation();
          onRemove(row._id)
        }}>
          <path
            d="M 12.59,6L 10,8.59L 7.41,6L 6,7.41L 8.59,10L 6,12.59L 7.41,14L 10,11.41L 12.59,14L 14,12.59L 11.41,10L 14,7.41L 12.59,6 Z "/>
        </svg>
      )}
    </div>
  )
})
