import React, { memo, ReactNode, useCallback, useEffect, useMemo, useState } from "react"
import {
  GroupRendererProps,
  TableGroup,
  TableRow,
  TableState,
} from '../types/Table'
import Checkbox from '../../checkbox/Checkbox'
import Caret from "./Caret"

interface Props {
  rows: any[]
  group: TableGroup
  groupRenderer?: (props: GroupRendererProps) => ReactNode

  state: TableState

  showCheckbox?: boolean

  onSelect: (id: string[], checked: boolean) => void
}

const isChecked = (rows: TableRow[], state: string[]): boolean => {
  return !rows.some(({ key }) => !state.includes(key as string))
}

const TableGroupRenderer: React.FC<Props> = ({
  rows,
  group,
  groupRenderer,
  state,
  onSelect
}) => {
  const [checked, setChecked] = useState(
    isChecked(rows, state.rowSelection || []),
  )

  const handleSelectionChange = useCallback((checked) => {
    onSelect(
      rows.map(({ key }) => key ),
      checked
    )
  }, [rows, onSelect])

  useEffect(() => {
    setChecked(isChecked(rows, state.rowSelection || []))
  }, [state, rows])

  return (
    <div className={'e-table-group'}>
      <Caret />

      {state.rowSelection && (
        <Checkbox onClick={e => e.stopPropagation()} checked={checked} onChange={handleSelectionChange} />
      )}

      {groupRenderer && (
        <div className={'e-table-group-value'}>
          {groupRenderer({
            group,
            rows,
          })}
        </div>
      )}
    </div>
  )
}

export default memo(TableGroupRenderer)
