import React from "react";
import { BaseRendererProps } from "../BaseRendererProps";
import {asString} from "../../../utils/as";
import TextareaControl from '../../controllers/Textarea'

export default class BaseDelayedLongTextRenderer extends React.PureComponent<BaseRendererProps<string>> {
  render() {
    const { value, readonly, onChange } = this.props;

    if (readonly) {
      return <div className={'re-value'}>{asString(value)}</div>
    }

    return (
      <TextareaControl
        fit
        defaultValue={asString(value)}
        onChange={onChange}
      />
    )
  }
}
