import React from 'react'
import { isMobileDevice } from '../../utils/platform'
import BaseInput from './BaseInput'
import { NumberInputProps } from './types/NumberInputProps'

const INT_MATCH = /^-?(\d)+/

const toNumber = (v: any, isInt?: boolean) => {
  if (v === '-') {
    return '-'
  }

  v = v.replace(/\s/g, '').replace(/,/g, '.')

  if (isInt) {
    return v.match(INT_MATCH)?.[0] || ''
  }

  if (!isFinite(v)) {
    v = parseFloat(v)
  }

  return v
}

export default React.memo(function NumberInput(props: NumberInputProps) {
  const { isInt, negative, ...rest } = props

  const processValue = (val: any) => {
    if (negative === false) {
      val = '' + Math.abs(val)
    }

    return toNumber(val, isInt)
  }

  return (
    <BaseInput
      {...rest}
      type={'text'}
      inputMode={isMobileDevice() ? 'decimal' : 'text'}
      processValue={processValue}
    />
  )
})
