import React from "react"
import { ViewProps } from "../types/ViewProps"
import IorvoList from "../../../components/iorvo-list/IorvoList"
import { GridColumn } from "../../../components/grid/types/GridColumn"
import { TableActions } from "../../../store/actions/TableActions"
import { GridRow } from "../../../components/grid/types/GridRow"
import { createRowWithDefaults } from "../../../utils/grid"

export default class ListView extends React.PureComponent<ViewProps> {
  handleCreate = () => {
    const data: GridRow = createRowWithDefaults(
      this.props.table,
      this.props.view,
    )
    data._id = Math.random()

    this.props.onAction(TableActions.CREATE_ROW, {
      data: [data],
    })
  }

  handleColumnResize = (columnId: string, width: number) => {
    const { onAction, view } = this.props

    onAction(TableActions.UPDATE_VIEW_COLUMN, {
      viewId: view._id,
      columnId,
      params: {
        width
      }
    })
  }

  handleChange = (rowId: string, column: GridColumn, value: any) => {
    this.props.onAction(TableActions.UPDATE_ROW, {
      rowId,
      data: {
        [column._id]: value,
      },
    })
  }

  render() {
    const { rows, table, view, user } = this.props

    return (
      <div className={"ws-view"}>
        {!rows.length && (
          <p className={'no-data'}>Пока нет записей</p>
        )}

        <IorvoList
          rows={rows}
          table={table}
          view={view}
          user={user}
          onCreate={this.handleCreate}
          onChange={this.handleChange}
          onResize={this.handleColumnResize}
        />
      </div>
    )
  }
}
