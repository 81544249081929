import { RefObject, useImperativeHandle, useMemo, useRef } from 'react'

export function createDOMRef(ref) {
  return {
    __getDOMNode() {
      return ref.current
    },
  }
}

export function createFocusableRef(domRef, focusableRef = domRef) {
  return {
    ...createDOMRef(domRef),
    focus() {
      if (focusableRef.current) {
        focusableRef.current.focus()
      }
    },
  }
}

export function useDOMRef<T extends HTMLElement = HTMLElement>(ref): RefObject<T> {
  let domRef = useRef<T>(null)
  useImperativeHandle(ref, () => createDOMRef(domRef))
  return domRef
}

export function useFocusableRef<T extends HTMLElement = HTMLElement>(
  ref,
  focusableRef,
): RefObject<T> {
  let domRef = useRef<T>(null)
  useImperativeHandle(ref, () => createFocusableRef(domRef, focusableRef))
  return domRef
}

export function unwrapDOMRef<T extends HTMLElement>(ref): RefObject<T> {
  return {
    get current() {
      return ref.current && ref.current.__getDOMNode()
    },
  }
}

export function useUnwrapDOMRef(ref) {
  return useMemo(() => unwrapDOMRef(ref), [ref])
}
