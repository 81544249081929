import React from "react"
import { IorvoSequenceNumber, IorvoSequenceNumberRenderer } from "../../types/IorvoGridProps"
import { IorvoGridCellProps, IorvoGridColumn } from "../../types/IorvoGridColumn"

const SNRenderer = (props: IorvoSequenceNumberRenderer) => {
  return props.displayIndex
}

export const getSNRenderer = (sn: IorvoSequenceNumber, columnsPayload?: any): IorvoGridColumn => {
  const renderer = (props: IorvoGridCellProps) => {
    const displayIndex: number = (props.groupIndex !== undefined ? props.groupIndex : props.index) + 1
    const Renderer = sn.renderer || SNRenderer

    return (
      // @ts-ignore
      <Renderer
        row={props.row}
        displayIndex={displayIndex}
        index={props.index}
        payload={columnsPayload}
      />
    )
  }

  return {
    _id: "sn",
    width: sn.width || 60,
    mask: false,
    renderer,
  }
}
