import React, { useCallback, useMemo } from 'react'
import { FormRendererProps } from '../types/FormRenderer'
import { Input } from '../../irv-ui'
import BtnWithIcon from '../../btn/BtnWithIcon'

const FormLinkRenderer: React.FC<FormRendererProps> = ({
  value,
  readonly,
  className,
  column,
  onCommit,
  onChange,
}) => {
  const href = /^http/.test(value) ? value : `http://${value}`
  const handleBlur = useCallback(
    e => {
      if (onCommit) {
        onCommit(e.target.value)
      }
    },
    [onCommit],
  )

  const link = useMemo(() => {
    if (!value) {
      return
    }

    const href = /^http/.test(value) ? value : `http://${value}`
    const component = (
      <a
        className="link"
        target="_blank"
        rel="noopener noreferrer"
        href={href}
        onClick={e => e.stopPropagation()}>
        {value}
      </a>
    )

    if (readonly) {
      return component
    }

    return <div className={'e-act'}>{component}</div>
  }, [value, readonly])

  if (readonly) {
    if (!link) {
      return null
    }

    return <div className={className}>{link}</div>
  }

  return (
    <>
      <Input
        id={column._id}
        className={className}
        value={value}
        onBlur={handleBlur}
        onChange={onChange}
      />

      {value && (
        <a
          className={'suffix'}
          title={value}
          href={href}
          target="_blank"
          rel="noopener noreferrer">
          <BtnWithIcon icon={'external'} />
        </a>
      )}
    </>
  )
}

export default FormLinkRenderer
