import React from 'react'
import {SortOrderType} from '../../../types/BaseView'
import {GridColumn} from "../../grid/types/GridColumn";
import {getUIColumnType, isDateColumn, isNumberColumn} from '../../../general/utils/column';
import './style.scss'
import {ColumnType} from "../../../general/types/ColumnType";

interface Props {
  order: SortOrderType
  column: GridColumn
  onChange: (order: SortOrderType) => void
}

const getLabels = (column: GridColumn): [string, string] => {
  if (isDateColumn(column) || isNumberColumn(column)) {
    return [
      '1 → 9',
      '9 → 1'
    ]
  }

  const uiType = getUIColumnType(column)

  if (uiType === ColumnType.CHECKBOX) {
    return [
      '☐ → ☑',
      '☑ → ☐'
    ]
  }

  return [
    'А → Я',
    'Я → А'
  ]
}

export default React.memo(function ColumnSortOrder(props: Props) {
  const onClick = () => {
    props.onChange(props.order === SortOrderType.DESC ? SortOrderType.ASC : SortOrderType.DESC)
  }

  const [asc, desc] = getLabels(props.column)

  return (
    <div className={`order-ctrl ${props.order}`} onClick={onClick}>
      <span className={'asc-label'}>{asc}</span>
      <span className={'desc-label'}>{desc}</span>
    </div>
  )
})