import React from 'react'
import { SimpleRendererProps } from '../types/SimpleRendererProps'

const BaseSimpleRenderer: React.FC<SimpleRendererProps> = ({
  value,
  className,
}) => {
  const isObj = value instanceof Object
  const displayValue = isObj ? null : value

  if (isObj) {
    console.warn(value, 'Is object...')
  }

  return <div className={className}>{displayValue}</div>
}

export default React.memo(BaseSimpleRenderer)
