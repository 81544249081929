import React, { useEffect, useState } from 'react'
import { Button, Input } from '../../components/irv-ui'
import Loading from '../loading/Loading'
import { GridRow } from '../grid/types/GridRow'
import { isArray } from '../../utils/is'
import { getViewValue } from '../../general/utils/value'
import LinkedEntriesModalContent from './LinkedEntriesModalContent'
import { LinkedEntriesModalProps } from './types'
import { asArray } from '../../general/utils/array'
import { Dialog } from '../irv-ui'

interface RowIndex {
  term: string
  id: string
}

const LinkedEntriesModal: React.FC<LinkedEntriesModalProps> = ({
  rows,
  visible,
  table,
  view,
  multiple,
  selected,
  onClose,
  onChange,
}) => {
  const isLoaded: boolean = !!rows && !!table
  const [filteredRows, setFilteredRows] = useState<GridRow[]>(rows || [])
  const [indexes, setIndexes] = useState<RowIndex[]>([])
  const [term, setTerm] = useState<string>('')
  const [selectedItems, setSelectedItems] = useState(selected || [])

  useEffect(() => {
    if (isLoaded) {
      const rowIndexes: RowIndex[] = rows.map(row => {
        const term = table.columns
          .map(column => {
            const value: any = getViewValue(table, column, row)

            if (value && value.value) {
              return value.value
            }

            if (isArray(value)) {
              return value.map(item => item.value || item).join(' ')
            }

            return value
          })
          .join(' ')

        return {
          term,
          id: row._id,
        }
      })

      setFilteredRows(rows)
      setIndexes(rowIndexes)
    }

    setTerm('')
  }, [isLoaded, rows, table])

  useEffect(() => {
    setSelectedItems([])
    setTerm('')
    setFilteredRows(rows)
  }, [visible, rows])

  useEffect(() => {
    setSelectedItems(selected)
  }, [selected])

  const handleChangeTerm = term => {
    setTerm(term)

    const filter = term.replace(/\s+/, ' ')
    const filteredRowsIds: string[] = indexes
      .filter(indexed => {
        return new RegExp(filter.trim().replace(/\s{2,}/g, ''), 'i').test(
          indexed.term,
        )
      })
      .map(index => index.id)

    setFilteredRows(rows.filter(r => filteredRowsIds.includes(r._id)))
  }

  const handleTouch = (rowIds: string[]) => {
    const ids: string[] = asArray(rowIds)

    if (multiple) {
      setSelectedItems(ids)
    } else {
      onChange(ids)
      onClose()
    }
  }

  const handleSubmit = () => {
    onChange(selectedItems)

    onClose()
  }

  const Content = visible ? (
    !isLoaded ? (
      <Loading />
    ) : (
      <LinkedEntriesModalContent
        rows={filteredRows}
        selected={selectedItems}
        view={view}
        table={table}
        showCheckbox={multiple}
        onTouch={handleTouch}
      />
    )
  ) : null

  const TitleComponent = (
    <Input
      fit
      value={term}
      placeholder="Поиск записи"
      onChange={handleChangeTerm}
    />
  )

  const FooterComponent = multiple ? (
    <>
      <Button variant={'primary'} onClick={handleSubmit}>
        Выбрать {selectedItems.length > 0 && `(${selectedItems.length})`}
      </Button>
      <Button onClick={onClose}>Отмена</Button>
    </>
  ) : null

  return (
    <Dialog
      header={TitleComponent}
      footer={FooterComponent}
      open={visible}
      onClose={onClose}
      alignSelf={'start'}
      maxHeight={'80%'}
      className={'linked-entries-modal'}
      width={750}>
      {Content}
    </Dialog>
  )
}

export default LinkedEntriesModal
