import React from 'react'
import { asArray } from '../../../utils/as'
import { Tag } from '../../../components/irv-ui'

interface Props {
  value: { _id: string; value?: string }[]
  onRemove?: (ars?: any) => void
}

const noName = 'Запись без имени'

const LinkToRecordTags: React.FC<Props> = ({ value, onRemove }) => {
  return (
    <>
      {asArray(value)
        .filter(Boolean)
        .map((rec: { _id: string; value: string }) => (
          <Tag
            key={rec._id}
            className={'eyzy-tag record'}
            title={rec.value || noName}
            onRemove={onRemove ? () => onRemove(rec._id) : null}>
            {rec.value}

            {!rec.value && (
              <span className="secondary-text">{noName}</span>
            )}
          </Tag>
        ))}
    </>
  )
}

export default React.memo(LinkToRecordTags)
