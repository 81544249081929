import React from "react";
import {asString} from "../../../utils/as";
import {FilterRendererProps} from "../../filter/FilterRendererFactory";
import DelayedInput from "../../controllers/DelayedInput";

export default React.memo(function BaseFilterRenderer(props: FilterRendererProps) {
  const {filter, onChange} = props;

  const handleChange = (value) => {
    onChange({value})
  }

  return (
    <DelayedInput
      fit
      value={asString(filter.value)}
      onChange={handleChange}
      delay={500}
    />
  )
})