import React from "react";
import Icon from "./Icon";
import NEWIcon from "./NEWIcon";

interface Props {
  onClick: () => void
  disabled?: boolean
  useTrash?: boolean
}

export default React.memo(function (props: Props) {
  return (
    <button className="remove" onClick={props.onClick} disabled={props.disabled} tabIndex={-1}>
      {props.useTrash ? (
        <Icon type={'delete'} />
      ): (
        <NEWIcon type={'cross-small'} />
      )}
    </button>
  )
})
