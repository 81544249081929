import React from "react"
import { ViewConfig } from "../../types/View"
import "../../NEW__styles/components/dayTimeline.scss"

const LINES: number = 24

interface Props {
  silence?: boolean
  config: ViewConfig
}

export default React.memo((props: Props) => {
  const config = props.config || {}
  const lines: HTMLDivElement[] = []
  const rowHeight: number = config.rowHeight
  const startHours = config.startHours || 0
  const endHours = config.endHours || LINES
  const style: any = rowHeight ? { height: rowHeight } : null

  for (let i = startHours; i < endHours; i++) {
    const el: any = (
      <div key={i} style={style}>
        {
          true !== props.silence
            ? (24 !== i && <span>{i < 10 ? `0${i}:00` : `${i}:00`}</span>)
            : ""
        }
      </div>
    )

    lines.push(el)
  }

  return (
    <div className="dt">
      <>
        {lines}
      </>
    </div>
  )
})
