import dayjs from 'dayjs'
import {between, effectiveHash} from '../../utils'
import {DateLike, Event} from '../../types/Event'
import {ProcessedEvent} from '../../types/Timeline'

export default class DayAndMonth {
  id = 'dayAndMonth'

  config = {
    eventWidth: 70,
    rowHeight: 50,
  }

  width: number

  _startMs: number
  _endMs: number

  startDate: DateLike
  endDate: DateLike

  // делать как и в неделе (рисуем роу и считается ТОЛЬКО сдвиг слева)
  constructor(startDate: DateLike, endDate: DateLike) {
    this.startDate = dayjs(startDate)
    this.endDate = dayjs(endDate)

    this.width = dayjs(this.startDate).endOf('month').date() * this.config.eventWidth

    this._startMs = +dayjs(this.startDate).startOf('month')
    this._endMs = +dayjs(this.endDate || this.startDate).endOf('month')
  }

  get height() {
    return this.config.rowHeight
  }

  get eventWidth() {
    return this.config.eventWidth
  }

  get columns() {
    let u = dayjs(this.startDate).endOf('month').date()

    return u
  }

  getEventDimentions = (event: Event, resourceEvents: Event[]) => {
    const startDate = dayjs(event.start).startOf('day')
    const endDate = dayjs(event.end || event.start).endOf('day')

    const start = +startDate
    const end = +endDate
    const diff = Math.abs(startDate.diff(endDate, 'day')) + 1

    const totalMs = this._endMs - this._startMs
    const x = this.width * ((start - this._startMs) / totalMs)
    const width = this.width * (diff / this.columns)

    const eventIndex: number = resourceEvents.findIndex(e => e.id === event.id)

    let y: number = 0

    resourceEvents.forEach((rEvent, i) => {
      if (rEvent.id === event.id || i > eventIndex) {
        return
      }

      const testEventStart = +dayjs(rEvent.start).startOf('day')
      const testEventEnd = +dayjs(rEvent.end).endOf('day')

      if (testEventEnd < start) {
        return
      }

      if (between([start, end], testEventStart) || between([start, end], testEventEnd)) {
        y += this.height
      }
    })

    return {
      x, 
      y,
      width
    }
  }

  calculateHeight = (events: ProcessedEvent[]): number => {
    if (events.length <= 1) {
      return this.height
    }

    const dateIntervals: Array<number[]> = events
      .map(e => {
        const date0 = effectiveHash(dayjs(e.event.start).startOf('day'))
        const date1 = effectiveHash(dayjs(e.event.end || e.event.start).startOf('day'))

        return [date0, date1].sort((d0, d1) => d0 - d1)
      })
      .sort((d0, d1) => d0[0] - d1[0])

    const groups: Array<{ interval: number[], amount: number }> = []

    dateIntervals.forEach((di) => {
      if (!groups.length) {
        return groups.push({
          interval: dateIntervals[0],
          amount: 1
        })
      }

      groups.forEach((g, j) => {
        if (between(g.interval, di[0]) || between(g.interval, di[1])) {
          g.amount += 1
          g.interval = [Math.min(g.interval[0], di[0]), Math.max(g.interval[1], di[1])]
        } else {
          groups.push({
            amount: 1,
            interval: di
          })
        }
      })
    })

    groups.sort((g0, g1) => g1.amount - g0.amount)

    return groups[0].amount * this.height
  }
  
  buildHeaders = () => {
    const from = dayjs(this.startDate)
    const to = dayjs(this.endDate)

    const diffDays = Math.abs(from.diff(to, 'day'))

    const monthHeaders = []
    const daysHeaders = []

    for (let i = 0; i <= diffDays; i++) {
      const d = from.add(i, 'day')

      daysHeaders.push({
        label: d.format('DD'),
        width: this.eventWidth,
        showWeekends: true
      })

      monthHeaders.push({
        label: d.format('dd'),
        width: this.eventWidth,
        showWeekends: true
      })
    }

    return [
      monthHeaders,
      daysHeaders
    ]
  }

  isMatched = (event: Event): boolean => {
    const startEndInterval = [+this.startDate, +this.endDate]

    if (between(startEndInterval, +dayjs(event.start))) {
      return true
    }

    if (event.end && between(startEndInterval, +dayjs(event.end))) {
      return true
    }

    return false
  }

  isWeekend = (index: number): boolean => {
    const day = dayjs(this.startDate).add(index, 'day').day()

    return [0, 6].includes(day)
  }
}