import { BaseTable } from '../types/BaseTable'
import { BaseView, ViewLike } from '../types/BaseView'
import { GridColumn } from '../components/grid/types/GridColumn'
import { getColumnById, getColumnParamsItem } from './get'
import { ColumnType } from '../general/types/ColumnType'

export function plFields(amount: number, lym?: boolean): string {
  if (amount === 1) {
    return '1 пол' + (lym ? 'е' : 'ю')
  }

  if (lym) {
    return amount + ' пол' + (amount > 4 ? 'ей' : 'я')
  }

  return amount + ' полям'
}

export function getColumnsNamesForHeader(
  table: BaseTable,
  columnIds: string[],
): string {
  const columnNames = columnIds
    .map(id => getColumnById(table, id))
    .filter(Boolean)
    .map(c => c.name)

  if (columnNames.length > 2) {
    return columnNames[0] + ` +${columnNames.length - 1}`
  }

  return columnNames.join(', ')
}

export function prepareColumns(
  table: BaseTable,
  view?: BaseView | ViewLike,
): GridColumn[] {
  if (!view) {
    return table.columns
  }

  return orderColumns(table.columns, view.order).filter(col =>
    isVisibleColumn(col, view),
  )
}

export function orderColumns(
  columns: any[],
  order?: { [key: string]: number },
): any[] {
  if (!order) {
    return columns
  }

  try {
    const columnsMap = columns.reduce((map, column) => {
      map[column._id] = column
      return map
    }, {})

    const orderPositions = Object.values(order)
    const result = Array(columns.length)
    const restIndexes: number[] = []

    for (let columnId in order) {
      result[order[columnId]] = columnsMap[columnId]
    }

    for (let i = 0; i < columns.length; i++) {
      if (!orderPositions.includes(i)) {
        restIndexes.push(i)
      }
    }

    columns.forEach(column => {
      if (column._id in order) {
        return
      }

      const nextIndex = restIndexes.shift()

      result[undefined === nextIndex ? columns.length - 1 : nextIndex] = column
    })

    return result.filter(Boolean)
  } catch (e) {
    console.error(e)
    return columns
  }
}

export function isVisibleColumn(
  column: GridColumn,
  view?: BaseView | ViewLike,
): boolean {
  if (!view) {
    return true
  }

  return getColumnParamsItem(view, column._id, 'hidden') !== true
}

const isColumnTypeFactory = (types: any[]) => {
  return (column: GridColumn): boolean => {
    if (types.includes(column.type)) {
      return true
    }

    const foreignType = column.params && column.params.foreignType

    return column.type === ColumnType.LOOKUP && types.includes(foreignType)
  }
}

export const isDateColumnType = isColumnTypeFactory([
  ColumnType.DATE,
  ColumnType.CREATION_DATE,
  ColumnType.MODIFIED_ON,
])

export function isDateColumn(table: BaseTable, colId: string): boolean {
  const column: GridColumn | undefined = this.table.columns.find(
    c => c._id === colId,
  )

  if (!column) {
    return false
  }

  return isDateColumnType(column)
}

export const NON_FLIGHT_TYPES: Array<string> = [
  ColumnType.LOOKUP,
  ColumnType.LINK_TO_RECORD,
  ColumnType.RECORD_INDEX,
]

const NOT_ALLOWED_MIRROR_TYPES = [
  ColumnType.MIRROR,
]

export const isAllowedForMirror = (column: GridColumn): boolean => {
  return !NOT_ALLOWED_MIRROR_TYPES.includes(column.type)
}

const NOT_ALLOWED_ADDON_TYPES = [
  ColumnType.LOOKUP,
  ColumnType.MODIFIED_BY,
  ColumnType.MODIFIED_ON,
  ColumnType.ROLLUP,
  ColumnType.COUNT,
  ColumnType.RECORD_INDEX,
  ColumnType.ACTION,
]

export const isAllowedForAddon = (column: GridColumn): boolean => {
  return !NOT_ALLOWED_ADDON_TYPES.includes(column.type)
}
