import React, { ReactElement, useState } from 'react'
import { IrvCollapseProps } from './types/Collapse'

import './styles.scss'

const IrvCollapse: React.FC<IrvCollapseProps> = ({ children, defaultOpen }) => {
  const [openPanels, setOpen] = useState<string[]>(defaultOpen || [])

  const createOnToggle = (key: string) => (state: boolean) => {
    if (state) {
      setOpen(openPanels.filter(openKey => key !== openKey))
    } else {
      setOpen(openPanels.concat(key))
    }
  }

  const elements = React.Children.map(children, (child, i) => {
    return React.cloneElement(child as ReactElement, {
      open: openPanels.includes(child.key),
      onToggle: createOnToggle(child.key),
    })
  })

  return <div className={'irv-collapse'}>{elements}</div>
}

export default IrvCollapse
