import { GridRow } from '../components/grid/types/GridRow'
import { ViewDateField } from '../types/BaseView'
import { BaseTable } from '../types/BaseTable'
import { getViewValue } from '../general/utils/value'
import { getRowValue } from './grid'
import { dayjs } from '../general/utils/date'
import { getAllowedColumnForLink } from './get'
import { LabeledValue } from '../types/Common'
import { translateRepeat } from './translations'
import {
  Event as CalendarEventType,
  Event,
  EventFrequency,
} from '../types/Calendar'
import get from 'lodash/get'
import { RRule, Frequency } from 'rrule'
import { EventDate } from '../components/calendar/NEW__types/Event'
import { ViewType } from '../components/calendar/NEW__types/Calendar'

export enum RangeType {
  START_DATE,
  START_TIME,
  END_DATE,
  END_TIME,
}

export function changeRangeDate(
  type: RangeType,
  date: Date,
  dates: EventDate,
): EventDate {
  const start = dayjs(dates.start)
  const end = dayjs(dates.end)
  const hasEnd: boolean = !!dates.end
  const result: any = {}

  switch (type) {
    case RangeType.START_DATE: {
      result.start = start
        .date(date.getDate())
        .month(date.getMonth())
        .year(date.getFullYear())
        .toDate()

      if (hasEnd) {
        result.end = end
          .date(date.getDate())
          .month(date.getMonth())
          .year(date.getFullYear())
          .toDate()
      }

      break
    }

    case RangeType.START_TIME: {
      const resultStart = start.hour(date.getHours()).minute(date.getMinutes())

      result.start = resultStart.toDate()

      if (hasEnd) {
        result.end = end
          .add(resultStart.diff(start, 'minute'), 'minute')
          .toDate()
      }

      break
    }

    case RangeType.END_DATE: {
      result.start = start
        .date(date.getDate())
        .month(date.getMonth())
        .year(date.getFullYear())
        .toDate()

      result.end = end
        .date(date.getDate())
        .month(date.getMonth())
        .year(date.getFullYear())
        .toDate()

      break
    }

    case RangeType.END_TIME: {
      result.end = end.hour(date.getHours()).minute(date.getMinutes()).toDate()

      break
    }
  }

  return result
}

export function getRepeatOptions(): LabeledValue[] {
  const freq: any[] = [
    EventFrequency.NONE,
    EventFrequency.DAILY,
    EventFrequency.WEEKLY,
    // EventFrequency.MONTHLY,
    // EventFrequency.YEARLY,
    EventFrequency.CUSTOM,
  ]

  return freq.map(f => ({
    value: f,
    label: translateRepeat(f),
  }))
}

export function lightEventToRow(
  event: Event,
  dateField: ViewDateField,
): GridRow {
  const result = eventToRow(event, dateField)

  for (let key in result) {
    if (undefined === result[key]) {
      delete result[key]
    }
  }

  return result
}

export function eventToRow(event: Event, dateField: ViewDateField): GridRow {
  const { start, end, title, settings } = dateField

  const mainColumn = title

  if (!start || !mainColumn) {
    return
  }

  const row: any = {
    [mainColumn._id]: event.title,
    [start._id]: event.start,
  }

  if (event.end) {
    row[end._id] = event.end
  }

  if (settings) {
    if (event.rrule) {
      try {
        const ruleString = (
          event.rrule.options ? event.rrule : new RRule(event.rrule)
        ).toString()
        const rrule = ruleString.match(/RRULE:([^$\n]+)/)[1]

        if (rrule) {
          row[settings._id] = { rrule }
        }
      } catch (e) {
        row[settings._id] = {}
        console.log(e)
      }
      // TODO: тут нужно делать налл и оно на сервере должно удаляться
    } else if (event.rrule === null) {
      row[settings._id] = {}
    }
  }

  if (event.id && '-1' !== event.id) {
    row._id = event.id
  }

  return row
}

export function rowToEvent(
  row: GridRow,
  dateField: ViewDateField,
  table: BaseTable,
  payload: any,
): Event | undefined {
  const { start, end, title, settings } = dateField

  const mainColumn = title || getAllowedColumnForLink(table)

  if (!start || !mainColumn) {
    return
  }

  const viewValue = getViewValue(table, mainColumn, row)
  const rowValue = getRowValue(row, start, table)
  const date = dayjs(rowValue)

  let dateEnd: any

  if (end) {
    const rowEndValue = getRowValue(row, end, table)
    dateEnd = dayjs(rowEndValue)
  }

  if (!rowValue || !date.isValid() || (dateEnd && !dateEnd.isValid())) {
    // eslint-disable-next-line
    return
  }

  const eventTitle = viewValue || ''
  const event: Event = {
    start: date.toDate(),
    end: dateEnd && dateEnd.toDate(),
    title: eventTitle,
    id: row._id,
  }

  if (payload) {
    event.payload = payload
  }

  if (settings) {
    const rRule = get(getRowValue(row, settings), 'rrule')

    if (rRule) {
      event.rrule = rRule
    }
  }

  return event
}

export function isNewEvent(event: Event): boolean {
  return !event || !event.id || /-1/.test(event.id)
}

export function getRepeatedEvents(
  event: Event,
  intervals: [Date, Date],
): Event[] {
  if (!event.rrule) {
    return [event]
  }

  try {
    const diffMinutes: number = dayjs(event.end).diff(event.start, 'minute')

    // @ts-ignore
    const dtStart = dayjs(event.start).utc().format('YYYYMMDD[T]HHmmss[Z]')
    const ruleString: string = 'DTSTART:' + dtStart + ';\nRRULE:' + event.rrule
    const rule = RRule.fromString(ruleString)
    const set = rule.between(intervals[0], intervals[1])

    return set
      .filter(s => +s >= +event.start)
      .map((s, i) => {
        const e = {
          ...event,
          id: `${event.id}.${i}`,
          rrule: rule,
          start: dayjs(s),
        }

        if (event.end) {
          e.end = e.start.add(diffMinutes, 'minutes')
        }

        return e
      })
  } catch (e) {
    return [event]
  }
}

export function getRepeatFrequency(event: CalendarEventType): EventFrequency {
  if (!event.rrule || !event.rrule.options) {
    return EventFrequency.NONE
  }

  switch (event.rrule.options.freq) {
    case Frequency.YEARLY:
    case Frequency.MONTHLY:
      return EventFrequency.NONE

    case Frequency.WEEKLY: {
      if (event.rrule.options.byweekday.length === 1) {
        return EventFrequency.WEEKLY
      }
    }
  }

  return EventFrequency.CUSTOM

  // if (repeat.iteration) {
  //   return repeat.iteration[0]
  // }
  //
  // if (repeat.frequency) {
  //   return CalendarEventFrequency.CUSTOM
  // }
  //
  // return CalendarEventFrequency.NONE
}

export function getToolbarDates(
  viewType: ViewType,
  dates: dayjs.Dayjs[],
): string {
  switch (viewType) {
    case ViewType.WEEK:
      return dates.map(d => d.format('D MMM')).join(' - ')
  }

  return ''
}
