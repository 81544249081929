import React from "react"
import { debounce } from "../../../utils/common"
import { asString } from "../../../utils/as"
import { Input } from "../../../components/irv-ui"
import { BaseRendererProps } from "../BaseRendererProps"

export default class FormTextRenderer extends React.Component<BaseRendererProps<any>> {
  commitChanges: (value: any) => void = debounce((value: any) => {
    this.props.onChange(value)
  }, 400)

  handleChange = e => {
    this.commitChanges(e.target.value)
  }

  render() {
    const { readonly, autoFocus, value, placeholder } = this.props

    if (readonly) {
      return <div className={"re-value"}>{asString(value)}</div>
    }

    return (
      <Input
        fit
        placeholder={placeholder}
        readOnly={readonly}
        defaultValue={asString(value)}
        onChange={this.handleChange}
        autoFocus={autoFocus}
      />
    )
  }
}
