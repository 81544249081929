import React, { useCallback, useMemo, useState } from 'react'
import { SettingsItemComponentProps } from '../types'
import { Input, Select } from '../../../irv-ui'
import {
  GridColumnLabelType,
  GridColumnTagType,
} from '../../../grid/types/GridColumn'

export default React.memo((props: SettingsItemComponentProps) => {
  const { params, onAction } = props
  const [divideBy, setDivideBy] = useState(params.divideBy || 100)

  const handleSaveDivideBy = useCallback(() => {
    onAction({
      divideBy,
    })
  }, [onAction, divideBy])

  const handleSelectLabelType = useCallback(
    (labelType: string) => {
      onAction({ labelType })
    },
    [onAction],
  )

  const handleSelectViewAs = useCallback(
    (viewAs: string) => {
      onAction({ viewAs })
    },
    [onAction],
  )

  const viewAsOptions = useMemo(() => {
    return [
      { value: GridColumnTagType.TEXT, text: 'Текст' },
      { value: GridColumnTagType.TAG, text: 'Тэг' },
      { value: GridColumnTagType.BAR, text: 'Прогресс' },
    ]
  }, [])

  const labelAsItems = useMemo(() => {
    return [
      { value: GridColumnLabelType.VALUE, text: 'Значение' },
      { value: GridColumnLabelType.DIFF, text: 'Разница' },
      { value: GridColumnLabelType.OP, text: 'Операция' },
      { value: GridColumnLabelType.OP_REVERSE, text: 'Зворотня Операция' },
      { value: GridColumnLabelType.PERCENT, text: 'Процент' },
    ]
  }, [])

  const showDivideBy: boolean = ['bar'].includes(params.viewAs)

  return (
    <>
      <div className={'column-settings-crow'}>
        <div className="column-settings-cell">
          <div className={'column-settings-label'}>Отображать как</div>
          <div className={'column-settings-ctrl'}>
            <Select
              defaultValue={params.viewAs}
              items={viewAsOptions}
              onSelect={handleSelectViewAs}
              fit
              placeholder={'Отображать как'}
            />
          </div>
        </div>
      </div>
      {showDivideBy && (
        <div className={'column-settings-crow'}>
          <div className="column-settings-cell">
            <div className={'column-settings-label'}>Разделить на</div>
            <div className={'column-settings-ctrl'}>
              <Input
                fit
                onChange={divideBy => setDivideBy(divideBy)}
                onBlur={handleSaveDivideBy}
                value={divideBy}
              />
            </div>
          </div>
        </div>
      )}
      {showDivideBy && (
        <div className={'column-settings-crow'}>
          <div className="column-settings-cell">
            <div className={'column-settings-label'}>Тип значения</div>
            <div className={'column-settings-ctrl'}>
              <Select
                defaultValue={params.labelType}
                items={labelAsItems}
                onSelect={handleSelectLabelType}
                fit
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
})
