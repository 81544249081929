import React, { useState } from 'react'
import Avatar from '../../user/Avatar'
import { EyzyUser } from '../../../types/EyzyUser'
import Tooltip from 'antd/lib/tooltip'
import { getRoleName, Roles } from '../../roles/Roles'
import RemoveBtn from '../../icon/RemoveBtn'
import { ActionType } from '../../../store/CommonEffects'
import { WorkspaceActions } from '../../../store/actions/WorkspaceActions'
import { isRoleAllowed, UIRoleAction } from '../../roles/RoleGateway'
import ColorPicker from '../../color/ColorPicker'
import { Select } from '../../irv-ui'

export const ROLES_OPTIONS = [
  {
    name: getRoleName(Roles.OWNER),
    value: Roles.OWNER,
    descr: 'Полный доступ к управлению пространства',
  },
  {
    name: getRoleName(Roles.DEPUTY),
    value: Roles.DEPUTY,
    descr:
      'Может полностью настраивать и редактировать базы. Так же управляет доступами',
  },
  {
    name: getRoleName(Roles.ADMIN),
    value: Roles.ADMIN,
    descr: 'Может полностью настраивать и редактировать базы',
  },
  {
    name: getRoleName(Roles.EDITOR),
    value: Roles.EDITOR,
    descr:
      'Может редактировать записи и представления, но не может настраивать таблицы или поля',
  },
  {
    name: getRoleName(Roles.MEMBER),
    value: Roles.MEMBER,
    descr: 'Может редактировать записи',
  },
  {
    name: getRoleName(Roles.READ_ONLY),
    value: Roles.READ_ONLY,
    descr: 'Не может редактировать',
  },
]

interface Props {
  user: EyzyUser
  editorRole: Roles
  onUpdate: (type: ActionType, payload: any) => any
}

export default React.memo(function Collaborator(props: Props) {
  const { user, editorRole, onUpdate } = props
  const [role, setRole] = useState(user.params.role)
  const [loading, setLoading] = useState(false)
  const canEditRole: boolean = editorRole >= role

  const handleRoleChange = level => {
    setLoading(true)
    setRole(level)

    onUpdate(WorkspaceActions.UPDATE_ROLE, {
      userId: user._id,
      level,
    })
  }

  const handleRemoveCollaborator = () => {
    setLoading(true)

    onUpdate(WorkspaceActions.REMOVE_COLLABORATOR, {
      userId: user._id,
    })
  }

  const handleColorChange = type => {
    onUpdate(WorkspaceActions.UPDATE, {
      color: {
        user: user._id,
        type,
      },
    })
  }

  return (
    <div className="collaborator">
      <Avatar user={user} />
      <div className="collaborator-name">
        <span className="name">{user.fullName}</span>
        <span className="email">{user.email}</span>
      </div>

      <ColorPicker color={user.params.color} onSelect={handleColorChange} />

      {canEditRole && (
        <>
          <Select
            dropdownClassName="collaborator-role"
            width={170}
            className="collaborator-role"
            items={ROLES_OPTIONS}
            value={role}
            theme={'white'}
            placement={'bottom right'}
            valueRenderer={({ item: { name } }) => name}
            dropdownWidth={550}
            onSelect={handleRoleChange}>
            {(item, arrow) => (
              <div className={'irv-bitem'}>
                <div className="name">{item.name}</div>
                <div className="descr">{item.descr}</div>

                {arrow}
              </div>
            )}
          </Select>

          {isRoleAllowed(
            UIRoleAction.WORKSPACE_REMOVE_COLLABORATOR,
            editorRole,
          ) ? (
            <RemoveBtn disabled={loading} onClick={handleRemoveCollaborator} />
          ) : (
            <span className={'stub'} />
          )}
        </>
      )}

      {!canEditRole && (
        <Tooltip placement="left" title={'У вас нет прав для редактирования'}>
          <span className="collaborator-role">{getRoleName(role)}</span>
        </Tooltip>
      )}
    </div>
  )
})
