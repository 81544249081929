import React, { useCallback } from 'react'
import Cell from './Cell'
import { IorvoRowProps } from '../types/IorvoGridRow'
import { IorvoGridColumn } from '../types/IorvoGridColumn'
import { areEqual } from 'react-window'
import GFake from '../components/GFake'

const Row = ({
  row,
  rowIndex,
  mask,
  style,
  columns,
  hovered,
  freeze,
  freezeWidth,
  columnPayload,
  className,
  onHover,
  depth,
}: IorvoRowProps) => {
  const handleHover = useCallback(() => {
    onHover(row._id)
  }, [onHover, row])

  const isActiveRow: boolean = !!mask && mask.r === rowIndex[1]

  let cn = 'irv-row'

  if (hovered) {
    cn += ' irv-row--hover'
  }

  if (isActiveRow) {
    cn += ' irv-row--active'
  }

  // Последняя запись в группе
  if (rowIndex[3]) {
    cn += ' irv-grlast'
  }

  if (className) {
    cn += ` ${className}`
  }

  const freezedColumns: IorvoGridColumn[] =
    freeze > 0 ? columns.slice(0, freeze) : []

  const restColumns: IorvoGridColumn[] = freeze
    ? columns.slice(freeze)
    : columns

  return (
    <div className={cn} style={style} onMouseEnter={handleHover}>
      {freezeWidth ? (
        <div
          className={'irv-frz'}
          style={{ width: freezeWidth }}
          data-r={rowIndex[1]}>
          <GFake depth={depth || 0} />

          {freezedColumns.map((column: IorvoGridColumn, i: number) => (
            <Cell
              key={i}
              row={row}
              rowIndex={rowIndex}
              index={i}
              column={column}
              columnPayload={columnPayload || {}}
              active={mask && isActiveRow && mask.c === i}
            />
          ))}
        </div>
      ) : (
        <GFake depth={depth || 0} />
      )}

      <div className={'irv-rwrap'} data-r={rowIndex[1]}>
        {restColumns.map((column: IorvoGridColumn, i: number) => (
          <Cell
            key={i}
            row={row}
            rowIndex={rowIndex}
            index={i + freeze}
            column={column}
            columnPayload={columnPayload || {}}
            active={mask && mask.r === rowIndex[1] && mask.c === i + freeze}
          />
        ))}
      </div>

      <GFake depth={depth || 0} isRight />
    </div>
  )
}

export default React.memo(Row, areEqual)
