import React from 'react'
import { TimelineColumn } from '../../types/Timeline'

interface Props {
  columns: TimelineColumn[]
  width: number
}

export default React.memo(function HeaderColumns(props: Props) {
  return (
    <div className='tl-header-columns' style={{width: props.width}}>
      { props.columns.map(c => (
        <div className='tl-column' style={{width: c.width}} key={c.title}>{c.title}</div>
      ))}
      <div className='spl' />
    </div>
  )
})