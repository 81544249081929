import React, { useRef } from 'react'
import {
  GridColumnLabelType,
  GridColumnTagType,
} from '../../grid/types/GridColumn'
import { getFormulaValueLabel } from '../../../utils/formula'
import { ProgressBar, Tag, TextArea } from '../../irv-ui'

interface Props {
  displayValue: string | number
  viewAs?: GridColumnTagType
  labelType?: GridColumnLabelType
  divideBy?: any
  asText?: boolean
}

const hasSpecials = (text: any): boolean => {
  return /!__LB__!/.test(text)
}

const FormulaValueRenderer: React.FC<Props> = ({
  displayValue,
  viewAs,
  labelType,
  divideBy,
  asText,
}) => {
  const ref = useRef()

  if (!displayValue || displayValue === '#VALUE!') {
    return
  }

  const value = hasSpecials(displayValue)
    ? (displayValue as string).replace(/!__LB__!/g, '\n')
    : displayValue

  if (viewAs === GridColumnTagType.TAG) {
    return <Tag>{value}</Tag>
  }

  if (viewAs === GridColumnTagType.BAR && divideBy) {
    const display = getFormulaValueLabel(value, divideBy, labelType)

    if (!display) {
      return
    }

    return <ProgressBar value={display.percent} label={display.label} />
  }

  if (hasSpecials(value) && !asText) {
    const text = (value as string).replace(/!__LB__!/g, '\n')

    return (
      <TextArea ref={ref} className={'fvt'} readOnly autoHeight>
        {text}
      </TextArea>
    )
  }

  // @ts-ignore
  return <span style={asText ? null : {whiteSpace: 'pre'}} dangerouslySetInnerHTML={{ __html: value }} />
}

export default React.memo(FormulaValueRenderer)
