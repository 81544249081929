import React, { useState } from "react"
import { Tag } from "../../../components/irv-ui"
import { removeItem } from "../../../utils/array"
import Icon from "../../icon/Icon"
import { isEmpty } from "../../../utils/is"
import { LinkedRow } from "../../../types/Row"
import { BaseRendererProps } from "../BaseRendererProps"
import LinkedRecordsModal from "../../linkedRecord/LinkedRecordModal"
import { asArray } from "../../../utils/as"

function renderTags(records: any[], onRemove?: (id: string) => void) {
  return (
    <>
      {asArray(records).filter(Boolean).map((rec: { _id: string, value: string }) => (
        <Tag key={rec._id} onRemove={onRemove ? onRemove(rec._id) : null} className={"eyzy-tag record"}>
          {rec.value}

          {!rec.value && (
            <span className="secondary-text">Запись без имени</span>
          )}
        </Tag>
      ))}
    </>
  )
}

export default function BaseLinkToRecordRenderer(props: BaseRendererProps<any[]>) {
  const [isModalVisible, setVisible] = useState(false)
  const { readonly, onChange, params } = props
  const value = props.value || []

  if (!readonly) {
    const onRemove = (id: string) => () => {
      const updatedRecords: LinkedRow[] = removeItem(value, (rec: LinkedRow) => rec._id === id)
      onChange!(updatedRecords)
    }

    const isMultiple: boolean = true === params.multiple
    const isAddBtnVisible: boolean = isMultiple
      ? true
      : (!value.length)

    return (
      <>
        <div className="grid-link-record truncate" onDoubleClick={() => isAddBtnVisible && setVisible(true)}>
          {isAddBtnVisible && (
            <button className="add-record" onClick={() => setVisible(true)}><Icon type={"plus"} /></button>
          )}

          {renderTags(value, onRemove)}
        </div>

        {isModalVisible && (
          <LinkedRecordsModal
            tableId={params.tableId}
            columnId={params.columnId}
            container={document.querySelector(".grid-link-record")}
            visible={isModalVisible}
            selectedRows={value}
            multiple={isMultiple}
            onClose={() => setVisible(false)}
            onSelect={(linkedRow: LinkedRow[]) => {
              setVisible(false)
              onChange(asArray(linkedRow))
            }}
          />
        )}
      </>
    )
  }

  if (isEmpty(value)) {
    return null
  }

  return (
    <span className="grid-cell-value">
      {renderTags(value)}
    </span>
  )
}
