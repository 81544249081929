export enum KEY_CODES {
  ENTER = 'Enter',
  ARROW_UP = 'ArrowUp',
  ARROW_DOWN = 'ArrowDown',
  ESCAPE = 'Escape'
}

export const isKeyPressed = (targetKey: string, codes: KEY_CODES[]): boolean => {
  return codes.includes(targetKey as KEY_CODES)
}
