import React from 'react'
import { SimpleRendererProps } from '../types/SimpleRendererProps'
import { EyzyUser } from '../../../types/EyzyUser'
import { asArray } from '../../../utils/as'
import { arrToObj } from '../../../utils/array'
import UserBadge from '../../__renderers/user/UserBadge'
import { useFormPayload } from '../RendererContext'

const UserRenderer: React.FC<SimpleRendererProps> = ({ value, className }) => {
  const context = useFormPayload()
  const usersMap = arrToObj(context.users || [], '_id', false)

  return (
    <div className={className}>
      {asArray(value)
        .map(id => usersMap[id])
        .filter(Boolean)
        .map((user: EyzyUser) => (
          <UserBadge key={user._id} user={user} />
        ))}
    </div>
  )
}

export default React.memo(UserRenderer)
