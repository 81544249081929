import React from 'react'
import {GridRendererProps} from "../GridRendererProps";
import BaseLinkToRecordRenderer from "./BaseLinkToRecordRenderer";

export default React.memo(function GridLinkToRecordRenderer(props: GridRendererProps) {
  const {onCommit, row, isActive, column} = props
  const value = row[column._id]
  const params = {
    ...column.params,
    tableId: column.table._id,
    columnId: column._id
  }

  return (
    <BaseLinkToRecordRenderer
      onChange={items => onCommit(items)}
      value={value || []}
      readonly={!isActive}
      params={params}
    />
  )
})
