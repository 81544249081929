import React from 'react'
import {Button} from '../../../../components/irv-ui'
import {DynamicFilterItem} from "../../../../components/filter/types/Filter";
import {BaseTable} from "../../../../types/BaseTable";
import {GridColumn} from "../../../../components/grid/types/GridColumn";
import {getColumnById, getDynamicFilterValue} from "../../../../utils/get";
import DatePicker from "react-datepicker";
import {dayjs, FILTER_FORMAT} from '../../../../general/utils/date'

interface Props {
  filter: DynamicFilterItem
  table: BaseTable
  onChange: (filterId: string, date: any) => void
}

export default React.memo(function (props: Props) {
  const column: GridColumn | undefined = getColumnById(props.table, props.filter.col)

  if (!column) {
    return null
  }

  const onChange = (date) => {
    props.onChange(props.filter._id, dayjs(date).format(FILTER_FORMAT))
  }

  return (
    <div className={'df-item'}>
      <DatePicker
        selected={dayjs(props.filter.value).toDate()}
        showMonthYearPicker
        showPopperArrow={false}
        showFullMonthYearPicker
        onChange={onChange}
        locale="uk"
        customInput={
          <Button>
            <span className={'df-label'}>{column.name}: </span>
            <span className={'df-value'}>{getDynamicFilterValue(props.filter)}</span>
          </Button>
        }
      />
    </div>
  )
})
