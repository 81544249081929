import React from 'react'
import {Addon} from "../../types/Addon";
import {GridRow} from "../grid/types/GridRow";
import Collapse from 'antd/lib/collapse';
import './styles/timeline.scss';
import Loading from "../loading/Loading";
import {fetchItem} from "../../store/CommonEffects";
import store from "../../store";
import {getView} from "../../utils/get";
import {BaseView} from "../../types/BaseView";
import {BaseTable} from "../../types/BaseTable";
import {isEmpty} from "../../utils/is";
import {AddonActions} from "../../store/actions/AddonActions";
import {EyzyUser} from "../../types/EyzyUser";

interface ParamsItem {
  open: boolean
  loaded: boolean
  rows: GridRow[]
  view?: BaseView
  table?: BaseTable
}

interface Props {
  widgets: Addon[]
  user: EyzyUser
}

interface State {
  params: { [key: string]: ParamsItem }
}

export default class WidgetTimelineContainer extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const params = {}

    props.widgets.forEach((widget: Addon) => {
      params[widget._id] = {
        open: !!localStorage.getItem(widget._id),
        loaded: false,
        rows: []
      }
    })

    this.state = {
      params
    }
  }

  handleChange = (e: any) => {
    const addons = Array.from(this.props.widgets.values())
    const params = {...this.state.params}

    addons.forEach(({_id}) => {
      const open = e.includes(_id)

      if (open) {
        localStorage.setItem(_id, '1')
      } else {
        localStorage.removeItem(_id)
      }

      params[_id] = {
        ...params[_id],
        open
      }
    })

    this.setState({params})
  }

  loadItems = (widget: Addon) => {
    const {viewId, tableId} = widget.params

    fetchItem(AddonActions.READ_TABLE, tableId, {viewId})(store.dispatch).then(({table, rows}) => {
      const view = getView(table, viewId)

      if (view) {
        const params = {...this.state.params}

        for (let i in params) {
          if (i === widget._id) {
            params[i] = {
              ...params[i],
              loaded: true,
              rows,
              view,
              table
            }
          }
        }
        console.log(params)
        this.setState({params})
      } else {
        throw new Error("Должно быть указано представление")
      }
    })
  }

  renderItem = (widget: Addon) => {
    const params = this.state.params[widget._id]!
    const activeKey = params.open ? [widget._id] : undefined

    if (params.open && !params.loaded) {
      this.loadItems(widget)
    }

    return (
      <Collapse key={widget._id} ghost={true} activeKey={activeKey} onChange={this.handleChange}>
        <Collapse.Panel key={widget._id} header={widget.name}>
          {!params.loaded && (
            <Loading width={17}/>
          )}

          {params.loaded && this.renderContent(params)}

          {params.loaded && isEmpty(params.rows) && (
            <p className={'no-data'}>Нет данных</p>
          )}
        </Collapse.Panel>
      </Collapse>
    )
  }

  renderContent = (params: ParamsItem) => {
    // const view: BaseView = params.view!
    // const table: BaseTable = params.table!
    // const rows = params.rows

    return null;

    // return (
    //   // <TimelineView
    //   //   view={view}
    //   //   rows={rows}
    //   //   table={table}
    //   //   user={this.props.user}
    //   //   userRole={2}
    //   // />
    // )
  }

  render() {
    return (
      <div className={'timeline-container'}>
        {this.props.widgets.map(this.renderItem)}
      </div>
    )
  }
}
