import React from "react"
import { createRoot } from "react-dom/client"
import App from "./App"
import * as Sentry from "@sentry/browser"
import { Integrations } from "@sentry/tracing"

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://b45bf1bb9ca74e97b510a2b2c1445d80@o1112122.ingest.sentry.io/6141527",

    // Alternatively, use `process.env.npm_package_version` for a dynamic release version
    // if your build tool supports it.
    release: "iorvo@1.0.1",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

const container = document.getElementById("root")
const root = createRoot(container)

root.render(<App />)

try {
  document.body.removeChild(document.querySelector(".spl"))
} catch (e) {
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
