import React from 'react'
import './savingIndicator.scss'
import Icon from "../icon/Icon";

export default function SavingIndicator(props: { visible: boolean }) {
  if (!props.visible) {
    return null
  }

  return (
    <span className="saving">
      Сохранение...
      <Icon type={"access-point"} />
    </span>
  )
}