import React, { FunctionComponent, useCallback, useMemo } from 'react'
import { areEqual } from 'react-window'
import {
  GroupHeaderRow,
  IorvoGroupHeaderProps,
} from '../../types/IorvoGridProps'
import { IorvoGridColumn } from '../../types/IorvoGridColumn'
import GFake from '../../components/GFake'
import GroupHeaderCell from './HeaderCell'
import { getGroupLevel } from '../../utils'
import { GROUP_FAKE } from '../../Grid'

interface Props {
  originalRows: any[]
  group: GroupHeaderRow
  groupHeaderRenderer?: FunctionComponent<IorvoGroupHeaderProps>
  style: any
  collapsed?: boolean
  columnPayload: any
  columns: IorvoGridColumn[]
  totalColumnsWidth: number
  freeze: number
  freezeWidth?: number
  depth: number
  onToggle: (group: GroupHeaderRow) => void
}

const GroupHeader: React.FC<Props> = ({
  originalRows,
  group,
  groupHeaderRenderer,
  style,
  totalColumnsWidth,
  columns,
  columnPayload,
  freeze,
  freezeWidth,
  depth,
  collapsed,
  onToggle,
}) => {
  const groupStyle = {
    ...style,
    width: totalColumnsWidth,
  }

  const cn: string = [
    'irv-group',
    `irv-gl-${getGroupLevel(group, depth)}`,
    collapsed ? 'collapsed' : '',
  ].join(' ')

  const [freezeColumns, restColumns] = useMemo(() => {
    let freezeColumns: IorvoGridColumn[] = []
    let restColumns: IorvoGridColumn[] = []

    if (freeze > 0) {
      restColumns = columns.slice(freeze)

      if (columns[0]._id === 'sn') {
        freezeColumns = columns.slice(1, freeze).map((c, i) => {
          if (i === 0) {
            return {
              ...c,
              width: c.width! + columns[0].width!,
            }
          }

          return { ...c }
        })
      } else {
        freezeColumns = columns.slice(0, freeze)
      }

      // добавляем чуток ширины для ПОСЛЕДНИХ колонок
      for (let i = depth - group.depth; i > 0; i--) {
        const index = restColumns.length - i
        const column = restColumns[index]

        if (column) {
          restColumns[index] = {
            ...column,
            width: column.width! + GROUP_FAKE,
          }
        }
      }
    } else {
      if (columns[0]._id === 'sn') {
        restColumns = columns.slice(1).map((c, i) => {
          if (i === 0) {
            return {
              ...c,
              width: c.width! + columns[0].width!,
            }
          }

          return { ...c }
        })
      } else {
        restColumns = columns.concat()
      }
    }

    if (freezeColumns.length) {
      freezeColumns[0].width! += (depth - group.depth) * GROUP_FAKE
    }

    return [freezeColumns, restColumns]
  }, [columns, freeze, depth, group])

  const handleToggle = useCallback(() => {
    onToggle(group)
  }, [group, onToggle])

  return (
    <div className={cn} style={groupStyle}>
      {freezeColumns.length > 0 ? (
        <div className={'irv-frz'} style={{ width: freezeWidth }}>
          <GFake depth={depth} starts={depth - group.depth} />
          {freezeColumns.map((column: IorvoGridColumn, i: number) => (
            <GroupHeaderCell
              key={column._id}
              column={column}
              originalRows={originalRows}
              columnPayload={columnPayload}
              group={group}
              groupHeaderRenderer={groupHeaderRenderer}
              caret={column.index === 0}
              onToggle={handleToggle}
            />
          ))}
        </div>
      ) : (
        <GFake depth={depth} starts={depth - group.depth} />
      )}

      <div className={'irv-group-wrap'}>
        {restColumns.map((column: IorvoGridColumn) => (
          <GroupHeaderCell
            key={column._id}
            column={column}
            originalRows={originalRows}
            columnPayload={columnPayload}
            group={group}
            groupHeaderRenderer={groupHeaderRenderer}
            caret={column.index === 0}
            onToggle={handleToggle}
          />
        ))}
      </div>

      <GFake depth={depth} starts={depth - group.depth} isRight />
    </div>
  )
}

export default React.memo(GroupHeader, areEqual)
