import React from 'react'
import { Input } from '../../../../../components/irv-ui'
import { BaseView } from '../../../../../types/BaseView'
import { BaseTable } from '../../../../../types/BaseTable'
import { ActionType } from '../../../../../store/CommonEffects'
import CustomizeFields from './blocks/CustomizeFields'
import CustomizeGroup from './blocks/CustomizeGroup'
import CustomizeColor from './blocks/CustomizeColor'
import CustomizeHeight from './blocks/CustomizeHeight'
import Collapse from 'antd/lib/collapse'
import Drawer from 'antd/es/drawer'
import get from 'lodash.get'
import NEWIcon from '../../../../../components/icon/NEWIcon'
import { GridColumn } from '../../../../../components/grid/types/GridColumn'
import { getColumnById, getColumnParamsItem } from '../../../../../utils/get'
import { isEmpty } from '../../../../../general/utils/is'
import { getViewColorType, ViewColorType } from '../../../../../utils/colors'
import { translateRowHeight } from '../../../../../utils/translations'
import Menu from 'antd/lib/menu'
import {
  ContextMenuItem,
  getMenuIcon,
} from '../../../../../components/dropdown/DropdownMenu'
import { TableActions } from '../../../../../store/actions/TableActions'
import { EventKey, onKeyHandler } from '../../../../../utils/dom'
import {
  ViewSettingsUIState,
  ViewSettingsUIStateItem,
} from '../../../types/UIState'
import BtnWithIcon from '../../../../../components/btn/BtnWithIcon'
import EditColumnContainer from './column/EditColumnContainer'

import './style.scss'

const menuItems: ContextMenuItem[] = [
  { key: TableActions.DUPLICATE_VIEW, text: 'Создать копию' },
  { key: TableActions.REMOVE_VIEW, text: 'Удалить' },
]

enum HeaderType {
  FIELDS,
  GROUPS,
  COLORS,
  ROW_HEIGHT,
}

const HEADERS = {
  [HeaderType.FIELDS]: {
    text: 'Поля',
    icon: 'list',
  },
  [HeaderType.GROUPS]: {
    text: 'Группировка',
    icon: 'grouping',
  },
  [HeaderType.COLORS]: {
    text: 'Подсветка',
    icon: 'color',
  },
  [HeaderType.ROW_HEIGHT]: {
    text: 'Высота строки',
    icon: 'medium',
  },
}

interface Props {
  view: BaseView
  table: BaseTable
  settingsUIState?: ViewSettingsUIState
  onPageChange: (state: ViewSettingsUIState) => void
  onAction: (action: ActionType, payload: any) => Promise<any>
}

interface State {
  name?: string
  activeKey?: string[]
}

export default class CustomizeContainer extends React.PureComponent<
  Props,
  State
> {
  constructor(props: Props) {
    super(props)

    this.state = {
      name: props.view.name,
    }
  }

  escHandler: any

  componentDidMount() {
    this.escHandler = onKeyHandler([EventKey.ESC], () => {
      this.setState({ name: this.props.view.name })
    })

    window.addEventListener('mousedown', this.handleMouseDown, false)
  }

  componentWillUnmount() {
    this.escHandler && this.escHandler()
    window.removeEventListener('mousedown', this.handleMouseDown, false)
  }

  close = () => {
    this.props.onPageChange(null)
  }

  openGeneral = () => {
    this.props.onPageChange({
      type: ViewSettingsUIStateItem.GENERAL,
    })
  }

  getColumn = (silence?: boolean): GridColumn => {
    const { settingsUIState, table } = this.props

    // Полюбому есть, тк выше будет проверка
    const columnId: string = get(settingsUIState, 'payload.columnId')
    const column: GridColumn = getColumnById(table, columnId)

    if (!column && !silence) {
      throw new Error(`Колонка не найдена`)
    }

    return column
  }

  getTitle = () => {
    const { settingsUIState } = this.props

    if (
      !settingsUIState ||
      settingsUIState.type === ViewSettingsUIStateItem.GENERAL
    ) {
      return 'Настройка представления'
    }

    if (settingsUIState) {
      return (
        <>
          <BtnWithIcon icon={'arrow-left'} min onClick={this.openGeneral} />
          {settingsUIState.type === ViewSettingsUIStateItem.COLUMN
            ? 'Настройка колонки'
            : 'Создание колонки'}
        </>
      )
    }
  }

  getExtra = (type: HeaderType) => {
    const { view, table } = this.props

    switch (type) {
      case HeaderType.FIELDS: {
        const hiddenColumns: number = table.columns.reduce(
          (count: number, column: GridColumn) => {
            return (
              count + (getColumnParamsItem(view, column._id, 'hidden') ? 1 : 0)
            )
          },
          0,
        )

        if (!hiddenColumns) {
          return
        }

        return (
          <span>
            {hiddenColumns} пол{hiddenColumns === 1 ? 'е' : 'я'} спрятано
          </span>
        )
      }

      case HeaderType.GROUPS: {
        if (!view.groups || isEmpty(view.groups)) {
          return
        }

        const groupIds: string[] = view.groups.map(g => g.columnId)
        const groupingColumns: string[] = table.columns
          .filter(c => groupIds.includes(c._id))
          .map(c => c.name)

        return <span>{groupingColumns.join(', ')}</span>
      }

      case HeaderType.COLORS: {
        const colorType = getViewColorType(view)

        if (colorType === ViewColorType.NONE) {
          return
        }

        if (colorType === ViewColorType.CONDITION) {
          return 'По условию'
        }

        const column: GridColumn | undefined = getColumnById(
          table,
          view.color.col,
        )

        return column ? column.name : null
      }

      case HeaderType.ROW_HEIGHT: {
        return translateRowHeight(get(view, 'params.rowHeight', 32))
      }
    }

    return null
  }

  getHeader = (type: HeaderType): React.ReactElement => {
    const conf = HEADERS[type]

    if (conf) {
      return (
        <div className={'view-settings-header'}>
          <div className={'text'}>
            <NEWIcon type={conf.icon} />
            {conf.text}
          </div>
          <div className={'extra'}>{this.getExtra(type) || null}</div>
        </div>
      )
    }

    return null
  }

  saveName = () => {
    const { view, onAction } = this.props
    const name = this.state.name

    if (name.trim()) {
      if (name !== view.name) {
        onAction(TableActions.UPDATE_VIEW, {
          name,
        })
      }
    }
  }

  handleMouseDown = e => {
    const { settingsUIState } = this.props

    if (
      settingsUIState &&
      !e.target.closest('.ws-view-settings') &&
      !e.target.closest('.cv')
    ) {
      this.close()
    }
  }

  handleEsc = () => {
    this.setState({ name: this.props.view.name })
  }

  handleChangeName = name => {
    this.setState({ name })
  }

  handleCollapse = (activeKey: string[]) => {
    this.setState({ activeKey })
  }

  renderViewSettings = () => {
    const { view, table, onPageChange, onAction, settingsUIState } = this.props
    const { name, activeKey } = this.state

    if (!settingsUIState) {
      return null
    }

    return (
      <div className="view-settings">
        <Input
          fit
          autoFocus
          className={'din'}
          value={name}
          onChange={this.handleChangeName}
          onPressEnter={this.saveName}
          onBlur={this.saveName}
        />

        <Collapse
          bordered={false}
          expandIconPosition={'right'}
          activeKey={activeKey}
          onChange={this.handleCollapse}
          destroyInactivePanel={true}>
          <Collapse.Panel
            header={this.getHeader(HeaderType.FIELDS)}
            key={HeaderType.FIELDS}>
            <CustomizeFields
              view={view}
              table={table}
              onAction={onAction}
              onPageChange={onPageChange}
            />
          </Collapse.Panel>
          <Collapse.Panel
            header={this.getHeader(HeaderType.GROUPS)}
            key={HeaderType.GROUPS}>
            <CustomizeGroup view={view} table={table} onAction={onAction} />
          </Collapse.Panel>
          <Collapse.Panel
            header={this.getHeader(HeaderType.COLORS)}
            key={HeaderType.COLORS}>
            <CustomizeColor view={view} table={table} onAction={onAction} />
          </Collapse.Panel>
          <Collapse.Panel
            header={this.getHeader(HeaderType.ROW_HEIGHT)}
            key={HeaderType.ROW_HEIGHT}>
            <CustomizeHeight view={view} table={table} onAction={onAction} />
          </Collapse.Panel>
        </Collapse>

        <div className={'cm'}>
          <Menu selectedKeys={[]}>
            {menuItems.map(menu => (
              <Menu.Item key={menu.key}>
                {getMenuIcon(menu.key)}
                {menu.text}
              </Menu.Item>
            ))}
          </Menu>
        </div>
      </div>
    )
  }

  renderColumnSettings = () => {
    const { view, table, onAction, onPageChange, settingsUIState } = this.props
    const column: GridColumn = getColumnById(
      table,
      get(settingsUIState, 'payload.columnId'),
    )

    if (!column) {
      return null
    }

    return (
      <EditColumnContainer
        column={column}
        view={view}
        table={table}
        onAction={onAction}
        onPageChange={onPageChange}
      />
    )
  }

  render() {
    const { settingsUIState } = this.props

    return (
      <Drawer
        visible={!!settingsUIState}
        width={400}
        title={this.getTitle()}
        mask={false}
        className={'ws-view-settings'}
        onClose={this.close}>
        {!settingsUIState ||
        settingsUIState.type === ViewSettingsUIStateItem.GENERAL
          ? this.renderViewSettings()
          : this.renderColumnSettings()}
      </Drawer>
    )
  }
}
