import React from "react";
import {Link} from "react-router-dom";
import Shape from "../../../../components/shape/Shape";
import Icon from "../../../../components/icon/Icon";
import {getColorClass, getTextColorByClass} from "../../../../utils/colors";
import {Base} from "../../../../types/Base";
import {ActionType, DisturbEntityArguments} from "../../../../store/CommonEffects";
import {Roles} from "../../../../components/roles/Roles";
import {BaseActions} from "../../../../store/actions/BaseActions";
import {Workspace} from "../../../../types/Workspace";
import {EyzyUser} from "../../../../types/EyzyUser";
import {
  getRole,
  getUserRole,
  isRoleAllowed,
  isWSActionGranted,
  UIRoleAction
} from "../../../../components/roles/RoleGateway";
import BaseContextMenu from "../../../../components/contextMenu/base/BaseContextMenu";
import NEWIcon from "../../../../components/icon/NEWIcon";
import {isMob} from "../../../../utils/is";

interface Props {
  base: Base
  user: EyzyUser
  workspace: Workspace
  onAction: (args: DisturbEntityArguments) => Promise<any>
  onMenuClick: (key: ActionType, payload: any) => void
}

export default React.memo(function HelloBase(props: Props) {
  const {base, user, workspace, onMenuClick} = props
  const wsRole = getRole(user, workspace)
  const userRole = wsRole === Roles.OWNER
    ? Roles.OWNER
    : base.limitedAccess ? getUserRole(user, base) || wsRole : getRole(user, workspace)

  const handleMenuClick = (key: ActionType) => onMenuClick(key, base)
  const handleOpenCollaborators = () => onMenuClick(BaseActions.PERMISSIONS_SETTING, base)

  const contextMenu = !isMob() && isRoleAllowed(UIRoleAction.BASE_ALLOW_CONFIGURE, userRole)
    ? (
      <BaseContextMenu
        base={base}
        hideCreateTable={true}
        onMenuClick={handleMenuClick}
      >
        <button className="bo b-edit">
          <NEWIcon type="b-caret-circle-down"/>
        </button>
      </BaseContextMenu>
    )
    : null

  return (
    <li data-base={base._id}>
      <Link to={`/${base._id}`} className="hello-link" aria-labelledby={base._id} title={base.name}>
        <Shape className={getColorClass(base.color)}>
          <Icon type={base.icon || 'custom'} style={getTextColorByClass(base.color)} custom={base.name}/>
        </Shape>
        <span className="tc" id={base._id}>{base.name}</span>

        {base.limitedAccess && isWSActionGranted(UIRoleAction.BASE_COLLABORATORS_LIST, user, workspace) && (
          <button className={'bo b-lock'} onClick={handleOpenCollaborators}>
            <NEWIcon type="b-lock"/>
          </button>
        )}
      </Link>

      {contextMenu}
    </li>
  )
})
