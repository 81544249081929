import React, { memo, useCallback, useMemo } from 'react'
import { GridColumnOptionItem } from '../../grid/types/GridColumn'
import { isMob } from '../../../utils/is'
import Tag from '../../shape/Tag'
import { arrToObj } from '../../../utils/array'
import { Select } from '../../irv-ui'
import { asArray } from '../../../utils/as'
import { FormRendererProps } from '../types/FormRenderer'
import { ColumnType } from '../../../general/types/ColumnType'

const FormSingleSelectRenderer: React.FC<FormRendererProps> = ({
  value,
  column,
  readonly,
  params,
  className,
  onChange,
  onCommit,
}) => {
  const mobile: boolean = isMob()
  const mode: string = useMemo(() => {
    return column.type === ColumnType.MULTIPLE_SELECT ? 'multiple' : ''
  }, [column])

  const { selectOptions, selectOptionsMap } = useMemo(() => {
    const params = column.params
    const options = params.options || []

    const possibleOptions = params.visibleOptions
      ? options.filter(opt => params.visibleOptions.includes(opt._id))
      : options

    const baseOption = !mode
      ? [{ _id: 'null', text: ' ', value: null } as GridColumnOptionItem]
      : []

    const selectOptions = baseOption
      .concat(possibleOptions)
      .filter(opt => asArray(value).includes(opt._id) || !opt.removedOn)
      .map(o => ({
        ...o,
        value: o._id,
      }))

    return {
      selectOptions,
      selectOptionsMap: arrToObj(selectOptions, '_id', false),
    }
  }, [params, mode, value])

  const onSelectChange = useCallback(
    val => {
      if (val === 'null') {
        val = null
      }

      onChange(val)

      if (onCommit) {
        onCommit(val)
      }
    },
    [onChange, onCommit],
  )

  const TagRenderer = useCallback(
    ({ item, onRemove }) => {
      const handleRemove = !mode || readonly || mobile ? null : onRemove

      return (
        <Tag
          key={item.value}
          color={params.useColor ? item.color : ''}
          removed={!!item.removedOn}
          onRemove={handleRemove}>
          {item.text}
        </Tag>
      )
    },
    [params, selectOptionsMap, mode, readonly, mobile],
  )

  const showSearch = useMemo(() => {
    if (isMob()) {
      return false
    }

    return selectOptions.length > 10
  }, [selectOptions])

  if (readonly) {
    return (
      <div className={className}>
        {asArray(value)
          .map(optId => selectOptionsMap[optId])
          .filter(Boolean)
          .map(option => (
            <Tag
              color={params.useColor ? option.color : ''}
              removed={!!option.removedOn}>
              {option.text}
            </Tag>
          ))}
      </div>
    )
  }

  return (
    <Select
      id={column._id}
      showSearch={showSearch}
      value={value || null}
      valueRenderer={TagRenderer}
      items={selectOptions}
      fit
      hideSelected={!mobile}
      mode={mode}
      className={className}
      onSelect={onSelectChange}>
      {(option, mark) => (
        <div className={'irv-bitem'}>
          {option._id === 'null' ? (
            mobile ? (
              <span className="secondary-text">Очистити</span>
            ) : (
              <span />
            )
          ) : (
            <Tag
              color={params.useColor ? option.color : ''}
              removed={!!option.removedOn}>
              {option.text}
            </Tag>
          )}

          {mobile && mark}
        </div>
      )}
    </Select>
  )
}

export default memo(FormSingleSelectRenderer)
