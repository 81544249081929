import React from 'react'
import {cn} from "../../utils/classname";

interface Props {
  autoFocus?: boolean
  defaultValue?: string
  value?: string
  onChange: (value: string) => void
  delay?: number
  fit?: boolean
  cn?: string
}

interface State {
  value: string
}

export default class DelayedInput extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      value: props.defaultValue || props.value
    }
  }

  timer: any

  componentDidUpdate(_, prevState: State) {
    if (this.props.value !== this.state.value && this.state.value === prevState.value) {
      this.setState({ value: this.props.value })
    }
  }

  initInterval = () => {
    clearTimeout(this.timer)

    this.timer = setTimeout(_ => {
      this.props.onChange(this.state.value)
    }, this.props.delay || 1000)
  }

  handleChange = (e) => {
    this.setState({ value: e.target.value })
    this.initInterval()
  }

  render() {
    const className = cn('e-input e-input-fit', this.props.cn)

    return (
      <input
        {...this.props}
        className={className}
        value={this.state.value}
        onChange={this.handleChange}
      />
    )
  }
}
