import React, { memo } from 'react'
import { BaseFormOptions, BaseFormProps } from '../types/BaseFormProps'
import { FormProvider } from '../../../_renderers/RendererContext'
import Form from './Form'
import { GridColumn } from '../../../grid/types/GridColumn'
import { GridRow } from '../../../grid/types/GridRow'
import { Filter } from '../../../../general/filters/Filter'
import { currentUser } from '../../../../utils/get'

const filterFields = (
  columns: GridColumn[],
  { table, user },
  formOptions: BaseFormOptions,
  data: GridRow,
): GridColumn[] => {
  return columns.filter(column => {
    const filter = formOptions[column._id]?.visibility

    if (!filter) {
      return true
    }

    return new Filter({
      table,
      user,
      filter,
    }).isMatch(data)
  })
}

const BaseForm: React.FC<BaseFormProps> = ({
  payload,
  formOptions,
  data,
  fields,
  ...rest
}) => {
  const visibleFields = !!formOptions
    ? filterFields(
        fields,
        { table: payload.table, user: currentUser() },
        formOptions,
        data,
      )
    : fields

  return (
    <FormProvider.Provider value={payload}>
      <Form
        {...rest}
        data={data}
        fields={visibleFields}
        formOptions={formOptions}
      />
    </FormProvider.Provider>
  )
}

export default memo(BaseForm)
