import React, { useState } from 'react'
import { Switch } from '../../../../components/irv-ui'
import { SettingsItemComponentProps } from '../types'
import Select from 'antd/es/select'
import PayloadProvider from '../../../workspace/PayloadProvider'
import { getCurrentUserMarkup } from '../../../../utils/get'
import { EyzyUser } from '../../../../types/EyzyUser'
import UserBadge from '../../../__renderers/user/UserBadge'
import { asArray } from '../../../../general/utils/array'

const { Option, OptGroup } = Select

export default React.memo((props: SettingsItemComponentProps) => {
  const { params, onAction } = props
  const [visible, setVisibility] = useState(false)
  const onChange = multiple => {
    onAction({
      multiple,
    })
  }

  const handleDefaultChange = value => {
    const aValue = asArray(value)
    const isNull = aValue.length === 1 && aValue[0] === null

    let defaultValue = isNull ? null : aValue.filter(v => !!v)

    if (isNull) {
      setVisibility(false)
    }

    onAction({
      defaultValue,
    })
  }

  const currentUser = getCurrentUserMarkup()
  const getPreparedGroups = (users: EyzyUser[]) => {
    return [
      {
        text: 'Спец. значения',
        users: [currentUser],
      },
      {
        text: 'Активные пользователи',
        users,
      },
    ]
  }

  return (
    <>
      <PayloadProvider.Consumer>
        {({ users }) => (
          <div className={'column-settings-row'}>
            <div className={'column-settings-label'}>Значение по умолчанию</div>
            <div className={'column-settings-ctrl c-selector'}>
              <Select
                getPopupContainer={trigger => trigger.parentNode}
                dropdownMatchSelectWidth={false}
                onChange={handleDefaultChange}
                value={params.defaultValue || [null]}
                open={visible}
                onDropdownVisibleChange={setVisibility}
                mode={params.multiple ? 'multiple' : undefined}>
                <Select.Option value={null}>Не указано</Select.Option>

                {getPreparedGroups(users || []).map((group, i) => (
                  <OptGroup key={i} label={group.text}>
                    {group.users.map(user => (
                      <Option
                        key={user._id}
                        value={user._id}
                        label={user.fullName}>
                        <UserBadge key={user._id} user={user} />
                      </Option>
                    ))}
                  </OptGroup>
                ))}
              </Select>
            </div>
          </div>
        )}
      </PayloadProvider.Consumer>
      <div className={'column-settings-row'}>
        <div className={'column-settings-label'}>Множественный выбор</div>
        <div className={'column-settings-ctrl'}>
          <Switch defaultChecked={params.multiple} onChange={onChange} />
        </div>
      </div>
    </>
  )
})
