import React from 'react'
import dayjs from 'dayjs'
import { topWithStartHours, MS_IN_DAY } from '../../utils'

interface Props {
  rowHeight: number
  startHours?: number
}

export default class CurrentTime extends React.PureComponent<Props> {
  timer: any

  componentDidMount() {
    this.initTimer()
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  initTimer = () => {
    this.timer = setTimeout(_ => {
      this.forceUpdate()
      this.initTimer()
    }, 1000 * 5)
  }

  render() {
    const { rowHeight, startHours } = this.props
    const dStart = +dayjs().startOf('day')
    const uStart = +new Date()
    const calendarRealHeight: number = rowHeight * 24
    const top = topWithStartHours(
      calendarRealHeight * ((uStart - dStart) / MS_IN_DAY), 
      rowHeight,
      startHours
    )

    return (
      <div className='ct' style={{top: top}} />
    )
  }
}
