import { ColumnType } from '../../general/types/ColumnType'
import { GridColumn } from '../grid/types/GridColumn'

import GridTextRenderer from './grid/TextRenderer'
import GridLongTextRenderer from './grid/LongTextRenderer'
import GridDateRenderer from './grid/DateRenderer'
import GridSingleSelectRenderer from './grid/SingleSelectRenderer'
import GridMultipleSelectRenderer from './grid/MultipleSelectRenderer'
import GridCheckboxRenderer from './grid/CheckboxRenderer'
import GridEmailRenderer from './grid/EmailRenderer'
import GridSocialRenderer from './grid/SocialRenderer'
import GridPhoneRenderer from './grid/PhoneRenderer'
import GridLinkRenderer from './grid/LinkRenderer'
import GridNumberRenderer from './grid/NumberRenderer'
import GridUserRenderer from './grid/UserRenderer'
import GridLinkToRecordRenderer from './grid/LinkToRecordRenderer'
import GridFormulaRenderer from './grid/FormulaRenderer'
import GridLookupRenderer from './grid/LookupRenderer'
import GridRollupRenderer from './grid/RollupRenderer'
import GridSpecialDateRenderer from './grid/SpecialDateRenderer'
import GridSpecialUserRenderer from './grid/SpecialUserRenderer'
import GridActionRenderer from './grid/ActionRenderer'
import GridMirrorRenderer from './grid/MirrorRenderer'

import BaseTextRenderer from './base/TextRenderer'
import BaseLongTextRenderer from './base/LongTextRenderer'
import BaseDateRenderer from './base/DateRenderer'
import BaseSingleSelectRenderer from './base/SelectRenderer'
import BaseUserRenderer from './base/UserRenderer'
import BaseLookupRenderer from './base/LookupRenderer'

import FormPhoneRenderer from './form/PhoneRenderer'
import FormSocialRenderer from './form/SocialRenderer'
import FormDateRenderer from './form/DateRenderer'
import FormUserRenderer from './form/UserRenderer'
import FormTextRenderer from './form/TextRenderer'
import FormLongTextRenderer from './form/LongTextRender'
import FormSingleSelectRenderer from './form/SingleSelectRender'
import FormLinkRenderer from './form/LinkRenderer'
import FormEmailRenderer from './form/EmailRenderer'
import FormNumberRenderer from './form/NumberRenderer'
import FormLinkToRecordRenderer from './form/LinkToRecordRenderer'
import FormFormulaRenderer from './form/FormulaRenderer'
import FormLookupRenderer from './form/LookupRenderer'
import FormCheckboxRenderer from './form/CheckboxRenderer'
import FormSpecialUserRenderer from './form/SpecialUserRenderer'
import FormSpecialDateRenderer from './form/SpecialDateRenderer'
import FormActionRenderer from './form/ActionRenderer'
import FormMirrorRenderer from "./form/FormMirrorRenderer"

import BaseSimpleRenderer from './simple/BaseSimpleRenderer'
import SimpleDateRenderer from './simple/DateRenderer'
import SimpleSelectRenderer from './simple/SelectRenderer'
import SimpleEmailRenderer from './simple/EmailRenderer'
import SimpleSocialRenderer from './simple/SocialRenderer'
import SimplePhoneRenderer from './simple/PhoneRenderer'
import SimpleLinkRenderer from './simple/LinkRenderer'
import SimpleNumberRenderer from './simple/NumberRenderer'
import SimpleUserRenderer from './simple/UserRenderer'
import SimpleLinkToRecordRenderer from './simple/LinkToRecordRenderer'
import SimpleLookupRenderer from './simple/LookupRenderer'
import SimpleCheckboxRenderer from './simple/CheckboxRenderer'
import SimpleFormulaRenderer from './simple/FormulaRenderer'

export const gridRendererFactory = (column: GridColumn): any => {
  switch (column.type) {
    case ColumnType.LONG_TEXT:
      return GridLongTextRenderer
    case ColumnType.DATE:
      return GridDateRenderer
    case ColumnType.SINGLE_SELECT:
      return GridSingleSelectRenderer
    case ColumnType.MULTIPLE_SELECT:
      return GridMultipleSelectRenderer
    case ColumnType.CHECKBOX:
      return GridCheckboxRenderer
    case ColumnType.EMAIL:
      return GridEmailRenderer
    case ColumnType.SOCIAL:
      return GridSocialRenderer
    case ColumnType.PHONE:
      return GridPhoneRenderer
    case ColumnType.LINK:
      return GridLinkRenderer
    case ColumnType.NUMBER:
      return GridNumberRenderer
    case ColumnType.USER:
      return GridUserRenderer
    case ColumnType.LINK_TO_RECORD:
      return GridLinkToRecordRenderer
    case ColumnType.FORMULA:
      return GridFormulaRenderer
    case ColumnType.LOOKUP:
      return GridLookupRenderer
    case ColumnType.ROLLUP:
      return GridRollupRenderer
    case ColumnType.CREATION_DATE:
    case ColumnType.MODIFIED_ON:
      return GridSpecialDateRenderer
    case ColumnType.CREATOR:
    case ColumnType.MODIFIED_BY:
      return GridSpecialUserRenderer
    case ColumnType.ACTION:
      return GridActionRenderer
    case ColumnType.MIRROR:
      return GridMirrorRenderer
  }

  return GridTextRenderer
}

export const baseRendererFactory = (column: GridColumn): any => {
  switch (column.type) {
    case ColumnType.TEXT:
      return BaseTextRenderer
    case ColumnType.LONG_TEXT:
      return BaseLongTextRenderer
    case ColumnType.DATE:
    case ColumnType.CREATION_DATE:
    case ColumnType.MODIFIED_ON:
      return BaseDateRenderer
    case ColumnType.SINGLE_SELECT:
    case ColumnType.MULTIPLE_SELECT:
      return BaseSingleSelectRenderer
    case ColumnType.USER:
      return BaseUserRenderer
  }

  return BaseTextRenderer
}

export const groupRendererFactory = (column: GridColumn): any => {
  switch (column.type) {
    case ColumnType.LOOKUP:
      return BaseLookupRenderer
    case ColumnType.USER:
    case ColumnType.CREATOR:
    case ColumnType.MODIFIED_BY:
      return BaseUserRenderer
    case ColumnType.SINGLE_SELECT:
    case ColumnType.MULTIPLE_SELECT:
      return BaseSingleSelectRenderer
  }

  return BaseTextRenderer
}

export const formRendererFactory = (column: GridColumn): any => {
  switch (column.type) {
    case ColumnType.TEXT:
      return FormTextRenderer
    case ColumnType.LONG_TEXT:
      return FormLongTextRenderer
    case ColumnType.DATE:
      return FormDateRenderer
    case ColumnType.SINGLE_SELECT:
    case ColumnType.MULTIPLE_SELECT:
      return FormSingleSelectRenderer
    case ColumnType.PHONE:
      return FormPhoneRenderer
    case ColumnType.LINK:
      return FormLinkRenderer
    case ColumnType.EMAIL:
      return FormEmailRenderer
    case ColumnType.SOCIAL:
      return FormSocialRenderer
    case ColumnType.NUMBER:
      return FormNumberRenderer
    case ColumnType.USER:
      return FormUserRenderer
    case ColumnType.LINK_TO_RECORD:
      return FormLinkToRecordRenderer
    case ColumnType.FORMULA:
      return FormFormulaRenderer
    case ColumnType.LOOKUP:
      return FormLookupRenderer
    case ColumnType.CHECKBOX:
      return FormCheckboxRenderer
    case ColumnType.CREATOR:
    case ColumnType.MODIFIED_BY:
      return FormSpecialUserRenderer
    case ColumnType.CREATION_DATE:
    case ColumnType.MODIFIED_ON:
      return FormSpecialDateRenderer
    case ColumnType.ACTION:
      return null
      // return FormActionRenderer
    case ColumnType.MIRROR:
      return FormMirrorRenderer
  }

  return BaseTextRenderer
}

export const inlineFormRendererFactory = (column: GridColumn): any => {
  return formRendererFactory(column)
}

export const simpleRendererFactory = (column: GridColumn): any => {
  switch (column.type) {
    case ColumnType.DATE:
    case ColumnType.MODIFIED_ON:
    case ColumnType.CREATION_DATE:
      return SimpleDateRenderer
    case ColumnType.SINGLE_SELECT:
    case ColumnType.MULTIPLE_SELECT:
      return SimpleSelectRenderer
    case ColumnType.EMAIL:
      return SimpleEmailRenderer
    case ColumnType.SOCIAL:
      return SimpleSocialRenderer
    case ColumnType.PHONE:
      return SimplePhoneRenderer
    case ColumnType.LINK:
      return SimpleLinkRenderer
    case ColumnType.NUMBER:
      return SimpleNumberRenderer
    case ColumnType.USER:
    case ColumnType.CREATOR:
    case ColumnType.MODIFIED_BY:
      return SimpleUserRenderer
    case ColumnType.LINK_TO_RECORD:
      return SimpleLinkToRecordRenderer
    case ColumnType.LOOKUP:
      return SimpleLookupRenderer
    case ColumnType.CHECKBOX:
      return SimpleCheckboxRenderer
    case ColumnType.FORMULA:
      // return SimpleFormulaRenderer
      return FormFormulaRenderer
  }

  return BaseSimpleRenderer
}

export enum RendererClassNames {
  INPUT = 'irv-spc tp',
  TEXTAREA = 'irv-spc tl',
  DATE = 'irv-spc td',
  SELECT = 'irv-spc sl',
  CHECKBOX = 'irv-spc ch',
  EMAIL = 'irv-spc el',
  SOCIAL = 'irv-spc sc',
  NUMBER = 'irv-spc nb',
  USER = 'irv-spc us',
  LINK_TO_RECORD = 'irv-spc lr',
  FORMULA = 'irv-spc fr',
  LOOKUP = 'irv-spc lp',
  ACTION = 'irv-spc an',
}

export const RendererClassNames__ = {
  [ColumnType.TEXT]: 'tp',
  [ColumnType.LONG_TEXT]: 'tl',
  [ColumnType.DATE]: 'td',
  [ColumnType.PHONE]: 'ph',
  [ColumnType.EMAIL]: 'el',
  [ColumnType.USER]: 'us',
  [ColumnType.LINK]: 'lk',
  [ColumnType.FORMULA]: 'fr',
  [ColumnType.CREATION_DATE]: 'cd',
  [ColumnType.MODIFIED_ON]: 'mo',
  [ColumnType.CHECKBOX]: 'cc',
  [ColumnType.LINK_TO_RECORD]: 'lr',
  [ColumnType.SINGLE_SELECT]: 'ss',
  [ColumnType.MULTIPLE_SELECT]: 'ms',
  [ColumnType.SOCIAL]: 'sc',
  [ColumnType.NUMBER]: 'nb',
}
