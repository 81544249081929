import React, { useCallback, useState } from 'react'
import ReactDOM from 'react-dom'
import { OpenTransition } from './OpenTransition'
import { OverlayProps } from './types/OverlayProps'

const Overlay = (props: OverlayProps, ref: any) => {
  const {
    children,
    open,
    container,
    onEnter,
    onEntering,
    onEntered,
    onExit,
    onExiting,
    onExited,
  } = props
  const [exited, setExited] = useState(!open)

  const handleEntered = useCallback(() => {
    setExited(false)
    if (onEntered) {
      onEntered()
    }
  }, [onEntered])

  const handleExited = useCallback(() => {
    setExited(true)
    if (onExited) {
      onExited()
    }
  }, [onExited])

  // Don't un-render the overlay while it's transitioning out.
  const mountOverlay = open || !exited

  if (!mountOverlay) {
    // Don't bother showing anything if we don't have to.
    return null
  }

  const contents = (
    <div style={{ zIndex: 9999 }}>
      <OpenTransition
        in={open}
        appear
        onExit={onExit}
        onExiting={onExiting}
        onExited={handleExited}
        onEnter={onEnter}
        onEntering={onEntering}
        onEntered={handleEntered}>
        {children}
      </OpenTransition>
    </div>
  )

  return ReactDOM.createPortal(contents, container || document.body)
}

export default React.forwardRef(Overlay)
