import React, { useCallback, useMemo } from 'react'
import { AddonFormProps } from '../types/AddonFormProps'
import { useAddonFormOptions } from '../useAddonFormOptions'
import { isAllowedForAddon, prepareColumns } from '../../../../utils/column'
import BaseForm from '../form/BaseForm'
import { getColumnById, mergeColumnWithViewParams } from '../../../../utils/get'
import { GridColumn } from '../../../grid/types/GridColumn'
import { FormDetailsPayload } from '../types/BaseFormProps'
import { AddonActions } from '../../../../store/actions/AddonActions'
import { getViewValue } from '../../../../general/utils/value'

export default function AddonForm(props: AddonFormProps) {
  const { table, header, formData, form, widget, onChange, onBindValues, errors, ws } = props

  const payload: FormDetailsPayload = {
    table,
    row: formData,
    users: ws.collaborators,
    links: {},
    recordAction: {
      action: AddonActions.READ_RECORDS,
      id: widget._id,
      queryString: {
        form: form._id,
      },
    },
  }

  // Когда добавиться всякие визибилити рулы
  // Тут будет "sensitive" колонки, при изменении которых будем СЧИТАТЬ визибилити
  // и ставить hidden где нужно.
  // А пока похуй

  const { formOptions, viewOptions } = useAddonFormOptions(table, form)

  const handleChange = useCallback(
    (column: GridColumn, value: any, payload?: any) => {
      onChange(form._id, column._id, value)

      // Тут еще много багов будет... потом уже тестить норм
      // Тут сейчас заюзаем bindValues
      if (payload && formOptions?.[column._id]?.bindValues?.columns) {
        onBindValues(
          form._id,
          formOptions?.[column._id]?.bindValues,
          payload
        )

        // const { rows, table } = payload
        //
        // if (rows.length !== 1) {
        //   return
        // }
        //
        // for (const [foreignColumn, currentColumn] of Object.entries(
        //   formOptions?.[column._id]?.bindValues?.columns,
        // )) {
        //   const foreignValue = getViewValue(
        //     table,
        //     getColumnById(table, foreignColumn),
        //     rows[0],
        //   )
        //
        //   if (foreignValue === void 0) {
        //     continue
        //   }
        //
        //   onChange(form._id, currentColumn, foreignValue)
        // }
      }
    },
    [onChange, form._id],
  )

  const fields = useMemo(() => {
    return prepareColumns(table, viewOptions)
      .map(column => ({
        ...column,
        params: mergeColumnWithViewParams(viewOptions, column),
      }))
      .filter(isAllowedForAddon)
  }, [table, viewOptions])

  return (
    <BaseForm
      header={header}
      fields={fields}
      errors={errors}
      formOptions={formOptions}
      onChange={handleChange}
      data={formData}
      payload={payload}
    />
  )
}
