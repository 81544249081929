import React from "react";
import {BaseRendererProps} from "../BaseRendererProps";
import {format} from "../../../general/utils/date";

export default React.memo(function BaseCreatedDateRenderer(props: BaseRendererProps<undefined | Date>) {
  if (!props.value) {
    return null
  }

  return (
    <div className={'re-value'}>
      {props.value && format(props.value, props.format, props.timeFormat)}
    </div>
  )
})