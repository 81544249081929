import React from 'react'
import { FilterRendererProps } from '../FilterRendererFactory'
import { Checkbox } from '../../../components/irv-ui'

export default React.memo(function CheckboxFilterRenderer(
  props: FilterRendererProps,
) {
  const { filter, onChange } = props

  const handleChange = value => {
    onChange({ value })
  }

  return (
    <div className={'fp-cbx'}>
      <Checkbox checked={filter.value} onChange={handleChange} />
    </div>
  )
})
