import React, {useEffect, useState} from 'react'
import {useDebounce} from "./hooks";

interface Props {
  autoFocus?: boolean
  defaultValue?: string
  value?: string
  onChange: (value: string) => void
  delay?: number
  fit?: boolean
}

export default React.memo(function TextareaControl(props: Props) {
  const {onChange, delay, defaultValue, fit, ...inputProps} = props
  const initValue = defaultValue || ''
  const [value, setValue] = useState(initValue)
  const debouncedValue = useDebounce(value, delay || 1000, false)

  const handleChange = (e) => {
    setValue(e.target.value)
  }

  useEffect(() => {
    if (debouncedValue === initValue) {
      return
    }

    onChange(debouncedValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue, initValue])

  return (
    <textarea
      {...inputProps}
      className={'eyzy-textarea eyzy-textarea-fit'}
      value={value}
      onChange={handleChange}
    />
  )
})