import React from 'react'
import {GROUP_FAKE} from "../Grid";

interface Props {
  depth: number
  starts?: number
  isRight?: boolean
}

const GFake: React.FC<Props> = ({depth, starts, isRight}) => {
  if (!depth) {
    return null
  }

  const result = []

  for (let i = starts || 0; i < depth; i++) {
    result.push(
      <div className={`irv-gf${isRight ? 'r' : ''} gg-${i}`} key={i} style={{width: GROUP_FAKE}}/>
    )
  }

  return <>{isRight ? result : result.reverse()}</>
}

export default React.memo(GFake)
