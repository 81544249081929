import React from 'react'
import { SimpleRendererProps } from '../types/SimpleRendererProps'
import SocialLink from '../utils/SocialLink'

const SocialRenderer: React.FC<SimpleRendererProps> = ({
  value,
  className,
  column,
}) => {
  if (!value) {
    return null
  }

  return (
    <div className={className}>
      <SocialLink value={value} asLink={true} type={column.params.sType} />
    </div>
  )
}

export default React.memo(SocialRenderer)
