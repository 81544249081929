import { cn } from '../utils/cn'

interface UnderlayProps {
  open?: boolean
  transparent?: boolean
}

export function Underlay(props: UnderlayProps) {
  const { open, transparent, ...restProps } = props
  const className = cn(
    'irv-underlay',
    {
      open,
      tr: transparent
    }
  )

  return <div className={className} {...restProps} />
}
