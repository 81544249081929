import "./style.scss"
import React from "react"
import { ColumnType } from "../../../general/types/ColumnType"
import { SettingsItemComponentProps } from "./types"
import DateComponent from "./components/Date"
import NumberComponent from "./components/Number"
import DefaultStrValue from "./components/DefaultStrValue"
import Checkbox from "./components/Checkbox"
import User from "./components/User"
import Record from "./components/Record"
import RecordView from "./components/RecordView"
import SelectItems from "./components/SelectItems"
import SingleSelectView from "./components/SingleSelectView"
import LookupSettings from "./components/Lookup"
import RollupSettings from "./components/Rollup"
import Social from "./components/Social"
import FormulaView from "./components/FormulaView"
import Formula from "./components/Formula"
import Mirror from './components/Mirror'

const SettingsItemComponents = {
  [ColumnType.TEXT]: {
    view: [DefaultStrValue],
  },
  [ColumnType.DATE]: {
    view: [DateComponent],
  },
  [ColumnType.CHECKBOX]: {
    view: [Checkbox],
  },
  [ColumnType.NUMBER]: {
    view: [NumberComponent],
  },
  [ColumnType.USER]: {
    view: [User],
  },
  [ColumnType.LINK_TO_RECORD]: {
    general: [Record],
    view: [RecordView],
  },
  [ColumnType.SINGLE_SELECT]: {
    general: [SelectItems],
    view: [SingleSelectView],
  },
  [ColumnType.MULTIPLE_SELECT]: {
    general: [SelectItems],
    view: [SingleSelectView],
  },
  [ColumnType.LOOKUP]: {
    general: [LookupSettings],
  },
  [ColumnType.ROLLUP]: {
    general: [RollupSettings],
  },
  [ColumnType.SOCIAL]: {
    general: [Social],
  },
  [ColumnType.FORMULA]: {
    general: [Formula],
    view: [FormulaView],
  },
  [ColumnType.MIRROR]: {
    general: [Mirror]
  },
}

export function getSettingsComponents(type: string, isView?: boolean): React.FC<SettingsItemComponentProps>[] | null {
  const item = SettingsItemComponents[type]

  if (!item) {
    return
  }

  // @ts-ignore
  return (isView ? item.view : item.general) || []
}
