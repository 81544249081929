import React from 'react'
import {GridRendererProps} from "../GridRendererProps";
import {formatNumber} from "../../../utils/view";
import { getValue } from '../../../general/utils/value';

export default React.memo(function GridNumberRenderer(props: GridRendererProps) {
  const {row, column} = props
  const value = getValue(column.table, column, row)

  if (isNaN(value)) {
    return null
  }

  return (
    <div className="re-value number">
      {formatNumber(value, column.params.precision)}
    </div>
  )
})
