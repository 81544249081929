import { useCallback, useRef, useState } from 'react'

export function useControlledState<T>(
  value: T,
  onChange: (value: T, ...args: any[]) => void,
  defaultValue: T,
): [T, (value: T, ...args: any[]) => void] {
  const [stateValue, setStateValue] = useState(value || defaultValue)
  const ref = useRef(value !== undefined)
  const wasControlled = ref.current
  const isControlled = value !== undefined

  // Internal state reference for useCallback
  const stateRef = useRef(stateValue)

  if (wasControlled !== isControlled) {
    console.warn(
      `WARN: A component changed from ${
        wasControlled ? 'controlled' : 'uncontrolled'
      } to ${isControlled ? 'controlled' : 'uncontrolled'}.`,
    )
  }

  ref.current = isControlled

  const setValue = useCallback(
    (value, ...args) => {
      const onChangeCaller = (value, ...onChangeArgs) => {
        if (onChange) {
          if (!Object.is(stateRef.current, value)) {
            onChange(value, ...onChangeArgs)
          }
        }
        if (!isControlled) {
          stateRef.current = value
        }
      }

      if (!isControlled) {
        setStateValue(value)
      }

      onChangeCaller(value, ...args)
    },
    [isControlled, onChange],
  )

  // If a controlled component's value prop changes, we need to update stateRef
  if (isControlled) {
    stateRef.current = value
  } else {
    value = stateValue
  }

  return [value, setValue]
}
