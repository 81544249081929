import React from 'react'
import {EyzyUser} from "../../types/EyzyUser";
import {BaseTable} from "../../types/BaseTable";
import {GridRow} from "../grid/types/GridRow";

export type EyzyLink = {
  table: BaseTable
  rows: GridRow[]
  loading?: boolean
}

type ContextProps = {
  users?: EyzyUser[]
  activeUsers?: EyzyUser[]
  links?: {[key: string]: EyzyLink}
}

export default React.createContext<ContextProps>({})
