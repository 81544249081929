import React from 'react'
import {Base} from '../../../../types/Base'
import Icon from "../../../../components/icon/Icon";
import {cn} from "../../../../utils/classname";
import {getColorClass, isBlackTextColor} from "../../../../utils/colors";

interface Props {
  base: Base
  containerCn?: string
  noName?: boolean
}

export default React.memo(function (props: Props) {
  const {base, noName} = props
  const iconClassName = cn(
    isBlackTextColor(base.color) ? 'b' : '',
    getColorClass(base.color)
  )

  const className = cn('base-link', props.containerCn)

  return (
    <div className={className}>
      <Icon
        className={iconClassName}
        type={base.icon || 'custom'}
        custom={base.name}
      />
      {!noName && <span className={'tc'}>{base.name}</span>}
    </div>
  )
})