import React, { useCallback } from 'react'
import { FormRendererProps } from '../types/FormRenderer'
import { Input } from '../../irv-ui'
import SocialLink from '../utils/SocialLink'
import { processSocialValue } from '../../../utils/social'

const FormSocialRenderer: React.FC<FormRendererProps> = ({
  value,
  readonly,
  className,
  column,
  params,
  onCommit,
  onChange,
}) => {
  const handleBlur = useCallback(
    e => {
      if (onCommit) {
        onCommit(processSocialValue(e.target.value))
      }
    },
    [onCommit],
  )

  const handleChange = useCallback(
    val => {
      if (onChange) {
        onChange(processSocialValue(val))
      }
    },
    [onChange],
  )

  if (readonly) {
    if (!value) {
      return null
    }

    return (
      <div className={className}>
        <SocialLink value={value} asLink={true} type={params.sType} />
      </div>
    )
  }

  return (
    <>
      <Input
        id={column._id}
        className={className}
        value={value}
        onBlur={handleBlur}
        onChange={handleChange}
      />

      {value && (
        <div className={'suffix'}>
          <SocialLink value={value} asLink asIcon type={params.sType} />
        </div>
      )}
    </>
  )
}

export default FormSocialRenderer
