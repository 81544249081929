import { useEffect, useState } from 'react'
import { RendererClassNames__ } from '../definitions'

const getClassName = (
  columnType: any,
  isEdit: boolean,
  extra?: string,
): string => {
  return [
    `irv-spc ${RendererClassNames__[columnType]}`,
    isEdit ? 'edt' : null,
    extra,
  ]
    .filter(Boolean)
    .join(' ')
}

export const useGridRendererClassName = (
  columnType: any,
  isEdit: boolean = false,
  cn?: string,
) => {
  const [className, setClassName] = useState<string>(
    getClassName(columnType, isEdit, cn),
  )

  useEffect(() => {
    setClassName(getClassName(columnType, isEdit, cn))
  }, [columnType, isEdit, cn])

  return className
}
