import React, { useEffect, useState } from "react"
import { weekdaysValueList } from "../../../../../utils/dates"
import "./style.scss"
import { removeItem } from "../../../../../utils/array"
import { RRule } from "rrule"

interface Props {
  rule: RRule
  onChange: (rule: any) => void
}

export default React.memo(function CustomRRule(props: Props) {
  const { rule, onChange } = props
  const ruleOptions = rule.options
  const [byweekday, setByweekday] = useState(ruleOptions.byweekday || [])

  const handleChange = (checked: boolean, day: number) => {
    const selectedDays = checked
      ? Array.from(new Set(byweekday).add(day))
      : removeItem(byweekday, d => d === day)

    onChange({
      ...rule.origOptions,
      byweekday: selectedDays,
    })

    setByweekday(selectedDays)
  }

  useEffect(() => {
    console.log("aaaa")
  }, [rule])

  return (
    <div className="calendar-weekdays">
      {weekdaysValueList().map((day) => (
        <label key={day.key}>
          <input
            type="checkbox"
            checked={byweekday.includes(day.value)}
            onChange={e => handleChange(e.target.checked, day.value)}
          />
          <span className="calendar-weekdays-day">{day.key}</span>
        </label>
      ))}
    </div>
  )
})
