import {Parser} from 'hot-formula-parser'
import {ColumnType} from '../types/ColumnType'

import {isSpecialColumnAndNotLookup, isDateColumn, isTextColumn, getById} from './column'
import {asArray, arrToObject} from './array'
import {isArray, isFloat} from './is'
import {dayjs, format} from './date'

/* eslint no-undef: 0 */

const DEFAULT_DATE_FORMAT = "D MMMM YYYY"

const VARIABLES = {
  LINE_BREAK: {
    VAR: 'LINE_BREAK',
    VALUE: '!__LB__!'
  }
}

// Это реально значение, которое храниться в базе
const getValue = (table, column, row) => {
  if (isSpecialColumnAndNotLookup(column)) {
    if (isDateColumn(column)) {
      const date = dayjs(row[column.type])

      return date.isValid() ? date.toDate() : null
    }

    return row[column.type]
  }

  const rowData = row.data || row

  if (column.type === ColumnType.FORMULA) {
    return applyFormula(column.params["formula"], {
      row, table, column,
    })
  }

  if (column.type === ColumnType.CHECKBOX) {
    return !!rowData[column._id]
  }

  if (rowData[column._id] && isTextColumn(column)) {
    return String(rowData[column._id])
  }

  return rowData[column._id]
}


// Это значение будет использоваться для сортировки, группировки
//
// Для даты - с форматом и прочей хуйней
// Для Lookup, Record - Стринги (через запятую)
// Single, Multiple - Должно вернуть ЗНАЧЕНИЯ, а не _id
// payload - Всякая хуйня: юзеры и т.д
const getViewValue = (table, column, row, payload = {}) => {
  const rowData = row.data || row
  const originalValue = rowData[column._id]
  const params = column.params || {}

  if (payload.users) {
    if (payload.users[0]) {
      payload.users = arrToObject(payload.users, '_id', false)
    }
  }

  // eslint-disable-next-line
  switch (column.type) {
    case ColumnType.TEXT:
    case ColumnType.LONG_TEXT:
    case ColumnType.EMAIL:
    case ColumnType.PHONE:
    case ColumnType.LINK:
      return originalValue || ""

    case ColumnType.FORMULA:
      return applyFormula(column.params["formula"], {
        row, table, column,
      })

    case ColumnType.SINGLE_SELECT:
    case ColumnType.MULTIPLE_SELECT: {
      const optionValues = (column.params.options || []).reduce((result, option) => {
        result[option._id] = option.text
        return result
      }, {})

      const list = (originalValue || [])
        .map(v => optionValues[v])

      if (payload.ignoreListOrder) {
        list.sort((item0, item1) => (item0 || "").localeCompare(item1))
      }

      return list.join(", ")
    }

    case ColumnType.DATE: {
      const date = dayjs(originalValue)

      if (!date.isValid()) {
        return ""
      }

      return format(date, params.format || DEFAULT_DATE_FORMAT, params.timeFormat)
    }

    case ColumnType.CHECKBOX:
      return originalValue === true || originalValue === 1 ? "TRUE" : "FALSE"

    case ColumnType.NUMBER: {
      const { format, precision } = column.params
      const parsed = format === "decimal" ? parseFloat(originalValue) : parseInt(originalValue)

      if (isNaN(parsed) || !isFinite(parsed)) {
        return
      }

      if (format === "decimal") {
        return parsed.toFixed(precision || 1)
      }

      return parsed
    }

    case ColumnType.LINK_TO_RECORD: {
      const items = asArray(originalValue)
        .filter(Boolean)
        .map(l => l.value)

      if (payload.ignoreListOrder) {
        items.sort((item0, item1) => (item0 || "").localeCompare(item1))
      }

      return items.join(", ")
    }

    case ColumnType.COUNT:
      return 0

    case ColumnType.LOOKUP: {
      const result = []
      const lookupColumn = {
        _id: column._id,
        type: params.foreignType,
        params: params.foreignParams,
      }

      asArray(originalValue).forEach(({ value }) => {
        result.push(
          getViewValue(
            table,
            lookupColumn,
            {
              [column._id]: value,
            },
            payload,
          ),
        )
      })

      return result.filter(Boolean).join(", ")
    }

    case ColumnType.ROLLUP:
      return originalValue

    case ColumnType.USER: {
      const users = payload.users || {}

      // учесть удаление пользователя. Он удален, но нарисовать стоит.
      const list = asArray(originalValue)
        .map((userId) => {
          const item = users[userId]
          return item ? (item.fullName || item.firstName) : ''
        })
        .filter(Boolean)

      if (payload.ignoreListOrder) {
        list.sort((item0, item1) => (item0 || "").localeCompare(item1))
      }

      return list.join(", ")
    }

    case ColumnType.CREATION_DATE:
    case ColumnType.MODIFIED_ON:
      const dateValue = column.type === ColumnType.CREATION_DATE
        ? row.createdOn
        : row.modifiedOn

      const date = dayjs(dateValue)

      if (!date.isValid()) {
        return ""
      }

      return format(date, params.format || DEFAULT_DATE_FORMAT, params.timeFormat)

    case ColumnType.CREATOR:
    case ColumnType.MODIFIED_BY: {
      const users = payload.users || {}
      const userId = column.type === ColumnType.CREATOR
        ? row.createdBy
        : row.modifiedBy

      if (!userId) {
        return ""
      }

      const user = users[userId]

      return user ? user.fullName : ""
    }
  }
}

const getSortValue = (table, column, row, payload) => {
  // eslint-disable-next-line
  switch (column.type) {
    // TODO не верно рабоатет. Должно проверять ордер при настройки колонки (порядок сортировать, а не имя)
    case ColumnType.SINGLE_SELECT:
    case ColumnType.MULTIPLE_SELECT:
      return getViewValue(table, column, row, payload)

    case ColumnType.USER:
    case ColumnType.LINK_TO_RECORD:
    case ColumnType.CREATOR:
    case ColumnType.MODIFIED_BY:
      return getViewValue(table, column, row, payload)

    case ColumnType.LOOKUP: {
      const result = []
      const params = column.params || {}
      const originalValue = asArray(row[column._id])
      const lookupColumn = {
        _id: column._id,
        type: params.foreignType,
        params: params.foreignParams,
      }

      asArray(originalValue).forEach(({ value }) => {
        result.push(
          getViewValue(
            table,
            lookupColumn,
            {
              [column._id]: value,
            },
            payload,
          ),
        )
      })

      return result.filter(Boolean).join(", ")
    }

    case ColumnType.CHECKBOX:
      return !!row[column._id]

    case ColumnType.DATE: {
      const date = dayjs(row[column._id])

      if (!date.isValid()) {
        return ""
      }

      return +date
    }

    case ColumnType.CREATION_DATE:
      return +new Date(row.createdOn)

    case ColumnType.MODIFIED_ON:
      return +new Date(row.modifiedOn)
  }

  return getValue(table, column, row)
}

const applyFormula = (formula, payload) => {
  try {
    const parser = new Parser()
    const variables = []
    const varColumns = new Map()
    const parsedFormula = (formula || "").replace(/{([^}]+)}/g, (searchString, colId) => {
      const varName = colId.replace(/\d/g, "")

      variables.push({
        name: varName,
        original: colId,
      })

      return varName
    })

    variables.forEach(variable => {
      const column = getById(payload.table, variable.original)

      if (!column) {
        // it must not BE!
        return
      }

      const value = getValue(
        payload.table,
        column,
        payload.row,
      )

      parser.setVariable(variable.name, value || null)

      if (value) {
        varColumns.set(value, column)
      }
    })

    // AS_TEXT(cellId0, cellId1, ....) - Первый попавшийся
    parser.setFunction("AS_TEXT", (params) => {
      let result = ""

      for (let i = 0; i < params.length; i++) {
        const column = varColumns.get(params[i])

        if (!column) {
          continue
        }

        result = getViewValue(payload.table, column, payload.row, { ...payload, column })

        if (result) {
          break
        }
      }

      return result
    })

    parser.setFunction("HAS", params => {
      const targetArr = asArray(params[0])
      const sourceArr = asArray(params[1])

      return targetArr.some(item => sourceArr.includes(item))
    })

    parser.setFunction("ARRAY", params => {
      return params
    })

    parser.setFunction("INT", params => {
      return (params[0] + "").replace(/\D/g, "")
    })

    parser.setFunction("LEN", params => {
      return (params[0] || []).length
    })

    parser.setFunction("DATETIME_FORMAT", params => {
      return dayjs(params[0]).format(params[1]).toString()
    })

    parser.setFunction("ADD_DATE", params => {
      const date = dayjs(params[0])

      if (!date.isValid()) {
        return null
      }

      const addedDate = date.add(params[1], params[2])

      if (addedDate.isValid()) {
        return addedDate.toDate()
      }
    })

    parser.setFunction("INDEX_OF", params => {
      const args = payload.args
      const value = params[0]

      if (!args || !value) {
        return ""
      }

      const index = asArray(value).findIndex(i => i.value === args.indexOfItem)

      if (index >= 0) {
        return index + 1 + ""
      }

      return ""
    })

    parser.setFunction("JOIN", params => {
      const targetArrays = params.slice(0, params.length - 1)
      let possibleSeparator = params[params.length - 1]

      if (isArray(possibleSeparator)) {
        targetArrays.push(possibleSeparator)
        possibleSeparator = ""
      }

      if (!targetArrays[0] || !isArray(targetArrays[0])) {
        return ""
      }

      const targetMaps = targetArrays.map(target => arrToObject(target, "_id", false))
      const finalResult = []

      targetArrays[0].forEach(({ _id }) => {
        const resItem = targetMaps.map(target => {
          const el = target[_id]

          if (!el) {
            return ""
          }

          return el.value || ""
        })

        finalResult.push(resItem.join(possibleSeparator))
      })

      return finalResult.join(", ")
    })

    parser.setFunction("CONCAT_ALL", params => {
      const items = params[0]

      let separator = params[1] || ""

      if (isArray(separator)) {
        separator = separator.map(s => s.value || s).join(",")
      }

      return items
        .map(item => {
          const value = item.value || item
          return value + ("" + separator)
        })
        .join(", ")
    })

    parser.setFunction('cell', params => {
      const column = getById(payload.table, params[0])

      if (!column) {
        return null
      }

      return getViewValue(payload.table, column, payload.row, { ...payload, column })
    })

    parser.setFunction('concat', params => {
      return params.join('')
    })

    parser.setVariable(VARIABLES.LINE_BREAK.VAR, VARIABLES.LINE_BREAK.VALUE)

    const result = parser.parse(parsedFormula)

    if (result.error) {
      return result.error
    }

    if (isFloat(result.result)) {
      return result.result.toFixed(2)
    }

    return result.result
  } catch (e) {
    console.log(e)
    return ""
  }
}

// Типа разные там штуки
// NOW(), TODAY() etc
// @ts-ignore
const getSpecificValue = (val, row, payload) => {
  switch (val) {
    case "NOW()":
      return new Date()

    default:
      return val
  }
}

export {getValue}
export {getViewValue}
export {getSortValue}
export {getSpecificValue}
export {applyFormula}
