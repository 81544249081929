import {FormOptions} from "./FormOptions";
import { ItemTitleBadge, ViewGroups } from "./BaseView"

export enum AddonType {
  WIDGET = 'widget',
  LIST = 'list',
  TIMELINE = 'timeline'
}

export interface AddonParams {
  type: WidgetType
  tableId: string
  viewId: string
  success?: string
}

export interface Addon {
  _id: string
  type: AddonType
  name: string
  titleBadge?: ItemTitleBadge[]
  forms?: AddonForm[]
  params: AddonParams
  icon: string
  color: string
}

interface BaseAddonForm {
  _id: string
  tableId?: string
  viewId?: string
  name: string
  btnText?: string
  helpText?: string
  logo?: string
  coverImg?: string
  order?: { [key: string]: number }
  groups?: ViewGroups[]
  formOptions?: { [key: string]: FormOptions }
  columnsParams?: { [key: string]: AddonFormColumnsParams }
}

export type ExtraAddonForm = BaseAddonForm & {
  linkedTo?: { formId: string, foreignId: string, columnId: string }[]
}

export type AddonForm = BaseAddonForm & {
  success?: string
  submitText?: string
  extras?: ExtraAddonForm[]
  forms?: AddonForm[]
}

export interface AddonFormColumnsParams {
  name: string
  default: any
  descr?: string
  required?: boolean
  viewId?: string
  hidden?: boolean
  visibleOptions?: string[]
}

export enum WidgetType {
  FORM = 'form'
}
