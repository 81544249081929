import React, { useState } from 'react'
import { Input } from '../../../../../../components/irv-ui'
import { ColumnSettingsProps } from '../types'
import {
  getColumnParamsItem,
  mergeColumnParams,
} from '../../../../../../utils/get'
import {
  ContextMenuItem,
  getMenuIcon,
} from '../../../../../../components/dropdown/DropdownMenu'
import { ViewActions } from '../../../../../../store/actions/ViewActions'
import { TableActions } from '../../../../../../store/actions/TableActions'
import Menu from 'antd/lib/menu'
import ColumnTypeSelector from './ColumnTypeSelector'
import GeneralColumnSettings from './GeneralColumnSettings'
import { GridColumnParams } from '../../../../../../components/grid/types/GridColumn'
import ViewColumnSettings from './ViewColumnSettings'
import { ViewSettingsUIStateItem } from '../../../../types/UIState'
import { ColumnType } from '../../../../../../general/types/ColumnType'
import { NON_FLIGHT_TYPES } from '../../../../../../utils/column'

const getMenuItems = (hidden: boolean): ContextMenuItem[] => {
  return [
    hidden
      ? { key: ViewActions.SHOW_COLUMN, text: 'Показать' }
      : { key: ViewActions.HIDE_COLUMN, text: 'Спрятать' },
    { key: TableActions.DUPLICATE_VIEW, text: 'Создать копию' },
    { key: TableActions.REMOVE_COLUMN, text: 'Удалить' },
  ]
}

export default React.memo(function EditColumnContainer(
  props: ColumnSettingsProps,
) {
  const { view, table, column, onAction, onPageChange } = props
  const [name, setName] = useState(column.name)
  const [typedColumn, setTypedColumn] = useState({ ...column })

  const handleSetName = (value: string) => setName(value)

  const saveName = () => {
    if (name.trim() && name !== column.name) {
      onAction(TableActions.UPDATE_COLUMN, {
        columnId: column._id,
        name,
      })
    }
  }

  const handleChangeType = type => {
    const params: any = {}

    setTypedColumn({
      ...typedColumn,
      type,
    })

    // Обновим уже потом, вместе с первыми параметрами
    // if (NON_FLIGHT_TYPES.includes(type)) {
    //   return
    // }

    if ([ColumnType.SINGLE_SELECT, ColumnType.MULTIPLE_SELECT].includes(type)) {
      if (column.params.options && column.params.options.length) {
        params.options = column.params.options
      }
    }

    onAction(TableActions.UPDATE_COLUMN, {
      columnId: column._id,
      params,
      type,
    })
  }

  const handleAction = ({ key }) => {
    onAction(key, {
      columnId: column._id,
    })

    if (key === TableActions.REMOVE_COLUMN) {
      onPageChange({
        type: ViewSettingsUIStateItem.GENERAL,
      })
    }
  }

  const handleChangeColumn = (params: GridColumnParams) => {
    const payload: any = {
      columnId: column._id,
      params,
    }

    if (NON_FLIGHT_TYPES.includes(typedColumn.type)) {
      payload.type = typedColumn.type
    }

    return onAction(TableActions.UPDATE_COLUMN, payload)
  }

  const handleChangeViewColumn = (params: GridColumnParams) => {
    return onAction(TableActions.UPDATE_VIEW_COLUMN, {
      columnId: column._id,
      params,
    })
  }

  const viewParams = mergeColumnParams(column, view)

  return (
    <div className="column-settings" key={column._id}>
      <Input
        fit
        autoFocus
        className={'din'}
        defaultValue={name}
        onChange={handleSetName}
        onPressEnter={saveName}
        onBlur={saveName}
      />
      <ColumnTypeSelector type={column.type} onChange={handleChangeType} />

      <GeneralColumnSettings
        table={table}
        column={column}
        params={viewParams}
        onChange={handleChangeColumn}
      />

      <ViewColumnSettings
        table={table}
        column={column}
        params={viewParams}
        onChange={handleChangeViewColumn}
      />

      <div className={'cm'}>
        <Menu selectedKeys={[]} onSelect={handleAction}>
          {getMenuItems(
            getColumnParamsItem(view, column._id, 'hidden') === true,
          ).map(menu => (
            <Menu.Item key={menu.key}>
              {getMenuIcon(menu.key)}
              {menu.text}
            </Menu.Item>
          ))}
        </Menu>
      </div>
    </div>
  )
})
