import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Input } from '../../../components/irv-ui'
import {
  ActionType,
  disturbEntity,
  DisturbEntityArguments,
} from '../../../store/CommonEffects'
import { bindActionCreators } from 'redux'
import ComplexMenu from '../../dropdown/ComplexMenu'
import { EventKey, onKeyHandler } from '../../../utils/dom'
import { BaseActions } from '../../../store/actions/BaseActions'
import { AppActions } from '../../../store/actions/AppActions'
import { BaseTable } from '../../../types/BaseTable'
import { TableActions } from '../../../store/actions/TableActions'

type Props = ReduxProps & {
  children: React.ReactNode
  table: BaseTable
  onMenuClick: (action: ActionType, payload?: any) => void
}

const menuItems = [
  { text: 'В закладки', key: AppActions.FAVORITE },
  { text: 'Скопировать ссылку', key: AppActions.COPY_LINK },
  { text: 'Создать копию', key: BaseActions.DUPLICATE },
  { text: 'Переместить', key: BaseActions.MOVE },
  { text: 'Удалить', key: BaseActions.REMOVE_TABLE },
]

function TableContextMenu(props: Props) {
  const { children, table, disturbEntity, onMenuClick } = props
  const [entityName, setEntityName] = useState(table.name)
  const [visible, setMenuVisibility] = useState(false)

  let keyHandlerState: null | (() => void)

  useEffect(() => {
    setEntityName(table.name)
  }, [table])

  const releaseKeyHandler = () => {
    if (keyHandlerState) {
      keyHandlerState()
    }

    keyHandlerState = null
  }

  const handleClickMenu = ({ key }) => {
    setMenuVisibility(false)
    onMenuClick(key as ActionType, table)
  }

  const handleChangeName = name => setEntityName(name)
  const saveName = () => {
    if (entityName.trim()) {
      if (entityName !== table.name) {
        disturbEntity({
          entity: TableActions.UPDATE,
          id: table._id,
          payload: { name: entityName },
        }).then(_ => setMenuVisibility(false))
      }
    } else {
      cancelEditing()
      setEntityName(table.name)
    }
  }

  const onVisibleChange = v => {
    setMenuVisibility(v)

    if (v) {
      keyHandlerState = onKeyHandler([EventKey.ESC], () => {
        cancelEditing()
        setEntityName(table.name)
        releaseKeyHandler()
      })
    } else {
      releaseKeyHandler()
    }
  }

  const cancelEditing = () => {
    setMenuVisibility(false)
  }

  const header = (
    <Input
      fit
      autoFocus
      value={entityName}
      className={'din'}
      onChange={handleChangeName}
      onPressEnter={saveName}
      onBlur={saveName}
    />
  )

  return (
    <ComplexMenu
      menu={menuItems}
      headerTitle={'Настройка таблицы'}
      header={header}
      overlayClassName={'sb-smenu'}
      visible={visible}
      onClick={handleClickMenu}
      onVisibleChange={onVisibleChange}>
      {children}
    </ComplexMenu>
  )
}

type ReduxDispatchProps = {
  disturbEntity: (args: DisturbEntityArguments) => Promise<any>
}

type ReduxProps = ReduxDispatchProps

const mapDispatchToProps = dispatch =>
  bindActionCreators<any, ReduxDispatchProps>(
    {
      disturbEntity,
    },
    dispatch,
  )

export default connect<any, ReduxDispatchProps>(
  null,
  mapDispatchToProps,
)(TableContextMenu)
