import React from "react"
import "./style.scss"

const NotFoundPage = () => {
  return (
    <div className="not-found">
      <>
        <p>Извините, страница, которую вы искали, не была найдена.</p>
        <p>
          <a href={"/"}>Вернуться домой</a>
        </p>
        <img src='/svg/robot_404.svg' alt={""} width={400} />
      </>
    </div>
  )
}

export default NotFoundPage
