import React from "react";
import {BaseRendererProps} from "../BaseRendererProps";

export default React.memo(function BaseLongTextRenderer(props: BaseRendererProps<string>) {
  if (props.readonly) {
    return <div className={'re-value'}>{props.value || ''}</div>
  }

  return (
    <textarea
      className="re-editor eyzy-textarea eyzy-textarea-fit"
      style={{height: '100px'}}
      value={props.value}
      onChange={(e) => props.onChange(e.target.value)}
    />
  )
})
