import React from 'react'
import {FilterRendererProps} from "../FilterRendererFactory";
import Select from "antd/es/select";
import {ColumnType} from "../../grid/types/ColumnType";
import {FilterOperators} from "../../../general/types/FilterOperators";
import {debounce} from "../../../utils/common";
import {asArray} from "../../../general/utils/array";

const isMultiple = (type, op): boolean => {
  if (type === ColumnType.MULTIPLE_SELECT) {
    return true
  }

  return [
    FilterOperators.IS_ANY_OF,
    FilterOperators.IS_NONE_OF
  ].includes(op)
}

export default React.memo(function ListFilterRenderer(props: FilterRendererProps) {
  const {column, filter, onChange} = props
  const handleChange = debounce((value) => {
    onChange({value: asArray(value)})
  }, 700)

  const options = (column.params.options || []).map(o => (
    <Select.Option value={o._id} key={o._id}>{o.text}</Select.Option>
  ))

  // @ts-ignore
  const multiple = isMultiple(column.type, filter.op)

  let value = filter.value
  let mode: any = multiple ? 'multiple' : undefined

  if (multiple) {
    value = asArray(value)
  } else if (value && value[0]) {
    value = value[0]
  }

  return (
    <Select value={value}
            filterOption={true}
            mode={mode}
            optionFilterProp={'label'}
            onChange={handleChange}
            getPopupContainer={trigger => trigger.parentNode}
            dropdownMatchSelectWidth={false}
    >
      {options}
    </Select>
  )
})
