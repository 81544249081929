import React from "react";
import {BaseRendererProps} from "../BaseRendererProps";

export default class BaseCountRenderer extends React.PureComponent<BaseRendererProps<number>> {
  render() {
    const {value} = this.props;

    return isFinite(value) ? value : 0
  }
}
