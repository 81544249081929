import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import { RendererClassNames } from '../definitions'
import { GridCellRendererProps } from '../types/GridRenderer'
import { getValue } from '../../../general/utils/value'
import { EyzyUser } from '../../../types/EyzyUser'
import { isMob, isUserRemoved } from '../../../utils/is'
import { asArray } from '../../../utils/as'
import UserBadge from '../../__renderers/user/UserBadge'
import { isEmpty } from '../../../general/utils/is'
import BaseUserRenderer from '../base/UserRenderer'
import { GridColumnOptionItem } from '../../grid/types/GridColumn'
import { Select } from '../../irv-ui'
import UserSelectOption from '../../user/UserSelectOption'

const GridUserRenderer: React.FC<GridCellRendererProps> = ({
  column,
  active,
  payload,
  row,
}) => {
  const [value, setValue] = useState(
    asArray(getValue(payload.table, column, row)),
  )

  const { multiple } = column.params
  const { ws, onChange } = payload

  const handleChange = useCallback(
    value => {
      let updatedValue = multiple ? value : [value]

      if (updatedValue[0] === null) {
        updatedValue = null
      }

      onChange(row._id, column, updatedValue)
      setValue(updatedValue)
    },
    [multiple, column, row, value, onChange],
  )

  const userOptions: Array<EyzyUser | GridColumnOptionItem> = useMemo(() => {
    // Собрать все пользователей, но не забыть юзеров, которые были удалениы, но еще есть в значении
    const wsCollaborators: any[] = ws.collaborators
      .filter(user => {
        if (isUserRemoved(user)) {
          return asArray(value).includes(user._id)
        }

        return user
      })
      .map(o => ({
        ...o,
        value: o._id,
      }))

    if (multiple) {
      return wsCollaborators
    }

    return [
      { _id: 'null', text: ' ', value: null } as GridColumnOptionItem,
    ].concat(wsCollaborators)
  }, [ws, value, multiple])

  const tagRender = useCallback(
    ({ item, onRemove }) => {
      return <UserBadge user={item} onClose={multiple ? onRemove : null} />
    },
    [multiple, value],
  )

  useEffect(() => {
    setValue(getValue(payload.table, column, row))
  }, [payload.table, column, row])

  if (!active) {
    if (isEmpty(value)) {
      return null
    }

    return (
      <BaseUserRenderer
        value={value}
        table={payload.table}
        column={column}
        payload={{ users: ws.collaborators }}
      />
    )
  }

  return (
    <Select
      showSearch={!isMob()}
      searchKeys={['fullName', 'email']}
      value={value}
      valueRenderer={tagRender}
      items={userOptions}
      quiet
      className={RendererClassNames.USER}
      dropdownClassName="irv-ow"
      onSelect={handleChange}
      mode={multiple ? 'multiple' : undefined}>
      {user => (
        <UserSelectOption
          user={user}
          useColor={column.params.useColor !== false}
        />
      )}
    </Select>
  )
}

export default memo(GridUserRenderer)
