import React, { useMemo } from 'react'
import { GridRow } from '../grid/types/GridRow'
import { BaseTable } from '../../types/BaseTable'
import { ViewLike } from '../../types/BaseView'
import {
  currentUser,
  getColumnParamsItem,
  mergeColumnWithViewParams,
} from '../../utils/get'
import { getTableColorsMap } from '../../utils/colors'
import { prepareColumns } from '../../utils/column'
import { GridColumn } from '../grid/types/GridColumn'
import { getValue } from '../../general/utils/value'
import { isMob } from '../../utils/is'
import { Table } from '../irv-ui'
import BtnWithIcon from '../btn/BtnWithIcon'
import { TableColumn } from '../irv-ui/components/table/types/Table'
import { TableTheme } from '../irv-ui/components/table/types/TableTheme'
import { SimpleRendererFactory } from '../_renderers/factory'
import { FormProvider, useFormPayload } from '../_renderers/RendererContext'

interface Props {
  showCheckbox?: boolean
  selected?: string[]
  view?: ViewLike
  rows: GridRow[]
  table: BaseTable
  readonly?: boolean
  onClick?: (rowId: string) => void
  onRemove?: (rowId: string) => void
}

const LinkedEntriesList: React.FC<Props> = ({
  rows,
  table,
  view,
  readonly,
  onRemove,
}) => {
  const formPayload = useFormPayload()
  const colorsMap = useMemo(() => {
    if (view) {
      return getTableColorsMap(rows, table, view, currentUser())
    }
  }, [table, view, rows])

  const columns = useMemo<TableColumn[]>(() => {
    const cols: TableColumn[] = prepareColumns(table, view).map(
      (column: GridColumn) => ({
        _id: column._id,
        title: column.name,
        width: getColumnParamsItem(view, column._id, 'width'),
        render: (row: GridRow) => (
          <FormProvider.Provider value={{ ...formPayload, row, table }}>
            <SimpleRendererFactory
              value={getValue(table, column, row)}
              column={column}
              params={mergeColumnWithViewParams(view, column)}
            />
          </FormProvider.Provider>
        ),
      }),
    )

    if (!readonly) {
      cols.push({
        _id: 'rm',
        width: 50,
        align: 'center',
        render: row => {
          return (
            <BtnWithIcon
              cn={'rm'}
              icon={'b-cross-circle'}
              onClick={() => onRemove(row._id)}
            />
          )
        },
      })
    }

    return cols
  }, [table, view, onRemove, readonly])

  return (
    <div className={'linked-entries-list'}>
      {rows.length === 0 && <p className={'no-records'}>Записи не найдены</p>}

      <Table
        pin={isMob() ? 0 : 1}
        pinRight={readonly ? 0 : 1}
        theme={[TableTheme.MAIN]}
        size={'s'}
        columns={columns}
        data={rows}
        layout={'fixed'}
      />
    </div>
  )
}

export default React.memo(LinkedEntriesList)
