import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import { getValue } from '../../../general/utils/value'
import { RendererClassNames } from '../definitions'
import { GridCellRendererProps } from '../types/GridRenderer'
import { GridColumnOptionItem } from '../../grid/types/GridColumn'
import { isMob } from '../../../utils/is'
import SelectRenderer from '../base/SelectRenderer'
import Tag from '../../shape/Tag'
import { arrToObj } from '../../../utils/array'
import { Select } from '../../irv-ui'
import { asArray } from '../../../utils/as'

const GridSingleSelectRenderer: React.FC<GridCellRendererProps> = ({
  column,
  active,
  payload,
  row,
}) => {
  const [value, setValue] = useState(
    asArray(getValue(payload.table, column, row) || []),
  )
  const params = column.params
  const { selectOptions, selectOptionsMap } = useMemo(() => {
    const params = column.params
    const options = params.options || []

    const possibleOptions = params.visibleOptions
      ? options.filter(opt => params.visibleOptions.includes(opt._id))
      : options

    const selectOptions = [
      { _id: 'null', text: ' ', value: null } as GridColumnOptionItem,
    ]
      .concat(possibleOptions)
      .filter(opt => asArray(value).includes(opt._id) || !opt.removedOn)
      .map(o => ({
        ...o,
        value: o._id,
      }))

    return {
      selectOptions,
      selectOptionsMap: arrToObj(selectOptions, '_id', false),
    }
  }, [column, value])

  const onSelectChange = val => {
    const value = 'null' === val ? null : [val]
    payload.onChange(row._id, column, value)

    // || [value] - если меняем на ПУСТО - все нахуй падает
    setValue(value || [value])
  }

  const TagRenderer = useCallback(
    ({ item }) => {
      return (
        <Tag
          key={item.value}
          color={params.useColor ? item.color : ''}
          removed={!!item.removedOn}>
          {item.text}
        </Tag>
      )
    },
    [payload, params, selectOptionsMap],
  )

  useEffect(() => {
    setValue(getValue(payload.table, column, row))
  }, [payload.table, column, row])

  if (payload.readonly || !active) {
    return (
      <SelectRenderer table={payload.table} column={column} value={value} />
    )
  }

  return (
    <Select
      showSearch={!isMob()}
      value={value}
      valueRenderer={TagRenderer}
      items={selectOptions}
      quiet
      className={RendererClassNames.SELECT}
      onSelect={onSelectChange}
      dropdownClassName="irv-ow">
      {option => (
        <div className={'irv-bitem'}>
          {option._id === 'null' ? (
            <span />
          ) : (
            <Tag
              color={params.useColor ? option.color : ''}
              removed={!!option.removedOn}>
              {option.text}
            </Tag>
          )}
        </div>
      )}
    </Select>
  )
}

export default memo(GridSingleSelectRenderer)
