import React from 'react'
import {EyzyUser} from "../../../types/EyzyUser";
import {getStoreUsers} from "../../../utils/get";
import Avatar from "../../user/Avatar";

interface Props {
  users: string[]
  withOwner?: boolean
  onSelect?: (userId: string) => void
  dimens?: number
}

export default React.memo(function CollaboratorList(props: Props) {
  const { withOwner, users, dimens } = props

  if (!props.users.length) {
    return null
  }

  const collaborators: EyzyUser[] = getStoreUsers().filter(user => {
    if (users.includes(user._id)) {
      return true
    }

    return withOwner === true
  })

  return (
    <div className='collaborator-list'>
      {collaborators.map(user => (
        <Avatar user={user} dimens={dimens} />
      ))}
    </div>
  )
})