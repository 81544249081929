import { BaseTable } from '../../../types/BaseTable'
import { BaseFilter } from '../../filter/types/Filter'
import { BasicOperators } from '../../../types/BasicOperators'
import { SocialTypes } from '../../../types/SocialNetworks'
import { EyzyUser } from "../../../types/EyzyUser"

export interface GridColumnActionItem {
  type: BasicOperators
  col: string
  value?: any
}

export interface GridColumnAction {
  _id: string
  btn: {
    text: string
    type?: string // ???
    bg?: string
  }
  actions: GridColumnActionItem[]
  visibility?: BaseFilter
}

export interface GridColumnOptionItem {
  _id: string
  text: string | null
  color?: string
  removedOn?: Date
}

export enum GridColumnTagType {
  TAG = 'tag',
  FIT = 'fit',
  BAR = 'bar',
  TEXT = 'text',
}

// В основном для формулы как отображать label
// В примерах будет 2 числа: 10 и 100
export enum GridColumnLabelType {
  VALUE = 'value', // 10
  DIFF = 'diff', // 90
  OP = 'op', // 10/100
  OP_REVERSE = 'opr', // 90/100
  PERCENT = 'percent', // 10%
}

export interface GridColumnParamsRollup {
  foreignId: string
  columnId: string
  formula: string
}

export interface GridColumnParams {
  visibleOptions?: string[]
  options?: GridColumnOptionItem[]
  defaultValue?: any
  tableId?: string
  columnId?: string
  foreignId?: string
  foreignType?: any
  foreignParams?: GridColumnParams
  viewId?: string
  template?: string
  multiple?: boolean
  formula?: string
  linkColumnId?: string
  fly?: boolean
  sType?: SocialTypes
  divideBy?: string | number
  // TODO remove
  showTime?: string
  timeFormat?: string
  format?: string
  precision?: number
  negative?: boolean
  specialColumn?: any
  viewAs?: GridColumnTagType
  labelType?: GridColumnLabelType
  useColor?: boolean
  actions?: GridColumnAction[]

  // Берем из вью
  width?: number
}

export interface GridColumn {
  _id: string
  field: string
  name?: string
  header: any
  renderer?: any
  width?: number
  resizer?: boolean
  editor?: any
  type: any
  params: GridColumnParams
  hidden?: boolean
  selectable?: boolean
  // Additional props
  table: BaseTable
}
