import React from 'react'
import {cn} from "../../utils/classname";
import {getColorClass} from "../../utils/colors";
import { Tag } from '../../components/irv-ui'

interface Props {
  color?: string
  removed?: boolean
  onRemove?: () => void
  children?: React.ReactNode
}

export default React.memo(function(props: React.PropsWithChildren<Props>) {
  const className = cn(
    'e-tag',
    'eyzy-tag',
    props.color ? getColorClass(props.color) : '',
    props.removed && 'removed'
  )

  return (
    <Tag onRemove={props.onRemove} className={className}>{props['children']}</Tag>
  )
})
