import React from 'react'
import { ViewDateField } from '../../../../../types/BaseView'
import { Input } from '../../../../../components/irv-ui'
import DateTimeRangePicker from '../dateTimeRangePicker/DateTimeRangePicker'
import ColumnNameWithIcon from '../../../../../components/columnHelpers/ColumnNameWithIcon'
import EventDetailsRepeat from './EventDetailsRepeat'
import {
  Event,
  EventDate,
} from '../../../../../components/calendar/NEW__types/Event'
import { dayjs } from '../../../../../general/utils/date'
import { CalendarViewType } from '../../../../../components/calendar/NEW__types/Calendar'
import { RRule } from 'rrule'
import { isMob } from '../../../../../utils/is'
import { isTextColumn } from '../../../../../general/utils/column'

interface Props {
  event: Event
  dateFields: ViewDateField
  view: CalendarViewType
  onChange: (value: any) => void
}

export default React.memo(function EventDetails(props: Props) {
  const { onChange, dateFields, event, view } = props
  const { start, end, title, settings } = dateFields

  const startDate = dayjs(event.start).toDate()
  const endDate = end ? dayjs(event.end).toDate() : null

  // const handleTitleBlur = e => {
  //   if (e.target.value !== event.title) {
  //     onChange({title: e.target.value}, true)
  //   }
  // }

  const handleNameChange = e => {
    onChange({ title: e.target.value })
  }

  const handleRepeatChange = (rrule: RRule) => {
    if (settings) {
      onChange({
        rrule,
      })
    }
  }

  const handleChangeRange = (dates: EventDate) => {
    onChange({ ...dates })
  }

  return (
    <div className={'event-details'}>
      <div className={'form-item'}>
        <div className={'form-item-label'}>
          <ColumnNameWithIcon column={title} />
        </div>
        <div className="form-item-ctrl">
          <Input
            fit
            autoFocus={!isMob()}
            value={event.title}
            disabled={!isTextColumn(title)}
            onChange={handleNameChange}
          />
          {/*<Input*/}
          {/*  fit*/}
          {/*  autoFocus*/}
          {/*  value={event.title}*/}
          {/*  onChange={handleNameChange}*/}
          {/*  onBlur={handleTitleBlur}*/}
          {/*  onPressEnter={handleTitleBlur}*/}
          {/*/>*/}
        </div>
      </div>

      <DateTimeRangePicker
        onChange={handleChangeRange}
        startColumn={start}
        start={startDate}
        end={endDate}
        endColumn={end}
        view={view}
      />

      {settings && (
        <EventDetailsRepeat event={event} onChange={handleRepeatChange} />
      )}
    </div>
  )
})
