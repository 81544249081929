import React from 'react'
import HelloTopbar from './components/topbar/HelloTopbar'
import HelloContainer from "./components/container/HelloContainer"

import './style.scss'

export default function Hello() {
  return (
    <div className="hello">
      <HelloTopbar/>
      <HelloContainer/>
    </div>
  )
}