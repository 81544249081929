import React from 'react'
import { TooltipProps } from 'antd/lib/tooltip'
import { ContextMenuItem, getMenuIcon } from './DropdownMenu'
import Menu from 'antd/lib/menu'
import { cn } from '../../utils/classname'
import ResponsiveTooltip from '../responsive/ResponsiveTooltip'

import './complexMenu.scss'

type Props = Partial<TooltipProps> & {
  children: any
  header?: React.ReactNode
  headerTitle?: string
  footer?: React.ReactNode
  menu?: ContextMenuItem[]
  onClick?: (info: any) => void
  isMHeader?: boolean // для заголовка где будет МЕНЮ (типа для колонок)
}

export default React.memo(function ComplexMenu(props: Props) {
  const {
    overlayClassName,
    placement,
    footer,
    header,
    headerTitle,
    menu,
    visible,
    isMHeader,
    onVisibleChange,
    onClick
  } = props

  const tooltipTitle = (
    <div className={'cm-content'}>
      {(header || headerTitle) && (
        <div className={`cm-${isMHeader ? 'm' : ''}header`}>
          {headerTitle && (
            <div className={'cm-header-title'}>{headerTitle}</div>
          )}
          {header}
        </div>
      )}

      {menu && (
        <Menu onClick={onClick}>
          {menu.map((item, i) => item.divider ? (
            <Menu.Divider key={i} />
          ) : (
            <Menu.Item key={item.key} className={item.className}>
              {getMenuIcon(item.key)}
              {item.text}
            </Menu.Item>
          ))}
        </Menu>
      )}

      {footer && (
        <div className={'cm-footer'}>{footer}</div>
      )}
    </div>
  )


  return (
    <ResponsiveTooltip
      title={tooltipTitle}
      trigger={['click']}
      placement={placement || 'bottomLeft'}
      overlayClassName={cn('cm', overlayClassName)}
      visible={visible}
      onVisibleChange={onVisibleChange}
    >
      {props.children}
    </ResponsiveTooltip>
  )
})
