import React, { useMemo } from 'react'
import { FormRendererProps } from '../types/FormRenderer'
import { format } from '../../../general/utils/date'
import { ColumnType } from "../../../general/types/ColumnType"

const FormSpecialDateRenderer: React.FC<FormRendererProps> = ({
  value,
  className,
  params,
  column,
}) => {
  const formattedValue = useMemo(() => {
    const columnParams = { ...params, ...column.params }

    if (!value) {
      if (column.type === ColumnType.CREATION_DATE) {
        return format(new Date(), columnParams.format, columnParams.timeFormat, true)
      }

      return ''
    }

    return format(value, columnParams.format, columnParams.timeFormat, true)
  }, [value, params, column])

  return <div className={className}>{formattedValue}</div>
}

export default FormSpecialDateRenderer
