import React, { useState } from 'react'
import { Button } from '../../../components/irv-ui'
import { GridCellRendererProps } from '../types/GridRenderer'
import { currentUser, getColumnById } from '../../../utils/get'
import { GridColumn, GridColumnAction } from '../../grid/types/GridColumn'
import { compileActionValue, getVisibleAction } from '../../../utils/grid'
import { runAction } from '../../../store/CommonEffects'
import { TableActions } from '../../../store/actions/TableActions'
import { RendererClassNames } from "../definitions"

const GridActionRenderer: React.FC<GridCellRendererProps> = ({
  active,
  column,
  row,
  payload,
}) => {
  const [loading, setLoading] = useState(false)
  const actions = column.params.actions || []
  const table = payload.table

  const user = currentUser()
  const visibleAction: GridColumnAction | undefined = getVisibleAction(
    actions,
    user,
    table,
    row,
  )

  if (!visibleAction) {
    return null
  }

  const { btn } = visibleAction
  const actionClassName: string = btn.bg ? `c-btn color-${btn.bg}` : ''

  const handleBtnClick = () => {
    setLoading(true)

    const data = {}

    visibleAction.actions.forEach(action => {
      const column: GridColumn | undefined = getColumnById(table, action.col)

      if (column) {
        data[action.col] = compileActionValue(action, row, {
          table: table,
          user,
          column,
        })
      }
    })

    runAction(TableActions.CALL_ACTION, table._id, {
      rowId: row._id,
      aId: visibleAction._id,
      data,
    }).then(_ => {
      setLoading(false)
    })

    setTimeout(_ => {
      if (loading) setLoading(false)
    }, 500)
  }

  return (
    <div className={RendererClassNames.ACTION}>
      <Button
        disabled={loading}
        autoFocus={active}
        className={actionClassName}
        onClick={handleBtnClick}>
        {btn.text}
      </Button>
    </div>
  )
}

export default React.memo(GridActionRenderer)
