import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Input } from "../../../components/irv-ui"
import { ActionType, disturbEntity, DisturbEntityArguments } from "../../../store/CommonEffects"
import { bindActionCreators } from "redux"
import ComplexMenu from "../../dropdown/ComplexMenu"
import { EventKey, onKeyHandler } from "../../../utils/dom"
import { BaseActions } from "../../../store/actions/BaseActions"
import { Base } from "../../../types/Base"
import Icon from "../../icon/Icon"
import Tooltip from "antd/lib/tooltip"
import { getColorClass, getTextColorByClass, isBlackTextColor } from "../../../utils/colors"
import { icons } from "../../../general/data/icons"
import { colorPalette } from "../../../general/data/colors"
import NEWIcon from "../../icon/NEWIcon"

type Props = ReduxProps & {
  children: React.ReactNode
  base: Base
  onMenuClick: (action: ActionType) => void
  hideCreateTable?: boolean
}

const menuItems = [
  { text: 'Создать таблицу', key: BaseActions.CREATE_TABLE, bold: true },
  { text: 'Настройка доступа', key: BaseActions.PERMISSIONS_SETTING },
  // {text: 'Создать копию', key: BaseActions.DUPLICATE},
  // {text: 'Переместить', key: BaseActions.MOVE},
  { text: 'Удалить', key: BaseActions.REMOVE },
]

const alignConfig = {
  offset: [10, 15],
}

const getColorShapeClass = (color, colorValue) => {
  return [
    color.v === colorValue ? `active ${color.c}` : color.c,
    isBlackTextColor(colorValue) ? 'b' : '',
  ].join(' ')
}

function getStyledIcon(
  isSelected: boolean,
  color: string,
  icon: string,
  name: string,
) {
  const iconColor: any = isSelected ? getTextColorByClass(color) : undefined

  return (
    <span
      className={!isSelected ? null : getColorClass(color)}
      key={icon}
      data-icon={icon}>
      <Icon
        type={icon}
        style={iconColor}
        custom={icon === 'custom' ? name : undefined}
      />
    </span>
  )
}

function BaseContextMenu(props: Props) {
  const { children, base, hideCreateTable, disturbEntity, onMenuClick } = props
  const [entityName, setEntityName] = useState(base.name)
  const [visible, setMenuVisibility] = useState(false)

  let keyHandlerState: null | (() => void)

  useEffect(() => {
    setEntityName(base.name)
  }, [base])

  const releaseKeyHandler = () => {
    if (keyHandlerState) {
      keyHandlerState()
    }

    keyHandlerState = null
  }

  const handleClickMenu = ({ key }) => {
    setMenuVisibility(false)
    onMenuClick(key as ActionType)
  }

  const handleColorClick = e => {
    const color = e.target.dataset.color

    if (color && color !== base.color) {
      disturbEntity({
        entity: BaseActions.UPDATE,
        id: base._id,
        payload: { color },
      })
    }
  }

  const handleIconClick = e => {
    let icon = e.target.dataset.icon

    if (!icon) {
      icon = e.target.parentElement.dataset.icon
    }

    if (icon && icon !== base.icon) {
      disturbEntity({
        entity: BaseActions.UPDATE,
        id: base._id,
        payload: { icon },
      })
    }
  }

  const handleChangeName = name => setEntityName(name)
  const saveName = () => {
    if (entityName.trim()) {
      if (entityName !== base.name) {
        disturbEntity({
          entity: BaseActions.UPDATE,
          id: base._id,
          payload: { name: entityName },
        })
      }
    } else {
      cancelEditing()
      setEntityName(base.name)
    }
  }

  const onVisibleChange = v => {
    setMenuVisibility(v)

    if (v) {
      keyHandlerState = onKeyHandler([EventKey.ESC], () => {
        cancelEditing()
        releaseKeyHandler()
      })
    } else {
      releaseKeyHandler()
    }
  }

  const cancelEditing = () => {
    setMenuVisibility(false)
  }

  const ColorIconRenderer = () => {
    return (
      <div className={'cm-base-exterior'}>
        <div className={'exterior-colors'} onClick={handleColorClick}>
          {colorPalette.map(color => (
            <span
              key={color.v}
              data-color={color.v}
              className={getColorShapeClass(color, base.color)}
            />
          ))}
        </div>

        <div className={'exterior-icons'} onClick={handleIconClick}>
          {icons.map(icon =>
            getStyledIcon(icon === base.icon, base.color, icon, base.name),
          )}
        </div>
      </div>
    )
  }

  const header = (
    <>
      <Input
        fit
        autoFocus
        value={entityName}
        className={'din'}
        onChange={handleChangeName}
        onPressEnter={saveName}
        onBlur={saveName}
      />
      <div className={'cm-dpd-wrap'}>
        <Tooltip
          title={() => <ColorIconRenderer />}
          trigger={['click']}
          align={alignConfig}
          placement={'right'}
          getPopupContainer={(triggerNode: HTMLElement) =>
            triggerNode.parentElement
          }>
          <button className={'bo cm-dpd'}>
            <NEWIcon type={'drop'} />
            <span>Цвет и Иконка</span>
            <NEWIcon type={'caret-right'} />
          </button>
        </Tooltip>
      </div>
    </>
  )

  const contextItems = hideCreateTable ? menuItems.filter(i => i.key !== BaseActions.CREATE_TABLE) : menuItems

  return (
    <ComplexMenu
      menu={contextItems}
      headerTitle={'Настройка базы'}
      header={header}
      overlayClassName={'sb-smenu'}
      visible={visible}
      onClick={handleClickMenu}
      onVisibleChange={onVisibleChange}>
      {children}
    </ComplexMenu>
  )
}

type ReduxDispatchProps = {
  disturbEntity: (args: DisturbEntityArguments) => Promise<any>
}

type ReduxProps = ReduxDispatchProps

const mapDispatchToProps = dispatch =>
  bindActionCreators<any, ReduxDispatchProps>(
    {
      disturbEntity,
    },
    dispatch,
  )

export default connect<any, ReduxDispatchProps>(
  null,
  mapDispatchToProps,
)(BaseContextMenu)
