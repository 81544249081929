import React from "react";
import {debounce} from "../../../utils/common";
import {asString} from "../../../utils/as";
import {BaseRendererProps} from "../BaseRendererProps";

export default class FormLongTextRenderer extends React.Component<BaseRendererProps<any>> {
  commitChanges: (value: any) => void = debounce((value: any) => {
    this.props.onChange(value)
  }, 400)

  handleChange = e => {
    this.commitChanges(e.target.value)
  }

  render() {
    const {readonly, autoFocus, value} = this.props

    if (readonly) {
      return <div className={'re-value'}>{asString(value)}</div>
    }

    return (
      <textarea
        className={"eyzy-textarea eyzy-textarea-fit"}
        readOnly={readonly}
        style={{height: '100px'}}
        defaultValue={asString(value)}
        onChange={this.handleChange}
        autoFocus={autoFocus}
      />
    )
  }
}
