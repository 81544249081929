import React from 'react'
import { DetailsBlockProps } from '../types'
import { Collapse, CollapsePanel } from '../../../irv-ui'
import { GridColumn } from '../../../grid/types/GridColumn'
import { FormRendererFactory } from '../../../_renderers/factory'
import { getValue } from '../../../../general/utils/value'
import { mergeColumnWithViewParams } from '../../../../utils/get'
import { useFormPayload } from "../../../_renderers/RendererContext"
import { useBlockProvider } from "../BlockProvider"

const DetailsPanelBlock: React.FC<DetailsBlockProps> = ({
  block,
  columnsMap,
}) => {
  const { view } = useBlockProvider()
  const { table, row } = useFormPayload()

  return (
    <div className={'details-block'}>
      <Collapse defaultOpen={block.closed ? [] : ['0']}>
        <CollapsePanel header={block.title} key={'0'}>
          {block.params.columns.map(id => {
            const column: GridColumn | undefined = columnsMap[id]

            if (!column) {
              return <div></div>
            }

            return (
              <FormRendererFactory
                key={column._id}
                value={getValue(table, column, row)}
                params={mergeColumnWithViewParams(view, column)}
                readonly={true}
                column={column}
              />
            )
          })}
        </CollapsePanel>
      </Collapse>
    </div>
  )
}

export default DetailsPanelBlock
