import React from "react";
import {Checkbox} from '../../../../components/irv-ui'
import {SettingsItemComponentProps} from "../types";

export default React.memo((props: SettingsItemComponentProps) => {
  const {params, onAction} = props
  const onChange = (e) => {
    onAction(
      {
        defaultValue: e.target.checked
      }
    )
  }

  return (
    <div className={'column-settings-row'}>
      <div className={'column-settings-label'}>
        Значение по умолчанию
      </div>
      <div className={'column-settings-ctrl'}>
        <Checkbox checked={params.defaultValue === true} onChange={onChange} />
      </div>
    </div>
  )
})
