import React from 'react'
import { connect } from 'react-redux'
import { Base } from '../../../types/Base'
import { BaseTable } from '../../../types/BaseTable'
import { BaseView, SortOrderType } from '../../../types/BaseView'
import { GridRow } from '../../../components/grid/types/GridRow'
import { ViewType } from '../../../components/grid/types/ViewType'
import Calendar from '../views/NEW__Calendar'
import { getUserRole } from '../../../components/roles/RoleGateway'
import { Roles } from '../../../components/roles/Roles'
import { EyzyUser } from '../../../types/EyzyUser'
import {
  ActionType,
  disturbEntity,
  DisturbEntityArguments,
  fetchItem,
  loadEntity,
  LoadEntityArguments,
} from '../../../store/CommonEffects'
import store from '../../../store'
import { buildLink, history, Pages } from '../../../utils/navigation'
import { applySort } from '../../../utils/sort'
import TimelineView from '../views/Timeline'
import { TableActions } from '../../../store/actions/TableActions'
import Loading from '../../../components/loading/Loading'
import { debounce } from '../../../utils/common'
import ViewHeader from '../components/view/header/ViewHeader'
import { bindActionCreators } from 'redux'
import { ViewActions } from '../../../store/actions/ViewActions'
import { Workspace } from '../../../types/Workspace'
import { ViewSettingsUIStateItem } from '../types/UIState'
import ListView from '../views/List'
import GridView from '../views/NEW__Grid'

interface State {
  isLoading?: boolean
  headerPayload?: any
}

class TableContainer extends React.PureComponent<Props, State> {
  state: State = {
    // headerPayload: {
    //   type: ViewSettingsUIStateItem.NEW_COLUMN
    // }
    // headerPayload: {
    //   type: ViewSettingsUIStateItem.COLUMN,
    //   payload: {
    //     "columnId": "cB5Ut7WYuL"
    //   }
    // }
  }

  getUserRole = (): Roles => {
    const { user, base } = this.props

    return (
      getUserRole(user, base) || getUserRole(user, { _id: base.workspaceId })
    )
  }

  reloadRows = debounce(() => {
    const { table, view } = this.props

    this.setState({ isLoading: true })

    fetchItem(TableActions.FETCH, table._id, { viewId: view._id })(
      store.dispatch,
    ).then(() => {
      this.setState({ isLoading: false })
    })
  }, 1000)

  filterRows = (filters): Promise<any> => {
    this.setState({ isLoading: true })

    const { table, view, loadEntity } = this.props

    return loadEntity({
      id: table._id,
      action: TableActions.FILTER_ROWS,
      queryString: {
        viewId: view._id,
        ...filters,
      },
    }).then(_ => this.setState({ isLoading: false }))
  }

  setHeaderPayload = (headerPayload: any) => {
    this.setState({
      headerPayload,
    })
  }

  handleAction = (action: ActionType, payload: any): Promise<any> => {
    const { table, view, disturbEntity } = this.props
    const viewId: string = view._id

    let callback

    // Тут типа доп манипуляции
    switch (action) {
      case TableActions.FILTER_ROWS:
        return this.filterRows(payload)

      case ViewActions.HIDE_COLUMN:
      case ViewActions.SHOW_COLUMN: {
        payload = {
          columnId: payload.columnId,
          params: {
            hidden: action === ViewActions.HIDE_COLUMN,
          },
        }
        action = TableActions.UPDATE_VIEW_COLUMN

        break
      }

      case TableActions.SETTINGS_COLUMN: {
        this.setHeaderPayload({
          type: ViewSettingsUIStateItem.COLUMN,
          payload,
        })

        return Promise.resolve()
      }

      case ViewActions.SORT_COLUMN_A_Z:
      case ViewActions.SORT_COLUMN_Z_A: {
        payload.sort = [
          {
            columnId: payload.columnId,
            order:
              action === ViewActions.SORT_COLUMN_A_Z
                ? SortOrderType.ASC
                : SortOrderType.DESC,
          },
        ]

        action = TableActions.UPDATE_VIEW

        break
      }

      case TableActions.UPDATE_VIEW_FILTER:
        action = TableActions.UPDATE_VIEW
        callback = this.reloadRows
        break
    }

    return disturbEntity({
      entity: action,
      id: table._id,
      payload: {
        viewId,
        ...payload,
      },
    }).then(response => {
      if (callback) {
        callback(response)
      }

      return response
    })
  }

  handleViewNavigate = (viewId: string) => {
    const table = this.props.table
    const view: BaseView =
      table.views.find(v => v._id === viewId) || table.views[0]

    history.push(
      buildLink(Pages.MAIN_WORKSPACE, {
        tableId: table._id,
        viewId: view._id,
      }),
    )
  }

  renderView = () => {
    const { base, table, view, rows, user, userRole, ws } = this.props

    let sortedRows: GridRow[]
    let Component

    switch (view!.type) {
      case ViewType.CALENDAR:
        Component = Calendar
        break
      case ViewType.TIMELINE:
        if (view.sort) {
          sortedRows = applySort(rows, table, view.sort)
        }

        Component = TimelineView
        break

      case ViewType.LIST:
        Component = ListView
        break

      case ViewType.GRID:
      default: {
        if (view.sort) {
          sortedRows = applySort(rows, table, view.sort)
        }

        Component = GridView
      }
    }

    return (
      <Component
        key={view._id}
        base={base}
        table={table}
        view={view}
        rows={sortedRows || rows}
        user={user}
        ws={ws}
        userRole={userRole}
        onAction={this.handleAction}
      />
    )
  }

  render() {
    const { table, view, ws, userRole } = this.props
    const { isLoading, headerPayload } = this.state

    return (
      <>
        <ViewHeader
          view={view}
          table={table}
          ws={ws}
          userRole={userRole}
          payload={headerPayload}
          onAction={this.handleAction}
        />

        {isLoading ? <Loading /> : this.renderView()}
      </>
    )
  }
}

type ReduxStateProps = {
  rows: GridRow[]
}

type ReduxDispatchProps = {
  loadEntity: (args: LoadEntityArguments) => Promise<any>
  disturbEntity: (args: DisturbEntityArguments) => Promise<any>
}

type ReduxProps = ReduxStateProps & ReduxDispatchProps
type Props = ReduxProps & {
  base: Base
  table: BaseTable
  view: BaseView
  ws: Workspace
  user: EyzyUser
  userRole: Roles
}

const mapStateToProps = (state: any): ReduxStateProps => ({
  rows: state.rows,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators<any, ReduxDispatchProps>(
    {
      loadEntity,
      disturbEntity,
    },
    dispatch,
  )

export default connect<ReduxStateProps, ReduxDispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(TableContainer)
