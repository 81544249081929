import React from "react"
import { Input } from "../../../components/irv-ui"
import { BaseRendererProps } from "../BaseRendererProps"
import { formatNumber } from "../../../utils/view"

const numberKeys = [
  48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 189, 190, 8, 46, 13, 37, 38, 39, 40, 16, 20, 91, 93,
]

interface State {
  hasError: boolean
}

export default class BaseNumberRenderer extends React.PureComponent<BaseRendererProps<number | string>> {
  state: State = {
    hasError: false,
  }

  handleChange = (e) => {
    if (e.target.value === "-") {
      return this.props.onChange("-")
    }

    const value = parseInt(e.target.value, 10)
    this.props.onChange(isFinite(value) ? value : "")
  }

  handleKeyDown = (e) => {
    if (e.metaKey) {
      return
    }

    const hasError = !numberKeys.includes(e.keyCode)

    if (hasError !== this.state.hasError) {
      this.setState({ hasError })
    }
  }

  handleBlur = () => {
    if (this.state.hasError) {
      this.setState({ hasError: false })
    }
  }

  render() {
    const { value, readonly, params } = this.props

    if (readonly) {
      return <div className={"re-value"}>
        {value === undefined ? null : formatNumber(value, params.precision)}
      </div>
    }

    const displayValue: any = value === null || value === undefined ? "" : value

    return (
      <div className={"nb"}>
        <Input
          type={"text"}
          className={"re-editor"}
          value={displayValue}
          onInput={this.handleChange}
          onBlur={this.handleBlur}
          onKeyDown={this.handleKeyDown}
        />
        {this.state.hasError && (
          <div className={"secondary-text"}>Пожалуйста, введите число</div>
        )}
      </div>
    )
  }
}
