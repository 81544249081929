import React from 'react'
import { useFocusRing } from 'react-aria'
import { IrvBaseBtnProps } from './types/IrvButtonProps'

import './styles.scss'
import {cn} from "../../utils/cn";

const Button: React.FC<IrvBaseBtnProps> = ({
  variant,
  className,
  children,
  type = 'button',
  fit,
  focusRing,
  ...rest
}) => {
  const { isFocusVisible, focusProps } = useFocusRing()
  const classNames = cn('e-btn', variant && `e-btn-${variant}`, className, {
    'e-fit': fit,
    'e-ring': focusRing && isFocusVisible,
  })

  return (
    <button {...focusProps} type={type} className={classNames} {...rest}>
      {children}
    </button>
  )
}

export default Button
