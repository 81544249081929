import React, { ReactNode } from 'react'
import { GridColumn } from '../../../grid/types/GridColumn'
import { EyzyUser } from '../../../../types/EyzyUser'
import { LoadEntityArguments } from '../../../../store/CommonEffects'
import { EyzyLink } from '../../../details/types/FormDetails'
import { GridRow } from '../../../grid/types/GridRow'
import { BaseTable } from '../../../../types/BaseTable'
import { FormOptions } from '../../../../types/FormOptions'
import { BaseView } from '../../../../types/BaseView'

type BaseFormError = React.ReactElement

export enum BaseFormLayout {
  HORIZONTAL = 'h',
  INLINE = 'inline',
}

export type BaseFormOptions = Record<string, FormOptions>

export interface FormDetailsPayload {
  row: GridRow
  table: BaseTable
  users?: EyzyUser[]
  view?: BaseView
  activeUsers?: EyzyUser[]
  links?: Record<string, EyzyLink>
  recordAction?: LoadEntityArguments
}

export interface FormProps {
  header?: ReactNode
  layout?: BaseFormLayout
  fields: GridColumn[]
  formOptions?: BaseFormOptions
  errors?: { [field: string]: BaseFormError[] }
  data: Object
  readonly?: boolean
  // payload - Что-то полезное. В linkedRedords - єто будут rows
  onChange: (fieldName, fieldValue, payload?: any) => void
  onCommit?: (fieldName, fieldValue, payload?: any) => void
}

export type BaseFormProps = FormProps & {
  payload?: FormDetailsPayload
}

export interface BaseFormFieldProps {
  field: GridColumn
  value: any
  error?: BaseFormError
  readonly?: boolean
  onChange: (fieldName, fieldValue) => void
}
