import React from 'react'
import { Event } from '../../types/Event'

interface Props {
  event: Event
  x: number
  y: number
  height: number
  width: number
}

export default React.memo(function TLEvent(props: Props) {
  const style = {
    left: props.x,
    top: props.y,
    height: props.height,
    width: props.width,
  }

  return (
    <div className='tl-event' style={style}>
      <div className="tl-event-content">
        <span>{props.event.title}</span>
      </div>
    </div>
  )
})