import React, { memo, useCallback } from 'react'
import { FormRendererProps } from '../types/FormRenderer'
import { NumberInput } from '../../irv-ui'

const FormNumberRenderer: React.FC<FormRendererProps> = ({
  id,
  value,
  readonly,
  className,
  params,
  onChange,
  onCommit,
}) => {
  const handleBlur = useCallback(
    e => {
      if (onCommit) {
        onCommit(e.target.value)
      }
    },
    [onCommit],
  )

  if (readonly) {
    return <div className={className}>{value}</div>
  }

  const isInt: boolean = !('precision' in params) || params.precision === 0

  return (
    <NumberInput
      id={id}
      isInt={isInt}
      negative={params.negative}
      value={value || ''}
      className={className}
      onChange={onChange}
      onBlur={handleBlur}
    />
  )
}

export default memo(FormNumberRenderer)
