import { useEffect, useState } from 'react'
import { BaseTable } from '../../../types/BaseTable'
import { AddonForm } from '../../../types/Addon'
import { ViewLike } from '../../../types/BaseView'
import { BaseFormOptions } from './types/BaseFormProps'

type AddonFormOptions = {
  formOptions?: BaseFormOptions
  viewOptions?: ViewLike
}

export const buildFormParams = (
  table: BaseTable,
  form: AddonForm,
): AddonFormOptions => {
  const view = form.viewId ? table.views.find(v => v._id === form.viewId) : form

  return {
    viewOptions: {
      order: view?.order,
      columnsParams: view?.columnsParams,
      groups: view?.groups,
    },
    formOptions: form.formOptions,
  }
}

export const useAddonFormOptions = (
  table: BaseTable,
  form: AddonForm,
): AddonFormOptions => {
  const [formParams, setFormParams] = useState<AddonFormOptions>(
    buildFormParams(table, form),
  )

  useEffect(() => {
    setFormParams(buildFormParams(table, form))
  }, [table, form])

  return formParams
}
