import React from 'react'
import Select from 'antd/es/select'
import Tag from '../../shape/Tag'
import {GridColumnOptionItem} from "../../grid/types/GridColumn";
import {isEmpty, isMob} from "../../../utils/is";
import {BaseRendererProps} from "../BaseRendererProps";
import {getOptionText} from "../../../utils/get";
import {asArray} from "../../../general/utils/array";

const Option = Select.Option
const TagRenderer = props => props.label

export default React.memo(function BaseMultipleSelectRenderer(props: BaseRendererProps<any, GridColumnOptionItem>) {
  const params = props.params || {}
  const o = props.options || params.options || []
  const width = props.width

  if (props.readonly) {
    if (isEmpty(props.value)) {
      return null
    }

    const selectedItems: GridColumnOptionItem[] = asArray(props.value || [])
      .map(i => getOptionText(i, o))
      .filter(Boolean)

    return (
      <span className="re-value grid-multiple">
        {selectedItems.map(item => (
          <Tag key={item._id} color={params.useColor && item.color}>{item.text}</Tag>
        ))}
      </span>
    )
  }

  const style = width ? {width} : null
  const value: string[] = asArray(props.value)
  const options = o.filter(o => {
    return !o.removedOn || value.includes(o._id)
  })

  return (
    <Select
      style={style}
      showSearch={!isMob()}
      value={props.value || []}
      optionFilterProp={'label'}
      onChange={newValue => props.onChange(newValue)}
      popupClassName="grid-mask-editor"
      getPopupContainer={trigger => trigger.parentNode}
      className="re-editor"
      tagRender={TagRenderer}
      mode="multiple">
      {options.map((option) => (
        <Option key={option._id} value={option._id} tt={123}>
          <Tag color={params.useColor && option.color}>{option.text}</Tag>
        </Option>
      ))}
    </Select>
  )
})
