export function isIOS() {
  return isIPhone() || isIPad()
}

export function isIPhone() {
  return testPlatform(/^iPhone/i)
}

export function isIPad() {
  return (
    testPlatform(/^iPad/i) ||
    // iPadOS 13 lies and says it's a Mac, but we can distinguish by detecting touch support.
    (isMac() && navigator.maxTouchPoints > 1)
  )
}

export function isMac() {
  return testPlatform(/^Mac/i)
}

function testPlatform(re: RegExp) {
  return typeof window !== 'undefined' && window.navigator != null
    ? re.test(
        window.navigator['userAgentData']?.platform ||
          window.navigator.platform,
      )
    : false
}

const MOBILE_SCREEN_WIDTH = 700

export function isMobileDevice(): boolean {
  return window.innerWidth <= MOBILE_SCREEN_WIDTH
}
