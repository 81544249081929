import Checkbox from "../../checkbox/Checkbox"
import React, { memo, useCallback } from "react"

interface Props {
  checked: boolean
  id: string
  onSelect?: (id: string[], state: boolean) => void
}

const TableCheckbox: React.FC<Props> = ({ id, checked, onSelect }) => {
  const onChange = useCallback(() => {
    if (onSelect) {
      onSelect([id], !checked)
    }
  }, [id, onSelect, checked])

  return (
    <Checkbox
      checked={checked}
      onClick={e => e.stopPropagation()}
      onChange={onChange}
    />
  )
}

export default memo(TableCheckbox)
