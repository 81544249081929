import React from 'react'
import {GridRendererProps} from "../GridRendererProps";
import {getValue} from "../../../general/utils/value";

export default React.memo(function GridLinkRenderer(props: GridRendererProps) {
  const {row, column} = props
  const value = getValue(column.table, column, row)

  if (!value) {
    return null
  }

  const href: string = /^http/.test(value) ? value : `http://${value}`
  const link = <a className="grid-phone" target="_blank" rel="noopener noreferrer" href={href}>{value}</a>

  return (
    <div className="re-value phone">
      <span>{link}</span>
    </div>
  )
})
