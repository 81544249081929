import { useState } from 'react'

export interface SelectState {
  /** Whether the select is currently focused. */
  readonly isFocused: boolean
  readonly isOpen: boolean

  open(): void

  close(): void

  toggle(): void

  /** Sets whether the select is focused. */
  setFocused(isFocused: boolean): void
}

export interface SelectProps {
  /** Sets the open state of the menu. */
  isOpen?: boolean
  /** Sets the default open state of the menu. */
  defaultOpen?: boolean
  /** Method that is called when the open state of the menu changes. */
  onOpenChange?: (isOpen: boolean) => void
}

/**
 * Provides state management for a select component. Handles building a collection
 * of items from props, handles the open state for the popup menu, and manages
 * multiple selection state.
 */
export function useSelectState(props: SelectProps): SelectState {
  const [isOpen, setOpen] = useState(!!props.defaultOpen)
  const [isFocused, setFocused] = useState(false)

  return {
    open() {
      setOpen(true)
    },
    close() {
      setOpen(false)
    },
    toggle() {
      setOpen(!isOpen)
    },
    isOpen,
    isFocused,
    setFocused,
  }
}
