import React, {useEffect, useState} from 'react'
import Select from "antd/lib/select";
import CustomRRule from "../weekDays/CustomRRule";
import {getRepeatFrequency, getRepeatOptions} from '../../../../../utils/calendar';
import {Event as CalendarEventType, EventFrequency} from "../../../../../types/Calendar";
import {Frequency} from 'rrule';
import {dayjs} from '../../../../../general/utils/date';

interface Props {
  event: CalendarEventType
  onChange: (repeat: any) => void
}

export default React.memo(function EventDetailsRepeat(props: Props) {
  const {event, onChange} = props
  const repeatOptions = getRepeatOptions()

  const [repeatValue, setRepeatValue] = useState(getRepeatFrequency(event))

  const handleRepeatChange = (freq: EventFrequency) => {
    setRepeatValue(freq)

    switch (freq) {
      case EventFrequency.NONE: return onChange(null)
      case EventFrequency.WEEKLY: {
        return onChange({
          freq: Frequency.WEEKLY,
          interval: 1,
          byweekday: [dayjs(event.start).day() - 1]  // Англ должен быть
        })
      }

      case EventFrequency.DAILY: {
        return onChange({
          freq: Frequency.DAILY,
          interval: 1
        })
      }

      case EventFrequency.CUSTOM: {
        return onChange({
          freq: Frequency.WEEKLY,
          interval: 1,
          byweekday: [dayjs(event.start).day() - 1] // Англ должен быть
        })
      }
    }
  }

  useEffect(() => {
    setRepeatValue(getRepeatFrequency(event))
    // eslint-disable-next-line
  }, [event.id])

  const handleChangeRule = (rule) => {
    onChange(rule)
  }

  return (
    <div className="event-details-repeat">
      <div className="event-details-row">
        <div className={'label'}>Повтор</div>
        <div className={'ctrl'}>
          <Select
            value={repeatValue}
            getPopupContainer={trigger => trigger.parentNode}
            onChange={handleRepeatChange}
            style={{width: 145}}
            options={repeatOptions}
          />
        </div>

        {repeatValue === EventFrequency.CUSTOM && event.rrule && (
          <CustomRRule rule={event.rrule} onChange={handleChangeRule}/>
        )}
      </div>

    </div>
  )
})