import React from 'react'
import Select from 'antd/es/select'
import {isEmpty, isMob, isUserRemoved} from "../../../utils/is";
import {EyzyUser} from "../../../types/EyzyUser";
import {getUsers} from "../../../utils/get";
import {BaseRendererProps} from "../BaseRendererProps";
import UserBadge from "./UserBadge";
import {asArray} from "../../../utils/as";
import buildTagRenderer from '../../user/TagRenderer';
import {removeItem} from "../../../utils/array";

const Option = Select.Option

export default React.memo(function BaseUserRenderer(props: BaseRendererProps<any, EyzyUser>) {
  const {readonly, value, options, width} = props
  const params = props.params || {}
  const multiple = params.multiple === true
  const users: EyzyUser[] = options || []

  if (readonly) {
    if (isEmpty(value)) {
      return null
    }

    const users = getUsers(value, options || []).filter(Boolean)
    const showNames: boolean = isFinite(params['width']) ? (params['width'] / users.length) > 130 : true

    return (
      <span className="re-value">
        {users.map(user => (
          <UserBadge key={user._id} user={user as EyzyUser} showName={showNames}/>
        ))}
      </span>
    )
  }

  const onChange = value => props.onChange(!multiple ? [value] : value)
  const onCloseTag = (userId: string) => onChange(removeItem(value, id => id === userId))
  const usersMap = {}
  const storeUsers: EyzyUser[] = users.filter(user => {
    usersMap[user._id] = user

    // Нужно его оставить для рендера
    if (isUserRemoved(user)) {
      return asArray(value).includes(user._id)
    }

    return true
  })

  const tagRenderer = buildTagRenderer(usersMap, onCloseTag)
  const style = width ? {width} : null

  return (
    <Select
      style={style}
      showSearch={!isMob()}
      value={value}
      optionFilterProp={'label'}
      onChange={onChange}
      popupClassName="grid-mask-editor"
      className="grid-editor"
      getPopupContainer={trigger => document.body}
      mode={multiple ? 'multiple' : undefined}
      tagRender={tagRenderer}
    >
      {storeUsers.map((user) => (
        <Option key={user._id} value={user._id} label={user.fullName} user={user}>{user.fullName || user.email}</Option>
      ))}
    </Select>
  )
})
