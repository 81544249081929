import React, { useState } from 'react'
import { Input } from '../../../../../components/irv-ui'
import { Workspace, WSModes } from '../../../../../types/Workspace'
import SBBase from './SBBase'
import { Base } from '../../../../../types/Base'
import { cn } from '../../../../../utils/classname'
import { ActionType } from '../../../../../store/CommonEffects'
import { WorkspaceActions } from '../../../../../store/actions/WorkspaceActions'
import { history } from '../../../../../utils/navigation'
import { hasWSMode } from '../../../../../utils/modes'
import { EyzyUser } from '../../../../../types/EyzyUser'
import {
  isWSActionGranted,
  UIRoleAction,
} from '../../../../../components/roles/RoleGateway'
import NEWIcon from '../../../../../components/icon/NEWIcon'
import Tooltip from 'antd/lib/tooltip'
import { isMob } from '../../../../../utils/is'

interface Props {
  ws: Workspace
  user: EyzyUser
  expanded: boolean
  expandedBase: string[]
  activeTable: string
  activeBase?: string
  callAction: (action: ActionType, id: string, payload: any) => Promise<any>
  onToggle: (entity: Workspace | Base, openNextLink?: boolean) => void
  onMenuClick: (action: ActionType, payload?: any) => void
}

export default React.memo((props: Props) => {
  const {
    ws,
    user,
    expanded,
    expandedBase,
    activeTable,
    activeBase,
    callAction,
    onToggle,
    onMenuClick,
  } = props
  const [isBaseCreating, setBaseCreating] = useState<boolean>(false)

  const simplified: boolean = hasWSMode(ws, WSModes.SIMPLIFIED_NAV)
  const containerClassName: string = cn(
    'sb-ws',
    expanded && 'expanded',
    simplified && 'smpl',
  )

  const handleToggle = () => onToggle(ws)
  const handleBaseCreate = () => {
    setBaseCreating(true)

    if (!expanded) {
      handleToggle()
    }
  }

  const addBase = e => {
    const name: string = e.target.value.trim()

    if (!name) {
      return cancelEditing()
    }

    callAction(WorkspaceActions.CREATE_BASE, ws._id, { name }).then(
      ({ tables }) => {
        history.push(`/${tables[0]._id}/${tables[0].views[0]._id}`)
      },
    )

    cancelEditing()
  }

  const cancelEditing = () => {
    setBaseCreating(false)
  }

  return (
    <div className={containerClassName}>
      <div className="sb-ws-name">
        <span title={ws.name} onClick={handleToggle}>
          {ws.name}
        </span>
        {!isMob() &&
          isWSActionGranted(UIRoleAction.WORKSPACE_CREATE_BASE, user, ws) && (
            <Tooltip title={'Создать базу'}>
              <button
                className={'cha create'}
                title={'Создать базу'}
                onClick={handleBaseCreate}>
                <NEWIcon type={'plus'} />
              </button>
            </Tooltip>
          )}
      </div>

      {expanded && (
        <div className="sb-bases">
          {ws.bases.map(b => (
            <SBBase
              key={b._id}
              base={b}
              user={user}
              ws={ws}
              simplified={simplified}
              expanded={expandedBase.includes(b._id)}
              active={activeBase === b._id}
              activeTable={activeTable}
              callAction={callAction}
              onToggle={onToggle}
              onMenuClick={onMenuClick}
            />
          ))}

          {isBaseCreating && (
            <Input
              autoFocus
              placeholder={'Имя базы'}
              className={'edit-en'}
              onPressEnter={addBase}
              onBlur={addBase}
              onPressEsc={cancelEditing}
            />
          )}
        </div>
      )}
    </div>
  )
})
