import React from 'react'
import { Workspace } from '../../../types/Workspace'
import Collaborator, { ROLES_OPTIONS } from './Collaborator'
import { getWSRole, isRoleAllowed, UIRoleAction } from '../../roles/RoleGateway'
import { EyzyUser } from '../../../types/EyzyUser'
import {
  ActionType,
  DisturbEntityArguments,
} from '../../../store/CommonEffects'
import { Roles } from '../../roles/Roles'
import { Button, Input, Tabs } from '../../../components/irv-ui'
import { isActiveUser, isRealEmail } from '../../../utils/is'
import { WorkspaceActions } from '../../../store/actions/WorkspaceActions'
import { Dialog, Select } from '../../irv-ui'

import './style.scss'

interface Props {
  ws: Workspace
  onClose: () => void
  onAction: (args: DisturbEntityArguments) => Promise<any>
  user: EyzyUser
  visible: boolean
}

interface State {
  fresh?: string
  freshRole: Roles
  isInviting?: boolean
  token?: string
}

class WorkspaceCollaborators extends React.Component<Props, State> {
  state: State = {
    fresh: '',
    freshRole: Roles.ADMIN,
  }

  handleChangeFresh = fresh => {
    this.setState({
      fresh,
    })
  }

  handleChangeFreshRole = freshRole => {
    this.setState({
      freshRole,
    })
  }

  invite = () => {
    const { fresh, freshRole } = this.state
    const { onAction, ws } = this.props

    this.setState({ isInviting: true })

    onAction({
      entity: WorkspaceActions.INVITE_COLLABORATOR,
      id: ws._id,
      payload: {
        email: fresh,
        level: freshRole,
      },
    }).then(({ token }) => {
      this.setState({
        token,
        isInviting: false,
        fresh: '',
        freshRole: Roles.ADMIN,
      })
    })
  }

  update = (entity: ActionType, payload: any) => {
    return this.props.onAction({
      entity,
      payload,
      id: this.props.ws._id,
    })
  }

  renderCollaborator = (user: EyzyUser, editorRole: Roles) => {
    return (
      <Collaborator
        key={user._id}
        user={user}
        onUpdate={this.update}
        editorRole={editorRole}
      />
    )
  }

  renderContent = () => {
    const { ws, user } = this.props
    const users: EyzyUser[] = ws.collaborators
      .filter(isActiveUser)
      .concat()
      .sort(a => (a._id === user._id ? -1 : 1))

    const editorId: string = user._id
    const editorRole = getWSRole(user, ws)
    const { fresh, freshRole, isInviting, token } = this.state

    return (
      <div className="collaborators">
        {isRoleAllowed(UIRoleAction.WORKSPACE_INVITE, editorRole) && (
          <Tabs>
            <Tabs.Tab label={'Пригласить по e-mail'}>
              <Input
                placeholder={'Введите почту'}
                value={fresh}
                onChange={this.handleChangeFresh}
                disabled={isInviting}
              />

              <Select
                dropdownClassName="collaborator-role"
                width={170}
                className="collaborator-role"
                items={ROLES_OPTIONS}
                value={freshRole}
                valueRenderer={({ item: { name } }) => name}
                dropdownWidth={550}
                placement={'bottom right'}
                disabled={isInviting}
                onSelect={this.handleChangeFreshRole}>
                {(item) => (
                  <div className={'irv-bitem'}>
                    <div className="name">{item.name}</div>
                    <div className="descr">{item.descr}</div>
                  </div>
                )}
              </Select>

              <Button
                variant={'primary'}
                disabled={isInviting || !isRealEmail(fresh)}
                onClick={this.invite}>
                Пригласить
              </Button>
            </Tabs.Tab>
          </Tabs>
        )}

        {token && (
          <Input
            value={`${window.location['origin']}/resetPassword/${token}`}
            fit
            disabled
            style={{ marginBottom: '20px' }}
          />
        )}

        <div className="collaborators-block">
          <h3>Сотрудники рабочего пространства</h3>
          <div className="collaborators-list">
            {users
              .filter(u => u._id !== editorId)
              .map(user => this.renderCollaborator(user, editorRole))}
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { ws, visible, onClose } = this.props

    return (
      <Dialog
        open={visible}
        width={'70%'}
        alignSelf={'start'}
        className={'irv-modalwt'}
        header={<h3>{ws.name}</h3>}
        onClose={onClose}>
        {this.renderContent()}
      </Dialog>
    )
  }
}

export default WorkspaceCollaborators
