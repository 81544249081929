import React, { memo, useCallback } from 'react'
import { RendererClassNames } from '../definitions'
import BaseGridInputRenderer from '../utils/BaseGridInputRenderer'
import { GridCellRendererProps } from '../types/GridRenderer'
import { GridColumn } from '../../grid/types/GridColumn'

const GridLinkRenderer: React.FC<GridCellRendererProps> = props => {
  const renderer = useCallback(
    (column: GridColumn, value: any) => {
      if (props.active) {
        return <a href={`tel:${value}`}>{value}</a>
      }

      return <span>{value}</span>
    },
    [props],
  )

  return (
    <BaseGridInputRenderer
      {...props}
      renderer={renderer}
      className={RendererClassNames.EMAIL}
    />
  )
}

export default memo(GridLinkRenderer)
