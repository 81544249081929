import {FilterOperators} from './FilterOperators'
import {DateFilterModes} from './DateFilterModes'

const DateModesMap = {
  [FilterOperators.IS]: [
    DateFilterModes.TODAY,
    DateFilterModes.TOMORROW,
    DateFilterModes.YESTERDAY,
    DateFilterModes.PAST_WEEK,      // 7 дней назад
    DateFilterModes.CURRENT_WEEK,
    DateFilterModes.NEXT_WEEK,      // 7 дней вперед
    DateFilterModes.NEXT_MONTH,     // след месяц (та же дата)
    DateFilterModes.PAST_MONTH,
    DateFilterModes.NEXT_DAYS_N,    // N дней вперед
    DateFilterModes.NEXT_MONTHS_N,
    DateFilterModes.PAST_DAYS_N,
    DateFilterModes.PAST_MONTHS_N,
    DateFilterModes.EXACT
  ],

  [FilterOperators.IS_WITHIN]: [
    DateFilterModes.CURRENT_WEEK,
    DateFilterModes.CURRENT_MONTH,
    DateFilterModes.CURRENT_YEAR,
    DateFilterModes.PAST_WEEK,
    DateFilterModes.PAST_7_DAYS,
    DateFilterModes.PAST_MONTH,
    DateFilterModes.PAST_30_DAYS,
    DateFilterModes.PAST_YEAR,
    DateFilterModes.NEXT_WEEK,
    DateFilterModes.NEXT_7_DAYS,
    DateFilterModes.NEXT_MONTH,
    DateFilterModes.NEXT_30_DAYS,
    DateFilterModes.NEXT_YEAR,
    DateFilterModes.NEXT_DAYS_N,    // N дней вперед
    DateFilterModes.NEXT_MONTHS_N,
    DateFilterModes.PAST_DAYS_N,
    DateFilterModes.PAST_MONTHS_N,
  ],

  [FilterOperators.IS_AFTER]: [
    DateFilterModes.EXACT
  ]
}

export {DateModesMap}