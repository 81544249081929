import React from "react";
import {GridRendererProps} from "../GridRendererProps";
import BaseDateRenderer from "./BaseDateRenderer";
import {debounce} from "../../../utils/common";

export default React.memo(function GridDateRenderer(props: GridRendererProps) {
  const {onCommit, isActive, row, column} = props
  const params = column.params || {}

  const onChange = debounce((date) => {
    onCommit(date)
  }, 400)

  return (
    <BaseDateRenderer
      value={row[column._id]}
      readonly={!isActive}
      onChange={onChange}
      format={params.format}
      params={params}
    />
  )
})
