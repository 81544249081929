import React, { useCallback } from 'react'
import { FormRendererProps } from '../types/FormRenderer'
import { Input } from '../../irv-ui'
import BtnWithIcon from '../../btn/BtnWithIcon'

const FormPhoneRenderer: React.FC<FormRendererProps> = ({
  value,
  readonly,
  className,
  column,
  onCommit,
  onChange,
}) => {
  const handleBlur = useCallback(
    e => {
      if (onCommit) {
        onCommit(e.target.value)
      }
    },
    [onCommit],
  )

  if (readonly) {
    if (!value) {
      return null
    }

    return (
      <div className={className}>
        <a
          className="link"
          href={`tel:${value}`}
          onClick={e => e.stopPropagation()}>
          {value}
        </a>
      </div>
    )
  }

  return (
    <>
      <Input
        id={column._id}
        className={className}
        value={value}
        onBlur={handleBlur}
        onChange={onChange}
      />

      {value && (
        <a
          className={'suffix'}
          title={value}
          href={`tel:${value}`}
          target="_blank"
          rel="noopener noreferrer">
          <BtnWithIcon icon={'phone'} />
        </a>
      )}
    </>
  )
}

export default FormPhoneRenderer
