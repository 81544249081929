import React from "react"

export interface HiddenSelectProps {
  name?: string
  disabled?: boolean
  value?: string
  onFocus?(): void
}

export const HiddenSelect: React.FC<HiddenSelectProps> = ({ name, disabled, value, onFocus }) => {
  return (
    <input
      type="hidden"
      name={name}
      disabled={disabled}
      value={value}
      onFocus={onFocus}
    />
  )
}
