import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { RendererClassNames } from '../definitions'
import { GridCellRendererProps } from '../types/GridRenderer'
import { getValue } from '../../../general/utils/value'
import { asArray } from '../../../utils/as'
import { removeItem } from '../../../utils/array'
import LinkToRecord from '../base/LinkToRecord'
import { LinkedRow } from '../../../types/Row'
import LinkedRecordsModal from '../../linkedRecord/LinkedRecordModal'
import LinkToRecordTags from '../utils/LinkToRecordTags'
import BtnWithIcon from '../../btn/BtnWithIcon'

const GridLinkToRecordRenderer: React.FC<GridCellRendererProps> = ({
  column,
  active,
  payload,
  row,
}) => {
  const [value, setValue] = useState(
    asArray(getValue(payload.table, column, row)),
  )
  const [isModalVisible, setVisible] = useState(false)
  const container = useRef<HTMLDivElement>()
  const params = {
    ...column.params,
    tableId: payload.table._id,
    columnId: column._id,
  }

  const isMultiple: boolean = true === params.multiple
  const isAddBtnVisible: boolean = useMemo(
    () => (isMultiple ? true : !value.length),
    [isMultiple, value],
  )

  const handleChange = useCallback(
    (values: any[]) => {
      hideModal()
      setValue(values)
      payload.onChange(row._id, column, values)
    },
    [row, column, payload],
  )

  const onRemove = useCallback(
    (id: string) => {
      const updatedRecords: LinkedRow[] = removeItem(
        value,
        (rec: LinkedRow) => rec._id === id,
      )

      handleChange(updatedRecords)
    },
    [value, handleChange],
  )

  const hideModal = () => {
    setVisible(false)
  }

  useEffect(() => {
    setValue(asArray(getValue(payload.table, column, row)))
  }, [row, payload, column])

  if (payload.readonly || !active) {
    return <LinkToRecord column={column} value={value} table={payload.table} />
  }

  return (
    <div className={RendererClassNames.LINK_TO_RECORD} ref={container}>
      {isAddBtnVisible && (
        <BtnWithIcon icon={'b-add-circle'} onClick={() => setVisible(true)} />
      )}

      <LinkToRecordTags
        value={value}
        onRemove={payload.readonly ? null : onRemove}
      />

      {isModalVisible && (
        <LinkedRecordsModal
          tableId={params.tableId}
          columnId={params.columnId}
          container={document.querySelector('.irv-grid')}
          visible={isModalVisible}
          selectedRows={value}
          multiple={isMultiple}
          onClose={hideModal}
          onSelect={handleChange}
        />
      )}
    </div>
  )
}

export default memo(GridLinkToRecordRenderer)
