import React, { useMemo } from "react"
import ig from '../../assets/svg/social/ig.svg'
import fb from '../../assets/svg/social/fb.svg'
import tw from '../../assets/svg/social/tw.svg'
import { SocialTypes } from "../../types/SocialNetworks"

interface Props {
  icon: string
}

const SIcon: React.FC<Props> = ({ icon }) => {
  const iconSrc = useMemo(() => {
    switch (icon) {
      case SocialTypes.INSTAGRAM: return ig
      case SocialTypes.FACEBOOK: return fb
      case SocialTypes.TWITTER: return tw
    }
  }, [icon])

  return (
    <img src={iconSrc} className={'s-icon'} width={18} alt={""} />
  )
}

export default React.memo(SIcon)
