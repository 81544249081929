import React from 'react'
import {ColumnTypeSelector} from "../types";
import ColumnNameWithIcon from "../../../../../../components/columnHelpers/ColumnNameWithIcon";
import {
  advancedColumns,
  basicColumns,
  columnTexts,
  getColumnIcon
} from "../../../../../../components/grid/columns/ColumnsFactory";
import Menu from "antd/lib/menu";

const SubMenu = Menu.SubMenu

export default React.memo(function ColumnTypeSelector(props: ColumnTypeSelector) {
  const {type, onChange} = props
  const typeTitle: any = (
    <div className={'column-def'}>
      <i className={`icon i-${getColumnIcon(type)}`}/>
      <span className="text truncate">{columnTexts[type]}</span>
    </div>
  )

  const onSelect = ({key}) => onChange(key)

  return (
    <div className="column-settings-item">
      <div className={'column-settings-row'}>
        <div className={'column-settings-label'}>
          Тип колонки
        </div>
        <div className={'column-settings-ctrl'}>
          <Menu mode="vertical"
                key={type}
                className={"wtm cv"}
                selectedKeys={[type]}
                onSelect={onSelect}
                triggerSubMenuAction={'click'}>
            <SubMenu title={typeTitle} popupClassName={'wtm-menu cv'}>
              <Menu.ItemGroup title="Базовые">
                {basicColumns.map(c => (
                  <Menu.Item key={c.type}>
                    <ColumnNameWithIcon column={c as any}/>
                  </Menu.Item>
                ))}
              </Menu.ItemGroup>
              <Menu.ItemGroup title="Продвинутые">
                {advancedColumns.map(c => (
                  <Menu.Item key={c.type}>
                    <ColumnNameWithIcon column={c as any}/>
                  </Menu.Item>
                ))}
              </Menu.ItemGroup>
            </SubMenu>
          </Menu>
        </div>
      </div>
    </div>
  )
})
