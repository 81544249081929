import React, { forwardRef, useEffect } from 'react'
import { PopoverProps } from './types/PopoverProps'
import { useDOMRef } from '../hooks/useDomRef'
import Overlay from './Overlay'
import { usePopover } from '../hooks/usePopover'
import { cn } from '../utils/cn'

import './styles/popover.scss'

const PopoverWrapper = forwardRef((props: PopoverProps, ref: any) => {
  const { children, open, className, style, underlay, onClose } = props
  const { popoverProps } = usePopover({
    ...props,
    popoverRef: ref,
    // @ts-ignore
    maxHeight: null,
  })

  const popoverClassName = cn('irv-popover', { open }, className)

  useEffect(() => {
    if (ref && ref.current) {
      setTimeout(() => {
        if (ref.current?.contains(document.activeElement)) {
          return
        }

        ref.current?.focus()
      }, 20)
    }
  }, [])

  return (
    <div
      {...popoverProps}
      style={{
        // @ts-ignore
        ...popoverProps.style,
        ...style,
      }}
      ref={ref}
      tabIndex={-1}
      className={popoverClassName}
      role="presentation">
      {underlay && <div className={'irv-underlay'} onClick={onClose} />}
      {children}
    </div>
  )
})

const Popover = (props: PopoverProps, ref: any) => {
  const domRef: any = useDOMRef(ref)
  const { open, children, ...otherProps } = props

  return (
    <Overlay {...otherProps} open={props.open}>
      <PopoverWrapper ref={domRef} {...props}>
        {children}
      </PopoverWrapper>
    </Overlay>
  )
}

export default forwardRef(Popover)
