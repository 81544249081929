import React from "react";
import {GridRendererProps} from "../GridRendererProps";
import BaseRecordIndexRenderer from "./BaseRecordIndexRenderer";
import { getValue } from "../../../general/utils/value";

export default React.memo(function GridRecordIndexRenderer(props: GridRendererProps) {
  if (props.isActive) {
    return null
  }

  const {row, column} = props
  const value = getValue(column.table, column, row)

  return (
      <div className="re-value">
        <BaseRecordIndexRenderer
          onChange={() => {}} value={value}
        />
      </div>
  )
})
