import {FilterOperators} from './FilterOperators'
import {ColumnType} from './ColumnType'
import {isUserColumn, isDateColumn, getUIColumnType} from '../utils/column'

const OperationsMap = {
  'string': [
    FilterOperators.IS,
    FilterOperators.IS_NOT,
    FilterOperators.CONTAINS,
    FilterOperators.DOES_NOT_CONTAIN,
    FilterOperators.IS_STARTS_WITH,
    FilterOperators.IS_ENDS_WITH,
    FilterOperators.IS_EMPTY,
    FilterOperators.IS_NOT_EMPTY
  ],
  'numberType': [
    FilterOperators.IS_EQUAL,
    FilterOperators.IS_NOT_EQUAL,
    FilterOperators.IS_LESS_THAN,
    FilterOperators.IS_GREATER_THAN,
    FilterOperators.IS_LESS_THAN_OR_EQUAL,
    FilterOperators.IS_GREATER_THAN_OR_EQUAL,
    FilterOperators.IS_EMPTY,
    FilterOperators.IS_NOT_EMPTY
  ],
  'singleList': [
    FilterOperators.IS,
    FilterOperators.IS_NOT,
    FilterOperators.IS_ANY_OF,
    FilterOperators.IS_NONE_OF,
    FilterOperators.IS_EMPTY,
    FilterOperators.IS_NOT_EMPTY
  ],
  'multipleList': [
    FilterOperators.IS_ANY_OF,
    FilterOperators.IS_ALL_OF,
    FilterOperators.IS,
    FilterOperators.IS_NONE_OF,
    FilterOperators.IS_EMPTY,
    FilterOperators.IS_NOT_EMPTY
  ],
  'date': [
    FilterOperators.IS,
    FilterOperators.IS_WITHIN,
    FilterOperators.IS_BEFORE,
    FilterOperators.IS_AFTER,
    FilterOperators.IS_ON_OR_BEFORE,
    FilterOperators.IS_ON_OR_AFTER,
    FilterOperators.IS_NOT,
    FilterOperators.IS_EMPTY,
    FilterOperators.IS_NOT_EMPTY
  ],
  'general': [
    FilterOperators.CONTAINS,
    FilterOperators.DOES_NOT_CONTAIN,
    FilterOperators.IS_EMPTY,
    FilterOperators.IS_NOT_EMPTY
  ],
  'checkbox': [
    FilterOperators.IS
  ],
  'formula': [
    FilterOperators.IS,
    FilterOperators.IS_NOT,
    FilterOperators.CONTAINS,
    FilterOperators.DOES_NOT_CONTAIN,

    FilterOperators.IS_EQUAL,
    FilterOperators.IS_NOT_EQUAL,
    FilterOperators.IS_LESS_THAN,
    FilterOperators.IS_GREATER_THAN,
    FilterOperators.IS_LESS_THAN_OR_EQUAL,
    FilterOperators.IS_GREATER_THAN_OR_EQUAL,

    FilterOperators.IS_EMPTY,
    FilterOperators.IS_NOT_EMPTY,
  ]
}

const getFilterOperators = (column) => {
  if (isUserColumn(column)) {
    return OperationsMap.multipleList
  }

  if (isDateColumn(column)) {
    return OperationsMap.date
  }

  const type = getUIColumnType(column)

  switch (type) {
    case ColumnType.TEXT:
    case ColumnType.LONG_TEXT:
    case ColumnType.EMAIL:
    case ColumnType.LINK:
    case ColumnType.PHONE:
      return OperationsMap.string

    case ColumnType.SINGLE_SELECT:
      return OperationsMap.singleList

    case ColumnType.MULTIPLE_SELECT:
      return OperationsMap.multipleList

    case ColumnType.CHECKBOX:
      return OperationsMap.checkbox

    case ColumnType.NUMBER:
      return OperationsMap.numberType

    case ColumnType.FORMULA:
      return OperationsMap.formula

    default:
      return OperationsMap.general
  }
}

export {OperationsMap}
export {getFilterOperators}