export enum WorkspaceActions {
  FETCH = 'w0',
  PERMISSIONS_SETTING = 'w1',
  CREATE = 'w2',
  REMOVE = 'w3',
  UPDATE = 'w4',
  UPDATE_ROLE = 'w5',
  CREATE_BASE = 'w6',
  REMOVE_COLLABORATOR = 'w7',
  ADDONS_DATA = 'w8',
  UTILITY = 'w9',
  INVITE_COLLABORATOR = 'w10',
  FETCH_BASES = 'w11',
  SETTINGS = 'w12'
}
