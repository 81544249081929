import React from 'react'
import Select from 'antd/es/select'
import {GridColumnOptionItem} from "../../grid/types/GridColumn";
import {BaseRendererProps} from "../BaseRendererProps";
import {isEmpty, isMob} from "../../../utils/is";
import {asArray} from "../../../utils/as";
import Tag from "../../shape/Tag";

const Option = Select.Option

export default React.memo(function BaseSingleSelectRenderer(props: BaseRendererProps<string[] | null>) {
  const params = props.params || {}
  const options = props.options || params.options || []
  const useColor = params.useColor
  const val = asArray(props.value)
  const width = props.width

  if (props.readonly) {
    if (isEmpty(props.value)) {
      return null
    }

    // TODO сука что за гавно
    const value = val![0]
    const item = options.find(o => o._id === value)

    if (!item) {
      return null
    }

    return (
      <span className={"re-value"}>
        <Tag color={useColor ? item.color : ''} removed={!!item.removedOn}>{item.text}</Tag>
      </span>
    )
  }

  const possibleOptions = params.visibleOptions
    ? options.filter(opt => params.visibleOptions.includes(opt._id))
    : options

  const selectOptions = [{_id: 'null', text: ' ', value: null} as GridColumnOptionItem]
    .concat(possibleOptions)
    .filter(o => {
      if (!o.removedOn) {
        return true
      }

      return val.includes(o._id)
    })

  const getPopupContainer = trigger => {
    if (isMob()) {
      return document.body
    }

    return trigger.parentNode
  }

  const onSelectChange = (newValue: string, opt) => {
    props.onChange('null' === opt.key ? null : [newValue])
  }

  const value = isEmpty(val) ? undefined : val[0]
  const style = width ? {width} : null

  return (
    <Select
      style={style}
      showSearch={!isMob()}
      value={value}
      filterOption={true}
      optionFilterProp={'label'}
      onChange={onSelectChange}
      className="grid-editor"
      getPopupContainer={getPopupContainer}
      dropdownMatchSelectWidth={false}
      popupClassName="grid-mask-editor">
      {selectOptions.map((option) => (
        <Option key={option._id} value={option._id} label={option.text}>
          {option.text !== ' ' &&
          <Tag color={useColor ? option.color : ''} removed={!!option.removedOn}>{option.text}</Tag>}
          {option.text === ' ' && option.text}
        </Option>
      ))}
    </Select>
  )
})
