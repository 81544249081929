import React, { CSSProperties, FunctionComponent, memo } from 'react'
import {
  IorvoGridColumn,
  IorvoGridColumnHeaderProps,
} from '../types/IorvoGridColumn'

interface Props {
  column: IorvoGridColumn
  payload: any
  renderer?:
    | React.FC<IorvoGridColumnHeaderProps>
    | FunctionComponent<IorvoGridColumnHeaderProps>
  onStartResize: (column: IorvoGridColumn, pos: any) => void
}

export default memo<Props>((props: Props): any => {
  const { renderer: Renderer, column, payload, onStartResize } = props
  const Component =
    column._id !== 'sn' && Renderer ? (
      <Renderer column={column} payload={payload} />
    ) : (
      column.name
    )

  const handleStartResize = (e: any) => {
    onStartResize(column, e.clientX)
  }

  const style: CSSProperties = {
    width: column.width,
  }

  const isSn: boolean = column._id === 'sn'
  const cn: string = ['irv-header-column', isSn && 'sn']
    .filter(Boolean)
    .join(' ')

  return (
    <div className={cn} style={style}>
      {Component}

      {column.resize && (
        <div className={'irv-rs'} onMouseDown={handleStartResize} />
      )}
    </div>
  )
})
