import { ProgressBarProps } from './types/ProgressBarProps'
import { CSSProperties, memo } from "react"
import { cn } from "../../utils/cn"

import './styles.scss'

const ProgressBar: React.FC<ProgressBarProps> = ({
  label,
  labelPosition,
  minValue = 0,
  maxValue = 100,
  value = 0,
}) => {
  const barStyle: CSSProperties = {}
  const percentage = (value - minValue) / (maxValue - minValue)

  barStyle.width = `${Math.round(percentage * 100)}%`

  const containerClassName = cn(
    'irv-progress-bar',
    labelPosition
  )

  return (
    <div className={containerClassName}>
      {label && (
        <div className={'label'}>{label}</div>
      )}
      <div className={'bar'}>
        <div className="fill" style={barStyle} />
      </div>
    </div>
  )
}

export default memo(ProgressBar)
