import React from 'react'
import { FormRendererProps } from '../types/FormRenderer'
import BaseUserRenderer from '../base/UserRenderer'
import { useFormPayload } from '../RendererContext'
import { currentUser } from '../../../utils/get'

const FormSpecialUserRenderer: React.FC<FormRendererProps> = ({
  value,
  className,
  column,
}) => {
  const payload = useFormPayload()
  const userValue = value ?? [currentUser()._id]

  return (
    <div className={className}>
      <BaseUserRenderer
        value={userValue}
        column={column}
        table={payload.table}
        payload={payload}
      />
    </div>
  )
}

export default FormSpecialUserRenderer
