import React, {useEffect, useRef} from 'react'
import {dayjs} from "../../../../../general/utils/date";

interface Props {
  selected: dayjs.Dayjs
  onChange: (date: Date) => void
}

const hours = new Array(24).fill('').map((_, i) => ('' + i).padStart(2, '0'))
const minutes = new Array(12).fill('').map((_, i) => ('' + (i * 5)).padStart(2, '0'))

enum DateType {
  HOURS,
  MINUTES
}

export default React.memo(function MOBDTSelector(props: Props) {
  const {selected, onChange} = props
  const hour: string = selected.format('HH')
  const min: string = selected.format('mm')
  const el = useRef(null)

  const handler = (type: DateType) => (e) => {
    const targetValue = parseInt(e.target.innerText)

    onChange(
      selected.set(type === DateType.HOURS ? 'hour' : 'minute', targetValue).toDate()
    )
  }

  useEffect(() => {
    try {
      const hoursEl = el.current.querySelector('.mdt-hours')
      const minsEl = el.current.querySelector('.mdt-mins')

      const minsIndex = minutes.findIndex(m => m === min)
      const hoursIndex = hours.findIndex(h => h === hour)

      hoursEl.scrollTop = hoursIndex * 35
      minsEl.scrollTop = minsIndex * 35
    } catch (e) {
      console.log(e)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className={'mdt-selector'} ref={el}>
      <div className={'selectors'}>
        <ul className={'mdt-hours'} onClick={handler(DateType.HOURS)}>
          {hours.map(h => (
            <li className={h === hour ? 'active' : ''}>{h}</li>
          ))}
        </ul>
        <ul className={'mdt-mins'} onClick={handler(DateType.MINUTES)}>
          {minutes.map(m => (
            <li className={m === min ? 'active' : ''}>{m}</li>
          ))}
        </ul>
      </div>
    </div>
  )
})