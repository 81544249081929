import React, { memo, useMemo } from 'react'
import BaseUserRenderer from '../base/UserRenderer'
import { GridCellRendererProps } from '../types/GridRenderer'
import { getValue } from '../../../general/utils/value'

const GridSpecialUserRenderer: React.FC<GridCellRendererProps> = ({
  row,
  payload,
  column,
}) => {
  const value = useMemo(
    () => getValue(payload.table, column, row),
    [payload, column, row],
  )

  const rendererPayload = useMemo(() => {
    return { users: payload.ws.collaborators }
  }, [payload])

  return (
    <BaseUserRenderer
      value={value}
      column={column}
      table={payload.table}
      payload={rendererPayload}
    />
  )
}

export default memo(GridSpecialUserRenderer)
