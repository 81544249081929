export enum TableActions {
  FETCH = 't0',
  CREATE = 't1',
  UPDATE = 't2',
  COPY = 't3',
  REMOVE = 't4',

  UPDATE_COLUMN = 't5',
  CREATE_COLUMN = 't6',
  REMOVE_COLUMN = 't7',
  SETTINGS_COLUMN = 't8',

  DUPLICATE_COLUMN = 't30',

  ADD_RIGHT_COLUMN = 't31',
  ADD_LEFT_COLUMN = 't32',

  CREATE_VIEW = 't9',
  UPDATE_VIEW_COLUMN = 't10',
  UPDATE_VIEW = 't11',
  REMOVE_VIEW = 't13',
  DUPLICATE_VIEW = 't14',

  CREATE_ROW = 't20',
  UPDATE_ROW = 't21',
  FETCH_LINKED_ROWS = 't23',
  UPDATE_CELL = 't24',
  DELETE_ROW = 't25',

  FILTER_ROWS = 't26',
  UPDATE_VIEW_FILTER = 't27',

  READ_RECORDS = 't28',

  CALL_ACTION = 't30',

  CALENDAR_ROWS = 't40'
}
