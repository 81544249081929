import React from 'react'
import {
  FakeRow,
  FakeType,
  GridGroup,
  GroupHeaderRow,
  GroupOrRow,
  GroupRow,
  IorvoGridProps,
  IorvoGridState,
} from './types/IorvoGridProps'
import { IorvoGridColumn } from './types/IorvoGridColumn'
import { VariableSizeList } from 'react-window'
import Row from './renderer/Row'
import { getSNRenderer } from './renderer/sn/SN'
import GroupHeader from './renderer/group/Header'
import Header from './components/Header'
import GFake from './components/GFake'

import './styles/index.scss'
import { hasOwn } from './utils'

export const GROUP_FAKE = 15
export const GROUP_PADDING = GROUP_FAKE * 2

class IorvoGrid extends React.PureComponent<IorvoGridProps, IorvoGridState> {
  c: React.RefObject<HTMLDivElement> = React.createRef()
  list: React.RefObject<any> = React.createRef()
  listApi: React.RefObject<any> = React.createRef()
  header: React.RefObject<any> = React.createRef()

  ro: any

  constructor(props: IorvoGridProps) {
    super(props)

    this.state = {
      cn: 'irv-grid',
      width: 0,
      height: 0,
      depth: 0,
      itemData: {
        collapsed: [],
      },
      ...this.process(props),
    } as IorvoGridState
  }

  componentDidMount() {
    this.updateViewportSize()
    this.initObserver()

    this.list.current.addEventListener('scroll', this.handleScrollList, false)

    window.addEventListener('keydown', this.handleKeyDown, true)
    window.addEventListener('mousedown', this.handleMouseDown, true)
  }

  componentDidUpdate(prevProps: Readonly<IorvoGridProps>) {
    const { rows, columns, columnPayload, grouping, freeze } = this.props

    if (
      prevProps.columns !== columns ||
      prevProps.grouping !== grouping ||
      prevProps.freeze !== freeze ||
      prevProps.columnPayload !== columnPayload ||
      prevProps.rows !== rows
    ) {
      this.setState(
        {
          ...(this.process(
            this.props,
            this.state.itemData.collapsed,
          ) as IorvoGridState),
        },
        () => {
          this.listApi.current.resetAfterIndex(0)
        },
      )
    }
  }

  componentWillUnmount() {
    this.list.current.removeEventListener(
      'scroll',
      this.handleScrollList,
      false,
    )
    window.removeEventListener('keydown', this.handleKeyDown, true)

    if (this.ro) {
      if (this.c.current) {
        this.ro.unobserve(this.c.current)
      }
    } else {
      window.removeEventListener('resize', this.updateViewportSize)
    }
  }

  initObserver = () => {
    // @ts-ignore
    if (ResizeObserver && this.c.current) {
      // @ts-ignore
      this.ro = new ResizeObserver(() => {
        const { width, height } = this.state
        const current: any = this.c.current!.parentElement
        const { clientWidth, clientHeight } = current

        if (width === clientWidth && height + 32 === clientHeight) {
          return
        }

        this.updateViewportSize()
      })
      this.ro.observe(this.c.current)
    } else {
      window.addEventListener('resize', this.updateViewportSize)
    }
  }

  isScrolled = (): boolean => {
    return this.list && this.list.current && this.list.current.scrollLeft > 0
  }

  process = (
    props: IorvoGridProps,
    collapsed?: string[],
  ): Partial<IorvoGridState> => {
    const processedState: Partial<IorvoGridState> = {}

    Object.assign(processedState, this.calculateColumns(props))

    Object.assign(
      processedState,
      this.calculateRows(props.rows, props.grouping, collapsed),
    )

    if (processedState && processedState.depth) {
      processedState.totalColumnsWidth! += GROUP_PADDING * processedState.depth
    }

    if (props.freeze && props.freeze > 0) {
      const groupPaddingWidth: number = (processedState.depth || 0) * GROUP_FAKE
      const groupFreezeWidth: number = processedState
        .columns!.slice(0, props.freeze)
        .reduce((freeze, column) => {
          return freeze + column.width!
        }, 0)

      processedState.freezeWidth = groupPaddingWidth + groupFreezeWidth
    }

    return processedState
  }

  calculateColumns = (
    props: IorvoGridProps,
  ): { columns: IorvoGridColumn[]; totalColumnsWidth: number } => {
    const { columns, columnPayload, sn } = props
    const resultColumns = sn
      ? [getSNRenderer(sn, columnPayload)].concat(columns)
      : columns

    let left: number = 0
    let index: number = 0

    return resultColumns.reduce(
      (result, column) => {
        const currentColumnWidth = column.width || 150
        const currentColumn = {
          ...column,
          left,
          width: currentColumnWidth,
        } as IorvoGridColumn

        if (column._id !== 'sn') {
          currentColumn.index = index++
        }

        left += currentColumnWidth

        result.columns.push(currentColumn)
        result.totalColumnsWidth += currentColumnWidth

        return result
      },
      { columns: [] as IorvoGridColumn[], totalColumnsWidth: 0 },
    )
  }

  calculateRows = (
    rows: any[],
    grouping?: GridGroup[],
    collapsed?: string[],
  ) => {
    let totalRowIndex: number = 0
    let depth: number = 0

    if (!grouping) {
      return {
        gRows: rows,
      }
    }

    const processRows = (rows: any[]) => {
      return rows.map((index, groupIndex) => {
        const res = [index, totalRowIndex++, groupIndex]

        // Типа последний row
        if (groupIndex === rows.length - 1) {
          res.push(true)
        }

        return res
      })
    }

    const collapsedGroups: string[] = collapsed || []

    const gRows = grouping.reduce((grows: GroupOrRow[], group) => {
      const result: GroupOrRow[] = [
        {
          group: group.group,
          key: group.key,
          depth: 1,
          ex: true,
          rows: group.rows,
        } as GroupHeaderRow,
      ]

      if (collapsedGroups.includes(group.key)) {
        return grows.concat(result)
      }

      if (group.groups) {
        let childDepth: number = 2

        const walker = (g: GridGroup, i: number) => {
          result.push({
            group: g.group,
            rows: g.rows,
            key: g.key,
            depth: childDepth,
            ex: !!i,
          } as GroupHeaderRow)

          if (g.rows && !collapsedGroups.includes(g.key)) {
            result.push(...processRows(g.rows))
          }

          depth = Math.max(depth, childDepth)

          // @ts-ignore
          if (i === group.groups.length - 1) {
            result.push({ ft: FakeType.END_OF_THE_GROUP, payload: { depth } })
          }

          if (g.groups) {
            childDepth++
            g.groups.forEach(walker)
          }
        }

        group.groups.forEach(walker)
      } else {
        result.push(...processRows(group.rows))
      }

      return grows.concat(result)
    }, [])

    const realDepth = Math.max(1, depth)

    // @ts-ignore
    gRows.push({ ft: FakeType.END_OF_THE_LIST })

    return {
      depth: realDepth,
      gRows,
    }
  }

  getItemSize = (index: number) => {
    const { rowHeight, grouping } = this.props

    if (grouping) {
      const { gRows } = this.state
      const gRow: GroupOrRow = gRows![index]

      if (!gRow) {
        return 0
      }

      if (hasOwn(gRow, 'ft')) {
        return GROUP_FAKE * 2
      }

      if (hasOwn(gRow, 'group')) {
        // @ts-ignore
        return gRow.ex ? 70 : 50
      }

      return rowHeight || 32
    }

    return rowHeight || 32
  }

  getOverscanCount = () => {
    const { gRows } = this.state

    if (!gRows) {
      return 10
    }

    if (gRows.length < 100) {
      return 100
    }

    return Math.min(100, Math.floor(gRows.length * 0.1))
  }

  getSelectedRow = (): string | null | void => {
    const {
      itemData: { mask },
      gRows,
    } = this.state

    if (!mask) {
      return
    }

    const gRow = gRows.find((r: any) => r[1] === mask.r)

    if (!gRow) {
      return
    }

    // @ts-ignore
    const row = gRow ? this.props.rows[gRow[1]] : null

    if (!row) {
      return
    }

    return row._id
  }

  callOnSelect = () => {
    const { onSelect, grouping, rows } = this.props

    if (!onSelect) {
      return
    }

    const {
      itemData: { mask },
      gRows,
    } = this.state

    if (!mask) {
      return onSelect(null)
    }

    if (grouping) {
      const gRowsIndex: number = gRows.findIndex((gr: any) => gr[1] === mask.r)
      const gRow = gRows[gRowsIndex] as any

      if (!gRow) {
        return
      }

      const row = rows[gRow[0]]

      onSelect({
        ...mask,
        r: gRow[0],
        id: row ? row._id : '',
      })
    } else {
      onSelect(mask)
    }
  }

  // Разрешить навигацию, если у сфокусируемого этомента есть класс irv-an
  hasANClass = (element: Element | null): boolean => {
    if (!element) {
      return false
    }

    return element.classList.contains('.irv-an') || !!element.closest('.irv-an')
  }

  scrollToActive = () => {
    const container = this.c.current

    if (!container) {
      return
    }

    const activeCell = container.querySelector('.active')

    if (!activeCell) {
      return
    }

    const { freeze, grouping } = this.props
    const { columns, itemData, depth, totalColumnsWidth } = this.state

    const containerBounds = container.getBoundingClientRect()
    const cellBounds = activeCell.getBoundingClientRect()

    // @ts-ignore
    const scrollerContainer = this.list.current

    const { scrollLeft, scrollTop } = scrollerContainer

    let leftBounds = cellBounds.left - containerBounds.left
    const topBounds = cellBounds.top - containerBounds.top - 32

    let newScrollLeft = scrollLeft
    let newScrollTop = scrollTop

    if (freeze && freeze > 0) {
      const col = columns[freeze - 1]

      // @ts-ignore
      leftBounds -= col.width + col.left + 2

      if (depth) {
        leftBounds -= depth * GROUP_FAKE
      }
    }

    if (leftBounds < 0) {
      newScrollLeft += leftBounds
    } else if (cellBounds.right > containerBounds.right) {
      newScrollLeft += cellBounds.right - containerBounds.right + 16 // MAGIC: scrollerWidth + 1px of border
    }

    if (topBounds < 0) {
      newScrollTop += topBounds
    } else if (cellBounds.bottom > containerBounds.bottom) {
      newScrollTop += cellBounds.bottom - containerBounds.bottom + 16 // MAGIC: scrollerWidth + 1px of border
    }

    if (itemData.mask) {
      if (itemData.mask.c === 1) {
        newScrollLeft = 0
      } else if (itemData.mask.c === columns.length - 1) {
        newScrollLeft = totalColumnsWidth
      }

      if (itemData.mask.r === 0 && grouping) {
        newScrollTop = 0
      }
    }

    scrollerContainer.scrollTo({
      left: Math.max(0, newScrollLeft),
      top: newScrollTop,
    })
  }

  navigateFromEvent = (e: any) => {
    const { itemData } = this.state
    const mask = itemData.mask

    if (!mask) {
      return
    }

    e.preventDefault()

    const { grouping } = this.props
    const { columns, gRows } = this.state

    let rIndex = mask.r
    let cIndex = mask.c

    switch (e.keyCode) {
      case 38: // top
        rIndex -= 1
        break
      case 39: // right
        cIndex += 1
        break
      case 40: // bottom
        rIndex += 1
        break
      case 37: // left
        cIndex -= 1
        break
    }

    if (9 === e.keyCode) {
      cIndex += e.shiftKey ? -1 : 1

      if (cIndex > columns.length - 1) {
        cIndex = 0
        rIndex += 1
      } else if (cIndex < 0) {
        cIndex = columns.length - 1
        rIndex -= 1
      }
    }

    rIndex = Math.min(Math.max(0, rIndex), gRows.length - 1)
    cIndex = Math.min(Math.max(0, cIndex), columns.length - 1)

    if (grouping) {
      // @ts-ignore
      const lastRowIndex = (gRows as any[]).findLastIndex(
        // @ts-ignore
        rows => !('ft' in rows),
      )

      if (lastRowIndex >= 0) {
        const grow = gRows[lastRowIndex] as GroupRow

        if (grow) {
          // @ts-ignore
          rIndex = Math.min(grow![1], rIndex)
        }
      }
    }

    if (cIndex === mask.c && rIndex === mask.r) {
      return
    }

    const column: IorvoGridColumn | undefined = columns[cIndex]

    if (column.mask === false) {
      return
    }

    this.setState(
      {
        itemData: {
          ...itemData,
          mask: {
            r: rIndex,
            c: cIndex,
          },
        },
      } as IorvoGridState,
      () => {
        this.scrollToActive()
        this.callOnSelect()
      },
    )
  }

  selectCellById = (rowId: string, cellIndex?: number) => {
    const { rows } = this.props
    const { gRows, itemData } = this.state

    const rowIndex = rows.findIndex(r => r._id === rowId)

    if (rowIndex === -1) {
      return
    }

    const mask = { ...itemData.mask }
    const gRow = gRows.find((r: any) => r[0] === rowIndex) as any

    if (!gRow) {
      return
    }

    if (cellIndex !== undefined) {
      mask.c = cellIndex
    }

    mask.r = gRow[1]

    this.setState(
      {
        itemData: {
          ...itemData,
          mask,
        },
      } as IorvoGridState,
      () => {
        this.scrollToActive()
      },
    )
  }

  updateViewportSize = () => {
    if (!this.c.current) {
      return
    }

    // @ts-ignore
    const current: HTMLDivElement = this.list.current.parentElement
    const { clientWidth, clientHeight } = current

    this.setState({
      width: clientWidth,
      height: clientHeight - 32,
    } as IorvoGridState)
  }

  handleScrollList = (e: any) => {
    const { scrolled } = this.state
    const scrolledNow: boolean = e.target.scrollLeft > 0

    // @ts-ignore
    this.header.current.scrollLeft = e.target.scrollLeft

    if (scrolledNow !== scrolled) {
      this.setState({
        cn: scrolledNow ? 'irv-grid scrolled' : 'irv-grid',
        scrolled: scrolledNow,
      } as IorvoGridState)
    }
  }

  handleKeyDown = (e: any) => {
    if (document.activeElement && !this.hasANClass(document.activeElement)) {
      const isWithinGrid = document.activeElement.closest('.irv-grid')

      if (isWithinGrid) {
        return
      }
    }

    if (e.target.classList.contains('irv-ow') || e.target.closest('.irv-ow')) {
      return
    }

    // Если что-то выделено и пробел - без скрола нахуй
    if (this.state.itemData.mask && e.keyCode === 32) {
      e.preventDefault()
    }

    if (
      [
        9 /* Tab*/, 38 /* Top */, 39 /* right */, 40 /* bottom */,
        37 /* left */,
      ].includes(e.keyCode)
    ) {
      return this.navigateFromEvent(e)
    }
  }

  handleMouseDown = (e: any) => {
    const target = e.target as Element
    const cell = (target.closest('.irv-cell') || target) as HTMLElement
    const { itemData } = this.state

    if (target.classList.contains('irv-ow') || target.closest('.irv-ow')) {
      return
    }

    if (cell.classList.contains('irv-cell')) {
      const row = cell.parentElement

      // @ts-ignore
      const cIndex = +cell.dataset.c

      // @ts-ignore
      const rIndex = +row.dataset.r

      const column = this.state.columns[cIndex]

      if (!column || column.mask === false) {
        return
      }

      const mask = {
        r: rIndex,
        c: cIndex,
      }

      this.setState(
        {
          itemData: {
            ...itemData,
            mask,
          },
        } as IorvoGridState,
        () => {
          this.scrollToActive()
          this.callOnSelect()
        },
      )
    } else if (itemData.mask) {
      delete itemData.mask

      // @ts-ignore
      this.setState(
        {
          itemData: {
            ...itemData,
          },
        },
        this.callOnSelect,
      )
    }
  }

  handleHoverRow = (hoveredRow: string) => {
    const { itemData } = this.state

    if (hoveredRow !== itemData.hoveredRow) {
      // @ts-ignore
      this.setState({
        itemData: {
          ...itemData,
          hoveredRow,
        },
      })
    }
  }

  handleStartResize = (column: IorvoGridColumn, startPos: any) => {
    const { freeze, sn, onResize } = this.props
    const { columns, freezeWidth, itemData, totalColumnsWidth } = this.state
    const initWidth: number = column.width!

    const columnIndex: number = (column.index || 0) + (sn ? 1 : 0)

    let width: number

    const handleMouseUp = () => {
      window.removeEventListener('mouseup', handleMouseUp)
      window.removeEventListener('mousemove', handleMouseMove)

      onResize && onResize(column, width)
    }

    const handleMouseMove = (e: any) => {
      const widthDiff: number = e.clientX - startPos
      const stateColumns = columns.concat()

      width = Math.max(50, initWidth + widthDiff)

      stateColumns[columnIndex] = {
        ...column,
        width,
      } as IorvoGridColumn

      const newState: Partial<IorvoGridState> = {
        columns: stateColumns,
        totalColumnsWidth: totalColumnsWidth + widthDiff,
        itemData: {
          ...itemData,
        },
      }

      if (freezeWidth) {
        const isFreezeColumn: boolean = freeze ? columnIndex < freeze : false

        if (isFreezeColumn) {
          newState.freezeWidth = freezeWidth + widthDiff
        }
      }

      requestAnimationFrame(() => {
        this.setState(newState as IorvoGridState)
      })
    }

    window.addEventListener('mouseup', handleMouseUp)
    window.addEventListener('mousemove', handleMouseMove)
  }

  handleGroupToggle = (group: GroupHeaderRow) => {
    const { rows, grouping } = this.props
    const itemData = this.state.itemData
    const collapsed: string[] = itemData.collapsed || []
    const keyIndex: number = collapsed.findIndex(key => key === group.key)

    if (keyIndex === -1) {
      collapsed.push(group.key)
    } else {
      collapsed.splice(keyIndex, 1)
    }

    const updatedState: Partial<IorvoGridState> = {
      itemData: {
        ...itemData,
        collapsed: collapsed.concat(),
      },
      ...this.calculateRows(rows, grouping, collapsed),
    }

    this.setState(updatedState as IorvoGridState, () => {
      this.listApi.current.resetAfterIndex(0)
    })
  }

  renderFt = (row: FakeRow, style: any) => {
    const { freezeWidth, totalColumnsWidth } = this.state
    const ftStyle = {
      ...style,
      width: totalColumnsWidth,
    }

    if (row.ft === FakeType.END_OF_THE_GROUP) {
      return (
        <div className={'irv-ft'} style={ftStyle}>
          {freezeWidth && (
            <div className={'irv-frz'} style={{ width: freezeWidth }}>
              <GFake depth={row.payload.depth || 0} />
              <div className="irv-ft-fl" />
            </div>
          )}

          <div className={'irv-rwrap'}>
            <div className="irv-ft-fl" />
            <GFake depth={row.payload.depth || 0} isRight />
          </div>
        </div>
      )
    }

    return <div className={'irv-fh'} style={ftStyle} />
  }

  renderGroupedRow = ({ style, index }: any) => {
    const {
      rows,
      rowHeight,
      rowsClassNames,
      columnPayload,
      freeze,
      groupHeaderRenderer,
    } = this.props
    const { gRows, columns, totalColumnsWidth, itemData, freezeWidth, depth } =
      this.state

    const gRow = gRows![index]

    if (!gRow) {
      return null
    }

    if (hasOwn(gRow, 'ft')) {
      // @ts-ignore
      return this.renderFt(gRow, style)
    }

    if (hasOwn(gRow, 'group')) {
      return (
        <GroupHeader
          key={index}
          group={gRow as GroupHeaderRow}
          originalRows={rows}
          groupHeaderRenderer={groupHeaderRenderer}
          style={style}
          depth={depth}
          totalColumnsWidth={totalColumnsWidth}
          columns={columns}
          columnPayload={columnPayload}
          // @ts-ignore
          collapsed={itemData.collapsed.includes(gRow.key)}
          freeze={freeze || 0}
          freezeWidth={freezeWidth}
          onToggle={this.handleGroupToggle}
        />
      )
    }

    // @ts-ignore
    const row = rows[gRow[0]]
    const rowStyle = {
      ...style,
      width: totalColumnsWidth,
      height: rowHeight || 32,
    }

    // @ts-ignore
    const className = rowsClassNames && rowsClassNames[row?._id]

    return (
      <Row
        key={row._id}
        row={row}
        // @ts-ignore
        rowIndex={gRow}
        className={className}
        mask={itemData.mask || {}}
        depth={depth}
        columns={columns}
        columnPayload={columnPayload}
        freeze={freeze || 0}
        freezeWidth={freezeWidth}
        hovered={itemData.hoveredRow === row._id}
        style={rowStyle}
        onHover={this.handleHoverRow}
      />
    )
  }

  renderRow = ({ style, index }: any) => {
    const { rows, rowsClassNames, rowHeight, columnPayload, freeze } =
      this.props
    const { columns, totalColumnsWidth, itemData, freezeWidth } = this.state

    const row = rows[index]
    const rowStyle = {
      ...style,
      width: totalColumnsWidth,
      height: rowHeight || 32,
    }

    // @ts-ignore
    const className = rowsClassNames && rowsClassNames[row._id]

    return (
      <Row
        key={row._id}
        className={className}
        row={row}
        rowIndex={[void 0, index]}
        mask={itemData.mask || {}}
        columns={columns}
        columnPayload={columnPayload}
        freeze={freeze || 0}
        freezeWidth={freezeWidth}
        hovered={itemData.hoveredRow === row._id}
        style={rowStyle}
        onHover={this.handleHoverRow}
      />
    )
  }

  renderHeader = () => {
    const { columnPayload, headerRenderer, freeze = 0, grouping } = this.props
    const { columns, totalColumnsWidth, freezeWidth } = this.state

    return (
      <Header
        // @ts-ignore
        ref={this.header}
        grouping={grouping}
        columns={columns}
        totalColumnsWidth={totalColumnsWidth}
        headerRenderer={headerRenderer}
        payload={columnPayload}
        freeze={freeze}
        freezeWidth={freezeWidth || 0}
        onStartResize={this.handleStartResize}
      />
    )
  }

  renderInnerElement = ({
    style,
    children,
  }: {
    style: Record<string, any>
    children: JSX.Element
  }) => {
    const elStyle = {
      ...style,
      width: this.state.totalColumnsWidth + 15,
    }

    return <div style={elStyle}>{children}</div>
  }

  render() {
    const { width, height, gRows, itemData, depth } = this.state
    const { grouping } = this.props

    let cn = this.state.cn

    if (depth > 0) {
      cn += ` gd-${depth - 1}`
    }

    return (
      <div className={cn} ref={this.c}>
        {this.renderHeader()}

        <VariableSizeList
          ref={this.listApi}
          outerRef={this.list}
          height={height}
          width={width}
          itemCount={gRows!.length}
          itemSize={this.getItemSize}
          itemData={itemData}
          overscanCount={this.getOverscanCount()}
          innerElementType={this.renderInnerElement}>
          {grouping ? this.renderGroupedRow : this.renderRow}
        </VariableSizeList>
      </div>
    )
  }
}

export default IorvoGrid
