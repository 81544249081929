import React, { useCallback, useMemo } from 'react'
import { IorvoGroupHeaderProps } from '../../../../components/iorvo-grid/types/IorvoGridProps'
import { GridRow } from '../../../../components/grid/types/GridRow'
import { GroupRendererFactory } from '../../../../components/_renderers/factory'
import { GridColumn } from '../../../../components/grid/types/GridColumn'
import {
  aggregateGroupValue,
  buildAggMenu,
} from '../../../../utils/aggregation'
import { AggregationType } from '../../../../types/BaseView'
import {
  isRoleAllowed,
  UIRoleAction,
} from '../../../../components/roles/RoleGateway'
import Icon from '../../../../components/icon/Icon'
import { DropdownMenu } from '../../../../components/dropdown/DropdownMenu'
import { TableActions } from '../../../../store/actions/TableActions'
import get from 'lodash.get'
import { cn } from '../../../../utils/classname'

type Props = IorvoGroupHeaderProps<GridColumn, any> & {
  originalRows: GridRow[]
}

const GridGroupSummary: React.FC<Props> = ({
  originalRows,
  rows,
  main,
  group,
  column,
  payload,
}) => {
  const aggType = useMemo(
    () => get(group.agg, column._id, main ? AggregationType.COUNT : null),
    [column, main, group],
  )

  const aggValue = useMemo(() => {
    if (aggType) {
      return aggregateGroupValue(
        payload.table,
        column,
        (rows || []).map(index => originalRows[index]),
        aggType,
      )
    }
  }, [aggType, rows, originalRows, column, payload])

  const isAllowedToEdit: boolean = useMemo(
    () => isRoleAllowed(UIRoleAction.VIEW_EDIT, payload.userRole),
    [payload],
  )
  const summaryMenu = useMemo(() => {
    return isAllowedToEdit ? buildAggMenu(column, main) : null
  }, [isAllowedToEdit, column, main])

  const handleSelectAgg = useCallback(
    ({ key }) => {
      const viewGroups = payload.view.groups || []
      const updatedGroups = viewGroups.map(g => {
        if (g.columnId !== group.columnId) {
          return g
        }

        return {
          ...g,
          agg: {
            ...g.agg,
            [column._id]: key,
          },
        }
      })

      payload.onAction(TableActions.UPDATE_VIEW, {
        groups: updatedGroups,
      })
    },
    [payload, group, column._id],
  )

  const containerCn: string = useMemo(() => {
    return cn('irv-summary', {
      ae: isAllowedToEdit, // Editable
      hv: isAllowedToEdit && aggValue, // Has smth to show
    })
  }, [payload, isAllowedToEdit])

  const agg = useMemo(() => {
    if (aggValue) {
      return (
        <div className={'agg'}>
          {aggValue.label && <span>{aggValue.label}</span>}
          {aggValue.value}
        </div>
      )
    }
  }, [aggValue])

  if (!group) {
    return null
  }

  return (
    <div className={containerCn}>
      {main && (
        <div className={'irv-grp-by'}>
          <div className="irv-grp-by-col">{group?.column?.name}</div>
          <div className="irv-grp-by-value">
            {(group?.value && group.column) ? (
              <GroupRendererFactory
                column={group.column}
                value={group.value}
                table={payload.table}
                payload={payload}
              />
            ) : (
              '(Empty)'
            )}
          </div>
        </div>
      )}

      {isAllowedToEdit ? (
        <DropdownMenu
          onClick={handleSelectAgg}
          items={summaryMenu}
          selectedKeys={[aggType]}>
          {agg || (
            <div className={'agg-hint'}>
              <Icon type={'chevron-down'} />
              Summary
            </div>
          )}
        </DropdownMenu>
      ) : (
        agg
      )}

      {/*{summaryMenu && (*/}
      {/*  <DropdownMenu*/}
      {/*    onClick={handleSelectAgg}*/}
      {/*    items={summaryMenu}*/}
      {/*    selectedKeys={[aggType]}>*/}
      {/*    <div className={'agg-hint'}>*/}
      {/*      <Icon type={'chevron-down'} />*/}
      {/*      Summary*/}
      {/*    </div>*/}
      {/*  </DropdownMenu>*/}
      {/*)}*/}

      {/*{aggValue && (*/}
      {/*  <div className={'agg'}>*/}
      {/*    {aggValue.label && <span>{aggValue.label}</span>}*/}
      {/*    {aggValue.value}*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  )
}

export default React.memo(GridGroupSummary)
