import { Event as CalendarEvent } from '../components/calendar/NEW__types/Event'

export type Event = CalendarEvent & {
  rrule?: any
}

export enum EventFrequency {
  YEARLY = 0,
  MONTHLY = 1,
  WEEKLY = 2,
  DAILY = 3,
  HOURLY = 4,
  MINUTELY = 5,
  SECONDLY = 6,
  NONE = 'none',
  CUSTOM = 'custom'
}
