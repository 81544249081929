import { chain } from './chain'
import { cn } from './cn'

interface Props {
  [key: string]: any
}

const idsUpdaterMap: Map<string, (v: string) => void> = new Map()

// taken from: https://stackoverflow.com/questions/51603250/typescript-3-parameter-list-intersection-type/51604379#51604379
type TupleTypes<T> = { [P in keyof T]: T[P] } extends { [key: number]: infer V }
  ? V
  : never
// eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars
type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I,
) => void
  ? I
  : never

export function mergeIds(idA: string, idB: string): string {
  if (idA === idB) {
    return idA
  }

  let setIdA = idsUpdaterMap.get(idA)
  if (setIdA) {
    setIdA(idB)
    return idB
  }

  let setIdB = idsUpdaterMap.get(idB)
  if (setIdB) {
    setIdB(idA)
    return idA
  }

  return idB
}

export function mergeProps<T extends Props[]>(
  ...args: T
): UnionToIntersection<TupleTypes<T>> {
  // Start with a base clone of the first argument. This is a lot faster than starting
  // with an empty object and adding properties as we go.
  const result: Props = { ...args[0] }
  for (let i = 1; i < args.length; i++) {
    const props = args[i]
    for (const key in props) {
      const a = result[key]
      const b = props[key]

      // Chain events
      if (
        typeof a === 'function' &&
        typeof b === 'function' &&
        // This is a lot faster than a regex.
        key[0] === 'o' &&
        key[1] === 'n' &&
        key.charCodeAt(2) >= /* 'A' */ 65 &&
        key.charCodeAt(2) <= /* 'Z' */ 90
      ) {
        result[key] = chain(a, b)

        // Merge classnames, sometimes classNames are empty string which eval to false, so we just need to do a type check
      } else if (
        (key === 'className' || key === 'UNSAFE_className') &&
        typeof a === 'string' &&
        typeof b === 'string'
      ) {
        result[key] = cn(a, b)
      } else if (key === 'id' && a && b) {
        result.id = mergeIds(a, b)
        // Override others
      } else {
        result[key] = b !== undefined ? b : a
      }
    }
  }

  return result as UnionToIntersection<TupleTypes<T>>
}
