import { SelectProps } from './types/Select'
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import Popover from '../../overlays/Popover'
import SelectItems from './SelectItems'
import { useSelectState } from './useSelectState'
import { cn } from '../../utils/cn'
import { isMob } from '../../../../utils/is'
import Tray from '../../overlays/Tray'
import { asArray } from '../../../../utils/as'
import { HiddenSelect } from './HiddenSelect'
import { isKeyPressed, KEY_CODES } from '../../utils/keyboard'
import NEWIcon from '../../../icon/NEWIcon'
import Tag from '../tag/Tag'
import { isMobileDevice } from '../../utils/platform'

import './styles.scss'

const getRealValue = (items: any[], selectedValue) => {
  const valueObj = items.find(({ value }) => '' + value === selectedValue)
  return valueObj?.value
}

const Select = (props: SelectProps, ref: any) => {
  const {
    id,
    children,
    className,
    dropdownClassName,
    valueRenderer,
    dropdownWidth,
    showSearch,
    searchKeys = ['text'],
    theme,
    mode,
    fit,
    quiet,
    hideSelected,
    items,
    disabled,
    placeholder,
    placement,
    onSelect,
  } = props

  const [uncontrolledValue, setUncontrolledValue] = useState(
    props.value || props.defaultValue,
  )

  const value = props.value === undefined ? uncontrolledValue : props.value

  const displayValue = useMemo<any[]>(() => {
    const finalValue = asArray(value, true)
    return items.filter(i => finalValue.includes(i.value))
  }, [value, items])

  const selectedKeys = useMemo<string[]>(() => {
    return displayValue.map(v => v.value)
  }, [displayValue])

  const triggerRef = useRef(null)
  const listboxRef = useRef(null)
  const popoverRef = useRef(null)

  const searchInputRef = useRef(null)
  const [searchValue, setSearchValue] = useState('')

  const isMultiple: boolean = mode === 'multiple'

  const state = useSelectState(props)
  const selectClassName = cn(
    'irv-select',
    {
      open: state.isOpen,
      fit,
      disabled,
      quiet,
    },
    theme ? `irv-select-${theme}` : '',
    className,
  )

  const triggerClassName = cn('irv-select-trigger', {
    multiple: isMultiple,
  })

  const [popoverStyles, setOverlayStyles] = useState(null)

  const triggerStyle = useMemo(() => {
    if (props.width) {
      return {
        width: props.width,
      }
    }
  }, [props.width])

  const filteredItems = useMemo(() => {
    if (searchValue) {
      const re = new RegExp(searchValue, 'i')

      return items.filter(item => {
        const itemValue = searchKeys.map(k => item[k]).join(' ')

        return re.test(itemValue)
      })
    }

    if (isMultiple && hideSelected !== false) {
      return items.filter(item => !asArray(value).includes(item.value))
    }

    return items
  }, [items, searchValue, isMultiple, hideSelected])

  const handleSelect = val => {
    const realValue = getRealValue(items, val)

    let resultValue

    if (isMultiple) {
      const currentValue = asArray(value)

      if (currentValue.includes(val)) {
        resultValue = currentValue.filter(v => v !== val)
      } else {
        resultValue = Array.from(new Set(asArray(value).concat(val)))
      }
    } else {
      resultValue = realValue
    }

    if (props.value === undefined) {
      setUncontrolledValue(resultValue)
    }

    if (onSelect) {
      onSelect(resultValue)
    }

    setSearchValue('')

    if (!isMultiple) {
      state.close()
    }
  }

  const handleSearchBlur = useCallback(
    e => {
      if (popoverRef?.current && popoverRef.current.__getDOMNode) {
        const popoverNode = popoverRef.current.__getDOMNode()

        if (!popoverNode.contains(e.relatedTarget)) {
          return state.close()
        }
      }

      setTimeout(() => {
        // state.isOpen && state.close()
        // setSearchValue('')
      }, 100)
    },
    [showSearch, state.isOpen],
  )

  const handleSearchClear = useCallback(() => {
    setSearchValue('')
  }, [state.isOpen])

  const handleRemove = useCallback(
    targetVal => {
      const resultValue = asArray(value).filter(value => value !== targetVal)

      if (props.value === undefined) {
        setUncontrolledValue(resultValue)
      }

      if (onSelect) {
        onSelect(resultValue)
      }

      if (popoverRef?.current && popoverRef.current.__getDOMNode) {
        const popoverNode = popoverRef.current.__getDOMNode()
        popoverNode?.focus()
      }
    },
    [items, value],
  )

  const handleSearchKeyup = useCallback(
    e => {
      if (state.isOpen && isKeyPressed(e.key, [KEY_CODES.ESCAPE])) {
        state.close()
        setSearchValue('')
      }
    },
    [state.isOpen],
  )

  const handleSearchChange = useCallback(
    e => {
      setSearchValue(e.target.value)
    },
    [items, searchValue],
  )

  useEffect(() => {
    if (triggerRef) {
      setOverlayStyles({
        minWidth: triggerRef.current.clientWidth,
        maxHeight: 400,
      })
    }
  }, [triggerRef])

  // Фокусируем поиск
  useEffect(() => {
    if (!showSearch || !searchInputRef?.current) {
      return
    }

    const timer = setTimeout(() => {
      if (searchInputRef?.current) {
        searchInputRef?.current.focus()
      }
    }, 200)

    return () => {
      clearTimeout(timer)
    }
  }, [showSearch, searchInputRef, state.isOpen])

  const renderValue = useCallback(
    v => {
      if (valueRenderer) {
        return valueRenderer({
          item: v,
          onRemove: () => handleRemove(v.value),
        })
      }

      if (isMultiple) {
        return (
          <Tag key={v.value} rounded onRemove={() => console.log('remove')}>
            {v.text || v.value}
          </Tag>
        )
      }

      return v.text || v.value
    },
    [valueRenderer, handleRemove, mode],
  )

  const searchInput = (
    <input
      value={searchValue}
      onChange={handleSearchChange}
      onKeyUp={handleSearchKeyup}
      onBlur={handleSearchBlur}
      type={'text'}
      placeholder={isMultiple ? 'Пошук значення' : ''}
      className={'search'}
      ref={searchInputRef}
    />
  )

  const showHeader: boolean = isMultiple && showSearch ? true : false

  const itemsBox = (
    <div className={'irv-select-box'}>
      {showHeader && <div className="irv-select-header">{searchInput}</div>}
      <SelectItems
        items={filteredItems}
        dropdownWidth={dropdownWidth}
        selectedKeys={selectedKeys}
        onSelect={handleSelect}
        ref={listboxRef}>
        {children}
      </SelectItems>
    </div>
  )

  const overlay = isMob() ? (
    <Tray
      open={state.isOpen}
      onClose={state.close}
      className={dropdownClassName}>
      {itemsBox}
    </Tray>
  ) : (
    <Popover
      ref={popoverRef}
      className={dropdownClassName}
      placement={placement || 'bottom left'}
      popoverRef={popoverRef}
      triggerRef={triggerRef}
      scrollRef={listboxRef}
      open={state.isOpen}
      style={popoverStyles}
      onClose={state.close}>
      {itemsBox}
    </Popover>
  )

  const triggerValue =
    value === undefined ? placeholder : displayValue.map(renderValue)

  return (
    <div className={selectClassName}>
      {isMobileDevice() && <HiddenSelect />}

      <button
        className={triggerClassName}
        ref={triggerRef}
        style={triggerStyle}
        disabled={disabled}
        id={id}
        onClick={state.open}>
        {showSearch && state.isOpen && !isMultiple && searchInput}

        {!searchValue || isMultiple ? (
          <div className={'irv-select-value'}>{triggerValue}</div>
        ) : null}

        {searchValue && !isMultiple ? (
          <button className={'clear'} onClick={handleSearchClear}>
            <NEWIcon type={'cross-small'} />
          </button>
        ) : (
          <svg
            focusable="false"
            className={'icon'}
            width={16}
            height={16}
            role="img">
            <path d="M9.99 1.01A1 1 0 0 0 8.283.303L5 3.586 1.717.303A1 1 0 1 0 .303 1.717l3.99 3.98a1 1 0 0 0 1.414 0l3.99-3.98a.997.997 0 0 0 .293-.707z"></path>
          </svg>
        )}
      </button>

      {overlay}
    </div>
  )
}

export default forwardRef(Select)
