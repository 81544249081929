import React from 'react'
import {FilterRendererProps} from "../FilterRendererFactory";
import Select from "antd/es/select";
import {isMob, isUserRemoved} from "../../../utils/is";
import UserBadge from "../../__renderers/user/UserBadge";
import {getCurrentUserMarkup} from "../../../utils/get";
import {EyzyUser} from "../../../types/EyzyUser";
import buildTagRenderer from "../../user/TagRenderer";
import {removeItem} from "../../../utils/array";

const {Option, OptGroup} = Select

interface Groups {
  text: string
  users: EyzyUser[]
}

export default React.memo(function UserFilterRenderer(props: FilterRendererProps) {
  const {value} = props.filter
  const currentUser = getCurrentUserMarkup()
  const usersMap = {
    currentUser
  }

  const groups: Groups[] = [{
    text: 'Спец. значения',
    users: [currentUser]
  }];

  (props.payload.users || []).forEach((user: EyzyUser) => {
    const isRemoved: boolean = isUserRemoved(user)
    const groupKey: number = isRemoved ? 2 : 1
    const groupLabel: string = isRemoved
      ? 'Удаленные пользователи'
      : 'Активные пользователи'

    if (!groups[groupKey]) {
      groups[groupKey] = {
        text: groupLabel,
        users: []
      }
    }

    usersMap[user._id] = user
    groups[groupKey].users.push(user)
  })

  const handleChange = (value: string[]) => {
    props.onChange({value})
  }

  const tagRenderer = buildTagRenderer(usersMap, userId => {
    handleChange(
      removeItem(value, item => item === userId)
    )
  })

  return (
    <Select
      showSearch={!isMob()}
      value={value}
      optionFilterProp={'label'}
      onChange={handleChange}
      getPopupContainer={trigger => trigger.parentNode}
      dropdownMatchSelectWidth={false}
      mode={'multiple'}
      tagRender={tagRenderer}
    >
      {groups.map((group, i) => (
        <OptGroup key={i} label={group.text}>
          {group.users.map(user => (
            <Option key={user._id} value={user._id} label={user.fullName}>
              <UserBadge key={user._id} user={user}/>
            </Option>
          ))}
        </OptGroup>
      ))}
    </Select>
  )
})
