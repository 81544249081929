import React, { memo } from 'react'
import { FormRendererProps } from '../types/FormRenderer'
import LookupRenderer from '../utils/LookupRenderer'

const FormLookupRenderer: React.FC<FormRendererProps> = ({ value, column }) => {
  return (
    <LookupRenderer
      value={value}
      column={column}
      params={column.params.foreignParams}
    />
  )
}

export default memo(FormLookupRenderer)
