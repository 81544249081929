import React, { useMemo } from 'react'
import { IrvDialogProps } from './types/Dialog'
import Modal from '../../overlays/Modal'
import { ModalProps } from '../../overlays/types/ModalProps'
import Button from '../button/Button'
import { isMobileDevice } from '../../utils/platform'
import { useViewportSize } from '../../hooks/useViewportSize'

import './styles.scss'

const DEFAULT_WIDTH: number = 520

const Dialog: React.FC<IrvDialogProps> = ({
  onClose,
  header,
  footer,
  children,
  width,
  alignSelf,
  maxHeight,
  type,
  ...restProps
}) => {
  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }

  const style = useMemo(() => {
    const s: any = {
      width: width || DEFAULT_WIDTH,
    }

    if (alignSelf) {
      s.alignSelf = alignSelf
    }

    if (maxHeight) {
      s.maxHeight = maxHeight
    }

    return s
  }, [width, alignSelf])

  const { width: windowWidth } = useViewportSize()

  const modalType = useMemo(() => {
    if (type) {
      return type
    }

    if (isMobileDevice()) {
      return 'fullscreen'
    }
  }, [type, windowWidth])

  return (
    <Modal
      {...(restProps as ModalProps)}
      style={style}
      type={modalType}
      onClose={handleClose}>
      {restProps.open ? (
        <>
          {header !== null && (
            <div className="irv-modal-header">
              {header && <div className={'irv-modal-title'}>{header}</div>}

              <Button variant={'minimal'} onClick={handleClose}>
                <svg
                  focusable="false"
                  aria-hidden="true"
                  role="img"
                  width={12}
                  height={12}>
                  <path d="M11.697 10.283L7.414 6l4.283-4.283A1 1 0 1 0 10.283.303L6 4.586 1.717.303A1 1 0 1 0 .303 1.717L4.586 6 .303 10.283a1 1 0 1 0 1.414 1.414L6 7.414l4.283 4.283a1 1 0 1 0 1.414-1.414z"></path>
                </svg>
              </Button>
            </div>
          )}

          <div className="irv-modal-content">{children}</div>

          {footer && <div className="irv-modal-footer">{footer}</div>}
        </>
      ) : null}
    </Modal>
  )
}

export default Dialog
