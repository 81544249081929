import React, { memo } from 'react'
import { ContainersProps } from '../types/ContainersProps'

const TableContainer: React.FC<ContainersProps> = ({
  className,
  rows,
  headers,
  children,
  empty,
  onBodyClick,
}) => {
  return (
    <table className={className}>
      <thead>
        <tr>
          {headers.map((header, i) => (
            <th {...header.th} className={header.className} key={i}>
              {header.title}
            </th>
          ))}
        </tr>
      </thead>

      <tbody onClick={onBodyClick}>
        {rows.map((row, i) => {
          const tr = (
            <tr
              {...row.props}
              key={row.key}
              data-key={row.key}
              className={row.className || null}>
              {row.cells.map(cell => (
                <td key={cell.key} {...cell.props} className={cell.className}>
                  {cell.render}
                </td>
              ))}
            </tr>
          )

          if (row.state.expanded && children) {
            return (
              <>
                {tr}
                <tr className={'expanded-data'} key={i}>
                  <td colSpan={headers.length}>{children({ row })}</td>
                </tr>
              </>
            )
          }

          return tr
        })}

        {!rows.length && empty ? (
          <tr className={'row'}>
            <td colSpan={headers.length} className={'row-cell no-data'}>
              {empty}
            </td>
          </tr>
        ) : null}
      </tbody>
    </table>
  )
}

export default memo(TableContainer)
