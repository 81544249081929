import React from 'react'
import { BaseRendererProps } from '../types/BaseRenderer'
import { RendererClassNames } from '../definitions'
import { format } from '../../../general/utils/date'

const DateRenderer: React.FC<BaseRendererProps> = ({ column, value }) => {
  const formattedValue = value
    ? format(value, column.params.format, column.params.timeFormat, true)
    : ''

  return <div className={RendererClassNames.DATE}>{formattedValue}</div>
}

export default React.memo(DateRenderer)
