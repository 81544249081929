import React from 'react'
import small from '../../assets/img/logo/logo.svg'
import main from '../../assets/img/logo/logo_b.svg'

interface Props {
  small?: number | boolean
}

export default React.memo(function Logo(props: Props) {
  return (
    <img src={props.small ? small : main} alt="iorvo.com" className={'logo'} />
  )
})
