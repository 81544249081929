import React, { useCallback, useMemo } from 'react'
import {
  ViewDetailsBlock,
  ViewDetailsProfile,
  ViewDetailsProfileTab,
} from '../../../types/ViewDetails'
import BlockFactory from '../blocks/BlockFactory'
import { hasViewAccess } from '../../../general/utils/view'
import NEWIcon from '../../icon/NEWIcon'
import { Input, Tabs } from '../../irv-ui'
import { FormDetailsProps } from '../types/FormDetails'
import { FormProvider } from '../../_renderers/RendererContext'
import { BlockProvider } from '../blocks/BlockProvider'
import { GridRow } from '../../grid/types/GridRow'
import { GridColumn } from '../../grid/types/GridColumn'

type Props = Omit<FormDetailsProps, 'layout' | 'row'> & {
  data: GridRow
  fields: GridColumn[]
}

const ProfileDetails: React.FC<Props> = ({
  view,
  data,
  fields,
  payload,
  user,
  onChange,
  onCommit,
  readonly,
}) => {
  const template: ViewDetailsProfile = view.details.template
  const templateInfo: ViewDetailsBlock[] = template.info || []

  const name = data[template.name] || ''

  const handleChangeName = useCallback(
    e => {
      if (readonly) {
        return
      }

      const column = fields.find(c => c._id === template.name)

      if (!column || name === e.target.value) {
        return
      }

      onCommit(column, e.target.value)
    },
    [name, readonly, fields, template],
  )

  const getTabLabel = useCallback((tab: ViewDetailsProfileTab) => {
    if (tab.visibility) {
      return (
        <div>
          {tab.name}
          <NEWIcon type={'b-lock'} />
        </div>
      )
    }

    return tab.name
  }, [])

  // Пока так. Потом можно будет создавать табы
  const tabs: ViewDetailsProfileTab[] = useMemo(() => {
    return template.tabs.filter(tab => {
      return hasViewAccess(tab, user)
    })
  }, [template])

  const blockProviderValue = {
    onCommit,
    onChange,
    readonly,
    fields,
    data,
    view,
    users: payload.users,
  }

  return (
    <FormProvider.Provider value={payload}>
      <BlockProvider.Provider value={blockProviderValue}>
        <div className={'profile-details'}>
          <div className={'profile-details-info'}>
            {template.name && (
              <div className={'profile-name'}>
                <Input
                  fit
                  readOnly={readonly}
                  defaultValue={name}
                  onBlur={handleChangeName}
                />
              </div>
            )}

            {templateInfo.map((block, i) => (
              <BlockFactory block={block} key={i} />
            ))}
          </div>

          <div className="profile-details-tabs">
            {tabs.length > 1 ? (
              <Tabs>
                {tabs.map((tab, i) => (
                  <Tabs.Tab label={getTabLabel(tab)} key={i}>
                    {tab.blocks.map((block, j) => (
                      <BlockFactory block={block} key={j} />
                    ))}
                  </Tabs.Tab>
                ))}
              </Tabs>
            ) : (
              tabs.map((tab, i) => (
                <div className="profile-details-tab" key={i}>
                  {tab.blocks.map((block, j) => (
                    <BlockFactory block={block} key={j} />
                  ))}
                </div>
              ))
            )}
          </div>
        </div>
      </BlockProvider.Provider>
    </FormProvider.Provider>
  )
}

export default ProfileDetails
