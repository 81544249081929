import React, { FunctionComponent, useMemo } from 'react'
import { IorvoGridColumn } from '../../types/IorvoGridColumn'
import {
  GroupHeaderRow,
  IorvoGroupHeaderProps,
} from '../../types/IorvoGridProps'
import Caret from '../../components/Caret'

interface Props {
  column: IorvoGridColumn
  columnPayload: any
  group: GroupHeaderRow
  groupHeaderRenderer?: FunctionComponent<IorvoGroupHeaderProps>
  originalRows: any[]
  caret?: boolean
  onToggle: () => void
}

const GroupHeaderCell: React.FC<Props> = ({
  onToggle,
  caret,
  column,
  columnPayload,
  group,
  groupHeaderRenderer: Renderer,
}) => {
  const style = useMemo(() => {
    return {
      width: column.width || 150,
    }
  }, [column])

  const res = Renderer ? (
    <Renderer
      group={group.group}
      payload={columnPayload}
      rows={group.rows || []}
      column={column}
      main={caret}
    />
  ) : null

  return (
    <div className={'irv-group-cell'} style={style}>
      {caret && <Caret handler={onToggle} />}
      {res}
    </div>
  )
}

export default React.memo(GroupHeaderCell)
