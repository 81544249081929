import React, { useCallback, useEffect, useLayoutEffect } from 'react'
import { BaseTextAreaProps } from './types/TextAreaProps'
import { parseWidth } from '../../common/dom'
import { isEnter, isEsc } from '../../common/keyboard'
import { cn } from '../../../../utils/classname'

const TextArea: React.FC<BaseTextAreaProps> = (
  {
    autoHeight,
    autoFocus,
    className,
    style,
    width,
    onPressEnter,
    onPressEsc,
    onKeyDown,
    onChange,
    ...rest
  },
  textAreaRef: any,
) => {
  const classNames = cn(
    'e-textarea',
    {
      'auto-height': autoHeight,
    },
    className,
  )

  const handleInput = useCallback(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto'
      textAreaRef.current.style.height = `${
        textAreaRef.current?.scrollHeight + 5
      }px`
    }
  }, [textAreaRef])

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (onChange) {
      onChange(e.target.value, e)
    }
  }

  const handleKeyDown = (e: any) => {
    if (onPressEnter && isEnter(e)) {
      onPressEnter(e)
    }

    if (onPressEsc && isEsc(e)) {
      onPressEsc(e)
    }

    if (onKeyDown) {
      onKeyDown(e)
    }
  }

  useEffect(() => {
    if (textAreaRef && textAreaRef.current && autoHeight) {
      textAreaRef.current.addEventListener('input', handleInput, false)
      handleInput()
    }

    return () => {
      if (textAreaRef && textAreaRef.current) {
        // eslint-disable-next-line
        textAreaRef.current.removeEventListener('input', handleInput, false)
      }
    }
  }, [autoHeight, handleInput, textAreaRef])

  useEffect(() => {
    if (autoFocus && textAreaRef) {
      textAreaRef.current.selectionStart = textAreaRef.current.value.length
    }
    // eslint-disable-next-line
  }, [autoFocus, textAreaRef])

  useEffect(() => {
    // if (autoFocus) {
    //   setTimeout(_ => {
    //     textAreaRef.current.focus()
    //   }, 300)
    // }
  }, [])

  useLayoutEffect(() => {
    if (autoFocus && textAreaRef.current !== document.activeElement) {
      setTimeout(() => {
        textAreaRef.current.focus()
      }, 150)
    }
  })

  const parsedWidth = parseWidth(width)
  const elemStyle = style || {}

  if (parsedWidth) {
    elemStyle.width = parsedWidth
  }

  return (
    // @ts-ignore
    <textarea
      {...rest}
      ref={textAreaRef}
      autoFocus={autoFocus}
      className={classNames}
      style={elemStyle}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
    />
  )
}

// @ts-ignore
export default React.forwardRef(TextArea)
