const ColumnType = {
  TEXT: 'text',
  LONG_TEXT: 'long',
  SINGLE_SELECT: 'single-select',
  MULTIPLE_SELECT: 'mul-select',
  DATE: 'date',
  EMAIL: 'email',
  SOCIAL: 'social',
  CHECKBOX: 'checkbox',
  PHONE: 'phone',
  LINK: 'link',
  NUMBER: 'number',
  LINK_TO_RECORD: 'record',
  ACTION: 'action',
  COUNT: 'count',
  FORMULA: 'formula',
  LOOKUP: 'lookup',
  ROLLUP: 'rollup',
  USER: 'user',
  CREATION_DATE: 'createdOn',
  CREATOR: 'createdBy',
  MODIFIED_BY: 'modifiedBy',
  MODIFIED_ON: 'modifiedOn',
  RECORD_INDEX: 'rIndex',
  JSON: 'json',
  MIRROR: 'mirror',
  LIST: 'list'
}

export {ColumnType}
