import React, { memo, useMemo } from 'react'
import { FormRendererProps } from '../types/FormRenderer'
import { SimpleRendererFactory } from '../factory'
import { getColumnById, mergeColumnWithViewParams } from '../../../utils/get'
import { ColumnType } from '../../../general/types/ColumnType'
import { useFormPayload } from '../RendererContext'
import { GridColumn } from '../../grid/types/GridColumn'
import { getValue } from '../../../general/utils/value'

const FormMirrorRenderer: React.FC<FormRendererProps> = ({ column }) => {
  const { table, view, row } = useFormPayload()
  const mirroredColumn = useMemo(() => {
    const mCol: GridColumn | undefined = getColumnById(
      table,
      column.params.columnId,
    )

    if (!mCol) {
      return
    }

    return {
      ...mCol,
      params: mergeColumnWithViewParams(view, mCol),
    }
  }, [table, view, column])

  const value = useMemo(() => {
    return getValue(table, mirroredColumn, row)
  }, [mirroredColumn, table, row])

  if (!mirroredColumn || mirroredColumn.type === ColumnType.MIRROR) {
    return null
  }

  return (
    <SimpleRendererFactory
      column={mirroredColumn}
      value={value}
      params={mirroredColumn.params}
    />
  )
}

export default memo( FormMirrorRenderer)
