import React from "react"
import { Button } from "../../../../../../components/irv-ui"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { BaseView, SortOrderType, ViewSort } from "../../../../../../types/BaseView"
import { BaseTable } from "../../../../../../types/BaseTable"
import { EyzyUser } from "../../../../../../types/EyzyUser"
import { ActionType } from "../../../../../../store/CommonEffects"
import { isEmpty } from "../../../../../../general/utils/is"
import { TableActions } from "../../../../../../store/actions/TableActions"
import { getColumnById } from "../../../../../../utils/get"
import { GridColumn } from "../../../../../../components/grid/types/GridColumn"
import ColumnSelector from "../../../../../../components/columnHelpers/columnSelector/ColumnSelector"
import ColumnSortOrder from "../../../../../../components/columnHelpers/columnSortOrder/ColumnSortOrder"
import RemoveBtn from "../../../../../../components/icon/RemoveBtn"
import { reorder } from "../../../../../../utils/array"

interface Props {
  view: BaseView
  table: BaseTable
  users: EyzyUser[]
  onAction: (action: ActionType, payload: any) => void
}

export default class CustomizeSort extends React.PureComponent<Props> {
  removeSortItem = (columnId: string) => {
    const { view, onAction } = this.props

    if (isEmpty(view.sort)) {
      return
    }

    onAction(
      TableActions.UPDATE_VIEW,
      {
        sort: view.sort.filter(s => s.columnId !== columnId),
      },
    )
  }

  handleAdd = () => {
    const { table, view, onAction } = this.props
    const sort = view.sort ? [...view.sort] : []
    const alreadyUserColumns: string[] = sort.map(s => s.columnId)

    for (let i = 0; i < table.columns.length; i++) {
      if (!alreadyUserColumns.includes(table.columns[i]._id)) {
        sort.push({
          columnId: table.columns[i]._id,
          order: SortOrderType.ASC,
        })

        break
      }
    }

    onAction(
      TableActions.UPDATE_VIEW,
      {
        sort,
      },
    )
  }

  changeSort = (key: string, value: SortOrderType | string, index: number) => {
    const { view, onAction } = this.props

    if (isEmpty(view.sort)) {
      return
    }

    const sort: ViewSort[] = view.sort!.map((s: ViewSort, i: number) => {
      if (i !== index) {
        return s
      }

      if (key === "columnId") {
        return {
          order: s.order,
          columnId: value as string,
        }
      }

      return {
        columnId: s.columnId,
        order: value as SortOrderType,
      }
    })

    onAction(
      TableActions.UPDATE_VIEW,
      {
        sort,
      },
    )
  }

  renderSortItem = (viewSort: ViewSort, index: number) => {
    const text: string = index === 0 ? "Сортировать" : "затем"
    const column: GridColumn | undefined = getColumnById(this.props.table, viewSort.columnId)

    if (!column) {
      return null
    }

    return (
      <Draggable key={viewSort.columnId} draggableId={viewSort.columnId} index={index}>
        {(provided) => (
          <li ref={provided.innerRef}
              {...provided.draggableProps}
          >
            <i {...provided.dragHandleProps} className={`icon mdi mdi-drag`} />
            <span className={"sort-fields-prefix"}>{text}</span>
            <ColumnSelector
              hiddenColumns={this.getHiddenColumns(viewSort.columnId)}
              table={this.props.table}
              selectedItem={viewSort.columnId}
              onChange={columnId => this.changeSort("columnId", columnId, index)}
            />
            <ColumnSortOrder
              order={viewSort.order}
              column={column}
              onChange={order => this.changeSort("order", order, index)}
            />
            <RemoveBtn onClick={() => this.removeSortItem(viewSort.columnId)} />
          </li>
        )}
      </Draggable>
    )
  }

  handleDragEnd = (result: any) => {
    if (!result.destination) {
      return
    }

    const reOrdered = reorder(
      this.props.view.sort,
      result.source.index,
      result.destination.index,
    )

    this.props.onAction(
      TableActions.UPDATE_VIEW,
      {
        sort: reOrdered,
      },
    )
  }

  getHiddenColumns = (colId: string): string[] => {
    return (this.props.view.sort || [])
      .map(s => s.columnId)
      .filter(c => c !== colId)
  }

  render = () => {
    const sort = this.props.view.sort
    const hasSort: boolean = !isEmpty(sort)

    return (
      <div className={"sort-fields"}>
        <DragDropContext onDragEnd={this.handleDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <ul
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {!hasSort && (
                  <p className={"empty"}>К этому представлению не применены сортировки</p>
                )}

                {hasSort && sort.map((s, index) => this.renderSortItem(s, index))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
        <Button onClick={this.handleAdd}>+ Добавить</Button>
      </div>
    )
  }
}
