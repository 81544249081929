export enum Roles {
  OWNER = 10,
  DEPUTY = 8,
  ADMIN = 7,
  EDITOR = 6,
  MEMBER = 4,
  READ_ONLY = 2
}

export type RolesList = { [key: string]: Roles }

const RoleNames: { [key: string]: string} = {
  [Roles.OWNER]: 'Владелец',
  [Roles.DEPUTY]: 'Заместитель',
  [Roles.ADMIN]: 'Администратор',
  [Roles.EDITOR]: 'Редактор',
  [Roles.MEMBER]: 'Участник',
  [Roles.READ_ONLY]: 'Только чтение'
}

export function getRoleName(role: Roles): string {
  return RoleNames[role]
}