import React from 'react'
import {CalendarViewProps, DateLike, HashedEvents} from "../NEW__types/Calendar";
import {Event} from "../NEW__types/Event";
import {hashMonth, isWeekend} from "../utils";
import dayjs from "dayjs";
import CalendarEvent from '../containers/CalendarEvent';

import '../NEW__styles/calendar/month.scss'

const WEEKS_AMOUNT = 6

interface Cell {
  className: string
  day: DateLike
  text: any
  events: Event[]
  moreEvents?: Event[]
}

interface State {
  rows: Cell[][]
}

export default class MonthView extends React.PureComponent<CalendarViewProps, State> {
  container: React.RefObject<any> = React.createRef()

  constructor(props: CalendarViewProps) {
    super(props)

    this.state = {
      rows: MonthView.buildRows(props.active, props.events, props.viewportHeight)
    }
  }

  static buildRows(startDate: DateLike, events: HashedEvents, viewportHeight): Cell[][] {
    const today = dayjs()
    const activeDate = dayjs(startDate).date(1)
    const monthStart = activeDate.startOf('month')
    const monthEnd = activeDate.endOf('month')
    const allowedRows: number = ((viewportHeight - 30) / WEEKS_AMOUNT) / 35

    const getEvents = (date: DateLike): Event[] => {
      return (events[hashMonth(date)] || []).slice(0, allowedRows)
    }

    const getMoreEvents = (date: DateLike): Event[] | undefined => {
      const e = events[hashMonth(date)]

      if (!e || e.length <= allowedRows) {
        return
      }

      return e.slice(allowedRows)
    }

    const days: Cell[] = Array.from(Array(monthEnd.date()), (d, i) => {
      const day = activeDate.date(i + 1)

      let className = Math.abs(today.diff(day)) < 5000 ? ' today' : ''

      return {
        day,
        events: getEvents(day),
        moreEvents: getMoreEvents(day),
        text: 0 === i ? `${day.format('D MMM')}` : i + 1,
        className
      } as Cell
    })

    const missedLeftDays = (activeDate.day() - 1 + 7) % 7

    if (missedLeftDays) {
      let prevMonth = monthStart.clone()

      for (let i = 0, len = Math.abs(missedLeftDays); i < len; i++) {
        const day = prevMonth.subtract(i + 1, 'day')

        let className = 'inactive'

        if (isWeekend(day)) {
          className += ' weekend'
        }

        days.unshift({
          day,
          events: getEvents(day),
          moreEvents: getMoreEvents(day),
          text: day.date(),
          className
        })
      }
    }

    const missedRightDays = WEEKS_AMOUNT * 7 - days.length

    if (missedRightDays) {
      let nextMonth = monthEnd.add(1, 'day')

      for (let i = 0; i < missedRightDays; i++) {
        let className = 'inactive'

        if (isWeekend(nextMonth)) {
          className += ' weekend'
        }

        days.push({
          day: nextMonth.clone(),
          text: 0 === i ? `${nextMonth.format('D MMM')}` : nextMonth.date(),
          className,
          moreEvents: getMoreEvents(nextMonth),
          events: getEvents(nextMonth),
        })

        nextMonth = nextMonth.add(1, 'day')
      }
    }

    return Array.from(Array(WEEKS_AMOUNT), (d, i) => {
      return days.slice(i * 7, i * 7 + 7)
    })
  }

  componentDidUpdate(prevProps: Readonly<CalendarViewProps>) {
    const {active, events, viewportHeight} = this.props

    if (prevProps.events !== events || prevProps.active !== active) {
      this.setState({
        rows: MonthView.buildRows(active, events, viewportHeight)
      })
    }
  }

  renderRows = () => {
    const {moreRenderer, eventRenderer} = this.props
    const getMoreEl = (events: Event[]) => <span className={'c-cell-more'}>+{events.length} ещё</span>

    return this.state.rows.map((cells: Cell[], i) => (
      <div className={'c-row'} key={i}>
        {cells.map((cell, c) => (
          <div className={('c-cell ' + cell.className).trim()} key={cell.text}>
            <div className={'c-cell-head'}>
              <span className={'c-cell-text'}>
                {cell.text}
              </span>

              {cell.moreEvents && (
                moreRenderer
                  ? moreRenderer({
                    moreEvents: cell.moreEvents,
                    events: cell.events,
                    date: cell.day,
                    children: getMoreEl(cell.moreEvents)
                  })
                  : getMoreEl(cell.moreEvents)
              )}
            </div>
            <div className="c-cell-events" role={'presentation'}>
              {cell.events.map(e => (
                <CalendarEvent event={e} key={e.id} renderer={eventRenderer}/>
              ))}
            </div>
          </div>
        ))}
      </div>
    ))
  }

  render() {
    return (
      <div className={'c-month'}>
        <div className={'c-head'}>
          <span>пн</span>
          <span>вт</span>
          <span>ср</span>
          <span>чт</span>
          <span>пт</span>
          <span>сб</span>
          <span>вс</span>
        </div>
        <div className={'c-body'}>
          {this.renderRows()}
        </div>
      </div>
    )
  }
}
