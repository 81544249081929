import React from 'react'
import { Input } from '../../components/irv-ui'
import Logo from '../../components/logo/Logo'
import { baseUrl } from '../../utils/request'

import './style.scss'

interface State {
  rePassword: string
  password: string
  error: string
}

export default class ResetPasswordContainer extends React.Component<
  any,
  State
> {
  state = {
    rePassword: '',
    password: '',
    error: '',
  }

  handleChange = (name: string) => value => {
    this.setState({
      [name]: value,
    } as State)
  }

  handleSubmit = e => {
    const { password, rePassword } = this.state

    if (password !== rePassword) {
      e.preventDefault()

      return this.setState({ error: 'Пароли должны совпадать' })
    }

    if (password.length < 6) {
      e.preventDefault()

      return this.setState({ error: 'Слишком короткий пароль' })
    }
  }

  render() {
    const { password, rePassword, error } = this.state

    return (
      <div className="login">
        <Logo small={1} />
        <form
          className="login-form"
          method="post"
          action={baseUrl + 'user/resetPassword'}
          onSubmit={this.handleSubmit}>
          <input
            type={'hidden'}
            name={'authenticity_token'}
            value={this.props.match.params.token}
          />
          <legend>Изменение пароля</legend>
          <Input
            placeholder="Новый пароль"
            value={password}
            type="password"
            required
            onChange={this.handleChange('password')}
            name="password"
            spellCheck="false"
            autoFocus
          />
          <Input
            placeholder="Подтвердите Новый пароль"
            value={rePassword}
            type="password"
            onChange={this.handleChange('rePassword')}
            name="rePassword"
            required
          />

          {error && <p style={{ color: '#f72e50' }}>{error}</p>}

          <input type="submit" value="Изменить пароль" />
        </form>
      </div>
    )
  }
}
