import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Input, TextArea } from '../../../components/irv-ui'
import {
  ActionType,
  disturbEntity,
  DisturbEntityArguments,
} from '../../../store/CommonEffects'
import { bindActionCreators } from 'redux'
import ComplexMenu from '../../dropdown/ComplexMenu'
import { WorkspaceActions } from '../../../store/actions/WorkspaceActions'
import { isWSActionGranted, UIRoleAction } from '../../roles/RoleGateway'
import { Workspace } from '../../../types/Workspace'
import { EyzyUser } from '../../../types/EyzyUser'
import { EventKey, onKeyHandler } from '../../../utils/dom'
import { isMob } from '../../../utils/is'

type Props = ReduxProps & {
  children: React.ReactNode
  ws: Workspace
  user: EyzyUser
  onMenuClick: (action: ActionType) => void
}

const possibleMenuItems = [
  {
    key: WorkspaceActions.SETTINGS,
    text: 'Настройки рабочей области',
    uiAction: UIRoleAction.WORKSPACE_EDIT,
  },
  {
    key: WorkspaceActions.PERMISSIONS_SETTING,
    text: 'Настройка доступа',
    uiAction: UIRoleAction.WORKSPACE_SHARE,
  },
  {
    key: WorkspaceActions.REMOVE,
    text: 'Удалить',
    uiAction: UIRoleAction.WORKSPACE_REMOVE,
  },
]

function WSContextMenu(props: Props) {
  const { children, user, ws, disturbEntity, onMenuClick } = props
  const [entityName, setEntityName] = useState(ws.name)
  const [visible, setMenuVisibility] = useState(false)

  let keyHandlerState: null | (() => void)

  useEffect(() => {
    setEntityName(ws.name)
  }, [ws])

  const releaseKeyHandler = () => {
    if (keyHandlerState) {
      keyHandlerState()
    }

    keyHandlerState = null
  }

  const handleClickMenu = ({ key }) => {
    setMenuVisibility(false)
    onMenuClick(key as ActionType)
  }

  const handleChangeName = name => setEntityName(name)
  const saveName = () => {
    if (entityName.trim()) {
      if (entityName !== ws.name) {
        disturbEntity({
          entity: WorkspaceActions.UPDATE,
          id: ws._id,
          payload: { name: entityName },
        }).then(_ => {
          cancelEditing()
        })
      }
    } else {
      cancelEditing()
      setEntityName(ws.name)
    }
  }

  const onVisibleChange = v => {
    setMenuVisibility(v)

    if (v) {
      keyHandlerState = onKeyHandler([EventKey.ESC], () => {
        cancelEditing()
        releaseKeyHandler()
      })
    } else {
      releaseKeyHandler()
    }
  }

  const cancelEditing = () => {
    setMenuVisibility(false)
  }

  const menuItems = possibleMenuItems.filter(item =>
    isWSActionGranted(item.uiAction, user, ws),
  )

  const HeaderComponent = isMob() ? TextArea : Input
  const headerProps: any = {
    fit: true,
    autoFocus: true,
    value: entityName,
    className: 'din',
    onChange: handleChangeName,
    onBlur: saveName
  }

  if (isMob()) {
    headerProps.autoHeight = true
    headerProps.rows = 1
  } else {
    headerProps.onPressEnter = saveName
  }

  const header = <HeaderComponent {...headerProps} />

  return (
    <ComplexMenu
      menu={menuItems}
      headerTitle={'Рабочая область'}
      header={header}
      overlayClassName={'sb-smenu'}
      visible={visible}
      onClick={handleClickMenu}
      onVisibleChange={onVisibleChange}>
      {children}
    </ComplexMenu>
  )
}

type ReduxDispatchProps = {
  disturbEntity: (args: DisturbEntityArguments) => Promise<any>
}

type ReduxProps = ReduxDispatchProps

const mapDispatchToProps = dispatch =>
  bindActionCreators<any, ReduxDispatchProps>(
    {
      disturbEntity,
    },
    dispatch,
  )

export default connect<any, ReduxDispatchProps>(
  null,
  mapDispatchToProps,
)(WSContextMenu)
