import React, { memo, useCallback, useRef } from "react"
import { FormRendererProps } from '../types/FormRenderer'
import { Input, TextArea } from '../../irv-ui'

const FormLongTextRenderer: React.FC<FormRendererProps> = ({
  id,
  value,
  readonly,
  className,
  onChange,
  onCommit,
}) => {
  const ref = useRef(null)
  const handleBlur = useCallback(
    e => {
      if (onCommit) {
        onCommit(e.target.value)
      }
    },
    [onCommit],
  )

  if (readonly) {
    return <div className={className}>{value}</div>
  }

  return (
    <TextArea
      id={id}
      ref={ref}
      value={value || ''}
      autoHeight
      className={className}
      onChange={onChange}
      onBlur={handleBlur}
    />
  )
}

export default memo(FormLongTextRenderer)
