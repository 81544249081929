import React, {useState} from 'react'
import {Button} from '../../../components/irv-ui'
import {GridRendererProps} from "../GridRendererProps";
import {GridColumn, GridColumnAction} from "../../grid/types/GridColumn";
import {compileActionValue, getVisibleAction} from '../../../utils/grid';
import {currentUser, getColumnById} from "../../../utils/get";
import {runAction} from "../../../store/CommonEffects";
import {TableActions} from "../../../store/actions/TableActions";

export default React.memo(function GridActionRenderer(props: GridRendererProps) {
  const [loading, setLoading] = useState(false)
  const {column, row, isActive} = props
  const actions = column.params.actions || []
  const table = column.table

  if (isActive) {
    return null
  }

  const user = currentUser()
  const visibleAction: GridColumnAction | undefined = getVisibleAction(actions, user, table, row)

  if (!visibleAction) {
    return null
  }

  const {btn} = visibleAction
  const actionClassName: string = btn.bg ? `c-btn color-${btn.bg}` : ''

  const handleBtnClick = () => {
    setLoading(true)

    const data = {}

    visibleAction.actions.forEach(action => {
      const column: GridColumn | undefined = getColumnById(table, action.col)

      if (column) {
        data[action.col] = compileActionValue(action, row, {
          table: table,
          user,
          column
        })
      }
    })

    runAction(TableActions.CALL_ACTION, table._id, {
      rowId: row._id,
      aId: visibleAction._id,
      data
    }).then(_ => {
      setLoading(false)
    })

    setTimeout(_ => {
      if (loading) setLoading(false)
    }, 500)
  }

  return (
    <div className={'grid-action'}>
      <Button disabled={loading} className={actionClassName} onClick={handleBtnClick}>{btn.text}</Button>
    </div>
  )
})
