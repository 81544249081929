import React, {useState, useEffect} from 'react'
import './loading.scss'

interface Props {
  delay?: number
  width?: number
  cn?: string
}

export default function Loading(props: Props) {
  const [show, setShow] = useState(!props.delay)

  useEffect(() => {
    if (!props.delay) {
      return
    }

    const timer = setTimeout(() => setShow(true), props.delay);
    return () => clearTimeout(timer);
  }, [props.delay]);

  if (!show) {
    return null
  }

  const cn = 'loading' + (props.cn ? ' ' + props.cn : '')
  const width = props.width || 40
  const style = {
    width, height: width
  }


  return (
    <div className={cn}>
      <div className="spinner" style={style}/>
    </div>
  )
}