import React from 'react'
import { SocialLinks } from '../../../utils/social'
import SIcon from '../../icon/SVGIcon'

interface Props {
  value: string
  type: string
  asLink?: boolean
  asIcon?: boolean
}

const SocialLink: React.FC<Props> = ({ value, type, asLink, asIcon }) => {
  const link = SocialLinks[type]

  if (!link) {
    return null
  }

  const presentation = asIcon ? (
    <SIcon icon={type} />
  ) : (
    <>
      <SIcon icon={type} />
      {value}
    </>
  )

  if (asLink) {
    return (
      <a href={`${link}${value}`} target={'_blank'} rel="noreferrer" title={value}>
        {presentation}
      </a>
    )
  }

  return presentation
}

export default React.memo(SocialLink)
