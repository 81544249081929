import React, { memo, useCallback, useMemo, useState } from 'react'
import { getValue } from '../../../general/utils/value'
import { RendererClassNames } from '../definitions'
import { GridCellRendererProps } from '../types/GridRenderer'
import { GridColumnOptionItem } from '../../grid/types/GridColumn'
import { isMob } from '../../../utils/is'
import SelectRenderer from '../base/SelectRenderer'
import Tag from '../../shape/Tag'
import { arrToObj } from '../../../utils/array'
import { Select } from '../../irv-ui'
import { asArray } from "../../../utils/as"

const GridMultipleSelectRenderer: React.FC<GridCellRendererProps> = ({
  column,
  active,
  payload,
  row,
}) => {
  const [value, setValue] = useState(getValue(payload.table, column, row))
  const params = column.params
  const options = params.options || []

  const onSelectChange = (value: string[]) => {
    if (value && value[0] === 'null') {
      value = null
    }

    payload.onChange(row._id, column, value)

    setValue(value)
  }

  const { selectOptions, selectOptionsMap } = useMemo(() => {
    const params = column.params
    const options = params.options || []

    const possibleOptions = params.visibleOptions
      ? options.filter(opt => params.visibleOptions.includes(opt._id))
      : options

    const selectOptions = [
      { _id: 'null', text: ' ', value: null } as GridColumnOptionItem,
    ]
      .concat(possibleOptions)
      .filter(opt => asArray(value).includes(opt._id) || !opt.removedOn)
      .map(o => ({
        ...o,
        value: o._id,
      }))

    return {
      selectOptions,
      selectOptionsMap: arrToObj(selectOptions, '_id', false),
    }
  }, [column, value])

  const TagRenderer = useCallback(
    ({ item, onRemove }) => {
      return (
        <Tag
          key={item.value}
          color={params.useColor ? item.color : ''}
          removed={!!item.removedOn}
          onRemove={payload.readonly ? null : onRemove}>
          {item.text}
        </Tag>
      )
    },
    [payload, params, selectOptionsMap],
  )

  if (payload.readonly || !active) {
    return (
      <SelectRenderer table={payload.table} column={column} value={value} />
    )
  }

  return (
    <Select
      showSearch={!isMob()}
      value={value}
      valueRenderer={TagRenderer}
      items={selectOptions}
      quiet
      mode={'multiple'}
      className={RendererClassNames.SELECT}
      onSelect={onSelectChange}
      dropdownClassName="irv-ow">
      {option => (
        <div className={'irv-bitem'}>
          {option._id === 'null' ? (
            <span />
          ) : (
            <Tag
              color={params.useColor ? option.color : ''}
              removed={!!option.removedOn}>
              {option.text}
            </Tag>
          )}
        </div>
      )}
    </Select>
  )
}

export default memo(GridMultipleSelectRenderer)
