import React from "react"
import PayloadProvider from "../../workspace/PayloadProvider"
import { BaseRendererProps } from "../BaseRendererProps"
import { EyzyUser } from "../../../types/EyzyUser"
import { asArray } from "../../../general/utils/array"
import UserBadge from "./UserBadge"
import { getStoreUsers } from "../../../utils/get"

export default React.memo(function(props: BaseRendererProps<any, EyzyUser>) {
  return (
    <PayloadProvider.Consumer>
      {({ users }) => {
        const user = asArray(users || getStoreUsers()).find(u => u._id === props.value)

        if (!user) {
          return null
        }

        return <UserBadge key={user._id} user={user as EyzyUser} />
      }}
    </PayloadProvider.Consumer>
  )
})
