import React from "react"
import { Input } from "../../../components/irv-ui"
import { BaseRendererProps } from "../BaseRendererProps"

export default React.memo(function BasePhoneRenderer(props: BaseRendererProps<string>) {
  const {readonly, value, onChange} = props

  if (readonly) {
    if (!value) {
      return null
    }

    return (
      <div className="re-value">
        <a className="phone" href={`tel:${value}`} onClick={e => e.stopPropagation()}>{value}</a>
      </div>
    )
  }

  return (
    <Input
      fit
      className="re-editor"
      onChange={onChange}
      value={value}
    />
  )
})
