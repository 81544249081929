import React, { memo, useCallback, useMemo } from 'react'
import { EyzyUser } from '../../../types/EyzyUser'
import { isMob, isUserRemoved } from '../../../utils/is'
import UserBadge from '../../__renderers/user/UserBadge'
import { isEmpty } from '../../../general/utils/is'
import { GridColumnOptionItem } from '../../grid/types/GridColumn'
import { Select } from '../../irv-ui'
import UserSelectOption from '../../user/UserSelectOption'
import { FormRendererProps } from '../types/FormRenderer'
import { asArray } from '../../../general/utils/array'
import { arrToObj } from '../../../utils/array'
import { useFormPayload } from '../RendererContext'

const FormUserRenderer: React.FC<FormRendererProps> = ({
  value,
  readonly,
  className,
  params = {},
  column,
  onChange,
  onCommit,
}) => {
  const { multiple } = params
  const { users } = useFormPayload()

  const mobile: boolean = isMob()

  const handleChange = useCallback(
    value => {
      let updatedValue = multiple ? value : [value]

      if (updatedValue[0] === null || updatedValue[0] === 'null') {
        updatedValue = null
      }

      onChange(updatedValue)

      if (onCommit) {
        onCommit(updatedValue)
      }
    },
    [multiple, onChange, onCommit],
  )

  const usersMap = useMemo(() => {
    return arrToObj(users, '_id', false)
  }, [users])

  const userOptions: Array<EyzyUser | GridColumnOptionItem> = useMemo(() => {
    // Собрать все пользователей, но не забыть юзеров, которые были удалениы, но еще есть в значении
    const wsCollaborators: any[] = users
      .filter(user => {
        if (isUserRemoved(user)) {
          return asArray(value).includes(user._id)
        }

        return user
      })
      .map(o => ({
        ...o,
        value: o._id,
      }))

    if (multiple) {
      return wsCollaborators
    }

    return [
      { _id: 'null', text: ' ', value: null } as GridColumnOptionItem,
    ].concat(wsCollaborators)
  }, [value, multiple, users])

  const tagRender = useCallback(
    ({ item, onRemove }) => {
      return <UserBadge user={item} onClose={multiple && !mobile ? onRemove : null} />
    },
    [multiple, mobile],
  )

  if (readonly) {
    if (isEmpty(value)) {
      return null
    }

    const selectedUsers = asArray(value)
      .map(id => usersMap[id])
      .filter(Boolean)

    return (
      <div className={className}>
        {selectedUsers.map(user => (
          <UserBadge user={user} key={user._id} />
        ))}
      </div>
    )
  }

  return (
    <Select
      id={column._id}
      showSearch={!isMob()}
      searchKeys={['fullName', 'email']}
      // ВВерху если null - удаляем св-во. Поэтому превращяется в undefined
      value={value || null}
      fit
      hideSelected={!mobile}
      valueRenderer={tagRender}
      items={userOptions}
      className={className}
      onSelect={handleChange}
      mode={multiple ? 'multiple' : undefined}>
      {(user, mark) => (
        <div className={'irv-bitem'} key={user._id}>
          {user._id === 'null' ? (
            mobile ? (
              <span className="secondary-text">Очистити</span>
            ) : (
              <span />
            )
          ) : (
            <UserSelectOption
              user={user}
              useColor={params.useColor !== false}
            />
          )}

          {mobile && mark}
        </div>
      )}
    </Select>
  )
}

export default memo(FormUserRenderer)
