import React from 'react'
import { SettingsItemComponentProps } from '../types'
import Select from 'antd/es/select'
import { SocialTypes } from '../../../../types/SocialNetworks'
import SIcon from '../../../icon/SVGIcon'

export default React.memo((props: SettingsItemComponentProps) => {
  const { params, onAction } = props

  const setType = (sType: string) => {
    onAction({
      sType,
    })
  }

  return (
    <div className={'column-settings-crow'}>
      <div className="column-settings-cell">
        <div className={'column-settings-label'}>Тип соц. сети</div>
        <div className={'column-settings-ctrl c-selector'}>
          <Select
            getPopupContainer={trigger => trigger.parentNode}
            dropdownMatchSelectWidth={false}
            onChange={setType}
            value={params.sType}>
            <Select.Option value={SocialTypes.INSTAGRAM}>
              <SIcon icon={SocialTypes.INSTAGRAM} /> Instagram
            </Select.Option>
            <Select.Option value={SocialTypes.FACEBOOK}>
              <SIcon icon={SocialTypes.FACEBOOK} /> Facebook
            </Select.Option>
            <Select.Option value={SocialTypes.TWITTER}>
              <SIcon icon={SocialTypes.TWITTER} /> Twitter
            </Select.Option>
          </Select>
        </div>
      </div>
    </div>
  )
})
