import React from "react";
import BaseLookupRenderer from "./BaseLookupRenderer";
import {GridRendererProps} from "../GridRendererProps";

export default React.memo(function GridLookupRenderer(props: GridRendererProps) {
  const {isActive, row, column} = props

  if (isActive) {
    return null
  }

  const foreignParams = column.params.foreignParams || {}
  const params = {
    ...column.params,
    width: column.width
  }

  return (
    <BaseLookupRenderer
      params={params}
      value={row[column._id]}
      readonly={false}
      onChange={() => {}}
      columnType={column.params.foreignType}
      width={column.width}
      options={foreignParams.options}
    />
  )
})