import React, { useEffect, useRef, useState } from 'react'
import { Button, Input } from '../../../../components/irv-ui'
import DNDList from '../../../dnd/DNDList'
import { SettingsItemComponentProps } from '../types'
import { GridColumnOptionItem } from '../../../grid/types/GridColumn'
import ColorPicker from '../../../color/ColorPicker'
import RemoveBtn from '../../../icon/RemoveBtn'
import { reorder } from '../../../../utils/array'

export default React.memo(function SelectItems(
  props: SettingsItemComponentProps,
) {
  const { column, params, onAction } = props
  const [options, setOptions] = useState([])
  const [isChanged, setChangeState] = useState(false)
  const [focused, setFocused] = useState(null)
  const container = useRef(null)

  useEffect(() => {
    setOptions(column.params.options || [])
    setChangeState(false)

    if (focused) {
      focusEl(focused)
      setFocused(null)
    }
  }, [column, focused])

  const handleChangeColor = (id: string) => (color: string) => {
    const updated = options.map(o => {
      if (o._id !== id) {
        return o
      }

      return {
        ...o,
        color,
      }
    })

    onAction({ options: updated })
  }

  const handleChangeName = (id: string) => text => {
    const updated = options.map(o => {
      if (o._id !== id) {
        return o
      }

      return {
        ...o,
        text,
      }
    })

    setChangeState(true)
    setOptions(updated)
  }

  const handleRemove = (id: string) => () => {
    onAction({
      options: options.map(o =>
        o._id === id ? { ...o, removedOn: new Date() } : o,
      ),
    })
  }

  const handleNameBlur = () => {
    if (isChanged) {
      onAction({ options })
    }
  }

  const handleDragEnd = (result: any) => {
    if (!result.destination) {
      return
    }

    onAction({
      options: reorder(options, result.source.index, result.destination.index),
    })
  }

  const handlePressEnter = e => {
    addOption()
  }

  const focusEl = id => {
    const el = document.querySelector(`[data-id="${id}"]`)

    if (el) {
      ;(el as HTMLInputElement).focus()
    }
  }

  const addOption = () => {
    onAction({
      options: options.concat({ text: '', _id: '-1' }),
    }).then(({ column }) => {
      const { options } = column.params
      setFocused(options[options.length - 1]._id)
    })
  }

  const renderOption = (item: GridColumnOptionItem, index: number) => {
    return (
      <div className={'select-opt-dnd'} key={item._id || index}>
        {params.useColor && (
          <ColorPicker
            color={item.color}
            onSelect={handleChangeColor(item._id)}
          />
        )}
        <Input
          data-id={item._id}
          type={'text'}
          value={item.text}
          onBlur={handleNameBlur}
          onPressEnter={handlePressEnter}
          onChange={handleChangeName(item._id)}
        />
        <RemoveBtn onClick={handleRemove(item._id)} />
      </div>
    )
  }

  return (
    <div className={'column-settings-row'}>
      <div className={'column-settings-label'}>
        <span>Опции</span>
        <Button variant={'minimal'} onClick={addOption}>
          + Добавить опцию
        </Button>
      </div>
      <div className={'column-settings-ctrl'} ref={container}>
        <DNDList
          onDragEnd={handleDragEnd}
          items={options.filter(o => !o.removedOn)}
          itemRenderer={renderOption}
          containerCn={'select-opts sbr'}
          droppableId={column._id}
        />
      </div>
    </div>
  )
})
