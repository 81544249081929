import React, { useMemo } from 'react'
import { BaseRendererProps } from '../types/BaseRenderer'
import { RendererClassNames } from '../definitions'
import { asArray } from '../../../utils/as'
import Tag from '../../shape/Tag'
import { GridColumnOptionItem } from '../../grid/types/GridColumn'

const SelectRenderer: React.FC<BaseRendererProps> = ({ column, value }) => {
  const items: GridColumnOptionItem[] = useMemo(() => {
    const options = column.params.options || []

    return asArray(value)
      .map(id => options.find(o => o._id === id))
      .filter(Boolean)
  }, [value, column])

  return (
    <div className={RendererClassNames.SELECT}>
      {items.map(item => (
        <Tag
          key={item._id}
          color={column.params.useColor ? item.color : ''}
          removed={!!item.removedOn}>
          {item.text}
        </Tag>
      ))}
    </div>
  )
}

export default React.memo(SelectRenderer)
