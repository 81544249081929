import React from 'react'
import dayjs from "dayjs";
import {Event, EventDate} from "./Event";
import {ToolbarDirection} from "../types/Toolbar";

export type DateLike = number | string | Date | dayjs.Dayjs

export enum ViewType {
  DAY = 'day',
  MONTH = 'month',
  WEEK = 'week',
  WORKING_WEEK = 'working_week',
  YEAR = 'year'
}

export type NavigatePayload = {
  active?: Date,
  activeView?: ViewType
}

export interface EventRendererProps {
  event: Event
}

export interface CalendarToolbarProps {
  active: dayjs.Dayjs
  activeView: CalendarViewType
  dates: dayjs.Dayjs[]
  views: CalendarViewType[]
  navigate: (direction: ToolbarDirection) => void
}

export interface ViewConfig {
  startHours?: number
  endHours?: number
  rowHeight?: number
  step?: number
  minCellWidth?: number

  showCard?: boolean
}

export type CalendarViewType = {
  key: string
  type: ViewType
  label: string
  value?: any
  config?: ViewConfig
}

export interface CalendarProps {
  active: DateLike
  activeView?: any
  events: Event[]
  selected?: string
  readonly?: boolean
  toolbar?: React.FunctionComponent<CalendarToolbarProps>
  views: CalendarViewType[]
  eventRenderer?: React.FunctionComponent<EventRendererProps>
  moreRenderer?: React.FC<{ events: Event[] }>
  onNavigate?: (payload: NavigatePayload) => void
  onSelect?: (event?: Event) => void
  onChange?: (event: Event, dates: EventDate) => void
  onAdd?: (dates: EventDate) => void
}

export interface CalendarViewProps {
  viewportHeight: number
  view: CalendarViewType
  active: dayjs.Dayjs
  events: HashedEvents
  selected?: string
  readonly?: boolean
  eventRenderer?: React.FunctionComponent<EventRendererProps>
  moreRenderer?: any
  onSelect?: (event?: Event) => void
  onAdd?: (dates: EventDate) => void
  onChange?: (event: Event, dates: EventDate) => void
}

export type HashedEvents = { [hash: string]: Event[] }
