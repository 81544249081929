import React from 'react'
import { BaseRendererProps } from '../types/BaseRenderer'
import { RendererClassNames } from '../definitions'

const TextRenderer: React.FC<BaseRendererProps> = ({ value }) => {
  if (value instanceof Object) {
    return null
  }

  return <div className={RendererClassNames.INPUT}>{value}</div>
}

export default React.memo(TextRenderer)
