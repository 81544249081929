import React from 'react'
import { Button } from '../../../../components/irv-ui'
import { BaseTable } from '../../../../types/BaseTable'
import { BaseView, ViewDateField } from '../../../../types/BaseView'
import { isEmpty } from '../../../../general/utils/is'
import { GridColumn } from '../../../grid/types/GridColumn'
import ColumnSelector from '../../../columnHelpers/columnSelector/ColumnSelector'
import { isDateColumn } from '../../../../general/utils/column'

import './styles.scss'
import { Dialog } from '../../../irv-ui'

interface Props {
  table: BaseTable
  view: BaseView
  dateField?: ViewDateField[]
  onChange: (dateField: any) => void
  onCancel: () => void
  visible: boolean
}

interface State {
  selectedField?: string
  isSubmitDisabled: boolean
}

export default class CalendarConfigurator extends React.PureComponent<
  Props,
  State
> {
  constructor(props: Props) {
    super(props)

    this.state = {
      selectedField: !isEmpty(props.dateField)
        ? props.dateField![0].start._id
        : undefined,
      isSubmitDisabled: true,
    }
  }

  render() {
    const { table, visible, onCancel } = this.props
    const dateFields: GridColumn[] = table.columns.filter(isDateColumn)

    const submitBtn = (
      <Button
        type="submit"
        variant="primary"
        onClick={this.handleSubmit}
        disabled={this.state.isSubmitDisabled}>
        Сохранить
      </Button>
    )

    return (
      <div className={'calendar-field'}>
        <Dialog
          open={visible}
          header={'Выберите поле для даты'}
          className={'calendar-field-modal'}
          width={'900px'}
          footer={submitBtn}
          onClose={onCancel}>
          {dateFields.length > 0
            ? this.renderExistsFields(dateFields)
            : this.renderNonExistsFields()}
        </Dialog>
      </div>
    )
  }

  renderExistsFields = (dateFields: GridColumn[]) => {
    return (
      <>
        <p>
          Какое поле даты вы хотели бы использовать для этого просмотра
          календаря? Ваши записи будут помещены в календарь на основе дат в этом
          поле.
        </p>
        <p>
          <span>Использовать поле: </span>

          <ColumnSelector
            table={this.props.table}
            placeholder={'Выберите колонку'}
            hiddenColumns={this.getHiddenColumns()}
            onChange={this.handleColumnChange}
          />
        </p>
      </>
    )
  }

  renderNonExistsFields = () => {
    return (
      <>
        <p>Создайте поле даты для размещения записей в календаре.</p>
        <p>
          <Button>Создать новое поля Даты</Button>
        </p>
      </>
    )
  }

  getHiddenColumns = () => {
    return this.props.table.columns
      .filter(c => !isDateColumn(c))
      .map(c => c._id)
  }

  handleColumnChange = (selectedField: string) => {
    this.setState({
      selectedField,
      isSubmitDisabled: false,
    })
  }

  handleSubmit = () => {
    if (this.state.selectedField) {
      this.props.onChange([
        {
          start: this.state.selectedField,
        },
      ])
    }
  }
}
