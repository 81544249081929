import {AppActions} from "../actions/AppActions";
import {TableActions} from "../actions/TableActions";
import {BaseActions} from "../actions/BaseActions";

const initState: { ws?: string, base?: string } = {}

export default function active(state = initState, action) {
  const payload = action.payload || {}

  switch (action.type) {
    case AppActions.INIT:
    case BaseActions.FETCH:
      return payload.active || state

    case TableActions.FETCH:
      return {
        ...state,
        ...payload.active
      }

    case AppActions.SET_ACTIVE:
      return {...state, ...payload}

    default:
      return state
  }
}
