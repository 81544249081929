import React from 'react'
import { BaseView } from '../../../types/BaseView'
import NEWIcon from '../../icon/NEWIcon'
import { getViewIcon } from '../../../utils/view'

interface Props {
  view: BaseView
}

export default React.memo(function (props: Props) {
  return (
    <span className={'view-name tc'}>
      <NEWIcon type={getViewIcon(props.view.type)} />
      {props.view.name}
    </span>
  )
})
