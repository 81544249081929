import React from 'react'
import {SettingsItemComponentProps} from "../types";
import {Switch} from '../../../../components/irv-ui'
import {connect} from "react-redux";
import {Workspace} from "../../../../types/Workspace";
import Select from "antd/es/select";
import {BaseTable} from "../../../../types/BaseTable";
import {getWSTable} from "../../../../utils/get";
import ViewName from "../../../workspace/view/ViewName";
import Tooltip from "antd/lib/tooltip";
import NEWIcon from "../../../icon/NEWIcon";

const {Option} = Select

class RecordView extends React.PureComponent<Props> {
  getTable = (): BaseTable | undefined => {
    const {ws, column} = this.props
    return getWSTable(ws, column.params.tableId)
  }

  handleViewChange = (viewId: string) => {
    this.props.onAction({viewId})
  }

  handleMultipleChange = (multiple: boolean) => {
    this.props.onAction({multiple})
  }

  render() {
    const table: BaseTable | undefined = this.getTable()

    if (!table) {
      return null
    }

    const {params} = this.props

    return (
      <>
        <div className={'column-settings-row'}>
          <div className={'column-settings-label'}>
            Указать представление
            <Tooltip title={'При выборе записи будут учтены все настройки представления, включая фильтры'}>
              <span className={'help'}>
                <NEWIcon type={'help'}/>
              </span>
            </Tooltip>
          </div>
          <div className={'column-settings-ctrl c-selector'}>
            <Select
              placeholder="Не указано"
              getPopupContainer={trigger => trigger.parentNode}
              value={params.viewId}
              onChange={this.handleViewChange}
            >
              <Option value={''}>Не указывать</Option>
              {table.views.map(view => (
                <Option key={view._id} value={view._id}>
                  <ViewName view={view}/>
                </Option>
              ))}
            </Select>
          </div>
          <div className="column-settings-hint">

          </div>
        </div>

        <div className={'column-settings-row'}>
          <div className={'column-settings-label'}>
            Множественный выбор
          </div>
          <div className={'column-settings-ctrl'}>
            <Switch defaultChecked={params.multiple} onChange={this.handleMultipleChange}/>
          </div>
        </div>
      </>
    )
  }
}


type ReduxStateProps = {
  ws: Workspace
}

type Props = SettingsItemComponentProps & ReduxStateProps

const mapStateToProps = (state: any): ReduxStateProps => ({
  ws: state.wss.find(ws => ws._id === state.active.ws),
})

export default connect(mapStateToProps)(RecordView)
