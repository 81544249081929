import {FilterOperators} from "../general/types/FilterOperators";
import {DateFilterModes} from "../general/types/DateFilterModes";
import { EventFrequency } from "../types/Calendar";

const filterTranslations = {
  [FilterOperators.IS_ANY_OF]: 'любой из',
  [FilterOperators.IS_ALL_OF]: 'все',
  [FilterOperators.IS]: 'является',
  [FilterOperators.IS_NOT]: 'не',
  [FilterOperators.IS_NONE_OF]: 'ни одного из',
  [FilterOperators.IS_EMPTY]: 'пустой',
  [FilterOperators.IS_NOT_EMPTY]: 'не пустой',
  [FilterOperators.IS_STARTS_WITH]: 'начинается на',
  [FilterOperators.IS_ENDS_WITH]: 'заканчивается на',
  [FilterOperators.CONTAINS]: 'содержит',
  [FilterOperators.DOES_NOT_CONTAIN]: 'не содержит',

  [FilterOperators.IS_WITHIN]: 'в пределах',
  [FilterOperators.IS_BEFORE]: 'до',
  [FilterOperators.IS_AFTER]: 'после',
  [FilterOperators.IS_ON_OR_BEFORE]: 'сейчас или раньше',
  [FilterOperators.IS_ON_OR_AFTER]: 'сейчас или после',

  [DateFilterModes.TODAY]: 'сегодня',
  [DateFilterModes.TOMORROW]: 'завтра',
  [DateFilterModes.YESTERDAY]: 'вчера',
  [DateFilterModes.EXACT]: 'в точности',
  [DateFilterModes.CURRENT_WEEK]: 'текущая неделя',
  [DateFilterModes.CURRENT_MONTH]: 'текущий месяц',
  [DateFilterModes.CURRENT_YEAR]: 'текущий год',
  [DateFilterModes.PAST_7_DAYS]: '',
  [DateFilterModes.PAST_30_DAYS]: '',
  [DateFilterModes.NEXT_7_DAYS]: '',
  [DateFilterModes.NEXT_30_DAYS]: '',
}

const repeatTranslations = {
  [EventFrequency.NONE]: 'Не повторять',
  [EventFrequency.DAILY]: 'Ежедневно',
  [EventFrequency.WEEKLY]: 'Еженедельно',
  [EventFrequency.MONTHLY]: 'Ежемесячно',
  [EventFrequency.YEARLY]: 'Каждый год',
  [EventFrequency.CUSTOM]: 'Свой вариант',
}

const rowHeight = {
  32: 'Обычная',
  56: 'Средняя',
  88: 'Высокая',
  128: 'Очень высокая'
}

export const translateFilterCtrl = op => {
  return filterTranslations[op] || op
}

export const translateRepeat = repeat => {
  return repeatTranslations[repeat] || repeatTranslations['-1']
}

export const translateFilterModeAndValue = (mode, value, filterValue) => {
  switch (mode) {
    case DateFilterModes.PAST_DAYS_N:
      return `${filterValue} прошедших дней`
  }

  return value
}

export const translateRowHeight = (height: number) => {
  return rowHeight[height] || ''
}
