import Input from './BaseInput'
import TextArea from './TextArea'
import NumberInput from './NumberInput'
import InputGroup from './group'

Input["Group"] = InputGroup

export default Input

export { TextArea }
export { NumberInput }
