import React from 'react'
import { IrvCollapsePanelProps } from './types/CollapsePanel'
import { cn } from '../../utils/cn'

const IrvCollapsePanel: React.FC<IrvCollapsePanelProps> = ({
  header,
  children,
  open,
  onToggle,
}) => {
  const className = cn('irv-collapse-panel', {
    open,
  })

  const handleClick = () => {
    onToggle && onToggle(open)
  }

  return (
    <div className={className}>
      <div className={'irv-collapse-header'} onClick={handleClick}>
        <button className={'irv-collapse-expand'}>
          <svg width="20" height="20" viewBox="0 0 1024 1024">
            <path d="M273.493 384h477.013c23.487 0.102 42.488 19.165 42.488 42.666 0 11.844-4.826 22.56-12.618 30.291l-0.003 0.003-238.080 238.080c-7.733 7.795-18.45 12.621-30.293 12.621s-22.56-4.826-30.291-12.618l-0.003-0.003-238.080-238.080c-7.795-7.733-12.621-18.45-12.621-30.294 0-23.501 19.001-42.565 42.478-42.666l0.010-0z"></path>
          </svg>
        </button>

        <div className={'irv-collapse-title'}>{header}</div>
      </div>
      {open && <div className={'irv-collapse-content'}>{children}</div>}
    </div>
  )
}

export default IrvCollapsePanel
