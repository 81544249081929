import React, { memo, useCallback } from 'react'
import { RendererClassNames } from '../definitions'
import BaseGridInputRenderer from '../utils/BaseGridInputRenderer'
import { GridCellRendererProps } from '../types/GridRenderer'
import { GridColumn } from '../../grid/types/GridColumn'
import SocialLink from '../utils/SocialLink'
import { processSocialValue } from "../../../utils/social"

const GridTextRenderer: React.FC<GridCellRendererProps> = props => {
  const renderer = useCallback(
    (column: GridColumn, value: any) => {
      if (!value) {
        return null
      }

      return (
        <SocialLink
          value={value}
          asLink={props.active}
          type={column.params.sType}
        />
      )
    },
    [props],
  )

  return (
    <BaseGridInputRenderer
      {...props}
      processValue={processSocialValue}
      renderer={renderer}
      className={RendererClassNames.SOCIAL}
    />
  )
}

export default memo(GridTextRenderer)
