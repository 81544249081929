import React from 'react'
import { Addon, AddonForm, ExtraAddonForm } from '../../../types/Addon'
import { BaseTable } from '../../../types/BaseTable'
import Loading from '../../loading/Loading'
import { Tabs } from '../../../components/irv-ui'
import successImg from '../../../assets/img/success.png'
import { load } from '../../../store/CommonEffects'
import { WorkspaceActions } from '../../../store/actions/WorkspaceActions'
import PayloadProvider from '../../workspace/PayloadProvider'
import set from 'lodash/set'
import get from 'lodash/get'
import { isArray, isEmpty, isString } from '../../../general/utils/is'
import { asArray } from '../../../general/utils/array'
import {
  getColumnById,
  getFormDefaultValues,
  getRowDefaultValues,
} from '../../../utils/get'
import { GridColumn } from '../../grid/types/GridColumn'
import { __post, getActionURL } from '../../../utils/request'
import { isMob } from '../../../utils/is'
import RemoveBtn from '../../icon/RemoveBtn'
import Icon from '../../icon/Icon'
import AddonBaseForm from '../../form/__new_form/addon/AddonForm'
import { Workspace } from '../../../types/Workspace'
import { buildFormParams } from '../../form/__new_form/useAddonFormOptions'
import { BaseViewColumnParams } from '../../../types/BaseView'
import { Dialog, Button } from '../../irv-ui'

import '../styles/forms.scss'
import { FormBindValues } from '../../../types/FormOptions'
import { getViewValue } from '../../../general/utils/value'

const errorTexts = {
  r: 'Обязательное поле.',
}

const wfCn: string = 'widget-form-container'

interface Props {
  widget: Addon
  wId: string
  ws: Workspace
  onClose: () => void
  onSend?: (formData?: any) => void
}

interface State {
  loaded: boolean
  loadedForms: string[]
  extras: string[]
  succeed?: boolean
  showRetry?: boolean
  isSending?: boolean
  loading?: boolean
  formData: { [formId: string]: any }
  tables: { [key: string]: BaseTable }
  activeForm?: AddonForm
  additionalPayload: any
  invalidParams?: any
}

export default class FormWidgetContainer extends React.PureComponent<
  Props,
  State
> {
  constructor(props: Props) {
    super(props)

    this.state = {
      loaded: false,
      formData: {},
      succeed: false,
      tables: {},
      extras: [],
      activeForm: props.widget?.forms[0],
      additionalPayload: {},
      loadedForms: [],
    }
  }

  timer: any

  componentDidMount() {
    if (this.props.widget) {
      this.getAddonsData(this.props.widget.forms[0])
    }

    window.addEventListener('resize', this.resizeMob)
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    window.removeEventListener('resize', this.resizeMob)
  }

  clearForm = () => {
    const activeForm = this.state.activeForm

    const formData = {
      ...this.state.formData,
      ...this.getDefaultValues(activeForm),
    }

    asArray(activeForm.extras).forEach(e => {
      formData[e._id] = {}
    })

    this.setState({ formData, invalidParams: null })
  }

  resizeMob = () => {
    this.forceUpdate()
  }

  getActiveForms = (): AddonForm[] => {
    const { activeForm, extras } = this.state

    if (activeForm.extras) {
      const extraForms = extras
        .map(id => activeForm.extras.find(e => e._id === id))
        .filter(Boolean)

      return [activeForm].concat(...extraForms)
    }

    return [activeForm]
  }

  isValidValue = (value: any): boolean => {
    if (isArray(value)) {
      return !isEmpty(value)
    } else if (isString(value)) {
      return !!value.trim()
    }

    return undefined !== value
  }

  validateForm = (updateState?: boolean): Promise<any> => {
    const { formData, tables } = this.state
    const invalidParams = {}
    const forms = this.getActiveForms()

    let isValid: boolean = true

    try {
      /* eslint no-loop-func:0 */
      for (let i = 0; i < forms.length; i++) {
        const form = forms[i]
        const table = tables[form.tableId]
        const data = formData[form._id] || {}

        const { formOptions } = buildFormParams(table, form)

        table.columns.forEach(column => {
          const { hidden, required } = get(formOptions, column._id, {})

          if (hidden || !required) {
            return
          }

          const value = data[column._id]

          if (!this.isValidValue(value)) {
            invalidParams[column._id] = [errorTexts.r]
            isValid = false
          }
        })
      }
    } catch (e) {
      console.log(e)
      return Promise.reject(invalidParams)
    }

    if (isValid) {
      return Promise.resolve()
    }

    if (updateState) {
      this.setState({ invalidParams })
    }

    return Promise.reject(invalidParams)
  }

  getAddonsData = (form: AddonForm): Promise<any> => {
    const { widget, wId } = this.props
    const formId = form._id
    const payload = {
      aId: widget._id,
      formId,
    }

    const sTables = Object.assign({}, this.state.tables)

    const handleSuccess = ({ table }) => {
      const newState: Partial<State> = {
        loaded: true,
        loading: false,
        loadedForms: this.state.loadedForms.concat(formId),
        tables: sTables,
      }

      if (table) {
        newState.tables[table._id] = table
        newState.formData = sTables
        newState.formData = {
          ...this.state.formData,
          ...this.getDefaultValues(form, newState.tables),
        }
      }

      // tables.forEach(table => {
      //   if (table._id in sTables) {
      //     sTables[table._id] = {
      //       ...table,
      //       views: sTables[table._id].views.concat(table.views)
      //     }
      //   } else {
      //     sTables[table._id] = table
      //   }
      // })

      this.setState(newState as State)

      return newState
    }

    return load({
      id: wId,
      queryString: payload,
      action: WorkspaceActions.ADDONS_DATA,
    })
      .then(handleSuccess)
      .catch(e => {
        console.log(e)
      })
  }

  getFormById = (formId: string): AddonForm | ExtraAddonForm | undefined => {
    const activeForm = this.state.activeForm

    if (!activeForm) {
      return
    }

    if (activeForm._id === formId) {
      return activeForm
    }

    if (activeForm.extras) {
      return activeForm.extras.find(f => f._id === formId)
    }

    return this.props.widget.forms.find(f => f._id === formId)
  }

  getDefaultFormValues = (form: AddonForm, tables, formData) => {
    return {
      ...getFormDefaultValues(
        tables[form.tableId],
        form.columnsParams as BaseViewColumnParams,
        formData,
      ),
    }
  }

  getDefaultValues = (form: AddonForm, tables = this.state.tables) => {
    const formData = {}
    const openedForms = asArray(form.forms || form)

    openedForms.forEach(f => {
      const table = tables[f.tableId]

      formData[f._id] = getRowDefaultValues(table, f.columnsParams)
    })

    return formData
  }

  getFormState = (
    form: AddonForm,
  ): { isConfigured: boolean; isLoaded: boolean } => {
    const { tables, loadedForms } = this.state

    const result = {
      isLoaded: loadedForms.includes(form._id),
      isConfigured: false,
    }

    if (result.isLoaded) {
      const table = tables[form.tableId]

      if (table) {
        result.isConfigured = form.viewId
          ? table.views.some(v => v._id === form.viewId)
          : true
      }
    }

    return result
  }

  submit = async () => {
    const { widget, onClose, onSend } = this.props
    const { activeForm, formData } = this.state
    const url: string = getActionURL('addon', 'submitForm', widget._id)
    const activeForms = this.getActiveForms()
    const data = activeForms.reduce((dataObj: any, form: AddonForm) => {
      return {
        ...dataObj,
        [form._id]: formData[form._id],
      }
    }, {})

    this.setState({ loaded: false })

    try {
      await __post(url, { formId: activeForm!._id, data }, true)

      this.setState({
        succeed: true,
        loading: false,
        loaded: true,
        showRetry: false,
      })

      this.timer = setTimeout(_ => {
        onClose()
      }, 2000)

      onSend && onSend()
    } catch (e) {
      this.setState({ showRetry: true, isSending: false, loading: false })
    }
  }

  handleSubmit = () => {
    this.setState({ isSending: true })
    this.validateForm()
      .then(this.submit)
      .catch(invalidParams => {
        this.setState({
          invalidParams,
          isSending: false,
        })

        try {
          document.querySelector(`.${wfCn} .error`).scrollIntoView()
        } catch (e) {}
      })
  }

  setActiveForm = (formId: string) => {
    const activeForm = this.props.widget.forms.find(f => f._id === formId)
    const { loadedForms } = this.state
    const needToLoadData: boolean = !loadedForms.includes(activeForm._id)
    const state = {
      activeForm,
      invalidParams: null,
    }

    if (needToLoadData) {
      const loaderTimer = setTimeout(_ => {
        this.setState({ loading: true })
      }, 200)

      this.setState(state)
      this.getAddonsData(activeForm).then(_ => {
        clearTimeout(loaderTimer)
      })
    } else {
      this.setState(state)
    }
  }

  handleBindValues = (
    formId: string,
    bindValues: FormBindValues,
    payload: any,
  ) => {
    const { rows } = payload

    if (rows.length !== 1) {
      return
    }

    const newFormValues = {}

    for (const [foreignColumn, currentColumn] of Object.entries(
      bindValues?.columns,
    )) {
      const foreignValue = rows[0]?.[foreignColumn]

      if (foreignValue === void 0 || foreignValue === '') {
        continue
      }

      newFormValues[currentColumn] = foreignValue
    }

    this.setState(state => {
      return {
        formData: {
          ...state.formData,
          [formId]: {
            ...state.formData[formId],
            ...newFormValues
          }
        }
      }
    })
  }

  handleChange = (formId: string, columnId: string, value: any) => {
    const formData = this.state.formData
    const activeFormData = { ...formData[formId] }

    if (value === undefined || value === null || value === '') {
      delete activeFormData[columnId]
    } else {
      activeFormData[columnId] = value
    }

    const updatedFormData = {
      ...formData,
      [formId]: activeFormData,
    }

    const invalidParams = { ...this.state.invalidParams }

    if (columnId in invalidParams) {
      if (this.isValidValue(activeFormData[columnId])) {
        delete invalidParams[columnId]
      }
    }

    this.setState({ formData: updatedFormData, invalidParams })
  }

  handlePayloadChange = (column: GridColumn, payload: any) => {
    const { additionalPayload } = this.state

    this.setState({
      additionalPayload: {
        ...additionalPayload,
        [column._id]: {
          ...additionalPayload[column._id],
          ...payload,
        },
      },
    })
  }

  handleExpandLinkedTable = (formId: string) => {
    const { tables, formData } = this.state
    const extras = (this.state.extras || []).concat(formId)

    this.setState({ extras })

    const form = this.getFormById(formId)

    if (!form) {
      return
    }

    const completeRender = ({ tables }) => {
      const additionalPayload = { ...this.state.additionalPayload }
      const linkedTo = asArray(form['linkedTo'])

      if (!linkedTo.length) {
        // Добавляем обычную форму
        formData[formId] = this.getDefaultFormValues(form, tables, formData)
      }

      linkedTo.forEach(link => {
        const data = Object.assign({}, formData[link.formId] || {})
        const linkData = this.getDefaultFormValues(form, tables, formData)
        const linkedForm = this.getFormById(link.formId)

        if (!linkedForm) {
          console.log('ФОрма сломана!!!')
          return
        }

        // Это на таблицу, на которую ссылаемся
        data[link.foreignId] = [
          {
            _id: form._id,
            ...linkData,
          },
        ]

        formData[link.formId] = data
        formData[form._id] = {
          ...linkData,
          [link.columnId]: [
            {
              _id: link.formId,
              ...data,
            },
          ],
        }

        additionalPayload[link.foreignId] = { table: tables[form.tableId] }
        additionalPayload[link.columnId] = { table: tables[linkedForm.tableId] }
      })

      this.setState({
        formData,
        additionalPayload,
      })
    }

    if (!(form.tableId in tables)) {
      this.getAddonsData(form).then(completeRender)
    } else completeRender({ tables })
  }

  handleUnlinkForm = (formId: string) => {
    const extras = (this.state.extras || []).filter(id => id !== formId)
    const formData = { ...this.state.formData }

    delete formData[formId]

    if (this.state.activeForm.extras) {
      const form = this.state.activeForm.extras.find(e => e._id === formId)

      if (form && form.linkedTo) {
        form.linkedTo.forEach(link => {
          const linkedForm = get(formData, link.formId, {})
          const linkedColumn = asArray(linkedForm[link.foreignId])

          const newValue = linkedColumn.filter(value => value._id !== form._id)

          set(formData, `${link.formId}.${link.foreignId}`, newValue)
        })
      }
    }

    this.setState({
      extras,
      formData,
    })
  }

  renderSucceed = () => {
    return (
      <div className={'success'}>
        <img src={successImg} alt={''} />
        {this.state.activeForm!.success || 'Thank you for submitting the form!'}
      </div>
    )
  }

  renderRetry = () => {
    return (
      <div className={'retry'}>
        <p>
          Извините, что-то сломалось{' '}
          <span role="img" aria-label={'sorry'}>
            🤷
          </span>
        </p>
        <Button
          type="submit"
          variant="primary"
          disabled={this.state.loading}
          onClick={this.handleSubmit}>
          Повторить попытку
        </Button>
      </div>
    )
  }

  renderHeaders = () => {
    const { activeForm } = this.state
    const { forms } = this.props.widget

    return (
      <div className={'widget-form-header'} key={'wh'}>
        <Tabs
          noContent
          activeKey={activeForm._id}
          onChange={this.setActiveForm}>
          {forms.map(f => (
            <Tabs.Tab label={<span>{f.name}</span>} key={f._id} />
          ))}
        </Tabs>
      </div>
    )
  }

  renderNotConfigured = (form: AddonForm) => {
    return (
      <p className={'no'}>
        Форма <b>{form.name}</b> не настроена!
      </p>
    )
  }

  renderExtras = (forms: ExtraAddonForm[]): React.ReactNode => {
    const { tables } = this.state
    const extras = asArray(this.state.extras)
    const notAddedForms: AddonForm[] = forms.filter(
      f => !extras.includes(f._id),
    )
    const addedForms: AddonForm[] = forms
      .filter(f => extras.includes(f._id))
      .sort((e0, e1) => {
        return extras.indexOf(e0._id) - extras.indexOf(e1._id)
      })

    const content = []

    addedForms.forEach(form => {
      const table = tables[form.tableId]

      // Тут как раз 600ms перед загрузить форму и отобразить лоадэр, если долго думает
      if (!table) {
        return
      }

      const formHeader = (
        <>
          <div className={'form-header'}>
            <h1>{form.name}</h1>
            <RemoveBtn
              useTrash
              onClick={() => this.handleUnlinkForm(form._id)}
            />
          </div>
          {form.helpText && <div className={'form-help'}>{form.helpText}</div>}
        </>
      )

      content.push(this.renderForm(form, table, formHeader))
    })

    if (notAddedForms.length) {
      content.push(
        <div className={'widget-form-ans'} key={'ff1'}>
          {notAddedForms.map((form, i) => (
            <Button
              key={i}
              onClick={_ => this.handleExpandLinkedTable(form._id)}>
              {form.btnText || form.name}
            </Button>
          ))}
        </div>,
      )
    }

    return <div className={'widget-form-ext'}>{content}</div>
  }

  renderForms = () => {
    const { widget } = this.props
    const { activeForm, tables, loading } = this.state
    const content: React.ReactNode[] = []
    const isMultipleForms: boolean = widget.forms.length > 1

    if (isMultipleForms) {
      content.push(this.renderHeaders())
    }

    if (loading) {
      content.push(<Loading cn={'pr'} />)
      return content
    }

    const { isLoaded, isConfigured } = this.getFormState(activeForm)

    if (isLoaded) {
      if (isConfigured) {
        const widgetFormContent = []
        const table = tables[activeForm.tableId]

        widgetFormContent.push(this.renderForm(activeForm, table))

        if (activeForm.extras) {
          widgetFormContent.push(this.renderExtras(activeForm.extras))
        }

        content.push(
          <div className={'widget-form-bb'} key={'bb'}>
            {widgetFormContent}
          </div>,
        )
      } else {
        content.push(
          <div className={'widget-form-missed-conf'}>
            <p>Форма настроена не верно. Обратитесь к администратору</p>
            <img src="/svg/robot_404.svg" alt={''} width={200} />
          </div>,
        )
      }
    }

    return content
  }

  renderForm = (
    form: AddonForm,
    table: BaseTable,
    header?: React.ReactNode,
  ): React.ReactNode => {
    const { invalidParams, formData } = this.state
    const { widget, ws } = this.props

    return (
      <AddonBaseForm
        key={form._id}
        ws={ws}
        header={header}
        table={table}
        form={form}
        widget={widget}
        errors={invalidParams}
        formData={formData[form._id] || {}}
        onChange={this.handleChange}
        onBindValues={this.handleBindValues}
      />
    )
  }

  renderFooter = () => {
    const { widget, onClose } = this.props
    const { loading, activeForm, isSending, showRetry } = this.state

    // А-Х-У-Е-Т-Ь
    if (!widget || loading || isSending || showRetry) {
      return null
    }

    const { isLoaded, isConfigured } = this.getFormState(activeForm)

    if (isLoaded && isConfigured) {
      return (
        <>
          <div className={'fl'}>
            <Button
              variant={'primary'}
              onClick={this.handleSubmit}
              disabled={isSending}>
              Отправить
            </Button>
            <Button variant={'minimal'} onClick={onClose}>
              Отмена
            </Button>
          </div>

          <Button variant={'minimal'} onClick={this.clearForm}>
            {isMob() ? <Icon type={'broom'} /> : 'Очистить'}
          </Button>
        </>
      )
    }

    return null
  }

  renderContent() {
    const { loaded, succeed, showRetry, additionalPayload } = this.state
    const { ws, widget } = this.props

    if (!widget) {
      return null
    }

    if (showRetry) {
      return this.renderRetry()
    }

    if (!loaded) {
      return <Loading />
    }

    if (succeed) {
      return this.renderSucceed()
    }

    return (
      <PayloadProvider.Provider
        value={{
          links: additionalPayload,
          activeUsers: ws.activeCollaborators,
          users: ws.collaborators,
        }}>
        {this.renderForms()}
      </PayloadProvider.Provider>
    )
  }

  render() {
    const { widget, onClose } = this.props

    return (
      <Dialog
        onClose={onClose}
        open={!!widget}
        alignSelf={'start'}
        width={800}
        header={<h3>{widget && widget.name}</h3>}
        footer={this.renderFooter()}
        className="widget-modal">
        <div className={wfCn}>{this.renderContent()}</div>
      </Dialog>
    )
  }
}
