import React, { useEffect, useState } from 'react'
import { Input } from '../../../../../components/irv-ui'
import { Base } from '../../../../../types/Base'
import { cn } from '../../../../../utils/classname'
import BaseLink from '../../helpers/BaseLink'
import Icon from '../../../../../components/icon/Icon'
import SBTable from './SBTable'
import Tooltip from 'antd/lib/tooltip'
import { ActionType } from '../../../../../store/CommonEffects'
import { BaseActions } from '../../../../../store/actions/BaseActions'
import { history } from '../../../../../utils/navigation'
import BaseContextMenu from '../../../../../components/contextMenu/base/BaseContextMenu'
import { EyzyUser } from '../../../../../types/EyzyUser'
import { Workspace } from '../../../../../types/Workspace'
import {
  isRoleAllowed,
  UIRoleAction,
} from '../../../../../components/roles/RoleGateway'
import { Roles } from '../../../../../components/roles/Roles'
import NEWIcon from '../../../../../components/icon/NEWIcon'
import { isMob } from '../../../../../utils/is'
import { userEntityRole } from '../../../../../general/utils/roles'
import Loading from '../../../../../components/loading/Loading'

interface Props {
  base: Base
  ws: Workspace
  user: EyzyUser
  active: boolean
  activeTable: string
  expanded?: boolean
  simplified?: boolean
  callAction: (action: ActionType, id: string, payload: any) => Promise<any>
  onToggle: (base: Base, openNextLink?: boolean) => void
  onMenuClick: (action: ActionType, payload?: any) => void
}

export default React.memo(function SBBase(props: Props) {
  const {
    onToggle,
    onMenuClick,
    user,
    ws,
    expanded,
    simplified,
    base,
    active,
    activeTable,
    callAction,
  } = props
  const [isTableCreating, setTableCreating] = useState<boolean>(false)
  const [isLoadingTables, setIsLoadingTables] = useState<boolean>(false)

  // @ts-ignore
  const userRole: Roles = userEntityRole(user, { ws, base })

  const isExpanded: boolean =
    isTableCreating ||
    (simplified ? expanded && base.tables.length > 1 : expanded)

  const containerClassName = cn(
    'sb-base',
    expanded && 'expanded',
    active && `active`,
  )

  useEffect(() => {
    if (isLoadingTables && base.tables[0]._id) {
      setIsLoadingTables(false)
    }
  }, [isLoadingTables, base])

  const handleToggle = e => {
    if (e.target.closest('.cm')) {
      // якась хуйня... почему-то сразабывает клик
      return
    }

    const openNextLink = simplified ? base.tables.length <= 1 : false

    if (!openNextLink && !base.tables[0]._id) {
      setIsLoadingTables(true)
    }

    onToggle(base, openNextLink)
  }

  const handleMenuClick = (key: ActionType) => {
    if (key === BaseActions.CREATE_TABLE) {
      return handleAddTable()
    }

    onMenuClick(key, base)
  }

  const handleBaseClick = e => {
    const openNextLink = simplified ? base.tables.length <= 1 : true

    if (!openNextLink && !base.tables[0]._id) {
      setIsLoadingTables(true)
    }

    stop(e)
    onToggle(base, openNextLink)
  }

  const handleAddTable = () => {
    if (!expanded) {
      onToggle(base, false)
    }

    setTableCreating(true)
  }

  const handleLockClick = e => {
    onMenuClick(BaseActions.PERMISSIONS_SETTING, base)
    stop(e)
  }

  const addTable = e => {
    const name: string = e.target.value.trim()

    if (!name) {
      return cancelEditing()
    }

    cancelEditing()
    callAction(BaseActions.CREATE_TABLE, base._id, { name }).then(table => {
      history.push(`/${table._id}/${table.views[0]._id}`)
    })
  }

  const cancelEditing = () => {
    setTableCreating(false)
  }

  const stop = e => {
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <div className={containerClassName}>
      <div className={'sb-base-link'}>
        {!simplified && (
          <button className={'che'} onClick={handleToggle}>
            {isLoadingTables ? (
              <Loading width={10} />
            ) : (
              <Icon type={'menu-right'} />
            )}
          </button>
        )}

        <div
          className={'sb-base-title'}
          title={base.name}
          onClick={handleToggle}>
          <a href={`/${base._id}`} onClick={handleBaseClick}>
            <BaseLink base={base} />
          </a>

          {base.limitedAccess &&
            !isMob() &&
            isRoleAllowed(UIRoleAction.BASE_COLLABORATORS_LIST, userRole) && (
              <Tooltip
                trigger={['hover']}
                title={'База с ограниченным доступом'}>
                <button className={'cha b-lock'} onClick={handleLockClick}>
                  <Icon type={'lock'} />
                </button>
              </Tooltip>
            )}

          {!isMob() && (
            <div className={'sb-base-ctrl'}>
              {isRoleAllowed(UIRoleAction.BASE_ALLOW_CONFIGURE, userRole) && (
                <BaseContextMenu base={base} onMenuClick={handleMenuClick}>
                  <button
                    className={'cha'}
                    title={'Настройка базы'}
                    onClick={stop}>
                    <NEWIcon type={'dots'} />
                  </button>
                </BaseContextMenu>
              )}
            </div>
          )}
        </div>

        {simplified && base.tables.length > 1 && (
          <button className={'che'} onClick={handleToggle}>
            {isLoadingTables ? (
              <Loading width={10} />
            ) : (
              <Icon type={'menu-right'} />
            )}
          </button>
        )}
      </div>

      {isExpanded && (
        <div className={'sb-tables'}>
          {base.tables.map(table => (
            <SBTable
              key={table._id}
              base={base}
              table={table}
              role={userRole}
              active={activeTable === table._id}
              onMenuClick={onMenuClick}
            />
          ))}

          {isTableCreating && (
            <Input
              autoFocus
              placeholder={'Имя таблицы'}
              className={'edit-en'}
              onPressEnter={addTable}
              onBlur={addTable}
              onPressEsc={cancelEditing}
            />
          )}
        </div>
      )}
    </div>
  )
})
