import {BaseTable} from "../types/BaseTable";
import {Base} from "../types/Base";
import {isString} from "./is";

const U_KEY: string = '_lu_1'

interface UsageResult {
  tableId?: string
  viewId?: string
}

function get(): any {
  try {
    const res = localStorage.getItem(U_KEY)

    if (!res) {
      return {}
    }

    return JSON.parse(res)
  } catch (e) {
    console.log(e)
  }
}

export function setLastUsage(baseId: string, tableId: string, viewId: string) {
  try {
    const usage = get()
    const baseUsage = usage[baseId] || {}

    baseUsage['t'] = tableId

    if (!baseUsage['e']) {
      baseUsage['e'] = {}
    }

    baseUsage['e'][tableId] = viewId

    usage[baseId] = baseUsage

    localStorage.setItem(U_KEY, JSON.stringify(usage))
  } catch (e) {
    console.log(e)
  }
}

export function lastUsage(base: Base | string, table?: BaseTable): UsageResult {
  try {
    const baseId: string = isString(base) ? base as string : (base as Base)._id
    const usage = get()
    const baseInfo = usage[baseId]

    if (!baseInfo || !baseInfo['t']) {
      return {}
    }

    if (!table) {
      return {
        tableId: baseInfo['t'],
        viewId: baseInfo['e'][baseInfo['t']]
      }
    }

    return baseInfo['e'][table._id] || {}
  } catch (e) {
    console.log(e)
    return {}
  }
}
