import React, { useEffect, useState } from "react"
import Select from "antd/es/select"
import DatePicker from "react-datepicker"
import { Switch } from "../../../../components/irv-ui"
import { dayjs, DEFAULT_DATE_FORMAT, format } from "../../../../general/utils/date"
import { SettingsItemComponentProps } from "../types"
import { parseDefaultValue } from "../../../../utils/dates"

const formats = [
  { value: "L", text: "Local" },
  { value: "D MMMM YYYY", text: "Friendly" },
  { value: "M/D/YYYY", text: "US" },
  { value: "D/M/YYYY", text: "European" },
  { value: "YYYY-M-D", text: "ISO" },
  { value: "relative", text: "Relative" },
]
const possibleTimeFormats = ["h:mm a", "HH:mm"]

export default React.memo((props: SettingsItemComponentProps) => {
  const today = dayjs()
  const { params, onAction } = props
  const currentFormat = params.format || DEFAULT_DATE_FORMAT
  const [defaultValue, setDefaultValue] = useState(parseDefaultValue(params.defaultValue) || 'null')
  const [selectedDate, setSelectedDate] = useState<Date | null>()

  useEffect(() => {
    if (params.defaultValue && defaultValue === "date") {
      const d = dayjs(params.defaultValue)

      if (d.isValid()) {
        setSelectedDate(d.toDate())
      }
    }

  }, [params.defaultValue, defaultValue])

  const onChange = (value) => {
    onAction({
      format: value,
    })
  }

  const onChangeTimeFormat = (value) => {
    const timeFormat = false === value
      ? null
      : (possibleTimeFormats.includes(value) ? value : possibleTimeFormats[0])

    onAction({
      timeFormat,
    })
  }

  const showTime: boolean = !!params.timeFormat

  const onChangeDefaultValue = (value) => {
    setDefaultValue(value)

    if (value === null || value === "TODAY()") {
      onAction({
        defaultValue: value,
      })
    }
  }

  const onDateChange = date => {
    setSelectedDate(date)

    onAction({
      defaultValue: dayjs(date).format("YYYY-M-D"),
    })
  }

  return (
    <>
      <div className={"column-settings-row"}>
        <div className={"column-settings-label"}>
          Формат
        </div>
        <div className={"column-settings-ctrl c-selector"}>
          <Select
            getPopupContainer={trigger => trigger.parentNode}
            defaultValue={currentFormat}
            onChange={onChange}
            dropdownMatchSelectWidth={false}
          >
            {formats.map(f => (
              <Select.Option value={f.value} key={f.value}>{f.text} ({format(today, f.value)})</Select.Option>
            ))}
          </Select>
        </div>
      </div>

      {params.format !== "relative" && (
        <div className={"column-settings-row"}>
          <div className={"column-settings-label"}>
            Показывать Время
          </div>
          <div className={"column-settings-ctrl"}>
            <Switch defaultChecked={showTime} onChange={onChangeTimeFormat} />
          </div>
        </div>
      )}

      {showTime && params.format !== "relative" && (
        <div className={"column-settings-row"}>
          <div className={"column-settings-label"}>
            Формат времени
          </div>
          <div className={"column-settings-ctrl c-selector"}>
            <Select getPopupContainer={trigger => trigger.parentNode}
                    defaultValue={params.timeFormat}
                    onChange={onChangeTimeFormat}
            >
              {possibleTimeFormats.map((f, i) => (
                <Select.Option value={f} key={f}>
                  {i === 0 ? "12-часовой" : "24-часовой"}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
      )}

      <div className={"column-settings-row"}>
        <div className={"column-settings-label"}>
          Значение по умолчанию
        </div>
        <div className={"column-settings-ctrl"}>
          <div className="half c-selector">
            <Select
              getPopupContainer={trigger => trigger.parentNode}
              defaultValue={defaultValue}
              onChange={onChangeDefaultValue}
              dropdownMatchSelectWidth={false}
            >
              <Select.Option value={'null'}>Не указано</Select.Option>
              <Select.Option value={"TODAY()"}>Сейчас</Select.Option>
              <Select.Option value={"date"}>Указать дату</Select.Option>
            </Select>
          </div>

          {defaultValue === "date" && (
            <div className="half">
              <DatePicker
                showPopperArrow={false}
                placeholder={"Выберите дату"}
                className={"e-input eyzy-date"}
                dateFormat={"d MMMM yyyy"}
                onChange={onDateChange}
                locale="ru"
                selected={selectedDate}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
})
