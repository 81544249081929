import { RefObject, SyntheticEvent, useEffect } from 'react'
import { useFocusWithin } from './useFocusWithin'
import { useInteractOutside } from "./useInteractOutside"

export interface AriaOverlayProps {
  /** Whether the overlay is currently open. */
  open?: boolean

  /** Handler that is called when the overlay should close. */
  onClose?: () => void

  /**
   * Whether to close the overlay when the user interacts outside it.
   * @default false
   */
  isDismissable?: boolean

  /** Whether the overlay should close when focus is lost or moves outside it. */
  shouldCloseOnBlur?: boolean

  /**
   * Whether pressing the escape key to close the overlay should be disabled.
   * @default false
   */
  isKeyboardDismissDisabled?: boolean

  /**
   * When user interacts with the argument element outside of the overlay ref,
   * return true if onClose should be called.  This gives you a chance to filter
   * out interaction with elements that should not dismiss the overlay.
   * By default, onClose will always be called on interaction outside the overlay ref.
   */
  shouldCloseOnInteractOutside?: (element: Element) => boolean
}

export interface OverlayAria {
  /** Props to apply to the overlay container element. */
  overlayProps: any
  /** Props to apply to the underlay element, if any. */
  underlayProps: any
}

const visibleOverlays: RefObject<Element>[] = []

export function useOverlay(
  props: AriaOverlayProps,
  ref: RefObject<Element>,
): OverlayAria {
  const {
    onClose,
    shouldCloseOnBlur,
    open,
    isDismissable = false,
    isKeyboardDismissDisabled = false,
    shouldCloseOnInteractOutside,
  } = props

  // Add the overlay ref to the stack of visible overlays on mount, and remove on unmount.
  useEffect(() => {
    if (open) {
      visibleOverlays.push(ref)
    }

    return () => {
      const index = visibleOverlays.indexOf(ref)

      if (index >= 0) {
        visibleOverlays.splice(index, 1)
      }
    }
  }, [open, ref])

  // Only hide the overlay when it is the topmost visible overlay in the stack.
  const onHide = (focusPrevRef?: boolean) => {
    if (visibleOverlays[visibleOverlays.length - 1] === ref && onClose) {
      onClose()

      if (focusPrevRef && visibleOverlays.length > 1) {
        const prevModal = visibleOverlays[visibleOverlays.length - 2]

        if (prevModal) {
          // @ts-ignore
          prevModal.current.focus()
        }
      }
    }
  }

  const onInteractOutsideStart = (e: SyntheticEvent<Element>) => {
    if (
      !shouldCloseOnInteractOutside ||
      shouldCloseOnInteractOutside(e.target as Element)
    ) {
      if (visibleOverlays[visibleOverlays.length - 1] === ref) {
        e.stopPropagation()
        e.preventDefault()
      }
    }
  }

  const onInteractOutside = (e: SyntheticEvent<Element>) => {
    if (
      !shouldCloseOnInteractOutside ||
      shouldCloseOnInteractOutside(e.target as Element)
    ) {
      if (visibleOverlays[visibleOverlays.length - 1] === ref) {
        e.stopPropagation()
        e.preventDefault()
      }
      onHide()
    }
  }

  // Handle the escape key
  const onKeyDown = e => {
    if (e.key === 'Escape' && !isKeyboardDismissDisabled) {
      e.stopPropagation()
      e.preventDefault()
      onHide(true)
    }
  }

  // Handle clicking outside the overlay to close it
  useInteractOutside({
    ref,
    onInteractOutside: isDismissable ? onInteractOutside : null,
    onInteractOutsideStart,
  })

  const { focusWithinProps } = useFocusWithin({
    disabled: !shouldCloseOnBlur,
    onBlurWithin: e => {
      if (
        !shouldCloseOnInteractOutside ||
        shouldCloseOnInteractOutside(e.relatedTarget as Element)
      ) {
        onClose()
      }
    },
  })

  const onPointerDownUnderlay = e => {
    // fixes a firefox issue that starts text selection https://bugzilla.mozilla.org/show_bug.cgi?id=1675846
    if (e.target === e.currentTarget) {
      e.preventDefault()
    }
  }

  return {
    overlayProps: {
      onKeyDown,
      ...focusWithinProps,
    },
    underlayProps: {
      onPointerDown: onPointerDownUnderlay,
      onClick: onHide
    },
  }
}
