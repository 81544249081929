import React from 'react'
import {cn} from "../../utils/classname";

interface Props {
  type: string;
  style?: any
  custom?: string
  className?: any
}

function getCustomLetters(text: string) {
  const formattedText: string = text.replace(/\s+/, ' ')
  const chunks: string[] = formattedText.split(
    /\s/.test(formattedText) ? ' ' : ''
  )

  return chunks
    .map(text => text.substr(0, 1))
    .slice(0, 2)
    .join('')
}

export default React.memo(function Icon(props: Props) {
  if ('custom' === props.type && props.custom) {
    const className = cn(
      `icon icon-custom`, props.className
    )

    return (
      <span className={className} style={props.style}>{getCustomLetters(props.custom!)}</span>
    )
  }

  const className = cn(
    `icon mdi mdi-${props.type}`, props.className
  )

  return (
    <i className={className} style={props.style} />
  )
})