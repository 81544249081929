import React from 'react'
import {BaseFilter, DynamicFilterItem} from "./types/Filter";
import {BaseTable} from "../../types/BaseTable";
import {GridColumn} from "../grid/types/GridColumn";
import {asArray} from "../../general/utils/array";
import {translateFilterCtrl, translateFilterModeAndValue} from "../../utils/translations";
import {getViewValue} from "../../general/utils/value";
import {getUIColumnType, isSpecialColumn} from "../../general/utils/column";

interface Props {
  filter: BaseFilter
  table: BaseTable
}

const createFilterChunks = (filter: BaseFilter, table: BaseTable, columnsMap: { [id: string]: GridColumn }): React.ReactNode => {
  const translatedItems = asArray(filter.filters)
    .map((filter: any) => {
      if (filter.col) {
        const {col, op, mode} = filter as DynamicFilterItem
        const column = columnsMap[col]

        if (!column) {
          // eslint-disable-next-line
          return
        }

        const value = getViewValue(table, column, {
          [isSpecialColumn(column) ? getUIColumnType(column) : column._id]: filter.value
        })

        const translatedValue = translateFilterModeAndValue(mode, value, filter.value)

        return [
          <span className={'fsi'}>
            <strong>{column.name}</strong>
            <span>{translateFilterCtrl(op)}</span>
            {translatedValue && <strong>{translatedValue}</strong>}
          </span>
        ]
      }

      // eslint-disable-next-line
      return
    })
    .filter(Boolean)

  return translatedItems.map((item, i) => {
    if (i < translatedItems.length - 1) {
      return [
        item,
        <span>{filter.op === 'or' ? 'ИЛИ' : 'И'}</span>
      ]
    }

    return item
  })
}

export default React.memo(function BeautyFilterString(props: Props) {
  const {table, filter} = props
  const columns: { [id: string]: GridColumn } = table.columns.reduce((res, column) => {
    res[column._id] = column
    return res
  }, {})

  return (
    <div className={'fs'}>
      {createFilterChunks(filter, table, columns)}
    </div>
  )
})
