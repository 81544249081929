import React, { memo, useEffect, useState } from "react"
import { getValue } from '../../../general/utils/value'
import DatePicker from 'react-datepicker'
import { RendererClassNames } from '../definitions'
import { dayjs } from '../../../general/utils/date'
import { GridCellRendererProps } from '../types/GridRenderer'
import BaseDateRenderer from '../base/DateRenderer'

const GridDateRenderer: React.FC<GridCellRendererProps> = ({
  column,
  active,
  payload,
  row,
}) => {
  const [value, setValue] = useState(getValue(payload.table, column, row))
  const [showPicker, setShowPicker] = useState<boolean>(false)
  const params = column.params

  useEffect(() => {
    if (active && !showPicker) {
      // Нужно чуток подождать, ибо эта хуйня сразу фокусируется
      setTimeout(_ => {
        setShowPicker(true)
      })
    } else if (!active) {
      setShowPicker(false)
    }
  }, [active, showPicker])

  useEffect(() => {
    setValue(getValue(payload.table, column, row))
  }, [payload.table, column, row])

  if (!active || !showPicker || payload.readonly) {
    return value ? (
      <BaseDateRenderer value={value} column={column} table={payload.table} />
    ) : null
  }

  const commit = (value: any) => {
    payload.onChange(row._id, column, value)
    setValue(value)
  }

  const onDateSelect = (date: Date) => {
    if (!date) {
      return commit(null)
    }

    const value = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
    )

    if (params.timeFormat) {
      value.setHours(date.getHours())
      value.setMinutes(date.getMinutes())
    }

    commit(value)
  }

  const datePickerProps: any = {
    selected: value ? dayjs(value).toDate() : null,
  }

  if (params.timeFormat) {
    datePickerProps.showTimeSelect = true
    datePickerProps.timeFormat = 'p'
    datePickerProps.timeIntervals = 15
  }

  let dFormat = 'dd.MM.yyyy'

  if (params.timeFormat) {
    dFormat += `, ${params.timeFormat}`
  }

  return (
    <DatePicker
      {...datePickerProps}
      showPopperArrow={false}
      locale="ru"
      onChange={onDateSelect}
      dateFormat={dFormat}
      isClearable
      autoFocus={false}
      className={RendererClassNames.DATE}
    />
  )
}

export default memo(GridDateRenderer)
