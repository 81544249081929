import React from 'react'
import { Addon } from '../../types/Addon'
import { Button } from '../../components/irv-ui'
import { GridRow } from '../grid/types/GridRow'
import Collapse from 'antd/lib/collapse'
import Loading from '../loading/Loading'
import { DisturbEntityArguments, fetchItem } from '../../store/CommonEffects'
import store from '../../store'
import { getColumnById, getView } from '../../utils/get'
import LinkedRecordList from '../linkedRecord/LinkedRecordList'
import { BaseView } from '../../types/BaseView'
import { BaseTable } from '../../types/BaseTable'
import { isEmpty, isMob } from '../../utils/is'
import { GridColumn } from '../grid/types/GridColumn'
import { createRowWithDefaults, groupRows } from '../../utils/grid'
import BaseRendererFactory from '../__renderers/RendererFactory'
import { AddonActions } from '../../store/actions/AddonActions'

import './styles/list.scss'
import { ViewType } from '../grid/types/ViewType'
import IorvoList from '../iorvo-list/IorvoList'
import { TableActions } from '../../store/actions/TableActions'
import WidgetTitleBadge from './WidgetTitleBadge'

interface ParamsItem {
  _id: string
  open: boolean
  loaded: boolean
  rows: GridRow[]
  view?: BaseView
  table?: BaseTable
  widgetParams: any
}

interface Props {
  widgets: Addon[]
  utility: any
  onAction: (args: DisturbEntityArguments) => Promise<any>
}

interface State {
  params: { [key: string]: ParamsItem }
}

export default class WidgetListContainer extends React.PureComponent<
  Props,
  State
> {
  constructor(props: Props) {
    super(props)

    const params = {}

    props.widgets.forEach((widget: Addon) => {
      params[widget._id] = {
        _id: widget._id,
        open: !!localStorage.getItem(widget._id),
        loaded: false,
        rows: [],
        widgetParams: widget.params || {},
      }
    })

    this.state = {
      params,
    }
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo)
  }

  handleChange = (e: any) => {
    const addons = Array.from(this.props.widgets.values())
    const params = { ...this.state.params }

    addons.forEach(({ _id }) => {
      const open = e.includes(_id)

      if (open) {
        localStorage.setItem(_id, '1')
      } else {
        localStorage.removeItem(_id)
      }

      params[_id] = {
        ...params[_id],
        open,
      }
    })

    this.setState({ params })
  }

  handleRowChange =
    (params: ParamsItem) => (rowId: string, column: GridColumn, value: any) => {
      this.props.onAction({
        entity: TableActions.UPDATE_ROW,
        id: params.table._id,
        payload: {
          viewId: params.view._id,
          rowId,
          data: {
            [column._id]: value,
          },
        },
      })

      this.setState({
        params: {
          ...this.state.params,
          [params._id]: {
            ...params,
            rows: params.rows.map(r => {
              if (r._id === rowId) {
                return {
                  ...r,
                  [column._id]: value,
                }
              }

              return r
            }),
          },
        },
      })
    }

  handleCreateRow = (params: ParamsItem) => () => {
    const data: GridRow = createRowWithDefaults(params.table, params.view)

    this.props
      .onAction({
        entity: TableActions.CREATE_ROW,
        id: params.table._id,
        payload: {
          data: [data],
        },
      })
      .then(row => {
        this.setState({
          params: {
            ...this.state.params,
            [params._id]: {
              ...params,
              rows: (params.rows || []).concat(row),
            },
          },
        })
      })
  }

  loadItems = (widget: Addon) => {
    const { viewId, tableId } = widget.params

    fetchItem(AddonActions.READ_TABLE, tableId, { viewId })(
      store.dispatch,
    ).then(({ table, rows }) => {
      const view = getView(table, viewId)

      if (view) {
        const params = { ...this.state.params }

        for (let i in params) {
          if (i === widget._id) {
            params[i] = {
              ...params[i],
              loaded: true,
              rows,
              view,
              table,
            }
          }
        }

        this.setState({ params })
      } else {
        throw new Error('Должно быть указано представление')
      }
    })
  }

  renderItem = (widget: Addon) => {
    const widgetId: string = widget._id
    const params = this.state.params[widgetId]!
    const activeKey = params.open ? [widgetId] : undefined

    if ((params.open && !params.loaded) || (widget.titleBadge && !params.loaded)) {
      this.loadItems(widget)
    }

    const utility = this.props.utility || {}
    const headerName =
      widgetId in utility ? (
        <div className={'list-widget-name'}>
          <span className={'name'}>{widget.name}</span>
          {widget.titleBadge && (
            <WidgetTitleBadge
              table={params.table}
              data={params.rows || []}
              badges={widget.titleBadge}
            />
          )}
          <span className={'count'}>{utility[widgetId]}</span>
        </div>
      ) : (
        widget.name
      )

    return (
      <Collapse
        key={widgetId}
        ghost={true}
        activeKey={activeKey}
        onChange={this.handleChange}
        expandIconPosition={isMob() ? 'right' : void 0}>
        <Collapse.Panel key={widgetId} header={headerName}>
          {!params.loaded && <Loading width={17} />}

          {params.loaded && this.renderContent(params)}

          {params.loaded &&
            isEmpty(params.rows) &&
            params.view.type !== ViewType.LIST && (
              <p className={'no-data'}>Нет данных</p>
            )}
        </Collapse.Panel>
      </Collapse>
    )
  }

  renderContent = (params: ParamsItem) => {
    const view: BaseView = params.view!
    const table: BaseTable = params.table!
    const rows = params.rows

    if (params.view.type === ViewType.LIST) {
      return this.renderListView(params)
    }

    if (view.groups && !isEmpty(view.groups)) {
      const column: GridColumn | undefined = getColumnById(
        table,
        view.groups[0]!.columnId,
      )

      if (column) {
        const groupedRows = groupRows(
          table,
          column!,
          rows,
          view.groups[0]!,
          view!,
        )

        return groupedRows.map(g => {
          const header = value => (
            <BaseRendererFactory
              column={column}
              columnType={column.type}
              params={column.params}
              table={table}
              value={value}
              readonly={true}
            />
          )

          return (
            <Collapse key={g.value} ghost={true} defaultActiveKey={[g.value]}>
              <Collapse.Panel key={g.value} header={header(g.value)}>
                <LinkedRecordList
                  table={table}
                  view={view}
                  rows={g.rows.map(v => params.rows[v])}
                />
              </Collapse.Panel>
            </Collapse>
          )
        })
      }
    }

    return (
      <LinkedRecordList
        table={params.table!}
        view={params.view}
        rows={params.rows}
      />
    )
  }

  renderListView = (params: ParamsItem) => {
    const listHeight = Math.min((params.rows.length + 1) * 32 + 32, 300)

    return (
      <>
        <IorvoList
          height={listHeight}
          rows={params.rows}
          table={params.table}
          view={params.view}
          onChange={this.handleRowChange(params)}
        />
        {params.widgetParams.allowCreate !== false && (
          <Button onClick={this.handleCreateRow(params)}>
            + Добавить задачу
          </Button>
        )}
      </>
    )
  }

  render() {
    return (
      <div className={'list-container'}>
        {this.props.widgets.map(this.renderItem)}
      </div>
    )
  }
}
