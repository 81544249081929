import {TableActions} from "../actions/TableActions";
import {BaseActions} from "../actions/BaseActions";
import {UserActions} from "../actions/UserActions";
import {WorkspaceActions} from "../actions/WorkspaceActions";
import {EyzyUser} from "../../types/EyzyUser";
import {Roles} from "../../components/roles/Roles";
import {RowActions} from "../actions/RowActions";
import * as Sentry from "@sentry/browser";
import {AppActions} from "../actions/AppActions";

const initState: EyzyUser | null = null

export default function rows(state = initState, action) {
  switch (action.type) {
    case AppActions.INIT:
    case TableActions.FETCH:
    case BaseActions.FETCH:
    case WorkspaceActions.FETCH:
    case RowActions.SHARED_ROW: {
      const user = action.payload.user || state

      try {
        if (process.env.NODE_ENV !== "development") {
          Sentry.setUser({
            username: user.fullName,
            id: user._id
          })
        }
      } catch (e) {}

      return user
    }

    case UserActions.SET_USER:
      return action.payload

    case WorkspaceActions.CREATE:
      if (action.result) {
        return {
          ...state,
          roles: {
            ...state!.roles,
            [action.result._id]: Roles.OWNER
          }
        }
      }

      return state

    case WorkspaceActions.UPDATE_ROLE:
      if (action.result === 'ok') {
        const user = state!
        if (action.payload.userId === user._id) {
          return {
            ...user,
            roles: {
              ...user.roles,
              [action.payload.id]: action.payload.level
            }
          }
        }
      }

      return state

    default:
      return state
  }
}