import React from "react";
import avatarImg from "../../assets/svg/user_icon_7@2x.png";
import {EyzyUser, UserAvatar} from "../../types/EyzyUser";
import Tooltip from "antd/lib/tooltip";

export function getAvatarCDN(avatar: UserAvatar, useSmall: boolean = true) {
  return [
    'https://storage.googleapis.com/iorvo-user-avatar/',
    avatar.name,
    '/',
    useSmall ? 'small' : 'main',
    '.',
    avatar.ext
  ].filter(Boolean).join('')
}

interface Props {
  user: EyzyUser
  hideTip?: boolean
  dimens?: number
}

export default class Avatar extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    const {dimens, user, hideTip} = this.props

    if (hideTip !== nextProps.hideTip) {
      return true
    }

    if (dimens !== nextProps.dimens) {
      return true
    }

    if (user.fullName !== nextProps.user.fullName) {
      return true
    }

    if (!user.avatar) {
      return true
    }

    return user.avatar.name !== nextProps.user.avatar.name
  }

  render() {
    const {user, hideTip, dimens} = this.props
    const image = user.avatar ? getAvatarCDN(user.avatar) : avatarImg
    const style = dimens ? {
      width: dimens,
      height: dimens
    } : null
    const width = dimens || 30

    const avatar = (
      <span className={'avatar'} style={style}>
        <img src={image} alt={user.fullName} width={width} height={width}/>
      </span>
    )

    if (hideTip) {
      return avatar
    }

    return (
      <Tooltip title={user.fullName} destroyTooltipOnHide={true}>{avatar}</Tooltip>
    )
  }
}
