import React from 'react'
import { BaseRendererProps } from '../BaseRendererProps'
import FormulaValueRenderer from '../../_renderers/utils/FormulaValueRenderer'
import { applyFormula } from "../../../general/utils/value"

export default React.memo(function BaseFormulaRenderer(
  props: BaseRendererProps<string>,
) {
  const { value, payload = {} } = props
  const { viewAs, labelType, divideBy } = props.params || {}

  const divideByValue = divideBy
    ? applyFormula(divideBy, {
      table: payload.table,
      row: payload.row
    })
    : null

  return (
    <span className={'re-value'}>
      <FormulaValueRenderer
        displayValue={value}
        viewAs={viewAs}
        labelType={labelType}
        divideBy={divideByValue}
      />
    </span>
  )
})
