import { BaseTable } from '../types/BaseTable'
import {
  BaseView,
  BaseViewColumnParams,
  ViewLike,
  ViewSort,
} from '../types/BaseView'
import { GridRow } from '../components/grid/types/GridRow'
import { isArray } from './is'
import get from 'lodash/get'
import {
  GridColumn,
  GridColumnOptionItem,
  GridColumnParams,
} from '../components/grid/types/GridColumn'
import store from '../store'
import { EyzyUser } from '../types/EyzyUser'
import { ViewType } from '../components/grid/types/ViewType'
import { asArray } from './as'
import { ColumnType } from '../components/grid/types/ColumnType'
import { getValue } from '../general/utils/value'
import {
  BaseFilter,
  DynamicFilterItem,
} from '../components/filter/types/Filter'
import { dayjs } from '../general/utils/date'
import { arrToObj } from './array'
import { Workspace } from '../types/Workspace'
import { applyFormulaForDefault } from './formula'

export function getMockUser(): EyzyUser {
  return {
    _id: "-1",
    fullName: "",
    firstName: "",
    roles: {},
    email: "anon@iorvo.com"
  }
}

export function getDynamicFilterValue(filter: DynamicFilterItem): string {
  return dayjs(filter.value).format('MMMM, YYYY')
}

export function getTemplateColumn(
  template: string,
  table: BaseTable,
): GridColumn | undefined {
  const colId: string = (template || '').replace(/{|}/g, '')
  return getColumnById(table, colId)
}

export function renderTemplate(
  template: string | undefined,
  table: BaseTable,
  row: GridRow,
): string {
  return (template || '').replace(
    /{([^}]+)}/g,
    (searchString: string, colId: string) => {
      const column: GridColumn | undefined = getColumnById(table, colId)

      if (!column) {
        // TODO must throw an error
        return ''
      }

      const value = getValue(table, column, row) || ''

      if ('string' === typeof value) {
        return value
      }

      if (value.text) {
        return value.text
      }

      if (isArray(value) && value[0]) {
        return value[0].text || ''
      }

      return ''
    },
  )
}

export function getStoreUsers(asMap?: boolean) {
  const { wss, active } = store.getState()
  const ws = wss.find(w => w._id === active.ws)
  const users: EyzyUser[] = ws ? ws.collaborators : []

  if (true === asMap) {
    return arrToObj(users, '_id', false)
  }

  return users
}

export function getLinkedItem(id: string): any {
  const l = store.getState().userMap
  return l[id]
}

export function getView(table: BaseTable, viewId: any): BaseView | undefined {
  return (table.views || []).find(view => view._id === viewId)
}

export function getViewParamItem(view: BaseView, paramName: string): any {
  return get(view, `params.${paramName}`, null)
}

export function mergeColumnParams(
  column: GridColumn,
  view: BaseView,
): Partial<GridColumnParams> {
  return {
    ...column.params,
    ...getColumnParams(view, column._id),
  }
}

export function getColumnParamsItem(
  view: BaseView | ViewLike,
  columnId: string,
  param: string,
): any {
  return get(view, `columnsParams.${columnId}.${param}`)
}

export function mergeColumnWithViewParams(
  view: ViewLike,
  column: GridColumn,
): GridColumnParams {
  return {
    ...getColumnParams(view, column._id),
    ...(column.params || {}),
  }
}

export function getColumnParams(
  view: BaseView | ViewLike,
  columnId: string,
): any {
  return get(view, `columnsParams.${columnId}`, {})
}

export function getRowDefaultValues(
  table: BaseTable,
  columnParams: BaseViewColumnParams = {},
): GridRow {
  const row = {}

  table.columns.forEach(column => {
    if (!column.params) {
      return
    }

    const viewParams = columnParams[column._id] || {}

    if (viewParams.defaultValue !== undefined) {
      row[column._id] = getDefaultValue(column, viewParams.defaultValue)
    }
  })

  return row
}

export function getFormDefaultValues(
  table: BaseTable,
  columnParams: BaseViewColumnParams = {},
  forms: any,
): GridRow {
  const row = {}

  table.columns.forEach(column => {
    if (!column.params) {
      return
    }

    const viewParams = columnParams[column._id] || {}

    if (viewParams.defaultValue !== undefined) {
      // В виджетах НУЖНО сделать значение по умолчанию как и у другой ФОРМЫ (на данный момент)
      const possibleFormLink = String(viewParams.defaultValue).match(
        /FORM:(\w+);COLUMN:(\w+)/,
      )

      if (possibleFormLink) {
        const [, formId, columnId] = possibleFormLink
        const value = get(forms, `${formId}.${columnId}`, undefined)

        if (value !== undefined) {
          row[column._id] = value
        }
      } else {
        row[column._id] = getDefaultValue(column, viewParams.defaultValue)
      }
    }
  })

  return row
}

export function getDefaultValue(column: GridColumn, value: any) {
  switch (column.type) {
    case ColumnType.DATE: {
      return applyFormulaForDefault(value)
    }

    case ColumnType.USER: {
      return asArray(value).map(id => {
        if (id === 'CURRENT_USER()' || id === 'currentUser') {
          return currentUser()._id
        }

        return id
      })
    }
  }

  return value
}

export function appId() {
  return store.getState().app._id
}

export function currentUser() {
  return store.getState().user || getMockUser()
}

export function getWSTable(ws: Workspace, id: string): BaseTable | undefined {
  if (!ws) {
    return
  }

  let table

  ws.bases.some(base => {
    // еще не загружено
    if (base.tables[0] && !base.tables[0]._id) {
      return false
    }

    table = base.tables.find(t => t._id === id)

    return !!table
  })

  return table
}

export function getViewByType(table: BaseTable, type: ViewType): BaseView[] {
  return table.views.filter(view => view.type === type)
}

export function getViewName(type: ViewType): string {
  switch (type) {
    case ViewType.CALENDAR:
      return 'Календарь'
    case ViewType.FORM:
      return 'Форма'

    default:
      return 'Сетка'
  }
}

export function getColumnsByType(table: BaseTable, type: any): GridColumn[] {
  return table.columns.filter(column => column.params && column.type === type)
}

export function getColumnById(
  table: BaseTable,
  id: string,
): GridColumn | undefined {
  return table.columns.find(column => column._id === id)
}

const allowedColumnForLinkTypes = [
  ColumnType.TEXT,
  ColumnType.LONG_TEXT,
  ColumnType.DATE,
  ColumnType.EMAIL,
  ColumnType.PHONE,
  ColumnType.LINK,
  ColumnType.NUMBER,
  ColumnType.LINK_TO_RECORD,
]

export function getAllowedColumnForLink(
  table: BaseTable,
): GridColumn | undefined {
  return table.columns.find(c => allowedColumnForLinkTypes.includes(c.type))
}

export function getOptionText(
  value: string,
  options: GridColumnOptionItem[],
): GridColumnOptionItem | undefined {
  return options.find(o => o._id === value)
}

export function getUsers(userValues: any[], store: EyzyUser[]): EyzyUser[] {
  return asArray(userValues)
    .map(value => {
      // getValue читает ИЗ сторы. Этого не должно быть
      if (value._id) return value._id

      return value
    })
    .map(value => {
      return store.find(s => s._id === value)
    })
}

const applyFormulaLikeValue = (value: any) => {
  if (/CURRENT_USER/.test(value)) {
    return [currentUser()._id]
  }

  return value
}

export function getViewDefaultValues(view: BaseView): any {
  if (!view || !view.columnsParams) {
    return
  }

  const columnsParams = view.columnsParams

  return Object.keys(columnsParams).reduce((result, column) => {
    const params = columnsParams[column]

    if (params && params.defaultValue) {
      result[column] = applyFormulaLikeValue(params.defaultValue)
    }

    return result
  }, {})
}

export function pick(targetObj: any, fields: string[]): any {
  const result = {}

  fields.forEach(field => {
    result[field] = targetObj[field]
  })

  return result
}

export function getCurrentUserMarkup(): EyzyUser {
  return {
    _id: 'currentUser',
    fullName: 'Текущий',
    // @ts-ignore
    params: {},
  }
}

export function getFilterColumns(
  table: BaseTable,
  filter: BaseFilter,
): GridColumn[] {
  const columns = arrToObj(table.columns, '_id', false)
  const collect = (f: DynamicFilterItem | BaseFilter) => {
    if ((f as BaseFilter).filters) {
      ;(f as BaseFilter).filters.forEach(collect)
    } else {
      result.push(columns[(f as DynamicFilterItem).col])
    }
  }

  const result: GridColumn[] = []

  filter.filters.forEach(collect)

  return result
}

export function getFilterColumnsText(
  table: BaseTable,
  filter: BaseFilter,
): string {
  const columns: GridColumn[] = getFilterColumns(table, filter)
  const filteredBy: string[] = Array.from(new Set(columns.map(c => c.name)))

  const res = [filteredBy[0]]

  if (filteredBy.length > 1) {
    res.push(`(+${filteredBy.length - 1})`)
  }

  return res.join(' ')
}

export function getSortColumnsText(table: BaseTable, sort: ViewSort[]): string {
  const columns = arrToObj(table.columns, '_id', false)
  const sortColumns = sort
    .map(s => columns[s.columnId])
    .filter(Boolean)
    .map(c => c.name)

  sortColumns.splice(1)

  if (sortColumns.length < sort.length) {
    return sortColumns.join(', ') + ` +${sort.length - 1}`
  }

  return sortColumns.join(', ')
}


export function getFormCDN(table: BaseTable, imageName: string): string {
  return process.env.REACT_APP_API + `table/${table._id}/formLogo/${imageName}`
}
