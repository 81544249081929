// Параметры колонки, которые можно переопределить для view
import { BaseView, ViewLike } from '../types/BaseView'
import { GridColumn } from '../components/grid/types/GridColumn'
import { getColumnParams } from './get'
import { BaseTable } from "../types/BaseTable"
import { arrToObj } from "./array"
import { Roles } from "../components/roles/Roles"

const columnParamKeys = ['defaultValue', 'viewId', 'format']

export const formatNumber = (number: any, precision?: number): any => {
  if (!precision) {
    return new Intl.NumberFormat('ru-RU').format(number)
  }

  return new Intl.NumberFormat('ru-RU', {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
    style: 'decimal',
  }).format(number)
}

export const hasAnyOverrides = (
  view: BaseView,
  column: GridColumn,
): boolean => {
  const viewParams = getColumnParams(view, column._id)

  return columnParamKeys.some(key => {
    return key in viewParams
  })
}

const viewTypeIcon = {
  grid: 'table',
  calendar: 'calendar',
  kanban: 'kanban',
  list: 'list',
}

export const getViewIcon = type => {
  return viewTypeIcon[type]
}

export const hasViewGroup = (view?: ViewLike, table?: BaseTable): boolean => {
  if (!view || !table) {
    return false
  }

  if (!view.groups || view.groups.length === 0) {
    return false
  }

  const columns = arrToObj(table.columns, '_id', false)
  const groups = view.groups.filter(g => g.columnId in columns)

  return groups.length > 0
}

export const hasColor = (view?: ViewLike): boolean => {
  if (!view) {
    return false
  }

  return !!view.color
}

export const isAllowedToShareRow = (view: BaseView, role: Roles): boolean => {
  const viewShareRole: Roles = view.details?.shareRole ?? Roles.ADMIN

  return role >= viewShareRole
}
