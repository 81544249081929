import React from 'react'
import { SimpleRendererProps } from '../types/SimpleRendererProps'
import { formatNumber } from '../../../utils/view'

const NumberRenderer: React.FC<SimpleRendererProps> = ({
  value,
  className,
  params,
}) => {
  if (!value) {
    return
  }

  const displayValue = isNaN(value) ? null : formatNumber(value, params.precision)

  return (
    <div className={className} title={displayValue}>
      {displayValue}
    </div>
  )
}

export default React.memo(NumberRenderer)
