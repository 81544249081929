import React from 'react'
import {BaseFilter} from '../../../../../../components/filter/types/Filter'
import {TableActions} from '../../../../../../store/actions/TableActions'
import {BaseTable} from "../../../../../../types/BaseTable";
import {ActionType} from "../../../../../../store/CommonEffects";
import FilterPicker from '../../../../../../components/filter/FilterPicker';
import {EyzyUser} from "../../../../../../types/EyzyUser";

interface Props {
  filter: BaseFilter
  table: BaseTable
  users: EyzyUser[]
  onAction: (action: ActionType, payload: any) => void
}

export default React.memo(function CustomizeFilter(props: Props) {
  const filterHeader: string = !!props.filter
    ? 'В этом представлении отображать записи'
    : 'К этому представлению не применяются никакие условия фильтрации'

  const payload = {
    users: props.users
  }

  const onChange = (filter: BaseFilter) => {
    props.onAction(TableActions.UPDATE_VIEW_FILTER, {filter})
  }

  return (
    <div className={'filter-fields'}>
      <FilterPicker
        header={filterHeader}
        filter={props.filter}
        table={props.table}
        payload={payload}
        showCtrl={true}
        onChange={onChange}
      />
    </div>
  )
})
