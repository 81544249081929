import React, { useMemo } from 'react'
import { applyFormula } from '../../../general/utils/value'
import { renderFormulaValue } from '../../../utils/formula'
import FormulaValueRenderer from '../utils/FormulaValueRenderer'
import { FormRendererProps } from '../types/FormRenderer'
import { useFormPayload } from '../RendererContext'

const FormFormulaRenderer: React.FC<FormRendererProps> = ({
  className,
  params,
  column,
}) => {
  const { table, row } = useFormPayload()
  const displayValue = useMemo(() => {
    const value = applyFormula(params.formula, {
      row,
      table,
      column,
    })

    return renderFormulaValue(value)
  }, [row, table, column, params])

  const divideBy = useMemo(() => {
    if (!params?.divideBy) {
      return
    }

    return applyFormula(params.divideBy, {
      row,
      table,
      column,
    })
  }, [row, column, params, table])

  return (
    <div className={className}>
      <FormulaValueRenderer
        divideBy={divideBy}
        displayValue={displayValue}
        labelType={params.labelType}
        viewAs={params.viewAs}
      />
    </div>
  )
}

export default React.memo(FormFormulaRenderer)
