import React from "react"
import Modal from "antd/es/modal"
import { LinkedRow } from "../../types/Row"
import { BaseTable } from "../../types/BaseTable"
import { GridRow } from "../grid/types/GridRow"
import { asArray } from "../../utils/as"
import { Button, Checkbox, Input } from "../../components/irv-ui"
import Loading from "../loading/Loading"
import { getAllowedColumnForLink, getView } from "../../utils/get"
import { isArray, isEmpty, isMob } from "../../utils/is"
import LinkedRecordsList from "./LinkedRecordList"
import Drawer from "antd/es/drawer"
import { GridColumn } from "../grid/types/GridColumn"
import { groupRows } from "../../utils/grid"
import { BaseView } from "../../types/BaseView"
import Collapse from "antd/lib/collapse"
import BaseRendererFactory from "../__renderers/RendererFactory"
import { applySort } from "../../utils/sort"
import { __get } from "../../utils/request"
import { getValue } from "../../general/utils/value"

import "./style.scss"

interface Props {
  multiple?: boolean
  viewId?: string
  tableId: string
  columnId: string
  onClose: () => void
  onSelect: (linkedRow: LinkedRow[], originalRow: GridRow[], originTable: BaseTable) => void
  visible: boolean
  container?: any
  selectedRows: LinkedRow[]
  isForm?: boolean
  customLink?: string
}

interface State {
  table: BaseTable
  view?: BaseView
  groups?: any
  rows: GridRow[] | null
  selected: string[]
  indexed?: Indexes[]
  filter: string
}

interface Indexes {
  term: string
  id: string
}

export default class LinkedRecordsModal extends React.PureComponent<Props, State> {
  private _rows: GridRow[] = []

  constructor(props: Props) {
    super(props)

    this.state = {
      selected: asArray(props.selectedRows).map(i => i._id),
      filter: '',
      table: {} as BaseTable,
      rows: null
    }
  }

  static buildIndexes = (rows: GridRow[], table: BaseTable): Indexes[] => {
    return rows.map(row => {
      const term = table.columns.map(column => {
        const value: any = getValue(table, column, row)

        if (value && value.value) {
          return value.value
        }

        if (isArray(value)) {
          return value.map(item => item.value || item).join(' ')
        }

        return value
      }).join(' ')

      return {
        term,
        id: row._id
      }
    })
  }

  componentDidMount(): void {
    const {viewId, tableId, columnId, multiple, customLink} = this.props
    let url = customLink || `table/${tableId}/records/${columnId}`

    if (viewId) {
      url += `?viewId=${viewId}`
    }

    __get(url).then(({table, rows}) => {
      let sortedRows: GridRow[] = rows
        .concat()
        .sort((r0: GridRow, r1: GridRow) => {
          return this.state.selected.includes(r0._id) ? -1 : 1
        })
        .filter((row: GridRow) => {
          if (multiple) {
            return true
          }

          return !this.state.selected.includes(row._id)
        })

      let newState: Partial<State> = {
        table,
        rows: sortedRows,
        indexed: LinkedRecordsModal.buildIndexes(sortedRows, table)
      }

      this._rows = sortedRows

      if (viewId) {
        const view = getView(table, viewId)

        newState.view = view

        if (view && !isEmpty(view.groups)) {
          const column: GridColumn | undefined = table.columns.find(c => c._id === view.groups![0]!.columnId)

          if (column) {
            newState.groups = groupRows(
              table,
              column,
              sortedRows,
              view.groups![0],
              view
            )
          }
        }

        if (view && view.sort) {
          const viewSortedRows = applySort(sortedRows.concat(), table, view.sort)
            .sort((r0: GridRow, r1: GridRow) => {
              return this.state.selected.includes(r0._id) ? -1 : 1
            })

          this._rows = viewSortedRows.concat()
          newState.rows = viewSortedRows.concat()
        }
      }

      this.setState(newState as State)
    })
  }

  handleFilter = (e: any) => {
    if (!this.state.indexed) {
      return
    }

    const filter = e.target.value.replace(/\s+/, ' ')
    const filteredRowsIds: string[] = this.state.indexed
      .filter(indexed => {
        return new RegExp(filter.trim().replace(/\s{2,}/g, ''), 'i').test(indexed.term)
      })
      .map(index => index.id)

    this.setState({
      filter,
      rows: this._rows.filter(row => filteredRowsIds.includes(row._id))
    })
  }

  handleSelect = (row: GridRow) => {
    const table = this.state.table
    const column = getAllowedColumnForLink(table)

    if (!column) {
      throw new ReferenceError('Нет колонок для выбора имени!')
    }

    let value: string | any[] = getValue(table, column, row)

    if (isArray(value)) {
      value = (value as any[]).map(i => i.value).join(', ')
    }

    if (this.props.multiple) {
      const alreadySelected: string[] = [...this.state.selected]

      if (alreadySelected.includes(row._id)) {
        alreadySelected.splice(
          alreadySelected.indexOf(row._id),
          1
        )
      } else {
        alreadySelected.push(row._id)
      }

      this.setState({
        selected: alreadySelected
      })
    } else {
      this.props.onSelect([{
        _id: row._id,
        value: value as string
      }], [row], table)
    }
  }

  toTemporary = (value) => {
    // TODO нужно
    if (isArray(value) && value[0] && value[0].value) {
      return value[0].value
    }

    return value
  }

  applySelection = () => {
    const {selected, table} = this.state
    const column = getAllowedColumnForLink(table)
    const rows = this._rows.filter(r => {
      return selected.includes(r._id)
    })

    const result = rows
      .map(r => ({
        _id: r._id,
        value: this.toTemporary(getValue(table, r, column!))
      }))

    this.props.onSelect(
      result,
      rows,
      table
    )
  }

  getGroupRows = (group, rows) => {
    return group.rows.map(index => rows[index]).filter(Boolean)
  }

  getGroupHeader = (group) => {
    const {table, view, selected, rows} = this.state
    const multiple: boolean = !!this.props.multiple

    if (!view) {
      return group.value
    }

    const column: GridColumn | undefined = table.columns.find(c => c._id === view.groups![0]!.columnId)

    if (!column) {
      return group.value
    }

    const isAllGroupUnSelected: boolean = group.rows.some(index => {
      const row = rows![index]
      return row && !selected.includes(row._id)
    })

    const onChange = (e: any) => {
      const selected: string[] = [...this.state.selected]

      if (e.target.checked) {
        group.rows.forEach(index => {
          const row = rows![index]

          if (row) {
            selected.push(row._id)
          }
        })

        this.setState({selected})
      } else {
        const groupRowIds: string[] = []

        group.rows.forEach(index => {
          const row = rows![index]

          if (row) {
            groupRowIds.push(row._id)
          }
        })

        this.setState({
          selected: selected.filter(s => !groupRowIds.includes(s))
        })
      }
    }

    if (!group.value) {
      return (
        <div className={'group-header'}>
          {multiple && (
            <span className={'checkbox-wrap'} onClick={e => e.stopPropagation()}>
              <Checkbox checked={!isAllGroupUnSelected} onChange={onChange}/>
            </span>
          )}
          (Empty)
        </div>
      )
    }

    return (
      <div className={'group-header'}>
        {multiple && (
          <span className={'checkbox-wrap'} onClick={e => e.stopPropagation()}>
            <Checkbox checked={!isAllGroupUnSelected} onChange={onChange}/>
          </span>
        )}
        <BaseRendererFactory
          column={column}
          columnType={column.type}
          params={column.params}
          table={table}
          value={group.value}
          readonly={true}
        />
      </div>
    )
  }

  renderHeader = () => {
    return (
      <Input fit placeholder="Поиск записи" autoFocus value={this.state.filter} onChange={this.handleFilter}/>
    )
  }

  renderContainer = (child: any) => {
    const {visible, container, onClose} = this.props

    if (isMob()) {
      return (
        <Drawer
          title={this.renderHeader()}
          placement={'bottom'}
          onClose={onClose}
          className="linked-records-modal irv-ow"
          height={document.body.clientHeight}
          visible={true}
          footer={this.renderFooter()}
        >
          {child}
        </Drawer>
      )
    }

    return (
      <Modal
        visible={visible}
        title={this.renderHeader()}
        width={"900px"}
        className="linked-records-modal irv-ow"
        getContainer={() => container || document.body}
        onCancel={onClose}
        footer={this.renderFooter()}>
        {child}
      </Modal>
    )
  }

  renderFooter = () => {
    if (!this.props.multiple) {
      return null
    }

    return (
      <>
        <Button variant={'primary'} onClick={this.applySelection}>Выбрать ({this.state.selected.length})</Button>
        <Button onClick={this.props.onClose}>Отмена</Button>
      </>
    )
  }

  renderGroups = () => {
    const {rows, selected, table, groups, view} = this.state

    if (isEmpty(rows)) {
      return null
    }

    return (
      <Collapse defaultActiveKey={groups.map(g => g.value || '(Empty)')}>
        {groups.map(group => (
          <Collapse.Panel key={group.value || '(Empty)'} header={this.getGroupHeader(group)}>
            <LinkedRecordsList
              rows={this.getGroupRows(group, rows)}
              view={view}
              table={table!}
              selected={selected}
              onSelect={this.handleSelect}
              showCheckbox={!!this.props.multiple}
            />
          </Collapse.Panel>
        ))}
      </Collapse>
    )
  }

  renderRecords = () => {
    const {rows, selected, table, groups} = this.state

    if (groups) {
      return this.renderGroups()
    }

    return (
      <LinkedRecordsList
        showCheckbox={!!this.props.multiple}
        rows={rows!}
        view={this.state.view}
        table={table!}
        selected={selected}
        onSelect={this.handleSelect}
      />
    )
  }

  render() {
    const {rows, table} = this.state
    const isCalled: boolean = !!rows

    return this.renderContainer(
      <>
        {!isCalled && (
          <Loading/>
        )}

        {isCalled && !isEmpty(rows) && (
          <h2 className={'t-name'}>{table.name}</h2>
        )}

        {isCalled && this.renderRecords()}

        {isCalled && isEmpty(rows) && (
          <p className={'no-records'}>Записи не найдены</p>
        )}
      </>
    )
  }
}
