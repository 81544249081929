import React from 'react'
import {SettingsItemComponentProps} from "../types";
import Loading from "../../../loading/Loading";
import {AppActions} from "../../../../store/actions/AppActions";
import {Workspace} from "../../../../types/Workspace";
import {bindActionCreators} from "redux";
import {loadEntity, LoadEntityArguments} from "../../../../store/CommonEffects";
import {connect} from "react-redux";
import {matchUrl, Pages} from "../../../../utils/navigation";
import {BaseTable} from "../../../../types/BaseTable";
import {getColumnById, getWSTable} from "../../../../utils/get";
import {GridColumn} from "../../../grid/types/GridColumn";
import {ColumnType} from "../../../grid/types/ColumnType";
import ColumnSelector from "../../columnSelector/ColumnSelector";

interface State {
  loaded?: boolean
  currentTable?: string
  linkColumnId?: string
  foreignId?: string
}

class LookupSettings extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      linkColumnId: props.params.linkColumnId,
      foreignId: props.params.foreignId,
    }
  }

  componentDidMount() {
    const {app, ws, loadEntity} = this.props
    const parsedUrl = matchUrl(document.location.pathname, {
      path: Pages.MAIN_WORKSPACE
    })

    let currentTable

    if (parsedUrl && parsedUrl.params) {
      currentTable = parsedUrl.params.tableId
    }

    loadEntity({
      id: app._id,
      action: AppActions.ENTITIES,
      queryString: {
        ws: ws._id
      }
    }).then(_ => this.setState({loaded: true, currentTable}))
  }

  handleForeignColumnChange = (foreignId: string) => {
    this.setState({foreignId})

    this.props.onAction({
      linkColumnId: this.state.linkColumnId,
      foreignId
    })
  }

  handleColumnChange = (linkColumnId: string) => {
    this.setState({linkColumnId})
  }

  renderForeignColumns = (currentTable: BaseTable) => {
    const {ws, column} = this.props
    const {linkColumnId} = this.state
    const linkColumn: GridColumn | undefined = getColumnById(currentTable, linkColumnId)

    if (!linkColumn) {
      return null
    }

    const table: BaseTable | undefined = getWSTable(ws, linkColumn.params.tableId)

    if (!table) {
      return null
    }

    const hiddenColumns: string[] = table.columns
      .filter(column => {
        return column.type === ColumnType.FORMULA
      })
      .map(c => c._id)

    return (
      <div className={'column-settings-row'}>
        <div className={'column-settings-slabel'}>
          Поле таблицы <strong>{table.name}</strong><span>, которое вы хотели бы найти</span>
        </div>
        <div className={'column-settings-ctrl c-selector'}>
          <ColumnSelector
            table={table}
            placeholder={'Выберите поле'}
            selectedItem={column.params.foreignId}
            hiddenColumns={hiddenColumns}
            onChange={this.handleForeignColumnChange}
          />
        </div>
      </div>
    )
  }

  renderSettings = () => {
    const {ws} = this.props
    const {currentTable, linkColumnId} = this.state
    const table: BaseTable | undefined = getWSTable(ws, currentTable)

    if (!table) {
      return null
    }

    const hiddenColumns: string[] = table.columns
      .filter(c => {
        return c.type !== ColumnType.LINK_TO_RECORD
      })
      .map(c => c._id)

    return (
      <>
        <div className={'column-settings-row'}>
          <div className={'column-settings-label'}>
            Поле в этой таблице, которое ссылается на записи, которые вы хотите найти
          </div>
          <div className={'column-settings-ctrl c-selector'}>
            <ColumnSelector
              table={table}
              placeholder={'Выберите поле'}
              selectedItem={linkColumnId}
              hiddenColumns={hiddenColumns}
              onChange={this.handleColumnChange}
            />
          </div>
        </div>

        {linkColumnId && this.renderForeignColumns(table)}
      </>
    )
  }

  render() {
    return (
      <div className={'column-settings-link'}>
        {this.state.loaded ? this.renderSettings() : <Loading/>}
      </div>
    )

  }
}

type ReduxDispatchProps = {
  loadEntity: (args: LoadEntityArguments) => Promise<any>,
}

type ReduxStateProps = {
  ws: Workspace
  app: any
}

type Props = SettingsItemComponentProps & ReduxStateProps & ReduxDispatchProps

const mapStateToProps = (state: any): ReduxStateProps => ({
  ws: state.wss.find(ws => ws._id === state.active.ws),
  app: state.app
})

const mapDispatchToProps = (dispatch) => bindActionCreators<any, ReduxDispatchProps>({
  loadEntity,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(LookupSettings)
