import React from 'react'
import {isEmail} from "../../../utils/is";
import {GridRendererProps} from "../GridRendererProps";
import { getValue } from '../../../general/utils/value';

export default React.memo(function GridEmailRenderer(props: GridRendererProps) {
  const {row, column} = props
  const value = getValue(column.table, column, row)

  if (!value) {
    return null
  }

  const isValidEmail: boolean = isEmail(value)

  if (!isValidEmail) {
    return (
      <div className="re-value">
        {String(value)}
      </div>
    )
  }

  return (
    <div className="re-value email">
      <span>{value}</span>
      <a className={'email-to'} href={`mailto:${value}`}>@</a>
    </div>
  )
})
