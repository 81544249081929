import React from 'react'
import { BaseRendererProps } from '../types/BaseRenderer'
import { Checkbox } from '../../../components/irv-ui'
import { RendererClassNames } from '../definitions'

const CheckboxRenderer: React.FC<BaseRendererProps> = ({ value }) => {
  return <Checkbox checked={value} className={RendererClassNames.CHECKBOX} />
}

export default React.memo(CheckboxRenderer)
