import notification from 'antd/lib/notification'
import { buildLink, history, Pages } from './navigation'

// @ts-ignore
export const baseUrl: string = process.env.REACT_APP_API

function getError(r, status): any {
  return {
    message: r.message,
    status,
  }
}

function onFailture({ status, message }, ignoreMessage?: boolean) {
  if (401 === status) {
    return history.replace(buildLink(Pages.LOGIN, {}))
  }

  if (403 === status) {
    return history.replace(buildLink(Pages.MAIN, {}))
  }

  if (404 === status) {
    return history.replace('/404')
  }

  if (true !== ignoreMessage && !message) {
    notification.info({
      className: 'resp_notification',
      placement: 'bottomLeft',
      message: 'Что-не так 😔 Попробуйте перезагрузить страницу',
    })
  }
}

export function request(url: string, data?: any, ignoreMessage?: boolean): any {
  let status
  let ok

  const bodyAndHeaders = data
    ? {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      }
    : {
        body: null,
        method: 'GET',
      }

  const init = {
    credentials: 'include',
    mode: 'cors',
    cache: 'no-cache',
    referrerPolicy: 'strict-origin-when-cross-origin',
    ...bodyAndHeaders,
  }

  // @ts-ignore
  const res = fetch(baseUrl + url, init)
    .then(r => {
      status = r.status
      ok = r.ok

      if (status === 204) {
        return 'ok'
      }

      return r.json()
    })
    .then(r => {
      if (status === 404) {
        return Promise.reject(getError(r, status))
      }

      if (r && r.message) {
        notification.info({
          className: 'resp_notification',
          placement: 'bottomLeft',
          message: r.message,
        })
      }

      if (ok) {
        if (status === 204) {
          return 'ok'
        }

        return r ? r.result : r
      }

      return Promise.reject(getError(r, status))
    })

  res.catch(e => {
    onFailture(e, ignoreMessage)

    // eslint-disable-next-line
    // throw {
    //   error: e,
    //   data: bodyAndHeaders,
    //   url: baseUrl + url
    // }
  })

  return res
}

export function __get(url: string) {
  return request(url)
}

export function __post(
  url: string,
  data: any = {},
  ignoreMessage?: boolean,
): any {
  return request(url, data, ignoreMessage)
}

export function getActionURL(
  type: string,
  action: string | null,
  id: string | null,
): string {
  return [type, id, action].filter(Boolean).join('/')
}

export function getQuery(payload: any) {
  const chunks: String[] = Object.keys(payload || {}).map(key => {
    return `${encodeURIComponent(key)}=${encodeURIComponent(payload[key])}`
  })

  if (chunks.length) {
    return `?${chunks.join('&')}`
  }

  return ''
}

export function addQuery(url: string, key: string, value?: any): string {
  const urlPart: string = `${key}${value ? '=' + value : ''}`

  if (/\?/.test(url.trim())) {
    return url + '&' + urlPart
  }

  return url + '?' + urlPart
}
