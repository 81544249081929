import React from 'react'
import { Addon } from '../../types/Addon'
import Shape from '../shape/Shape'
import Icon from '../icon/Icon'
import './styles/widgets.scss'
import { Workspace } from '../../types/Workspace'
import { random } from '../../utils/random'
import FormWidgetContainer from './forms/FormWidgetContainer'
import { isMob } from '../../utils/is'

interface Props {
  workspace: Workspace
  widgets: Addon[]
  onSend?: () => void
}

interface State {
  activeWidget: null | Addon
  wKey?: string // Чтобы спрятать succeed состояние
}

export default class WidgetsContainer extends React.PureComponent<
  Props,
  State
> {
  state = {
    activeWidget: null,
    wKey: '',
  }

  handleClose = () => {
    this.setState({ activeWidget: null })
  }

  handleClick = e => {
    const { id } = e.currentTarget.dataset

    if (!id) {
      throw new TypeError('Ooh!')
    }

    const activeWidget: Addon = this.props.widgets.find(w => w._id === id)!

    this.setState({
      activeWidget,
      wKey: activeWidget._id + random('a', 3),
    })
  }

  renderItem = (widget: Addon) => {
    const { color, icon } = widget

    return (
      <div
        className="widget-item"
        key={widget._id}
        data-id={widget._id}
        onClick={this.handleClick}
        tabIndex={-1}>
        <Shape color={color} type={'circle'}>
          <Icon type={icon} />
        </Shape>
        <span className="truncate">{widget.name}</span>
      </div>
    )
  }

  render() {
    const { activeWidget, wKey } = this.state
    const { workspace, widgets, onSend } = this.props

    return (
      <>
        <FormWidgetContainer
          key={wKey}
          wId={workspace._id}
          widget={activeWidget}
          ws={workspace}
          onClose={this.handleClose}
          onSend={onSend}
        />

        <div className={'widgets-container'}>
          {widgets.map(this.renderItem)}

          {/* ебанутый фикс для мобилы. Не все виджеты вылазиют */}
          {isMob(true) && <div className={'mf'} />}
        </div>
      </>
    )
  }
}
