import React, { useCallback, useState } from 'react'
import { SettingsItemComponentProps } from '../types'
import { TextArea } from '../../../irv-ui'

export default React.memo((props: SettingsItemComponentProps) => {
  const { params, onAction } = props

  const [formula, setFormula] = useState(params.formula)

  const handleBlur = useCallback(e => {
    onAction({
      formula: e.target.value,
    })
  }, [])

  const handleChange = useCallback(formula => {
    setFormula(formula)
  }, [])

  return (
    <div className={'column-settings-crow'}>
      <div className="column-settings-cell">
        <div className={'column-settings-label'}>Формула</div>
        <div className={'column-settings-ctrl c-selector'}>
          <TextArea
            onBlur={handleBlur}
            onChange={handleChange}
            fit
            value={formula}
          />
        </div>
      </div>
    </div>
  )
})
