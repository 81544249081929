import React, { useCallback, useMemo, useState } from 'react'
import { DetailsBlockProps } from '../types'
import { Table, Tag } from '../../../irv-ui'
import { getValue } from '../../../../general/utils/value'
import { applySort } from '../../../../utils/sort'
import {
  currentUser,
  getColumnById,
  getColumnParamsItem,
  mergeColumnWithViewParams,
} from '../../../../utils/get'
import { TableColumn } from '../../../irv-ui/components/table/types/Table'
import { GridRow } from '../../../grid/types/GridRow'
import { Filter } from '../../../../general/filters/Filter'
import NoData from '../../../helpers/NoData'
import { TableTheme } from '../../../irv-ui/components/table/types/TableTheme'
import get from 'lodash.get'
import { aggregateGroupValue } from '../../../../utils/aggregation'
import { getColorClass } from '../../../../utils/colors'
import Loading from '../../../loading/Loading'
import { __get, getActionURL, getQuery } from '../../../../utils/request'
import {
  FormProvider,
  useFormPayload,
} from '../../../_renderers/RendererContext'
import { SimpleRendererFactory } from '../../../_renderers/factory'
import { useBlockProvider } from '../BlockProvider'
import { BaseTable } from '../../../../types/BaseTable'
import { ViewDetailsBlock } from '../../../../types/ViewDetails'
import { EyzyUser } from '../../../../types/EyzyUser'

const getTableProps = (
  block: ViewDetailsBlock,
  table: BaseTable,
  rows: GridRow[],
  users: EyzyUser[],
): { columns: TableColumn[]; data: GridRow[] } => {
  const { columnsParams, viewId, sort, tableColumns, filter } = block.params

  const filteredRows = filter
    ? new Filter({
        table,
        user: currentUser(),
        filter,
      }).bulk(rows)
    : rows

  const view = viewId ? table.views.find(v => v._id === viewId) : null
  const data = applySort(filteredRows, table, view ? view.sort : sort)
  const columns = tableColumns
    ? tableColumns.map(id => getColumnById(table, id)).filter(Boolean)
    : table.columns

  const resultColumns: TableColumn[] = columns.map(column => {
    const width =
      (view
        ? getColumnParamsItem(view, column._id, 'width')
        : column.params.width) || column.params.width

    const rendererParams = view
      ? mergeColumnWithViewParams(view, column)
      : get(columnsParams, column._id, column.params)

    const providerValue = {
      table,
      users,
    }

    return {
      _id: column._id,
      title: column.name,
      width,
      render: (row: GridRow) => {
        return (
          <FormProvider.Provider value={{ ...providerValue, row }}>
            <SimpleRendererFactory
              value={getValue(table, column, row)}
              column={column}
              params={rendererParams}
            />
          </FormProvider.Provider>
        )
      },
    }
  })

  return {
    data,
    columns: resultColumns,
  }
}

const DetailsTableBlock: React.FC<DetailsBlockProps> = ({ block }) => {
  const { table, row, links, users } = useFormPayload()
  const { view } = useBlockProvider()
  const [childrenPayload, setChildrenPayload] = useState<{
    [rowId: string]: { table: BaseTable; rows: GridRow[] }
  }>({})

  const foreignTable = useMemo(() => {
    return links[block.params.columnId]?.table
  }, [links, block])

  const ChildrenComponent = useMemo(() => {
    const { tableChildren } = block.params

    if (!tableChildren) {
      return null
    }

    return ({ row }) => {
      if (!childrenPayload[row.origin._id]) {
        return <Loading width={22} />
      }

      const { table, rows } = childrenPayload[row.origin._id]
      const props = getTableProps(tableChildren, table, rows, users)

      return (
        <Table
          {...props}
          empty={<NoData />}
          height={block.params.tableChildren ? void 0 : 320}
          theme={[TableTheme.MAIN]}
          size={'s'}
        />
      )
    }
  }, [childrenPayload, block, row, users])

  const tableProps = useMemo(() => {
    const { columnId } = block.params
    const { rows } = links[columnId]

    if (!rows || !table) {
      return
    }

    return getTableProps(block, foreignTable, rows, users)
  }, [block, table, foreignTable, links, users])

  const badges = useMemo(() => {
    if (!block.titleBadge) {
      return
    }

    const { columnId } = block.params
    const { table } = links[columnId]
    const badges = []

    block.titleBadge.forEach(badge => {
      const column = getColumnById(table, badge.col)

      if (!column) {
        return
      }

      const agg = aggregateGroupValue(table, column, tableProps.data, badge.agg)

      if (!agg) {
        return
      }

      const content = [
        badge.label === false ? '' : badge.label || agg.label,
        agg.value,
      ]
        .filter(Boolean)
        .join(' ')

      badges.push(<Tag className={getColorClass(badge.color)}>{content}</Tag>)
    })

    return badges
  }, [block, tableProps, links])

  const handleToggleExpand = useCallback(
    (rowId: string) => {
      if (rowId in childrenPayload) {
        return
      }
      const url: string =
        getActionURL('row', 'blockData', row._id) +
        getQuery({
          rowId,
          viewId: view._id,
          block: block._id,
        })

      __get(url).then(result => {
        setChildrenPayload(payload => {
          return {
            ...payload,
            [rowId]: result,
          }
        })
      })
    },
    [block, table, view, childrenPayload],
  )

  if (!tableProps) {
    return null
  }

  return (
    <div className={'details-block'}>
      {block.title || badges ? (
        <h3>
          {block.title}
          {badges}
        </h3>
      ) : null}

      <Table
        {...tableProps}
        empty={<NoData />}
        height={block.params.tableChildren ? void 0 : 320}
        theme={[TableTheme.MAIN]}
        size={'l'}
        onToggleExpand={handleToggleExpand}>
        {ChildrenComponent}
      </Table>
    </div>
  )
}

export default DetailsTableBlock
