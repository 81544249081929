import React from "react";
import {connect} from 'react-redux'
import {callAction} from "../../../store/CommonEffects";
import {bindActionCreators} from "redux";
import {Resource} from "../../../components/calendar/types/Resource";
import {DateLike, Event} from "../../../components/calendar/types/Event";
import {ViewProps} from "../types/ViewProps";
import Timeline from "../../../components/calendar/timeline/Timeline";
import {TimelineColumn} from "../../../components/calendar/types/Timeline";
import {ViewGroups, ViewTimeline} from "../../../types/BaseView";
import {GridColumn} from "../../../components/grid/types/GridColumn";
import {getById} from "../../../general/utils/column";
import {applyFormula, getValue, getViewValue} from "../../../general/utils/value";
import {dayjs} from "../../../general/utils/date";
import {isMob} from "../../../utils/is";
import {isArray} from "../../../general/utils/is";
import {exportComputedDates} from "../../../utils/dates";
import {getStoreUsers} from "../../../utils/get";
import {BaseTable} from "../../../types/BaseTable";
import {BaseRendererFactory} from "../../../components/__renderers/RendererFactory";
import '../styles/timeline.scss'

interface State {
  resources: Resource[]
  events: Event[]
  columns: TimelineColumn[]
  split?: boolean
  group?: string
  startDate: DateLike
  endDate: DateLike
  isConfigured: boolean
}

class TimelineView extends React.PureComponent<ViewProps, State> {

  /*
  constructor(props: ViewProps) {
    super(props)

    const events: any[] = []
    const resources: Resource[] = []
    const rows: GridRow[] = store.getState().rows
    const {view, table} = props
    const filteredRows: GridRow[] = view.filters ? applyFilter<GridRow>(rows, view.filters, table) : rows
    const resourceColumn = getColumnById(table, view.timeline!.resource)!
    const eventsColumn = getColumnById(table, view.timeline!.dates[0]!.start)!

    if (!resourceColumn) {
      this.state = {
        events,
        resources: [],
        resourceColumn,
        mainDate: new Date()
      }
      return
    }

    filteredRows.forEach(row => {
      const value = getRowValue(row, eventsColumn)

      if (value) {
        if (isArray(value)) {
          value.forEach(item => {
            events.push({
              resourceId: row._id,
              start: new Date(item.value),
              title: applyFormula("INDEX_OF({cjVPtyp9hwEsGtM7}) & \"/\" & {cnFNhrW4FzuA5btf}" || view.timeline!.template, {
                table: table,
                row,
                column: resourceColumn,
                args: item
              })
            })
          })
        } else {
          events.push({
            resourceId: row._id,
            start: new Date(value),
            title: applyFormula(view.timeline!.template, {
              table: table,
              row,
              column: resourceColumn
            })
          })
        }
      }

      resources.push({
        id: row._id,
        title: getStringValue(row, resourceColumn)
      })
    })

    this.state = {
      events,
      resources,
      resourceColumn,
      mainDate: new Date()
    }
  }
  */
  static getDerivedStateFromProps(props: ViewProps, state: State) {
    const timeline: ViewTimeline = props.view.timeline

    if (!timeline) {
      return {
        isConfigured: false
      }
    }

    const {table, rows} = props
    const resourceColumn: GridColumn | undefined = getById(table, timeline.resource)

    if (!resourceColumn) {
      return {
        isConfigured: false
      }
    }

    const resources = []
    const events = []

    const activeGroup: ViewGroups | undefined = props.view.groups && props.view.groups[0]

    rows.forEach(row => {
      const group = activeGroup && getViewValue(
        table,
        getById(table, activeGroup.columnId), row, {
          users: getStoreUsers(true),
          ignoreListOrder: activeGroup.ignoreListOrder
        }
      )

      const resource = {
        id: row._id,
        title: getViewValue(table, resourceColumn, row),
        data: row,
        group
      }

      resources.push(resource)

      timeline.dates.forEach((date, i) => {
        const start = row[date.start]
        // const end = row[date.end]

        if (isArray(start)) {
          return exportComputedDates(start).forEach((item, j) => {
            events.push({
              id: `${row._id}_${i}_${j}`,
              resourceId: row._id,
              title: applyFormula("INDEX_OF({cjVPtyp9hwEsGtM7}) & \"/\" & {cnFNhrW4FzuA5btf}" || timeline.template, {
                table,
                row,
                column: resourceColumn,
                args: {
                  indexOfItem: item
                }
              }),
              start: item,
            })
          })
        }

        // events.push({
        //   id: `${row._id}_${i}`,
        //   resourceId: row._id,
        //   title: applyFormula("INDEX_OF({cjVPtyp9hwEsGtM7}) & \"/\" & {cnFNhrW4FzuA5btf}" || timeline.template, {
        //     table,
        //     row,
        //     column: resourceColumn,
        //     args: {
        //       indexOfItem: item
        //     }
        //   }),
        //   start,
        //   end
        // })
      })
    })

    const columns = [
      {
        title: resourceColumn.name,
        renderer: (props) => props.title,
        width: 290
      }
    ]

    return {
      events,
      resources,
      startDate: dayjs().startOf('month'),
      endDate: dayjs().endOf('month'),
      columns,
      group: activeGroup ? 'group' : void 0,
      split: false === isMob(),
      groupRenderer: activeGroup
        ? TimelineView.createGroupRenderer(props.table, getById(props.table, activeGroup.columnId))
        : undefined
    }
  }

  static createGroupRenderer(table: BaseTable, column: GridColumn) {
    return (props) => {
      const r = props.group.resources[0]
      const value = r
        ? getValue(table, column, r.resource.data)
        : undefined

      return (
        <BaseRendererFactory
          onChange={_ => {}}
          params={column.params}
          columnType={column.type}
          value={value}
          readonly={true}
        />
      )
    }
  }

  render() {
    return (
      <Timeline
        hideEmptyResources={true}
        {...this.state}
      />
    )
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  update: callAction
}, dispatch)

export default connect(null, mapDispatchToProps)(TimelineView)
