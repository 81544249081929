import {LoadingAction} from "./LoadingAction";

const initState: boolean = false

export default function loading(state = initState, action) {
  switch(action.type) {
    case LoadingAction.START: return true
    case LoadingAction.FINISH: return false
    default:
      return state
  }
}