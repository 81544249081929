const getColorsList = (prefix) => {
  return Array.from(Array(11)).map((_, i) => ({
    v: prefix + i,
    c: `color-${prefix}${i}`
  }))
}

const colorPalette = [...getColorsList('b'), ...getColorsList('c'), ...getColorsList('d')]

const isValidColor = (color) => {
  return colorPalette.some(c => c.v === color)
}

export {getColorsList}
export {colorPalette}
export {isValidColor}
