import React, { useMemo } from 'react'
import { BaseRendererProps } from '../types/BaseRenderer'
import { RendererClassNames } from '../definitions'
import { BaseRendererFactory } from '../factory'
import { asArray } from '../../../general/utils/array'

const LookupRenderer: React.FC<BaseRendererProps> = ({
  value,
  column,
  table,
  payload,
}) => {
  const renderColumn = useMemo(() => {
    return {
      ...column,
      type: column.params.foreignType,
      params: {
        ...column.params.foreignParams,
      },
    }
  }, [column])

  return (
    <div className={RendererClassNames.LOOKUP}>
      {asArray(value)
        .filter(v => !!v.value)
        .map(({ _id, value }) => (
          <BaseRendererFactory
            key={_id}
            payload={payload}
            column={renderColumn}
            value={value}
            table={table}
          />
        ))}
    </div>
  )
}

export default React.memo(LookupRenderer)
