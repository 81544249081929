import React from 'react'
import { Tabs } from '../../../components/irv-ui'
import Loading from '../../../components/loading/Loading'
import { connect } from 'react-redux'
import { WorkspaceRouterProps } from '../types/WorkspaceRouterProps'
import { Base } from '../../../types/Base'
import { EyzyUser } from '../../../types/EyzyUser'
import { Q } from '../types/Q'
import {
  disturbEntity,
  DisturbEntityArguments,
  loadEntity,
  LoadEntityArguments,
} from '../../../store/CommonEffects'
import { buildLink, history, Pages } from '../../../utils/navigation'
import { TableActions } from '../../../store/actions/TableActions'
import { BaseTable } from '../../../types/BaseTable'
import { BaseView } from '../../../types/BaseView'
import TableContainer from './TableContainer'
import BaseLink from '../components/helpers/BaseLink'
import { cn } from '../../../utils/classname'
import { isMob, isTablet } from '../../../utils/is'
import Icon from '../../../components/icon/Icon'
import RowDetails from '../components/rowDetails/RowDetails'
import { Roles } from '../../../components/roles/Roles'
import {
  getUserEntityRole,
  isWSActionGranted,
  UIRoleAction,
} from '../../../components/roles/RoleGateway'
import { Workspace } from '../../../types/Workspace'
import ViewList from '../../../components/workspace/view/viewList/ViewList'
import { ViewType } from '../../../components/grid/types/ViewType'
import Sb from '../components/sidebar/Sb'
import { bindActionCreators } from 'redux'
import { AppActions } from '../../../store/actions/AppActions'
import ViewName from '../../../components/workspace/view/ViewName'
import AddViewModal from '../components/view/AddViewModal'
import BtnWithIcon from '../../../components/btn/BtnWithIcon'
import { setLastUsage } from '../../../utils/storage'
import ViewUsers from '../components/view/ViewUsers'

import '../styles/workspace.scss'
import '../styles/helpers.scss'

class WorkspaceContainer extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      loading: !props.user,
      isSBCollapsed: isMob(),
    }
  }

  componentDidMount() {
    this.loadData()

    if (isMob(true)) {
      document.addEventListener('click', this.onContentClick)
    }
  }

  componentWillUnmount() {
    if (isMob(true)) {
      document.removeEventListener('click', this.onContentClick)
    }

    this.props.clearRows()
  }

  componentDidUpdate(prevProps: Props) {
    const prevMatch = prevProps.match.params
    const currentMatch = this.props.match.params

    if (
      prevMatch.tableId !== currentMatch.tableId ||
      prevMatch.viewId !== currentMatch.viewId
    ) {
      const state = {
        loading: true,
        isSBCollapsed: isMob(true) ? true : this.state.isSBCollapsed,
      }

      setLastUsage(
        this.props.activeBase,
        currentMatch.tableId,
        currentMatch.viewId,
      )

      this.setState(state)
      this.loadData()
    }
  }

  initApp = () => {
    const { match, loadEntity } = this.props
    const { tableId, viewId } = match.params
    const queryString = {
      viewId,
      tableId,
    }

    if (!queryString.viewId) {
      delete queryString.viewId
    }

    loadEntity({ entity: AppActions.INIT, q: [Q.USER], queryString }).then(
      ({ tables }) => {
        if (!queryString.viewId) {
          // Зашли на страницу БЕЗ viewId
          const table = tables.find(t => t._id === tableId) || tables[0]
          const view = table.views[0]

          history.replace(
            buildLink(Pages.MAIN_WORKSPACE, {
              tableId: table._id,
              viewId: view._id,
            }),
          )
        }

        this.setState({
          loading: false,
        })
      },
    )
  }

  openAddView = () => {
    this.setState({ addViewModal: true })
  }

  closeAddView = () => {
    this.setState({ addViewModal: false })
  }

  onContentClick = e => {
    if (!e.target || this.state.isSBCollapsed) {
      return
    }

    // @ts-ignore
    const el = e.target!.closest('.ws-content')

    if (el) {
      this.handleToggleBar()
    }
  }

  getUserRole = (base: Base): Roles => {
    const { user, ws } = this.props
    return getUserEntityRole(user, { base, ws })
  }

  getEntities = (): { base?: Base; table?: BaseTable; view?: BaseView } => {
    const { match, ws } = this.props

    let base: any = {}
    let table
    let view

    const { tableId, viewId } = match.params

    ws.bases.forEach(b => {
      if (b.tables.some(t => t._id === tableId)) {
        base = b
        table = base.tables.find(t => t._id === tableId)
        view = table.views.find(v => v._id === viewId)
      }
    })

    return { base, table, view }
  }

  ensureCorrectTV = () => {
    // const { loadEntity, match } = this.props
    // const { tableId, viewId } = match.params
  }

  getTabLabel = (view: BaseView): React.ReactNode => {
    return <ViewName view={view} />
  }

  loadData = () => {
    const { loadEntity, match, user, history } = this.props
    const { tableId, viewId } = match.params

    // Идет редирект с BaseRouterContainer. Чтобы не читать повторно с базы
    if (history.location.hash === '#ld') {
      history.replace(history.location.pathname)
      return
    }

    const loadArgs: any = {
      entity: TableActions.FETCH,
      id: tableId,
      queryString: {},
    }

    if (viewId) {
      loadArgs.queryString.viewId = viewId
    }

    if (!user) {
      loadArgs.q = [Q.USER]
    }

    return loadEntity(loadArgs).then(({ tables }) => {
      if (tables) {
        const table = tables.find(t => t._id === tableId) || tables[0]
        const view =
          table.views.find(v => v._id === viewId) || table.views[0]._id

        if (table._id !== tableId && view._id !== viewId) {
          const nextUrl =
            buildLink(Pages.MAIN_WORKSPACE, {
              tableId: table._id,
              viewId: view._id,
            }) + '#ld'

          history.push(nextUrl)
        }
      }

      this.setState({
        loading: false,
      })

      if (isMob()) {
        try {
          setTimeout(_ => {
            window.dispatchEvent(new Event('resize'))
          }, 500)
        } catch (e) {}
      }
    })
  }

  handleViewChange = (viewId: string) => {
    const { table } = this.getEntities()
    history.push(`/${table!._id}/${viewId}`)
  }

  handleAddView = (name: string, type: ViewType) => {
    const { match, disturbEntity } = this.props
    const { tableId } = match.params

    disturbEntity({
      entity: TableActions.CREATE_VIEW,
      id: this.props.match.params.tableId,
      payload: {
        name,
        type,
      },
    }).then(result => {
      history.push(
        buildLink(Pages.MAIN_WORKSPACE, {
          tableId,
          viewId: result._id,
        }),
      )

      this.closeAddView()
    })
  }

  handleViewNavigate = (viewId: string) => {
    const { match } = this.props
    const { tableId } = match.params

    history.push(
      buildLink(Pages.MAIN_WORKSPACE, {
        tableId,
        viewId,
      }),
    )
  }

  handleCloseDetails = () => {
    const { tableId, viewId } = this.props.match.params

    history.push(
      buildLink(Pages.MAIN_WORKSPACE, {
        tableId,
        viewId,
      }),
    )
  }

  handleMenuClick = () => {
    setTimeout(_ => this.handleToggleBar())
  }

  handleToggleBar = () => {
    this.setState({ isSBCollapsed: !this.state.isSBCollapsed }, () => {
      try {
        setTimeout(_ => {
          window.dispatchEvent(new Event('resize'))
        }, 350)
      } catch (e) {}
    })
  }

  redirectToView(viewId: string) {
    const to = buildLink(Pages.MAIN_WORKSPACE, {
      tableId: this.props.match.params.tableId,
      viewId,
    })

    alert(to)

    // return <Redirect to={to} />
  }

  renderContent = () => {
    const { loading } = this.state

    if (loading) {
      return (
        <div className={'ws-content'}>
          <Loading />
        </div>
      )
    }

    const { user, match, ws, activeBase } = this.props
    const { tableId, viewId, rowId } = match.params

    const base = ws.bases.find(b => b._id === activeBase)
    const table: BaseTable | undefined = base.tables.find(
      t => t._id === tableId,
    )

    if (!table) {
      return null
    }

    const view: BaseView | undefined = table.views.find(
      (v: BaseView) => v._id === viewId,
    )

    if (!view) {
      return this.redirectToView(table.views[0]._id)
    }

    const userRole: Roles = this.getUserRole(base)

    return (
      <>
        <div className="ws-table">
          <TableContainer
            base={base}
            table={table}
            view={view}
            ws={ws}
            user={user}
            userRole={userRole}
          />
        </div>

        <RowDetails
          rowId={rowId}
          view={view}
          table={table}
          userRole={userRole}
          ws={ws}
          onClose={this.handleCloseDetails}
        />
      </>
    )
  }

  renderHeader = (base?: Base, table?: BaseTable, view?: BaseView) => {
    if (!base || !table) {
      return null
    }

    const { user, ws } = this.props

    return (
      <div className={'ws-header'}>
        <div className="ws-header-nav">
          {isTablet() && (
            <button className={'menu bi'} onClick={this.handleMenuClick}>
              <Icon type={'menu'} />
            </button>
          )}

          <Tabs
            noContent
            key={table._id}
            activeKey={view._id}
            onChange={this.handleViewChange}>
            {table.views.map(v => (
              <Tabs.Tab label={this.getTabLabel(v)} key={v._id} />
            ))}
          </Tabs>

          {isWSActionGranted(UIRoleAction.VIEW_CREATE, user, ws) && (
            <BtnWithIcon
              icon={'plus'}
              min
              cn={'add-view'}
              onClick={this.openAddView}
            />
          )}
        </div>

        {!isMob() && (
          <ViewUsers
            ws={ws}
            base={base}
            table={table}
            view={view}
            user={user}
          />
        )}
      </div>
    )
  }

  renderHeader__OLD = (base?: Base, table?: BaseTable, view?: BaseView) => {
    if (!base || !table) {
      return null
    }

    return (
      <div className={'ws-header'}>
        <div className="ws-header-nav">
          <button className={'menu bi'} onClick={this.handleMenuClick}>
            <Icon type={'menu'} />
          </button>

          <BaseLink base={base} noName />

          <div className={'table-link tc'}>{table.name}</div>
          <span className={'dl'}>/</span>

          <ViewList
            views={table.views}
            activeView={view}
            userRole={this.getUserRole(base)}
            onAddView={_ => {}}
            onViewChange={this.handleViewNavigate}
          />
        </div>
      </div>
    )
  }

  render() {
    if (!this.props.ws) {
      return <Loading />
    }

    const { isSBCollapsed, addViewModal } = this.state
    const wsClassName: string = cn('ws', isSBCollapsed && 'sb--collapsed')
    const { base, table, view } = this.getEntities()

    return (
      <div className={wsClassName}>
        <Sb
          ws={this.props.ws}
          activeTable={table}
          collapsed={isSBCollapsed}
          onToggleBar={this.handleToggleBar}
        />
        <div className={'ws-content'}>
          <>
            {this.renderHeader(base, table, view)}
            {this.renderContent()}
          </>
        </div>

        <AddViewModal
          key={view && view._id}
          visible={addViewModal}
          onCancel={this.closeAddView}
          onAdd={this.handleAddView}
        />
      </div>
    )
  }
}

type ReduxStateProps = {
  tables: BaseTable[]
  user: EyzyUser
  workspaces: Workspace[]
  userMap: { [id: string]: EyzyUser }
  wss: Workspace[]
  ws: Workspace
  activeBase?: string
}

type ReduxDispatchProps = {
  loadEntity: (args: LoadEntityArguments) => Promise<any>
  disturbEntity: (args: DisturbEntityArguments) => Promise<any>
  clearRows: () => void
}

type ReduxProps = ReduxStateProps & ReduxDispatchProps
type RouterProps = WorkspaceRouterProps & { match: any; history: any }
type Props = ReduxProps & RouterProps

interface State {
  loading: boolean
  isSBCollapsed: boolean
  addViewModal?: boolean
}

const mapStateToProps = (state: any): ReduxStateProps => ({
  tables: state.tables,
  user: state.user,
  workspaces: state.workspaces || [],
  wss: state.wss,
  ws: state.wss.find(w => w._id === state.active.ws),
  activeBase: state.active.base,
  userMap: state.userMap,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators<any, ReduxDispatchProps>(
    {
      loadEntity,
      disturbEntity,
      clearRows: () => dispatch => dispatch({ type: AppActions.CLEAR_ROWS }),
    },
    dispatch,
  )

export default connect<ReduxStateProps, ReduxDispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(WorkspaceContainer)
