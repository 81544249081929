import { GridColumn } from '../components/grid/types/GridColumn'
import { GridRow } from '../components/grid/types/GridRow'
import { AggregationType } from '../types/BaseView'
import { ColumnType } from '../general/types/ColumnType'
import { ContextMenuItem } from '../components/dropdown/DropdownMenu'
import { getValue } from '../general/utils/value'
import { BaseTable } from '../types/BaseTable'

interface AggGroupValue {
  label?: string
  value: any
}

const AggregationLabels = {
  [AggregationType.EMPTY]: 'Не заполненые',
  [AggregationType.FILLED]: 'Заполненые',
  [AggregationType.UNIQUE]: 'Уникальные',
  [AggregationType.PERCENT_EMPTY]: 'Не заполненые',
  [AggregationType.PERCENT_FILLED]: 'Заполненые',
  [AggregationType.PERCENT_UNIQUE]: 'Уникальные',
  [AggregationType.SUM]: 'Сумма',
  [AggregationType.COUNT]: 'Кол-во',
}

// Это для меню
const AggMenuLabels = {
  ...AggregationLabels,
  [AggregationType.PERCENT_EMPTY]: 'Процент не заполненых',
  [AggregationType.PERCENT_FILLED]: 'Процент заполненых',
  [AggregationType.PERCENT_UNIQUE]: 'Процент уникальных',
}

const baseChoices = [
  AggregationType.EMPTY,
  AggregationType.FILLED,
  AggregationType.UNIQUE,
  AggregationType.PERCENT_EMPTY,
  AggregationType.PERCENT_FILLED,
  AggregationType.PERCENT_UNIQUE,
]

// Да, по ссылке нах
const columnAggChoices = {
  [ColumnType.FORMULA]: baseChoices.concat(AggregationType.SUM),
  [ColumnType.NUMBER]: baseChoices.concat(AggregationType.SUM),
  [ColumnType.LONG_TEXT]: baseChoices,
  [ColumnType.EMAIL]: baseChoices,
}

export const buildAggMenu = (
  column: GridColumn,
  addCount?: boolean,
): ContextMenuItem[] | null => {
  const base = [
    {
      key: 'none',
      text: 'Без подсчета',
    },
  ]

  if (addCount) {
    base.push({
      key: AggregationType.COUNT,
      text: AggregationLabels[AggregationType.COUNT],
    })
  }

  const columnChoices = columnAggChoices[column.type] || baseChoices

  return base.concat(
    columnChoices.map(c => ({
      key: c,
      text: AggMenuLabels[c],
    })),
  )
}

const renderPerValue = (value: number): any => {
  return value % 1 ? value.toFixed(2) : value
}

export function aggregateGroupValue(
  table: BaseTable,
  column: GridColumn,
  rows: GridRow[],
  type: AggregationType,
): AggGroupValue {
  const values = rows.map(row => getValue(table, column, row))
  let value

  if (type === AggregationType.NONE) {
    return null
  }

  switch (type) {
    case AggregationType.COUNT:
      value = values.length
      break

    case AggregationType.EMPTY: {
      value = values.reduce((acc: number, rowValue: any) => {
        return !rowValue ? acc + 1 : acc
      }, 0)

      break
    }

    case AggregationType.PERCENT_EMPTY:
      const empty = values.filter(v => (v === 0 ? false : !v)).length
      const vEmpty = 100 * (empty / values.length)

      value = `${renderPerValue(vEmpty)}%`
      break

    case AggregationType.FILLED:
      value = values.reduce((acc: number, rowValue: any) => {
        return rowValue ? acc + 1 : acc
      }, 0)

      break

    case AggregationType.PERCENT_FILLED: {
      const filledAmount = values.reduce((acc: number, rowValue: any) => {
        return rowValue ? acc + 1 : acc
      }, 0)

      value = `${Math.floor((filledAmount / values.length) * 100)}%`

      break
    }

    case AggregationType.SUM:
      value =
        values
          // У формул случается хуйня...
          .filter(v => !!v && v !== '#VALUE!')
          .reduce((acc: number, rowValue: any) => {
            const val = parseInt(rowValue)

            if (isFinite(val)) {
              return acc + val
            }

            return val
          }, 0) + ''

      break

    case AggregationType.UNIQUE:
      value = [...new Set(values.filter(v => v !== undefined))].length
      break

    case AggregationType.PERCENT_UNIQUE:
      const uniq = [...new Set(values)].length
      const vUniq = 100 * (uniq / values.length)

      value = `${renderPerValue(vUniq)}%`
      break
  }

  return {
    label: AggregationLabels[type],
    value,
  }
}
