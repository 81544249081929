import React, {useRef} from 'react'
import {Button, Switch} from '../../components/irv-ui'
import {DynamicFilterItem, FilterItemMode, FilterOp, FilterPayload} from "./types/Filter";
import {BaseTable} from "../../types/BaseTable";
import ColumnSelector from "../columnHelpers/columnSelector/ColumnSelector";
import {GridColumn} from "../grid/types/GridColumn";
import {getColumnById} from "../../utils/get";
import {getFilterOperators} from "../../general/types/OperationsMap";
import Select from 'antd/lib/select';
import {translateFilterCtrl} from '../../utils/translations';
import Icon from '../icon/Icon';
import {FilterRendererFactory} from './FilterRendererFactory';
import {BasicOperators} from "../../types/BasicOperators";
import {FilterOperators} from '../../general/types/FilterOperators';
import {DropdownMenu} from "../dropdown/DropdownMenu";
import {TableActions} from "../../store/actions/TableActions";
import {ViewActions} from "../../store/actions/ViewActions";

const isEmptiness = (o) => emptyOperators.includes(o)

const showDots = (o) => {
  return o.length > 2 && !isEmptiness(o)
}

const emptyOperators: string[] = [
  FilterOperators.IS_EMPTY, FilterOperators.IS_NOT_EMPTY
]

const menuItems = [{
  key: ViewActions.GROUP_COLUMN,
  text: 'Группировать',
}, {
  key: ViewActions.DUPLICATE,
  text: 'Дублировать',
}, {
  key: TableActions.REMOVE_VIEW,
  text: 'Удалить',
}]

interface Props {
  i: number
  filter: DynamicFilterItem
  table: BaseTable
  op?: FilterOp
  payload: FilterPayload
  onChange: (filterId: string | undefined, operator: BasicOperators, payload?: any) => void
  onOpChange: (op: FilterOp) => void
}

export default React.memo(function FilterItem(props: Props) {
  const parentNode = useRef(null)
  const {table, filter, payload, onChange, i, op} = props
  const column: GridColumn | undefined = getColumnById(table, filter.col)

  if (!column) {
    return null
  }

  // @ts-ignore
  const selectedValue: string = filter['op']
  const operators: string[] = getFilterOperators(column)

  const onFilterChange = (payload: any) => {
    onChange(filter._id, BasicOperators.SET, payload)
  }

  const handleOpChange = (op) => {
    const payload = {
      op
    }

    if (isEmptiness(op)) {
      payload['value'] = null
    }

    onChange(filter._id, BasicOperators.SET, payload)
  }

  const handleMenuClick = ({key}) => {
    switch (key) {
      case TableActions.REMOVE_VIEW:
        return onChange(filter._id, BasicOperators.REMOVE)
    }
  }

  const handleCellValueChange = (state: boolean) => {
    onChange(filter._id, BasicOperators.SET, {
      value: null,
      mode: state ? FilterItemMode.CELL_VALUE : null
    })
  }

  const handleCellValueColChange = (value: string) => {
    onChange(filter._id, BasicOperators.SET, {
      value,
    })
  }

  const handleChangeColumn = (columnId: string) => {
    const column: GridColumn | undefined = getColumnById(table, columnId)

    if (column) {
      onChange(filter._id, BasicOperators.SET, {
        op: getFilterOperators(column)[0],
        col: columnId,
        value: null
      })
    }
  }

  const actionsMenu = !isEmptiness(selectedValue)
    ? [
      <Switch size={'sm'} onChange={handleCellValueChange} checked={filter.mode === FilterItemMode.CELL_VALUE}>
        Указать ячейку
      </Switch>
    ]
    : null

  let fpValue = null

  if (filter.mode === FilterItemMode.CELL_VALUE) {
    fpValue = (
      <ColumnSelector
        table={table}
        onChange={handleCellValueColChange}
        selectedItem={filter.value}
      />
    )
  } else if (!isEmptiness(selectedValue)) {
    fpValue = (
      <FilterRendererFactory
        onChange={onFilterChange}
        filter={filter}
        column={column}
        payload={payload}
      />
    )
  }

  return (
    <div className='fp-item' ref={parentNode}>
      <div className="fp-ctrl">
        <div className={'fp-dop'}>
          {i === 0 ? 'Где' : (
            i === 1 ? (
              // @ts-ignore
              <Select value={op || 'and'} getPopupContainer={trigger => trigger.parentNode} onChange={props.onOpChange}>
                <Select.Option value={'and'}>И</Select.Option>
                <Select.Option value={'or'}>ИЛИ</Select.Option>
              </Select>
            ) : op === 'or' ? 'или' : 'и'
          )}
        </div>
        <div className='fp-col'>
          <ColumnSelector
            table={table}
            onChange={handleChangeColumn}
            selectedItem={filter.col}
          />
        </div>
        <div className="fp-op">
          {operators && (
            <Select
              value={selectedValue}
              getPopupContainer={trigger => trigger.parentNode}
              onChange={handleOpChange}
            >
              {operators.map(o => (
                <Select.Option value={o} key={o}>
                  {translateFilterCtrl(o)}
                  {showDots(o) && '...'}
                </Select.Option>
              ))}
            </Select>
          )}
        </div>
        {fpValue && (
          <div className='fp-value'>
            {fpValue}
          </div>
        )}
      </div>
      <div className="fp-a">
        <DropdownMenu onClick={handleMenuClick} items={menuItems} actionItems={actionsMenu}
                      getPopupContainer={() => parentNode.current}>
          <Button>
            <Icon type={'dots-vertical'}/>
          </Button>
        </DropdownMenu>
      </div>
    </div>
  )
})
