import React, { forwardRef } from 'react'
import DatePicker from 'react-datepicker'
import { FormRendererProps } from '../types/FormRenderer'
import { dayjs, format } from '../../../general/utils/date'
import { isMob } from '../../../utils/is'
import { Button } from '../../eyzy'
import NEWIcon from '../../icon/NEWIcon'

console.log('Custom input for onBlur')
const FormDateRenderer: React.FC<FormRendererProps> = ({
  value,
  readonly,
  className,
  params = {},
  onChange,
  onCommit,
}) => {
  const showTime: boolean = !!params.timeFormat
  const datePickerProps: any = {
    className: 'e-input',
    selected: value ? dayjs(value).toDate() : null,
  }

  if (showTime) {
    datePickerProps.showTimeSelect = true
    datePickerProps.timeFormat = 'p'
    datePickerProps.timeIntervals = 15
  }

  if (isMob()) {
    const MobileCustomInput = forwardRef(
      ({ onClick, ...rest }: any, ref: any) => {
        return (
          <Button onClick={onClick} ref={ref}>
            <NEWIcon type={'d-calendar'} />
            {format(value, params.format, params.timeFormat, true)}
          </Button>
        )
      },
    )

    datePickerProps.customInput = <MobileCustomInput />
  } else {
    let dFormat = 'dd.MM.yyyy'

    if (showTime) {
      dFormat += `, ${params.timeFormat}`
    }

    datePickerProps.placeholder = dFormat
    datePickerProps.dateFormat = dFormat
  }

  const onDateSelect = function onDateSelect(date: Date) {
    if (!date) {
      return onChange(null)
    }

    const value = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
    )

    if (params.timeFormat) {
      value.setHours(date.getHours())
      value.setMinutes(date.getMinutes())
    }

    onChange(value)

    if (onCommit) {
      onCommit(value)
    }
  }

  if (readonly) {
    if (value) {
      return (
        <div className={className}>
          {format(value, params.format, params.timeFormat, true)}
        </div>
      )
    }

    return null
  }

  return (
    <div className={className}>
      <DatePicker
        {...datePickerProps}
        showPopperArrow={false}
        locale="ru"
        width={300}
        onChange={onDateSelect}
      />
      <NEWIcon type={'d-calendar'} />
    </div>

  )
}

export default FormDateRenderer
