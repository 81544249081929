import {UserRoles} from '../types/UserRoles'
import {userEntityRole} from './roles'

const isRoleAllowed = () => {
  return false
}

const hasAccess = (view, { user, base, workspace }) => {
  const role = userEntityRole(user, {ws: workspace, base})

  if (role < UserRoles.READ_ONLY) {
    return false
  }

  if (role === UserRoles.OWNER) {
    return true
  }

  if (view.visibility) {
    if ('limited' === view.visibility.type) {
      const { role, users } = view.visibility

      if (role) {
        return isRoleAllowed()
      }

      if (users) {
        return (users || []).includes(user._id)
      }
    }
  }

  return true
}

const hasViewAccess = (view, user) => {
  if (!view || !user) {
    return false
  }

  if (view.visibility) {
    if ('limited' === view.visibility.type) {
      const { role, users } = view.visibility

      if (role) {
        return isRoleAllowed()
      }

      if (users) {
        return (users || []).includes(user._id)
      }
    }
  }

  return true
}

export {hasAccess}
export {hasViewAccess}
