import React, { useCallback } from "react"
import { IorvoGridMask } from '../../../../components/iorvo-grid/types/IorvoGridProps'
import { BaseTable } from '../../../../types/BaseTable'
import { GridColumn } from '../../../../components/grid/types/GridColumn'
import ColumnNameWithIcon from '../../../../components/columnHelpers/ColumnNameWithIcon'
import {
  isRoleAllowed,
  UIRoleAction,
} from '../../../../components/roles/RoleGateway'
import { BaseDelayedRendererFactory } from '../../../../components/__renderers/RendererFactory'
import { GridRow } from '../../../../components/grid/types/GridRow'
import { Roles } from '../../../../components/roles/Roles'
import { getValue } from '../../../../general/utils/value'
import { openDetailsPage } from '../../../../utils/navigation'
import Icon from '../../../../components/icon/Icon'

import './styles.scss'

interface Props {
  mask?: IorvoGridMask
  table: BaseTable
  columns: GridColumn[]
  row?: GridRow
  userRole: Roles
  onChange: (rowId: string, column: GridColumn, value: any) => void
  closeSelf: () => void
}

const CellDetails: React.FC<Props> = ({ mask, table, columns, row, userRole, closeSelf, onChange }) => {
  const handleExpand = useCallback(() => {
    openDetailsPage(row._id)
    closeSelf()
  }, [row])

  if (!mask || !row) {
    return null
  }

  const column: GridColumn = columns[mask.c - 1]

  let value

  if (column) {
    value = getValue(table, column, row)
  }

  const handleChange = (val: any) => {
    onChange(row._id, column, val)
  }

  return (
    <div className={'cell-details irv-ow'}>
      <div className="cell-details-value">
        <ColumnNameWithIcon column={column} />

        <div>
          <BaseDelayedRendererFactory
            key={row._id + column._id}
            value={value}
            onChange={handleChange}
            columnType={column.type}
            params={column.params}
            options={column.params.options || []}
            readonly={!isRoleAllowed(UIRoleAction.TABLE_EDIT_ROW, userRole)}
          />
        </div>
      </div>
      <div className="cell-details-ctrl">
        <button className={'bo'} onClick={handleExpand}>
          <Icon type={'arrow-expand'} />
        </button>
      </div>
    </div>
  )
}

export default React.memo(CellDetails)
