import React from 'react'
import {isEmail} from "../../../utils/is";
import {Input} from '../../../components/irv-ui'
import {BaseRendererProps} from "../BaseRendererProps";

export default React.memo(function BaseEmailRenderer(props: BaseRendererProps<string>) {
  const {readonly, value, onChange} = props

  if (readonly) {
    if (!value) {
      return null
    }

    if (!isEmail(value)) {
      return (
        <div className="re-value">
          {value}
        </div>
      )
    }

    return (
      <div className="re-value">
        <a className="email" href={`mailto:${value}`} onClick={e => e.stopPropagation()}>{value}</a>
      </div>
    )
  }

  return (
    <Input
      fit
      className="re-editor"
      onChange={onChange}
      value={value}
    />
  )
})
