import {
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { PlacementAxis } from './usePopover'
import { AriaPositionProps, Placement } from './types/Overlay'
import { calculatePosition, PositionResult } from '../utils/calculatePosition'

export interface PositionAria<T = any> {
  /** Props for the overlay container element. */
  overlayProps: any
  /** Placement of the overlay with respect to the overlay trigger. */
  placement: PlacementAxis

  /** Updates the position of the overlay. */
  updatePosition(): void
}

export function useOverlayPosition(props: AriaPositionProps): PositionAria {
  const {
    targetRef,
    overlayRef,
    scrollRef = overlayRef,
    placement = 'bottom' as Placement,
    containerPadding = 10,
    shouldFlip = true,
    boundaryElement = typeof document !== 'undefined' ? document.body : null,
    offset = 5,
    crossOffset = 0,
    shouldUpdatePosition = true,
    open,
    onClose,
    maxHeight,
  } = props

  const [position, setPosition] = useState<PositionResult>({
    position: {},
    arrowOffsetLeft: undefined,
    arrowOffsetTop: undefined,
    maxHeight: undefined,
    placement: undefined,
  })

  const updatePositionDeps = [
    shouldUpdatePosition,
    placement,
    overlayRef.current,
    targetRef.current,
    scrollRef.current,
    containerPadding,
    shouldFlip,
    boundaryElement,
    offset,
    crossOffset,
    open,
    maxHeight,
  ]

  const updatePosition = useCallback(() => {
    if (
      shouldUpdatePosition === false ||
      open ||
      !overlayRef.current ||
      !targetRef.current ||
      !scrollRef.current ||
      !boundaryElement
    ) {
      return
    }

    setPosition(
      calculatePosition({
        placement,
        overlayNode: overlayRef.current,
        targetNode: targetRef.current,
        scrollNode: scrollRef.current,
        padding: containerPadding,
        shouldFlip,
        boundaryElement,
        offset,
        crossOffset,
        maxHeight,
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, updatePositionDeps)

  // Update position when anything changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(updatePosition, updatePositionDeps)

  // Update position on window resize
  useResize(updatePosition)

  // Update position when the overlay changes size (might need to flip).
  // useResizeObserver({
  //   ref: overlayRef,
  //   onResize: updatePosition
  // })

  // Reposition the overlay and do not close on scroll while the visual viewport is resizing.
  // This will ensure that overlays adjust their positioning when the iOS virtual keyboard appears.
  let isResizing = useRef(false)

  useLayoutEffect(() => {
    let timeout: ReturnType<typeof setTimeout>
    let onResize = () => {
      isResizing.current = true
      clearTimeout(timeout)

      timeout = setTimeout(() => {
        isResizing.current = false
      }, 500)

      updatePosition()
    }

    window.visualViewport?.addEventListener('resize', onResize)

    return () => {
      window.visualViewport?.removeEventListener('resize', onResize)
    }
  }, [updatePosition])

  const close = useCallback(() => {
    if (!isResizing.current) {
      onClose();
    }
  }, [onClose, isResizing])

  return {
    overlayProps: {
      style: {
        position: 'absolute',
        zIndex: 100000, // should match the z-index in ModalTrigger
        ...position.position,
        maxHeight: position.maxHeight
      }
    },
    placement: position.placement,
    updatePosition
  };
}

function useResize(onResize) {
  useLayoutEffect(() => {
    window.addEventListener('resize', onResize, false)
    return () => {
      window.removeEventListener('resize', onResize, false)
    }
  }, [onResize])
}
