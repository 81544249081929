import React from "react"
import DatePicker from "react-datepicker"
import { Input } from "../../../components/irv-ui"
import { dayjs } from "../../../general/utils/date"
import { Select } from "antd"
import { DateModesMap } from "../../../general/types/DateModesMap"
import { FilterRendererProps } from "../FilterRendererFactory"
import { translateFilterCtrl } from "../../../utils/translations"
import { DateFilterModes } from "../../../general/types/DateFilterModes"

const isSpecifiableValue = (mode: any): boolean => {
  return [
    DateFilterModes.PAST_DAYS_N,
    DateFilterModes.PAST_MONTHS_N,
    DateFilterModes.NEXT_MONTHS_N,
    DateFilterModes.NEXT_DAYS_N,
  ].includes(mode)
}

export default function DateRenderer(props: FilterRendererProps) {
  const { filter, onChange } = props
  const modes = DateModesMap[filter.op]

  if (!modes) {
    return null
  }

  const modesNode = modes.map(mode => (
    <Select.Option value={mode} key={mode}>{translateFilterCtrl(mode)}</Select.Option>
  ))

  // @ts-ignore
  const selectedMode: string = filter["mode"]

  const handleChangeMode = mode => {
    onChange({ mode, value: null })
  }

  const handleDateChange = value => {
    onChange({ value })
  }

  const handleSpecifiableValue = e => {
    const value = parseInt(e.target.value, 10)
    handleDateChange(isFinite(value) ? value : null)
  }

  let exactElement = null

  if (selectedMode === DateFilterModes.EXACT) {
    const datePickerProps: any = {
      className: "eyzy-input fp-c",
      selected: filter.value ? dayjs(filter.value).toDate() : null,
    }

    exactElement = (
      <DatePicker
        {...datePickerProps}
        showPopperArrow={false}
        locale="ru"
        placeholder={"dd.MM.yyyy"}
        onChange={handleDateChange}
        dateFormat={"dd.MM.yyyy"}
      />
    )
  } else if (isSpecifiableValue(selectedMode)) {
    exactElement = (
      <Input
        key={selectedMode}
        defaultValue={filter.value}
        onChange={handleSpecifiableValue}
      />
    )
  }

  return (
    <div className={"fp-dmode"}>
      <Select
        value={selectedMode}
        dropdownMatchSelectWidth={false}
        getPopupContainer={trigger => trigger.parentNode}
        onChange={handleChangeMode}
      >
        {modesNode}
      </Select>

      {exactElement}
    </div>
  )

  // return (
  //   <DatePicker
  //     {...datePickerProps}
  //     showPopperArrow={false}
  //     locale="ru"
  //     placeholder={'dd.MM.yyyy'}
  //     onChange={onDateSelect}
  //     dateFormat={'dd.MM.yyyy'}
  //   />
  // )
}
