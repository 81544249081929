import {BaseTable} from "../../../types/BaseTable";

export function table(state: any, tableId: string, cb: (table: BaseTable) => Partial<BaseTable>) {
  return state.map(ws => {
    const hasTable: boolean = ws.bases.some(base => {
      if (!base.tables) {
        return false
      }

      return base.tables.some(t => t._id === tableId)
    })

    if (!hasTable) {
      return ws
    }

    return {
      ...ws,
      bases: ws.bases.map(base => {
        if (base.tables.some(t => t._id === tableId)) {
          return {
            ...base,
            tables: base.tables.map(t => {
              if (t._id === tableId) {
                return {
                  ...t,
                  ...cb(t)
                }
              }

              return t
            })
          }
        }

        return base
      })
    }
  })
}
