import React from "react";
import {GridRow} from "../grid/types/GridRow";
import {BaseTable} from "../../types/BaseTable";
import LinkedRecordItem from "./LinkedRecordItem";
import {BaseView} from "../../types/BaseView";
import {asArray} from "../../general/utils/array";

interface Props {
  expandable?: boolean
  showCheckbox?: boolean
  selected?: string[]
  view?: BaseView
  rows: GridRow[]
  table: BaseTable
  onSelect?: (row: GridRow) => void
  onRemove?: (rowId: string) => void
}

export default class LinkedRecordList extends React.PureComponent<Props> {
  render() {
    const {rows, table, onRemove, onSelect, view, showCheckbox, expandable} = this.props
    return (
      <div className="linked-records">
        {asArray(rows).map((row: GridRow) => (
          <LinkedRecordItem
            showCheckbox={showCheckbox}
            expandable={expandable}
            key={row._id}
            row={row}
            table={table}
            view={view}
            onClick={onSelect}
            onRemove={onRemove}
            active={this.isActive(row._id)}
          />
        ))}
      </div>
    )
  }

  isActive = (id: string): boolean => {
    return (this.props.selected || []).includes(id)
  }
}