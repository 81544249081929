import React, { useCallback, useEffect, useState } from 'react'
import { cn } from '../../utils/cn'
import TableCheckbox from './renderers/TableCheckbox'
import TableGroupRenderer from './renderers/TableGroup'
import {
  TableColumn,
  TableGroup,
  TableHeader,
  TableResultGroup,
  TableRow,
  UseTableProps,
  UseTableResult,
} from './types/Table'
import Checkbox from '../checkbox/Checkbox'
import TableExpand from './renderers/TableExpand'

const prepareHeaders = (
  columns: TableColumn[],
  pin: number,
  pinRight: number,
  layout: any,
  isGroup: boolean,
): TableHeader[] => {
  let left: number = 0
  let headers = columns.map((column: TableColumn, index: number) => {
    const headerWidth = column.width || 150
    const style: any = {
      [isGroup ? 'flexBasis' : 'width']: headerWidth,
    }

    if (layout !== 'fixed' && !column.spec) {
      delete style.width
      delete style.flexBasis
    }

    const isFreezed: boolean = pin && index < pin

    if (isFreezed) {
      style.left = left
      style.position = 'sticky'
    }

    left += headerWidth

    const className = isFreezed ? (index + 1 === pin ? 'fix-last' : 'fix') : ''

    return {
      width: headerWidth,
      title: column.title || null,
      th: {
        style,
      },
      className: cn('th-cell', className, column.className),
    }
  })

  if (pinRight) {
    for (let i = 1; i <= pinRight; i++) {
      const cell = headers[headers.length - i]

      if (cell) {
        cell.className += ' pin-right'
      }
    }
  }

  return headers
}

const prepareRows = ({
  rows,
  columns,
  pin,
  pinRight,
  rowSelection,
  expandedRows,
  idKey,
}): TableRow[] => {
  return rows.map(row => {
    let left: number = 0

    const cells = columns.map((c: TableColumn, index: number) => {
      const cellStyle: any = {}
      const isFreezed: boolean = pin && index < pin

      if (isFreezed) {
        cellStyle.left = left
        cellStyle.position = 'sticky'
      }

      if (c.align) {
        cellStyle.textAlign = c.align
      }

      left += c.width || 150

      const className = isFreezed
        ? index + 1 === pin
          ? 'fix-last'
          : 'fix'
        : ''

      return {
        render: c.render ? c.render(row) : (row || {})[c._id],
        props: {
          style: cellStyle,
        },
        key: c._id || index,
        className: cn('row-cell', className, c.className),
      }
    })

    if (pinRight) {
      for (let i = 1; i <= pinRight; i++) {
        const cell = cells[cells.length - i]

        if (cell) {
          cell.className = 'pin-right'
        }
      }
    }

    const selected: boolean = (rowSelection || []).includes(row[idKey])
    const expanded: boolean = (expandedRows || []).includes(row[idKey])

    return {
      cells,
      origin: row,
      className: cn({
        row: true,
        expanded,
        selected,
      }),
      key: row[idKey] || row._id,
      state: {
        expanded,
        selected,
      },
    } as TableRow
  })
}

const prepareTable = (props: UseTableProps): UseTableResult => {
  let pin: number = props.pin || 0
  let pinRight: number = props.pinRight || 0
  let rows: TableRow[]
  let groups: TableResultGroup[]

  const columns: TableColumn[] = props.columns.concat()
  const { rowSelection = [], expandedRows = [] } = props.state

  const showCheckbox: boolean = !!props.state.rowSelection
  const showExpand: boolean = !!props.state.expandedRows

  if (showCheckbox) {
    columns.unshift({
      _id: 'cb',
      spec: true,
      width: 56,
      align: 'center',
      className: 'row-checkbox',
      render: row => (
        <TableCheckbox
          id={row[props.idKey]}
          checked={props.state.rowSelection.includes(row[props.idKey])}
          onSelect={props.onSelect}
        />
      ),
    })

    if (pin > 0) {
      pin++
    }
  }

  if (showExpand) {
    columns.unshift({
      _id: 'ex',
      spec: true,
      width: 56,
      align: 'center',
      className: 'row-expand',
      render: row => (
        <TableExpand
          id={row[props.idKey]}
          checked={props.state.expandedRows.includes(row[props.idKey])}
          onExpand={props.onExpand}
        />
      ),
    })

    if (pin > 0) {
      pin++
    }
  }

  if (props.grouping) {
    groups = props.grouping.map((group: TableGroup) => {
      const realRows = group.rows
        .map(index => props.data[index])
        .filter(Boolean)

      const groupRows: TableRow[] = prepareRows({
        rows: realRows,
        pin,
        pinRight,
        columns,
        idKey: props.idKey,
        rowSelection,
        expandedRows,
      })

      const collapsed = (props.state.collapsedGroups || []).includes(group.key)

      return {
        key: group.key,
        rows: groupRows,
        props: {
          onClick: () => props.onCollapse(group.key),
          className: 'irv-group-header ' + (collapsed ? 'collapsed' : ''),
        },
        collapsed,
        render: (
          <TableGroupRenderer
            rows={groupRows}
            group={group}
            groupRenderer={props.groupRenderer}
            state={props.state}
            showCheckbox={showCheckbox}
            onSelect={props.onSelect}
          />
        ),
      }
    })
  } else {
    rows = prepareRows({
      rows: props.data,
      pin,
      pinRight,
      columns,
      rowSelection,
      expandedRows,
      idKey: props.idKey,
    })
  }

  return {
    rows,
    groups,
    headers: prepareHeaders(
      columns,
      pin,
      pinRight,
      props.layout,
      !!props.grouping,
    ),
  }
}

const useTable = (props: UseTableProps): UseTableResult => {
  const [{ rows, headers, groups }, setTableState] = useState(
    prepareTable(props),
  )

  useEffect(() => {
    setTableState(prepareTable(props))
    // eslint-disable-next-line
  }, [
    props.data,
    props.columns,
    props.grouping,
    props.pin,
    props.state,
    props.onExpand,
  ])

  return { rows, headers, groups }
}

export default useTable
