import React, { memo } from 'react'
import Caret from "./Caret"

interface Props {
  checked: boolean
  id: string
  onExpand?: (id: string) => void
}

const TableExpand: React.FC<Props> = ({ id, checked, onExpand }) => {
  const handleClick = (e) => {
    e.stopPropagation()
    onExpand(id)
  }

  return (
    <Caret onClick={handleClick} />
  )
}

export default memo(TableExpand)
