const DateFilterModes = {
  TODAY: 'today',
  TOMORROW: 'tomorrow',
  YESTERDAY: 'yesterday',
  EXACT: 'exact',

  CURRENT_WEEK: 'currentWeek',
  CURRENT_MONTH: 'currentMonth',
  CURRENT_YEAR: 'currentYear',

  PAST_7_DAYS: 'past7Days',
  PAST_30_DAYS: 'past30Days',
  NEXT_7_DAYS: 'next7Days',
  NEXT_30_DAYS: 'next30Days',

  PAST_WEEK: 'pastWeek',
  PAST_MONTH: 'pastMonth',
  PAST_YEAR: 'pastYear',

  NEXT_WEEK: 'nextWeek',
  NEXT_MONTH: 'nextMonth',
  NEXT_YEAR: 'nextYear',

  PAST_DAYS_N: 'pastNumberOfDays',
  PAST_MONTHS_N: 'pastNumberOfMonths',
  NEXT_DAYS_N: 'nextNumberOfDays',
  NEXT_MONTHS_N: 'nextNumberOfMonths',

  MONTH: 'month'
}

export {DateFilterModes}