function asArray(item) {
  if (Array.isArray(item)) {
    return item
  }

  return [item].filter(Boolean)
}

function arrToObject(targetArray, key, removeKey) {
  return (targetArray || []).reduce((map, input) => {
    const targetKey = input[key]

    if (false !== removeKey) {
      delete input[key]
    }

    return {...map, [targetKey]: input}
  }, {});
}

function uniq(arr) {
  return Array.from(new Set(arr))
}

function uniqArrays(...elements) {
  return [...new Set(elements.flatMap(el => el))]
}

export {asArray}
export {arrToObject}
export {uniq}
export {uniqArrays}
