import React, { useCallback, useState } from 'react'
import { asArray } from '../../../general/utils/array'
import BtnWithIcon from '../../btn/BtnWithIcon'
import LinkedEntries from '../../linkedEntries/LinkedEntries'
import { FormRendererProps } from '../types/FormRenderer'
import { useFormPayload } from '../RendererContext'

const FormLinkToRecordRenderer: React.FC<FormRendererProps> = ({
  value,
  readonly,
  className,
  column,
  params,
  onCommit,
  onChange,
}) => {
  const { multiple } = params
  const [modalVisible, setModalVisible] = useState(false)
  const { recordAction, links } = useFormPayload()
  const selectedRows = asArray(value || [])
  const hasValue: boolean = selectedRows.length > 0

  const handleChange = useCallback(
    (ids, payload) => {
      onChange(ids, payload)

      if (onCommit) {
        onCommit(ids, payload)
      }
    },
    [onCommit, onChange],
  )

  const dataAction = {
    ...recordAction,
    queryString: {
      ...recordAction.queryString,
      columnId: column._id,
    },
  }

  const isAddBtnVisible: boolean = readonly
    ? false
    : multiple
    ? true
    : !hasValue

  const handleVisibilityChange = (visible: boolean) => setModalVisible(visible)

  const Component = isAddBtnVisible ? (
    <BtnWithIcon
      cn={'linked-entries-btn'}
      icon={'b-add-circle'}
      focusRing
      onClick={() => setModalVisible(true)}>
      Вибрати запис
    </BtnWithIcon>
  ) : null

  const Content = (
    <LinkedEntries
      dataAction={dataAction}
      data={links[column._id]}
      selectorVisible={modalVisible}
      readonly={readonly}
      value={selectedRows}
      entriesParams={params}
      onVisibilityChange={handleVisibilityChange}
      onChange={handleChange}
    />
  )

  return (
    <div className={className}>
      {Component}
      {Content}
    </div>
  )
}

export default FormLinkToRecordRenderer
