import React, { memo, useMemo } from 'react'
import { EyzyLink, FormDetailsProps } from './types/FormDetails'
import { ViewDetailsType } from '../../types/ViewDetails'
import ProfileDetails from './views/ProfileDetails'
import {
  BaseFormLayout,
  FormDetailsPayload,
} from '../form/__new_form/types/BaseFormProps'
import { Workspace } from '../../types/Workspace'
import { TableActions } from '../../store/actions/TableActions'
import { prepareColumns } from '../../utils/column'
import { mergeColumnWithViewParams } from '../../utils/get'
import { getValue } from '../../general/utils/value'
import BaseForm from '../form/__new_form/form/BaseForm'
import { GridRow } from '../grid/types/GridRow'
import { GridColumn } from '../grid/types/GridColumn'
import { EyzyUser } from '../../types/EyzyUser'

type Props = Omit<FormDetailsProps, 'payload' | 'layout'> & {
  collaborators: EyzyUser[]
  links: Record<string, EyzyLink>
}

const FormDetailsController: React.FC<Props> = ({
  table,
  view,
  row,
  header,
  collaborators,
  links,
  readonly,
  user,
  onChange,
  onCommit,
}) => {
  const payload = useMemo<FormDetailsPayload>(() => {
    return {
      links,
      row,
      table,
      users: collaborators,
      recordAction: {
        action: TableActions.READ_RECORDS,
        id: table._id,
      },
    }
  }, [table, view, row, collaborators, links])

  const fields = useMemo<GridColumn[]>(() => {
    return prepareColumns(table, view).map(column => {
      return {
        ...column,
        params: mergeColumnWithViewParams(view, column),
      }
    })
  }, [table, view])

  const data = useMemo<GridRow>(() => {
    return fields.reduce(
      (res, col) => ({
        ...res,
        [col._id]: getValue(table, col, row),
      }),
      {},
    )
  }, [fields, table, row])

  const as = view.details?.as
  const layout =
    as === ViewDetailsType.FORM
      ? BaseFormLayout.HORIZONTAL
      : BaseFormLayout.INLINE

  if (as === ViewDetailsType.PROFILE) {
    return (
      <ProfileDetails
        view={view}
        table={table}
        data={data}
        fields={fields}
        payload={payload}
        user={user}
        onChange={onChange}
        onCommit={onCommit}
        readonly={readonly}
      />
    )
  }

  return (
    <BaseForm
      fields={fields}
      readonly={readonly}
      header={header}
      formOptions={view.details?.settings?.formOptions}
      layout={layout}
      onChange={onChange}
      onCommit={onCommit}
      data={data}
      payload={payload}
    />
  )
}

export default memo(FormDetailsController)
