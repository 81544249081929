export enum View {
  DAY = 'day',
  MONTH = 'month',
  WEEK = 'week',
  WORKING_WEEK = 'working_week',
  CUSTOM = 'custom'
}

export interface ViewConfig {
  startHours?: number
  endHours?: number
  rowHeight?: number
  step?: number
}