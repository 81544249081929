const isEmail = (value) => {
  return !!~(value || '').indexOf('@')
}

const isString = (value) => {
  return 'string' === typeof value
}

const isArray = (value) => {
  return Array.isArray(value)
}

const isBool = (value) => {
  return 'boolean' === typeof value
}

const isEmpty = (value) => {
  if (null == value) {
    return true
  }

  if (isArray(value) || isString(value)) {
    return !value.length
  }

  const keys = Object.keys(value)

  return keys.length === 0
}

const isFloat = value => !!(value % 1)

export {isEmail}
export {isString}
export {isArray}
export {isBool}
export {isEmpty}
export {isFloat}
