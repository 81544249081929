import React from "react";
import {GridColumn} from "../grid/types/GridColumn";
import {getUIColumnType, isDateColumn, isSelectColumn, isUserColumn} from "../../general/utils/column";
import DateRenderer from "./renderers/DateRenderer";
import {DynamicFilterItem, FilterPayload} from "./types/Filter";
import UserFilterRenderer from "./renderers/UserFilterRenderer";
import ListFilterRenderer from "./renderers/ListRenderer";
import BaseFilterRenderer from "../__renderers/text/BaseFilterRenderer";
import CheckboxFilterRenderer from "./renderers/CheckboxFilterRenderer";
import {ColumnType} from "../../general/types/ColumnType";

export interface FilterRendererProps {
  onChange: (value: any, payload?: any) => void
  column: GridColumn
  filter: DynamicFilterItem
  payload?: FilterPayload
}

function getRenderer(column) {
  const type = getUIColumnType(column)

  if (isDateColumn(column)) {
    return DateRenderer
  }

  if (isUserColumn(column)) {
    return UserFilterRenderer
  }

  if (isSelectColumn(column)) {
    return ListFilterRenderer
  }

  switch(type) {
    case ColumnType.CHECKBOX:
      return CheckboxFilterRenderer

    default: return BaseFilterRenderer
  }
}

export function FilterRendererFactory(props: FilterRendererProps) {
  const Renderer = getRenderer(props.column)

  return (
    // @ts-ignore
    <Renderer {...props} />
  )
}