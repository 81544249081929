import {isString} from "./is";

export function asArray<T = any>(item: any, withFalsy?: boolean): T[] {
  if (Array.isArray(item)) {
    return item
  }

  if (withFalsy) {
    return [item]
  }

  return [item].filter(Boolean)
}

export function asString(item: any): string {
  if (isString(item) || 'number' === typeof item) {
    return item as string
  }

  return ''
}
