import React from "react";
import {BaseRendererProps} from "../BaseRendererProps";
import {asArray} from "../../../general/utils/array";

export default React.memo(function BaseRecordIndexRenderer(props: BaseRendererProps<string>) {
  return (
    <React.Fragment>
      {asArray(props.value).map(v => (
        <span>{v.value}</span>
      ))}
    </React.Fragment>
  )
})
