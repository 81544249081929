import React from "react";
import InputControl from '../../../controllers/Input'
import {SettingsItemComponentProps} from "../types";

export default React.memo((props: SettingsItemComponentProps) => {
  const onChange = (defaultValue) => {
    props.onAction(
      {
        defaultValue
      }
    )
  }

  const defaultValue = props.params.defaultValue || ''

  return (
    <div className={'column-settings-row'}>
      <div className={'column-settings-label'}>
        Значение по умолчанию
      </div>
      <div className={'column-settings-ctrl'}>
        <InputControl
          defaultValue={defaultValue}
          onChange={onChange}
        />
      </div>
    </div>
  )
})
