import React, {useState} from 'react'
import {Button} from '../../components/irv-ui'
import {FilterPickerProps} from "./types/FilterPickerProps";
import FilterGroup from "./FilterGroup";
import {BasicOperators} from '../../types/BasicOperators';
import {DynamicFilterItem, BaseFilter, FilterOp} from "./types/Filter";
import {FilterOperators} from "../../general/types/FilterOperators";
import {removeFilter} from "../../general/filters/utils";
import {random} from "../../utils/random";

import './styles.scss'

export default React.memo(function FilterPicker(props: FilterPickerProps) {
  const {table, header, payload, showCtrl, onChange} = props
  const [filter, setFilter] = useState(props.filter)

  const changeAndCall = (f) => {
    setFilter(f)
    onChange(f)
  }

  const handleFilterChange = (filterId: string | undefined, operator: BasicOperators, payload?: any) => {
    let newFilter = {
      ...filter
    }

    if (operator === BasicOperators.SET) {
      newFilter.filters = filter.filters.map((f: DynamicFilterItem) => {
        if (f._id !== filterId) {
          return f
        }

        const filterValue = {...f}

        for (let i in payload) {
          if (payload[i] === null) {
            delete filterValue[i]
          } else {
            filterValue[i] = payload[i]
          }
        }

        return filterValue
      })
    } else if (operator === BasicOperators.REMOVE) {
      // @ts-ignore
      newFilter = removeFilter(newFilter, filterId)
    }

    changeAndCall(newFilter)
  }

  const handleAdd = () => {
    const {op, filters} = filter || {}
    const newFilter: BaseFilter = {
      op,
      // @ts-ignore
      filters: (filters || []).concat({
        _id: random('f', 6),
        col: table.columns[0]._id,
        op: FilterOperators.CONTAINS
      })
    }

    changeAndCall(newFilter)
  }

  const handleOpChange = (op: FilterOp, level: number) => {
    let newFilter = {
      ...filter,
      op
    }

    if (op === 'and') {
      delete newFilter.op
    }

    changeAndCall(newFilter)
  }

  return (
    <div className={'fp'}>
      {header && (
        <div className={'fp-header'}>{header}</div>
      )}

      {filter && (
        <div className="fp-body">
          <FilterGroup
            filter={filter}
            level={0}
            table={table}
            payload={payload}
            onChange={handleFilterChange}
            onOpChange={handleOpChange}
          />
        </div>
      )}

      {showCtrl && (
        <div className="fp-footer">
          <Button variant={'minimal'} onClick={handleAdd}>+ Добавить фильтр</Button>
          {/*<Button variant={'minimal'}>+ Добавить группу фильтров</Button>*/}
        </div>
      )}
    </div>
  )
})
