import React from 'react'
import { SimpleRendererProps } from '../types/SimpleRendererProps'

const EmailRenderer: React.FC<SimpleRendererProps> = ({ value, className }) => {
  if (!value) {
    return null
  }

  return (
    <div className={className}>
      <a className={'link'} title={value} href={`mailto:${value}`}>
        {value}
      </a>
    </div>
  )
}

export default React.memo(EmailRenderer)
