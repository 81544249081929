import React from 'react'
import { Button } from '../../../../../../components/irv-ui'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { BaseView, ViewColorCondition } from '../../../../../../types/BaseView'
import { BaseTable } from '../../../../../../types/BaseTable'
import BtnWithIcon from '../../../../../../components/btn/BtnWithIcon'
import Select from 'antd/es/select'
import get from 'lodash.get'
import NEWIcon from '../../../../../../components/icon/NEWIcon'
import {
  getUIColumnType,
  isUserColumn,
} from '../../../../../../general/utils/column'
import { ColumnType } from '../../../../../../general/types/ColumnType'
import ColumnNameWithIcon from '../../../../../../components/columnHelpers/ColumnNameWithIcon'
import { ActionType } from '../../../../../../store/CommonEffects'
import { TableActions } from '../../../../../../store/actions/TableActions'
import {
  getRandomColor,
  getViewColorType,
  ViewColorType,
} from '../../../../../../utils/colors'
import ColorPicker from '../../../../../../components/color/ColorPicker'
import RemoveBtn from '../../../../../../components/icon/RemoveBtn'
import Tooltip from 'antd/lib/tooltip'
import BeautyFilterString from '../../../../../../components/filter/BeautyFilterString'
import FilterPicker from '../../../../../../components/filter/FilterPicker'
import { removeItem, reorder } from '../../../../../../utils/array'

interface Props {
  view: BaseView
  table: BaseTable
  onAction: (action: ActionType, payload: any) => void
}

interface State {
  type: ViewColorType
  visibleFCond?: any
}

export default class CustomizeColor extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      type: getViewColorType(props.view),
    }
  }

  isColorized = (): boolean => {
    return !!this.props.view.color
  }

  getAllowedColumns = () => {
    return this.props.table.columns.filter(c => {
      const type = getUIColumnType(c)

      if (type === ColumnType.SINGLE_SELECT) {
        return true
      }

      if (isUserColumn(c)) {
        const params = c.params ? c.params.foreignParams || c.params : {}
        return !params.multiple
      }

      return false
    })
  }

  getCondOverlay = (cond: ViewColorCondition, index: number) => {
    const { table } = this.props
    const payload = {
      users: [],
    }

    const onChange = this.handleFilterChange(index)

    return (
      <div className={'view-settings-tt'}>
        <FilterPicker
          filter={cond.filter}
          table={table}
          payload={payload}
          showCtrl={true}
          onChange={onChange}
        />
      </div>
    )
  }

  setFallback = (otherwise?: any) => {
    const { onAction, view } = this.props
    const color = {
      ...view.color,
      otherwise,
    }

    if (otherwise === null) {
      delete color.otherwise
    } else if (!otherwise) {
      color.otherwise = getRandomColor('c')
    }

    onAction(TableActions.UPDATE_VIEW, {
      color,
    })
  }

  setColor = (col: string | null) => {
    const { onAction, view } = this.props

    onAction(TableActions.UPDATE_VIEW, {
      color: col
        ? {
            ...(view.color || {}),
            col,
          }
        : {},
    })
  }

  setColorType = (type: any) => {
    const { onAction, view } = this.props
    const color = {
      ...view.color,
      type,
    }

    if (!type) {
      delete color.type
    }

    onAction(TableActions.UPDATE_VIEW, {
      color,
    })
  }

  setCondColor = (condIndex: number) => (color: string) => {
    const { onAction, view } = this.props
    const condition = (view.color.condition || [])
      .map((c, i) => {
        if (condIndex !== i) {
          return c
        }

        return { ...c, color }
      })
      .filter(Boolean)

    onAction(TableActions.UPDATE_VIEW, {
      color: {
        ...view.color,
        condition,
      },
    })
  }

  addCondColor = () => {
    const { onAction, view } = this.props
    const condition = get(view, 'color.condition', [])

    condition.push({
      color: `b${condition.length}`,
    })

    onAction(TableActions.UPDATE_VIEW, {
      color: { condition },
    })

    this.setState({
      visibleFCond: condition.length - 1,
    })

    // this.setState({
    //   activeKey: get(this.state, 'activeKey', []).concat(condition.length)
    // })
  }

  removeCond = (condIndex: number) => () => {
    const { onAction, view } = this.props
    const condition = removeItem(
      view.color.condition || [],
      (_, index) => index === condIndex,
    )

    onAction(TableActions.UPDATE_VIEW, {
      color: {
        ...view.color,
        condition,
      },
    })
  }

  handleCondDragEnd = (result: any) => {
    if (!result.destination) {
      return
    }

    const { view, onAction } = this.props
    const condition = reorder(
      view.color.condition,
      result.source.index,
      result.destination.index,
    )

    onAction(TableActions.UPDATE_VIEW, {
      color: {
        ...view.color,
        condition,
      },
    })
  }

  handleFilterChange = (condIndex: number) => filter => {
    const { onAction, view } = this.props
    const condition = (view.color.condition || [])
      .map((c, i) => {
        if (condIndex !== i) {
          return c
        }

        return { ...c, filter }
      })
      .filter(Boolean)

    onAction(TableActions.UPDATE_VIEW, {
      color: {
        ...view.color,
        condition,
      },
    })
  }

  handleSelect = (type: ViewColorType) => {
    this.setState({ type })
  }

  handleRemoveField = () => {
    this.setColor(null)
    this.handleSelect(ViewColorType.NONE)
  }

  renderFieldSettings = () => {
    const { view } = this.props
    const columns = this.getAllowedColumns()
    const color = view.color || {}

    return (
      <>
        <div className="help">
          Раскрасить записи используя настройки поля Одиночный выбор или
          Пользователь
        </div>
        <div className={'c-selector'}>
          <Select
            getPopupContainer={trigger => trigger.parentNode}
            value={color.col}
            onChange={this.setColor}
            showArrow={false}
            className={'column-selector'}
            placeholder={'Выберите колонку'}
            dropdownMatchSelectWidth={false}>
            {columns.map(column => (
              <Select.Option value={column._id} key={column._id}>
                <ColumnNameWithIcon column={column} />
              </Select.Option>
            ))}
          </Select>

          {color.col && (
            <BtnWithIcon
              min
              icon={'cross-small'}
              onClick={this.handleRemoveField}
            />
          )}
        </div>
        {color.col && (
          <div className="c-settings">
            <div>
              <div className="c-settings-text">Тип подсветки</div>
              <div className="c-selector">
                <Select
                  getPopupContainer={trigger => trigger.parentNode}
                  value={color.type || null}
                  onChange={this.setColorType}
                  style={{ width: 165 }}>
                  <Select.Option value={null}>Цветной флажок</Select.Option>
                  <Select.Option value={'row'}>Запись целиком</Select.Option>
                </Select>
              </div>
            </div>

            <div>
              <div className="c-settings-text">Резервный цвет</div>

              {color.otherwise ? (
                <div className="c-settings-ctrl">
                  <ColorPicker
                    color={color.otherwise}
                    onSelect={color => this.setFallback(color)}
                  />
                  <Tooltip
                    title={'Удалить цвет'}
                    trigger={['hover']}
                    placement="bottom">
                    <span>
                      <RemoveBtn
                        onClick={() => this.setFallback(null)}
                        useTrash
                      />
                    </span>
                  </Tooltip>
                </div>
              ) : (
                <Button variant={'minimal'} onClick={_ => this.setFallback()}>
                  + Добавить
                </Button>
              )}
            </div>
          </div>
        )}
      </>
    )
  }

  renderCondSettings = () => {
    const { visibleFCond } = this.state
    const { view, table } = this.props
    const color = view.color || {}
    const conditions = color.condition || []

    return (
      <>
        <div className="help">
          {conditions.length > 0
            ? 'Раскрасить записи путем определения условия'
            : 'Ни одного цвета не установлено'}
        </div>

        <DragDropContext onDragEnd={this.handleCondDragEnd}>
          <Droppable droppableId="droppable">
            {provided => (
              <ul
                {...provided.droppableProps}
                className={'color-cond'}
                ref={provided.innerRef}>
                {conditions.map((cond, i) => (
                  <Draggable
                    key={`cond-${i}`}
                    draggableId={`cond-${i}`}
                    index={i}>
                    {provided => (
                      <li
                        key={`cond-${i}`}
                        ref={provided.innerRef}
                        className={'color-cond-item'}
                        {...provided.draggableProps}>
                        <RemoveBtn onClick={this.removeCond(i)} useTrash />

                        <ColorPicker
                          color={cond.color}
                          onSelect={this.setCondColor(i)}
                        />

                        <Tooltip
                          title={this.getCondOverlay(cond, i)}
                          defaultVisible={visibleFCond === i}
                          overlayClassName={'wtt cv'}
                          trigger={['click']}
                          placement="bottomRight">
                          <div className={'color-cond-text'}>
                            {cond.filter ? (
                              <BeautyFilterString
                                filter={cond.filter}
                                table={table}
                              />
                            ) : (
                              <span>Условие не задано</span>
                            )}

                            <BtnWithIcon icon={'edit'} />
                          </div>
                        </Tooltip>
                        <div {...provided.dragHandleProps}>
                          <NEWIcon type={'drag'} />
                        </div>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>

        <div className={'color-footer'}>
          <Button variant={'minimal'} onClick={this.addCondColor}>
            + Цвет
          </Button>
        </div>

        {conditions.length > 0 && (
          <div className="c-settings">
            <div>
              <div className="c-settings-text">Тип подсветки</div>
              <div className="c-selector">
                <Select
                  getPopupContainer={trigger => trigger.parentNode}
                  value={color.type || null}
                  onChange={this.setColorType}
                  style={{ width: 165 }}>
                  <Select.Option value={null}>Цветной флажок</Select.Option>
                  <Select.Option value={'row'}>Запись целиком</Select.Option>
                </Select>
              </div>
            </div>

            <div>
              <div className="c-settings-text">Резервный цвет</div>

              {color.otherwise ? (
                <div className="c-settings-ctrl">
                  <ColorPicker
                    color={color.otherwise}
                    onSelect={color => this.setFallback(color)}
                  />
                  <Tooltip
                    title={'Удалить цвет'}
                    trigger={['hover']}
                    placement="bottom">
                    <span>
                      <RemoveBtn
                        onClick={() => this.setFallback(null)}
                        useTrash
                      />
                    </span>
                  </Tooltip>
                </div>
              ) : (
                <Button variant={'minimal'} onClick={_ => this.setFallback()}>
                  + Добавить
                </Button>
              )}
            </div>
          </div>
        )}
      </>
    )
  }

  renderSettings = (type: ViewColorType) => {
    if (type === ViewColorType.FIELD) {
      return this.renderFieldSettings()
    }

    return this.renderCondSettings()
  }

  render() {
    const { type } = this.state

    return (
      <div className={'view-settings-item color'}>
        {type === ViewColorType.NONE && (
          <div className={'c-selector'}>
            <Select
              value={type}
              getPopupContainer={trigger => trigger.parentNode}
              onSelect={this.handleSelect}>
              <Select.Option value={0}>
                <div className={'color-type'}>Без подсветки</div>
              </Select.Option>
              <Select.Option value={1}>
                <div className={'color-type'}>
                  <NEWIcon type={'b-caret-circle-down'} /> Поле
                </div>
              </Select.Option>
              <Select.Option value={2}>
                <div className={'color-type'}>
                  <NEWIcon type={'filter'} /> Условие
                </div>
              </Select.Option>
            </Select>
          </div>
        )}

        {type ? this.renderSettings(type) : null}
      </div>
    )
  }
}
