import { Workspace } from "../../types/Workspace"
import get from "lodash.get"
import { AppActions } from "../actions/AppActions"
import { EyzyUser } from "../../types/EyzyUser"
import { BaseActions } from "../actions/BaseActions"
import { WorkspaceActions } from "../actions/WorkspaceActions"
import { TableActions } from "../actions/TableActions"
import { table } from "./utils/tableReducerUtil"
import { baseUpdater } from "./utils/baseReducerUtil"
import { orderColumns } from "../../utils/column"
import { isActiveUser } from "../../utils/is"

const initState: Workspace[] = []

export default function workspaces(state = initState, action) {
  const payload = action.payload || {}
  const result = action.result
  const id = action.id || payload.id

  switch (action.type) {
    case AppActions.INIT: {
      const { workspaces, wsUsers, user } = payload
      const wss = workspaces.map((ws: Workspace) => {
        return {
          ...ws,
          ...mergeWSUsers(ws, wsUsers),
        }
      })

      return orderColumns(wss, get(user, "params.wsOrder"))
    }

    case BaseActions.FETCH:
    case TableActions.FETCH: {
      const { workspaces, tables, wsUsers, user, active } = payload
      const areWSSLoaded: boolean = !!workspaces

      const wss = (workspaces || state).map((ws: Workspace) => {
        const result = areWSSLoaded
          ? {
            ...ws,
            ...mergeWSUsers(ws, wsUsers),
          }
          : ws

        if (ws._id === active.ws && tables) {
          result.bases = result.bases.map(base => {
            if (base._id === active.base) {
              return {
                ...base,
                tables,
              }
            }

            return base
          })
        }

        return result
      })

      return orderColumns(wss, get(user, "params.wsOrder"))
    }

    case AppActions.ENTITIES: {
      const wsBases = payload.reduce((wss, base) => {
        if (!wss[base.workspaceId]) {
          wss[base.workspaceId] = []
        }

        wss[base.workspaceId].push(base)
        return wss
      }, {})

      return state.map(ws => {
        if (ws._id in wsBases) {
          return {
            ...ws,
            bases: wsBases[ws._id],
          }
        }

        return ws
      })
    }

    case WorkspaceActions.FETCH_BASES: {
      return state.map(ws => {
        if (ws._id !== id) {
          return ws
        }

        return {
          ...ws,
          bases: payload.bases,
          baseIds: payload.bases.map(b => b._id),
        }
      })
    }

    case WorkspaceActions.UPDATE: {
      const { id, ...rest } = payload

      return state.map(ws => {
        if (ws._id !== id) {
          return ws
        }

        return {
          ...ws,
          ...rest,
        }
      })
    }

    case WorkspaceActions.UPDATE_ROLE: {
      if (!result) {
        return state
      }

      return state.map(ws => {
        if (ws._id !== id) {
          return ws
        }

        const { level, userId } = payload
        const collaborators = ws.collaborators.map(u => {
          if (u._id !== userId) {
            return u
          }

          return {
            ...u,
            params: {
              ...u.params,
              role: level,
            },
          }
        })

        return {
          ...ws,
          collaborators,
        }
      })
    }

    case WorkspaceActions.REMOVE_COLLABORATOR: {
      if (!result) {
        return state
      }

      return state.map(ws => {
        if (ws._id !== id) {
          return ws
        }

        return {
          ...ws,
          activeCollaborators: ws.activeCollaborators.filter(
            u => u._id !== payload.userId,
          ),
          collaborators: ws.collaborators.map(u => {
            if (u._id !== payload.userId) {
              return u
            }

            return {
              ...u,
              params: {
                ...u.params,
                removedOn: new Date(),
              },
            }
          }),
        }
      })
    }

    case WorkspaceActions.INVITE_COLLABORATOR: {
      if (!result) {
        return state
      }

      return state.map(ws => {
        if (ws._id !== id) {
          return ws
        }

        return {
          ...ws,
          collaborators: ws.collaborators.concat(result.user),
          activeCollaborators: ws.activeCollaborators.concat(result.user),
          users: {
            ...ws.users,
            [result.user._id]: result.user,
          },
        }
      })
    }

    case WorkspaceActions.CREATE_BASE: {
      if (!result) {
        return state
      }

      return state.map(ws => {
        if (ws._id !== id) {
          return ws
        }

        return {
          ...ws,
          bases: ws.bases.concat(result),
        }
      })
    }

    case BaseActions.REMOVE: {
      if (!result) {
        return state
      }

      return state.map(ws => {
        if (ws.bases.some(b => b._id === id)) {
          return {
            ...ws,
            bases: ws.bases.filter(b => b._id !== id),
          }
        }

        return ws
      })
    }

    case BaseActions.FETCH_TABLES: {
      return state.map((ws: Workspace) => {
        const hasBase = ws.bases && ws.bases.some(b => b._id === id)

        if (!hasBase) {
          return ws
        }
        return {
          ...ws,
          bases: ws.bases.map(b => {
            if (b._id === id) {
              return {
                ...b,
                tables: payload.tables,
              }
            }

            return b
          }),
        }
      })
    }

    case BaseActions.UPDATE: {
      const { id, ...rest } = payload

      return baseUpdater(state, id, base => {
        return rest
      })
    }

    case BaseActions.CREATE_TABLE: {
      if (!result) {
        return state
      }

      return state.map(ws => {
        if (ws.bases.some(b => b._id === result.baseId)) {
          return {
            ...ws,
            bases: ws.bases.map(base => {
              if (base._id === result.baseId) {
                return {
                  ...base,
                  tables: base.tables.concat(result),
                }
              }

              return base
            }),
          }
        }

        return ws
      })
    }

    case BaseActions.UPDATE_PERMISSIONS: {
      if (!result) {
        return state
      }

      return baseUpdater(state, payload.id, base => {
        return {
          ...base,
          limitedAccess: result.limitedAccess,
        }
      })
    }

    case TableActions.UPDATE: {
      if (!result) {
        return state
      }

      const { id, ...rest } = payload

      return table(state, id, () => rest)
    }

    case TableActions.REMOVE_COLUMN: {
      if (!result) {
        return state
      }

      return table(state, payload.id, table => {
        return {
          columns: table.columns.filter(
            column => column._id !== payload.columnId,
          ),
        }
      })
    }

    case TableActions.CREATE_COLUMN: {
      if (!result) {
        return state
      }

      return table(state, payload.id, table => {
        return {
          columns: table.columns.concat(result.column),
        }
      })
    }

    case TableActions.UPDATE_COLUMN: {
      const { _id, id, columnId, ...rest } = payload

      const newState = table(state, id, table => {
        return {
          columns: table.columns.map(c => {
            if (c._id !== columnId) {
              return c
            }

            if (result) {
              return result.column
            }

            return {
              ...c,
              ...rest,
              params: {
                ...(c.params || {}),
                ...(rest.params || {}),
              },
            }
          }),
        }
      })

      if (!result) {
        return newState
      }

      if (result.tables && result.tables.length) {
        const tables = result.tables
        const tIds = tables.map(t => t._id)

        return newState.map(ws => {
          const hasTable: boolean = ws.bases.some(base => {
            return base.tables.some(t => tIds.includes(t._id))
          })

          if (!hasTable) {
            return ws
          }

          const bases = ws.bases.map(base => {
            if (base.tables.some(t => tIds.includes(t._id))) {
              return {
                ...base,
                tables: base.tables.map(t => {
                  const resultTable = tables.find(rt => rt._id === t._id)
                  return resultTable || t
                }),
              }
            }

            return base
          })

          return {
            ...ws,
            bases,
          }
        })
      }

      return newState
    }

    case TableActions.UPDATE_VIEW_COLUMN: {
      if (!result) {
        return state
      }

      const { id, viewId, columnId, params } = payload

      return table(state, id, table => {
        return {
          views: table.views.map(v => {
            if (v._id !== viewId) {
              return v
            }

            const columnParams = v.columnsParams || {}
            const currentColumnParams = Object.assign(
              {},
              columnParams[columnId],
              params,
            )

            return {
              ...v,
              columnsParams: Object.assign({}, columnParams, {
                [columnId]: currentColumnParams,
              }),
            }
          }),
        }
      })
    }

    case TableActions.UPDATE_VIEW: {
      const { _id, id, viewId, ...rest } = payload

      return table(state, id, table => {
        return {
          views: table.views.map(v => {
            if (v._id !== viewId) {
              return v
            }

            return {
              ...v,
              ...rest,
            }
          }),
        }
      })
    }

    case TableActions.CREATE_VIEW: {
      if (!result) {
        return state
      }

      return table(state, payload.id, table => {
        return {
          views: table.views.concat(result),
        }
      })
    }

    default:
      return state
  }
}

function mergeWSUsers(ws: Workspace, users: { [id: string]: EyzyUser }): any {
  const activeCollaborators: EyzyUser[] = []

  // @ts-ignore
  const collaborators: EyzyUser[] = Object.entries(ws.users).map(
    ([id, params]) => {
      const u = users[id] || {}
      const user: EyzyUser = {
        ...u,
        params: {
          // @ts-ignore
          ...u.params,
          // @ts-ignore
          ...params,
        },
      } as EyzyUser

      user.fullName = [user.firstName, user.lastName].filter(Boolean).join(" ")

      if (isActiveUser(user)) {
        activeCollaborators.push(user)
      }

      return user
    },
  )

  return {
    ...ws,
    collaborators,
    activeCollaborators,
  }
}
