import React from 'react'
import BaseCheckboxRenderer from "./BaseCheckboxRenderer";
import {GridRendererProps} from "../GridRendererProps";

export default React.memo(function GridCheckboxRenderer(props: GridRendererProps) {
  const {onCommit, isActive, row, column} = props
  const value = row[column._id]

  if (!isActive && !value) {
    return null
  }

  return (
    <BaseCheckboxRenderer
      value={value}
      readonly={false}
      onChange={(value: boolean) => onCommit(value ? 1 : null)}
      autoFocus={isActive}
    />
  )
})