import React from "react";
import { BaseRendererProps } from "../BaseRendererProps";
import {asString} from "../../../utils/as";
import InputControl from '../../controllers/Input'

export default class BaseDelayedTextRenderer extends React.PureComponent<BaseRendererProps<string>> {
  render() {
    const { value, readonly, onChange } = this.props;

    if (readonly) {
      return <div className={'re-value'}>{asString(value)}</div>
    }

    return (
      <InputControl
        fit={true}
        defaultValue={asString(value)}
        onChange={onChange}
      />
    )
  }
}
