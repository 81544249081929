import React from 'react'
import { EyzyUser } from '../../../types/EyzyUser'
import { DisturbEntityArguments } from '../../../store/CommonEffects'
import { Base } from '../../../types/Base'
import { Button, Switch } from '../../../components/irv-ui'
import Avatar from '../../user/Avatar'
import { Roles } from '../../roles/Roles'
import { replaceItem } from '../../../utils/array'
import { BaseActions } from '../../../store/actions/BaseActions'
import { Workspace } from '../../../types/Workspace'
import { isActiveUser } from '../../../utils/is'
import { getRole } from '../../roles/RoleGateway'
import get from 'lodash.get'
import { Dialog, Select } from '../../irv-ui'

import './style.scss'
import { ROLES_OPTIONS } from './Collaborator'

interface Props {
  base?: Base
  ws?: Workspace
  visible: boolean
  onClose: () => void
  onAction: (args: DisturbEntityArguments) => Promise<any>
  user: EyzyUser
}

interface State {
  limitedAccess: boolean
  usersRoles: UserRole[]
  isLoading: boolean
  visible?: boolean
}

interface UserRole {
  id: string
  user: EyzyUser
  hasAccess: boolean
  role: Roles
}

export default class BaseCollaborators extends React.Component<Props, State> {
  state = {
    limitedAccess: false,
    usersRoles: [],
    isLoading: false,
    visible: false,
  }

  static buildRoles(ws: Workspace, base: Base): UserRole[] {
    const limitedAccess = Object(base.limitedAccess || {})

    return ws.collaborators
      .filter(isActiveUser)
      .filter(u => getRole(u, ws) !== Roles.OWNER)
      .map(user => {
        const result: UserRole = {
          id: user._id,
          user,
          hasAccess: user._id in limitedAccess,
          role: limitedAccess[user._id] || get(ws, `users.${user._id}.role`, 2),
        }

        return result
      })
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.visible !== state.visible && props.base && props.ws) {
      return {
        isLoading: false,
        limitedAccess: !!props.base.limitedAccess,
        usersRoles: BaseCollaborators.buildRoles(props.ws, props.base),
        visible: props.visible,
      }
    }

    return {
      visible: props.visible,
    }
  }

  handleChangeRole = (id, params: any) => {
    const usersRoles = replaceItem(
      this.state.usersRoles,
      r => r.id === id,
      [],
      (item: UserRole) => ({
        ...item,
        ...params,
      }),
    )

    this.setState({ usersRoles })
  }

  handleChangeAccess = (value: boolean) => {
    this.setState({ limitedAccess: value })
  }

  handleSave = () => {
    const payload = {
      limitedAccess: this.state.limitedAccess,
      users: this.state.usersRoles.map((u: UserRole) => ({
        userId: u.id,
        level: u.role,
        hasAccess: u.hasAccess,
      })),
    }

    if (!payload.limitedAccess) {
      delete payload.users
    }

    const { onAction, onClose, base } = this.props

    this.setState({ isLoading: true })

    onAction({
      entity: BaseActions.UPDATE_PERMISSIONS,
      id: base._id,
      payload,
    })

    onClose()
  }

  renderCollaborators = () => {
    if (!this.state.limitedAccess) {
      return null
    }

    const usersRoles = this.state.usersRoles

    return (
      <div className="collaborators-block">
        <h3>Сотрудники рабочего пространства</h3>
        <div className="collaborators-list">
          {usersRoles.map((user: UserRole) => (
            <div className="collaborator" key={user.id}>
              <Avatar user={user.user} />
              <div className="collaborator-name">
                <span className="name">{user.user.fullName}</span>
                <span className="email">{user.user.email}</span>
              </div>
              <Switch
                checked={user.hasAccess}
                onChange={hasAccess =>
                  this.handleChangeRole(user.user._id, { hasAccess })
                }>
                Доступ открыт
              </Switch>
              <Select
                dropdownClassName="collaborator-role"
                width={170}
                items={ROLES_OPTIONS}
                value={user.role}
                disabled={!user.hasAccess}
                dropdownWidth={550}
                placement={'bottom right'}
                theme={'white'}
                valueRenderer={({ item: { name } }) => name}
                onSelect={userRole => {
                  this.handleChangeRole(user.user._id, { role: userRole })
                }}>
                {(item, arrow) => (
                  <div className={'irv-bitem'}>
                    <div className="name">{item.name}</div>
                    <div className="descr">{item.descr}</div>

                    {arrow}
                  </div>
                )}
              </Select>
            </div>
          ))}
        </div>
      </div>
    )
  }

  renderFooter = () => {
    return (
      <div className="collaborators-footer">
        <Button
          disabled={this.state.isLoading}
          variant="primary"
          onClick={this.handleSave}>
          Сохранить
        </Button>
        <Button disabled={this.state.isLoading} onClick={this.props.onClose}>
          Отмена
        </Button>
      </div>
    )
  }

  render() {
    const { base, onClose, visible } = this.props
    const { limitedAccess } = this.state

    if (!base) {
      return null
    }

    return (
      <Dialog
        width={760}
        alignSelf={'start'}
        header={<h3>Настройка доступа к базе {base!.name}</h3>}
        onClose={onClose}
        open={visible}
        footer={this.renderFooter()}>
        {base && (
          <div className="collaborators">
            <p>
              Данная функция доступна только владельцам. Все пользователи
              рабочей области, у которых заберете права - не будут видеть это
              базу.
            </p>
            <p>
              <Switch
                checked={limitedAccess}
                onChange={this.handleChangeAccess}>
                Ограничить доступ
              </Switch>
            </p>

            {this.renderCollaborators()}
          </div>
        )}
      </Dialog>
    )
  }
}
