import React from 'react'
import {Workspace} from "../../../../types/Workspace";
import {Base} from "../../../../types/Base";
import {BaseView} from "../../../../types/BaseView";
import {BaseTable} from "../../../../types/BaseTable";
import Avatar from "../../../../components/user/Avatar";
import {EyzyUser} from "../../../../types/EyzyUser";
import {hasAccess} from "../../../../general/utils/view";
import get from 'lodash.get'

interface Props {
  ws: Workspace
  base: Base
  table: BaseTable
  view: BaseView
  user: EyzyUser
}

export default React.memo(function ViewUsers(props: Props) {
  const {ws, view, base} = props

  if (!get(ws, 'params.showCollaborators')) {
    return null
  }

  const viewUsers = ws.collaborators.filter(u => hasAccess(view, {base, workspace: ws, user: u}))
  const shownUsers: EyzyUser[] = viewUsers.slice(0, 3)
  const theRest = viewUsers.slice(3)

  return (
    <div className={'view-users'}>
      {shownUsers.map(u => (
        <Avatar key={u._id} user={u} />
      ))}

      {theRest.length > 0 && (
        <button className={'bim'}>+{theRest.length}</button>
      )}
    </div>
  )
})
