import React, {
  forwardRef,
  HTMLAttributes,
  ReactNode,
  RefObject, useEffect,
  useLayoutEffect,
} from "react"
import { useDOMRef } from '../hooks/useDomRef'
import { ModalProps } from './types/ModalProps'
import { useOverlay } from '../hooks/useOverlay'
import Overlay from './Overlay'
import { Underlay } from './Underlay'
import { usePreventScroll } from '../hooks/usePreventScroll'
import { cn } from '../utils/cn'
import { mergeProps } from '../utils/mergeProps'
import { useViewportSize } from "../hooks/useViewportSize"

interface ModalWrapperProps extends HTMLAttributes<HTMLElement> {
  children: ReactNode
  open?: boolean
  onClose?: () => void
  type?: 'modal' | 'fullscreen' | 'tray'
  overlayProps: HTMLAttributes<HTMLElement>
  className?: string
}

const ModalWrapper = forwardRef(function (
  props: ModalWrapperProps,
  ref: RefObject<HTMLDivElement>,
) {
  const { children, open, type, className, overlayProps, ...otherProps } = props
  const wrapperClassName = cn('irv-modal-wrapper')
  const modalClassName = cn(
    'irv-modal',
    { open },
    className,
    type ? `irv-modal-${type}` : '',
  )

  usePreventScroll()

  const viewport = useViewportSize()
  const style: any = {
    ...otherProps.style,
    // @ts-ignore
    maxHeight: (viewport.height * 0.9) + 'px',
  }

  if (type === 'fullscreen') {
    // fix for mobile
    delete style.maxHeight
    delete style.width
  }

  if (otherProps.style.maxHeight) {
    delete style.maxHeight
  }

  return (
    <div className={wrapperClassName}>
      <div
        {...mergeProps(otherProps, overlayProps)}
        ref={ref}
        style={style}
        tabIndex={-1}
        className={modalClassName}>
        {children}
      </div>
    </div>
  )
})

const Modal = (props: ModalProps, ref: any) => {
  const domRef: any = useDOMRef(ref)

  const { children, onClose, type, style, className, ...otherProps } = props
  const { overlayProps, underlayProps } = useOverlay(props, domRef)

  useEffect(() => {
    if (!props.open) {
      return
    }

    if (domRef && domRef.current) {
      setTimeout(() => {
        if (domRef.current.contains(document.activeElement)) {
          return
        }

        domRef.current.focus()
      }, 200)
    }
  }, [props.open])

  return (
    <Overlay {...otherProps}>
      <Underlay {...underlayProps} />
      <ModalWrapper
        onClose={onClose}
        type={type}
        ref={domRef}
        style={style}
        className={className}
        overlayProps={overlayProps}>
        {children}
      </ModalWrapper>
    </Overlay>
  )
}

export default forwardRef(Modal)
