import React from 'react'
import { getColumnById } from '../../utils/get'
import { aggregateGroupValue } from '../../utils/aggregation'
import { Tag } from '../irv-ui'
import { getColorClass } from '../../utils/colors'
import { BaseTable } from '../../types/BaseTable'
import { ItemTitleBadge } from '../../types/BaseView'
import { GridRow } from '../grid/types/GridRow'

interface Props {
  table: BaseTable
  badges: ItemTitleBadge[]
  data: GridRow[]
}

const WidgetTitleBadge: React.FC<Props> = ({ table, badges, data }) => {
  if (!table || !data) {
    return null
  }

  const tags = badges
    .map((badge, i) => {
      const column = getColumnById(table, badge.col)

      if (!column) {
        // eslint-disable-next-line array-callback-return
        return
      }

      const agg = aggregateGroupValue(table, column, data, badge.agg)

      if (!agg) {
        // eslint-disable-next-line array-callback-return
        return
      }

      const content = [
        badge.label === false ? '' : badge.label || agg.label,
        agg.value,
      ]
        .filter(Boolean)
        .join(' ')

      return <Tag key={`${i}`} className={getColorClass(badge.color)}>{content}</Tag>
    })
    .filter(Boolean)

  if (!tags.length) {
    return null
  }

  return <div className={'badge'}>{tags}</div>
}

export default WidgetTitleBadge
