import React, { FunctionComponent } from 'react'
import { IorvoGridColumn, IorvoGridColumnHeaderProps } from './IorvoGridColumn'

export enum FakeType {
  END_OF_THE_GROUP,
  END_OF_THE_LIST,
}

export type GroupHeaderRow = {
  group: any
  key: string
  depth: number
  rows?: number[]
  ex?: boolean // экстра высота для хедера, когда нужен отступ для ВСЕХ, кроме первого (для depth >= 1)
}

export type GroupRow = Array<
  number[]
  /*
    rowIndex (По этому индексу читается из props.rows),
    index of the whole list (просто инкрементиться),
    index of the group
  */
>

export type FakeRow = { ft: FakeType; payload?: any }

export type GroupOrRow = GroupHeaderRow | GroupRow | FakeRow

/*
  GroupRoRow Example:
  [
    { group: {}, key: 'uniqKey_1', depth: 0 },
      { group: {}, key: 'uniqKey_2', depth: 1 },
        { group: {}, key: 'uniqKey_3', depth: 2 },
          [ Number('rowIndex1'), Number('rowPositionInGroup1') ],
          [ Number('rowIndex2'), Number('rowPositionInGroup2') ],
          [ Number('rowIndex3'), Number('rowPositionInGroup3') ],
        { group: {}, key: 'uniqKey_4', depth: 2 },
          [ Number('rowIndex1'), Number('rowPositionInGroup1') ],
          [ Number('rowIndex2'), Number('rowPositionInGroup2') ],
  ]
*/

export type GridGroup = {
  key: string
  group?: any
  groups?: GridGroup[]
  rows: number[]
}

export interface IorvoGridMask {
  c: number
  r: number
  id?: string
}

export interface IorvoGridState {
  cn: string
  scrolled: boolean

  height: number
  width: number
  itemData: {
    hoveredRow?: any
    mask?: IorvoGridMask
    collapsed: string[]
  }

  columns: IorvoGridColumn[]

  // Если есть группы - не забыть добавить чуток ширины :-D
  totalColumnsWidth: number

  freezeWidth?: number

  gRows: GroupOrRow[]

  // Глубина группы
  depth: number

  // Спрятанные группы
  collapsed: string[]
}

export interface IorvoGridProps {
  rowsClassNames?: {}
  rows: any[]
  rowHeight?: number
  freeze?: number
  columns: IorvoGridColumn[]
  columnPayload?: any
  headerRenderer?: React.FC<IorvoGridColumnHeaderProps>
  grouping?: GridGroup[]
  groupHeaderRenderer?: FunctionComponent<IorvoGroupHeaderProps>
  sn?: IorvoSequenceNumber
  onSelect?: (mask?: IorvoGridMask | null) => void
  onResize?: (column: IorvoGridColumn, width: number) => void
}

export interface IorvoGroupHeaderProps<C = IorvoGridColumn, G = GridGroup> {
  payload?: any
  rows: number[]
  group: G
  column: C
  main?: boolean
}

export interface IorvoSequenceNumber {
  width?: number
  renderer?: FunctionComponent<IorvoSequenceNumberRenderer>
}

export interface IorvoSequenceNumberRenderer {
  index: number
  displayIndex: number
  payload?: any
  row: any
}
