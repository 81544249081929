import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useKeyPress } from '../../hooks/useKeyPress'
import { getValue } from '../../../general/utils/value'
import { EventKey, isKey } from '../../../utils/dom'
import get from 'lodash.get'
import NEWIcon from '../../icon/NEWIcon'
import { useGridRendererClassName } from '../hooks/useGridRendererClassName'
import { GridCellRendererProps } from '../types/GridRenderer'
import { Dialog, TextArea } from '../../irv-ui'
import { isMob } from "../../../utils/is"

const GridLongTextRenderer: React.FC<GridCellRendererProps> = ({
  column,
  active,
  payload,
  row,
}) => {
  const [value, setValue] = useState(getValue(payload.table, column, row))
  const [isModalVisible, setModal] = useState(false)
  const [isEdit, setEdit] = useState<boolean>(false)
  const cn = useGridRendererClassName(column.type, isEdit)

  const pressedKey = useKeyPress(payload.readonly ? [] : null, active, row._id + column._id)
  const rowHeight: number = get(payload.view, 'rowHeight', 32)

  const editorRef = useRef()

  const handleChange = value => {
    if (payload.readonly) {
      return
    }

    setValue(value)
  }

  const handleExpand = () => {
    setModal(true)
  }

  const handleDblClick = useCallback(() => {
    setEdit(true)
  }, [])

  const closeModal = () => {
    if (getValue(payload.table, column, row) !== value) {
      payload.onChange(row._id, column, value)
    }

    setModal(false)
  }

  const saveValue = useCallback(() => {
    setEdit(false)
    setModal(false)

    if (getValue(payload.table, column, row) !== value) {
      payload.onChange(row._id, column, value)
    }
    // eslint-disable-next-line
  }, [row, value, column, payload])

  const editor = (
    <TextArea
      className={cn}
      value={value}
      ref={editorRef}
      autoFocus
      autoHeight
      style={{ minHeight: rowHeight + 15, maxHeight: 500 }}
      onChange={handleChange}
      onBlur={saveValue}
    />
  )

  const presentation = (
    <div className={cn} onDoubleClick={handleDblClick}>
      <div className={'tl-text'}>{value}</div>
      {active && (
        <>
          <Dialog
            onClose={closeModal}
            open={isModalVisible}
            className={'irv-ow tl'}
            alignSelf={'start'}
            header={null}
            footer={null}>
            {editor}
          </Dialog>

          {!payload.readonly && (
            <button className={'exp'} onClick={handleExpand}>
              <NEWIcon type={'expand'} />
            </button>
          )}
        </>
      )}
    </div>
  )

  useEffect(() => {
    setValue(getValue(payload.table, column, row))
  }, [payload.table, column, row])

  useEffect(() => {
    if (
      isModalVisible ||
      !pressedKey ||
      pressedKey.ctrlKey ||
      pressedKey.metaKey
    ) {
      return
    }

    if (isMob()) {
      return
    }

    if (isEdit) {
      if (isKey(pressedKey.key, EventKey.ESC)) {
        setEdit(false)
        setValue(getValue(payload.table, column, row))
      }
    } else {
      if (pressedKey.key.length === 1) {
        setTimeout(_ => setValue(pressedKey.key))
        setEdit(true)
      } else if (isKey(pressedKey.key, EventKey.ENTER)) {
        setEdit(true)
      }
    }
    // eslint-disable-next-line
  }, [pressedKey, isModalVisible])

  if (payload.readonly) {
    return presentation
  }

  if (isEdit) {
    return editor
  }

  return presentation
}

export default memo(GridLongTextRenderer)
