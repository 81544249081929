import React from 'react'
import { Resource } from '../../types/Resource'
import { TimelineColumn } from '../../types/Timeline'

interface Props {
  resource: Resource
  columns: TimelineColumn[]
  height: number
}

export default React.memo(function TimelineResource(props: Props) {
  const style = {
    height: props.height
  }

  return (
    <div style={style} className='tl-resource' data-id={props.resource.id}>
      {props.columns.map(c => (
        <div className='tl-resource-col' key={props.resource.id}>{c.renderer(props.resource)}</div>
      ))}
    </div>
  )
})