import dayjs from 'dayjs'
import dayjsUtc from 'dayjs/plugin/utc'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'

import uk from 'dayjs/locale/uk'

dayjs.locale(uk);
dayjs.extend(localizedFormat);
dayjs.extend(localeData);
dayjs.extend(weekday);
dayjs.extend(dayjsUtc);
dayjs.extend(relativeTime);

const FILTER_FORMAT = "YYYY-MM-D";
const DEFAULT_DATE_FORMAT = "D MMMM YYYY";
const DEFAULT_TIME_FORMAT = "HH:mm";

const weekDaysEng = "mon_tue_wed_thu_fri_sat_sun".split("_");

const weekdaysMin = [...dayjs.weekdaysMin(dayjs().weekday(1))];

const weekdaysValueList = () =>
  weekdaysMin.map((w, i) => ({
    value: weekDaysEng[i],
    key: w,
  }));

const utc = (date) => dayjs(date).utc();

const isValid = (date) => {
  return dayjs(date).isValid();
};

const format = (date, format, timeFormat, ignoreInvalid) => {
  const d = dayjs(date);

  if (ignoreInvalid && !d.isValid()) {
    return "";
  }

  let dFormat = format || DEFAULT_DATE_FORMAT;

  if (timeFormat) {
    dFormat += `, ${timeFormat}`;
  }

  return format === "relative" ? d.fromNow() : d.format(dFormat);
};

export {dayjs}
export {utc}
export {isValid}
export {weekdaysValueList}
export {weekdaysMin}
export {format}
export {DEFAULT_DATE_FORMAT}
export {DEFAULT_TIME_FORMAT}
export {FILTER_FORMAT}
