import React from 'react'
import { DetailsBlockProps } from '../types'
import { useBlockProvider } from '../BlockProvider'
import Form from '../../../form/__new_form/form/Form'
import { BaseFormLayout } from '../../../form/__new_form/types/BaseFormProps'

const DetailsFieldsBlock: React.FC<DetailsBlockProps> = ({ block  }) => {
  const { fields, data, onCommit, onChange, readonly } = useBlockProvider()

  const columns = block.params.columns
    .map(id => fields.find(f => f._id === id))
    .filter(Boolean)

  return (
    <div className={'details-block'}>
      {block.title && <h3>{block.title}</h3>}

      <Form
        fields={columns}
        data={data}
        readonly={readonly}
        layout={BaseFormLayout.INLINE}
        onChange={onChange}
        onCommit={onCommit}
      />
    </div>
  )
}

export default DetailsFieldsBlock
