import React from "react"
import { isBlackTextColor } from "../../utils/colors"
import { getColorsList } from "../../general/data/colors"

interface Props {
  ignorePrefix?: string[]
  color?: string
  onSelect?: (color: string) => void
}

const prefixes: string[] = ["b", "c", "d"]
const getColorShapeClass = (color, activeColor) => {
  return [
    color.v === activeColor ? `active ${color.c}` : color.c,
    isBlackTextColor(activeColor) ? "b" : "",
  ].join(" ")
}

export default React.memo(function ColorPalette(props: Props) {
  const palette = prefixes
    .filter(p => !(props.ignorePrefix || []).includes(p))
    .reduce((acc, p) => acc.concat(...getColorsList(p)), [])

  const handleClick = ({ target }) => {
    if (props.onSelect) {
      target.dataset.color && props.onSelect(target.dataset.color)
    }
  }

  return (
    <div className={"color-palette"} onClick={handleClick}>
      {palette.map(color => (
        <span
          key={color.v}
          data-color={color.v}
          className={getColorShapeClass(color, props.color)}
        />
      ))}
    </div>
  )
})
