import React from 'react'
import {Input} from "../../components/irv-ui"
import Logo from "../../components/logo/Logo";
import {__post, baseUrl} from "../../utils/request";
import {buildLink, history, Pages} from "../../utils/navigation";

import './style.scss'

interface State {
  email: string
  password: string
  auth_token: string
  isLoading?: boolean
}

export default class LoginContainer extends React.Component<any, State> {
  state = {
    email: "",
    password: "",
    auth_token: "11111",
    isLoading: false
  }

  handleSubmit = (e) => {
    e.preventDefault()

    this.setState({isLoading: true})

    __post('user/login', this.state).then((response) => {
      history.replace(buildLink(Pages.MAIN, {}))
    })
  }

  handleChange = (name: string) => (value) => {
    this.setState({
      [name]: value
    } as State)
  }

  render() {
    const {email, password, isLoading} = this.state
    const btnClassName = isLoading ? 'pr' : ''
    const btnText = isLoading ? 'Входим...' : 'Войти'

    return (
      <div className="login">
        <Logo small={1}/>

        <form className="login-form" method="post" action={baseUrl + 'user/login'} onSubmit={this.handleSubmit}>
          <legend>Sign In</legend>
          <Input placeholder="Email Address"
                 value={email}
                 type="email"
                 required
                 fit
                 onChange={this.handleChange('email')}
                 name="email"
                 disabled={isLoading}
                 spellCheck="false"
                 autoFocus
          />
          <Input placeholder="Password" value={password} type="password" onChange={this.handleChange('password')}
                 name="password"
                 disabled={isLoading}
                 fit
                 required/>
          <input type="submit" value={btnText} className={btnClassName}/>
        </form>
      </div>
    )
  }
}
