import React from 'react'
import './style.scss'
import { cn } from '../../utils/classname'

export default React.memo(function Shape(props: any) {
  const style: any = {}

  if (props.color) {
    style.backgroundColor = props.color
  }

  const className = cn(
    'shape',
    `shape--${props.type || 'rect'}`,
    props.className
  )

  return (
    <span className={className} style={style}>
      {props.children}
    </span>
  )
})