import React from 'react'
import { IorvoGridColumnHeaderProps } from '../iorvo-grid/types/IorvoGridColumn'
import ColumnNameWithIcon from '../columnHelpers/ColumnNameWithIcon'
import { isRoleAllowed, UIRoleAction } from '../roles/RoleGateway'
import ColumnContextMenu from '../contextMenu/column/ColumnContextMenu'
import { GridColumn } from './types/GridColumn'

const GridHeaderRenderer: React.FC<IorvoGridColumnHeaderProps> = ({
  column,
  payload
}) => {
  const { onAction, userRole } = payload
  const headerContent = <ColumnNameWithIcon column={column as GridColumn} />

  if (!isRoleAllowed(UIRoleAction.TABLE_EDIT_COLUMN, userRole)) {
    return headerContent
  }

  return (
    <ColumnContextMenu onAction={onAction} column={column as GridColumn}>
      <div className={'irv-edit-clm'}>{headerContent}</div>
    </ColumnContextMenu>
  )
}

export default GridHeaderRenderer
