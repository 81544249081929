import React from 'react'
import Icon from '../../../../components/icon/Icon'
import { Workspace } from '../../../../types/Workspace'
import './style.scss'
import { EyzyUser } from '../../../../types/EyzyUser'
import { getWSRole } from '../../../../components/roles/RoleGateway'
import { Roles } from '../../../../components/roles/Roles'

interface Props {
  workspaces: Workspace[]
  user: EyzyUser
}

function handleClick(e) {
  e.preventDefault()

  const workspaceId: string = e.target.href.replace(/^.+\//, '')
  const workspaceContainer: HTMLUListElement | null = document.querySelector(
    `[data-id="${workspaceId}"]`,
  )

  if (workspaceContainer) {
    workspaceContainer.scrollIntoView()
    workspaceContainer.classList.add('animate')

    setTimeout(() => {
      workspaceContainer.classList.remove('animate')
    }, 1000)
  }
}

export default React.memo(function HelloMenu(props: Props) {
  if (!props.workspaces.length) {
    return null
  }

  const isOwner: boolean = props.workspaces.some(
    w => getWSRole(props.user, w) === Roles.OWNER,
  )

  return (
    <div className="hello-menu">
      <div className="hello-menu__workspaces">
        <h3>РАБОЧИЕ ОБЛАСТИ</h3>

        <ul>
          {props.workspaces.map(w => (
            <li className="hello-link" key={w._id}>
              <a
                href={`/${w._id}`}
                className="truncate"
                title={w.name}
                onClick={handleClick}>
                <Icon type="account-group" />
                {w.name}
              </a>
            </li>
          ))}
        </ul>
      </div>

      {isOwner && (
        <div className="hello-menu__addons">
          <h3>ДОПОЛНИТЕЛЬНО</h3>

          <ul>
            <li className="hello-link">
              <a href={'/'} className="truncate">
                <Icon type="widgets" />
                Настройка приложения
              </a>
            </li>
            <li className="hello-link">
              <a href={'/'} className="truncate">
                <Icon type="widgets" />
                Виджеты
              </a>
            </li>
            <li className="hello-link">
              <a href={'/'} className="truncate">
                <Icon type="finance" />
                Отчеты
              </a>
            </li>
          </ul>
        </div>
      )}
    </div>
  )
})
