import {combineReducers} from "redux"
import loading from "./loading/loadingReducer"
import bases from "./reducers/BasesReducer"
import tables from "./reducers/TablesReducer"
import rows from "./reducers/RowReducer"
import user from "./reducers/UserReducer"
import workspaces from './reducers/WorkspacesReducer'
import userMap from './reducers/UserMapReducer'
import utility from './reducers/UtilityReducer'
import wss from './reducers/WSSReducer'
import active from './reducers/ActiveStateReducer'
import app from './reducers/AppReducer'

export default combineReducers({
  workspaces,
  loading,
  bases,
  tables,
  rows,
  user,
  userMap,
  utility,

  active,
  app,
  wss,
})
