import React, { memo, useCallback, useEffect, useState } from 'react'
import { NumberInput } from '../../../components/irv-ui'
import { useKeyPress } from '../../hooks/useKeyPress'
import { getValue } from '../../../general/utils/value'
import { RendererClassNames } from '../definitions'
import { EventKey, isKey } from '../../../utils/dom'
import { GridCellRendererProps } from '../types/GridRenderer'
import { formatNumber } from '../../../utils/view'

const GridNumberRenderer: React.FC<GridCellRendererProps> = ({
  column,
  active,
  payload,
  row,
}) => {
  const [value, setValue] = useState(getValue(payload.table, column, row))
  const [isEdit, setEdit] = useState<boolean>(false)

  const params = column.params
  const pressedKey = useKeyPress(null, active, row._id + column._id)

  const handleChange = val => {
    setValue(val)
  }

  const handleDblClick = useCallback(() => {
    setEdit(true)
  }, [])

  const presentation = (
    <div className={RendererClassNames.NUMBER} onDoubleClick={handleDblClick}>
      <span>{isNaN(value) ? null : formatNumber(value, params.precision)}</span>
    </div>
  )

  const saveValue = useCallback(() => {
    setEdit(false)

    if (getValue(payload.table, column, row) !== value) {
      payload.onChange(row._id, column, value)
    }
    // eslint-disable-next-line
  }, [row, value, column, payload])

  useEffect(() => {
    setValue(getValue(payload.table, column, row))
  }, [payload.table, column, row])

  useEffect(() => {
    // console.log(pressedKey, value, active)
    if (!pressedKey || pressedKey.ctrlKey || pressedKey.metaKey) {
      return
    }

    if (isEdit) {
      if (isKey(pressedKey.key, EventKey.ESC)) {
        setEdit(false)
        setValue(getValue(payload.table, column, row))
      } else if (isKey(pressedKey.key, EventKey.ENTER)) {
        saveValue()
      }
    } else {
      if (pressedKey.key.length === 1) {
        setValue(pressedKey.key)
        setEdit(true)
      } else if (isKey(pressedKey.key, EventKey.ENTER)) {
        setEdit(true)
      }
    }
    // eslint-disable-next-line
  }, [pressedKey, active])

  if (payload.readonly) {
    return presentation
  }

  if (isEdit) {
    const isInt: boolean = !('precision' in params || params.precision === 0)

    return (
      <NumberInput
        autoFocus
        value={value || ''}
        isInt={isInt}
        className={RendererClassNames.NUMBER}
        onChange={handleChange}
        onBlur={saveValue}
      />
    )
  }

  return presentation
}

export default memo(GridNumberRenderer)
