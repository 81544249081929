import {DateFilterModes} from '../types/DateFilterModes'
import {dayjs} from '../utils/date'
import {asArray} from '../utils/array'

/*eslint default-case: 0*/

const parseSpecialKey = key => {
  switch(key) {
    case 'TODAY': return new Date()
  }

  return key
}

function getDynamicFilterValue(filter, value) {
  const preparedValue = parseSpecialKey(value)

  switch(filter.mode) {
    case DateFilterModes.MONTH:
      return dayjs(preparedValue).toDate()
  }

  return filter.value
}

function removeFilter(filter, id) {
  return accumulator(filter)

  function accumulator(f) {
    const result = {}

    if (f.op && f.op === 'or') {
      result.op = 'or'
    }

    result.filters = asArray(f.filters)
      .map(fItem => {
        if (fItem.filters) {
          return accumulator(fItem)
        }

        return fItem._id === id ? null : fItem
      })
      .filter(Boolean)

    return result
  }
}

export {getDynamicFilterValue}
export {removeFilter}