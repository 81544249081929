import React from 'react'
import { SimpleRendererProps } from '../types/SimpleRendererProps'
import LinkToRecordTags from "../utils/LinkToRecordTags"

const LinkToRecordRenderer: React.FC<SimpleRendererProps> = ({ value, className }) => {
  if (!value) {
    return null
  }

  return (
    <div className={className}>
      <LinkToRecordTags value={value} />
    </div>
  )
}

export default React.memo(LinkToRecordRenderer)
