import React from "react"
import { Route, Router, Switch } from "react-router-dom"
import store from "./store"
import { Provider } from "react-redux"
import { history, Pages } from "./utils/navigation"

import Hello from "./containers/hello"
import LoginContainer from "./containers/login/LoginContainer"
import ResetPasswordContainer from "./containers/login/ResetPasswordContainer"
import BaseRouterContainer from "./containers/workspace/containers/BaseRouterContainer"
import WorkspaceContainer from "./containers/workspace/containers/WorkspaceContainer"
import RowDetailsContainer from "./containers/workspace/containers/RowDetailsContainer"
import NotFoundPage from "./containers/404/NotFoundPage"

import "antd/dist/antd.css"
import "./assets/style/app.scss"
import ResetPasswordError from "./containers/login/ResetPasswordError"

const App = () => {
  return (
    <Provider store={store}>
      <Router history={history}>
        <Switch>
          <Route exact path={Pages.MAIN} component={Hello} />
          <Route exact path={Pages.MAIN_BASE} component={BaseRouterContainer} />
          <Route
            exact
            path={Pages.MAIN_WORKSPACE_NO_VIEW}
            component={WorkspaceContainer}
          />
          <Route
            exact
            path={Pages.MAIN_WORKSPACE}
            component={WorkspaceContainer}
          />
          {/*<Route exact path={Pages.MAIN_BASE} component={BaseContainer}/>*/}
          {/*<Route exact path={Pages.TABLE_BASE} component={BaseContainer}/>*/}
          <Route exact path={Pages.LOGIN} component={LoginContainer} />
          <Route
            exact
            path={Pages.RESET_PASSWORD}
            component={ResetPasswordContainer}
          />
          <Route
            exact
            path={Pages.RESET_PASSWORD_ERROR}
            component={ResetPasswordError}
          />
          <Route
            exact
            path={Pages.ROW_DETAILS}
            component={RowDetailsContainer}
          />
          <Route exact path={Pages.NOT_FOUND} component={NotFoundPage} />
          <Route component={NotFoundPage} />
        </Switch>
      </Router>
    </Provider>
  )
}

export default App
