import React from 'react'
import { Field } from '../../../components/irv-ui'
import dayjs from 'dayjs'
import { FormRendererProps } from '../FormRendererProps'

const FormSpecialDateRenderer: React.FC<FormRendererProps> = ({
  label,
  value,
}) => {
  return <Field label={label}>{dayjs(value).format('D MMMM YYYY')}</Field>
}

export default React.memo(FormSpecialDateRenderer)
