import React from 'react'
import { RowDetailsContainerProps } from '../types/RowDetailsContainer'
import { __post, getActionURL, request } from '../../../utils/request'
import Loading from '../../../components/loading/Loading'
import { BaseTable } from '../../../types/BaseTable'
import { GridRow, RowSharingLink } from '../../../components/grid/types/GridRow'
import NotFoundPage from '../../404/NotFoundPage'
import store from '../../../store'
import { RowActions } from '../../../store/actions/RowActions'
import { EyzyUser } from '../../../types/EyzyUser'

import '../styles/rowdetails.scss'
import FormDetailsController from '../../../components/details/FormDetailsController'
import { GridColumn } from '../../../components/grid/types/GridColumn'
import { getFormCDN, getMockUser } from "../../../utils/get"
import { BaseView } from "../../../types/BaseView"
import { ViewDetailsType } from "../../../types/ViewDetails"

type Props = RowDetailsContainerProps & { match: any; history: any }

interface State {
  loading: boolean
  is404: boolean
  table?: BaseTable
  row?: GridRow
  wsUsers?: any
  user?: EyzyUser
  link?: RowSharingLink
  links?: any
}

class RowDetailsContainer extends React.PureComponent<Props, State> {
  state: State = {
    loading: true,
    is404: false,
  }

  componentDidMount() {
    const url: string = getActionURL(
      'row',
      'publicData',
      this.props.match.params.link,
    )

    request(url, null, true)
      .then(resp => {
        store.dispatch({
          type: RowActions.SHARED_ROW,
          payload: { ...resp },
        })

        this.setState({
          ...resp,
          loading: false,
          is404: false,
        })
      })
      .catch(_ => {
        console.log(_)
        this.setState({ is404: true, loading: false })
      })
  }

  isReadOnly = () => {
    const { link, user } = this.state

    if (link.editLevel === 2) {
      return !user
    }

    return link.editLevel !== 3;
  }

  getViewLayout = (view: BaseView): { classname?: string, style: any } => {
    let classname

    if (view.details?.settings?.layout) {
      classname = `details-layout-${view.details?.settings?.layout}`
    }

    return {
      classname,
      style: { background: view.details?.settings?.bgColor }
    }
  }

  handleChange = (column: GridColumn, value) => {
    this.setState({
      row: {
        ...this.state.row,
        [column._id]: value,
      },
    })
  }

  handleCommit = (column: GridColumn, value) => {
    if (this.isReadOnly()) {
      return
    }

    __post(`row/${this.props.match.params.link}/updateICell`, {
      column: column._id,
      value,
    })
  }

  renderFormHeader = (view: BaseView) => {
    const details = view.details

    if (!details || !details.settings || details.as !== ViewDetailsType.FORM) {
      return null
    }

    const { settings } = details
    const elements = []

    if (!this.isReadOnly()) {
      elements.push(<h3>* Дані зберігаються автоматично</h3>)
    }

    if (settings.logo) {
      elements.push(
        <div className={'irv-frm-logo'}>
          <img src={getFormCDN(this.state.table, settings.logo)} alt={""} />
        </div>
      )
    }

    if (settings.title) {
      elements.push(
        <h2>{settings.title}</h2>
      )
    }

    if (settings.description) {
      elements.push(
        <div className={'irv-frm-description'}>{settings.description}</div>
      )
    }

    if (!elements.length) {
      return null
    }

    return elements
  }

  render() {
    const { loading, is404, row, table, wsUsers, user, links } = this.state

    if (!row) {
      return (
        <div>
          {is404 && <NotFoundPage />}

          {loading && <Loading />}
        </div>
      )
    }

    const view = table.views[0]
    const { style, classname } = this.getViewLayout(view)

    return (
      <div className={'row-details-container scr ' + classname} style={style}>
        <FormDetailsController
          view={view}
          table={table}
          header={this.renderFormHeader(view)}
          row={row}
          collaborators={wsUsers}
          links={links}
          user={user || getMockUser()}
          readonly={this.isReadOnly()}
          onCommit={this.handleCommit}
          onChange={this.handleChange}
        />
      </div>
    )
  }
}

export default RowDetailsContainer
