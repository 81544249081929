import {TableActions} from "../actions/TableActions";
import {BaseTable} from "../../types/BaseTable";
import {BaseActions} from "../actions/BaseActions";
import {GridColumn} from "../../components/grid/types/GridColumn";

const initState: BaseTable[] = []

export default function bases(state = initState, action) {
  const payload = action.payload || {}
  const result = action.result

  switch (action.type) {
    case BaseActions.FETCH:
    case TableActions.FETCH:
      return payload.tables || state

    case BaseActions.CREATE_TABLE:
      if (result && result.table) {
        return state.concat(result.table)
      }

      return state

    case TableActions.UPDATE:
      return result
        ? state
        : state.map(table => {
          if (table._id !== payload._id) {
            return table
          }

          return {
            ...table,
            ...payload
          }
        })

    case TableActions.UPDATE_VIEW_COLUMN: {
      if (result) {
        return state
      }

      const {_id, id, viewId, columnId, ...rest} = payload

      return state.map((table) => {
        if (table._id !== _id) {
          return table
        }

        const views = table.views.map(v => {
          if (v._id !== viewId) {
            return v
          }

          const columnParams = v.columnsParams || {}
          const currentColumnParams = Object.assign({}, columnParams[columnId], rest)

          return {
            ...v,
            columnsParams: Object.assign(
              {},
              columnParams,
              {
                [columnId]: currentColumnParams
              }
            )
          }
        })

        return {
          ...table,
          views
        }
      })
    }

    case TableActions.UPDATE_COLUMN:
      return state.map((table) => {
        if (table._id !== payload._id) {
          return table
        }

        return {
          ...table,
          columns: table.columns.map(c => {
            if (c._id !== payload.columnId) {
              return c
            }

            if (result && result.column) {
              return result.column
            }

            const resultColumn = {
              ...c,
              ...payload,
              params: Object.assign({}, c.params, payload.params)
            }

            if (payload.columnId) {
              resultColumn._id = payload.columnId
            }

            return resultColumn
          })
        }
      })

    case TableActions.CREATE_COLUMN:
      return state.map((table) => {
        if (table._id !== payload.id) {
          return table
        }

        const newColumn: GridColumn = result || payload
        newColumn['isNew'] = true

        return {
          ...table,
          columns: table.columns.concat(newColumn)
        }
      })

    case TableActions.CREATE_VIEW:
      if (!result) {
        return state
      }

      return state.map((table) => {
        if (table._id !== payload.id) {
          return table
        }

        return {
          ...table,
          views: table.views.concat(result)
        }
      })

    case TableActions.UPDATE_VIEW_FILTER: {
      if (!result) {
        return state
      }

      const {_id, viewId} = payload

      return state.map((table) => {
        if (table._id !== _id) {
          return table
        }

        const views = table.views.map(v => {
          if (v._id !== viewId) {
            return v
          }

          return result
        })

        return {
          ...table,
          views
        }
      })
    }

    case TableActions.UPDATE_VIEW: {
      if (result) {
        return state
      }

      const {_id, id, viewId, columnId, ...rest} = payload

      return state.map((table) => {
        if (table._id !== _id) {
          return table
        }

        const views = table.views.map(v => {
          if (v._id !== viewId) {
            return v
          }

          return {
            ...v,
            ...rest
          }
        })

        return {
          ...table,
          views
        }
      })
    }

    default:
      return state
  }
}
