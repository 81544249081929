import React from 'react'
import {GridRendererProps} from "../GridRendererProps";
import {getStoreUsers} from "../../../utils/get";
import BaseUserRenderer from "./BaseUserRenderer";

export default React.memo(function GridCreatorRenderer(props: GridRendererProps) {
  if (props.isActive) {
    return null
  }

  return (
    <BaseUserRenderer
      readonly={true}
      value={props.row['createdBy']}
      onChange={_ => {}}
      options={getStoreUsers()}
    />
  )
})
