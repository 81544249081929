import React from 'react'
import { Checkbox } from '../../../components/irv-ui'
import { BaseRendererProps } from '../BaseRendererProps'

export default React.memo(function BaseCheckboxRenderer(
  props: BaseRendererProps<boolean>,
) {
  const { readonly, autoFocus, value, onChange } = props
  const checkboxProps: any = {
    autoFocus,
    checked: value,
    value,
  }

  if (!readonly && onChange) {
    checkboxProps['onKeyDown'] = e => {
      if (e.keyCode === 13) onChange(!value)
    }

    checkboxProps['onChange'] = _ => onChange(!value)
  }

  return (
    <div className={'re-editor checkbox'}>
      <Checkbox {...checkboxProps} />
    </div>
  )
})
