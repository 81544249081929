import {createStore, applyMiddleware, compose} from 'redux'
import thunk from 'redux-thunk'
import rootReducer from "./rootReducer";

// @ts-ignore
const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = preloadedState => createStore(
  rootReducer,
  preloadedState,
  storeEnhancers(applyMiddleware(thunk))
)

const store = configureStore({})

export default store
