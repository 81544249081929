import React from 'react'
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

import './styles.scss'

interface Props {
  onDragEnd: (res: any) => void
  containerCn?: string
  droppableId: string
  items: any[]
  itemRenderer: any
}

export default React.memo(function DNDList(props: Props) {
  return (
    <DragDropContext onDragEnd={props.onDragEnd}>
      <Droppable droppableId={props.droppableId}>
        {(provided) => (
          <ul
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={props.containerCn + ' dnd'}
          >
            {props.items.map((item, index) => (
              <Draggable key={item._id} draggableId={item._id} index={index}>
                {(draggableProvided) => (
                  <li
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                  >
                    <i {...draggableProvided.dragHandleProps} className={`icon i-drag`}/>
                    <div className={'c-dnd'}>
                      {props.itemRenderer(item, index)}
                    </div>
                  </li>
                )}
              </Draggable>
            ))}

            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  )
})
