import React from 'react'
import { SimpleRendererProps } from '../types/SimpleRendererProps'
import { format } from "../../../general/utils/date"

const DateRenderer: React.FC<SimpleRendererProps> = ({ value, className, params }) => {
  const formattedValue = value
    ? format(value, params.format, params.timeFormat, true)
    : ''

  return <div className={className}>{formattedValue}</div>
}

export default React.memo(DateRenderer)
