import React from 'react'
import { SimpleRendererProps } from '../types/SimpleRendererProps'
import { format } from "../../../general/utils/date"

const PhoneRenderer: React.FC<SimpleRendererProps> = ({ value, className, }) => {
  if (!value) {
    return null
  }

  return (
    <div className={className}>
      <a
        className="link"
        href={`tel:${value}`}
        onClick={e => e.stopPropagation()}>
        {value}
      </a>
    </div>
  )
}

export default React.memo(PhoneRenderer)
