import React, { memo, useMemo } from "react"
import { cn } from '../../../utils/cn'
import { ContainersProps } from '../types/ContainersProps'
import { TableHeader, TableResultGroup } from "../types/Table"

const GroupsContainer: React.FC<ContainersProps> = ({
  className,
  headers,
  empty,
  groups,
  onBodyClick,
}) => {
  const containerClassName = cn(className)
  const containerWidth = useMemo(() => {
    const width = headers.reduce((width: number, header: TableHeader) => {
      return width + header.width
    }, 0)

    if (width > 0) {
      return {
        width
      }
    }
  }, [headers])

  return (
    <div className={containerClassName} onClick={onBodyClick} style={containerWidth}>
      <div className={'irv-table-header'}>
        {headers.map(header => (
          <div tabIndex={-1} {...header.th} className={header.className}>
            {header.title}
          </div>
        ))}
      </div>

      {groups.map(group => (
        <div className={'irv-table-group'} key={group.key}>
          <div {...group.props}>
            {group.render}
          </div>

          {!group.collapsed && (
            <div className={'irv-table-rows'}>
              {group.rows.map(row => (
                <div
                  {...row.props}
                  key={row.key}
                  data-key={row.key}
                  className={row.className || null}>
                  {row.cells.map((cell, i) => (
                    <div
                      key={cell.key}
                      {...cell.props}
                      className={cell.className}
                      {...headers[i].th}>
                      {cell.render}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}

      {!groups.length ? empty : null}
    </div>
  )
}

export default memo(GroupsContainer)
