import {IorvoGridColumn} from "./types/IorvoGridColumn";
import {GroupHeaderRow} from "./types/IorvoGridProps";

export const getGroupLevel = (group: GroupHeaderRow, depth: number = 0): number => {
  return depth - group.depth
}

export const hasOwn = (target: any, key: string): boolean => {
  if (target.hasOwnProperty) {
    return target.hasOwnProperty(key)
  }

  return false
}

/// REMOVE
export const calculateColumnsWidth = (columns: IorvoGridColumn[]): number => {
  return columns.reduce((res, col) => res + (col.width || 150), 0)
}

