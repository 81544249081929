import React, { useMemo } from 'react'
import { IorvoGridColumn } from '../types/IorvoGridColumn'
import HeaderColumn from '../renderer/HeaderColumn'

interface Props {
  totalColumnsWidth: number
  freeze: number
  freezeWidth: number
  payload: any
  grouping: any // Тут похуй. Если есть -  увеличим "sn"
  columns: IorvoGridColumn[]
  headerRenderer: any
  onStartResize: (column: IorvoGridColumn, pos: any) => void
}

const Header: React.FC<Props> = (
  {
    freeze,
    columns,
    freezeWidth,
    totalColumnsWidth,
    headerRenderer,
    grouping,
    payload,
    onStartResize,
  },
  ref,
) => {
  const [freezeColumns, restColumns] = useMemo(() => {
    const freezeColumns: IorvoGridColumn[] =
      freeze > 0 ? columns.slice(0, freeze) : []

    const restColumns: IorvoGridColumn[] = freeze
      ? columns.slice(freeze)
      : columns

    if (restColumns[0]?._id === 'sn') {
      return [
        freezeColumns,
        restColumns.map((c, i) => ({
          ...c,
          width: i === 0 ? (c.width || 0) + (grouping ? 15 : 0) : c.width,
        })),
      ]
    }

    return [freezeColumns, restColumns]
  }, [columns, freeze, grouping])

  return (
    <div className="irv-header" ref={ref}>
      <div
        className="irv-header-wrap"
        style={{ width: totalColumnsWidth + 30 }}>
        {freezeWidth ? (
          <div className={'irv-frz'} style={{ width: freezeWidth }}>
            {freezeColumns.map((column: IorvoGridColumn) => (
              <HeaderColumn
                key={column._id}
                column={column}
                renderer={headerRenderer}
                payload={payload}
                onStartResize={onStartResize}
              />
            ))}
          </div>
        ) : null}

        {restColumns.map((column: IorvoGridColumn, i: number) => (
          <HeaderColumn
            key={column._id}
            column={column}
            renderer={headerRenderer}
            payload={payload}
            onStartResize={onStartResize}
          />
        ))}
      </div>
    </div>
  )
}

// @ts-ignore
export default React.forwardRef(Header)
