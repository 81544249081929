import {FilterOperators} from '../types/FilterOperators'
import {OperationsMap} from '../types/OperationsMap'

import {filterUserRole} from './FilterUserRule'
import {filterDateRule} from './FilterDateRule'

import {isEmpty} from '../utils/is'
import {asArray} from '../utils/array'
import {getValue} from '../utils/value'
import {isDateColumn, isUserColumn, isSelectColumn, getById, isSpecialColumn} from '../utils/column'
import {utc, isValid} from '../utils/date'

/*eslint default-case: 0*/

class Filter {
  constructor(payload) {
    this.table = payload.table
    this.filter = payload.filter
    this.user = payload.user
  }

  bulk(rows) {
    if (this._isEmptyRules()) {
      return rows
    }

    return rows.filter(row => this.isMatch(row))
  }

  _isEmptyRules() {
    return !this.filter || isEmpty(this.filter.filters)
  }

  isNumeric(op) {
    return OperationsMap.numberType.includes(op)
  }

  isEmptiness(op) {
    return [FilterOperators.IS_EMPTY, FilterOperators.IS_NOT_EMPTY].includes(op)
  }

  isMatch(row) {
    if (this._isEmptyRules()) {
      return true
    }

    return this._callFilters(row, this.filter.filters, this.filter.op)
  }

  _callFilters = (row, filters, op) => {
    const filterFn = filter => {
      if (filter.filters) {
        return this._callFilters(row, filter.filters, filter.op)
      }

      return this.callRule(filter, row)
    }

    if (op === 'or') {
      return filters.some(filterFn)
    }

    return filters.every(filterFn)
  }

  callAnd(rulesAnd, row) {
    return rulesAnd.every(rule => this.callRule(rule, row))
  }


  callRule(rule, row) {
    const column = getById(this.table, rule.col)

    if (!column) {
      return false
    }

    const value = getValue(this.table, column, row)

    if (this.isEmptiness(rule.op)) {
      return this.callEmptinessRule(rule, column, value)
    }

    // Может быть и формула дата
    if (isDateColumn(column) || value instanceof Date) {
      return this.callDateRule(rule, value)
    }

    if (isUserColumn(column)) {
      return this.callUserRule(rule, column, value)
    }

    if (isSelectColumn(column)) {
      return this.callSelectRule(rule, column, value)
    }

    if (this.isNumeric(rule.op)) {
      return this.callNumericRule(rule, column, value, row)
    }

    // тут еще select
    if (rule.op === FilterOperators.IS) {
      return this.callIs(rule, column, value)
    }

    if (rule.op === FilterOperators.IS_NOT) {
      return this.callIsNot(rule, column, value)
    }

    return false
  }

  callIs(rule, column, value) {
    return asArray(rule.value).join(',') === asArray(value).join(',')
  }

  callIsNot(rule, column, value) {
    return asArray(rule.value).join(',') !== asArray(value).join(',')
  }

  callSelectRule(rule, column, val) {
    const value = asArray(val)
    const ruleValue = asArray(rule.value)

    switch (rule.op) {
      case FilterOperators.IS:
        return ('' + value) === ('' + ruleValue)

      case FilterOperators.IS_NOT:
        return '' + value !== '' + ruleValue
      default: return false
    }
  }

  callDateRule(rule, value) {
    const [start, end] = filterDateRule(rule)
    const date = utc(value).toDate()

    if (!isValid(date)) {
      return false
    }

    if (+start > +date) {
      return false
    }

    if (!end) {
      return true
    }

    return +date <= +end
  }

  callUserRule(rule, column, value) {
    const aValue = asArray(value)
    const userIds = []

    if (isSpecialColumn(column.type)) {
      userIds.push(value)
    } else if ('lookup' === column.type) {
      aValue.forEach(v => {
        userIds.push(...asArray(v.value))
      })
    } else {
      userIds.push(...aValue)
    }

    return filterUserRole(rule, userIds, this.user.id || this.user._id)
  }

  callNumericRule(rule, column, value, row) {
    const ruleValue = rule.mode === 'cellValue'
      ? parseInt(row.data[rule.value])
      : parseInt(rule.value)

    const c = parseInt(value)

    // TODO тут проверить флоат или интs
    switch (rule.op) {
      case FilterOperators.IS_EQUAL:
        return ruleValue === c
      case FilterOperators.IS_NOT_EQUAL:
        return ruleValue !== c
      case FilterOperators.IS_GREATER_THAN:
        return c > ruleValue
      case FilterOperators.IS_GREATER_THAN_OR_EQUAL:
        return c >= ruleValue
      case FilterOperators.IS_LESS_THAN:
        return c < ruleValue
      case FilterOperators.IS_LESS_THAN_OR_EQUAL:
        return c <= ruleValue
    }
  }

  callEmptinessRule(rule, column, value) {
    return rule.op === FilterOperators.IS_EMPTY
      ? undefined === value
      : undefined !== value
  }
}

export {Filter}
