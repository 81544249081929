import React from 'react'
import {GridRendererProps} from "../GridRendererProps";
import BaseMultipleSelectRenderer from "./BaseMultipleSelectRenderer";

export default React.memo(function GridMultipleSelectRenderer(props: GridRendererProps) {
  const {onCommit, isActive, row, column} = props

  return (
    <BaseMultipleSelectRenderer
      value={row[column._id]}
      readonly={!isActive}
      options={column.params.options || []}
      onChange={(val) => onCommit(val)}
      width={column.width || 150}
      params={column.params}
    />
  )
})
