import React, { useEffect, useMemo, useState } from 'react'
import { GridCellRendererProps } from '../types/GridRenderer'
import { getValue } from '../../../general/utils/value'
import { useKeyPress } from '../../hooks/useKeyPress'
import { EventKey } from '../../../utils/dom'
import { asArray } from '../../../general/utils/array'
import BaseLookupRenderer from '../base/LookupRenderer'
import Tooltip from 'antd/es/tooltip'

const GridLookupRenderer: React.FC<GridCellRendererProps> = ({
  active,
  column,
  row,
  payload,
}) => {
  const value = useMemo(
    () => asArray(getValue(payload.table, column, row)),
    [payload, column, row],
  )

  const [visible, setVisible] = useState<boolean>(false)
  const pressedKey = useKeyPress(
    [EventKey.SPACE, EventKey.ENTER],
    active,
    row._id + column._id,
  )

  useEffect(() => {
    if (pressedKey) {
      setVisible(true)
    }
  }, [pressedKey])

  useEffect(() => {
    setVisible(false)
    // eslint-disable-next-line
  }, [active])

  return (
    <Tooltip
      title={'Значение поля вычисляется автоматически'}
      open={visible}>
      <BaseLookupRenderer
        value={value}
        table={payload.table}
        column={column}
        payload={payload}
      />
    </Tooltip>
  )
}

export default React.memo(GridLookupRenderer)
