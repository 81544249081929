import React, { useState } from 'react'
import { Button, Input, Tabs } from '../../../../components/irv-ui'
import NEWIcon from '../../../../components/icon/NEWIcon'
import { getViewIcon } from '../../../../utils/view'
import { ViewType } from '../../../../components/grid/types/ViewType'
import { Dialog } from '../../../../components/irv-ui'

import './style.scss'

interface Props {
  visible: boolean
  onCancel: () => void
  onAdd: (name: string, type: ViewType) => void
}

const RViewType = (type: any, name: string) => (
  <>
    <NEWIcon type={getViewIcon(type)} />
    {name}
  </>
)

export default React.memo(function (props: Props) {
  const { visible, onCancel, onAdd } = props
  const [name, setName] = useState('')
  const [view, setView] = useState(ViewType.GRID)
  const [isCreateDisabled, setCreateState] = useState(true)

  const handleChange = name => {
    setName(name)
    setCreateState(false)
  }
  const handleAdd = _ => {
    onAdd(name, view)
    setCreateState(true)
  }

  return (
    <Dialog
      onClose={onCancel}
      open={visible}
      footer={null}
      header={null}
      alignSelf={'start'}
      width={900}
      className={'m-add-view'}>
      <Input
        autoFocus
        placeholder={'Имя представления'}
        value={name}
        onChange={handleChange}
      />
      <Tabs vertical activeKey={view} onChange={setView}>
        <Tabs.Tab key={ViewType.GRID} label={RViewType(ViewType.GRID, 'Сетка')}>
          <div className="help">
            Легко управляйте, обновляйте и упорядочивайте свои задачи в режиме
            сетки.
          </div>
        </Tabs.Tab>
        <Tabs.Tab key={ViewType.LIST} label={RViewType(ViewType.LIST, 'Список')}>
          <div className="help">
            Легко управляйте, обновляйте и упорядочивайте свои задачи в режиме
            сетки.
          </div>
        </Tabs.Tab>
        <Tabs.Tab
          key={ViewType.CALENDAR}
          label={RViewType(ViewType.CALENDAR, 'Календарь')}>
          <div className="help">
            Представление календаря — это ваше место для планирования и
            управления ресурсами
          </div>
        </Tabs.Tab>
        <Tabs.Tab
          key={ViewType.KANBAN}
          label={RViewType(ViewType.KANBAN, 'Канбан')}>
          <div className="help">
            Создайте свою идеальную доску и легко перетаскивайте данные между
            столбцами
          </div>
        </Tabs.Tab>
      </Tabs>

      <Button
        variant={'primary'}
        disabled={isCreateDisabled}
        onClick={handleAdd}>
        Добавить
      </Button>
    </Dialog>
  )
})
