import React from 'react'
import {CalendarProps, CalendarViewType, HashedEvents, ViewType} from "../NEW__types/Calendar";
import {Event} from "../NEW__types/Event";
import {ToolbarDirection} from "../types/Toolbar";
import {createHashedEvents, dayjs, getActiveView, getToolbarDates} from "../utils";
import MonthView from "../NEW__views/MonthView";
import CustomDays from "../NEW__views/CustomDays";

import '../NEW__styles/calendar/base.scss'

interface State {
  _copyEvents: Event[]
  events?: HashedEvents
  ready?: boolean
}

export default class Calendar extends React.PureComponent<CalendarProps, State> {
  mainEl: React.RefObject<any> = React.createRef()
  viewEl: React.RefObject<any> = React.createRef()

  state: State = {
    _copyEvents: []
  }

  static getDerivedStateFromProps = (props: CalendarProps, state: State) => {
    if (state._copyEvents !== props.events) {
      return {
        _copyEvents: props.events,
        events: createHashedEvents( // тут еще сделать сортировку для ДЛИННЫХ дат
          props.events,
          dayjs(props.active),
          getActiveView(props.activeView, props.views)
        )
      }
    }

    return null
  }

  componentDidMount() {
    this.setState({
      ready: true
    })
  }

  navigate = (direction: ToolbarDirection) => {
    const {onNavigate, active, activeView, views} = this.props

    if (!onNavigate) {
      return
    }

    const activeCalendarView: CalendarViewType = (views || []).find(v => v.key === activeView) || views[0]
    const date = dayjs(active)
    const newActive = activeCalendarView.type === ViewType.WORKING_WEEK
      ? date.add(direction, 'week').toDate()
      : date.add(direction, activeCalendarView.type).toDate()

    onNavigate({
      active: newActive
    })
  }

  setToday = () => {
    if (this.props.onNavigate) {
      this.props.onNavigate({active: dayjs().toDate()})
    }
  }

  onNavigate = (direction: ToolbarDirection) => {
    if (direction === ToolbarDirection.TODAY) {
      this.setToday()
    } else {
      this.navigate(direction)
    }
  }

  handleChangeView = (activeView: any) => {
    this.props.onNavigate({activeView})
  }

  renderToolbar(view: CalendarViewType) {
    const {toolbar: CalendarToolbar, active, views} = this.props

    if (!CalendarToolbar) {
      return null
    }

    const activeDate = dayjs(active)

    return (
      <div className={'c-toolbar'}>
        <CalendarToolbar
          active={activeDate}
          activeView={view}
          dates={getToolbarDates(activeDate, view)}
          views={views}
          navigate={this.onNavigate}
        />
      </div>
    )

    // const viewOptions = views.map(v => ({
    //   value: v.key,
    //   label: v.label
    // }))
    //
    // return (
    //   <div className={'c-toolbar'}>
    //     <button className="today" onClick={_ => this.onNavigate(ToolbarDirection.TODAY)}>Сегодня</button>
    //     <button className={'nav-ctrl'}
    //             onClick={_ => this.onNavigate(ToolbarDirection.PREV_PERIOD)}>
    //       <i className={'icon mdi mdi-chevron-left'}/>
    //     </button>
    //     <button className={'nav-ctrl'}
    //             onClick={_ => this.onNavigate(ToolbarDirection.NEXT_PERIOD)}>
    //       <i className={'icon mdi mdi-chevron-right'}/>
    //     </button>
    //
    //     <span className={'render-range'}>
    //       {getToolbarDate(active)}
    //     </span>
    //
    //     {viewOptions && viewOptions.length > 1 && (
    //       <Radio.Group
    //         options={viewOptions}
    //         value={activeView || viewOptions[0].value}
    //         onChange={this.handleChangeView}
    //       />
    //     )}
    //   </div>
    // )
  }

  renderView = (view: CalendarViewType) => {
    const {ready, events} = this.state

    if (!ready) {
      return null
    }

    const {moreRenderer, eventRenderer, toolbar, selected, onAdd, onSelect, onChange} = this.props
    const active = dayjs(this.props.active)
    const viewProps = {
      onSelect,
      onAdd,
      onChange,
      active,
      events,
      selected,
      view,
      moreRenderer,
      eventRenderer,
      toolbar,
      viewportHeight: this.viewEl.current.clientHeight
    }

    let Renderer

    switch (view.type) {
      case ViewType.DAY: {
        return (
          <CustomDays
            {...viewProps}
            days={1}
          />
        )
      }

      case ViewType.WEEK: {
        const startDate = active
          .startOf('week')
          .hour(5)

        return (
          <CustomDays
            {...viewProps}
            active={startDate}
            days={7}
          />
        )
      }

      case ViewType.MONTH:
        Renderer = MonthView
        break
    }

    return (
      <Renderer {...viewProps} />
    )
  }

  render() {
    const view = getActiveView(this.props.activeView, this.props.views)

    return (
      <div className={'c calendar'} ref={this.mainEl}>
        {this.renderToolbar(view)}

        <div className="c-view" ref={this.viewEl}>
          {this.renderView(view)}
        </div>
      </div>
    )
  }
}
