import React from 'react'
import {GridRendererProps} from "../GridRendererProps";
import {getStoreUsers} from '../../../utils/get';
import BaseUserRenderer from './BaseUserRenderer';

export default React.memo(function GridUserRenderer(props: GridRendererProps) {
  const {isActive, row, column, onCommit} = props

  return (
    <BaseUserRenderer
      readonly={!isActive}
      value={row[column._id] as string[]}
      onChange={users => onCommit(users)}
      multiple={true}
      params={column.params}
      width={column.width || 150}
      options={getStoreUsers()}
    />
  )
})
