import { GridRow } from "../components/grid/types/GridRow"
import { BaseTable } from "../types/BaseTable"
import { BaseView, ViewLike } from "../types/BaseView"
import { getColumnById } from "./get"
import { getRowValue } from "./grid"
import { asArray } from "./as"
import { ColumnType } from "../components/grid/types/ColumnType"
import { EyzyUser } from "../types/EyzyUser"
import store from "../store"
import { Filter } from "../general/filters/Filter"
import { getUIColumnType, isUserColumn } from "../general/utils/column"
import { getValue } from "../general/utils/value"
import { getColorsList } from "../general/data/colors"
import { arrToObj } from "./array"
import { hasColor } from "./view"

/*
a: []
b: ['#8c0404', '#e6304f', '#ff9900', '#1ac12c', '#00a7a7', '#50b2ee', '#216bff', '#9107ee', '#eb06eb', '#5e5e5e']
c: ['#cc4124', '#e06666', '#f6b26b', '#93c47d', '#76a5af', '#92cff4', '#6d9eeb', '#8e7cc3', '#c27ba0', '#c6c6c6']
d: ['#e3b6ad', '#f8cece', '#f6dfc7', '#d7e8d0', '#cdebeb', '#c6e6f9', '#d2e5f7', '#dad2eb', '#efd5e0', '#e8e8e8']
 */

export enum ViewColorType {
  NONE,
  FIELD,
  CONDITION,
}

export const mainColors: string[] = [
  "#980000",
  "#ff0000",
  "#ff9900",
  "#ffff00",
  "#00ff00",
  "#00ffff",
  "#4a86e8",
  "#0000ff",
  "#9900ff",
  "#ff00ff",
]

export const mainColorLight: string[] = [
  "#e6b8af",
  "#f4cccc",
  "#fce5cd",
  "#fff2cc",
  "#d9ead3",
  "#d0e0e3",
  "#c9daf8",
  "#cfe2f3",
  "#d9d2e9",
  "#ead1dc",
]

export const secondaryColors: string[] = [
  "#dd7e6b",
  "#ea9999",
  "#f9cb9c",
  "#ffe599",
  "#b6d7a8",
  "#a2c4c9",
  "#a4c2f4",
  "#9fc5e8",
  "#b4a7d6",
  "#d5a6bd",
  "#cc4125",
  "#e06666",
  "#f6b26b",
  "#ffd966",
  "#93c47d",
  "#76a5af",
  "#6d9eeb",
  "#6fa8dc",
  "#8e7cc3",
  "#c27ba0",
  "#a61c00",
  "#cc0000",
  "#e69138",
  "#f1c232",
  "#6aa84f",
  "#45818e",
  "#3c78d8",
  "#3d85c6",
  "#674ea7",
  "#a64d79",
  "#85200c",
  "#990000",
  "#b45f06",
  "#bf9000",
  "#38761d",
  "#134f5c",
  "#1155cc",
  "#0b5394",
  "#351c75",
  "#741b47",
  "#5b0f00",
  "#660000",
  "#783f04",
  "#7f6000",
  "#274e13",
  "#0c343d",
  "#1c4587",
  "#073763",
  "#20124d",
  "#4c1130",
]

export const baseColors: string[] = [
  "#8c0404",
  "#e6304f",
  "#ff9900",
  "#1ac12c",
  "#00a7a7",
  "#50b2ee",
  "#216bff",
  "#9107ee",
  "#eb06eb",
  "#5e5e5e",
]

export const whiteBlackColors: string[] = [
  "#000000",
  "#434343",
  "#666666",
  "#999999",
  "#b7b7b7",
  "#cccccc",
  "#d9d9d9",
  "#efefef",
  "#f3f3f3",
  "#ffffff",
]

export const allColors: string[] = [
  ...mainColors,
  ...mainColorLight,
  ...secondaryColors,
  ...whiteBlackColors,
]

const useWhiteColors: string[] = [
  "#0c80d9",
  "#02a3d3",
  "#00a7a7",
  "#1ac12c",
  "#e6304f",
  "#8c0404",
  "#5e5e5e",
  "#000000",
  "#0c343d",
  "#5b0f00",
  "#4c1130",
  "#20124d",
  "#274e13",
  "#660000",
  "#073763",
  "#134f5c",
  "#783f04",
  "#7f6000",
  "#434343",
  "#741b47",
  "#351c75",
  "#85200c",
  "#38761d",
  "#980000",
  "#990000",
  "#0b5394",
  "#1c4587",
  "#a61c00",
  "#b45f06",
  "#bf9000",
  "#666666",
  "#cc0000",
  "#45818e",
  "#1155cc",
  "#cc4125",
  "#a64d79",
  "#674ea7",
  "#6aa84f",
  "#ff00ff",
  "#9900ff",
  "#0000ff",
  "#ff9900",
  "#ff0000",
]

export const getColorClass = (prefix: string): string => {
  return `color-${prefix}`
}

export const isBlackTextColor = (className: string) => {
  if (["c2", "c3", "c4", "c5", "c8", "c9"].includes(className)) {
    return true
  }

  return /d/.test(className)
}

export const getTextColorByClass = (className: string) => {
  if (isBlackTextColor(className)) {
    return { color: "#222222" }
  }
}

export function shoudUseWhite(color: any) {
  return useWhiteColors.includes(color)
}

export function getRandomColor(prefix = "b"): string {
  const colors = getColorsList(prefix)
  const color = colors[Math.floor(Math.random() * 10)]

  return color ? color.v : colors[0].v
}

export function getRandomBaseColor() {
  return getRandomColor()
}

export function getRowColor(
  row: GridRow,
  table: BaseTable,
  view: BaseView,
): string | undefined {
  if (!view.color || !view.color.col) {
    return
  }

  const column = getColumnById(table, view.color.col)

  if (!column) {
    return
  }

  const rowValue = asArray(getRowValue(row, column))

  if (column.type === ColumnType.SINGLE_SELECT) {
    const options = column.params.options || []

    for (let i = 0; i < options.length; i++) {
      if (rowValue.includes(options[i]._id)) {
        return options[i].color
      }
    }
  }

  if (isUserColumn(column) && rowValue.length > 0) {
    const _store = store.getState()
    const activeWs = _store.wss.find(ws => _store.active.ws === ws._id)

    // @ts-ignore
    const users: { [key: string]: EyzyUser } = arrToObj(activeWs.collaborators, "_id", false)

    for (let i = 0; i < rowValue.length; i++) {
      const user = users[rowValue[i]]

      if (user) {
        return user.params && user.params.color
      }
    }
  }
}

export function getTableColorsMap(
  rows: GridRow[],
  table: BaseTable,
  view: ViewLike,
  user: EyzyUser,
): { [rowId: string]: string } {
  const result: { [rowId: string]: string } = {}

  if (!hasColor(view)) {
    return null
  }

  const colorType: string = view.color.type === "row" ? "ic" : "ia"

  if (view.color && view.color.condition) {
    view.color.condition.forEach(condition => {
      const filter = new Filter({
        table,
        user,
        filter: condition.filter,
      })

      rows.forEach(row => {
        if (result[row._id]) {
          return
        }

        if (filter.isMatch(row)) {
          result[row._id] = `${condition.color} ${colorType}`
        }
      })
    })

    if (view.color.otherwise) {
      rows.forEach(row => {
        if (!result[row._id]) {
          result[row._id] = `${view.color.otherwise} ${colorType}`
        }
      })
    }

    return result
  }

  const column = getColumnById(table, view.color.col)

  if (!column) {
    return
  }

  if (isUserColumn(column)) {
    const _store = store.getState()
    const activeWs = _store.wss.find(ws => _store.active.ws === ws._id)
    const users: { [key: string]: EyzyUser } = activeWs.collaborators

    for (let i = 0; i < rows.length; i++) {
      const row = rows[i]
      const val = getValue(table, column, row)
      const userId = Array.isArray(val) && val[0] ? val[0] : val

      if (!userId) {
        continue
      }

      // lookup
      const user = users[userId._id ? userId.value[0] : userId]

      if (user && user.params && user.params.color) {
        result[row._id] = `${user.params.color} ${colorType}`
      }
    }
  }

  if (getUIColumnType(column) === ColumnType.SINGLE_SELECT) {
    const options = (
      (column.params.foreignParams || column.params).options || []
    ).reduce((res, opt) => {
      res[opt._id] = opt
      return res
    }, {})

    for (let i = 0; i < rows.length; i++) {
      const row = rows[i]
      const val = getValue(table, column, row) || []

      if (val[0]) {
        const opt = options[val[0]]

        if (opt && !(row._id in result) && opt.color) {
          result[row._id] = `${opt.color} ${colorType}`
        }
      }

      if (view.color.otherwise && !result[row._id]) {
        result[row._id] = `${view.color.otherwise} ${colorType}`
      }
    }
  }

  return result
}

export function getViewColorType(view: BaseView): ViewColorType {
  const color = view.color

  if (!color) {
    return ViewColorType.NONE
  }

  if (color.col) {
    return ViewColorType.FIELD
  }

  if (color.condition) {
    return ViewColorType.CONDITION
  }

  return ViewColorType.NONE
}
