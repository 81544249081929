import React from 'react'
import {EyzyUser} from "../../../types/EyzyUser";
import {BaseRendererProps} from "../BaseRendererProps";
import BaseUserRenderer from "./BaseUserRenderer";
import {getStoreUsers} from "../../../utils/get";
import PayloadProvider from '../../workspace/PayloadProvider';

export default React.memo(function UserRenderer(props: BaseRendererProps<any, EyzyUser>) {
  return (
    <PayloadProvider.Consumer>
      {({users}) => (
        <BaseUserRenderer {...props} options={users || getStoreUsers()}/>
      )}
    </PayloadProvider.Consumer>
  )
})
