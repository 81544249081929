import React from 'react'
import { hasViewGroup } from '../../utils/view'
import LinkedEntriesGroupsList from './LinkedEntriesGroupsList'
import { LinkedEntriesModalContentProps } from './types'
import RowsTable from '../table/rowsTable/RowsTable'
import { isEmpty } from '../../general/utils/is'

const LinkedEntriesModalContent: React.FC<LinkedEntriesModalContentProps> = ({
  rows,
  view,
  table,
  selected,
  showCheckbox,
  onTouch,
}) => {
  const hasGroup = hasViewGroup(view, table)
  const hasRows: boolean = !isEmpty(rows)

  return (
    <>
      {!hasGroup && <h3 className={'t-name'}>{table.name}</h3>}

      {hasRows ? (
        <RowsTable
          rows={rows}
          table={table}
          view={view}
          showCheckbox={showCheckbox}
          selected={selected}
          onClick={onTouch}
        />
      ) : (
        <p className={'no-entries'}>Записи не найдены</p>
      )}
    </>
  )
}

export default React.memo(LinkedEntriesModalContent)
