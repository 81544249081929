import React, { forwardRef, useState } from "react"
import { BaseRendererProps } from '../BaseRendererProps'
import DatePicker, { registerLocale } from 'react-datepicker'
import uk from 'date-fns/locale/uk'
import 'react-datepicker/dist/react-datepicker.css'
import { dayjs, format } from '../../../general/utils/date'
import { Button } from "../../irv-ui"
import NEWIcon from "../../icon/NEWIcon"

registerLocale('uk', uk)

export default function BaseDelayedDateRenderer(
  props: BaseRendererProps<number | null | Date>,
) {
  const [value, setValue] = useState(props.value)
  const showTime: boolean = !!props.params.timeFormat

  if (props.readonly) {
    if (!props.value) {
      return null
    }

    const d = dayjs(props.value)

    return (
      <div className={'re-value'}>
        {d.isValid()
          ? format(props.value, props.params.format, props.params.timeFormat)
          : ''}
      </div>
    )
  }

  const datePickerProps: any = {
    className: 'eyzy-input eyzy-date',
    selected: props.value ? dayjs(props.value).toDate() : null,
  }

  if (showTime) {
    datePickerProps.showTimeSelect = true
    datePickerProps.timeFormat = 'p'
    datePickerProps.timeIntervals = 15
  }

  const MobileCustomInput = forwardRef(
    ({ onClick, ...rest }: any, ref: any) => {
      const v = dayjs(rest.value)
      // console.log(v)
      // console.log(rest)
      return (
        <Button onClick={onClick}>
          <NEWIcon type={'d-calendar'} />
          {rest.value}
        </Button>
      )
    },
  )

  datePickerProps.customInput = <MobileCustomInput />

  const onDateSelect = function onDateSelect(date: Date) {
    if (!date) {
      return props.onChange(null)
    }

    const value = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
    )

    if (props.params.timeFormat) {
      value.setHours(date.getHours())
      value.setMinutes(date.getMinutes())
    }

    props.onChange(value)
    setValue(value)
  }

  let dFormat = 'dd.MM.yyyy'

  if (showTime) {
    dFormat += `, ${props.params.timeFormat}`
  }

  return (
    <DatePicker
      {...datePickerProps}
      popperPlacement={'top'}
      showPopperArrow={false}
      locale="uk"
      placeholder={dFormat}
      onChange={onDateSelect}
      dateFormat={dFormat}
    />
  )
}
