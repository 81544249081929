import React, { RefObject } from 'react'
import { EyzyUser } from '../../types/EyzyUser'
import Avatar, { getAvatarCDN } from './Avatar'
import Menu from 'antd/lib/menu'
import './styles/profile.scss'
import { Button, Input } from '../../components/irv-ui'
import avatarImg from '../../assets/svg/user_icon_7@2x.png'
import { isMob } from '../../utils/is'
import notification from 'antd/lib/notification'
import { __post, baseUrl } from '../../utils/request'
import { buildLink, Pages } from '../../utils/navigation'
import { Dialog } from '../irv-ui'

interface Props {
  user: EyzyUser
}

interface State {
  visible: boolean
  avatar: string
  firstName: string
  lastName: string
  updatedAvatar: any
}

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export default class Profile extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      visible: false,
      avatar: props.user.avatar
        ? getAvatarCDN(props.user.avatar, false)
        : avatarImg,
      lastName: props.user.lastName || '',
      firstName: props.user.firstName || '',
      updatedAvatar: null,
    }
  }

  file: RefObject<HTMLInputElement> = React.createRef()

  render() {
    const { user } = this.props
    const { visible, avatar, firstName, lastName, updatedAvatar } = this.state

    return (
      <>
        <div className="profile-avatar" onClick={this.handleOpen}>
          {updatedAvatar && (
            <span className={'avatar'}>
              <img
                src={updatedAvatar}
                alt={user.fullName}
                width="30"
                height="30"
              />
            </span>
          )}

          {!updatedAvatar && <Avatar user={user} hideTip />}
        </div>

        <Dialog
          open={visible}
          width={900}
          onClose={this.handleClose}
          footer={this.renderFooter()}
          header={null}
          className={'profile-modal'}>
          <div className={'profile'}>
            {!isMob() && (
              <div className="profile-modal-menu">
                <Menu defaultSelectedKeys={['1']}>
                  <Menu.Item key={'1'}>Настройка аккаунта</Menu.Item>
                </Menu>
              </div>
            )}
            <div className="profile-modal-content">
              <div className={'profile-block'}>
                <h3>Фото</h3>

                <div className="profile-block-avatar">
                  <div className={'preview'}>
                    <img
                      src={avatar}
                      width={'85px'}
                      height={'85px'}
                      alt={user.fullName}
                    />
                  </div>

                  <label>
                    <span className={'eyzy-btn'}>Загрузить фото</span>
                    <input
                      ref={this.file}
                      type={'file'}
                      onChange={this.handleAvatarChange}
                      multiple={false}
                      accept={'image/*'}
                    />
                  </label>
                </div>
              </div>

              <div className="profile-block">
                <h3>Личная информация</h3>

                <div className="profile-block-input">
                  <label>email</label>
                  <div className="input">{user.email}</div>
                </div>

                <div className="profile-block-input">
                  <label htmlFor={'p-name'}>Имя</label>
                  <Input
                    defaultValue={firstName}
                    onChange={this.createHandler('firstName')}
                    className={'input'}
                    id={'p-name'}
                  />
                </div>

                <div className="profile-block-input">
                  <label htmlFor={'p-lastname'}>Фамилия</label>
                  <Input
                    defaultValue={lastName}
                    onChange={this.createHandler('lastName')}
                    className={'input'}
                    id={'p-lastname'}
                  />
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </>
    )
  }

  renderFooter = () => {
    return (
      <>
        <Button onClick={this.save} variant={'primary'}>
          Сохранить
        </Button>
        <Button onClick={this.handleClose} variant={'minimal'}>Отмена</Button>
        <a href={'/logout'} onClick={this.logout}>
          Выход
        </a>
      </>
    )
  }

  createHandler = (fieldName: string) => (value: any) => {
    // @ts-ignore
    this.setState({
      [fieldName]: value,
    })
  }

  save = () => {
    // @ts-ignore
    const file: File | undefined = this.file.current.files
      ? this.file.current.files[0]
      : undefined

    const formData = new FormData()
    const { firstName, lastName } = this.state

    if (file) {
      formData.append('avatar', file)
    }

    firstName && formData.append('firstName', firstName)
    lastName && formData.append('lastName', lastName)

    fetch(baseUrl + 'user/update', {
      method: 'post',
      body: formData,
    }).then(response => {
      if (response.ok) {
        if (file) {
          const reader = new FileReader()

          reader.onload = (e: any) => {
            this.setState({ updatedAvatar: e.target.result })
          }

          reader.readAsDataURL(file)
        }

        return this.handleClose()
      }

      notification.info({
        className: 'resp_notification',
        placement: 'bottomLeft',
        message: 'Что-не так 😔 Попробуйте попробовать еще раз',
      })
    })
  }

  handleClose = () => {
    this.setState({ visible: false })
  }

  handleOpen = () => {
    this.setState({ visible: true })
  }

  handleAvatarChange = async () => {
    // @ts-ignore
    const file: File | undefined = this.file.current.files
      ? this.file.current.files[0]
      : undefined

    if (file) {
      const avatar = await getBase64(file)

      this.setState({
        // @ts-ignore
        avatar,
      })
    }
  }

  logout = e => {
    e.preventDefault()

    __post('user/logout').then(_ => {
      window.location.href = buildLink(Pages.MAIN, {})
    })
  }
}
