import React from "react";
import {BaseRendererProps} from "../BaseRendererProps";
import BaseCreatedDateRenderer from "./BaseCreatedDateRenderer";
import {GridRendererProps} from "../GridRendererProps";

export default React.memo(function GridModifiedDateRenderer(props: GridRendererProps) {
  // @ts-ignore
  const p: BaseRendererProps<any> = {
    value: props.row['modifiedOn'],
    format: 'D MMMM YYYY HH:mm:ss'
  }

  // TODO добавить в columnCostomizer

  return (
    // @ts-ignore
    <BaseCreatedDateRenderer {...p}/>
  )
})