import React, { forwardRef, Ref, useCallback } from 'react'
import { BaseCheckboxProps } from './types/BaseCheckboxProps'
import { cn } from '../../utils/cn'
import { useControlledState } from '../../hooks/useControlledState'

import './style/checkbox.scss'

function Checkbox(props: BaseCheckboxProps, ref: Ref<any>) {
  const {
    className,
    width,
    required,
    autoFocus,
    children,
    defaultChecked,
    useRing,
    readOnly,
    onChange,
    ...rest
  } = props

  const [checked, setChecked] = useControlledState(
    props.checked,
    onChange,
    props.defaultChecked,
  )

  const classNames = cn('e-checkbox', className)

  const eOuterClassName = 'e-outer'

  const handleChange = useCallback(
    e => {
      if (readOnly) {
        return
      }

      setChecked(e.target.checked)
    },
    [onChange, readOnly, setChecked],
  )

  return (
    <div className={classNames}>
      <input
        type="checkbox"
        onChange={handleChange}
        checked={checked}
        autoFocus={autoFocus}
        readOnly={readOnly}
        ref={ref}
        {...rest}
      />

      <span className={eOuterClassName}>
        <span className="e-inner">
          {checked && (
            <svg focusable="false" role="img">
              <path d="M3.788 9A.999.999 0 0 1 3 8.615l-2.288-3a1 1 0 1 1 1.576-1.23l1.5 1.991 3.924-4.991a1 1 0 1 1 1.576 1.23l-4.712 6A.999.999 0 0 1 3.788 9z"></path>
            </svg>
          )}
        </span>
      </span>

      {children && <span className="e-checkbox-label">{children}</span>}
    </div>
  )
}

export default forwardRef(Checkbox)
