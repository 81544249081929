import get from 'lodash.get'
import {EyzyUser} from "../../types/EyzyUser";
import {Roles} from "./Roles";
import {Workspace} from "../../types/Workspace";
import {Base} from "../../types/Base";
import { userEntityRole } from '../../general/utils/roles';

export function getRole(user, entity: Workspace | Base): Roles {
  if ((entity as Workspace).users) {
    return getWSRole(user, entity as Workspace)
  }

  return 0
}

export function getWSRole(user: EyzyUser, ws: Workspace): Roles {
  return get(ws, `users.${user._id}.role`, 0) as Roles
}

export function getUserRole(user, {_id}: any = {}): Roles {
  return (user.roles || {})[_id] || 0
}

export function isWSActionGranted(action: UIRoleAction, user: EyzyUser, entity: Workspace): boolean {
  return getRole(user, entity) >= ActionRoles[action]
}

export function isActionGranted(action: UIRoleAction, user: EyzyUser, entity: any): boolean {
  return getUserRole(user, entity) >= ActionRoles[action]
}

export function isActionGranted_NEW(action: UIRoleAction, user: EyzyUser, entities: any): boolean {
  return isRoleAllowed(
    action,
    userEntityRole(user, entities)
  )
}

export function getUserEntityRole(user: EyzyUser, entities: {ws?: Workspace, base?: Base}): Roles {
  // @ts-ignore
  return userEntityRole(user, entities)
}

export function isRoleAllowed(action: UIRoleAction, role: Roles): boolean {
  return role >= ActionRoles[action]
}

export enum UIRoleAction {
  WORKSPACE_EDIT,
  WORKSPACE_RENAME,
  WORKSPACE_SHARE,
  WORKSPACE_INVITE,
  WORKSPACE_REMOVE_COLLABORATOR,
  WORKSPACE_REMOVE,
  WORKSPACE_CREATE_BASE,
  BASE_COLLABORATORS_LIST,
  BASE_ALLOW_CONFIGURE,
  BASE_COMMON_SETTINGS,
  BASE_REMOVE,
  BASE_ROLE_SETTINGS,
  TABLE_CREATE,
  TABLE_EDIT,
  TABLE_EDIT_COLUMN,
  TABLE_RESIZE_COLUMN,
  TABLE_CREATE_VIEW,
  TABLE_EDIT_VIEW,
  TABLE_CREATE_COLUMN,
  TABLE_EDIT_ROW,
  TABLE_CREATE_ROW,
  VIEW_EDIT,
  VIEW_CREATE
}

export const ActionRoles = {
  [UIRoleAction.WORKSPACE_EDIT]: Roles.ADMIN,
  [UIRoleAction.WORKSPACE_RENAME]: Roles.ADMIN,
  [UIRoleAction.WORKSPACE_SHARE]: Roles.ADMIN,
  [UIRoleAction.WORKSPACE_INVITE]: Roles.DEPUTY,
  [UIRoleAction.WORKSPACE_REMOVE_COLLABORATOR]: Roles.DEPUTY,
  [UIRoleAction.WORKSPACE_REMOVE]: Roles.OWNER,
  [UIRoleAction.WORKSPACE_CREATE_BASE]: Roles.ADMIN,
  [UIRoleAction.BASE_COLLABORATORS_LIST]: Roles.DEPUTY,
  [UIRoleAction.BASE_ALLOW_CONFIGURE]: Roles.ADMIN,
  [UIRoleAction.BASE_COMMON_SETTINGS]: Roles.ADMIN,
  [UIRoleAction.BASE_REMOVE]: Roles.ADMIN,
  [UIRoleAction.BASE_ROLE_SETTINGS]: Roles.ADMIN,
  [UIRoleAction.TABLE_CREATE]: Roles.ADMIN,
  [UIRoleAction.TABLE_EDIT]: Roles.ADMIN,
  [UIRoleAction.TABLE_EDIT_COLUMN]: Roles.ADMIN,
  [UIRoleAction.TABLE_RESIZE_COLUMN]: Roles.EDITOR,
  [UIRoleAction.TABLE_CREATE_VIEW]: Roles.EDITOR,
  [UIRoleAction.TABLE_EDIT_VIEW]: Roles.EDITOR,
  [UIRoleAction.TABLE_CREATE_COLUMN]: Roles.ADMIN,
  [UIRoleAction.TABLE_EDIT_ROW]: Roles.MEMBER,
  [UIRoleAction.TABLE_CREATE_ROW]: Roles.MEMBER,
  [UIRoleAction.VIEW_EDIT]: Roles.EDITOR,
  [UIRoleAction.VIEW_CREATE]: Roles.ADMIN
}
