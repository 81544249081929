import React, { memo, useMemo } from 'react'
import BaseDateRenderer from '../base/DateRenderer'
import { GridCellRendererProps } from '../types/GridRenderer'
import { getValue } from '../../../general/utils/value'

const GridSpecialDateRenderer: React.FC<GridCellRendererProps> = ({
  row,
  payload,
  column,
}) => {
  const value = useMemo(
    () => getValue(payload.table, column, row),
    [payload, column, row],
  )

  return (
    <BaseDateRenderer value={value} column={column} table={payload.table} />
  )
}

export default memo(GridSpecialDateRenderer)
