import React from 'react'
import {Switch} from '../../../../components/irv-ui';
import {SettingsItemComponentProps} from "../types";
import NEWIcon from "../../../icon/NEWIcon";
import Tooltip from "antd/lib/tooltip";
import Tag from "../../../shape/Tag";
import Select from "antd/es/select";

export default React.memo(function SingleSelectView(props: SettingsItemComponentProps) {
  const {params, onAction} = props
  const defaultOptions = [
    {_id: null, text: 'Не указано'},
    ...(params.options || [])
  ]

  const defaultValue = params.defaultValue || null

  const handleChangeUseColor = (useColor) => {
    onAction({useColor})
  }

  const handleChangeFly = (fly) => {
    onAction({fly})
  }

  const handleChangeDefaultValue = (defaultValue) => {
    onAction({defaultValue: [defaultValue]})
  }

  return (
    <>
      <div className={'column-settings-row'}>
        <div className={'column-settings-label'}>
          Значение по умолчанию
        </div>
        <div className={'column-settings-ctrl c-selector'}>
          <Select
            value={defaultValue}
            optionFilterProp={'label'}
            onChange={handleChangeDefaultValue}
            getPopupContainer={trigger => trigger.parentNode}
          >
            {defaultOptions.map((option) => (
              <Select.Option key={option._id} value={option._id} label={option.text}>
                {option.text !== ' ' &&
                  <Tag color={params.useColor ? option.color : ''} removed={!!option.removedOn}>{option.text}</Tag>}
                {option.text === ' ' && option.text}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
      <div className={'column-settings-row'}>
        <div className={'column-settings-label'}>
          <div className={'df'}>
            Разрешить создавать опции
            <Tooltip title={'Любой пользователь, у кого есть право на редактирование сможет создать новую опцию'}>
              <span className={'help'}>
                <NEWIcon type={'help'}/>
              </span>
            </Tooltip>
          </div>
        </div>
        <div className={'column-settings-ctrl'}>
          <Switch checked={!!params.fly} onChange={handleChangeFly}/>
        </div>
      </div>
      <div className={'column-settings-row'}>
        <div className={'column-settings-label'}>
          Использовать цвета
        </div>
        <div className={'column-settings-ctrl'}>
          <Switch checked={!!params.useColor} onChange={handleChangeUseColor}/>
        </div>
      </div>
    </>
  )
})
