import React from 'react'
import { BaseRendererProps } from '../types/BaseRenderer'
import { RendererClassNames } from '../definitions'
import { isString } from '../../../general/utils/is'

const LongTextRenderer: React.FC<BaseRendererProps> = ({ value }) => {
  if (!isString(value)) {
    return null
  }

  return <div className={RendererClassNames.TEXTAREA}>{value}</div>
}

export default React.memo(LongTextRenderer)
