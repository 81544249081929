import React from 'react'
import Menu from 'antd/lib/menu'
import Dropdown from 'antd/lib/dropdown'
import { WorkspaceActions } from '../../store/actions/WorkspaceActions'
import { TableActions } from '../../store/actions/TableActions'
import './dropdownMenu.scss'
import { ActionType } from '../../store/CommonEffects'
import { BaseActions } from '../../store/actions/BaseActions'
import { MenuTheme } from 'antd/lib/menu/MenuContext'
import { ViewType } from '../grid/types/ViewType'
import { ViewActions } from '../../store/actions/ViewActions'
import { AppActions } from '../../store/actions/AppActions'
import { UIRoleAction } from '../roles/RoleGateway'
import NEWIcon from '../icon/NEWIcon'

export const iconsMap: Map<string, string> = new Map([
  [AppActions.FAVORITE, 'star'],
  [AppActions.COPY_LINK, 'link'],

  [WorkspaceActions.SETTINGS, 'settings'],
  [WorkspaceActions.UPDATE, 'pencil'],
  [WorkspaceActions.REMOVE, 'trash'],
  [WorkspaceActions.PERMISSIONS_SETTING, 'lock'],

  [TableActions.SETTINGS_COLUMN as string, 'settings-sliders'],
  [TableActions.DUPLICATE_COLUMN, 'duplicate'],
  [TableActions.REMOVE_COLUMN, 'trash'],
  [TableActions.REMOVE_VIEW, 'trash'],
  [TableActions.DUPLICATE_VIEW, 'duplicate'],
  [TableActions.UPDATE, 'pencil'],
  [TableActions.COPY, 'content-copy'],

  [BaseActions.CREATE_TABLE, 'plus'],
  [BaseActions.REMOVE_TABLE, 'trash'],
  [BaseActions.REMOVE, 'trash'],
  [BaseActions.MOVE, 'transfer'],
  [BaseActions.DUPLICATE, 'duplicate'],
  [BaseActions.PERMISSIONS_SETTING, 'lock'],

  [ViewType.GRID, 'table-large'],
  [ViewType.CALENDAR, 'calendar-month'],
  [ViewActions.DUPLICATE, 'content-copy'],
  [ViewActions.GROUP_COLUMN, 'group'],
  [ViewActions.HIDE_COLUMN, 'c-eye'],
  [ViewActions.SHOW_COLUMN, 'eye'],
  [ViewActions.SORT_COLUMN_A_Z, 'up-arrow'],
  [ViewActions.SORT_COLUMN_Z_A, 'down-arrow'],
])

export interface ContextMenuItem {
  key: string | ActionType
  text?: string
  icon?: string
  className?: string
  bold?: boolean
  uiAction?: UIRoleAction
  divider?: number
}

type Props = {
  onClick: (param: any) => void
  items: ContextMenuItem[]
  actionItems?: React.ReactNode[]
  style?: any
  theme?: MenuTheme
  selectedKeys?: string[]
  getPopupContainer?: any
  header?: React.ReactNode
} & { children: React.ReactNode }

export function getMenuIcon(key: string): React.ReactNode | null {
  const icon = iconsMap.get(key as ActionType)

  if (!icon) {
    return null
  }

  return <NEWIcon type={icon} />
}

export const DropdownMenu: React.FunctionComponent<Props> = (props: Props) => {
  const menu = (
    <Menu
      onClick={props.onClick}
      style={props.style}
      theme={props.theme || 'dark'}
      selectedKeys={props.selectedKeys}>
      {props.actionItems && [
        props.actionItems.map((a, i) => <Menu.Item>{a}</Menu.Item>),
        <Menu.Divider />,
      ]}

      {props.items.map(item => (
        <Menu.Item key={item.key}>
          {getMenuIcon(item.key)}

          {item.bold ? <strong>{item.text}</strong> : item.text}
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Dropdown
      overlay={menu}
      trigger={['click']}
      overlayClassName={'eyzy-dropdown'}
      getPopupContainer={props.getPopupContainer}>
      {props.children}
    </Dropdown>
  )
}
