import React, { useMemo } from 'react'
import { BaseRendererProps } from '../types/BaseRenderer'
import { RendererClassNames } from '../definitions'
import { asArray } from '../../../general/utils/array'
import UserBadge from '../../__renderers/user/UserBadge'
import { EyzyUser } from '../../../types/EyzyUser'
import { arrToObj } from '../../../utils/array'

const UserRenderer: React.FC<BaseRendererProps> = ({ value, payload }) => {
  const usersMap = useMemo(() => {
    if (!payload || !payload.users) {
      return {}
    }

    return arrToObj(payload.users, '_id', false)
  }, [payload])

  const users: EyzyUser[] = asArray(value)
    .map(id => usersMap[id])
    .filter(Boolean)

  return (
    <div className={RendererClassNames.USER}>
      {users.map(user => (
        <UserBadge user={user} key={user._id} />
      ))}
    </div>
  )
}

export default React.memo(UserRenderer)
