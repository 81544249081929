import React from 'react'
import Drawer from 'antd/lib/drawer'
import { Button, Input, Switch } from '../../../../../components/irv-ui'
import { Event } from '../../../../../components/calendar/NEW__types/Event'
import { ViewDateField } from '../../../../../types/BaseView'
import { CalendarViewType } from '../../../../../components/calendar/NEW__types/Calendar'
import DatePicker from 'react-datepicker'
import './style.scss'
import { dayjs } from '../../../../../general/utils/date'
import DTSelector from './DTSelector'
import { cn } from '../../../../../utils/classname'
import {
  changeRangeDate,
  isNewEvent,
  RangeType,
} from '../../../../../utils/calendar'
import EventDetailsRepeat from '../eventDetails/EventDetailsRepeat'
import { RRule } from 'rrule'

enum DTSelectorType {
  START_DATE,
  START_TIME,
  END_DATE,
  END_TIME,
}

interface Props {
  event?: Event
  dateFields: ViewDateField
  view: CalendarViewType
  onChange: (value: any) => void
  onRemove: (id: string) => void
  onSave: (payload: any) => void
}

interface State {
  payload?: any
  event?: Event
  selector?: DTSelectorType
}

export default class MobEventDetails extends React.PureComponent<Props, State> {
  form: React.RefObject<HTMLDivElement> = React.createRef()

  constructor(props: Props) {
    super(props)

    this.state = {
      event: props.event,
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { event } = this.props

    if (prevProps.event !== event) {
      this.setState({
        event: event ? { ...event } : null,
        selector: null,
      })
    }
  }

  getTitle = () => {
    const { onRemove, event } = this.props

    return (
      <React.Fragment>
        <Button variant={'minimal'} onClick={_ => onRemove('')}>
          Отмена
        </Button>
        <span>{isNewEvent(event) ? 'Новое событие' : 'Редактирование'}</span>
        <Button
          variant={'minimal'}
          disabled={!this.state.payload}
          onClick={this.handleSave}>
          Сохранить
        </Button>
      </React.Fragment>
    )
  }

  changeHandler = (colId: string) => (payload: any) => {
    if (colId === 'rrule') {
      return this.handleChange({
        [colId]: new RRule(payload),
      })
    }

    this.handleChange({
      [colId]: payload,
    })
  }

  datesChangeHandler = (type: RangeType) => (date: Date) => {
    const { event } = this.state
    const dates = {
      start: event.start,
      end: event.end,
    }

    const range = changeRangeDate(type, date, dates)

    this.handleChange(range)
  }

  handleRemove = () => {
    const { event } = this.state

    if (event) {
      this.props.onRemove(event.id)
    }
  }

  handleTitleChange = e => {
    this.handleChange({ title: e.target.value })
  }

  handleChange = payload => {
    const event = {
      ...this.state.event,
      ...payload,
    }

    this.setState({
      payload: {
        ...this.state.payload,
        ...payload,
      },
      event,
    })
  }

  handleKeyUp = e => {
    if (e.keyCode === 13) {
      e.target.blur()
    }
  }

  handleSave = () => {
    this.props.onSave(this.state.payload)
  }

  renderDates = () => {
    const { dateFields } = this.props
    const { selector, event } = this.state

    if (!event) {
      return null
    }

    const start = dayjs(event.start)
    const end = dayjs(event.end)

    const toggleSelector = (selector: DTSelectorType) => () => {
      this.setState({
        selector: this.state.selector === selector ? null : selector,
      })
    }

    const activeClass: string = 'active'
    const fooClass: string = 'form-item-foo event-d'

    const sDateClass: string = cn(
      'date',
      selector === DTSelectorType.START_DATE && activeClass,
    )
    const sTimeClass: string = cn(
      'time',
      selector === DTSelectorType.START_TIME && activeClass,
    )
    const eDateClass: string = cn(
      'date',
      selector === DTSelectorType.END_DATE && activeClass,
    )
    const eTimeClass: string = cn(
      'time',
      selector === DTSelectorType.END_TIME && activeClass,
    )

    const sFooClass: string = cn(
      fooClass,
      [DTSelectorType.START_DATE, DTSelectorType.START_TIME].includes(
        selector,
      ) && 'open',
    )
    const eFooClass: string = cn(
      fooClass,
      [DTSelectorType.END_DATE, DTSelectorType.END_TIME].includes(selector) &&
        'open',
    )

    const result = [
      <div className={'form-item event-date'} key={'s'}>
        <div className={'event-date-selectors'}>
          <div className={'form-item-label'}>
            <span>{dateFields.start.name}</span>
          </div>
          <div className={'form-item-ctrl'}>
            <input
              readOnly
              className={sDateClass}
              onClick={toggleSelector(DTSelectorType.START_DATE)}
              value={start.format('DD MMMM YYYY')}
            />
            <input
              readOnly
              className={sTimeClass}
              onClick={toggleSelector(DTSelectorType.START_TIME)}
              value={start.format('HH:mm')}
            />
          </div>
        </div>
        <div className={sFooClass}>
          {selector === DTSelectorType.START_DATE && (
            <DatePicker
              selected={dayjs(event.start).toDate()}
              className={'dt-selector-date'}
              locale="ru"
              placeholder={'dd.MM.yyyy'}
              showPopperArrow={false}
              dateFormat={'d MMMM yyyy'}
              inline
              onChange={this.datesChangeHandler(RangeType.START_DATE)}
            />
          )}

          {selector === DTSelectorType.START_TIME && (
            <DTSelector
              selected={dayjs(event.start)}
              onChange={this.datesChangeHandler(RangeType.START_TIME)}
            />
          )}
        </div>
      </div>,
    ]

    if (dateFields.end) {
      result.push(
        <div className={'form-item event-date'} key={'e'}>
          <div className={'event-date-selectors'}>
            <div className={'form-item-label'}>{dateFields.end.name}</div>
            <div className={'form-item-ctrl'}>
              <input
                readOnly
                className={eDateClass}
                onClick={toggleSelector(DTSelectorType.END_DATE)}
                value={end.format('DD MMMM YYYY')}
              />
              <input
                readOnly
                className={eTimeClass}
                onClick={toggleSelector(DTSelectorType.END_TIME)}
                value={end.format('HH:mm')}
              />
            </div>
          </div>

          <div className={eFooClass}>
            {selector === DTSelectorType.END_DATE && (
              <DatePicker
                selected={end.toDate()}
                className={'dt-selector-date'}
                locale="ru"
                placeholder={'dd.MM.yyyy'}
                showPopperArrow={false}
                dateFormat={'d MMMM yyyy'}
                inline
                onChange={this.datesChangeHandler(RangeType.END_DATE)}
              />
            )}

            {selector === DTSelectorType.END_TIME && (
              <DTSelector
                selected={end}
                onChange={this.datesChangeHandler(RangeType.END_TIME)}
              />
            )}
          </div>
        </div>,
      )
    }

    return result
  }

  render() {
    const { dateFields } = this.props
    const { event } = this.state
    const { settings } = dateFields

    return (
      <Drawer
        title={this.getTitle()}
        placement={'bottom'}
        destroyOnClose
        closable={false}
        height={document.body.clientHeight}
        className={'mob-event-details'}
        visible={!!event}>
        <div
          className={'form form-label-row'}
          ref={this.form}
          key={event ? event.id : ''}>
          <div className={'form-item'}>
            <div className={'form-item-ctrl'}>
              <Input
                fit
                placeholder={dateFields.title.name}
                defaultValue={event && event.title}
                onKeyUp={this.handleKeyUp}
                onChange={this.handleTitleChange}
              />
            </div>
          </div>

          <div className={'form-group'}>
            <div className={'form-item'}>
              <div className={'form-item-label'}>Весь день</div>
              <div className={'form-item-ctrl'}>
                <Switch />
              </div>
            </div>

            {this.renderDates()}
          </div>

          {settings && event && (
            <EventDetailsRepeat
              event={event}
              onChange={this.changeHandler('rrule')}
            />
          )}

          {!isNewEvent(event) && (
            <div className="form-item">
              <Button
                variant={'minimal'}
                className={'rm'}
                onClick={this.handleRemove}>
                Удалить
              </Button>
            </div>
          )}
        </div>
      </Drawer>
    )
  }
}
