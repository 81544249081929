import React from 'react'
import './style.scss'
import {BaseView} from '../../../../types/BaseView'
import {Roles} from '../../../roles/Roles'
import {ViewType} from '../../../grid/types/ViewType'
import Icon from '../../../icon/Icon'
import ViewName from '../ViewName'
import CollaboratorList from '../../collaborators/CollaboratorList'
import Dropdown from 'antd/lib/dropdown'

interface Props {
  views: BaseView[]
  activeView: BaseView
  userRole: Roles
  onAddView: (type: ViewType) => void
  onViewChange: (viewId) => void
}

function getOverlay({views, activeView, onAddView, onViewChange, userRole}) {
  return (
    <div className="view-list">
      <ul className="view-list-container">
        {views.map(view => {
          return (
            <li className='view-list-item' key={view._id}>
              {activeView._id === view._id && (
                <Icon type="check"/>
              )}
              <button onClick={() => {
                onViewChange(view._id)
              }}>
                <ViewName view={view}/>
              </button>

              {view.visibility && view.visibility.users && (
                <CollaboratorList users={view.visibility.users} dimens={20}/>
              )}
            </li>
          )
        })}
      </ul>
      {/*{isRoleAllowed(UIRoleAction.TABLE_CREATE_VIEW, userRole) && (*/}
      {/*  <div className="view-list-actions">*/}
      {/*    <span>Добавить:</span>*/}
      {/*    <ul>*/}
      {/*      <li>*/}
      {/*        <button onClick={() => onAddView(ViewType.GRID)}><Icon type="table-large"/>Сетку</button>*/}
      {/*      </li>*/}
      {/*      <li>*/}
      {/*        <button onClick={() => onAddView(ViewType.CALENDAR)}><Icon type="calendar-month"/>Календарь*/}
      {/*        </button>*/}
      {/*      </li>*/}
      {/*    </ul>*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  )
}

export default function ViewList(props: Props) {
  const {activeView} = props

  return (
    <Dropdown overlay={getOverlay(props)} trigger={['click']}>
      <div className="view-selector">
        <ViewName view={activeView}/>
        <Icon type="menu-down"/>
      </div>
    </Dropdown>
  )
}